/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'

/**********************************************************************************************************
 *   API
 **********************************************************************************************************/
import { appAPI } from 'api/app'
import { baseApi } from 'api/base'
import { billingAPI } from 'api/billing'
import { domainAPI } from 'api/domain'
import { shopAPI } from 'api/shop'
import { supportAPI } from 'api/support/support'

/**********************************************************************************************************
 *   INTERFACE & TYPES
 **********************************************************************************************************/
import {
    ChangeSecurityQuestion,
    EditAdditionalUser,
    EmailLog,
    IAdditionalUser,
    Role,
    SecurityQuestion,
    UpdateProfilePayload,
    User
} from 'models/account'
import { Links, MetaPayload, MetaResponse, SessionQuery } from 'models/app'
import { ICreateTwoFactorPayload, ICreateTwoFactorResponse, TAddTwoFactorPayload, TTwoFactorMethodList } from 'models/authentication'

/**********************************************************************************************************
 *   HELPER
 **********************************************************************************************************/
import globalCookies from 'helpers/cookies'

/**********************************************************************************************************
 *   ACCOUNT API
 **********************************************************************************************************/
export const accountAPI = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        updateProfile: builder.mutation<User, UpdateProfilePayload>({
            async queryFn(_arg, _queryApi, _extraOptions, baseQuery) {
                const updateProfile = await baseQuery({
                    url: 'client/me',
                    method: 'PUT',
                    body: _arg
                })

                if (updateProfile.error) return { error: updateProfile.error }

                const user = (await baseQuery('client/me')) as QueryReturnValue<
                    { data: User; meta: FetchBaseQueryMeta },
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >

                if (user.error) return { error: user.error }

                return {
                    data: user.data.data
                }
            }
        }),
        updatePassword: builder.mutation<void, { old_password: string; password: string; password_confirmation: string }>({
            query: (credentials) => ({
                url: 'client/me/password',
                method: 'PUT',
                body: credentials
            })
        }),
        changeEmailAddress: builder.mutation({
            async queryFn(_arg, _queryApi, _extraOptions, baseQuery) {
                const changeEmailAddress = await baseQuery({
                    url: 'client/me/email',
                    method: 'PUT',
                    body: _arg
                })

                const user = (await baseQuery('client/me')) as QueryReturnValue<
                    { data: User; meta: FetchBaseQueryMeta },
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >

                if (changeEmailAddress.error) return { error: changeEmailAddress.error }

                if (user.error) return { error: user.error }

                return {
                    data: user.data.data
                }
            }
        }),
        twoFactor: builder.query<TTwoFactorMethodList, void>({
            query: () => 'client/me/two-factor',
            transformResponse: (response: { data: TTwoFactorMethodList }) => response.data,
            providesTags: ['Two-Factor']
        }),
        createTwoFactor: builder.mutation<ICreateTwoFactorResponse, ICreateTwoFactorPayload>({
            query: (twoFactor) => ({
                url: 'client/me/two-factor/create',
                method: 'POST',
                body: twoFactor
            }),
            transformResponse: (response: { data: ICreateTwoFactorResponse }) => response.data
        }),
        addTwoFactor: builder.mutation<void, TAddTwoFactorPayload>({
            query: (twoFactor) => ({
                url: 'client/me/two-factor',
                method: 'POST',
                body: twoFactor
            }),
            invalidatesTags: ['Two-Factor']
        }),
        updatePrimaryTwoFactor: builder.mutation<void, number>({
            query: (id) => ({
                url: `client/me/two-factor/${id}`,
                method: 'PUT'
            }),
            invalidatesTags: ['Two-Factor']
        }),
        removeTwoFactor: builder.mutation<void, number>({
            query: (id) => ({
                url: `client/me/two-factor/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Two-Factor']
        }),
        closeAccount: builder.mutation({
            async queryFn(_arg, _queryApi, _extraOptions, baseQuery) {
                const closeAccount = await baseQuery({
                    url: 'client/close-account',
                    method: 'POST',
                    body: _arg
                })

                if (closeAccount.error) return { error: closeAccount.error }

                const logout = (await baseQuery({
                    url: 'client/logout',
                    method: 'POST'
                })) as QueryReturnValue<{ data: []; meta: FetchBaseQueryMeta }, FetchBaseQueryError, FetchBaseQueryMeta>

                if (logout.error) return { error: logout.error }

                globalCookies.remove('XSRF-TOKEN')
                globalCookies.remove('mercury_session')

                _queryApi.dispatch(appAPI.util.resetApiState())
                _queryApi.dispatch(accountAPI.util.resetApiState())
                _queryApi.dispatch(domainAPI.util.resetApiState())
                _queryApi.dispatch(supportAPI.util.resetApiState())
                _queryApi.dispatch(shopAPI.util.resetApiState())
                _queryApi.dispatch(billingAPI.util.resetApiState())

                return {
                    data: logout.data.data
                }
            }
        }),
        additionalUsers: builder.query<Array<IAdditionalUser>, void>({
            query: () => 'client/additional-users',
            transformResponse: (response: { data: Array<IAdditionalUser> }) => response.data,
            providesTags: ['Additional-Users']
        }),
        addAdditionalUser: builder.mutation<void, { email: string; role_id: number; access_expiry?: string }>({
            query: (user) => ({
                url: 'client/additional-users',
                method: 'POST',
                body: user
            }),
            invalidatesTags: ['Additional-Users']
        }),
        updateAdditionalUser: builder.mutation<void, EditAdditionalUser & { id: number }>({
            query: ({ id, ...rest }) => ({
                url: `client/additional-users/${id}`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['Additional-Users']
        }),
        removeAdditionalUser: builder.mutation<void, number>({
            query: (id) => ({
                url: `client/additional-users/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Additional-Users']
        }),
        additionalUserRoles: builder.query<Array<{ id: number; name: Role }>, void>({
            query: () => 'client/additional-users/roles'
        }),
        emailLogs: builder.query<{ data: Array<EmailLog>; links: Links; meta: MetaResponse }, MetaPayload>({
            query: ({ page = 1, filter_by, sort_by = '-created_at', search_by }) => {
                const sortBy = sort_by ? `&sort_by=${sort_by}` : ''
                const filterBy = filter_by ? `&filter_by=${filter_by.key},${filter_by.values}` : ''
                const searchBy = search_by?.value ? `&search_by=${search_by.key},~,${search_by.value}` : ''

                return `client/email-logs?page=${page}${sortBy}${filterBy}${searchBy}`
            },
            providesTags: ['Email-Logs']
        }),
        viewEmailLogs: builder.query<EmailLog, number>({
            query: (id) => `client/email-logs/${id}`,
            transformResponse: (response: { data: EmailLog }) => response.data
        }),
        resendEmail: builder.mutation<void, number>({
            query: (id) => ({
                url: `client/email-logs/${id}/resend`,
                method: 'POST'
            })
        }),
        getSecurity: builder.query<Array<SecurityQuestion>, void>({
            query: () => 'client/questions',
            transformResponse: (response: { data: Array<SecurityQuestion> }) => response.data,
            providesTags: ['Security']
        }),
        updateSecurity: builder.mutation<{ user: User; security: [] }, ChangeSecurityQuestion>({
            async queryFn(_arg, _queryApi, _extraOptions, baseQuery) {
                const security = (await baseQuery({
                    url: 'client/me/question',
                    method: 'PUT',
                    body: _arg
                })) as QueryReturnValue<{ data: []; meta: FetchBaseQueryMeta }, FetchBaseQueryError, FetchBaseQueryMeta>

                if (security.error) return { error: security.error }

                const user = (await baseQuery('client/me')) as QueryReturnValue<
                    { data: User; meta: FetchBaseQueryMeta },
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >

                if (user.error) return { error: user.error }

                return {
                    data: {
                        user: user.data.data,
                        security: security.data.data
                    }
                }
            }
        }),
        getUserSessions: builder.query<SessionQuery, void>({
            query: () => 'client/me/sessions',
            providesTags: ['User-Sessions']
        }),
        endUserSessions: builder.mutation<void, { password: string }>({
            query: (body) => ({
                url: 'client/me/sessions',
                method: 'DELETE',
                body
            }),
            invalidatesTags: ['User-Sessions']
        })
    })
})

export const {
    usePrefetch,
    useUpdateProfileMutation,
    useUpdatePasswordMutation,
    useChangeEmailAddressMutation,
    useTwoFactorQuery,
    useCreateTwoFactorMutation,
    useAddTwoFactorMutation,
    useUpdatePrimaryTwoFactorMutation,
    useRemoveTwoFactorMutation,
    useCloseAccountMutation,
    useAdditionalUsersQuery,
    useAddAdditionalUserMutation,
    useUpdateAdditionalUserMutation,
    useRemoveAdditionalUserMutation,
    useAdditionalUserRolesQuery,
    useEmailLogsQuery,
    useViewEmailLogsQuery,
    useResendEmailMutation,
    useGetSecurityQuery,
    useUpdateSecurityMutation,
    useGetUserSessionsQuery,
    useEndUserSessionsMutation
} = accountAPI
