/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _HeroBannerSearch } from './search'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { WebsiteHomeStyles as S } from '../../home/style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TWebsiteHero = React.FC<{
    title: string
    subtitle?: string
    children?: React.ReactNode
    className?: string
    large?: boolean
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _WebsiteHero: TWebsiteHero = ({ title, subtitle, children, className, large }) => {
    /***** RENDER *****/
    return (
        <S.HeroBanner className={className}>
            <S.BannerInner $large={large}>
                <S.HeroHeading>{title}</S.HeroHeading>
                {!!subtitle && <S.SubHeading>{subtitle}</S.SubHeading>}
                <div>{children}</div>
            </S.BannerInner>
        </S.HeroBanner>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/***** COMPOUND EXPORTS *****/
export const WebsiteHero = Object.assign(_WebsiteHero, {
    Search: _HeroBannerSearch
})
