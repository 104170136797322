/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'
import { CombinedTButtonProps, TAnchorButtonProps, TLinkButtonProps, TOnClickButtonProps, TSubmitButtonProps, _Button } from '../base'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Theme } from 'nxui/src'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { AnchorStyles } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
// For the anchor variation
type TAnchorProps = CombinedTButtonProps

// Helper to easily define a button
type Button<T extends { type: string }> = React.FC<Omit<T, 'type'>>

// Define the button variants (without type as the component defines this)
type StandardAnchor = Button<TAnchorButtonProps>
type SubmitAnchor = Button<TSubmitButtonProps>
type OnClickAnchor = Button<TOnClickButtonProps>
type LinkAnchor = Button<TLinkButtonProps>

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
const StyledAnchor = styled(_Button)<TAnchorProps>`
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    width: fit-content;
    text-decoration: none;
    letter-spacing: 0.25;
    font-size: 12px;

    ${Theme.Functions.setFont('Inter', 600)}
    ${Theme.Functions.outline('none')};
    ${Theme.Functions.animation('all')};
    ${({ fullwidth }) => fullwidth && 'width: 100%;'}

    :focus {
        ${Theme.Functions.outline('none')};
    }

    .icon {
        width: 14px;
        height: 14px;
        margin: 0 -4px 0 6px;
    }

    ${AnchorStyles.Color}
`

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _StandardAnchor: StandardAnchor = ({ children, ...props }) => {
    return (
        <StyledAnchor type='anchor' {...(props as TAnchorProps)}>
            {children}
        </StyledAnchor>
    )
}

export const _OnClickAnchor: OnClickAnchor = ({ children, ...props }) => {
    return (
        <StyledAnchor type='onclick' {...(props as TAnchorProps)}>
            {children}
        </StyledAnchor>
    )
}

export const _SubmitAnchor: SubmitAnchor = ({ children, ...props }) => {
    return (
        <StyledAnchor type='submit' {...(props as TAnchorProps)}>
            {children}
        </StyledAnchor>
    )
}

export const _LinkAnchor: LinkAnchor = ({ children, ...props }) => {
    return (
        <StyledAnchor type='link' {...(props as TAnchorProps)}>
            {children}
        </StyledAnchor>
    )
}
