import { PurchaseDomains } from 'containers/shop/domain/domain.shop'
import { useShopActiveGroup } from 'containers/shop/hooks/useShopActiveGroup'
import { PurchaseServices } from 'containers/shop/services/services.shop'
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function ShopElements() {
    /***** HOOKS *****/
    const [{ type }] = useShopActiveGroup()
    const { domains, products } = useAppSelector((state) => state.app.appSettings.section.shop)

    /***** RENDER *****/
    if (type === 'domains' && domains) return <PurchaseDomains />
    if (products) return <PurchaseServices />
    return <div>Nothing available for purchase at this time</div>
}
