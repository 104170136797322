import { shopAPI } from 'api/shop'
import type { IProductGroup } from 'models/shop/product'
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
*   CONSTS
**********************************************************************************************************/
export const domainProductGroup: IProductGroup = {
    id: 0,
    type: 'domains',
    name: 'Domain Names',
    description: null
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useDefaultProductGroup = () => {
    const { domains, products } = useAppSelector((state) => state.app.appSettings.section.shop)
    return shopAPI.endpoints.productGroups.useQuery(undefined, {
        selectFromResult: (data) => {
            const firstData = data?.data?.at(0) ?? domainProductGroup
            const defaultProductGroup = !domains && products && data?.data?.length ? firstData : domainProductGroup

            return { defaultProductGroup }
        }
    })
}
