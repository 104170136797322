import { useNavigate, useSearchParams } from 'react-router-dom'
import type { AllRoutes } from 'router/helpers'

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Simple useNavigate abstraction for navigating while maintaining search params. By default, the navigate function will
 * wipe these, so use this hook when you know that there may be search params that need to persist between pages.
 *
 * Note, if you expect to include more search params during navigation, use the basic useNavigate hook, as this hook
 * is designed to maintain the current search state, not for adding/removing more.
 */
export const useStatefulNavigate = () => {
    /***** HOOKS *****/
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    /***** HOOK RESULTS *****/
    return (to: AllRoutes): void => {
        return navigate({
            search: searchParams.toString(),
            pathname: to
        })
    }
}
