/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Anchor, Table } from 'nxui/src'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import PasswordLightbox from 'containers/services/lightbox/password.lightbox'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
import { Service } from 'models/service'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const PasswordActions = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

interface Props {
    username: string
    server: Service['server']
    schema: Service['schema']
    product: Service['product']
}

export function ServerDetails({ username, server, schema, product }: Props) {
    const [isPasswordHidden, setIsPasswordHidden] = useState(true)

    const { id } = useParams()
    const serviceId = Number(id)

    const [lightbox, setLightbox] = useState({
        status: false,
        template: ''
    })

    const { name, ip_address, additional_meta, nameservers } = server
    const { disk_usage, disk_limit, incoming_mail_server, outgoing_mail_server, webmail_url } = additional_meta || {}

    const tableColumns = useMemo(
        () => [
            {
                Header: 'Server Details',
                accessor: 'details'
            },
            {
                Header: '',
                accessor: 'value'
            },
            {
                Header: '',
                accessor: 'action',
                align: 'right'
            }
        ],
        []
    )

    const tableData = useMemo(() => {
        const serverInformation: { details: string; value: string | JSX.Element; action: string | JSX.Element }[] = [
            {
                details: 'Server Name',
                value: name || '',
                action: ''
            },
            {
                details: 'IP Address',
                value: ip_address || '',
                action: ''
            },
            {
                details: 'Username',
                value: username,
                action: ''
            },
            {
                details: 'Password',
                value: isPasswordHidden ? '**********' : schema.password || '',
                action: (
                    <PasswordActions>
                        <button type='button' onClick={() => setIsPasswordHidden((prev) => !prev)}>
                            {isPasswordHidden ? <EyeSlashIcon key='2' width={20} /> : <EyeIcon key='1' width={20} />}
                        </button>
                        <Anchor color={'primary'} onClick={() => setLightbox({ status: true, template: 'password' })}>
                            Reset
                        </Anchor>
                    </PasswordActions>
                )
            }
        ]

        if (product.service_type === 'email_hosting') {
            serverInformation.push({
                details: 'Webmail',
                value: webmail_url ? (
                    <Anchor color='primary' href={`https://${webmail_url}`} target='_blank'>
                        https://{webmail_url}
                    </Anchor>
                ) : (
                    ''
                ),
                action: ''
            })
            serverInformation.push({
                details: 'Incoming Mail Server',
                value: incoming_mail_server || '',
                action: ''
            })
            serverInformation.push({
                details: 'Outgoing Mail Server',
                value: outgoing_mail_server || '',
                action: ''
            })
            serverInformation.push({
                details: 'Disk Usage',
                value: disk_usage || disk_limit ? `${disk_usage || ''} / ${disk_limit || ''}` : '',
                action: ''
            })
        }

        if (server && Array.isArray(server.nameservers)) {
            return [
                ...serverInformation,
                ...server.nameservers.map((nameserver, index) => ({
                    details: `Nameserver ${index + 1}`,
                    value: nameserver
                }))
            ]
        }

        return serverInformation
    }, [ip_address, name, nameservers, isPasswordHidden])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <>
            <Table
                render={{
                    columns: () => tableColumns,
                    data: () => tableData
                }}
            />
            <PasswordLightbox
                id={serviceId}
                status={lightbox.status && lightbox.template === 'password'}
                close={() => setLightbox({ status: false, template: '' })}
            />
        </>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
