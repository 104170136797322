/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import PropTypes from 'prop-types'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Lightbox } from '../lightbox.styles'
import { handleActionsRender, handleLinksRender } from '../lightbox'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const LightboxFooterComponent = ({ lightboxFooterRef, actions, links, loading }) => {
    return (
        <Lightbox.Footer.Wrapper ref={lightboxFooterRef} height={lightboxFooterRef?.current?.clientHeight}>
            <>
                {links?.length > 0 && <Lightbox.Footer.Links>{handleLinksRender(links)}</Lightbox.Footer.Links>}
                {actions?.length > 0 && <Lightbox.Footer.Actions>{handleActionsRender(actions, loading)}</Lightbox.Footer.Actions>}
            </>
        </Lightbox.Footer.Wrapper>
    )
}

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
LightboxFooterComponent.propTypes = {
    lightboxFooterRef: PropTypes.shape({ current: PropTypes.object }),
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            color: PropTypes.string,
            func: PropTypes.func
        })
    ),
    links: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            href: PropTypes.string,
            target: PropTypes.string,
            func: PropTypes.func
        })
    ),
    loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

export default LightboxFooterComponent
