/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
/**********************************************************************************************************
 *   COMPONENT IMPORTS
 **********************************************************************************************************/
import SupportForm from 'containers/support/form/supportForm'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setSupportOpen } from 'store/slices/supportSlice'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { useAppDispatch, useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { Styles as S } from './support.style'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function Support() {
    const dispatch = useAppDispatch()
    const { supportOpen, isSubmitting } = useAppSelector((state) => state.support)

    const conditionalProps = {
        title: 'Support Request',
        content: <SupportForm />,
        conditions: { state: supportOpen, action: () => dispatch(setSupportOpen(false)) },
        actions: [
            {
                label: 'Submit Ticket',
                color: 'primary',
                type: 'submit',
                form: 'supportForm',
                loading: isSubmitting
            }
        ]
    }

    /*   RENDER COMPONENT
     *****************************************************/
    return <S.Lightbox {...conditionalProps} close={() => dispatch(setSupportOpen(false))} />
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
