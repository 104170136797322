/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import PropTypes from 'prop-types'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Lightbox } from '../lightbox.styles'
import { Loader } from '../../loader'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const LightboxBodyComponent = ({ children, loading }) => {
    return (
        <Lightbox.Default.Section>
            {loading ? <Loader.Basic message={loading?.length > 0 ? loading : 'Loading...'} /> : children}
        </Lightbox.Default.Section>
    )
}

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
LightboxBodyComponent.propTypes = {
    children: PropTypes.element,
    loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

export default LightboxBodyComponent
