/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { FormikProps } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Lightbox } from 'nxui/src'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import ChangeServicePlanForm from 'containers/services/form/changeServicePlan/changeServicePlan.form'
import { ChangePlanPreview } from 'containers/services/lightbox/changePlan/changeServicePreview'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useChangePlanMutation, useChangePlanPreviewMutation } from 'api/service'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
import { ServiceMetaPayload } from 'api/service/types'
import { ChangePlanForm, ChangePlanPayload } from 'models/service'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface Props {
    status: boolean
    meta: ServiceMetaPayload
    close: () => void
}

export interface ChangePlanData {
    template: 'configure' | 'loading' | 'confirm'
    value: ChangePlanPayload | null
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function ChangeServicePlanLightbox({ status, close, meta = { page: '1', sort_by: null } }: Props) {
    /***** STATE *****/
    const formRef = useRef<FormikProps<ChangePlanForm>>(null)
    const [changePlanData, setChangePlanData] = useState<ChangePlanData>({
        template: 'configure',
        value: null
    })

    /***** HOOKS *****/
    const { id } = useParams()

    /***** QUERIES *****/
    const [changePlan, { isLoading: isChangePlanMutationLoading }] = useChangePlanMutation()
    const [changePlanPreview, { isLoading: isChangePlanPreviewLoading }] = useChangePlanPreviewMutation({
        fixedCacheKey: 'change-plan-lightbox'
    })

    /***** EFFECTS *****/
    useEffect(() => {
        if (changePlanData.template === 'confirm' && changePlanData.value) {
            const { billing_cycle_id, product_id, config } = changePlanData.value

            changePlanPreview({
                id: Number(id),
                billing_cycle_id,
                product_id,
                config
            })
        }
    }, [changePlanData.template])

    /***** RENDER HELPERS *****/
    const getConditionalProps = () => {
        const getConfirmLightboxLoading = () => {
            if (isChangePlanPreviewLoading) return 'Calculating change plan preview'
            if (isChangePlanMutationLoading) return 'Generating change plan invoice'
            return false
        }

        switch (changePlanData.template) {
            case 'confirm':
                return {
                    title: 'Confirm Change Plan',
                    description: `Once you confirm this submission, an invoice for your plan change will be generated.
                    This invoice must be paid in full in order for the plan change to take affect.`,
                    content: <ChangePlanPreview onBack={() => setChangePlanData((prev) => ({ ...prev, template: 'configure' }))} />,
                    conditions: {
                        state: status,
                        action: () => {
                            close()
                            setChangePlanData({ template: 'configure', value: null })
                        }
                    },
                    actions: [
                        {
                            label: 'Cancel',
                            color: 'error',
                            type: 'button',
                            func: () => {
                                close()
                                setChangePlanData({ template: 'configure', value: null })
                            }
                        },
                        {
                            label: 'Generate Invoice',
                            color: 'primary',
                            type: 'submit',
                            func: () => {
                                if (changePlanData.value) {
                                    const { billing_cycle_id, product_id, config } = changePlanData.value

                                    changePlan({
                                        id: Number(id),
                                        billing_cycle_id,
                                        product_id,
                                        config
                                    })
                                        .then(() => {
                                            setChangePlanData({ template: 'configure', value: null })
                                            return close()
                                        })
                                        .catch(() => {
                                            setChangePlanData({ template: 'configure', value: null })
                                            return close()
                                        })
                                }
                            }
                        }
                    ],
                    loading: getConfirmLightboxLoading()
                }
            case 'configure':
            default:
                return {
                    title: 'Change Plan',
                    description: `You can change your plan in order to update your resource requirements.
                    You will be invoiced on the new plan at the end of your current prepaid period.`,
                    content: <ChangeServicePlanForm form={formRef} setChangePlanData={setChangePlanData} meta={meta} />,
                    conditions: { state: status, action: () => close() },
                    actions: [
                        {
                            label: 'Back',
                            color: 'secondary',
                            type: 'button',
                            func: () => close()
                        },
                        {
                            label: 'Preview Change',
                            color: 'primary',
                            type: 'submit',
                            disabled: false,
                            form: 'changePlanForm'
                        }
                    ]
                }
        }
    }

    /*   RENDER COMPONENT
     *****************************************************/
    return <Lightbox {...getConditionalProps()} />
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
