/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Form, Formik, FormikProps } from 'formik'
import { useEffect, useRef } from 'react'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { serviceAPI, useChangeBillingCycleMutation } from 'api/service'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { defaultCurrency } from 'helpers/utils'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
import { ServiceMetaPayload } from 'api/service/types'
import { Service } from 'models/service'

interface Props {
    id: number
    close: () => void
    meta: ServiceMetaPayload
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function BillingCycleForm({ id, close, meta = { page: '1', sort_by: null } }: Props) {
    const form = useRef<FormikProps<{ billing_cycle_id: number }>>(null)
    const { data: billingCycleData } = serviceAPI.endpoints.billingCycle.useQueryState(id)
    const { service } = serviceAPI.endpoints.services.useQueryState(meta, {
        selectFromResult: ({ data }) => ({
            service: data?.data.find((serviceItem) => serviceItem.id === id) as Service
        })
    })
    const [changeBillingCycle, { isLoading, isSuccess }] = useChangeBillingCycleMutation({ fixedCacheKey: 'billing-cycle-change' })
    const billingCycle = billingCycleData?.map(({ price, billing_cycle }) => ({
        value: billing_cycle.id,
        label: `${billing_cycle.name} - ${defaultCurrency(price)}`
    }))

    useEffect(() => {
        if (isSuccess && form.current?.dirty) {
            form.current.setSubmitting(false)
            form.current.resetForm()
            close()
        }
    }, [form, isLoading, isSuccess])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Formik
            initialValues={{
                billing_cycle_id: service.billing_cycle.id
            }}
            onSubmit={({ billing_cycle_id }) => {
                changeBillingCycle({ id, billing_cycle_id })
            }}
            innerRef={form}
        >
            {() => (
                <Form id={'changeBillingCycleForm'}>
                    <Field
                        label={'Billing Cycle'}
                        name={'billing_cycle_id'}
                        type={'select'}
                        disabled={isLoading}
                        list={billingCycle}
                        component={NXUIForm.SelectField}
                    />
                </Form>
            )}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
