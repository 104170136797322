/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
export const TableTitleStyles = {
    Outer: styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 24px;
        font-size: 18px;

        border-block: 1px solid ${({ theme }) => theme['border--100']};
    `,
    Title: styled.div``,
    Children: styled.div``
}
