/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'
import { Flex } from '../../../components/utilities/Flex'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
export const WebsiteTermsOfServiceStyles = {
    LegalFlexContainer: styled(Flex)`
        background-color: ${({ theme }) => theme['background--100']};
    `
}
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/
