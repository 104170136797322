/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import React, { useRef, useState, useEffect } from 'react'
import { useFormikContext } from 'formik'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/high-res.css'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { RenderInputLabel, RenderErrorIcon, Style } from '../util'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled from 'styled-components'
import * as Theme from '../../../theme/theme'

export const Input = {
    Wrapper: styled.div`
        ${Style.Wrapper}
    `,
    Column: styled.div`
        ${Style.Column}

        &:focus-within {
            outline: none;
            border-color: ${(props) => props.theme[`border--200`]};
        }
    `,
    Base: styled.div`
        .form-control {
            ${Style.Field}
            width: 100%;
            padding-left: 60px;
            border: none;

            ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-xl`])} {
                padding-left: 60px;
            }
        }
        .flag-dropdown {
            border: none;
            border-radius: 6px 0 0 6px;

            .selected-flag {
                padding: 0;
                display: flex;
                justify-content: center;
                .arrow {
                    display: none;
                }

                &:hover,
                &:focus {
                    color: ${(props) => props.theme['black--100']};
                    background-color: ${(props) => props.theme[`background--200`]};
                    border-radius: 6px 0 0 6px;
                }
            }

            &.open {
                border-radius: 6px 0 0 6px;

                .selected-flag {
                    color: ${(props) => props.theme['black--100']};
                    background-color: ${(props) => props.theme[`background--200`]};
                    border-radius: 6px 0 0 6px;
                }
            }
        }
    `,
    Label: styled.label`
        ${Style.Label}
    `
}

const isFunctionComponent = (component) => {
    console.log(typeof component, component)
    return typeof component === 'function' && String(component).includes('return React.createElement')
}

/**********************************************************************************************************
 *   INPUT FIELD
 **********************************************************************************************************/
export const PhoneField = ({ embedded, required, label, field, disabled, autoFormat = true, form: { touched, errors, isSubmitting }, ...props }) => {
    const { setFieldValue, submitForm } = useFormikContext()

    /*   REF
     *****************************************************/
    const inputRef = useRef()

    /*   STATE
     *****************************************************/
    const [hasFocus, setFocus] = useState(false)

    /*   EFFECT
     *****************************************************/
    useEffect(() => {
        if (document.hasFocus() && inputRef.current && inputRef.current.contains(document.activeElement)) {
            setFocus(true)
        }
    }, [])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Input.Wrapper className={props.className}>
            {RenderInputLabel(embedded, required, label, field)}
            <Input.Column focus={hasFocus} error={touched[field.name] && errors[field.name]}>
                <Input.Base
                    as={PhoneInput}
                    country={props.country ?? 'AU'}
                    placeholder={props.placeholder ?? '+61 2222 5555'}
                    value={field.value}
                    onChange={(value) => setFieldValue(field.name, value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            submitForm()
                        }
                    }}
                    autoFormat={autoFormat}
                    onFocus={() => setFocus(true)}
                    onBlur={(e) => {
                        field.onBlur && field.onBlur(e)
                        setFocus(false)
                    }}
                    disabled={isSubmitting || disabled}
                    inputProps={{
                        name: field.name,
                        ref: inputRef,
                        ...props
                    }}
                ></Input.Base>
                {RenderErrorIcon(field.name, touched, errors)}
            </Input.Column>
        </Input.Wrapper>
    )
}
