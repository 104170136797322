/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Dispatch, SetStateAction, createContext, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { THeaderNavItem } from './nav/item'

export const HeaderContext = createContext<{
    setActiveTitle: Dispatch<SetStateAction<PropsFrom<THeaderNavItem>>>
    closeSidebar: () => void
    uninitialized?: boolean
    inSidebar?: boolean
}>({
    setActiveTitle: () => void 0,
    closeSidebar: () => void 0,
    inSidebar: false,
    uninitialized: true
})

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * A hook that will set the active title in the header context based on the condition provided
 */
export const useHeaderContext = () => {
    /***** HOOKS *****/
    const { pathname } = useLocation()
    const { setActiveTitle, uninitialized, closeSidebar, inSidebar } = useContext(HeaderContext)

    /***** EFFECTS *****/
    const useActiveTitleEffect = (condition: boolean, props: PropsFrom<THeaderNavItem>, deps: React.DependencyList) => {
        useEffect(() => {
            if (condition && props) {
                setActiveTitle(props)
            }
        }, [pathname, ...deps])
    }

    /***** RESULTS *****/
    return {
        useActiveTitleEffect,
        closeSidebar,
        uninitialized,
        inSidebar
    }
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
