/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { configureStore } from '@reduxjs/toolkit'

/**********************************************************************************************************
 *   APIs
 **********************************************************************************************************/
import { baseApi } from 'api/base'

/**********************************************************************************************************
 *   SLICES
 **********************************************************************************************************/
import accountSlice from 'store/slices/accountSlice'
import alertSlice from 'store/slices/alertSlice'
import appSlice from 'store/slices/appSlice'
import domainSlice from 'store/slices/domainSlice'
import shopSlice from 'store/slices/shopSlice'
import supportSlice from 'store/slices/supportSlice'

/**********************************************************************************************************
 *   MIDDLEWARES
 **********************************************************************************************************/
import { alert } from 'api/middleware/alert'
import { checkIsAuthenticated } from 'api/middleware/checkIsAuthenticated'
import { appListenerMiddleware } from 'api/middleware/listeners/app'
import { authenticationListenerMiddleware } from 'api/middleware/listeners/authentication'
import { billingListenerMiddleware } from 'api/middleware/listeners/billing'
import { domainListenerMiddleware } from 'api/middleware/listeners/domain'
import { serviceListenerMiddleware } from 'api/middleware/listeners/service'
import { shopListenerMiddleware } from 'api/middleware/listeners/shop'

/**********************************************************************************************************
 *   STORE CONFIGURE
 **********************************************************************************************************/
export const store = configureStore({
    reducer: {
        app: appSlice,
        account: accountSlice,
        domain: domainSlice,
        shop: shopSlice,
        support: supportSlice,
        alerts: alertSlice,
        [baseApi.reducerPath]: baseApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
            .concat(alert, checkIsAuthenticated, baseApi.middleware)
            .prepend(
                appListenerMiddleware.middleware,
                authenticationListenerMiddleware.middleware,
                billingListenerMiddleware.middleware,
                domainListenerMiddleware.middleware,
                serviceListenerMiddleware.middleware,
                shopListenerMiddleware.middleware
            )
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
