/* eslint-disable indent */
/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useState } from 'react'

/**********************************************************************************************************
 *   SHARED COMPONENTS
 **********************************************************************************************************/
import { Button } from '../button'
import { Loader } from '../loader'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled, { css } from 'styled-components'
import * as Theme from '../../theme/theme'

const Toggle = {
    Base: styled.div`
        position: relative;
        display: flex;
        flex-direction: column;
    `,
    Wrapper: styled.div`
        display: inline-flex;
        flex-flow: row wrap;
        gap: 10px;
    `,
    Loader: styled.span`
        position: relative;
        left: 10px;
        top: -1px;
    `,
    Title: styled.div`
        display: inline-flex;
        flex-flow: row nowrap;
        margin-bottom: 4px;
        font-size: 1.2rem;
        color: ${(props) => props.theme[`text--200`]};
        ${Theme.Functions.normalizeText()};
        ${Theme.Functions.setFont('Inter', 700)};
    `,
    Button: styled(Button)`
        flex: 0 1 0;
        width: fit-content;
        margin: 0;

        ${(props) =>
            props.color === 'primary' &&
            css`
                border: 1px solid transparent;
            `}
    `
}

/**********************************************************************************************************
 *   RENDER TOGGLE FIELD
 **********************************************************************************************************/
const ToggleComponent = (props) => {
    const [active, setActive] = useState(props.initialValue || false)

    return (
        <Toggle.Base className={props.className}>
            <Toggle.Title>
                {props.label}{' '}
                {props.loading && (
                    <Toggle.Loader>
                        <Loader.Basic />
                    </Toggle.Loader>
                )}
            </Toggle.Title>
            <Toggle.Wrapper>
                <Toggle.Button
                    type={'button'}
                    color={active ? props.color : 'secondary'}
                    onClick={(e) => {
                        if (active) return
                        setActive(!active)
                        props.toggle.enabled.onClick(e)
                    }}
                    disabled={props.loading || props.disabled}
                >
                    {props.toggle.enabled.label}
                </Toggle.Button>
                <Toggle.Button
                    type={'button'}
                    color={!active ? props.color : 'secondary'}
                    onClick={(e) => {
                        if (!active) return
                        setActive(!active)
                        props.toggle.disabled.onClick(e)
                    }}
                    disabled={props.loading || props.disabled}
                >
                    {props.toggle.disabled.label}
                </Toggle.Button>
            </Toggle.Wrapper>
        </Toggle.Base>
    )
}

ToggleComponent.defaultProps = {
    disabled: false,
    loading: false,
    initialValue: undefined,
    color: 'primary'
}

export { ToggleComponent as Toggle }
