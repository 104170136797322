/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Formik, FormikProps, FormikValues } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { LightboxFooter, LightboxHeader, Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Preset } from './addPreset.styles'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { domainAPI } from 'api/domain'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { DNSRecord, PresetResponse } from 'models/domain'
import { appendMissingDomains, getRemovingRecords, injectMergeFields } from './_addPreset.helpers'

type TWithModifiedRecords = (
    recordInformation: { preset: PresetResponse; records?: DNSRecord[]; domain: string },
    children: (props: { removing: DNSRecord[]; adding: DNSRecord[] } & FormikProps<Record<string, string>>) => React.ReactElement
) => (props: FormikProps<Record<string, string>>) => React.ReactElement
type TRemoveSquirlyBraces = (config: DNSRecord) => DNSRecord
type TConvertMergeFieldToLabel = (field: string) => string
type TInitializeMergeFields = (fields: Array<string>) => Record<string, string>
type TValidate = (values: Record<string, string>) => Record<string, string>
type THelpers = {
    removeSquirlyBraces: TRemoveSquirlyBraces
    convertMergeFieldToLabel: TConvertMergeFieldToLabel
    initializeMergeFields: TInitializeMergeFields
    validate: TValidate
    withModifiedRecords: TWithModifiedRecords
} & Record<string, unknown>

const helpers = {
    removeSquirlyBraces: (config) => ({
        ...config,
        content: config.content.replaceAll(/[{}]/g, '')
    }),
    convertMergeFieldToLabel: (field) => field.replaceAll(/[{}]/g, '').replaceAll(/[-_\s]/g, ' '),
    initializeMergeFields: (fields) => {
        return fields
            .filter((input) => input !== null)
            .reduce((acc, field) => {
                acc[field] = ''
                return acc
            }, {} as Record<string, string>)
    },
    validate: (values) => {
        const errors = {} as Record<string, string>
        Object.entries(values).forEach(([key, value]) => {
            if (!value || value?.length === 0) {
                errors[key] = 'Required'
            }
        })

        return errors
    },
    convertFieldsToArrayOnMergeField: (records: Array<DNSRecord>) => {
        const convertField = (field: string) =>
            String(field) //precautionary cast to string
                .replaceAll(/(\{\{[^}]+\}\})/g, ',$1,') //add commas around merge fields
                .split(',') //split on commas
                .filter((s) => s !== '') //remove empty strings
                .map((s) => (!s.includes('{{') ? s : s.replaceAll(/[_-]/g, ' '))) //replace non-merge-field special chars with white space

        return records.map((record) => ({
            ...record,
            hostname: convertField(record.hostname),
            content: convertField(record.content)
        }))
    },
    withModifiedRecords: ({ records, preset, domain }, children) => {
        return (formikProps) => {
            const config = preset.config?.map((record) => ({ ...record, isPreset: true }))

            const removing = getRemovingRecords({
                formValues: formikProps.values ?? {},
                records: config,
                removingRecords: preset.records_to_overwrite,
                existingRecords: records ?? [],
                domain
            })

            const adding = injectMergeFields(config, formikProps.values).map((record) => appendMissingDomains({ domain, record }))

            return children({ removing, adding, ...formikProps })
        }
    }
} satisfies THelpers

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const PresetForm = ({
    isOpen,
    presetId,
    onConfirm,
    onClose
}: {
    isOpen: boolean
    presetId?: number
    onConfirm: (fields: Record<string, string>) => void
    onClose: () => void
}) => {
    /***** HOOKS *****/
    const { Form } = Preset
    const { id } = useParams()
    const domainId = Number(id)
    const formikRef = useRef<FormikProps<FormikValues>>(null)
    const scrollRef = useRef<HTMLDivElement>(null)

    /***** Queries *****/
    const { preset } = domainAPI.endpoints.dnsPresets.useQuery(domainId, {
        selectFromResult: ({ data, ...rest }) => ({
            ...rest,
            preset: data?.find((p) => p.id === presetId)
        })
    })
    const { records } = domainAPI.endpoints.dnsRecords.useQuery(domainId, {
        selectFromResult: ({ data, ...rest }) => ({
            ...rest,
            records: data?.records
        })
    })
    const { domain } = domainAPI.endpoints.domain.useQuery(domainId, {
        selectFromResult: ({ data, ...rest }) => ({
            ...rest,
            domain: data?.domain ?? ''
        })
    })

    if (!isOpen || !preset) return null

    /***** RENDER *****/
    return (
        <>
            <Preset.Form.Notice>
                <span>Please Note! </span>
                Please be aware that the preset records you are about to add may conflict with some of your existing records
            </Preset.Form.Notice>
            <LightboxHeader title={`Connect to ${preset.name}`} conditions={{ state: isOpen, action: onClose }} />
            <Formik
                initialValues={helpers.initializeMergeFields(preset.input_fields)}
                validate={helpers.validate}
                onSubmit={onConfirm}
                innerRef={formikRef}
            >
                {helpers.withModifiedRecords({ preset, records, domain }, ({ removing, adding }) => (
                    <>
                        {preset.input_fields.length > 0 && (
                            <Form.InputFields>
                                {preset.input_fields.map((field) => (
                                    <Field
                                        key={field}
                                        name={field}
                                        label={helpers.convertMergeFieldToLabel(field)}
                                        type='text'
                                        component={NXUIForm.InputField}
                                    />
                                ))}
                            </Form.InputFields>
                        )}
                        <Form.Record.Wrapper ref={scrollRef}>
                            <OwnRecordsTable scrollRef={scrollRef} title='RECORDS BEING ADDED' records={adding} />
                            <OwnRecordsTable scrollRef={scrollRef} title='RECORDS BEING REMOVED' records={removing} />
                        </Form.Record.Wrapper>
                    </>
                ))}
            </Formik>
            <LightboxFooter
                actions={[
                    {
                        type: 'button',
                        color: 'secondary',
                        label: 'Cancel',
                        func: onClose
                    },
                    {
                        type: 'button',
                        color: 'primary',
                        label: 'Apply',
                        func: () => formikRef.current?.handleSubmit()
                    }
                ]}
            />
        </>
    )
}

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type OwnRecordsTable = React.FC<{
    records: Array<DNSRecord> | undefined
    title: string
    scrollRef: React.RefObject<HTMLDivElement>
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const OwnRecordsTable: OwnRecordsTable = ({ records, title, scrollRef }) => {
    /***** STATE *****/
    const titleRef = useRef<HTMLHeadingElement>(null)
    const [isFixed, setIsFixed] = useState(false)
    const { Form } = Preset

    /***** EFFECTS *****/
    useEffect(() => {
        const isTopTouchingContainerTop = () => {
            const titleRect = titleRef.current?.getBoundingClientRect()
            const containerRect = titleRef.current?.parentElement?.getBoundingClientRect()

            if (!titleRect || !containerRect) return false

            return titleRect.top <= containerRect.top
        }

        //register event
        const handleScroll = () => setIsFixed(isTopTouchingContainerTop())

        scrollRef.current?.addEventListener('scroll', handleScroll)

        //unregister event
        return () => {
            scrollRef.current?.removeEventListener('scroll', handleScroll)
        }
    }, [scrollRef.current])

    /***** RENDER *****/
    if (!Array.isArray(records)) return null
    if (records.length === 0) return null

    const _records = helpers.convertFieldsToArrayOnMergeField(records)

    const renderMergeField = (field: string) => {
        if (!field.includes('{{')) return field

        return <Form.Record.ContentMergeField>{field.replace(/\{\{([^}]+)\}\}/, '$1')}</Form.Record.ContentMergeField>
    }

    return (
        <>
            <Form.Record.Title fixed={isFixed} ref={titleRef}>
                {title}
            </Form.Record.Title>
            <Form.Record.Background>
                <Form.Record.Outer>
                    {_records.map((record, i) => (
                        <Form.Record.Single key={i}>
                            <Form.Record.Label>Type</Form.Record.Label>
                            <Form.Record.ContentType>{record.type}</Form.Record.ContentType>

                            <Form.Record.Label>Hostname</Form.Record.Label>
                            <Form.Record.Content>{record.hostname.map(renderMergeField)}</Form.Record.Content>

                            <Form.Record.Label>Content</Form.Record.Label>
                            <Form.Record.Content>{record.content.map(renderMergeField)}</Form.Record.Content>
                        </Form.Record.Single>
                    ))}
                </Form.Record.Outer>
            </Form.Record.Background>
        </>
    )
}
