/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import styled from 'styled-components'

const Dropdown = {
    Trigger: styled.div`
        position: relative;
        display: inline-flex;
        align-items: center;
        width: fit-content;
    `
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DropdownTrigger = (props) => {
    /*   RENDER
     ****************************************************/
    return (
        <Dropdown.Trigger
            tabIndex={0}
            className={props.className}
            {...props.triggerProps}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    props.trigger(!props.open)
                }
            }}
            onClick={() => props.trigger(!props.open)}
        >
            {props.children}
        </Dropdown.Trigger>
    )
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
DropdownTrigger.defaultProps = {
    className: undefined,
    open: false,
    layerProps: undefined,
    trigger: () => void 0,
    triggerProps: undefined,
    tabIndex: 0
}
