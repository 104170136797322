/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/solid'
import { DateTime } from 'luxon'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { Anchor, Badge, Card, Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Information } from 'containers/domains/domains.styles'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { domainAPI, useDomainListQuery, useToggleTransferLockMutation } from 'api/domain'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setAppActiveInvoice } from 'store/slices/appSlice'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { copyToClipboard, daysRemainsUntilExpired, parseISO } from 'helpers/utils'
import { useAppDispatch } from 'store/hooks'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
import { MetaPayload } from 'models/app'

interface Props {
    meta?: MetaPayload
    setOpenRenewDomainLightbox: Dispatch<SetStateAction<boolean>>
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function InformationComponent({ meta = { page: '1' }, setOpenRenewDomainLightbox }: Props) {
    const { id } = useParams()
    const domainId = Number(id)
    const dispatch = useAppDispatch()
    const { service } = useDomainListQuery(meta, {
        selectFromResult: ({ data, ...rest }) => ({
            ...rest,
            service: data?.data.find((domain) => domain.id === domainId)
        })
    })
    const now = DateTime.now()
    const expiryDate = service?.meta.expiry ? parseISO(service?.meta.expiry) : now
    const expiryDiff = expiryDate.diff(now, 'days').toObject().days ?? 0
    const { currentData: eppCode } = domainAPI.endpoints.eppCode.useQueryState(domainId)
    const { eligibility } = domainAPI.endpoints.eligibilityDetails.useQueryState(domainId, {
        selectFromResult: ({ data }) => ({
            eligibility:
                data?.map(({ field_name, field_value }) => ({
                    information: field_name,
                    value: field_value
                })) ?? []
        })
    })
    const { data: domainRenewal } = domainAPI.endpoints.domainRenewal.useQueryState(domainId)
    const [toggleTransferLock, { isLoading: isTogglingTransferLock }] = useToggleTransferLockMutation()
    const generalData = useMemo(() => {
        const domain = service ? service?.domain.split('.') : ['']

        if (domain[domain.length - 1] === 'au') {
            return [
                {
                    information: 'Renew Domain Name',
                    value:
                        domainRenewal && domainRenewal.errors.length >= 1 ? (
                            <Information.Renewal>
                                <span>{domainRenewal?.errors[0]}</span>
                                <Anchor
                                    color={'primary'}
                                    onClick={() => dispatch(setAppActiveInvoice({ invoice: domainRenewal?.invoice, status: true }))}
                                >
                                    {domainRenewal?.invoice?.id ? `#${domainRenewal?.invoice?.id}` : null}
                                </Anchor>
                            </Information.Renewal>
                        ) : (
                            <>
                                {expiryDiff > -1 && (
                                    <Badge color={'alternative'}>
                                        EXPIRING{' '}
                                        {daysRemainsUntilExpired(service?.meta.expiry) === 'Today'
                                            ? daysRemainsUntilExpired(service?.meta.expiry)
                                            : `IN ${daysRemainsUntilExpired(service?.meta.expiry)}`}
                                    </Badge>
                                )}
                            </>
                        ),
                    action: domainRenewal?.statuses.is_renewable ? (
                        <Anchor color={'primary'} onClick={() => setOpenRenewDomainLightbox(true)}>
                            Renew
                        </Anchor>
                    ) : null
                },
                {
                    information: 'EPP Code',
                    value: eppCode ? (
                        <Information.EPPCode>
                            <span>{eppCode}</span>
                            <button onClick={() => copyToClipboard(eppCode)}>
                                <ClipboardDocumentCheckIcon />
                            </button>
                        </Information.EPPCode>
                    ) : null
                }
            ]
        }

        return [
            {
                information: 'Renew Domain Name',
                value:
                    domainRenewal && domainRenewal.errors.length >= 1 ? (
                        <Information.Renewal>
                            <span>{domainRenewal?.errors[0]}</span>
                            <Anchor
                                color={'primary'}
                                onClick={() => dispatch(setAppActiveInvoice({ invoice: domainRenewal?.invoice, status: true }))}
                            >
                                {domainRenewal?.invoice?.id ? `#${domainRenewal?.invoice?.id}` : null}
                            </Anchor>
                        </Information.Renewal>
                    ) : (
                        <>
                            {expiryDiff > -1 && (
                                <Badge color={'alternative'}>
                                    EXPIRING{' '}
                                    {daysRemainsUntilExpired(service?.meta.expiry) === 'Today'
                                        ? daysRemainsUntilExpired(service?.meta.expiry)
                                        : `IN ${daysRemainsUntilExpired(service?.meta.expiry)}`}
                                </Badge>
                            )}
                        </>
                    ),
                action: domainRenewal?.statuses.is_renewable ? (
                    <Anchor color={'primary'} onClick={() => setOpenRenewDomainLightbox(true)}>
                        Renew
                    </Anchor>
                ) : null
            },
            {
                information: 'Transfer Lock',
                value: (
                    <NXUIForm.Switch
                        checked={!!service?.meta.transfer_lock}
                        disabled={service?.status !== 'active' || isTogglingTransferLock}
                        onClick={() => {
                            if (service) {
                                toggleTransferLock({
                                    domain: {
                                        ...service,
                                        meta: {
                                            ...service?.meta,
                                            transfer_lock: !service?.meta.transfer_lock
                                        }
                                    },
                                    meta
                                })
                            }
                        }}
                    />
                )
            },
            {
                information: 'EPP Code',
                value: eppCode ? (
                    <Information.EPPCode>
                        <span>{eppCode}</span>
                        <button onClick={() => copyToClipboard(eppCode)}>
                            <ClipboardDocumentCheckIcon />
                        </button>
                    </Information.EPPCode>
                ) : null
            }
        ]
    }, [eppCode, service, domainRenewal, isTogglingTransferLock, domainId])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Information.Base>
            <Card
                table={{
                    render: {
                        columns: () => {
                            const headers = [
                                {
                                    Header: 'General Information',
                                    accessor: 'information'
                                },
                                {
                                    accessor: 'value'
                                }
                            ]
                            if (domainRenewal?.invoice) {
                                return headers
                            }

                            return [
                                ...headers,
                                {
                                    Header: '',
                                    accessor: 'action',
                                    align: 'right'
                                }
                            ]
                        },
                        data: () => generalData
                    }
                }}
            />
            {eligibility.length > 0 ? (
                <Card
                    table={{
                        render: {
                            columns: () => [
                                {
                                    Header: 'Eligibility Information',
                                    accessor: 'information'
                                },
                                {
                                    accessor: 'value'
                                },
                                {
                                    Header: '',
                                    accessor: 'action',
                                    align: 'right'
                                }
                            ],
                            data: () => eligibility
                        }
                    }}
                />
            ) : null}
        </Information.Base>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { InformationComponent as Information }
