/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import PropTypes from 'prop-types'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { ArrowRightIcon } from '@heroicons/react/24/solid'

/**********************************************************************************************************
 *   STYLING
 **********************************************************************************************************/
import styled from 'styled-components'
import * as Theme from '../../theme/theme'

const Breadcrumbs = {
    Container: styled.div`
        display: flex;
        align-items: center;
        min-height: 52px;
        margin: 0 0 8px;
        color: ${(props) => props.theme[`text--100`]};
        ${Theme.Functions.setFont('Inter', 500)};
    `,
    Icon: styled.div`
        width: 22px;
        margin-right: 12px;
        color: ${(props) => props.theme[`icon--100`]};
    `,
    Crumbs: styled.div`
        display: flex;
        flex-direction: row;
        font-size: 1.4rem;
        text-transform: capitalize;

        > span: {
            margin-right: 16px;
        }
    `,
    Break: styled.span`
        margin: 0 10px;
    `
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const BreadcrumbsComponent = ({ className, icon, location }) => {
    const locationArray = location.pathname ? location.pathname.split('/') : undefined

    function renderCrumbs() {
        if (locationArray) {
            locationArray.shift() // Remove first entry as its always an empty string when a valid location is provided.
            Number(locationArray[locationArray.length - 1]) ? locationArray.splice(-1, 1) : locationArray // Remove last entry if its a number to clean up the next step

            let crumbs = locationArray.map((crumb, index) => {
                return !Number(crumb) && index !== locationArray.length - 1 ? (
                    <div key={crumb}>
                        <span>{crumb.replace('-', ' ')}</span>
                        <Breadcrumbs.Break children={'/'} />
                    </div>
                ) : !Number(crumb) ? (
                    <span key={crumb}>{crumb.replace('-', ' ')}</span>
                ) : (
                    ''
                )
            })
            return crumbs
        }
        return ''
    }

    return (
        <Breadcrumbs.Container className={`${className ? ` ${className}` : ``}`}>
            <Breadcrumbs.Icon>{icon}</Breadcrumbs.Icon>
            <Breadcrumbs.Crumbs>{renderCrumbs()}</Breadcrumbs.Crumbs>
        </Breadcrumbs.Container>
    )
}

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
BreadcrumbsComponent.propTypes = {
    className: PropTypes.string,
    location: PropTypes.object,
    icon: PropTypes.element
}

BreadcrumbsComponent.defaultProps = {
    icon: <ArrowRightIcon />
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { BreadcrumbsComponent as Breadcrumbs }
