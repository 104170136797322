import { FieldDatalist } from 'containers/shop/components/datalist.component'
import { FieldDescriptionText } from 'containers/shop/components/description.component'
import { fieldDefaultProps, IFieldInput } from 'containers/shop/components/form.d'
import { FieldInput } from 'containers/shop/components/input.component'
import { FieldLabel } from 'containers/shop/components/label.component'
import { FieldWrapper } from 'containers/shop/components/wrapper.component'
import { useRef, useState } from 'react'

/**********************************************************************************************************
 *   INPUT FIELD
 **********************************************************************************************************/
export const InputPrefabStandard = ({ id, name, label, list, ...props }: IFieldInput) => {
    const { className, disabled } = props
    const [touched, setTouched] = useState(false)
    const inputRef = useRef<HTMLInputElement | null>(null)

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <FieldWrapper className={className}>
            <FieldLabel htmlFor={id} label={label} />
            <FieldInput
                name={name}
                ref={inputRef}
                id={id}
                list={list ?? ''}
                onBlur={() => setTouched(true)}
                touched={touched || props.error}
                {...props}
                disabled={disabled}
            />
            {props.description && <FieldDescriptionText>{props.description}</FieldDescriptionText>}
            {props.options && <FieldDatalist name={list ?? ''} options={props.options} />}
        </FieldWrapper>
    )
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
InputPrefabStandard.defaultProps = fieldDefaultProps
