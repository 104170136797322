/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import React from 'react'
import PropTypes from 'prop-types'

import { useScrollPosition } from '../../helpers/hooks'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import { Scroll } from './scroll.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ScrollComponent = (props) => {
    const [scrollRef, onScroll, scrollPositions] = useScrollPosition()

    return (
        <Scroll.Base ref={scrollRef} {...props} onScroll={onScroll} positions={scrollPositions} direction={props.direction}>
            {props.children}
        </Scroll.Base>
    )
}

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
ScrollComponent.propTypes = {
    key: PropTypes.string,
    as: PropTypes.string,
    className: PropTypes.string,
    direction: PropTypes.string,
    children: PropTypes.any
}

ScrollComponent.defaultProps = {
    as: 'div',
    direction: 'vertical'
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { ScrollComponent as Scroll }
