/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useState } from 'react'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TUseToggle = (defaultValue?: boolean) => [value: boolean, toggleValue: () => void, setValue: Dispatch<SetStateAction<boolean>>]

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useToggle: TUseToggle = (defaultValue) => {
    /***** STATE *****/
    const [value, setValue] = useState(!!defaultValue)

    /***** FUNCTIONS *****/
    const toggle = useCallback(() => {
        setValue((x) => !x)
    }, [])

    /***** RENDER *****/
    return [value, toggle, setValue]
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
