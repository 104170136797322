/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'

/**********************************************************************************************************
 *   APIs
 **********************************************************************************************************/
import { accountAPI } from 'api/account'
import { baseApi } from 'api/base'
import { billingAPI } from 'api/billing'
import { domainAPI } from 'api/domain'
import { serviceAPI } from 'api/service'
import { shopAPI } from 'api/shop'
import { supportAPI } from 'api/support/support'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { logoutReset } from 'store/slices/appSlice'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import globalCookies from 'helpers/cookies'

/**********************************************************************************************************
 *   INTERFACE & TYPES
 **********************************************************************************************************/
import { Message } from 'models/app'
import {
    IAuthorisedAccount,
    ISession,
    IUserAuthForm,
    IUserResetPassword,
    IUserSignUp,
    IUserTwoFactorMethod,
    TTwoFactorMethodList
} from 'models/authentication'

/**********************************************************************************************************
 *   APP API
 **********************************************************************************************************/
export const authAPI = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getSession: builder.query<ISession, void>({
            query: () => 'client/session',
            transformResponse: (baseQueryReturnValue: { data: ISession }) => baseQueryReturnValue.data,
            providesTags: ['Session']
        }),
        getTwoFactor: builder.query<TTwoFactorMethodList, void>({
            query: () => 'client/auth/two-factor',
            transformResponse: (baseQueryReturnValue: { data: TTwoFactorMethodList }) => baseQueryReturnValue.data
        }),
        getAuthorisedAccounts: builder.query<Array<IAuthorisedAccount>, void>({
            query: () => 'client/selected-account',
            transformResponse: (baseQueryReturnValue: { data: Array<IAuthorisedAccount> }) => baseQueryReturnValue.data,
            providesTags: ['Accounts']
        }),
        selectTwoFactorMethod: builder.query<Array<IUserTwoFactorMethod>, string>({
            query: (method) => `client/auth/two-factor?type=${method}`,
            providesTags: ['Selected-Two-Factor']
        }),
        login: builder.mutation<[], IUserAuthForm>({
            query: (credentials) => ({
                url: 'client/login',
                method: 'POST',
                body: credentials
            })
        }),
        verifyTwoFactorCode: builder.mutation<[], { code: string | null; type: string }>({
            query: (values) => ({
                url: `client/auth/two-factor?type=${values.type}`,
                method: 'POST',
                body: {
                    code: values.code
                }
            }),
            invalidatesTags: ['Session']
        }),
        selectAccount: builder.mutation<{ data: []; messages: Array<Message> }, number | null>({
            query: (account) => ({
                url: `client/selected-account/${account}`,
                method: 'POST'
            })
        }),
        recoverPassword: builder.mutation<[], { email: string }>({
            query: (credentials) => ({
                url: 'client/forgot-password',
                method: 'POST',
                body: credentials
            })
        }),
        resetPassword: builder.mutation<[], IUserResetPassword>({
            query: (credentials) => ({
                url: 'client/reset-password',
                method: 'POST',
                body: credentials
            })
        }),
        registerNewUser: builder.mutation<[], IUserSignUp>({
            query: (body) => ({
                url: 'client/register',
                method: 'POST',
                body
            })
        }),
        acceptInvitation: builder.query<{ data: { email: string }; messages: Array<Message> }, string | undefined>({
            query: (token) => `client/invitation/accept/${token}`
        }),
        logout: builder.mutation<[], void>({
            async queryFn(_arg, _queryApi, _extraOptions, baseQuery) {
                const logout = (await baseQuery({
                    url: 'client/logout',
                    method: 'POST'
                })) as QueryReturnValue<{ data: []; meta: FetchBaseQueryMeta }, FetchBaseQueryError, FetchBaseQueryMeta>

                if (logout.error) return { error: logout.error }

                _queryApi.dispatch(authAPI.util.resetApiState())
                _queryApi.dispatch(accountAPI.util.resetApiState())
                _queryApi.dispatch(domainAPI.util.resetApiState())
                _queryApi.dispatch(serviceAPI.util.resetApiState())
                _queryApi.dispatch(supportAPI.util.resetApiState())
                _queryApi.dispatch(shopAPI.util.resetApiState())
                _queryApi.dispatch(billingAPI.util.resetApiState())
                _queryApi.dispatch(logoutReset())

                globalCookies.remove('XSRF-TOKEN')
                globalCookies.remove('mercury_session')

                return {
                    data: logout.data.data
                }
            }
        })
    })
})

export const {
    useGetSessionQuery,
    useLoginMutation,
    useGetTwoFactorQuery,
    useVerifyTwoFactorCodeMutation,
    useGetAuthorisedAccountsQuery,
    useSelectTwoFactorMethodQuery,
    useSelectAccountMutation,
    useRecoverPasswordMutation,
    useResetPasswordMutation,
    useRegisterNewUserMutation,
    useAcceptInvitationQuery,
    useLogoutMutation
} = authAPI
