/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { XMarkIcon } from '@heroicons/react/24/solid'
import { Form, Formik } from 'formik'
import { Theme } from 'nxui/src'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import toast from 'react-hot-toast'
import styled from 'styled-components'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { appAPI } from 'api/app'
import { useGeneratePaymentMethodTokenMutation } from 'api/billing'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { useApplyAccountCreditToInvoice } from 'containers/billing/utils'
import { useAppDispatch, useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   INTERFACE & TYPES
 **********************************************************************************************************/
import { PaymentMethod } from 'models/billing'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const IframeContainer = {
    Page: styled.div`
        position: absolute;
        inset: 0;
        z-index: 100000;
    `,
    Background: styled.div`
        background-color: black;
        opacity: 0.6;
        position: absolute;
        inset: 0;
    `,
    Modal: styled.div`
        border-radius: 10px;
        width: 750px;
        height: 720px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            width: 340px;
            height: 500px;
        }

        iframe {
            position: relative;
            z-index: 1;
        }
    `,
    ModalCloseButton: styled.button`
        color: black;
        position: absolute;
        top: 20px;
        right: 20px;
    `
}

interface IframeProps {
    srcDoc: string
    onClose: () => void
    invoiceId: string
    setTemplate: (template: 'invoice' | 'success' | 'redirect') => void
}

function Iframe({ srcDoc, onClose, invoiceId, setTemplate }: IframeProps) {
    const dispatch = useAppDispatch()

    useEffect(() => {
        function listenForPaymentComplete(event: MessageEvent<ExpectedAny>) {
            if (!event.isTrusted || event.data?.type !== 'payment') {
                return
            }

            const { message, status } = event.data?.data || {}
            const isSuccess = status === 'payment_success'

            toast.custom(
                {
                    // @ts-ignore
                    detail: message,
                    type: isSuccess ? 'SUCCESS' : 'ERROR'
                },
                {
                    duration: 5000
                }
            )

            if (isSuccess) {
                setTemplate('success')
                dispatch(appAPI.util.invalidateTags(['Invoices', { type: 'Invoice', id: invoiceId }, 'Services', 'Service', 'Payment-Methods-Data']))
            }

            onClose()
        }

        window.addEventListener('message', listenForPaymentComplete)

        return () => {
            window.removeEventListener('message', listenForPaymentComplete)
        }
    }, [])

    const windowWidth = window.innerWidth

    return (
        <IframeContainer.Page>
            <IframeContainer.Background />
            <IframeContainer.Modal>
                <IframeContainer.ModalCloseButton onClick={onClose}>
                    <XMarkIcon height={20} width={20} />
                </IframeContainer.ModalCloseButton>
                <iframe
                    srcDoc={srcDoc}
                    id='tokenFormIframe'
                    title='Payment Form'
                    width={windowWidth > 500 ? '650' : '300'}
                    height={windowWidth > 500 ? '620' : '400'}
                />
            </IframeContainer.Modal>
        </IframeContainer.Page>
    )
}

interface TokenTypeFormProps {
    accountCreditToApply: number
    selectedPaymentMethod?: PaymentMethod
    setTemplate: (template: 'invoice' | 'success' | 'redirect') => void
}

export function TokenTypeForm({ accountCreditToApply, selectedPaymentMethod, setTemplate }: TokenTypeFormProps) {
    const [isIframeShowing, setIsIframeShowing] = useState(false)

    const appActiveInvoice = useAppSelector((state) => state.app.appActiveInvoice)

    const [generatePaymentMethodToken, { data: generatePaymentMethodTokenData }] = useGeneratePaymentMethodTokenMutation()

    const { applyAccountCreditToInvoice } = useApplyAccountCreditToInvoice()

    return (
        <>
            <Formik
                initialValues={{}}
                onSubmit={async () => {
                    // TODO: handle if credit is being applied first, similar to linkTypeForm

                    if (!appActiveInvoice?.invoice?.id || !selectedPaymentMethod?.id) return

                    const { isInvoiceStillUnpaid, isSuccess } = await applyAccountCreditToInvoice(appActiveInvoice.invoice.id, accountCreditToApply)

                    if (isSuccess) {
                        if (isInvoiceStillUnpaid) {
                            generatePaymentMethodToken({
                                paymentMethodId: selectedPaymentMethod?.id,
                                payload: { type: 'checkout', invoice_id: appActiveInvoice.invoice.id }
                            })
                                .unwrap()
                                .then(() => {
                                    setIsIframeShowing(true)
                                    return
                                })
                                .catch(() => {
                                    // Catch
                                })
                        } else {
                            setTemplate('success')
                        }
                    }
                }}
            >
                <Form id='tokenTypeForm'></Form>
            </Formik>
            {isIframeShowing &&
                createPortal(
                    <Iframe
                        onClose={() => setIsIframeShowing(false)}
                        srcDoc={generatePaymentMethodTokenData?.data.render || ''}
                        invoiceId={typeof appActiveInvoice?.invoice?.id === 'number' ? appActiveInvoice.invoice.id.toString() : ''}
                        setTemplate={setTemplate}
                    />,
                    document.body
                )}
        </>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
