export const COLORS = ['primary', 'secondary', 'confirm', 'error', 'warning', 'info', 'white', 'black']

export const SIZES = ['small', 'medium', 'large']

export const POSITIONS = ['left', 'right', 'top', 'bottom', 'center']

export const EFontWeights = {
    Light: '300',
    Normal: '400',
    Semibold: '500',
    Bold: '600',
    Black: '700'
}
