/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Dialog } from '@headlessui/react'
import styled from 'styled-components'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/utilities/Flex'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Theme } from 'nxui/src'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
export const LightboxHeaderStyles = {
    Wrapper: styled.header`
        padding: 16px;
        border-bottom: 1px solid ${({ theme }) => theme['border--100']};
        background-color: ${({ theme }) => theme['background--000']};

        ${({ theme }) => Theme.Functions.mediaBreakUp(theme['breakpoints-sm'])} {
            padding: 24px 64px 24px 24px;
        }
    `,

    Description: styled(Dialog.Description).attrs({ as: 'p' })`
        margin-top: 8px;
        font-size: 1.4rem;
        line-height: 1.5;
        color: ${({ theme }) => theme['text--100']};

        ${Theme.Functions.setFont('Inter', 400)};
    `,

    CloseButtonWrapper: styled(Flex)`
        position: absolute;
        top: 0;
        right: 0;
        height: 36px;
        width: 40px;
        margin: 0 auto;
        flex-shrink: 0;
        border-radius: 100%;
        padding: 16px 16px 0 0;
        color: ${({ theme }) => theme['icon--100']};

        ${Theme.Functions.outline('none')};
        ${Theme.Functions.animation('all')};
        ${({ theme }) => Theme.Functions.mediaBreakUp(theme['breakpoints-sm'])} {
            margin: 0;
            height: 44px;
            width: 44px;
        }

        &:hover {
            color: ${(props) => props.theme[`error--100`]};
        }
    `
}
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/
