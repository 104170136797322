/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Button } from '../../button'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import { Cart } from './cart.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const CartFooter = (props) => {
    /*   RENDER
     ****************************************************/
    return (
        <Cart.Footer className={props.className}>
            <Button color={'secondary'} onClick={props.close}>
                Close Cart
            </Button>
            <Button color={'primary'} onClick={props.checkout} disabled={props.products && Boolean(props.products?.length <= 0)}>
                Checkout
            </Button>
        </Cart.Footer>
    )
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
CartFooter.defaultProps = {
    className: undefined
}
