/* eslint-disable indent */
/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { PencilIcon, ShoppingCartIcon, TrashIcon, XCircleIcon } from '@heroicons/react/24/solid'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import * as Theme from '../../../theme/theme'

import { EFontWeights } from '../../../models/generic'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
export const Cart = {
    Wrapper: styled.aside`
        position: fixed;
        right: -380px;
        top: 80px;
        opacity: 0;
        visibility: hidden;
        ${Theme.Functions.animation('all', 200)};

        ${(props) =>
            props.open &&
            css`
                right: 20px;
                opacity: 1;
                visibility: visible;
            `}
    `,
    Base: styled.div`
        z-index: 2;
        position: relative;
        display: block;
        height: calc(100vh - 160px);
        width: 380px;
        border-radius: 12px;
        background-color: ${(props) => props.theme['background--000']};
        box-shadow: 0 0 3.1px ${(props) => Theme.Functions.hexToRGBA(props.theme[`background--400`], 0.15)},
            0 0 6.6px ${(props) => Theme.Functions.hexToRGBA(props.theme[`background--400`], 0.093)},
            0 0 11px ${(props) => Theme.Functions.hexToRGBA(props.theme[`background--400`], 0.058)},
            0 0 17px ${(props) => Theme.Functions.hexToRGBA(props.theme[`background--400`], 0.037)},
            0 0 26.2px ${(props) => Theme.Functions.hexToRGBA(props.theme[`background--400`], 0.024)},
            0 0 50px ${(props) => Theme.Functions.hexToRGBA(props.theme[`background--400`], 0.017)};
    `,
    Background: styled.div`
        z-index: 1;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        opacity: 0;
        visibility: hidden;
        background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`background--400`], 0.35)};
        ${Theme.Functions.animation('all', 200)}

        ${(props) =>
            props.open &&
            css`
                ${Theme.Functions.mediaBreakDown(props.theme[`--break-md`])} {
                    opacity: 1;
                    visibility: visible;
                }
            `}
    `,
    List: {
        Base: styled(motion.div)`
            position: relative;
            margin: 20px;
            overflow: auto;
            height: calc(100vh - 425px);
        `,
        Products: styled.div`
            display: flex;
            flex-direction: column;
        `
    },
    Header: {
        Base: styled.div`
            position: relative;
        `,
        Title: styled.h2`
            padding: 20px;
            font-size: 2.4rem;
            color: ${(props) => props.theme['text--300']};
            ${Theme.Functions.normalizeText()};
            ${Theme.Functions.setFont(`'Inter', sans-serif`, EFontWeights.Bold)};
        `,
        Close: styled(XCircleIcon)`
            cursor: pointer;
            position: absolute;
            top: 15px;
            right: 15px;
            width: 20px;
            height: 20px;
            color: ${(props) => props.theme['text--100']};
            ${Theme.Functions.animation('color', 200)};

            &:hover {
                color: ${(props) => props.theme['error--100']};
            }
        `
    },
    Footer: styled.div`
        position: absolute;
        bottom: 0;
        display: inline-flex;
        flex-flow: row nowrap;
        gap: 10px;
        justify-content: space-between;
        width: fill-available;
        margin: 15px 20px;

        > button {
            width: 100%;
            margin: 0;
        }
    `,
    Product: {
        Base: styled(motion.div)`
            display: inline-flex;
            flex-flow: column nowrap;
            width: 100%;
            padding: 10px 0;
            margin-bottom: 10px;
            border-bottom: 1px solid ${(props) => props.theme['border--100']};

            &:last-of-type {
                border-bottom: none;
            }
        `,
        Row: styled.div`
            position: relative;
            display: inline-flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            width: 100%;
            height: 100%;
        `,
        Column: styled.div`
            position: relative;
            display: inline-flex;
            flex-flow: column nowrap;
            width: 100%;
            height: 100%;
        `,
        Header: styled.div`
            width: 100%;
            margin-bottom: 10px;

            > h2 {
                font-size: 1.4rem;
                color: ${(props) => props.theme['text--300']};
                line-height: 1;
                ${Theme.Functions.setFont(`'Inter', sans-serif`, EFontWeights.Bold)};
            }
            > h4 {
                font-size: 1.2rem;
                text-transform: capitalize;
                color: ${(props) => props.theme['text--100']};
                ${Theme.Functions.setFont(`'Inter', sans-serif`, EFontWeights.Semibold)};
            }
        `,
        Content: styled.div`
            display: flex;
            flex-flow: column nowrap;
            width: 100%;
        `,
        Footer: styled.div``,
        Addons: styled.div`
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
        `,
        Price: styled.div`
            position: absolute;
            top: 0;
            right: 0;
            display: inline-flex;
            flex-flow: column nowrap;

            h2 {
                font-size: 1.4rem;
                color: ${(props) => props.theme['text--300']};
                line-height: 1;
                ${Theme.Functions.setFont(`'Inter', sans-serif`, EFontWeights.Bold)};
            }

            p {
                text-align: end;
                margin-top: 5px;
                font-size: 1.2rem;
                color: ${(props) => props.theme['text--100']};
                ${Theme.Functions.normalizeText()};
                ${Theme.Functions.setFont(`'Inter', sans-serif`, EFontWeights.Semibold)};
            }
        `,
        Remove: styled(TrashIcon)`
            cursor: pointer;
            width: 20px;
            color: ${(props) => props.theme['icon--100']};
            ${Theme.Functions.animation('color', 200)};

            &:hover {
                color: ${(props) => props.theme['error--100']};
            }
        `,
        Edit: styled(PencilIcon)`
            cursor: pointer;
            width: 20px;
            color: ${(props) => props.theme['icon--100']};
            ${Theme.Functions.animation('color', 200)};

            &:hover {
                color: ${(props) => props.theme['info--100']};
            }
        `,
        Actions: styled.div`
            display: inline-flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            gap: 20px;
        `
    },
    Addon: {
        Base: styled.div`
            width: 100%;
        `,
        Title: styled.div`
            > h2 {
                font-size: 1.2rem;
                color: ${(props) => props.theme['text--300']};
                ${Theme.Functions.normalizeText()};
                ${Theme.Functions.setFont(`'Inter', sans-serif`, EFontWeights.Bold)};
            }
            > h4 {
                margin-top: 5px;
                font-size: 1.2rem;
                color: ${(props) => Theme.Functions.hexToRGBA(props.theme['text--300'], 0.5)};
                ${Theme.Functions.normalizeText()};
                ${Theme.Functions.setFont(`'Inter', sans-serif`, EFontWeights.Bold)};
            }
        `,
        Price: styled.div``
    },
    Empty: {
        Base: styled.div`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 240px;
            padding: 20px;
            font-size: 2rem;
            border-radius: 12px;
            color: ${(props) => props.theme['text--100']};
            ${Theme.Functions.normalizeText()};
            ${Theme.Functions.setFont(`'Inter', sans-serif`, EFontWeights.Bold)};
        `,
        Icon: styled(ShoppingCartIcon)`
            width: 48px;
            margin-bottom: 10px;
            color: ${(props) => props.theme['icon--100']};
        `
    },
    Summary: {
        Base: styled.div`
            position: relative;
            bottom: 15px;
            padding: 20px;
        `,
        Total: styled.div`
            display: inline-flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            width: 100%;
            padding-top: 10px;
            border-top: 1px solid ${(props) => props.theme['border--100']};

            h2 {
                font-size: 1.8rem;
                color: ${(props) => props.theme['text--300']};
                ${Theme.Functions.setFont(`'Inter', sans-serif`, EFontWeights.Bold)};
            }

            p {
                font-size: 1.8rem;
                color: ${(props) => props.theme['text--300']};
                ${Theme.Functions.setFont(`'Inter', sans-serif`, EFontWeights.Bold)};
            }
        `,
        Description: styled.div`
            margin-top: 10px;
            font-size: 1.2rem;
            color: ${(props) => props.theme['text--100']};
            ${Theme.Functions.normalizeText()};
            ${Theme.Functions.setFont(`'Inter', sans-serif`, EFontWeights.Semibold)};
        `
    }
}
