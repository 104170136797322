/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TProps = {
    all?: number
    top?: number
    right?: number
    bottom?: number
    left?: number
    block?: number
    inline?: number
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Padding is a utility component that allows you to add padding to a component without needing to create a new class or Styled component.
 */
const _Padding = styled.div<TProps>`
    ${({ all }) => all && `padding: ${all}px;`}
    ${({ top }) => top && `padding-top: ${top}px;`}
    ${({ right }) => right && `padding-right: ${right}px;`}
    ${({ bottom }) => bottom && `padding-bottom: ${bottom}px;`}
    ${({ left }) => left && `padding-left: ${left}px;`}
    ${({ block }) => block && `padding-block: ${block}px;`}
    ${({ inline }) => inline && `padding-inline: ${inline}px;`}
`

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Margin is a utility component that allows you to add margin to a component without needing to create a new class or Styled component.
 */
const _Margin = styled.div<TProps>`
    ${({ all }) => all && `margin: ${all}px;`}
    ${({ top }) => top && `margin-top: ${top}px;`}
    ${({ right }) => right && `margin-right: ${right}px;`}
    ${({ bottom }) => bottom && `margin-bottom: ${bottom}px;`}
    ${({ left }) => left && `margin-left: ${left}px;`}
    ${({ block }) => block && `margin-block: ${block}px;`}
    ${({ inline }) => inline && `margin-inline: ${inline}px;`}
`

export const Padding = Object.assign(_Padding, { Margin: _Margin })
