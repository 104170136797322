/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { Card, Tabs, Theme } from 'nxui/src'

/**********************************************************************************************************
 *   INTERFACES / ENUMS
 **********************************************************************************************************/
import { Weight } from 'models/enums'

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
export const Billing = {
    Base: styled(Tabs.Prefab)`
        background-color: ${(props) => props.theme['white--100']};
        border-radius: 16px;
        margin-bottom: 1rem;

        > nav {
            padding: 20px 20px 0;
        }

        > div {
            &:first-child {
                ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
                    padding: 1rem 1rem 0 1rem;
                }
            }

            > section {
                margin-bottom: 0;
                box-shadow: none;
                border-top-left-radius: 0;
                border-top-left-radius: 0;
            }
        }
    `,
    Overview: styled(motion.div)`
        width: 100%;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-xl'])} {
            margin-bottom: 1rem;
        }
    `
}

export const PaymentMethods = {
    Card: styled(Card)`
        width: 100%;
        margin-top: 20px;
        margin-bottom: 0;
        border-radius: 0 0 16px 16px;
        box-shadow: none;

        > header {
            border-radius: 0;
        }
    `,
    Footer: styled.div`
        display: flex;
        justify-content: flex-end;
        padding: 10px 10px 10px 0;
        border-top: 1px solid ${(props) => props.theme['gray--100']};
    `
}

export const Payment = {
    Base: styled.div`
        margin-top: 1rem;
        font-size: 1.4rem;
        background-color: ${(props) => props.theme['white--100']};
        border-radius: 16px;
        border: 1px solid ${(props) => props.theme['gray--100']};
        ${Theme.Functions.setFont('Inter', Weight.Medium)};
    `,
    Container: styled.div`
        width: 100%;
        padding: 18px 24px;
        display: flex;
        justify-content: space-between;

        &:first-child {
            border-bottom: 1px solid ${(props) => props.theme['gray--100']};
        }
    `,
    Title: styled.div`
        color: ${(props) => props.theme['text--100']};
    `
}

export const Method = {
    Container: styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
    `,
    Type: styled.div`
        svg {
            height: 16px;
            width: 40px;
        }
    `,
    Identifier: styled.div`
        color: ${(props) => props.theme['text--100']};
    `
}

export const Actions = styled.div`
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
        flex-direction: column;
        gap: 10px;
    }

    > button {
        width: 47%;
        justify-content: center;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            width: 100%;
            margin: 0;
        }
    }
`

export const Invoices = {
    Base: styled(Card)`
        ${(props) =>
            props.title === '' &&
            css`
                border-top-left-radius: 0;
                border-top-left-radius: 0;
            `};

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-xl'])} {
            margin-top: 1rem;
        }
    `,
    Text: styled.span`
        color: ${(props) => props.theme['black--100']};
    `
}

export const CreditTransactions = {
    Card: styled(Card)`
        width: 100%;
        margin-bottom: 0;
        border-radius: 0 0 16px 16px;
        box-shadow: none;

        > header {
            border-radius: 0;
        }
    `,
    Credit: styled.p`
        font-size: 1.4rem;
        font-weight: ${Weight.SemiBold};
        color: ${(props) => props.theme['confirm--100']};
    `,
    Debit: styled.p`
        font-size: 1.4rem;
        font-weight: ${Weight.SemiBold};
        color: ${(props) => props.theme['error--100']};
    `
}

export const Transactions = {
    Card: styled(Card)`
        width: 100%;
        margin-bottom: 0;
        border-radius: 0 0 16px 16px;
        box-shadow: none;

        > header {
            border-radius: 0;
        }
    `,
    Text: styled.span`
        color: ${(props) => props.theme['black--100']};
    `
}

export const AccountCredit = {
    Container: styled.div`
        margin-bottom: 25px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 20px;
    `
}
