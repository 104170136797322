/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { CheckIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Button } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { authAPI } from 'api/authentication'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { useAppDispatch, useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { SignUp } from 'containers/authentication/authentication.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const AuthRegisterSuccess = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const {
        appSession: { authenticated }
    } = useAppSelector((state) => state.app)
    const { isFetching } = authAPI.endpoints.getSession.useQueryState()

    /*   RENDER COMPONENT
     *****************************************************/
    if (authenticated) {
        return (
            <SignUp.Registration.Success>
                <SignUp.Registration.Icon>
                    <CheckIcon />
                </SignUp.Registration.Icon>
                <SignUp.Registration.Title>Invitation to manage account accepted.</SignUp.Registration.Title>
                <Button
                    color='primary'
                    disabled={isFetching}
                    loading={isFetching && 'Redirecting to Dashboard'}
                    onClick={async () => {
                        const session = await dispatch(authAPI.endpoints.getSession.initiate(undefined, { forceRefetch: true })).unwrap()

                        if (session.authenticated && session.current_account) {
                            navigate('/')
                        }
                    }}
                >
                    Continue to Dashboard
                </Button>
            </SignUp.Registration.Success>
        )
    }

    return (
        <SignUp.Registration.Success>
            <SignUp.Registration.Icon>
                <CheckIcon />
            </SignUp.Registration.Icon>
            <SignUp.Registration.Title>Successfully Registered!</SignUp.Registration.Title>
            <SignUp.Registration.Description>You can now login and use your new account!</SignUp.Registration.Description>
            <Button
                color='primary'
                disabled={isFetching}
                loading={isFetching && 'Redirecting to Dashboard'}
                onClick={async () => {
                    const session = await dispatch(authAPI.endpoints.getSession.initiate(undefined, { forceRefetch: true })).unwrap()

                    if (session.authenticated && session.current_account) {
                        navigate('/')
                    }
                }}
            >
                Continue to Dashboard
            </Button>
        </SignUp.Registration.Success>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
