/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled, { keyframes } from 'styled-components'

const ALoading = keyframes`
     to {
         background-position: -150% 0px;
     }
 `

export const Table = {
    Base: styled.div`
        cursor: progress;
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        height: 25px;
        border-radius: 4px;
        background-color: ${(props) => props.theme[`background--000`]};
        overflow: hidden;
    `,
    Text: styled.div`
        width: 100%;
        height: 100%;
        background-color: ${(props) => props.theme[`background--200`]};
    `,
    Block: styled.div`
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    `,
    Gradient: styled.div`
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: linear-gradient(-45deg, transparent, ${(props) => props.theme[`background--000`]}, transparent);
        background-position: 250% 0px;
        background-size: 250% 250%;
        background-repeat: no-repeat;
        animation: ${ALoading} 2s ease-in-out infinite;
    `
}
