/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { Field, Formik, FormikHelpers, FormikProps } from 'formik'
import { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Anchor, Badge, Button, Lightbox, Form as NXUIForm, Tooltip } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { domainAPI, useAddDNSRecordsMutation } from 'api/domain'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { DNSRecordSchema, getRecordTypeColour, replaceNullToEmptyString } from 'helpers/utils'
import Yup from 'helpers/yup'
import type { Reducer } from 'react'
import {
    filterFields,
    hasPriorityField,
    reappendDomainToRecord,
    removeFloatingDotFromString,
    removeFloatingDots,
    removeTrailingDomainFromRecord,
    replaceAtSymbolWithDomain,
    replaceDomainWithAtSymbol
} from './_editRecord.helpers'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { DNSMode, Records } from 'containers/domains/domains.styles'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import AddPresetLightbox from 'containers/domains/lightbox/addPreset/addPreset.lightbox'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
import { DNSRecord } from 'models/domain'
import { DNSRecordType } from 'models/enums'
import PreviewDNSRecordResult from '../addRecord/previewRecord'
type TFormProps = { records: Array<DNSRecord> }

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function EditRecordForm() {
    /***** HOOKS *****/
    const { id } = useParams()
    const domainId = Number(id)
    const editingRecordFormRef = useRef<FormikProps<TFormProps>>(null)

    /***** QUERIES *****/
    const { records, isError } = domainAPI.endpoints.dnsRecords.useQueryState(domainId, {
        selectFromResult: ({ data, ...rest }) => ({
            ...rest,
            records: data?.records.map((record) => removeFloatingDots(record)) ?? []
        })
    })
    const { domain: { domain } = {} } = domainAPI.endpoints.domainList.useQueryState(
        { page: '1' },
        {
            selectFromResult: ({ data }) => ({
                domain: data?.data.find((domainItem) => domainItem.id === domainId)
            })
        }
    )

    const { presets, isLoading: isPresetsLoading } = domainAPI.endpoints.dnsPresets.useQuery(domainId, {
        selectFromResult: ({ data, ...rest }) => ({
            ...rest,
            presets: data?.filter((p) => p.is_active)
        })
    })

    const [addDnsRecords, { isLoading: isAddDNSRecordLoading }] = useAddDNSRecordsMutation({ fixedCacheKey: 'edit-dns-record' })

    /***** STATE *****/
    const [editIndexes, setEditIndexes] = useReducer<Reducer<number[], number>>((original: number[], editIndex: number | null) => {
        if (editIndex === null) return []

        if (original.includes(editIndex)) return original.filter((index) => index !== editIndex)

        return [editIndex, ...original]
    }, [])
    const [submittingIndex, setSubmittingIndex] = useState<number | null>(null)
    const [pendingDelete, setPendingDelete] = useState<number | null>(null)
    const [deletingIndex, setDeletingIndex] = useState<number | null>(null)
    const [isPresetLightboxOpen, setIsPresetLightboxOpen] = useState(false)

    /***** RENDER HELPERS *****/
    const formikInitialValues = useMemo(
        () => ({
            records: records.map((record) => {
                if (record.type === 'SRV') {
                    const [weight, port, destination]: string[] = record.content.split(' ')

                    return replaceNullToEmptyString({
                        weight,
                        port,
                        destination: removeFloatingDotFromString(destination),
                        ...record
                    })
                }

                const nullsToEmptyString = replaceNullToEmptyString(record) as DNSRecord
                const replacedDomainWithAtSymbol = replaceDomainWithAtSymbol(nullsToEmptyString, domain)
                const replacedDomainEndingWithDot = replacedDomainWithAtSymbol
                const removedDomainAtEnd = removeTrailingDomainFromRecord(replacedDomainEndingWithDot, domain)

                // return replacedDomainEndingWithDot;
                return removedDomainAtEnd
            }) as Array<DNSRecord>
        }),
        [records]
    )

    const data = useMemo(
        () => (props?: FormikProps<TFormProps>) =>
            records.flatMap((record: DNSRecord, index: number) => {
                //Edit information
                const { values, initialValues, errors = {} } = props ?? {}
                const { type: initialType } = initialValues?.records?.[index] ?? {}
                const formRecordValues = values?.records?.[index]

                const chevron = editIndexes.includes(index) ? <ChevronDownIcon /> : <ChevronRightIcon />

                const defaultRow = {
                    type: (
                        <Records.Record mode='view' hasBadge>
                            <Records.Hidden>Type</Records.Hidden>
                            <Badge color={getRecordTypeColour(record.type)}>{record.type}</Badge>
                        </Records.Record>
                    ),
                    hostname: (
                        <Records.Record mode='view'>
                            <Records.Hidden>Hostname</Records.Hidden>
                            <div>{removeFloatingDotFromString(record.hostname)}</div>
                        </Records.Record>
                    ),
                    content: (
                        <Records.Record mode='view'>
                            <Records.Hidden>Content</Records.Hidden>
                            <div>{removeFloatingDotFromString(record.content)}</div>
                        </Records.Record>
                    ),
                    ttl: (
                        <Records.Record mode='view'>
                            <Records.Hidden>TTL</Records.Hidden>
                            <div>{record.ttl}</div>
                        </Records.Record>
                    ),
                    priority: (
                        <Records.Record mode='view'>
                            <Records.Hidden>Priority</Records.Hidden>
                            <div>{record.priority}</div>
                        </Records.Record>
                    ),
                    actions: (
                        <Records.Record mode='view'>
                            <Records.Hidden>Actions</Records.Hidden>
                            <Records.EditRecord>
                                <Anchor type='button' onClick={() => setEditIndexes(index)} color={'primary'}>
                                    <span>Edit {chevron}</span>
                                </Anchor>
                            </Records.EditRecord>
                        </Records.Record>
                    )
                } as const

                const defaultFieldRow = {
                    type: (
                        <Records.EditField>
                            <span>Type</span>
                            <Field
                                disabled={isAddDNSRecordLoading}
                                name={`records.${index}.type`}
                                component={Records.Combobox}
                                options={Object.values(DNSRecordType).sort((a) => (a === initialType ? -1 : 1))}
                            />
                        </Records.EditField>
                    ),
                    hostname: (
                        <Records.EditField>
                            <span>Hostname</span>
                            <Field
                                name={`records.${index}.hostname`}
                                type={'text'}
                                disabled={isAddDNSRecordLoading}
                                component={NXUIForm.InputField}
                            />
                        </Records.EditField>
                    ),
                    content: (
                        <Records.EditField>
                            <span>Content</span>
                            <Field
                                name={`records.${index}.content`}
                                type={'text'}
                                disabled={isAddDNSRecordLoading}
                                component={formRecordValues?.type === DNSRecordType.TXT ? NXUIForm.TextAreaField : NXUIForm.InputField}
                            />
                        </Records.EditField>
                    ),
                    ttl: (
                        <Records.EditField>
                            <span>TTL</span>
                            <Records.Small>
                                <Field name={`records.${index}.ttl`} type={'text'} disabled={isAddDNSRecordLoading} component={NXUIForm.InputField} />
                            </Records.Small>
                        </Records.EditField>
                    ),
                    priority: (
                        <Records.EditField>
                            <span>Priority</span>
                            <Records.Small>
                                <Field
                                    name={`records.${index}.priority`}
                                    type={'text'}
                                    disabled={isAddDNSRecordLoading}
                                    component={NXUIForm.InputField}
                                />
                            </Records.Small>
                        </Records.EditField>
                    ),
                    actions: (
                        <Records.EditField>
                            <span></span>
                        </Records.EditField>
                    )
                } as const

                const saveButton = () => {
                    if (submittingIndex === index) {
                        return <Records.SubmittingButton />
                    }

                    if (submittingIndex) {
                        return (
                            <Button type='button' color='secondary' disabled>
                                Save
                            </Button>
                        )
                    }

                    return (
                        <Button
                            type='button'
                            onClick={() => {
                                if (props?.isSubmitting || isAddDNSRecordLoading) return

                                setSubmittingIndex(index)
                                props?.handleSubmit()
                            }}
                            color='primary'
                            disabled={Object.keys(errors).length > 0 || isAddDNSRecordLoading}
                        >
                            Save
                        </Button>
                    )
                }

                const editActionRow = {
                    type: (
                        <Records.ActionRow>
                            <Records.DeleteButton
                                loading={typeof deletingIndex === 'number' && index === deletingIndex && 'Deleting'}
                                type='button'
                                onClick={() => setPendingDelete(index)}
                                color='error'
                                disabled={isAddDNSRecordLoading}
                            >
                                Delete Record
                            </Records.DeleteButton>
                            <Records.RightButtons>
                                <Button type='button' onClick={() => setEditIndexes(index)} color='secondary'>
                                    Cancel
                                </Button>
                                {saveButton()}
                            </Records.RightButtons>
                        </Records.ActionRow>
                    )
                } as const

                if (editIndexes.length !== 0 && editIndexes.includes(index)) {
                    //formRecordValues should always exist as long as there is an editIndex
                    if (!formRecordValues) return [defaultRow, defaultFieldRow]

                    const previewRow = {
                        type: (
                            <Records.PreviewRow>
                                <PreviewDNSRecordResult
                                    type={formRecordValues.type}
                                    hostname={formRecordValues.hostname}
                                    content={formRecordValues.content}
                                    port={String(formRecordValues.port)}
                                    destination={formRecordValues.destination}
                                    ttl={String(formRecordValues.ttl)}
                                    domain={domain}
                                />
                            </Records.PreviewRow>
                        )
                    } as const

                    if (formRecordValues.type === 'SRV') {
                        // Initial Values already split into weight, port, destination
                        const SRVFields = {
                            weight: (
                                <Records.EditField>
                                    <span>Weight</span>
                                    <Field
                                        name={`records.${index}.weight`}
                                        type={'text'}
                                        disabled={isAddDNSRecordLoading}
                                        component={NXUIForm.InputField}
                                    />
                                </Records.EditField>
                            ),
                            port: (
                                <Records.EditField>
                                    <span>Port</span>
                                    <Field
                                        name={`records.${index}.port`}
                                        type={'text'}
                                        disabled={isAddDNSRecordLoading}
                                        component={NXUIForm.InputField}
                                    />
                                </Records.EditField>
                            ),
                            destination: (
                                <Records.EditField>
                                    <span>Destination</span>
                                    <Field
                                        name={`records.${index}.destination`}
                                        type={'text'}
                                        disabled={isAddDNSRecordLoading}
                                        component={NXUIForm.InputField}
                                    />
                                </Records.EditField>
                            )
                        }

                        //convert to single table cell and spread across all columns
                        const defaultEditableSRVRow = {
                            type: (
                                <Records.SRVRow>
                                    {defaultFieldRow.type}
                                    {defaultFieldRow.hostname}

                                    {SRVFields.weight}
                                    {SRVFields.port}
                                    {SRVFields.destination}

                                    {defaultFieldRow.ttl}
                                    {defaultFieldRow.priority}
                                </Records.SRVRow>
                            )
                        } as const

                        return [defaultRow, previewRow, defaultEditableSRVRow, editActionRow]
                    }

                    return [filterFields(defaultRow, formRecordValues), previewRow, filterFields(defaultFieldRow, formRecordValues), editActionRow]
                }

                return filterFields(
                    {
                        ...record,
                        type: defaultRow.type,
                        hostname: defaultRow.hostname,
                        content: defaultRow.content,
                        ttl: defaultRow.ttl,
                        priority: defaultRow.priority,
                        actions: defaultRow.actions
                    },
                    record
                )
            }),
        [records, editIndexes, isAddDNSRecordLoading]
    )

    const columns = () => {
        if (isError) return []

        const headers = {
            type: {
                Header: 'Type',
                accessor: 'type'
            },
            hostname: {
                Header: 'Hostname',
                accessor: 'hostname'
            },
            content: {
                Header: 'Content',
                accessor: 'content'
            },
            ttl: {
                Header: 'TTL',
                accessor: 'ttl'
            },
            priority: {
                Header: 'Priority',
                accessor: 'priority'
            },
            actions: {
                Header: '',
                accessor: 'actions'
            }
        } as const

        type headersType = typeof headers

        const createColumns = (extra?: headersType[keyof headersType][]) =>
            [headers.type, headers.hostname, headers.content, headers.ttl, ...(extra ?? []), headers.actions] as headersType[keyof headersType][]

        if (records.some((record) => hasPriorityField(record))) return createColumns([headers.priority])

        return createColumns()
    }

    const recordsView = (props?: FormikProps<TFormProps>) => {
        const SRVEditIndexes = editIndexes.filter(
            (index) => props?.values?.records?.[index]?.type === 'SRV' || (!props && records?.[index]?.type === 'SRV')
        )

        const renderPresetButton = () => {
            const isDisabled = isPresetsLoading || Number(presets?.filter(({ is_active }) => is_active)?.length) === 0

            const buttonProps = {
                disabled: isDisabled,
                type: 'button',
                color: 'secondary',
                onClick: () => setIsPresetLightboxOpen(true)
            }

            if (isDisabled) {
                return (
                    <Tooltip content={noPresetsMessage}>
                        <Button {...buttonProps}>Add Preset</Button>
                    </Tooltip>
                )
            }

            return <Button {...buttonProps}>Add Preset</Button>
        }

        const noPresetsMessage = "It looks like there aren't any presets available for this domain yet."

        return (
            <>
                <Records.View
                    editIndexes={editIndexes}
                    srvIndexes={SRVEditIndexes}
                    table={{
                        conditions: {
                            header: true,
                            search: false,
                            pagination: false,
                            sort: false
                        },
                        render: {
                            columns,
                            data: () => data(props)
                        }
                    }}
                />

                {/* Presets */}
                <Records.TableActions>{renderPresetButton()}</Records.TableActions>

                {/* Preset Lightbox */}
                <AddPresetLightbox isOpen={isPresetLightboxOpen} onClose={() => setIsPresetLightboxOpen(false)} />
            </>
        )
    }

    /**** Effects *****/
    useEffect(() => {
        if (isAddDNSRecordLoading) return

        setSubmittingIndex(null)
    }, [isAddDNSRecordLoading])

    /*** VIEW MODE ***/
    if (editIndexes.length === 0) return recordsView()

    const formikOnSubmit = async (values: TFormProps, { resetForm }: FormikHelpers<TFormProps>) => {
        const formattedRecord = values.records
            .map((record) => ({
                //convert to lowercase
                ...record,
                hostname: record.hostname.toLowerCase(),
                content: record.content.toLowerCase()
            }))
            .map((record) => replaceAtSymbolWithDomain(record, domain)) //replace @ with domain
            .map((record) => {
                //format SRV records
                if (record.type !== 'SRV') return record

                return {
                    id: record.id,
                    type: record.type,
                    hostname: record.hostname,
                    content: `${record.weight} ${record.port} ${record.destination}`,
                    priority: record.priority,
                    ttl: record.ttl
                }
            })
            .map((record) => reappendDomainToRecord(record, domain)) //reappend domain to hostname

        try {
            const result = await addDnsRecords({
                id: domainId,
                records: formattedRecord,
                successMessage: 'Successfully updated DNS records'
            }).unwrap()

            setSubmittingIndex(null)

            if (result) {
                resetForm()
                if (submittingIndex !== null) setEditIndexes(submittingIndex) //remove from editing
            }
        } catch (error) {
            resetForm()
        }
    }

    /*** EDIT MODE ***/
    return (
        <>
            <Formik
                innerRef={editingRecordFormRef}
                enableReinitialize={true}
                initialValues={formikInitialValues}
                validationSchema={Yup.object({ records: Yup.array().of(DNSRecordSchema) })}
                onSubmit={formikOnSubmit}
            >
                {(props) => <Records.Edit>{recordsView(props)}</Records.Edit>}
            </Formik>

            <Lightbox
                title={'Current DNS Record will be deleted'}
                className={'lightbox'}
                type={'confirm'}
                icon={'warning'}
                description={
                    <DNSMode.Confirmation>
                        <span>You are about to delete the following record, note that this action is irreversible.</span>
                        <DNSMode.Actions>
                            <Button type='button' color='secondary' onClick={() => setPendingDelete(null)}>
                                No, keep my current DNS settings
                            </Button>
                            <Button
                                type='submit'
                                color='error'
                                onClick={async () => {
                                    if (pendingDelete === null) return

                                    setDeletingIndex(pendingDelete)
                                    setPendingDelete(null)

                                    await addDnsRecords({
                                        id: domainId,
                                        records: records.filter((_, index) => index !== pendingDelete),
                                        successMessage: 'Successfully deleted DNS record'
                                    }).finally(() => {
                                        setDeletingIndex(null)
                                        setEditIndexes(pendingDelete) //remove from editing
                                    })
                                }}
                            >
                                Delete
                            </Button>
                        </DNSMode.Actions>
                    </DNSMode.Confirmation>
                }
                conditions={{
                    state: pendingDelete !== null,
                    action: () => setPendingDelete(null)
                }}
            />
        </>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
