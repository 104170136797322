/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import React, { useEffect, useState, useRef } from 'react'
import { DateTime } from 'luxon'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { RenderInputLabel, RenderErrorMessages, RenderErrorIcon, Style } from '../util'
import { Input } from './input'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled from 'styled-components'

const Calendar = {
    Wrapper: styled.div`
        display: flex;
        width: 100%;

        select {
            min-width: 130px;
            border-radius: 0px;
            border-left-width: 0px;
            border-right-width: 0px;

            &:first-of-type {
                min-width: 65px;
                border-radius: 8px 0 0 8px;
            }

            &:last-of-type {
                min-width: 90px;
                border-radius: 0 8px 8px 0;
            }
        }
    `
}

export const Select = {
    Field: styled.select`
        ${Style.Field}
        appearance: none;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
        background-position: right 8px center;
        background-repeat: no-repeat;
        background-size: 24px 24px;
    `
}

/**********************************************************************************************************
 *   INPUT FIELD
 **********************************************************************************************************/
export const DateField = ({
    className,
    embedded,
    required,
    label,
    disabled,
    isExpiry,
    field,
    form: { setFieldValue, touched, errors, isSubmitting }
}) => {
    /*   REF
     *****************************************************/
    const dayRef = useRef()
    const monthRef = useRef()
    const yearRef = useRef()

    /*   STATE
     *****************************************************/
    const [active, setActive] = useState('day')
    const [hasFocus, setFocus] = useState(false)
    const [initialValue, setInitialValue] = useState(false)
    const [day, setDay] = useState(DateTime.now().day)
    const [month, setMonth] = useState(DateTime.now().month)
    const [year, setYear] = useState(DateTime.now().year)

    const [refresh, setRefresh] = useState(false)
    const [days, setDays] = useState([])
    const years = isExpiry ? getFutureYears() : getPastYears()
    const months = [
        { value: 1, label: 'January' },
        { value: 2, label: 'February' },
        { value: 3, label: 'March' },
        { value: 4, label: 'April' },
        { value: 5, label: 'May' },
        { value: 6, label: 'June' },
        { value: 7, label: 'July' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'October' },
        { value: 11, label: 'November' },
        { value: 12, label: 'December' }
    ]

    /*   FUNCTIONS
     *****************************************************/
    function updateFieldValue(day, month, year) {
        setFieldValue(field.name, `${day}/${month}/${year}`)
        setRefresh(!refresh)
    }

    function getFutureYears() {
        const years = []
        for (let i = DateTime.now().year; i <= DateTime.now().year + 100; i++) {
            years.push({ value: i, label: i })
        }
        return years
    }

    function getPastYears() {
        const years = []
        for (let i = DateTime.now().year; i >= DateTime.now().year - 100; i--) {
            years.push({ value: i, label: i })
        }
        return years
    }

    function getDays() {
        const days = []
        const year = year ? year : DateTime.now().year

        for (let i = 1; i <= DateTime.utc(year, month).daysInMonth; i++) {
            days.push({ value: i, label: i })
        }

        setRefresh(!refresh)
        return days
    }

    /*   EFFECT
     *****************************************************/
    useEffect(() => {
        if (
            (document.hasFocus() && dayRef.current.contains(document.activeElement)) ||
            monthRef.current.contains(document.activeElement) ||
            yearRef.current.contains(document.activeElement)
        ) {
            setFocus(true)
        }

        if (refresh) {
            setDays(getDays())
        }

        if (!initialValue) {
            setInitialValue(true)
            updateFieldValue(day, month, year)
        }
    }, [day, month, year, refresh, initialValue])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Input.Wrapper className={className} embedded={embedded}>
            {RenderInputLabel(embedded, required, label, field)}
            <Input.Column focus={hasFocus} error={errors[`day`] || errors[`month`] || errors[`year`]}>
                <Calendar.Wrapper>
                    <input type={'hidden'} {...field} />
                    <Select.Field
                        ref={dayRef}
                        id={'day'}
                        value={day}
                        disabled={isSubmitting || disabled}
                        onChange={(e) => {
                            setDay(Number(e.target.value))
                            updateFieldValue(Number(e.target.value), month, year)
                        }}
                        onFocus={(e) => {
                            setActive(e.target.name)
                            setFocus(true)
                        }}
                        onBlur={(e) => {
                            field.onBlur && field.onBlur(e)
                            setFocus(false)
                        }}
                    >
                        {days?.map(({ label, value }, index) => (
                            <option key={`${'day'}-${index}`} value={value}>
                                {label}
                            </option>
                        ))}
                    </Select.Field>
                    <Select.Field
                        ref={monthRef}
                        id={'month'}
                        value={month}
                        disabled={isSubmitting || disabled}
                        onChange={(e) => {
                            setMonth(Number(e.target.value))
                            updateFieldValue(day, Number(e.target.value), year)
                        }}
                        onFocus={(e) => {
                            setActive(e.target.name)
                            setFocus(true)
                        }}
                        onBlur={(e) => {
                            field.onBlur && field.onBlur(e)
                            setFocus(false)
                        }}
                    >
                        {months.map(({ label, value }, index) => (
                            <option key={`${'month'}-${index}`} value={value}>
                                {label}
                            </option>
                        ))}
                    </Select.Field>
                    <Select.Field
                        ref={yearRef}
                        id={'year'}
                        value={year}
                        disabled={isSubmitting || disabled}
                        onChange={(e) => {
                            setYear(Number(e.target.value))
                            updateFieldValue(day, month, Number(e.target.value))
                        }}
                        onFocus={(e) => {
                            setActive(e.target.name)
                            setFocus(true)
                        }}
                        onBlur={(e) => {
                            field.onBlur && field.onBlur(e)
                            setFocus(false)
                        }}
                    >
                        {years.map(({ label, value }, index) => (
                            <option key={`${'year'}-${index}`} value={value}>
                                {label}
                            </option>
                        ))}
                    </Select.Field>
                </Calendar.Wrapper>
                {RenderErrorIcon(active, touched, errors, embedded)}
            </Input.Column>
            {RenderErrorMessages(active, touched, errors)}
        </Input.Wrapper>
    )
}
