/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import React from 'react'
import PropTypes from 'prop-types'
import * as Theme from '../../theme/theme'
import styled, { css } from 'styled-components'

/**********************************************************************************************************
 *   COMPONENT IMPORTS
 **********************************************************************************************************/
import { Loader } from '../loader'

/**********************************************************************************************************
 *   STYLE
 **********************************************************************************************************/
const SharedAnchor = styled.a`
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    width: fit-content;
    text-decoration: none;
    letter-spacing: 0.25;
    font-size: 12px;
    ${Theme.Functions.setFont('Inter', 600)}
    ${Theme.Functions.outline(`none`)};
    ${Theme.Functions.animation(`all`)};

    :focus {
        ${Theme.Functions.outline(`none`)};
    }

    .icon {
        width: 14px;
        height: 14px;
        margin: 0 -4px 0 6px;
    }

    ${({ color }) => {
        switch (color) {
            case 'primary':
                return css`
                    color: ${(props) => props.theme[`primary--100`]};

                    .icon {
                        color: ${(props) => props.theme[`primary--100`]};
                    }

                    &:hover {
                        color: ${(props) => props.theme[`primary--200`]};

                        .icon: {
                            color: ${(props) => props.theme[`primary--200`]};
                        }
                    }
                `

            case 'error':
                return css`
                    color: ${(props) => props.theme[`error--100`]};

                    .icon {
                        color: ${(props) => props.theme[`error--100`]};
                    }

                    &:hover {
                        color: ${(props) => props.theme[`error--200`]};

                        .icon: {
                            color: ${(props) => props.theme[`error--200`]};
                        }
                    }
                `

            case 'alternate':
                return css`
                    color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`white--100`], `0.6`)};

                    .icon {
                        color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`white--100`], `0.6`)};
                    }

                    &:hover {
                        color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`white--100`], `1`)};

                        .icon: {
                            color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`white--100`], `1`)};
                        }
                    }
                `

            case 'select':
                return css`
                    justify-content: center;
                    height: 32px;
                    padding: 0 12px;
                    margin: 0;
                    border-radius: 8px;

                    color: ${(props) => props.theme[`primary--100`]};
                    background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`primary--100`], `0.1`)};

                    .icon {
                        color: ${(props) => props.theme[`primary--100`]};
                    }

                    &:hover {
                        background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`primary--100`], `0.2`)};
                    }
                `

            case 'secondary':
            default:
                return css`
                    color: ${(props) => props.theme[`text--200`]};

                    .icon {
                        color: ${(props) => props.theme[`text--200`]};
                    }

                    &:hover {
                        color: ${(props) => props.theme[`text--100`]};

                        .icon: {
                            color: ${(props) => props.theme[`text--100`]};
                        }
                    }
                `
        }
    }}
`

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Anchor = ({ children, onClick, disabled, className, target, href, loading, download, rel, color, icon, type, form, ...rest }) => {
    const conditional = { onClick, disabled, href, target, download, rel }

    function renderAnchorContent() {
        if (loading) return <Loader.Basic message={loading ? loading : children} />

        return (
            <>
                {children}
                {icon && <div className={`icon`}>{icon}</div>}
            </>
        )
    }

    if (conditional && conditional.onClick)
        return (
            <SharedAnchor
                as={`button`}
                type={`button`}
                color={color ? color : `secondary`}
                className={`${className ? ' ' + className : ''}`}
                {...conditional}
                {...rest}
            >
                {renderAnchorContent()}
            </SharedAnchor>
        )

    if (type === 'submit') {
        return (
            <SharedAnchor
                as={`button`}
                type={type}
                form={form}
                color={color ? color : `secondary`}
                className={`${className ? ' ' + className : ''}`}
                {...conditional}
                {...rest}
            >
                {renderAnchorContent()}
            </SharedAnchor>
        )
    }

    return (
        <SharedAnchor color={color ? color : `secondary`} className={`${className ? ` ${className}` : ``}`} {...conditional} {...rest}>
            {renderAnchorContent()}
        </SharedAnchor>
    )
}

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
Anchor.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    target: PropTypes.string,
    href: PropTypes.string,
    download: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary', 'error', 'alternate', 'select']),
    rel: PropTypes.oneOf([
        'alternate',
        'author',
        'bookmark',
        'external',
        'help',
        'license',
        'next',
        'nofollow',
        'noopener',
        'noreferrer',
        'prev',
        'search',
        'tag'
    ]),
    icon: PropTypes.element
}
