/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import type { TypedAddListener, TypedStartListening } from '@reduxjs/toolkit'
import { addListener, createListenerMiddleware } from '@reduxjs/toolkit'
import Config from 'data/config'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { appAPI } from 'api/app'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setAppInitialised, setAppSettings } from 'store/slices/appSlice'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { AppDispatch, RootState } from 'store/store'

/**********************************************************************************************************
 *   BASE EXPORTS
 **********************************************************************************************************/
export const appListenerMiddleware = createListenerMiddleware()

export type AppStartListening = TypedStartListening<RootState, AppDispatch>

export const startAppListening = appListenerMiddleware.startListening as AppStartListening

export const addAppListener = addListener as TypedAddListener<RootState, AppDispatch>

/**********************************************************************************************************
 *   SET APPLICATION SETTINGS AND THEME AFTER INITIALISATION
 **********************************************************************************************************/
startAppListening({
    matcher: appAPI.endpoints.initialiseApplication.matchFulfilled,
    effect: async ({ payload }, listenerApi) => {
        const { app } = listenerApi.getState()
        const { appSettings } = app
        const { settings } = payload

        listenerApi.dispatch(
            setAppSettings({
                ...appSettings,
                ...settings,
                theme: {
                    ...Config.Theme,
                    ...settings.theme
                }
            })
        )

        listenerApi.dispatch(setAppInitialised(true))
    }
})
