/* eslint-disable @typescript-eslint/no-explicit-any */
/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { FormikErrors, FormikTouched, getIn } from 'formik'
import { useId, useRef } from 'react'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import { FieldErrorStyles as S } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TFieldErrorMessage = React.FC<{
    name: string
    touched: FormikTouched<any>
    errors: FormikErrors<any>
    className?: string
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _FieldErrorIcon: TFieldErrorMessage = ({ name, touched, errors, className }) => {
    /***** HOOKS *****/
    const id = useId()
    const tooltipRef = useRef<HTMLDivElement>(null)

    /***** RENDER HELPERS *****/
    const error = getIn(errors, name)
    const touch = getIn(touched, name)

    /***** RENDER *****/
    return (
        <S.Icon.Wrapper id={id} visible={touch && error} className={className} aria-hidden={touch && error ? 'true' : 'false'}>
            <S.Icon.Tip id={id} ref={tooltipRef} size={tooltipRef?.current?.offsetHeight ?? 0}>
                {error}
                <S.Icon.Arrow />
            </S.Icon.Tip>
            <S.Icon id={`${name}-error-icon`} />
        </S.Icon.Wrapper>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/***** EXPORTS *****/
export const FieldErrorIcon = Object.assign(_FieldErrorIcon, {
    Styles: S
})
