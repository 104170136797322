/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon, XCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import PropTypes from 'prop-types'
import { toast, useToaster } from 'react-hot-toast'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Anchor } from '../anchor'
import { Fade } from '../transition/transition'

/**********************************************************************************************************
 *   STYLE
 **********************************************************************************************************/
import styled, { createGlobalStyle } from 'styled-components'
import * as Theme from '../../theme/theme'

const GlobalAlertStyle = createGlobalStyle`
    .nxAlert {
        &__transition {
            &--enter {
                transform: 0px;
                transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
                transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
                transition-duration: 300ms;
            }

            &--enterFrom {
                transform: 8px;
                opacity: 0;

                ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
                    transform: translateY(0px);
                    transform: translateX(8px);
                }
            }

            &--enterTo {
                transform: translateY(0px);
                opacity: 1;

                ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
                    transform: translateX(0);
                }
            }

            &--leave {
                transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
                transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
                transition-duration: 300ms;
            }

            &--leaveFrom {
                opacity: 1;
            }

            &--leaveTo {
                opacity: 0;
            }
        }

        &__alertEnter {
            opacity: 1;
        }

        &__alertExit {
            opacity: 0;
        }
    }
`

const Alert = {
    Provider: styled.div`
        position: absolute;
        top: 0px;
        right: 0px;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-end;
    `,
    Base: styled.div`
        pointer-events: none;
        width: 100%;
        padding: 12px;
        ${Theme.Functions.animation('opacity', 1000)};
    `,
    Container: styled.div`
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        border-radius: 8px;
        background-color: ${(props) => props.theme[`background--000`]};
        ${Theme.Functions.animation('all')};
    `,
    Fade: styled(Fade)`
        width: fit-content;
    `,
    Wrapper: styled.div`
        pointer-events: auto;
        display: flex;
        align-items: center;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0.3px 0.3px 0.6px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--200`], `0.1`)},
            0.7px 0.7px 1.3px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--200`], `0.072`)},
            1.3px 1.3px 2.5px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--200`], `0.06`)},
            2.2px 2.2px 4.5px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--200`], `0.05`)},
            4.2px 4.2px 8.4px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--200`], `0.04`)},
            10px 10px 20px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--200`], `0.028`)};

        &.primaryBackground {
            border-left: 8px solid ${(props) => Theme.Functions.hexToRGBA(props.theme[`primary--100`], `0.75`)};
        }

        &.confirmBackground {
            border-left: 8px solid ${(props) => Theme.Functions.hexToRGBA(props.theme[`confirm--100`], `0.75`)};
        }

        &.errorBackground {
            border-left: 8px solid ${(props) => Theme.Functions.hexToRGBA(props.theme[`error--100`], `0.75`)};
        }

        &.warningBackground {
            border-left: 8px solid ${(props) => Theme.Functions.hexToRGBA(props.theme[`warning--100`], `0.75`)};
        }

        &.infoBackground {
            border-left: 8px solid ${(props) => Theme.Functions.hexToRGBA(props.theme[`info--100`], `0.75`)};
        }
    `,
    Details: {
        Wrapper: styled.div`
            width: auto;
            padding: 16px;
            padding-left: 12px;
            padding-right: 28px;
            border-right: 1px solid ${(props) => props.theme[`border--100`]};
        `,
        Title: styled.h3`
            font-size: 1.4rem;
            ${Theme.Functions.setFont(`Inter`, 600)};

            &.warningText {
                color: ${(props) => props.theme[`warning--100`]};
            }

            &.errorText {
                color: ${(props) => props.theme[`error--100`]};
            }

            &.infoText {
                color: ${(props) => props.theme[`info--100`]};
            }

            &.confirmText {
                color: ${(props) => props.theme[`confirm--100`]};
            }
        `,
        Description: styled.p`
            margin-top: 5px;
            color: ${(props) => props.theme[`text--100`]};
            ${Theme.Functions.setFont(`Inter`, 600)};
        `,
        Action: styled.div`
            height: fit-content;
            display: flex;
            flex-direction: column-reverse;
            padding: 16px;
            ${Theme.Functions.spaceX(`28px`)};

            a {
                margin-top: 12px
                color: ${(props) => props.theme[`primary--100`]};
            }
        `
    },
    Close: {
        Container: styled.div`
            position: absolute;
            top: 8px;
            right: 8px;
            margin-left: 16px;
            flex-shrink: 0;
            display: flex;
            ${Theme.Functions.outline('none')};

            button,
            svg,
            path {
                ${Theme.Functions.outline('none')};
            }
        `,
        Icon: styled(XMarkIcon)`
            color: ${(props) => props.theme[`text--100`]};
            ${Theme.Functions.outline('none')};
            ${Theme.Functions.animation('all')};

            &:hover {
                color: ${(props) => props.theme[`error--100`]};
            }
        `
    },
    Dismiss: styled(Anchor)`
        margin: 5px 0;
        color: ${(props) => props.theme[`text--100`]};
        font-size: 1.2rem;
        ${Theme.Functions.setFont(`Inter`, 600)};
        ${Theme.Functions.outline('none')};

        button,
        svg,
        path {
            ${Theme.Functions.outline('none')};
        }

        &:hover {
            color: ${(props) => props.theme[`text--300`]};
        }
    `,
    Icon: styled.div`
        margin: 4px 4px 4px 12px;
        flex-shrink: 0;
        border-radius: 9999px;

        &.warningIcon {
            color: ${(props) => props.theme[`warning--100`]};
        }

        &.errorIcon {
            color: ${(props) => props.theme[`error--100`]};
        }

        &.infoIcon {
            color: ${(props) => props.theme[`info--100`]};
        }

        &.confirmIcon {
            color: ${(props) => props.theme[`confirm--100`]};
        }
    `
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const AlertComponent = ({ t, offset }) => {
    function handleIconRender(type) {
        switch (type) {
            case 'WARN':
                return (
                    <Alert.Icon className={`warningIcon`}>
                        <ExclamationCircleIcon width={`32px`} aria-hidden='true' />
                    </Alert.Icon>
                )

            case 'ERROR':
                return (
                    <Alert.Icon className={`errorIcon`}>
                        <XCircleIcon width={`32px`} aria-hidden='true' />
                    </Alert.Icon>
                )

            case 'INFO':
                return (
                    <Alert.Icon className={`infoIcon`}>
                        <InformationCircleIcon width={`32px`} aria-hidden='true' />
                    </Alert.Icon>
                )

            default:
            case 'SUCCESS':
                return (
                    <Alert.Icon className={`confirmIcon`}>
                        <CheckCircleIcon width={`32px`} aria-hidden='true' />
                    </Alert.Icon>
                )
        }
    }

    function getTextColor(type) {
        switch (type) {
            case 'WARN':
                return `warningText`

            case 'ERROR':
                return `errorText`

            case 'INFO':
                return `infoText`

            case 'SUCCESS':
                return `confirmText`

            default:
                return ``
        }
    }

    function getBackgroundColor(type) {
        switch (type) {
            case 'WARN':
                return `warningBackground`

            case 'ERROR':
                return `errorBackground`

            case 'INFO':
                return `infoBackground`

            case 'SUCCESS':
                return `confirmBackground`

            default:
                return ``
        }
    }

    function handleLinksRender(link) {
        return link ? (
            <>
                <Alert.Dismiss href={link} target={`_blank`}>
                    Read More
                </Alert.Dismiss>
                <Alert.Dismiss onClick={() => toast.dismiss(t.id)}>Dismiss</Alert.Dismiss>
            </>
        ) : (
            <Alert.Dismiss onClick={() => toast.dismiss(t.id)}>Dismiss</Alert.Dismiss>
        )
    }

    return (
        <Alert.Fade when={t.visible}>
            <Alert.Base
                role={`alert`}
                aria-label={`notification`}
                key={t.id}
                className={t.visible ? 'nxAlert__alertEnter' : 'nxAlert__alertExit'}
                style={{ transform: `translateY(${offset}px)` }}
            >
                <Alert.Container>
                    <Alert.Wrapper className={getBackgroundColor(t.message?.type)}>
                        {handleIconRender(t.message?.type)}
                        <Alert.Details.Wrapper>
                            <Alert.Details.Title className={getTextColor(t.message?.type)}>
                                {t.message?.code ? `${t.message?.code}: ` : ''}
                                {t.message?.title}
                            </Alert.Details.Title>
                            <Alert.Details.Description>{t.message?.detail}</Alert.Details.Description>
                        </Alert.Details.Wrapper>
                        <Alert.Details.Action>{handleLinksRender(t.message?.link)}</Alert.Details.Action>
                    </Alert.Wrapper>
                </Alert.Container>
            </Alert.Base>
        </Alert.Fade>
    )
}

const AlertProvider = () => {
    const { toasts, handlers } = useToaster()
    const { calculateOffset, updateHeight } = handlers

    return (
        <>
            <GlobalAlertStyle />
            <Alert.Provider>
                {toasts.map((t) => (
                    <AlertComponent
                        key={t.id}
                        t={t}
                        updateHeight={updateHeight}
                        offset={calculateOffset(t.id, {
                            reverseOrder: false
                        })}
                    />
                ))}
            </Alert.Provider>
        </>
    )
}

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
AlertComponent.propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({
        type: PropTypes.string,
        title: PropTypes.string,
        detail: PropTypes.string,
        code: PropTypes.number,
        link: PropTypes.string
    }),
    dismiss: PropTypes.func
}

export { AlertComponent as Alert, AlertProvider }
