/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import React, { useState, useEffect, useRef } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { RenderInputLabel, RenderErrorIcon, Style } from '../util'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled from 'styled-components'

export const Input = {
    Wrapper: styled.div`
        ${Style.Wrapper}
    `,
    Column: styled.div`
        ${Style.Column}
    `,
    Base: styled.input`
        ${Style.Field}

        ::-webkit-calendar-picker-indicator {
            display: none;
        }
    `,
    Label: styled.label`
        ${Style.Label}
    `,
    Datalist: styled.datalist``
}

/**********************************************************************************************************
 *   INPUT FIELD
 **********************************************************************************************************/
export const DatalistField = ({ className, embedded, required, label, list, field, disabled, form: { touched, errors, isSubmitting }, ...props }) => {
    /*   REF
     *****************************************************/
    const inputRef = useRef()

    /*   STATE
     *****************************************************/
    const [hasFocus, setFocus] = useState(false)

    /*   EFFECT
     *****************************************************/
    useEffect(() => {
        if (document.hasFocus() && inputRef.current.contains(document.activeElement)) {
            setFocus(true)
        }
    }, [])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Input.Wrapper className={className} embedded={embedded}>
            {RenderInputLabel(embedded, required, label, field)}
            <Input.Column focus={hasFocus} error={touched[field.name] && errors[field.name]}>
                <Input.Base
                    {...field}
                    {...props}
                    onFocus={() => setFocus(true)}
                    onBlur={(e) => {
                        field.onBlur && field.onBlur(e)
                        setFocus(false)
                    }}
                    ref={inputRef}
                    disabled={isSubmitting || disabled}
                    list={list.name}
                />
                {RenderErrorIcon(field.name, touched, errors)}
            </Input.Column>
            <Input.Datalist id={list.name}>
                {list.options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Input.Datalist>
        </Input.Wrapper>
    )
}
