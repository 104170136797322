/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Table } from 'nxui/src'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { ServerDataTableCell } from 'containers/services/server/dkimRecords'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
import { Service } from 'models/service'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
interface Props {
    server: Service['server']
}

export function SpfRecords({ server }: Props) {
    const { spf_record } = server.additional_meta || {}

    const tableColumns = [
        {
            Header: 'SPF Record',
            accessor: 'details'
        },
        {
            Header: '',
            accessor: 'value'
        },
        {
            Header: '',
            accessor: 'action',
            align: 'right'
        }
    ]

    const tableData = [
        {
            details: <ServerDataTableCell.MinWidth>Record</ServerDataTableCell.MinWidth>,
            value: <ServerDataTableCell.WordBroken>{spf_record || ''}</ServerDataTableCell.WordBroken>,
            action: ''
        }
    ]

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Table
            render={{
                columns: () => tableColumns,
                data: () => tableData
            }}
        />
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
