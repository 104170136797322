/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { ReactElement } from 'react-markdown/lib/react-markdown'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { RenderPropOrInvokeChildren } from 'components/renderPropOrInvokeChildren'
import { Anchor } from 'nxui/src'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { BubbleStyles as S } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TBubbleBaseProps = {
    /**
     * Icon to display in the card. If this is not provided, a default icon is used as a placeholder
     */
    icon?: React.FC<React.RefAttributes<SVGSVGElement>>
    className?: string
    type?: 'data' | 'compound'
}

type TBubbleChildrenProps = TBubbleBaseProps & {
    /**
     * Optional children that can be used to render a more customized bubble
     */
    children: React.ReactNode
}

type TBubbleDataProps = TBubbleBaseProps & {
    /**
     * Title of the bubble
     */
    title: string

    /**
     * Description of the bubble
     */
    description: string
    action?: {
        label: string
        onClick: () => void
    }
}

type TBubblePropsUnion = TBubbleChildrenProps | TBubbleDataProps
type TBubbleProps<T extends 'data' | 'compound' = 'data'> = T extends 'compound' ? TBubbleChildrenProps : TBubbleDataProps
type TBubble = <Type extends 'data' | 'compound' = 'data'>(props: TBubbleProps<Type> & { type?: Type }) => ReactElement

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const BaseBubble: TBubble = ({ icon: Icon, className, ...props }) => {
    /***** RENDER *****/
    return (
        <S.Bubble className={className}>
            <RenderPropOrInvokeChildren props={props as unknown as TBubblePropsUnion} prop='children'>
                {({ description, title, action }) => (
                    <>
                        <S.Title>{title}</S.Title>
                        <S.Description>{description}</S.Description>
                        {action && (
                            <Anchor color='alternate' onClick={action.onClick} icon={<ArrowRightIcon />}>
                                {action.label}
                            </Anchor>
                        )}
                    </>
                )}
            </RenderPropOrInvokeChildren>
            <S.IconWrapper>{Icon ? <Icon /> : <ArrowRightIcon />}</S.IconWrapper>
        </S.Bubble>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/***** BUBBLE VARIANTS *****/
const CompoundBubble = (props: Omit<TBubbleProps<'compound'>, 'type'>) => BaseBubble({ ...props, type: 'compound' })
const DataBubble = (props: Omit<TBubbleProps<'data'>, 'type'>) => BaseBubble({ ...props, type: 'data' })

/***** EXPORTS *****/
export const Bubble = Object.assign(DataBubble, {
    Title: S.Title,
    Description: S.Description,
    Compound: CompoundBubble,
    Styles: S
})
