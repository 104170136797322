/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled, { css, keyframes } from 'styled-components'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Theme } from 'nxui/src'

const ASpin = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
export const BasicLoaderStyles = {
    SVG: styled.svg<{ hasMessage?: boolean; position?: 'right' | 'left' }>`
        height: 1.25rem;
        width: 1.25rem;
        animation: ${ASpin} 1s linear infinite;

        ${({ hasMessage, position }) => {
            if (!hasMessage) return

            if (position === 'left') {
                return css`
                    margin-left: 0.75rem;
                    margin-right: -0.25rem;
                `
            } else {
                return css`
                    margin-left: -0.25rem;
                    margin-right: 0.75rem;
                `
            }
        }}
    `,
    Circle: styled.circle`
        opacity: 0.1;
    `,
    Path: styled.path`
        opacity: 0.4;
    `,
    Base: styled.span`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    `,
    Message: styled.span`
        color: ${({ theme }) => Theme.Functions.hexToRGBA(theme['text--300'], 0.75)};
        font-size: 1.4rem;
    `
}
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/
