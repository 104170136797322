/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setAppActiveRouter } from 'store/slices/appSlice'
import { store } from 'store/store'

/**********************************************************************************************************
 *   MIDDLEWARE
 **********************************************************************************************************/
export const checkIsAuthenticated: Middleware = () => (next) => (action) => {
    if (isRejectedWithValue(action) && action.payload.data?.messages?.[0]?.title === 'Unauthenticated') {
        return store.dispatch(
            setAppActiveRouter({
                template: 'guest',
                meta: {
                    loading: false,
                    message: '',
                    redirect: '/login'
                }
            })
        )
    }

    return next(action)
}
