/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Formik, FormikProps } from 'formik'
import { Dispatch, RefObject, SetStateAction, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { Button, Lightbox, MultiSelect, Form as NXUIForm, Table } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useAccountPaymentMethodsQuery, usePaymentMethodsQuery } from 'api/billing'
import { serviceAPI } from 'api/service'
import { useCheckoutCartMutation, useCreateCartMutation, useDeleteCartMutation } from 'api/shop'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setAppActiveInvoice, setCart } from 'store/slices/appSlice'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Addon } from 'containers/services/services.styles'
import { Registration } from 'containers/shop/domain/domain.styles'
import { Services } from 'containers/shop/shop.styles'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { useAppDispatch } from 'store/hooks'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
import { Service } from 'models/service'
import { IAddon, IConfigOption, IProduct } from 'models/shop/product.d'

interface Props {
    template: 'configuration' | 'confirmation' | 'product'
    setTemplate: Dispatch<SetStateAction<'configuration' | 'confirmation' | 'product'>>
    addonParent?: Service
    productAddon?: IAddon
    status: boolean
    close: () => void
}

interface IAddonConfigurationForm {
    billing_cycle_id: number | null
    config_options: Array<{
        config_id: number
        option_id: number
    }>
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function AddonPurchaseLightbox({ template, setTemplate, addonParent, productAddon, status, close }: Props) {
    let conditionalProps
    const { id } = useParams()
    const serviceId = Number(id)
    const dispatch = useAppDispatch()
    const [createCart, { isLoading: isCreateCartLoading, data: cart, reset: resetCreateCart }] = useCreateCartMutation({
        fixedCacheKey: 'addon-purchase-cart'
    })
    const [checkoutCart, { isLoading: isCheckingOutCart, reset: resetCheckoutCart }] = useCheckoutCartMutation({
        fixedCacheKey: 'addon-purchase-checkout'
    })
    const { data: paymentMethods } = usePaymentMethodsQuery()
    const { data: accountPaymentMethods } = useAccountPaymentMethodsQuery()
    const [deleteCart] = useDeleteCartMutation()
    const serviceConfigRef = useRef<FormikProps<IAddonConfigurationForm>>(null)
    const addon = cart?.items?.find(({ parent_id }) => parent_id === serviceId)
    const data = useMemo(() => {
        if (!addon) return []

        return [
            {
                addon: addon.name,
                quantity: addon.quantity,
                price: addon?.price?.total
            }
        ]
    }, [addon])

    const renderBillingCycle = (product: IProduct, ref: RefObject<FormikProps<IAddonConfigurationForm>>) => (
        <>
            <Registration.Title>Billing Cycle</Registration.Title>
            <Services.Content>
                <MultiSelect
                    options={product.pricing.map(({ billing_cycle }) => ({
                        type: 'button',
                        label: billing_cycle.name,
                        active: ref.current?.values.billing_cycle_id === billing_cycle.id,
                        onClick: () => ref.current?.setFieldValue('billing_cycle_id', billing_cycle.id)
                    }))}
                />
            </Services.Content>
        </>
    )

    const renderInputType = (configOption: IConfigOption, index: number) => {
        switch (configOption.input_type) {
            case 'dropdown':
                return (
                    <div key={configOption.id}>
                        <div>{configOption.name}</div>
                        <Field
                            name={`config_options.${index}.option_id`}
                            type='select'
                            list={configOption.values.map((configOptionValue) => ({
                                label: configOptionValue.name,
                                value: configOptionValue.id
                            }))}
                            component={NXUIForm.SelectField}
                        />
                    </div>
                )
            case 'checkbox':
                return (
                    <div key={configOption.id}>
                        <div>{configOption.name}</div>
                        {configOption.values.map((configOptionValue) => (
                            <Field
                                key={String(configOptionValue)}
                                name={`config_options.${index}.quantity`}
                                value={String(1)}
                                type={'checkbox'}
                                description={configOptionValue.name}
                                component={NXUIForm.CheckboxField}
                            />
                        ))}
                    </div>
                )
            case 'radio':
                return (
                    <div key={configOption.id} role={'group'} aria-labelledby={configOption.name}>
                        <div>{configOption.name}</div>
                        <div>
                            {configOption.values.map((configOptionValue) => (
                                <Field
                                    id={`config_options.${index}.option_id`}
                                    key={String(configOptionValue.id)}
                                    label={configOptionValue.name}
                                    name={`config_options.${index}.option_id`}
                                    value={String(configOptionValue.id)}
                                    type={'radio'}
                                    description={configOptionValue.name}
                                    component={NXUIForm.RadioField}
                                />
                            ))}
                        </div>
                    </div>
                )
            case 'number':
                return (
                    <div key={configOption.id}>
                        <div>{configOption.name}</div>
                        {configOption.values.map((configOptionValue) => (
                            <Field
                                id={`config_options.${index}.quantity`}
                                label={configOptionValue.name}
                                key={configOptionValue.id}
                                name={`config_options.${index}.quantity`}
                                type='number'
                                min='1'
                                step='1'
                                component={NXUIForm.NumberField}
                            />
                        ))}
                    </div>
                )
            default:
                return null
        }
    }

    const renderConfigOptions = (product: IProduct) => {
        if (!product.config_option_groups || (Array.isArray(product.config_option_groups) && product.config_option_groups.length === 0)) {
            return null
        }

        return (
            <>
                <Registration.Title>Configuration Options</Registration.Title>
                <Services.Content>
                    {product.config_option_groups.map((configOptionGroup) =>
                        configOptionGroup.config_options.map((configOption, index) => renderInputType(configOption, index))
                    )}
                </Services.Content>
            </>
        )
    }

    function renderAddon() {
        if (!productAddon || !addonParent) return null
        const { pricing } = productAddon

        return (
            <Formik
                enableReinitialize
                validateOnMount
                innerRef={serviceConfigRef}
                initialValues={{
                    billing_cycle_id: pricing[0].billing_cycle.id ?? null,
                    config_options:
                        !productAddon?.config_option_groups || productAddon.config_option_groups?.length === 0
                            ? []
                            : productAddon.config_option_groups[0].config_options.map((configOption) => ({
                                  config_id: configOption.id,
                                  option_id: configOption.values[0].id
                              }))
                }}
                onSubmit={async ({ billing_cycle_id, config_options }) => {
                    await createCart({
                        items: [
                            {
                                billing_cycle_id,
                                product_id: productAddon.id,
                                parent_id: addonParent.id,
                                config_options: config_options.map(({ config_id, option_id }) => ({
                                    config_id: Number(config_id),
                                    option_id: Number(option_id)
                                })),
                                order_type: 'all'
                            }
                        ]
                    })
                    setTemplate('confirmation')
                }}
            >
                {() => (
                    <Registration.Form id={'configOptionForm'}>
                        <Registration.Body>
                            {renderBillingCycle(productAddon, serviceConfigRef)}
                            {renderConfigOptions(productAddon)}
                        </Registration.Body>
                    </Registration.Form>
                )}
            </Formik>
        )
    }

    switch (template) {
        case 'confirmation':
            conditionalProps = {
                title: 'Confirm Addon',
                type: 'confirm',
                icon: 'info',
                description:
                    'When you confirm this submission, an invoice for your addon will be generated. This invoice must be paid in full in order for the addon to take affect.',
                conditions: {
                    state: status,
                    action: () => {
                        if (cart?.uuid) {
                            deleteCart(cart.uuid)
                        }
                        resetCreateCart()
                        close()
                        setTimeout(() => {
                            setTemplate('product')
                        }, 500)
                    }
                },
                actions: [
                    {
                        label: 'No, return to Services',
                        color: 'error',
                        type: 'button',
                        func: () => {
                            if (cart?.uuid) {
                                deleteCart(cart.uuid)
                            }

                            close()
                            setTimeout(() => {
                                setTemplate('product')
                            }, 500)
                        }
                    },
                    {
                        label: 'Generate Invoice',
                        color: 'primary',
                        disabled: isCheckingOutCart,
                        loading: isCheckingOutCart && 'Generating Invoice',
                        type: 'button',
                        func: async () => {
                            if (!cart?.uuid || !paymentMethods || !accountPaymentMethods) return

                            const creditCard = paymentMethods.find(({ name }: { name: string }) => name === 'Credit Card')
                            const defaultPayment = accountPaymentMethods.find(({ is_default }) => is_default)

                            if (!creditCard || !defaultPayment) return

                            const result = await checkoutCart({
                                uuid: cart.uuid,
                                payment_method_id: creditCard?.id,
                                payment_method_data_id: defaultPayment?.id
                            }).unwrap()

                            close()
                            resetCheckoutCart()
                            dispatch(setCart(undefined))
                            dispatch(serviceAPI.endpoints.service.initiate(addonParent?.id, { forceRefetch: true }))

                            if (result.invoice) {
                                setTimeout(() => {
                                    dispatch(setAppActiveInvoice({ invoice: result.invoice, status: true }))
                                }, 500)
                            }
                        }
                    }
                ]
            }
            break
        case 'configuration':
            conditionalProps = {
                title: 'Addon',
                type: 'custom',
                content: (
                    <Addon.Base
                        header={{ title: productAddon?.name ?? '' }}
                        footer={
                            <Addon.Footer>
                                <Button color={'flat'} type={'button'} onClick={() => close()}>
                                    Cancel
                                </Button>
                                <Button
                                    form='configOptionForm'
                                    loading={isCreateCartLoading && 'Configuring'}
                                    disabled={isCreateCartLoading}
                                    color={'primary'}
                                    type={'submit'}
                                >
                                    Configure
                                </Button>
                            </Addon.Footer>
                        }
                    >
                        {renderAddon()}
                    </Addon.Base>
                ),
                conditions: { state: status, action: () => close() }
            }
            break
        case 'product':
        default:
            conditionalProps = {
                title: 'Addon',
                type: 'custom',
                content: (
                    <Addon.Base
                        header={{ title: 'Addon Configuration' }}
                        footer={
                            <Addon.Footer>
                                <Button color={'flat'} type={'button'} onClick={() => close()}>
                                    Cancel
                                </Button>
                                <Button color={'primary'} type={'button'} onClick={() => setTemplate('confirmation')}>
                                    Configure
                                </Button>
                            </Addon.Footer>
                        }
                    >
                        <Table
                            render={{
                                columns: () => [
                                    {
                                        Header: 'Addon',
                                        accessor: 'addon'
                                    },
                                    {
                                        Header: 'Quantity',
                                        accessor: 'quantity'
                                    },
                                    {
                                        Header: 'Price',
                                        accessor: 'price'
                                    }
                                ],
                                data: () => data
                            }}
                            conditions={{
                                sort: false,
                                pagination: false
                            }}
                        />
                    </Addon.Base>
                ),
                conditions: { state: status, action: () => close() }
            }
    }

    /*   RENDER COMPONENT
     *****************************************************/
    return <Lightbox {...conditionalProps} />
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
