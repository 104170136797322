/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import type { TypedAddListener, TypedStartListening } from '@reduxjs/toolkit'
import { addListener, createListenerMiddleware } from '@reduxjs/toolkit'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { billingAPI } from 'api/billing'
import { serviceAPI } from 'api/service'

/**********************************************************************************************************
 *   HELPER IMPORTS
 **********************************************************************************************************/
import { AppDispatch, RootState } from 'store/store'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setAppActiveInvoice } from 'store/slices/appSlice'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
import { ServiceMetaPayload } from 'api/service/types'

/**********************************************************************************************************
 *   BASE EXPORTS
 **********************************************************************************************************/
export const serviceListenerMiddleware = createListenerMiddleware()

export type AppStartListening = TypedStartListening<RootState, AppDispatch>

export const startAppListening = serviceListenerMiddleware.startListening as AppStartListening

/**********************************************************************************************************
 *   CHANGE SERVICE PLAN AND OPEN INVOICE LIGHTBOX
 **********************************************************************************************************/
const params = new URLSearchParams(window.location.search)
const page = params.get('page')
const sort_by = params.get('sort_by')
const search_by = params.get('search_by')
const filter_by = params.get('filter_by')
const [filterKey, ...filterValue] = filter_by ? filter_by.split(',') : []
const metaPayload = {
    page,
    sort_by,
    search_by: !search_by ? undefined : { key: '', value: search_by },
    filter_by: !filter_by ? undefined : [filterKey, ...filterValue]
} satisfies ServiceMetaPayload

startAppListening({
    matcher: serviceAPI.endpoints.changePlan.matchFulfilled,
    effect: async ({ payload: invoice, meta }, listenerApi) => {
        if (invoice.status === 'unpaid') {
            listenerApi.dispatch(
                serviceAPI.util.updateQueryData('services', metaPayload, (draftServices) => {
                    draftServices.data = draftServices.data.map((draftService) => {
                        if (draftService.id === meta.arg.originalArgs.id) {
                            return {
                                ...draftService,
                                action_invoices: {
                                    ...draftService.action_invoices,
                                    change_plan: invoice
                                }
                            }
                        }

                        return draftService
                    })
                })
            )

            listenerApi.dispatch(billingAPI.util.prefetch('invoice', invoice.id, {}))
        }

        if (await listenerApi.condition(billingAPI.endpoints.invoice.matchFulfilled)) {
            listenerApi.dispatch(setAppActiveInvoice({ invoice, status: true }))
        }

        listenerApi.cancelActiveListeners()
    }
})

/**********************************************************************************************************
 *   RENEW SERVICE PLAN AND OPEN INVOICE LIGHTBOX
 **********************************************************************************************************/
startAppListening({
    matcher: serviceAPI.endpoints.renewService.matchFulfilled,
    effect: async ({ payload: invoice }, listenerApi) => {
        if (invoice.status === 'unpaid') {
            listenerApi.dispatch(billingAPI.util.prefetch('invoice', invoice.id, {}))
        }

        if (await listenerApi.condition(billingAPI.endpoints.invoice.matchFulfilled)) {
            listenerApi.dispatch(setAppActiveInvoice({ invoice, status: true }))
        }

        listenerApi.cancelActiveListeners()
    }
})

/**********************************************************************************************************
 *   CANCEL CHANGE SERVICE PLAN REQUEST AND DELETE INVOICE FROM ACTION INVOICES
 **********************************************************************************************************/
startAppListening({
    matcher: serviceAPI.endpoints.cancelChangePlanRequest.matchFulfilled,
    effect: async (action, listenerApi) => {
        listenerApi.dispatch(
            serviceAPI.util.updateQueryData('services', metaPayload, (draftServices) => {
                draftServices.data = draftServices.data.map((draftService) => {
                    if (draftService.id === action.meta.arg.originalArgs) {
                        return {
                            ...draftService,
                            action_invoices: {
                                ...draftService.action_invoices,
                                change_plan: null
                            }
                        }
                    }

                    return draftService
                })
            })
        )

        listenerApi.cancelActiveListeners()
    }
})

export const addAppListener = addListener as TypedAddListener<RootState, AppDispatch>
