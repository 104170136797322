/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Formik, FormikProps } from 'formik'
import { useRef } from 'react'
import * as Yup from 'yup'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { billingAPI, usePayInvoiceMutation } from 'api/billing'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { useApplyAccountCreditToInvoice } from 'containers/billing/utils'
import { useAppDispatch, useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   STYLE
 **********************************************************************************************************/
import { Payment } from 'components/invoice/invoice.styles'

/**********************************************************************************************************
 *   INTERFACE & TYPES
 **********************************************************************************************************/
import { PaymentMethod } from 'models/billing'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
interface CreditCardFormProps {
    accountCreditToApply: number
    selectedPaymentMethod?: PaymentMethod
}

interface CardPayInvoiceForm {
    cardHolder: string
    cardNumber: string
    cvc: string
    expiryDate: string
    save_details: boolean
}

export default function CreditCardForm({ accountCreditToApply, selectedPaymentMethod }: CreditCardFormProps) {
    const dispatch = useAppDispatch()
    const { appActiveInvoice } = useAppSelector((state) => state.app)
    const creditCardRef = useRef<FormikProps<CardPayInvoiceForm>>(null)
    const [payInvoice] = usePayInvoiceMutation({ fixedCacheKey: 'pay-invoice' })
    const { applyAccountCreditToInvoice } = useApplyAccountCreditToInvoice()

    return (
        <Formik
            innerRef={creditCardRef}
            initialValues={{
                cardHolder: '',
                cardNumber: '',
                cvc: '',
                expiryDate: '',
                save_details: false
            }}
            validationSchema={Yup.object({
                cardHolder: Yup.string().required('Required'),
                cardNumber: Yup.string().required('Card Number is required'),
                expiryDate: Yup.string().required('Required'),
                save_details: Yup.boolean()
            })}
            onSubmit={async ({ cardNumber, cardHolder, expiryDate, cvc, save_details }) => {
                const formattedExpiry = expiryDate.split('/')

                if (!appActiveInvoice?.invoice?.id) return

                const { isInvoiceStillUnpaid, isSuccess } = await applyAccountCreditToInvoice(appActiveInvoice.invoice.id, accountCreditToApply)

                if (isSuccess && isInvoiceStillUnpaid) {
                    await payInvoice({
                        id: String(appActiveInvoice?.invoice?.id),
                        payment_method_id: selectedPaymentMethod?.id || 4,
                        payment_data: {
                            payment_method_id: selectedPaymentMethod?.id || 4,
                            card_holder: cardHolder,
                            cvv: cvc,
                            card_number: cardNumber.replace(/ /g, ''),
                            expiry: `${formattedExpiry[0]}/${formattedExpiry[1]}`.replace(/ /g, ''),
                            save_details: Boolean(save_details)
                        }
                    })
                }

                dispatch(billingAPI.util.invalidateTags(['Payment-Methods-Data']))
                dispatch(billingAPI.endpoints.invoice.initiate(appActiveInvoice.invoice.id, { forceRefetch: true })).unwrap()
            }}
        >
            {({ isSubmitting }) => (
                <Payment.Form id='creditCardForm'>
                    <Payment.Divider>
                        <Payment.Line />
                        <Payment.Text>Pay with new card</Payment.Text>
                    </Payment.Divider>
                    <Field
                        id={'cardHolder'}
                        label={'Cardholder Name'}
                        name={'cardHolder'}
                        type={'text'}
                        disabled={isSubmitting}
                        autoComplete={'name'}
                        component={NXUIForm.InputField}
                    />
                    <Field id={'card_details'} label={'Card Details'} expiry cvc disabled={isSubmitting} component={NXUIForm.PaymentField} />
                    <Field
                        name={'save_details'}
                        type={'checkbox'}
                        disabled={isSubmitting}
                        description={'Save Details'}
                        component={NXUIForm.CheckboxField}
                    />
                </Payment.Form>
            )}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
