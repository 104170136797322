/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useState, useEffect } from 'react'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { usePaymentMethodsQuery, useAccountPaymentMethodsQuery } from 'api/billing'

/**
 * Returns a list of all payment methods that can be used to pay for something. Can be used in the shop, and invoice payments
 */
export function usePaymentMethods() {
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<number | null>(null)
    const [selectedSavedPaymentMethodId, setSelectedSavedPaymentMethodId] = useState<number | null>(null)

    const { data: paymentMethodsData, isFetching: isPaymentMethodsFetching } = usePaymentMethodsQuery()
    const { data: accountPaymentMethodsData, isFetching: isAccountPaymentMethodsFetching } = useAccountPaymentMethodsQuery()

    const selectedPaymentMethod = paymentMethodsData?.find((method) => method.id === selectedPaymentMethodId)
    const selectedSavedPaymentMethod = accountPaymentMethodsData?.find((savedMethod) => savedMethod.id === selectedSavedPaymentMethodId)

    function _setSelectedPaymentMethodId(id: number) {
        setSelectedSavedPaymentMethodId(null)
        setSelectedPaymentMethodId(id || null)
    }

    function _setSelectedSavedPaymentMethodId(id?: number) {
        setSelectedPaymentMethodId(null)
        // When "saved payment methods" is clicked, have the default saved method be initially selected
        setSelectedSavedPaymentMethodId(id || accountPaymentMethodsData?.find(({ is_default }) => is_default)?.id || null)
    }

    // When all the data has loaded, set the selected method to be the default, or if there is no saved methods, the first available payment method
    useEffect(() => {
        if (!paymentMethodsData || !accountPaymentMethodsData) return

        const defaultSavedPaymentMethod = accountPaymentMethodsData.find(({ is_default }) => is_default)

        if (defaultSavedPaymentMethod) _setSelectedSavedPaymentMethodId(defaultSavedPaymentMethod.id)
        else _setSelectedPaymentMethodId(paymentMethodsData[0]?.id)
    }, [paymentMethodsData, accountPaymentMethodsData])

    return {
        paymentMethods: paymentMethodsData || [],
        selectedPaymentMethod,
        setSelectedPaymentMethodId: _setSelectedPaymentMethodId,
        savedPaymentMethods: accountPaymentMethodsData || [],
        selectedSavedPaymentMethod,
        setSelectedSavedPaymentMethodId: _setSelectedSavedPaymentMethodId,
        isFetching: isPaymentMethodsFetching || isAccountPaymentMethodsFetching
    }
}
