/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { memo } from 'react'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { FooterDetailStyles as S } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TFooterDetailIcon = React.FC<
    React.RefAttributes<SVGSVGElement> &
        React.SVGProps<SVGSVGElement> & {
            icon: React.FC<React.RefAttributes<SVGSVGElement>>
        }
>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Helper component for applying styling to any element passed as a prop.
 */
const _FooterDetailIcon: TFooterDetailIcon = ({ icon: Icon, ...props }) => {
    /***** RENDER *****/
    return (
        <S.IconWrapper>
            <Icon height={16} {...props} />
        </S.IconWrapper>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/***** EXPORTS *****/
export const FooterDetailIcon = memo(_FooterDetailIcon)
