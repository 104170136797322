/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { LightboxFooterStyles as S } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TLightboxFooter = React.FC<{
    children: React.ReactNode
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const LightboxFooter: TLightboxFooter = ({ children }) => {
    /***** RENDER *****/
    return (
        <S.FlexWrapper fullWidth justify='flex-end' align='center'>
            {children}
        </S.FlexWrapper>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
