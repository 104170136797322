/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { FormikConsumer, useFormikContext } from 'formik'
import React from 'react'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Flex } from 'components/utilities/Flex'
import { WrapWithComponent } from 'components/wrapWithComponent'
import { noop } from 'helpers/utils'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { StandardSearchForm } from './form'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { WebsiteHomeStyles as S } from 'containers/website/home/style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type THeroBannerSearch = React.FC<{
    /**
     * Name of the internal input field
     */
    name?: string

    /**
     * If custom is provided, do not automatically perform search logic and instead
     * allow the parent to provide a form state using Formik
     */
    custom?: boolean

    className?: string

    /**
     * Placeholder text of the search field
     */
    placeholder?: string
}>

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
/**
 * Since the HeroBanner form is used across 3 pages and can conditionally be wrapped in a form provider, the FormikContext
 * is intended to optionally not be used within a form context, however, the useFormikContext hook will throw a warning
 * if it is not used within a form context. This hook will silence the warning if the context is not found.
 */
const useSilencedFormikContext = () => {
    // Save the original console.warn function
    const originalWarn = console.warn

    // Override console.warn to do nothing
    console.warn = noop

    // Get the formik context (ignoring warnings if not inside a form)
    const formikContext = useFormikContext()

    // Restore the original console.warn function
    console.warn = originalWarn

    // Return the formik context
    return formikContext
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _HeroBannerSearch: THeroBannerSearch = ({ name, custom, className, placeholder }) => {
    /***** HOOKS *****/
    const formikContext = useSilencedFormikContext()

    /***** RENDER HELPERS *****/
    if (!formikContext && custom) {
        throw new Error('HeroBannerSearch must be wrapped in a FormikProvider when provided with the custom flag')
    }

    /***** RENDER *****/
    return (
        <div className={className}>
            <WrapWithComponent component={StandardSearchForm} wrap={!custom}>
                <FormikConsumer>
                    {({ isSubmitting }) => (
                        <Flex gap={10}>
                            <S.InputField name={name ?? 'search'} placeholder={placeholder ?? 'Start your search here'} embedded />
                            <S.Button disabled={isSubmitting} loading={isSubmitting && 'Searching'}>
                                <Flex justify='space-around' align='center' fullWidth>
                                    Search <S.Icons.MagnifyingGlass height={24} />
                                </Flex>
                            </S.Button>
                        </Flex>
                    )}
                </FormikConsumer>
            </WrapWithComponent>
        </div>
    )
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
