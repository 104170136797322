/* eslint-disable import/no-named-as-default-member */
/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import PropTypes from 'prop-types'
import { useId } from 'react'
import styled from 'styled-components'
import * as Theme from '../../theme/theme'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Loader } from '../loader'
import { Table } from '../table'

/**********************************************************************************************************
 *   STYLE
 **********************************************************************************************************/
const Card = {
    Wrapper: styled.section`
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 16px;
        min-width: 280px;
        height: min-content;
        font-size: 1.4rem;
        background-color: ${(props) => props.theme[`background--000`]};
        border-radius: 16px;
        box-shadow: 0 1px 3px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--100`], `0.1`)},
            0 1px 2px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--100`], `0.06`)};
        ${Theme.Functions.setFont('Inter', 400)};
    `,
    Header: styled.header`
        display: inline-flex;
        align-items: center;
        height: 40px;
        width: 100%;
        padding: 0 24px;
        background-color: ${(props) => props.theme[`background--100`]};
        border-radius: 16px 16px 0 0;
        font-size: 1.2rem;
        color: ${(props) => props.theme[`text--100`]};
        letter-spacing: -0.25px;
        text-transform: uppercase;
        border-bottom: 1px solid ${(props) => props.theme[`border--100`]};
        ${Theme.Functions.setFont('Inter', 600)};

        &.nxCard__grid {
            border-bottom: none;
        }
    `,
    Grid: styled.div`
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    `,
    Table: styled.div`
        display: flex;
        width: 100%;
    `,
    Body: styled.div`
        display: inline-flex;
        width: 100%;
        font-size: 1.4rem;
        letter-spacing: -0.25px;
        ${Theme.Functions.setFont('Inter', 400)};
    `,
    Footer: styled.footer`
        width: 100%;
    `,
    Loader: styled(Loader.Basic)`
        margin: 16px auto;
    `,
    Row: styled.div`
        display: inline-flex;
        flex-wrap: wrap;
        width: 100%;
        min-width: 280px;

        &:last-of-type {
            border-radius: 0 0 16px 16px;

            > div:first-of-type {
                border-radius: 0 0 0 16px;
            }

            > div:last-of-type {
                border-radius: 0 0 16px 0;
            }
        }
    `,
    Column: {
        Base: styled.div`
            display: inline-flex;
            flex: 1 1 0;
            background-color: ${(props) => props.theme[`background--000`]};
            min-width: 280px;
            padding: 18px 24px;
            box-shadow: 1px 0 0 0 ${(props) => props.theme[`gray--100`]}, 0 1px 0 0 ${(props) => props.theme[`gray--100`]},
                1px 1px 0 0 ${(props) => props.theme[`gray--100`]}, 1px 0 0 0 ${(props) => props.theme[`gray--100`]} inset,
                0 1px 0 0 ${(props) => props.theme[`gray--100`]} inset;

            &:first-of-type {
                box-shadow: 1px 0 0 0 ${(props) => props.theme[`gray--100`]}, 0 1px 0 0 ${(props) => props.theme[`gray--100`]},
                    1px 1px 0 0 ${(props) => props.theme[`gray--100`]}, 0 0 0 0 ${(props) => props.theme[`gray--100`]} inset,
                    0 1px 0 0 ${(props) => props.theme[`gray--100`]} inset;
            }

            ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-sm`])} {
                padding: 10px 15px;
                flex-direction: column;
            }
        `,
        Render: styled.div`
            display: inline-flex;
            align-items: center;
            width: 100%;
            color: ${(props) => props.theme[`text--300`]};
            font-size: 1.4rem;
            letter-spacing: -0.25px;
            ${Theme.Functions.setFont('Inter', 500)};
        `,
        Title: styled.div`
            display: inline-flex;
            align-items: center;
            height: 100%;
            min-width: 130px;
            margin-right: 10px;
            color: ${(props) => props.theme[`text--100`]};
            font-size: 1.4rem;
            letter-spacing: -0.25px;
            ${Theme.Functions.setFont('Inter', 600)};

            ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-sm`])} {
                margin-right: 15px;
                min-width: 100%;
            }
        `
    }
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const CardComponent = ({ className, footer, grid = [], table, title, loading, children }) => {
    const validateGrid = () => grid?.length > 0
    // eslint-disable-next-line no-prototype-builtins
    const validateTable = () => table?.hasOwnProperty('render')
    const validateLoading = () => !!loading

    function renderGrid() {
        return grid.map((row) => {
            return (
                <Card.Row key={useId()}>
                    {row.map((column) => {
                        return (
                            <Card.Column.Base key={useId()} className={`${column.className ? ` ${column.className}` : ``}`}>
                                <Card.Column.Title>{column.title}</Card.Column.Title>
                                <Card.Column.Render>{column.render}</Card.Column.Render>
                            </Card.Column.Base>
                        )
                    })}
                </Card.Row>
            )
        })
    }

    function renderTable() {
        return <Table {...table} conditions={{ ...table?.conditions, embedded: true }} />
    }

    function renderLoader() {
        return <Card.Loader message={loading?.length > 0 ? loading : 'Loading...'} />
    }

    function renderChildren() {
        switch (true) {
            case validateLoading():
                return renderLoader()
            case validateGrid():
                return renderGrid()
            case validateTable():
                return renderTable()

            default:
                return children
        }
    }

    return (
        <Card.Wrapper className={`${className ? ` ${className}` : ``}`}>
            {title ? <Card.Header className={`${validateGrid() && 'nxCard__grid'}`}>{title}</Card.Header> : ''}
            {validateGrid() ? (
                <Card.Grid>{renderChildren()}</Card.Grid>
            ) : validateTable() ? (
                <Card.Table>{renderChildren()}</Card.Table>
            ) : (
                renderChildren()
            )}
            {footer ? <Card.Footer>{footer}</Card.Footer> : ''}
        </Card.Wrapper>
    )
}

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
CardComponent.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    grid: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.shape({
                className: PropTypes.string,
                title: PropTypes.string,
                render: PropTypes.any
            })
        )
    ),
    footer: PropTypes.any
}

export { CardComponent as Card }
