/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { createSlice } from '@reduxjs/toolkit'

/**********************************************************************************************************
 *    SLICE
 **********************************************************************************************************/
export const supportSlice = createSlice({
    name: 'support',
    initialState: {
        supportOpen: false,
        isSubmitting: false
    },
    reducers: {
        setSupportOpen: (state, { payload }) => {
            state.supportOpen = payload
        },
        setIsSubmitting: (state, { payload }) => {
            state.isSubmitting = payload
        }
    }
})

export const { setSupportOpen, setIsSubmitting } = supportSlice.actions

export default supportSlice.reducer
