/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useEffect, useRef, useState } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Dropdown } from '../../dropdown'
import { RenderErrorIcon, RenderInputLabel, Style } from '../util'
import { Input } from './input'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled from 'styled-components'
import * as Theme from '../../../theme/theme'

export const Prefix = {
    Wrapper: styled.div`
        position: relative;
        display: inline-flex;
        flex-direction: row;
        width: 100%;
    `,
    Field: styled.input`
        ${Style.Field};
        padding-right: 40px;
        border-radius: 0 6px 6px 0;
    `,
    Text: styled.span`
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 12px;
        background-color: ${(props) => props.theme[`background--100`]};
        font-size: 1.2rem;
        letter-spacing: -0.25px;
        color: ${(props) => props.theme[`text--100`]};
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        ${Theme.Functions.setFont('Inter', 700)};
    `,
    Dropdown: styled(Dropdown)`
        position: relative;
        width: fit-content;
        margin: 0;
        border-right: 1px solid ${(props) => props.theme[`border--100`]};
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;

        > div {
            cursor: pointer;
            border: 0;
            border-radius: 0;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            background-color: ${(props) => props.theme[`background--100`]};

            span {
                color: ${(props) => props.theme[`text--100`]};
                ${Theme.Functions.setFont('Inter', 700)};
            }
        }
    `
}

export function stripPrefixFromValue(values, field, activePrefix) {
    const prefixValue = values[field.name]?.substring(0, activePrefix?.length)

    return values[field.name] && activePrefix && prefixValue === activePrefix ? values[field.name].replace(activePrefix, '') : values[field.name]
}

export function handlePrefixRender(prefixes, activePrefix, setActivePrefix, error) {
    prefixes?.length === 1 && !activePrefix && setActivePrefix(prefixes[0])

    return prefixes?.length > 1 ? (
        <Prefix.Dropdown
            attached={'right'}
            options={prefixes.map((prefix) => {
                return {
                    label: prefix,
                    onClick: () => {
                        setActivePrefix(prefix)
                    }
                }
            })}
        >
            {prefixes[0]}
        </Prefix.Dropdown>
    ) : prefixes?.length === 1 ? (
        <Prefix.Text error={error}>{prefixes[0]}</Prefix.Text>
    ) : (
        ''
    )
}

/**********************************************************************************************************
 *   PREFIX FIELD
 **********************************************************************************************************/
export const PrefixField = ({ embedded, required, label, field, disabled, prefixes, form: { values, touched, errors, isSubmitting }, ...props }) => {
    /*   REF
     *****************************************************/
    const inputRef = useRef()

    /*   STATE
     *****************************************************/
    const [activePrefix, setActivePrefix] = useState(undefined)
    const [hasFocus, setFocus] = useState(false)

    /*   EFFECTS
     *****************************************************/
    useEffect(() => {
        if (document.hasFocus() && inputRef.current.contains(document.activeElement)) {
            setFocus(true)
        }
        prefixes?.length === 1 && !activePrefix && setActivePrefix(prefixes[0])
    }, [activePrefix])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Input.Wrapper className={props.className} embedded={embedded}>
            {RenderInputLabel(embedded, required, label, field)}
            <Input.Column focus={hasFocus} error={touched[field.name] && errors[field.name]}>
                <Prefix.Wrapper>
                    {handlePrefixRender(prefixes, activePrefix, setActivePrefix, touched[field.name] && errors[field.name])}
                    <Prefix.Field
                        {...field}
                        {...props}
                        onFocus={() => setFocus(true)}
                        onBlur={(e) => {
                            field.onBlur && field.onBlur(e)
                            setFocus(false)
                        }}
                        ref={inputRef}
                        disabled={isSubmitting || disabled}
                        value={stripPrefixFromValue(values, field, activePrefix)}
                    />
                </Prefix.Wrapper>
                {RenderErrorIcon(field.name, touched, errors)}
            </Input.Column>
        </Input.Wrapper>
    )
}
