/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import {
    DocumentArrowDownIcon,
    GlobeAltIcon,
    HomeIcon,
    MagnifyingGlassCircleIcon,
    QuestionMarkCircleIcon,
    ShoppingBagIcon
} from '@heroicons/react/20/solid'
import { AnimatePresence } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

/**********************************************************************************************************
 *   COMPONENT IMPORTS
 **********************************************************************************************************/
import { Header } from 'components/header'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setRedirect, triggerRedirect } from 'store/slices/appSlice'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { useAppDispatch, useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'helpers/hooks/useAppViewport'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { titleCase } from 'helpers/utils'
import { Helmet } from 'react-helmet-async'
import { GuestRoute } from 'router/guestRouter.styles'
import { websiteRoutes } from './consts'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function GuestRoot() {
    /***** HOOKS *****/
    const headerRef = useRef<HTMLElement>(null)
    const isMobile = useAppViewport(['xs', 'sm', 'md'])
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const {
        appSettings: {
            provider: { provider_name, logo_url, application_name },
            section: { website, login }
        },
        appActiveRouter
    } = useAppSelector((state) => state.app)

    /***** EFFECTS *****/
    useEffect(() => {
        if (appActiveRouter.meta.redirect) {
            dispatch(triggerRedirect(navigate))
        }
    }, [appActiveRouter.meta.redirect])

    /***** EFFECTS *****/
    useEffect(() => {
        if ((Object.values(websiteRoutes) as Array<string>).includes(pathname)) {
            dispatch(setRedirect(false))
            headerRef.current?.scrollIntoView()
        }
    }, [pathname])

    /***** FUNCTIONS *****/
    function getPageTitle() {
        const path = pathname.split('/')
        const page = path.length >= 3 && path[1] === 'login' ? path[2] : path[1]

        // Handle website routes
        if (website) {
            if (Object.values(websiteRoutes).find((route) => route === pathname)) {
                const title = path[1] === '' ? 'Home' : path[1]
                return `${titleCase(title)} - ${application_name}`
            }
        }

        return path.length === 0 || path.every((pathname) => pathname === '') ? 'Loading...' : `${titleCase(page)} - ${application_name}`
    }

    /***** RENDER *****/
    return (
        <GuestRoute.Outer>
            <Helmet>
                <title>{getPageTitle()}</title>
            </Helmet>
            <GuestRoute.Container>
                {((Object.values(websiteRoutes) as Array<string>).includes(pathname) || pathname.includes('/shop')) && (
                    <Header
                        ref={headerRef}
                        type={website ? 'sidebar' : 'original'}
                        logo={<Header.Logo src={logo_url} alt={provider_name} />}
                        login={
                            !!login && (
                                <GuestRoute.HeaderButton size='small' to='/login'>
                                    <Header.Action />
                                    {!isMobile && <GuestRoute.HeaderActionText>Login/Sign Up</GuestRoute.HeaderActionText>}
                                </GuestRoute.HeaderButton>
                            )
                        }
                    >
                        <Header.Nav>
                            <Header.Nav.Item path={websiteRoutes.ROOT} mobileIcon={HomeIcon} exact permanent>
                                Home
                            </Header.Nav.Item>
                            <Header.Nav.Item path={websiteRoutes.DOMAIN_PRICING} mobileIcon={GlobeAltIcon} exact permanent>
                                Domain Names
                            </Header.Nav.Item>
                            <Header.Nav.Item path={websiteRoutes.ABOUT_US} mobileIcon={QuestionMarkCircleIcon} exact permanent>
                                About Us
                            </Header.Nav.Item>
                            <Header.Nav.Item path={websiteRoutes.TERMS_OF_SERVICE} mobileIcon={DocumentArrowDownIcon} exact>
                                Legal Document
                            </Header.Nav.Item>
                            <Header.Nav.Item path={websiteRoutes.WHOIS} mobileIcon={MagnifyingGlassCircleIcon} exact>
                                Whois
                            </Header.Nav.Item>
                            <Header.Nav.Item path='/shop' mobileIcon={ShoppingBagIcon} permanent>
                                Shop
                            </Header.Nav.Item>
                        </Header.Nav>
                    </Header>
                )}
                <AnimatePresence mode={'wait'}>
                    <Outlet />
                </AnimatePresence>
            </GuestRoute.Container>
        </GuestRoute.Outer>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
