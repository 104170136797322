/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useState } from 'react'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { S } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TCheckBox = React.FC<{
    children: React.ReactNode
    checked?: boolean
    onClick?: () => void
    disabled?: boolean
    className?: string
    intrinsic?: React.InputHTMLAttributes<HTMLInputElement>
    boxPosition?: 'left' | 'right'
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _CheckBox: TCheckBox = ({ checked = false, children, disabled, className, onClick, boxPosition, intrinsic }) => {
    const [isFocused, setIsFocused] = useState(false)

    /***** FUNCTIONS *****/
    function toggleCheckBox(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        onClick?.()
    }

    function onBlur(e: React.FocusEvent<HTMLInputElement>) {
        setIsFocused(false)
        intrinsic?.onBlur?.(e)
    }

    function onFocus(e: React.FocusEvent<HTMLInputElement>) {
        setIsFocused(true)
        intrinsic?.onFocus?.(e)
    }

    /***** RENDER *****/
    return (
        <S.Label className={className}>
            <S.InternalFlex
                focused={isFocused}
                checked={checked}
                fullWidth
                direction={boxPosition === 'right' ? 'row' : 'row-reverse'}
                align='center'
                gap={20}
            >
                <S.ChildrenWrapper>{children}</S.ChildrenWrapper>
                <S.ToggleBox />
            </S.InternalFlex>
            <S.Input
                type='checkbox'
                checked={checked}
                disabled={disabled}
                onChange={toggleCheckBox}
                {...intrinsic}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        </S.Label>
    )
}
