/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useToaster } from 'react-hot-toast'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { Loader } from 'components/loader'
import { Alert as AlertComponent, Fade, Theme } from 'nxui/src'

/**********************************************************************************************************
 *   ROUTERS
 **********************************************************************************************************/
import GuestRouter from 'router/guestRouter'
import UserRouter from 'router/userRouter'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useInitialiseApplicationQuery } from 'api/app'
import { useGetSessionQuery } from 'api/authentication'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setAppLinkTypePaymentState } from 'store/slices/appSlice'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { useLocalStorage } from 'helpers/hooks'
import { useAppDispatch, useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
const AppGlobalStyles = createGlobalStyle`
    html {
        font-display: swap;
        text-rendering: geometricPrecision;
        font-feature-settings: "kern" 1;
        font-kerning: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        ${Theme.Functions.setBaseLine()};
    }

    html, body, #app-root {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        min-width: 390px;

        .lightbox {
            max-width: 500px;
        }

        .grecaptcha-badge {
            visibility: hidden !important;
        }
    }

    

`

const Alert = {
    Provider: styled.div`
        z-index: 21;
        position: absolute;
        top: 0px;
        right: 0px;
    `
}

const Application = {
    Transition: styled(Fade)`
        position: absolute !important;
        display: flex;
        flex-flow: column nowrap;
        background-color: ${(props) => props.theme['root--background']};
        ${Theme.Functions.inset()};
    `
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const App = () => {
    const dispatch = useAppDispatch()
    const { appInitialised, appActiveRouter, appSettings } = useAppSelector((state) => state.app)
    const [redirect] = useLocalStorage('redirect', { isRedirect: false, invoice: undefined, order: undefined })

    /****************************************************
     *  TOAST
     ****************************************************/
    const { toasts, handlers } = useToaster()
    const { calculateOffset, updateHeight } = handlers

    /****************************************************
     *  PAYMENT GATEWAY REDIRECT
     ****************************************************/
    const params = new URLSearchParams(window.location.search)
    const canceled = params.get('canceled')
    const success = params.get('success')
    const payerId = params.get('PayerID')
    const token = params.get('token')

    // Handle user returning from stripe or paypal checkout
    useEffect(() => {
        if (!redirect.order && !redirect.invoice) return

        if (token && payerId) {
            dispatch(
                setAppLinkTypePaymentState({
                    invoiceId: redirect.invoice,
                    orderId: redirect.order,
                    processingStatus: 'in-progress',
                    paymentType: 'PayPal',
                    paymentStatus: 'success'
                })
            )
            return
        }

        if (canceled) {
            dispatch(
                setAppLinkTypePaymentState({
                    invoiceId: redirect.invoice,
                    orderId: redirect.order,
                    processingStatus: 'finish',
                    paymentType: 'Stripe',
                    paymentStatus: 'canceled'
                })
            )
            return
        }

        if (success) {
            dispatch(
                setAppLinkTypePaymentState({
                    invoiceId: redirect.invoice,
                    orderId: redirect.order,
                    processingStatus: 'in-progress',
                    paymentType: 'Stripe',
                    paymentStatus: 'success'
                })
            )
            return
        }

        // If the user has used the browser back button from the stipe or paypal checkout
        dispatch(
            setAppLinkTypePaymentState({
                invoiceId: redirect.invoice,
                orderId: redirect.order,
                processingStatus: 'finish',
                paymentType: 'Unknown',
                paymentStatus: 'used-browser-back'
            })
        )
    }, [])

    /****************************************************
     *  GET APPLICATION SETTINGS & THEME
     ****************************************************/
    const { isSuccess } = useInitialiseApplicationQuery()
    useGetSessionQuery(undefined, { skip: !appInitialised })

    function appDisplay() {
        switch (appActiveRouter.template) {
            case 'user':
                return <UserRouter />
            case 'guest':
                return <GuestRouter />
            case 'loading':
            default:
                return <Loader width={70} height={70} message={appActiveRouter.meta?.message} />
        }
    }

    /****************************************************
     *  FAVICON
     ****************************************************/
    function getFaviconHref() {
        if (!appInitialised) return

        const { url, favicon_url } = appSettings.provider
        return favicon_url?.startsWith('http') ? favicon_url : `${url}/${favicon_url}`
    }

    /****************************************************
     *  GET APPLICATION SETTINGS & THEME
     ****************************************************/
    return (
        <ThemeProvider theme={appSettings.theme}>
            <AppGlobalStyles />
            <Alert.Provider>
                {toasts.map((t) => (
                    <AlertComponent
                        key={t.id}
                        t={t}
                        updateHeight={updateHeight}
                        offset={calculateOffset(t, {
                            reverseOrder: false
                        })}
                    />
                ))}
            </Alert.Provider>
            <Helmet>
                <link rel='preconnect' href='https://fonts.googleapis.com' />
                <link rel='preconnect' href='https://fonts.gstatic.com' />
                <link href='https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap' rel='stylesheet' />
                <link href='https://rsms.me/inter/inter.css' rel='stylesheet' media='print' />
                {isSuccess && <link rel='preload' as='image' href={appSettings.provider.logo_url} />}
                {appInitialised && <link id='favicon' rel='icon' href={getFaviconHref()} />}
            </Helmet>
            <Application.Transition when={appActiveRouter.template}>{appDisplay()}</Application.Transition>
        </ThemeProvider>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
