/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

/**********************************************************************************************************
 *   API
 **********************************************************************************************************/
import { accountAPI } from 'api/account'
import { authAPI } from 'api/authentication'
import { shopAPI } from 'api/shop'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
import { User } from 'models/account'
import { AuthorisedAccount, Settings } from 'models/app'
import { ISession, IUserTwoFactorMethod, TTwoFactorMethodList } from 'models/authentication'
import { Invoice } from 'models/billing'
import { ICart, ICartItem } from 'models/shop/cart'
import { IProduct } from 'models/shop/product'

/**********************************************************************************************************
 *   HELPER
 **********************************************************************************************************/
import Config from 'data/config'

/**********************************************************************************************************
 *    SLICE
 **********************************************************************************************************/
interface Router {
    template: 'loading' | 'user' | 'guest'
    meta: {
        loading: boolean
        message?: string
        redirect?: string | boolean
    }
}

interface ActiveInvoice {
    invoice: Invoice | undefined
    status: boolean
}

interface AppState {
    appInitialised: boolean
    appSession: ISession
    appSettings: Settings
    appAuthentication: {
        authorisedAccounts?: Array<AuthorisedAccount>
        twoFactor: {
            primary?: IUserTwoFactorMethod
            backup?: TTwoFactorMethodList
            requested?: IUserTwoFactorMethod
        }
    }
    appActiveRouter: Router
    appSwitchAccount: boolean
    appActiveInvoice: ActiveInvoice
    appLinkTypePaymentState: {
        invoiceId?: string
        orderId?: string
        processingStatus: 'idle' | 'in-progress' | 'finish'
        paymentType?: 'PayPal' | 'Stripe' | 'Unknown'
        paymentStatus?: 'canceled' | 'success' | 'used-browser-back'
    }
    appTokenTypeOrderPaymentState: {
        status: 'idle' | 'success' | 'error' | 'ready'
        paymentMethodId: number | null
        invoiceId: number | null
    }
    cart?: ICart
    shopConfigure?: {
        service: ICartItem
        product: IProduct
    }
}

const initialState: AppState = {
    appInitialised: false,
    appSession: {
        authenticated: false,
        two_factor_required: false
    },
    appAuthentication: {
        authorisedAccounts: undefined,
        twoFactor: {
            primary: undefined,
            backup: undefined
        }
    },
    appSettings: {
        section: Config.Section,
        provider: Config.Provider,
        theme: Config.Theme,
        currency: Config.Currency
    },
    appActiveRouter: {
        template: 'loading',
        meta: {
            loading: false,
            message: 'Initialising...'
        }
    },
    appSwitchAccount: false,
    appActiveInvoice: {
        invoice: undefined,
        status: false
    },
    appLinkTypePaymentState: {
        invoiceId: undefined,
        orderId: undefined,
        processingStatus: 'idle'
    },
    appTokenTypeOrderPaymentState: {
        status: 'idle',
        invoiceId: null,
        paymentMethodId: null
    }
}

export const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        setRedirect: (state, action: PayloadAction<typeof state.appActiveRouter.meta.redirect>) => {
            state.appActiveRouter.meta.redirect = action.payload
        },
        triggerRedirect: (state, action: PayloadAction<(url: string) => void>) => {
            if (state.appActiveRouter.meta.redirect) {
                action.payload(state.appActiveRouter.meta.redirect as string)
            }
            state.appActiveRouter.meta.redirect = false
        },
        logoutReset: (state) => {
            state.appInitialised = true
        },
        setAppInitialised: (state, action: PayloadAction<boolean>) => {
            state.appInitialised = action.payload
        },
        setAppSettings: (state, action: PayloadAction<Settings>) => {
            state.appSettings = action.payload
        },
        setAppSession: (state, action: PayloadAction<ISession>) => {
            state.appSession = action.payload
        },
        setUser: (state, action: PayloadAction<User>) => {
            state.appSession = {
                ...state.appSession,
                user: action.payload
            }
        },
        setAuthorisedAccounts: (state, action: PayloadAction<Array<AuthorisedAccount>>) => {
            state.appAuthentication = {
                ...state.appAuthentication,
                authorisedAccounts: action.payload
            }
        },
        setRequestedMethod: (state, action: PayloadAction<IUserTwoFactorMethod>) => {
            state.appAuthentication.twoFactor.requested = action.payload
        },
        setSwitchAccountOpen: (state, action: PayloadAction<boolean>) => {
            state.appSwitchAccount = action.payload
        },
        setAppActiveRouter: (state, action: PayloadAction<Router>) => {
            state.appActiveRouter = action.payload
        },
        setAppActiveInvoice: (state, action: PayloadAction<{ invoice: Invoice | undefined; status: boolean }>) => {
            state.appActiveInvoice = action.payload
        },
        setAppLinkTypePaymentState: (state, action: PayloadAction<AppState['appLinkTypePaymentState']>) => {
            state.appLinkTypePaymentState = action.payload
        },
        readyAppTokenTypeOrderPayment: (state, action: PayloadAction<Omit<AppState['appTokenTypeOrderPaymentState'], 'status'>>) => {
            state.appTokenTypeOrderPaymentState = { ...action.payload, status: 'ready' }
        },
        updateAppTokenTypeOrderPaymentStatus: (state, action: PayloadAction<AppState['appTokenTypeOrderPaymentState']['status']>) => {
            state.appTokenTypeOrderPaymentState = {
                status: action.payload,
                paymentMethodId: null,
                invoiceId: null
            }
        },
        setCart: (state, action: PayloadAction<ICart | undefined>) => {
            state.cart = action.payload
        },
        setPromo: (state, action: PayloadAction<string | undefined>) => {
            if (!state.cart) return

            state.cart = {
                ...state.cart,
                promo_code: action.payload
            }
        }
    },
    extraReducers: (builder) => {
        /**********************************************************************************************************
         *   LOGOUT
         **********************************************************************************************************/
        builder
            .addMatcher(authAPI.endpoints.logout.matchPending, (state) => {
                state.appActiveRouter = {
                    template: 'loading',
                    meta: {
                        loading: false,
                        message: 'logging out...'
                    }
                }
            })

            .addMatcher(authAPI.endpoints.logout.matchFulfilled, (state) => {
                state.appAuthentication = {
                    authorisedAccounts: undefined,
                    twoFactor: {
                        primary: undefined,
                        backup: undefined
                    }
                }
            })

            /**********************************************************************************************************
             *   AUTHORISED ACCOUNT
             **********************************************************************************************************/
            .addMatcher(authAPI.endpoints.getAuthorisedAccounts.matchFulfilled, (state, { payload }) => {
                state.appAuthentication = {
                    ...state.appAuthentication,
                    authorisedAccounts: payload
                }
            })

            .addMatcher(authAPI.endpoints.getTwoFactor.matchFulfilled, (state, { payload }) => {
                if (payload) {
                    state.appAuthentication = {
                        ...state.appAuthentication,
                        twoFactor: {
                            primary: payload && payload.filter((item: IUserTwoFactorMethod) => item.is_primary)[0],
                            backup: payload
                        }
                    }
                }
            })

            /**********************************************************************************************************
             *   CLOSE ACCOUNT
             **********************************************************************************************************/
            .addMatcher(accountAPI.endpoints.closeAccount.matchPending, (state) => {
                state.appActiveRouter = {
                    template: 'loading',
                    meta: {
                        loading: false,
                        message: 'Closing account & logging out...'
                    }
                }
            })
            .addMatcher(accountAPI.endpoints.closeAccount.matchRejected, (state) => {
                state.appActiveRouter = {
                    template: 'user',
                    meta: {
                        loading: false,
                        message: ''
                    }
                }
            })
            .addMatcher(accountAPI.endpoints.closeAccount.matchFulfilled, (state) => {
                state.appActiveRouter = {
                    template: 'guest',
                    meta: {
                        loading: false,
                        message: ''
                    }
                }
                state.appSession = {
                    current_account: undefined,
                    primary_account: undefined,
                    authenticated: false,
                    two_factor_required: false,
                    user: undefined
                }
            })

            .addMatcher(
                accountAPI.endpoints.updateProfile.matchFulfilled,
                (
                    state,
                    {
                        meta: {
                            arg: { originalArgs }
                        }
                    }
                ) => {
                    if (originalArgs?.company_name && state.appSession.current_account) {
                        state.appSession = {
                            ...state.appSession,
                            current_account: {
                                ...state.appSession.current_account,
                                account: {
                                    ...state.appSession.current_account.account,
                                    company_name: originalArgs.company_name,
                                    business_number: originalArgs.business_number
                                }
                            }
                        }
                    }
                }
            )

            /**********************************************************************************************************
             *   CART
             **********************************************************************************************************/
            .addMatcher(shopAPI.endpoints.createCart.matchFulfilled, (state, { payload }) => {
                state.cart = payload
                localStorage.setItem('cart_uuid', payload.uuid)
            })
            .addMatcher(shopAPI.endpoints.updateCartItems.matchFulfilled, (state, { payload }) => {
                state.cart = payload
            })
            .addMatcher(shopAPI.endpoints.viewCart.matchFulfilled, (state, { payload }) => {
                state.cart = payload
            })
            .addMatcher(shopAPI.endpoints.removeCartItem.matchFulfilled, (state, { payload }) => {
                state.cart = payload
            })
            .addMatcher(shopAPI.endpoints.deleteCart.matchFulfilled, (state) => {
                localStorage.removeItem('cart_uuid')
                state.cart = undefined
            })
    }
})

export const {
    setRedirect,
    triggerRedirect,
    logoutReset,
    setAppInitialised,
    setAppSettings,
    setAppSession,
    setUser,
    setAuthorisedAccounts,
    setRequestedMethod,
    setSwitchAccountOpen,
    setAppActiveRouter,
    setAppActiveInvoice,
    setAppLinkTypePaymentState,
    readyAppTokenTypeOrderPayment,
    updateAppTokenTypeOrderPaymentStatus,
    setCart
} = applicationSlice.actions

export default applicationSlice.reducer
