import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'

/**********************************************************************************************************
 *   COMMON FUNCTIONS
 **********************************************************************************************************/
export const isFunction = (value) =>
    value && (Object.prototype.toString.call(value) === '[object Function]' || 'function' === typeof value || value instanceof Function)

export const isValid = (variable) => {
    // eslint-disable-next-line valid-typeof
    return typeof variable !== undefined || typeof variable !== 'undefined' || typeof variable !== null
}

export const uid = () => {
    return (Math.random().toString(36) + Date.now().toString(36)).substr(2, 10)
}

export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

/*   VALIDATION
 **********************************************************************************************************/
export const regex = {
    password: /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,}$/,
    phone: /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
    internationalPhone: /^(?:\+|\.| |[0-9])*$/,
    domain: /^[a-z0-9]+(-[a-z0-9]+)*$/i,
    domainWithExtension: /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/,
    dateOfBirth: /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/
}

/*   VIEWPORT FUNCTION
 **********************************************************************************************************/

// 1a.
export function getWindowSize() {
    const { innerWidth: width, innerHeight: height } = window
    return { width, height }
}

// 1b.
export function GetBreakpoint() {
    const [windowSize, setWindowSize] = useState(getWindowSize())

    useEffect(() => {
        const handleResize = () => {
            setWindowSize(getWindowSize())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    // eslint-disable-next-line no-undef
    const breakpointArray = Object.keys(currentTheme).filter((breakpoint) => windowSize.width <= parseInt(currentTheme[breakpoint]))

    return breakpointArray?.length > 0 ? breakpointArray[0] : breakpointArray
}

/*   DATA FUNCTIONS
 **********************************************************************************************************/
export function getBadgeColour(status = '') {
    switch (status) {
        case 'paid':
            return 'confirm'
        case 'unpaid':
            return 'alternative'
        case 'cancelled':
            return 'secondary'
        case 'overdue':
            return 'attention'
        default:
            return ''
    }
}

export function getStatesByCountryCode(countryCode) {
    if (countryCode === 'AU') {
        return [
            { state_code: 'ACT', state: 'Australian Capital Territory' },
            { state_code: 'NSW', state: 'New South Wales' },
            { state_code: 'QLD', state: 'Queensland' },
            { state_code: 'VIC', state: 'Victoria' },
            { state_code: 'SA', state: 'South Australia' },
            { state_code: 'WA', state: 'Western Australia' },
            { state_code: 'TAS', state: 'Tasmania' }
        ]
    }

    if (countryCode === 'NZ') {
        return [
            { state_code: 'NTL', state: 'Northland' },
            { state_code: 'AUK', state: 'Auckland' },
            { state_code: 'WKO', state: 'Waikato' },
            { state_code: 'BOP', state: 'Bay of Plenty' },
            { state_code: 'GIS', state: 'Gisborne' },
            { state_code: 'HKB', state: `Hawke's Bay` },
            { state_code: 'TKI', state: 'Taranaki' },
            { state_code: 'MWT', state: 'Manawatu-Whanganui' },
            { state_code: 'WGN', state: 'Wellington' },
            { state_code: 'TAS', state: 'Tasman' },
            { state_code: 'NSN', state: 'Nelson' },
            { state_code: 'MBH', state: 'Marlborough' },
            { state_code: 'WTC', state: 'West Coast' },
            { state_code: 'CAN', state: 'Canterbury' },
            { state_code: 'OTA', state: 'Otago' },
            { state_code: 'STL', state: 'Southland' }
        ]
    }

    return {}
}

export function getBillingCycle(name = '') {
    switch (name) {
        case 'Monthly':
            return '/mo'
        case 'Quarterly':
            return '/3mo'
        case 'Semi-Annually':
            return '/6mo'
        case 'Annually':
            return '/yr'
        case 'Biennially':
            return '/2yr'
        case 'Triennially':
            return '/3yr'
        case 'One Time':
            return ''
        case '1 Year':
            return '/1yr'
        case '2 Years':
            return '/2yr'
        case '3 Years':
            return '/3yr'
        case '4 Years':
            return '/4yr'
        case '5 Years':
            return '/5yr'
        case '6 Years':
            return '/6yr'
        case '7 Years':
            return '/7yr'
        case '8 Years':
            return '/8yr'
        case '9 Years':
            return '/9yr'
        case '10 Years':
            return '/10yr'
        default:
            return '/mo'
    }
}

/*   MATH FUNCTIONS
 **********************************************************************************************************/
export const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

export function mapValueToRange(current, in_min, in_max, out_min, out_max) {
    const mapped = ((current - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
    return clamp(mapped, out_min, out_max)
}

/*   STRING FUNCTIONS
 **********************************************************************************************************/
export function getInitials(fullName = '') {
    const nameArr = fullName.split('')
    const initials = nameArr.filter((char) => /[A-Z]/.test(char))

    return initials.join('')
}

export function isValidArray(arg) {
    return Array.isArray(arg) && arg.length > 0
}

export function removePropertyByKeys(object = {}, keys = []) {
    return Object.fromEntries(Object.entries(object).filter(([key]) => !keys.includes(key)))
}

export function titleCase(string = '') {
    string = string.toLowerCase().split(' ')

    for (let i = 0; i < string.length; i++) {
        string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1)
    }
    return string.join(' ')
}

export function camelCase(string = '') {
    return string.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
}

export function snakeCase(string = '') {
    return (
        string &&
        string
            .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
            .map((x) => x.toLowerCase())
            .join('_')
    )
}

export function getDomainName(domainName = '') {
    const values = domainName.replace('www.', '').toLowerCase().split('.')

    return values[0]
}

export const isNumeric = (v) => {
    return /^\d*$/.test(v)
}

/*   OBJECT FUNCTIONS
 **********************************************************************************************************/

export function isObjectEmpty(object = {}) {
    return Object.keys(object).length === 0
}

export function flattenObject(object = {}) {
    const flattened = {}

    Object.keys(object).forEach((key) => {
        if (typeof object[key] === 'object' && object[key] !== null) {
            Object.assign(flattened, flattenObject(object[key]))
        } else {
            flattened[key] = object[key]
        }
    })

    return flattened
}

export function renameKeys(keysMap = {}, object = {}) {
    return Object.keys(object).reduce((acc, key) => {
        const renameObject = {
            [keysMap[key] || key]: object[key]
        }

        return {
            ...acc,
            ...renameObject
        }
    }, {})
}

export function removeUndefinedNullEmptyStringFromObject(object) {
    return Object.keys(object).reduce((acc, key) => {
        if (object[key] !== null && object[key] !== undefined && object[key] !== '') {
            acc[key] = object[key]
        }

        return acc
    }, {})
}

/*   TIMER FUNCTIONS
 **********************************************************************************************************/
export function Timer(callback, delay) {
    let timerId
    let start
    let remaining = delay

    const pause = () => {
        window.clearTimeout(timerId)
        remaining -= Date.now() - start
    }

    const cancel = () => {
        window.clearTimeout(timerId)
    }

    const resume = () => {
        start = Date.now()
        window.clearTimeout(timerId)
        timerId = window.setTimeout(callback, remaining)
    }

    resume()

    return { cancel, pause, resume }
}

/*   DATE FUNCTIONS
 **********************************************************************************************************/
export function parseDateTime(dateTime) {
    return new DateTime.fromISO(dateTime)
}

export function parseDate(date) {
    return new DateTime.fromFormat(date, 'dd/MM/yyyy')
}

export function parseDateToReadable(dateTime) {
    return new DateTime.fromISO(dateTime).setLocale('en-AU').toLocaleString()
}

export function daysBetween(startDate, endDate) {
    return startDate.diff(endDate, ['years', 'months', 'days', 'hours'])
}

export function aud(number = '') {
    const numberWithoutCommas = parseFloat(number.toString().replace(/,/g, ''))

    return new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2
    }).format(numberWithoutCommas)
}

/**********************************************************************************************************
 *   usePagination HOOK
 **********************************************************************************************************/
export const DOTS = '...'

export function range(start, end) {
    let length = end - start + 1

    return Array.from({ length }, (_, index) => index + start)
}

export function usePagination({ totalCount, pageSize, siblingCount = 1, currentPage }) {
    return useMemo(() => {
        const totalPageCount = Math.ceil(totalCount / pageSize)
        const totalPageNumbers = siblingCount + 5

        /*
          Case 1:
          If the number of pages is less than the page numbers we want to show in our
          paginationComponent, we return the range [1..totalPageCount]
        */
        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount)
        }

        /*
            Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
        */
        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1)
        const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount)

        /*
          We do not show dots just when there is just one page number to be inserted between
          the extremes of sibling and the page limits i.e 1 and totalPageCount.
          Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
        */
        const shouldShowLeftDots = leftSiblingIndex > 2
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2

        const firstPageIndex = 1
        const lastPageIndex = totalPageCount

        /*
            Case 2: No left dots to show, but rights dots to be shown
        */
        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount
            let leftRange = range(1, leftItemCount)

            return [...leftRange, DOTS, totalPageCount]
        }

        /*
            Case 3: No right dots to show, but left dots to be shown
        */
        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = 3 + 2 * siblingCount
            let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount)

            return [firstPageIndex, DOTS, ...rightRange]
        }

        /*
            Case 4: Both left and right dots to be shown
        */
        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex)

            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
        }
    }, [totalCount, pageSize, siblingCount, currentPage])
}
