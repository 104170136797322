/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/utilities/Flex'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
export const WebsiteFeatureStyles = {
    FlexContainer: styled(Flex)`
        flex-grow: 1;
        width: 250px;
        max-width: 350px;

        svg {
            height: 55px;
            margin-bottom: 15px;
            color: ${({ theme }) => theme['primary--100']};
        }
    `,

    Title: styled.h4`
        font-weight: 500;
        font-size: 18px;
        text-align: center;
        text-transform: none;
        color: ${({ theme }) => theme['text--300']};
        margin-bottom: 10px;
    `,

    Description: styled.p`
        font-size: 14px;
        text-align: center;
        font-weight: 400;
        color: ${({ theme }) => theme['text--100']};
    `
}
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/
