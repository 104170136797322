/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _CheckBox } from '../base'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Theme } from 'nxui/src'
import { S } from '../style'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Stylistic variation of the CheckBox component with a pill shape.
 */
export const _CheckBoxPill = styled(_CheckBox)`
    border-radius: 24px;
    width: min-content;

    ${Theme.Functions.setFont('Inter', 600)};

    ${S.ToggleBox} {
        display: none;
    }

    ${S.ChildrenWrapper} {
        white-space: nowrap;
    }

    ${S.InternalFlex} {
        border-radius: 24px;
        padding: 10px 15px;

        background-color: ${({ checked, theme }) => (checked ? theme['info--100'] : theme['background--200'])};
        color: ${({ checked, theme }) => (checked ? theme['white--100'] : theme['text--100'])};

        &:hover {
            background-color: ${({ theme, checked }) => (checked ? theme['info--100'] : theme['background--200'])};
            color: ${({ theme, checked }) => (checked ? theme['white--100'] : theme['info--100'])};
        }
    }
`
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
