/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import PropTypes from 'prop-types'
import { Dialog } from '@headlessui/react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Lightbox } from '../lightbox.styles'
import { handleCloseRender } from '../lightbox'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const LightboxHeaderComponent = ({ lightboxHeaderRef, title, description, conditions }) => {
    return (
        <Lightbox.Default.Header ref={lightboxHeaderRef}>
            {title && <Dialog.Title as={`h2`}>{title}</Dialog.Title>}
            {description && (
                <Dialog.Description as={`p`} className={`desc`}>
                    {description}
                </Dialog.Description>
            )}
            {handleCloseRender(conditions)}
        </Lightbox.Default.Header>
    )
}

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
LightboxHeaderComponent.propTypes = {
    lightboxFooterRef: PropTypes.shape({ current: PropTypes.object }),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    conditions: PropTypes.shape({
        action: PropTypes.func.isRequired
    })
}

export default LightboxHeaderComponent
