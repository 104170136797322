/**
 * @fileoverview Website styles and helpers
 *
 * This file containers helper styles and StyledComponents that can be used throughout the website
 * portion of MCA to ensure consistent theming, spacing, etc
 */

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled, { css } from 'styled-components'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Theme } from 'nxui/src'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
/**
 * Constant values for use within the application
 */
const Consts = {
    Width: '1150px',
    BlockPadding: '72px',
    inlinePadding: 'max(40px, calc((100vw - 1150px) / 2))'
}

/**
 * Helper CSS values to be applied to a style component, these are not components themselves
 */
const Helpers = {
    InlinePadding: css`
        padding-inline: ${Consts.inlinePadding};
    `,
    AlternativeAnchorColor: css`
        color: rgba(255, 255, 255, 0.6);
    `,
    Banner: css`
        padding-block: 72px;
        min-height: 360px;
        padding-inline: 50px;
        max-width: 100%;
        width: 100vw;

        ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-sm'])} {
            padding-block: 40px;
            padding-inline: 20px;
        }
    `,
    Fonts: {
        h1: css`
            font-size: 48px;
            line-height: 48px;

            ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-sm'])} {
                font-size: 34px;
                line-height: 36px;
            }
        `,

        h2: css`
            font-size: 36px;
            line-height: 36px;
            letter-spacing: -1px;
            font-weight: 800;

            ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-sm'])} {
                font-size: 28px;
                line-height: 28px;
            }
        `,
        h3: css`
            font-size: 24px;
            line-height: 24px;
            font-weight: 800;

            ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-sm'])} {
                font-size: 20px;
                line-height: 20px;
            }
        `,
        h4: css`
            font-size: 20px;
            line-height: 20px;
            font-weight: 800;

            ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-sm'])} {
                font-size: 18px;
                line-height: 18px;
            }
        `,
        h5: css`
            font-size: 18px;
            line-height: 18px;
            font-weight: 800;

            ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-sm'])} {
                font-size: 16px;
                line-height: 16px;
            }
        `
    },
    Sizes: {
        PageMaxWidth: css`
            max-width: 1150px;
        `
    }
}

const Components = {
    /**
     * Provides logic for Internal Banners within a MCA Website Page
     */
    BannerInner: styled.div<{
        /**
         * Render the banner at a larger (1150px) size, rather than the default (700px)
         */
        $large?: boolean

        /**
         * When not used within a flex box, the center prop can be used to center the banner using margins
         */
        $center?: boolean
    }>`
        max-width: 100%;
        width: ${({ $large }) => ($large ? '1150px' : '700px')};
        ${({ $center }) => $center && 'margin-inline: auto;'}
    `
}
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/

/***** EXPORTS *****/
export const WebsiteStyles = {
    Helpers,
    Consts,
    Components
}
