/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import throttle from 'lodash.throttle'
import { useState } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Theme } from '../../theme/variables'

import { useEventListener } from './useEventListener'
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect'

/**********************************************************************************************************
 *   HOOK
 **********************************************************************************************************/
export function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0
    })

    const getWindowMeta = throttle(function () {
        const handleBreakpoint = (width) => {
            switch (true) {
                case width <= Theme['breakpoints-sm']:
                    return 'sm'
                case width >= Theme['breakpoints-sm'] && width < Theme['breakpoints-md']:
                    return 'md'

                case width >= Theme['breakpoints-md'] && width < Theme['breakpoints-lg']:
                    return 'lg'

                case width >= Theme['breakpoints-lg'] && width < Theme['breakpoints-xl']:
                    return 'xl'

                case width >= Theme['breakpoints-xl'] && width < Theme['breakpoints-2xl']:
                    return '2xl'

                case width >= Theme['breakpoints-2xl'] && width < Theme['breakpoints-3xl']:
                default:
                    return '3xl'
            }
        }

        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
            breakpoint: handleBreakpoint(window.innerWidth)
        })
    }, 300)

    useEventListener('resize', getWindowMeta)

    // Set size at the first client-side load
    useIsomorphicLayoutEffect(() => {
        getWindowMeta()
    }, [])

    return windowSize
}
