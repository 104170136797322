/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useEffect, useRef, useState } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Button } from '../../button'
import { Loader } from '../../loader'
import { RenderErrorIcon, RenderInputLabel, Style } from '../util'
import { Input } from './input'
import { handlePrefixRender, Prefix, stripPrefixFromValue } from './prefix'

/**********************************************************************************************************
 *   ASSET IMPORTS
 **********************************************************************************************************/
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled from 'styled-components'

const Search = {
    Action: styled(Button)`
        position: relative;
        margin: 0;
        border-radius: 0px;
        border-left-width: 0px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    `,
    Field: styled.input`
        ${Style.Field}
        border-radius: 0px;
        border-left-width: 0px;
        border-right-width: 0px;
    `,
    Icon: styled(MagnifyingGlassIcon)`
        width: 20px;
    `
}

/**********************************************************************************************************
 *   SEARCH FIELD
 **********************************************************************************************************/
export const SearchField = ({
    embedded,
    required,
    className,
    loading,
    prefixes,
    label,
    field,
    disabled,
    form: { values, touched, errors, isSubmitting },
    ...props
}) => {
    /*   REF
     *****************************************************/
    const inputRef = useRef()

    /*   STATE
     *****************************************************/
    const [hasFocus, setFocus] = useState(false)
    const [activePrefix, setActivePrefix] = useState(undefined)

    /*   EFFECTS
     *****************************************************/
    useEffect(() => {
        if (document.hasFocus() && inputRef.current.contains(document.activeElement)) {
            setFocus(true)
        }

        prefixes?.length === 1 && !activePrefix && setActivePrefix(prefixes[0])
    }, [activePrefix])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Input.Wrapper className={props.className} embedded={embedded}>
            {RenderInputLabel(embedded, required, label, field)}
            <Input.Column focus={hasFocus} error={touched[field.name] && errors[field.name]}>
                <Prefix.Wrapper>
                    {handlePrefixRender(prefixes, activePrefix, setActivePrefix)}
                    <Search.Field
                        {...field}
                        {...props}
                        ref={inputRef}
                        disabled={isSubmitting || disabled}
                        value={stripPrefixFromValue(values, field, activePrefix)}
                        onFocus={() => setFocus(true)}
                        onBlur={(e) => {
                            field.onBlur && field.onBlur(e)
                            setFocus(false)
                        }}
                    />
                    {RenderErrorIcon(field.name, touched, errors)}
                    <Search.Action type={'submit'} color={'primary'} disabled={disabled}>
                        {loading ? <Loader.Basic /> : <Search.Icon />}
                    </Search.Action>
                </Prefix.Wrapper>
            </Input.Column>
        </Input.Wrapper>
    )
}
