/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Form, Formik, FormikProps } from 'formik'
import { RefObject, useEffect } from 'react'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useChangePasswordMutation } from 'api/service'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
interface Props {
    id: number
    form: RefObject<FormikProps<{ password: string }>>
    close: () => void
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function PasswordForm({ id, form, close }: Props) {
    const [changePassword, { isLoading, isSuccess, isError }] = useChangePasswordMutation()

    useEffect(() => {
        if ((isSuccess || isError) && form.current) {
            form.current.setSubmitting(false)
            form.current.resetForm()
            close()
        }
    }, [form, isSuccess, isError])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Formik
            innerRef={form}
            initialValues={{
                password: ''
            }}
            onSubmit={({ password }) => {
                changePassword({ id, password })
            }}
        >
            {() => (
                <Form id={'changePasswordForm'}>
                    <Field
                        label={'New Password'}
                        name={'password'}
                        disabled={isLoading}
                        autoComplete={'new-password'}
                        component={NXUIForm.InputField}
                    />
                </Form>
            )}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
