/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import * as Yup from 'yup'

/**********************************************************************************************************
 *   METHODS
 **********************************************************************************************************/
Yup.addMethod<Yup.StringSchema>(Yup.string, 'validateABN', function (message: string) {
    return this.test(`validateABN`, message, function (value: string | undefined) {
        const { path, createError } = this
        const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19]

        if (!value) return createError({ path, message: message ?? 'Required' })

        if (value.length != 11) return createError({ path, message: message ?? 'ABN needs to be 11 digits long' })

        const ABN = Number(value?.substring(0, 2)) - 10 + value?.substring(2, 11)

        let total = 0
        for (let i = 0; i < 11; i++) total += weights[i] * Number(ABN.charAt(i))

        if (total == 0 || total % 89 != 0) return createError({ path, message: message ?? 'Please enter a valid ABN' })

        return true
    })
})

/**********************************************************************************************************
 *   EXPORT
 **********************************************************************************************************/
export default Yup
