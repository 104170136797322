/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Theme } from 'nxui/src'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
export const HeaderActionStyles = {
    Outer: styled.div`
        svg {
            cursor: pointer;
            grid-column: 3;
            width: 20px;
            justify-self: flex-end;
            color: ${(props) => props.theme['icon--100']};
            display: inline;

            ${Theme.Functions.animation('transition-all')};

            &:hover {
                color: ${(props) => props.theme['text--300']};
            }
        }
    `
}
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/
