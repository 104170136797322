/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Records } from 'containers/domains/domains.styles'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { FormRecord, RecordType } from 'models/domain'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { secondsToDhms } from 'helpers/utils'

/**
 * Generates a label based on the type of DNS record
 */
export function handleContentLabel(type: RecordType = 'A'): ContentLabelRT {
    switch (type) {
        case 'A':
            return 'IPv4 Address'
        case 'AAAA':
            return 'IPv6 Address'
        case 'CNAME':
            return 'Target'
        case 'MX':
            return 'Mail Server'
        case 'NS':
            return 'Nameserver'
        case 'SRV':
            return 'Destination'
        default:
            return 'Content'
    }
}
type ContentLabelRT = 'IPv4 Address' | 'IPv6 Address' | 'Target' | 'Mail Server' | 'Nameserver' | 'Destination' | 'Content'

/**
 * Generates a description based on the type of DNS record
 */
function handleDNSRecordDescription(type: RecordType): RecordDescriptionRT {
    switch (type) {
        case 'A':
        case 'AAAA':
            return ' points to '
        case 'CNAME':
            return ' is an alias of '
        case 'MX':
            return ' handles mail for '
        case 'NS':
            return ' is managed by '
        case 'TXT':
            return ' has a record with content '
        case 'SRV':
            return ' points to '
        default:
            return ''
    }
}
type RecordDescriptionRT = ' points to ' | ' is an alias of ' | ' handles mail for ' | ' is managed by ' | ' has a record with content ' | ''

/**
 * either returns a generic label/description if content is not available, otherwise returns the content
 */
function handleContent(type: RecordType, content = '') {
    return content === '' ? `[${handleContentLabel(type)}]` : content
}

/**
 * either returns a generic label/description if hostname is not available, otherwise returns the hostname
 */
function handleHostname(type: RecordType, hostname: string, domain?: string) {
    if (hostname === '') return '[Hostname]'
    if (hostname === '@' && type !== 'CNAME') return domain
    if (hostname === domain) return domain

    return `${hostname}.${domain}`
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Generates a preview of the DNS record based on the type of DNS record, this is presented as a string if the type is not supported
 */
export default function PreviewDNSRecordResult({ type, hostname, content, port, destination, ttl, domain }: FormRecord & { domain?: string }) {
    /*   RENDER COMPONENT
     *****************************************************/
    switch (type) {
        case 'A':
        case 'AAAA':
        case 'CNAME':
        case 'NS':
        case 'TXT':
            return (
                <Records.Result>
                    <Records.Value>{handleHostname(type, hostname?.trim?.(), domain)}</Records.Value>
                    {handleDNSRecordDescription(type)}
                    <Records.Value>{handleContent(type, content)}</Records.Value>
                    {Number(ttl) > 0 && (
                        <>
                            <span>, refresh every </span>
                            <Records.Value>{secondsToDhms(Number(ttl))}.</Records.Value>
                        </>
                    )}
                </Records.Result>
            )
        case 'MX':
            return (
                <Records.Result>
                    <Records.Value>{handleContent(type, content)}</Records.Value>
                    {handleDNSRecordDescription(type)}
                    <Records.Value>{handleHostname(type, hostname?.trim?.(), domain)}</Records.Value>
                    {Number(ttl) > 0 && (
                        <>
                            <span>, refresh every </span>
                            <Records.Value>{secondsToDhms(Number(ttl))}; </Records.Value> {/* semicolon intention */}
                        </>
                    )}
                    lower 'priority' value will take precedence over a higher 'priority' value.
                </Records.Result>
            )
        case 'SRV':
            return (
                <Records.Result>
                    <Records.Value>{handleHostname(type, hostname?.trim?.(), domain)}</Records.Value>
                    {handleDNSRecordDescription(type)}
                    <Records.Value>{handleContent(type, destination)}</Records.Value>
                    {' and listens on '}
                    <Records.Value>{port === '' ? '[Port]' : port}</Records.Value>
                    {Number(ttl) > 0 && (
                        <>
                            <span>, refresh every </span>
                            <Records.Value>{secondsToDhms(Number(ttl))}; </Records.Value> {/* semicolon intention */}
                        </>
                    )}
                    lower 'priority' value will take precedence over a higher 'priority' value.
                </Records.Result>
            )
        default:
            return <></>
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
