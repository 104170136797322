/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Anchor } from '../../anchor'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import { Cart } from './cart.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const CartProduct = (props) => {
    /*   RENDER
     ****************************************************/
    return (
        <Cart.Product.Base key={props.id}>
            <Cart.Product.Row>
                <Cart.Product.Header>
                    <h2>{props.title}</h2>
                    {props.subtitle && <h4>{props.subtitle}</h4>}
                </Cart.Product.Header>
                <Cart.Product.Price>
                    <h2>${props.price} AUD</h2>
                    {props.interval && <p>{props.interval}</p>}
                </Cart.Product.Price>
            </Cart.Product.Row>

            <Cart.Product.Actions>
                <Anchor color={'error'} onClick={() => props.remove}>
                    Remove
                </Anchor>
                {props.edit && (
                    <Anchor color={'primary'} onClick={() => props.edit}>
                        Edit
                    </Anchor>
                )}
            </Cart.Product.Actions>
        </Cart.Product.Base>
    )
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
CartProduct.defaultProps = {
    className: undefined
}
