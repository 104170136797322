/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'
import React from 'react'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { RenderPropOrInvokeChildren } from 'components/renderPropOrInvokeChildren'
import { Flex } from 'components/utilities/Flex'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { FooterDetailIcon } from './icon'
import { FooterDetailStyles as S } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TContactDetailBaseProps = {
    title: string
}

type TContactDetailPhoneEmailProps = {
    phone?: string
    email?: string
}

type TContactDetailChildrenProps = {
    children: React.ReactNode
}

type TContactDetailProps = TContactDetailBaseProps & (TContactDetailPhoneEmailProps | TContactDetailChildrenProps)
type TContactDetail = React.FC<TContactDetailProps>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _ContactDetail: TContactDetail = ({ title, ...props }) => {
    if ('phone' in props) {
        if (!props.phone || !props.email) {
            return null
        }
    }

    /***** RENDER *****/
    return (
        <div>
            <S.Heading>{title}</S.Heading>
            <RenderPropOrInvokeChildren props={props} prop='children'>
                {({ phone, email }) => (
                    <>
                        <Flex gap={8} justify='flex-start' align='center'>
                            <FooterDetailIcon icon={PhoneIcon} />
                            <S.Text>{phone}</S.Text>
                        </Flex>
                        <Flex gap={8} justify='flex-start' align='center'>
                            <FooterDetailIcon icon={EnvelopeIcon} />
                            <S.Anchor href={`mailto:${email}`}>{email}</S.Anchor>
                        </Flex>
                    </>
                )}
            </RenderPropOrInvokeChildren>
        </div>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/***** EXPORTS *****/
export const ContactDetail = Object.assign(_ContactDetail, {
    Styles: S,
    Icon: FooterDetailIcon
})
