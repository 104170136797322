/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Bars3Icon } from '@heroicons/react/20/solid'
import styled, { css } from 'styled-components'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { HeaderNavContainer } from './nav'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Theme } from 'nxui/src'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
export const HeaderStyles = {
    Header: styled.header<{ type?: 'original' | 'sidebar' }>`
        display: grid;
        grid-template-columns: clamp(80px, 160px, 100%) 1fr clamp(20px, 120px, 100%);
        column-gap: 10px;
        padding-inline: max(40px, calc((100vw - 1650px) / 2));
        align-items: center;

        height: 90px;
        width: 100%;
        background-color: ${(props) => props.theme['background--100']};

        ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-md'])} {
            padding-inline: 20px;

            ${({ type }) => {
                switch (type) {
                    case 'original':
                        return css`
                            grid-template-columns: clamp(80px, 160px, 100%) clamp(20px, 120px, 100%);

                            /* Override logo default styling */
                            img {
                                margin-bottom: 0;
                                padding-block: 0;
                            }
                        `
                    case 'sidebar':
                    default:
                        return css`
                            grid-template-columns: 22px 1fr 40px;
                        `
                }
            }}
        }
    `,

    Logo: styled.img`
        grid-column: 1;
        justify-self: flex-start;

        ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-md'])} {
            margin-bottom: 10px;
            padding-block: 10px;
        }
    `,

    Burger: styled(Bars3Icon)`
        cursor: pointer;
    `,

    SideNav: styled.div<{ open?: boolean }>`
        z-index: 16;
        position: fixed;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        width: 260px;
        min-width: 260px;
        padding: 1.25rem;
        background-color: ${({ theme }) => theme['background--100']};
        border-right: 1px solid ${({ theme }) => theme['border--100']};
        left: ${({ open }) => (open ? '0' : '-260')}px;

        ${Theme.Functions.animation('left')};

        ${({ theme }) => Theme.Functions.mediaBreakUp(theme['breakpoints-lg'])} {
            position: sticky;
            height: unset;
        }

        ${({ theme }) => Theme.Functions.mediaBreakUp(theme['breakpoints-xl'])} {
            position: sticky;
            height: unset;
        }

        ${HeaderNavContainer} {
            flex-direction: column;
            gap: 0;

            padding: 10px;
            margin-bottom: auto;
            border-radius: 16px;
            border: 1px solid rgb(209, 213, 219);
            background-color: rgb(243, 244, 246);
            overflow: auto;
            width: 100%;
            height: fit-content;
        }

        ${HeaderNavContainer.Item.Style.Container} {
            border-top: 1px solid transparent;
        }
    `,
    SideNavInner: styled.div`
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
    `
}
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/
