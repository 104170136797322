/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Formik } from 'formik'
import * as Yup from 'yup'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { authAPI, useLoginMutation } from 'api/authentication'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { useAppDispatch } from 'store/hooks'

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
import { Auth } from 'containers/authentication/authentication.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function AuthLoginForm() {
    const dispatch = useAppDispatch()
    const [login] = useLoginMutation()

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Formik
            enableReinitialize
            validateOnMount
            initialValues={{
                email: '',
                password: '',
                remember: false
            }}
            validationSchema={Yup.object({
                email: Yup.string().email('Invalid email address').required('Required'),
                password: Yup.string().required('Required')
            })}
            onSubmit={({ email, password, remember }, { setSubmitting, resetForm }) => {
                login({ email, password, remember })
                    .unwrap()
                    .then((response) => {
                        dispatch(authAPI.endpoints.getSession.initiate(undefined, { forceRefetch: true }))

                        return response
                    })
                    .catch(() => {
                        setSubmitting(false)
                        resetForm()
                    })
            }}
        >
            {({ isSubmitting, isValid, values, setFieldValue, dirty }) => {
                return (
                    <Auth.Wrapper>
                        <Auth.Title>Login</Auth.Title>
                        <Auth.Description>Enter your credentials to access your account.</Auth.Description>
                        <Auth.Form>
                            <Field
                                required
                                id={'email'}
                                label={'Email address'}
                                name={'email'}
                                type={'text'}
                                disabled={isSubmitting}
                                autoComplete={'username'}
                                component={NXUIForm.InputField}
                            />
                            <Field
                                required
                                id={'password'}
                                label={'Password'}
                                name={'password'}
                                type={'password'}
                                disabled={isSubmitting}
                                autoComplete={'current-password'}
                                component={NXUIForm.SensitiveField}
                            />
                            <Auth.Reset>
                                <Auth.Switch.Field>
                                    <Auth.Switch.Label>Remember Me</Auth.Switch.Label>
                                    <NXUIForm.Switch
                                        disabled={isSubmitting}
                                        checked={values.remember}
                                        onClick={() => setFieldValue('remember', !values.remember)}
                                    />
                                </Auth.Switch.Field>
                                <Auth.Link to='forgot'>Forgot password?</Auth.Link>
                            </Auth.Reset>
                            <Auth.Submit
                                color={'primary'}
                                type={'submit'}
                                disabled={!isValid || isSubmitting || !dirty}
                                loading={isSubmitting ? 'Logging in...' : false}
                            >
                                Login
                            </Auth.Submit>
                        </Auth.Form>
                    </Auth.Wrapper>
                )
            }}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
