/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { formatBytes } from '../../../helpers/functions'
import { Style } from '../util'

/**********************************************************************************************************
 *   ASSET IMPORTS
 **********************************************************************************************************/
import { FolderIcon, TrashIcon } from '@heroicons/react/24/solid'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled from 'styled-components'
import * as Theme from '../../../theme/theme'

export const Drop = {
    Base: styled.div`
        ${Style.Field};
        cursor: pointer;
        border: 1px dashed ${(props) => Theme.Functions.hexToRGBA(props.theme[`primary--100`], `0.25`)};
        background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`primary--100`], `0.03`)};

        &:focus,
        &:hover {
            border: 1px dashed ${(props) => Theme.Functions.hexToRGBA(props.theme[`primary--100`], `1`)};
        }
    `,
    Container: styled.div`
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
    `,
    Input: styled.input``,
    Title: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
    `,
    Subtitle: styled.p`
        font-size: 1.2rem;
        color: ${(props) => props.theme[`primary--100`]};
        ${Theme.Functions.setFont('Inter', 600)};
    `,
    Item: styled.div``,
    Label: styled.label`
        display: inline-block;
        margin: 8px 0;
        font-size: 1.2rem;
        color: ${(props) => props.theme[`text--200`]};
        ${Theme.Functions.normalizeText()};
        ${Theme.Functions.setFont('Inter', 700)};
    `,
    Uploaded: {
        Base: styled.div`
            width: 100%;
            min-height: 44px;
            margin: 10px 10px 0 0;
            word-break: break-word;

            &:first-of-type {
                margin-top: 0;
            }

            &:last-of-type {
                margin-bottom: 10px;
            }
        `,
        Details: styled.div`
            height: auto;
            width: 100%;
            padding: 10px 15px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            border: 1px solid ${(props) => props.theme[`gray--100`]};
            border-radius: 8px;
        `,
        Text: styled.p`
            display: inline-block;
            width: 100%;
            font-size: 12px;
            color: ${(props) => props.theme[`text--200`]};
            ${Theme.Functions.normalizeText()};
            ${Theme.Functions.setFont('Inter', 700)};

            span {
                font-size: 1.2rem;
                color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`text--100`], `.75`)};
            }
        `,
        Warning: styled.p`
            display: inline-block;
            width: 100%;
            font-size: 12px;
            color: ${(props) => props.theme[`error--100`]};
            ${Theme.Functions.normalizeText()};
            ${Theme.Functions.setFont('Inter', 700)};

            span {
                font-size: 1.2rem;
                color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`error--100`], `.75`)};
            }
        `,
        Remove: styled(TrashIcon)`
            cursor: pointer;
            z-index: 1;
            width: 16px;
            margin: 0 auto 0 10px;
            color: ${(props) => props.theme[`error--100`]};
        `
    },
    Icon: styled(FolderIcon)`
        width: 16px;
        margin-right: 10px;
        color: ${(props) => props.theme[`primary--100`]};
    `,
    Text: styled.p`
        font-size: 1.2rem;
        letter-spacing: -0.25px;
        color: ${(props) => props.theme[`primary--100`]};
        ${Theme.Functions.setFont('Inter', 500)};
    `
}

/**********************************************************************************************************
 *   RENDER DROPZONE
 **********************************************************************************************************/
export const UploadField = ({ form, field, disabled, label = 'Upload Files' }) => {
    const maxSize = 2306867
    const { acceptedFiles, getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
        maxSize,
        maxFiles: 5,
        accept: {
            'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
            'text/*': ['.txt', '.xlsx', '.csv'],
            'application/pkix-cert': ['.crt', '.cer'],
            'application/pkcs8': ['.key'],
            'application/x-pem-file': ['.pem'],
            'application/pdf': ['.pdf'],
            'application/docx': ['.docx']
        },
        disabled: disabled,
        onDrop: addNewFiles
    })
    const [listedFiles, setListedFiles] = useState(acceptedFiles)

    function addNewFiles(files) {
        let newFiles = [...listedFiles, ...files]
        setListedFiles(newFiles)
        form.setFieldValue(field.name, newFiles)
    }

    function removeFile(i) {
        form.setFieldValue(
            field.name,
            field.value.filter((_, index) => index !== i)
        )
        setListedFiles(listedFiles.filter((_, index) => index !== i))
    }

    function renderFilesList() {
        return acceptedFiles.map((item, index) => {
            return (
                <Drop.Uploaded.Base key={index}>
                    <Drop.Uploaded.Details>
                        <Drop.Uploaded.Text>
                            {item.name} - <span>{formatBytes(item.size)}</span>
                        </Drop.Uploaded.Text>
                        <Drop.Uploaded.Remove onClick={() => removeFile(index)} />
                    </Drop.Uploaded.Details>
                </Drop.Uploaded.Base>
            )
        })
    }

    function renderErrors() {
        if (fileRejections[0].errors[0].code === 'file-too-large') {
            return 'File is too large. Please keep it under 2.2MB.'
        }

        if (fileRejections[0].errors[0].code) {
            return 'Only these file type are allowed: png, gif, jpeg, jpg, pdf, txt, docx, xlsx, csv, cer, crt, key, pem'
        }

        return fileRejections[0].errors[0].message
    }

    return (
        <>
            {label && <Drop.Label>{label}</Drop.Label>}
            {listedFiles?.length > 0 && <Drop.Container>{renderFilesList()}</Drop.Container>}
            {fileRejections.length >= 1 && (
                <Drop.Uploaded.Base>
                    <Drop.Uploaded.Details>
                        <Drop.Uploaded.Warning>{renderErrors()}</Drop.Uploaded.Warning>
                    </Drop.Uploaded.Details>
                </Drop.Uploaded.Base>
            )}
            <Drop.Base {...getRootProps()}>
                <Drop.Input {...getInputProps()} />
                <Drop.Title>
                    <Drop.Icon />
                    {isDragActive ? (
                        <Drop.Text>Drop the files here ...</Drop.Text>
                    ) : (
                        <Drop.Text>Click here to attach a file or drag-and-drop it into this box.</Drop.Text>
                    )}
                </Drop.Title>
            </Drop.Base>
        </>
    )
}
