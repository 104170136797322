/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Link } from 'react-router-dom'
import styled from 'styled-components'

/**********************************************************************************************************
 *   SHARED COMPONENTS
 **********************************************************************************************************/
import { Button } from 'components/buttons'
import { Header } from 'components/header'
import { Anchor, Frame, Theme } from 'nxui/src'

/**********************************************************************************************************
 *   INTERFACES / ENUMS
 **********************************************************************************************************/
import { Weight } from 'models/enums'

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
export const GuestRoute = {
    Frame: styled(Frame)`
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        overflow: auto;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-lg'])} {
            margin-top: 0;
        }
    `,
    HeaderButton: styled(Button.Link)`
        ${Header.Action.Raw.GridPlacement};
        white-space: nowrap;

        ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-md'])} {
            border: none;
            background: none;
        }
    `,
    Outer: styled.div`
        position: relative;
        width: 100%;
        height: 100%;
        overflow-x: hidden;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        background-color: ${(props) => props.theme[`background--200`]};
    `,
    Container: styled.div`
        display: flex;
        flex: 1;
        flex-direction: column;
    `,
    Content: styled.div`
        display: inline-flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    `,
    Base: styled.div`
        display: flex;
        flex-flow: column wrap;
    `,
    Wrapper: styled.div`
        width: 100%;
    `,
    Company: styled.div`
        display: inline-flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        padding: 20px;
    `,
    Footer: styled.footer`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        font-size: 1.2rem;
        letter-spacing: -0.25px;
        padding-top: 1.5rem;
        color: ${(props) => props.theme['text--100']};
        ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
    `,
    Account: styled.span`
        opacity: 0.8;
    `,
    Link: styled(Link)`
        color: ${(props) => props.theme['primary--100']};
    `,
    SiteMap: styled.div`
        display: flex;
        align-items: center;
        gap: 5px;
        margin: 5px 0 20px;
    `,
    Info: styled.div`
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    `,
    Anchor: styled(Anchor)`
        color: ${(props) => props.theme['primary--100']};
    `,
    Title: styled.h2`
        font-size: 1.4rem;
        color: ${(props) => props.theme['text--100']};
        ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
    `,
    Logo: styled.div`
        display: inline-flex;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
        position: relative;
    `,
    HeaderActionText: styled.span`
        font-weight: 500;
        display: flex;
        align-items: center;
        font-size: 12px;

        color: ${({ theme }) => theme['text--100']};
    `
}
