/**
 * @fileoverview Helper functions/hooks for getting what routes should be registered in the app.
 */

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Navigate } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { AboutUs } from 'containers/website/about-us'
import { Home } from 'containers/website/home'
import { WebsiteDomainPricing } from 'containers/website/pricing'
import { TermsOfService } from 'containers/website/terms-of-service'
import { Whois } from 'containers/website/whois'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { websiteRoutes } from 'router/consts'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TUseWebsiteRoutes = () => (fallback?: string) => Array<{ index?: boolean; element: JSX.Element }>

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Get the routes for the whitelabel website. If the website is not enabled, the appropriate redirect routes will be returned.
 */
export const useWebsiteRoutes: TUseWebsiteRoutes = () => {
    /***** HOOKS *****/
    const { website } = useAppSelector((state) => state.app.appSettings.section)

    /***** RESULTS *****/
    if (!website) {
        return (fallback?: string) => [{ index: true, element: <Navigate to={fallback ?? '/login'} replace /> }]
    }

    return () => [
        { index: true, element: <Home /> },
        { path: websiteRoutes.DOMAIN_PRICING, element: <WebsiteDomainPricing /> },
        { path: websiteRoutes.TERMS_OF_SERVICE, element: <TermsOfService /> },
        { path: websiteRoutes.WHOIS, element: <Whois /> },
        { path: websiteRoutes.ABOUT_US, element: <AboutUs /> }
    ]
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
