/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { DNSModeType } from 'models/enums'

/**********************************************************************************************************
 *    SLICE
 **********************************************************************************************************/
interface DomainState {
    dnsMode?: `${DNSModeType}`
    customNameservers: Array<string>

    /**
     * States correlating with showing the DNS Hosting related forms
     */
    addingRecord: boolean
    addingEmailRedirect: boolean
    addingDomainRedirect: boolean
}

const initialState: DomainState = {
    dnsMode: undefined,
    customNameservers: [],
    addingRecord: false,
    addingEmailRedirect: false,
    addingDomainRedirect: false
}

export const domainSlice = createSlice({
    name: 'domain',
    initialState,
    reducers: {
        setDNSMode: (state, action: PayloadAction<`${DNSModeType}`>) => {
            state.dnsMode = action.payload
        },
        setCustomNameservers: (state, action: PayloadAction<Array<string>>) => {
            state.customNameservers = action.payload
        },
        toggleAddingRecord: (state, action: PayloadAction<boolean | undefined>) => {
            state.addingRecord = typeof action.payload !== 'boolean' ? !state.addingRecord : action.payload
        },
        toggleAddingEmailRedirect: (state, action: PayloadAction<boolean | undefined>) => {
            state.addingEmailRedirect = typeof action.payload !== 'boolean' ? !state.addingEmailRedirect : action.payload
        },
        toggleAddingDomainRedirect: (state, action: PayloadAction<boolean | undefined>) => {
            state.addingDomainRedirect = typeof action.payload !== 'boolean' ? !state.addingDomainRedirect : action.payload
        }
    }
})

export const { setDNSMode, setCustomNameservers, toggleAddingRecord, toggleAddingEmailRedirect, toggleAddingDomainRedirect } = domainSlice.actions
export default domainSlice.reducer
