/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import { Cart } from './cart.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const CartHeader = (props) => {
    /*   RENDER
     ****************************************************/
    return (
        <Cart.Header.Base className={props.className}>
            <Cart.Header.Title>Cart</Cart.Header.Title>
            <Cart.Header.Close onClick={props.close} />
        </Cart.Header.Base>
    )
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
CartHeader.defaultProps = {
    className: undefined
}
