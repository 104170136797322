/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useState, useEffect } from 'react'
import { useFormikContext } from 'formik'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useApplyAccountCreditMutation, billingAPI } from 'api/billing'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { useAppDispatch } from 'store/hooks'

/**
 * Handles the logic of applying account credit to an invoice.
 */
export function useApplyAccountCreditToInvoice() {
    const dispatch = useAppDispatch()

    const [applyAccountCredit, { isLoading }] = useApplyAccountCreditMutation({ fixedCacheKey: 'apply-account-credit' })

    async function applyAccountCreditToInvoice(
        invoiceId: number,
        creditAmountToApply: number
    ): Promise<{
        isInvoiceStillUnpaid: boolean
        isSuccess: boolean
    }> {
        // Only apply account credit if it has been selected
        if (!creditAmountToApply) {
            return {
                isInvoiceStillUnpaid: true,
                isSuccess: true
            }
        }

        try {
            const applyAccountCreditData = await applyAccountCredit({
                id: invoiceId,
                account_credit: creditAmountToApply
            }).unwrap()

            if (!applyAccountCreditData?.amount_due || applyAccountCreditData.amount_due === '0.00') {
                dispatch(billingAPI.util.invalidateTags(['Invoices', { type: 'Invoice', id: invoiceId }]))
                return {
                    isInvoiceStillUnpaid: false,
                    isSuccess: true
                }
            }

            return {
                isInvoiceStillUnpaid: true,
                isSuccess: true
            }
        } catch (e) {
            return {
                isInvoiceStillUnpaid: true,
                isSuccess: false
            }
        }
    }

    return { applyAccountCreditToInvoice, isApplyAccountCreditToInvoiceLoading: isLoading }
}

/**
 * Handles applying of account credit, with a debounce on the input field.
 */
export interface AccountCreditFormValues {
    accountCreditAmount: string
    applyAccountCredit: boolean
}

export const AccountCreditDebounce = ({
    onStopTyping
}: {
    onStopTyping: ({ accountCreditAmount, applyAccountCredit }: AccountCreditFormValues, isValid: boolean) => void
}) => {
    const [isTyping, setIsTyping] = useState(false)
    const { values, isValid } = useFormikContext<AccountCreditFormValues>()

    useEffect(() => {
        setIsTyping(true)

        const timeout = setTimeout(() => {
            setIsTyping(false)
        }, 300)

        return () => clearTimeout(timeout)
    }, [values])

    useEffect(() => {
        if (!isTyping) onStopTyping(values, isValid)
    }, [isTyping])

    return null
}
