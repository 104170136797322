/* eslint-disable indent */
/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useFormikContext } from 'formik'
import { useState } from 'react'

/**********************************************************************************************************
 *   COMPONENTS
 *********************************************************************************************************/
import { Button } from '../../button'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { RenderInputLabel } from '../util'
import { Input } from './input'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled, { css } from 'styled-components'

const Select = {
    Base: styled.div`
        display: inline-flex;
        flex-flow: row wrap;
        gap: 10px;
    `,
    Button: styled(Button)`
        flex: 1 1 100px;
        margin: 0;

        ${(props) =>
            props.color === 'primary' &&
            css`
                border: 1px solid transparent;
            `}
    `
}

/**********************************************************************************************************
 *   INPUT FIELD
 **********************************************************************************************************/
export const MultiSelectField = (props) => {
    const { disabled, field, label, required, embedded } = props
    const [active, setActive] = useState(field?.value || props.options[0].value)
    const { isSubmitting, setFieldValue } = useFormikContext()

    function options() {
        if (props.options) {
            return props.options.map((option) => {
                return (
                    <Select.Button
                        type={'button'}
                        color={active === option.value ? 'primary' : 'secondary'}
                        onClick={() => {
                            setActive(option.value)
                            setFieldValue(field.name, option.value)
                        }}
                        disabled={isSubmitting || disabled}
                    >
                        {option.label}
                    </Select.Button>
                )
            })
        }
        return []
    }

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Input.Wrapper className={props.className} embedded={embedded}>
            {RenderInputLabel(embedded, required, label, field)}
            <Select.Base>
                <input type={'hidden'} {...field} value={field.value} disabled={isSubmitting || disabled} />
                {props.options && options()}
            </Select.Base>
        </Input.Wrapper>
    )
}
