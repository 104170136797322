/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Table } from 'nxui/src'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
import { Service } from 'models/service'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
interface Props {
    server: Service['server']
}

export function Ports({ server }: Props) {
    const { pop3_port, imap_port, outgoing_port } = server.additional_meta || {}

    const tableColumns = [
        {
            Header: 'Ports',
            accessor: 'details'
        },
        {
            Header: '',
            accessor: 'value'
        },
        {
            Header: '',
            accessor: 'action',
            align: 'right'
        }
    ]

    const tableData = [
        {
            details: 'Incoming POP3 Port',
            value: pop3_port || '',
            action: ''
        },
        {
            details: 'Incoming IMAP Mail Port',
            value: imap_port || '',
            action: ''
        },
        {
            details: 'Outgoing SMTP Port',
            value: outgoing_port || '',
            action: ''
        }
    ]

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Table
            render={{
                columns: () => tableColumns,
                data: () => tableData
            }}
        />
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
