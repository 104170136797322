/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useFormikContext } from 'formik'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { domainAPI } from 'api/domain'

/**********************************************************************************************************
 *   FORM
 **********************************************************************************************************/
import { InputField } from 'components/form/input'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { TEmailForwardingFormValues } from '.'
import { forwardingStyles as S } from '../_forwarding.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const EmailForwardingSource = () => {
    /***** HOOKS *****/
    const { id } = useParams()
    const { values } = useFormikContext<TEmailForwardingFormValues>()

    /***** QUERIES *****/
    const { data: domainData } = domainAPI.endpoints.domain.useQueryState(Number(id))

    /***** RENDER *****/
    return (
        <S.InputField
            name='source'
            permanentPlaceholder={<InputField.PermanentPlaceholder>@{domainData?.domain}</InputField.PermanentPlaceholder>}
            disabled={values.catch_all}
            label='Address'
        />
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
