/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { domainAPI } from 'api/domain'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { LightboxBody, LightboxFooter, LightboxHeader } from 'nxui/src'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Preset } from './addPreset.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SelectPreset = ({ isOpen, onSelect, onClose }: { isOpen: boolean; onSelect: (id: number) => void; onClose: () => void }) => {
    const { id } = useParams()
    const domainId = Number(id)

    /***** Queries *****/
    const { presets, isLoading } = domainAPI.endpoints.dnsPresets.useQuery(domainId, {
        selectFromResult: ({ data, ...rest }) => ({
            ...rest,
            presets: data?.filter((p) => p.is_active)
        })
    })

    if (!isOpen) return null

    return (
        <>
            <LightboxHeader
                title='App preset options'
                description='What app preset would you like to connect your domain name to?'
                conditions={{ state: isOpen, action: onClose }}
            />
            <LightboxBody loading={isLoading}>
                <Preset.Select.Outer>
                    {presets?.map((preset) => (
                        <Preset.Select.Item
                            key={preset.id}
                            onClick={() => {
                                onSelect(preset.id)
                            }}
                        >
                            {preset.name}
                        </Preset.Select.Item>
                    ))}
                </Preset.Select.Outer>
            </LightboxBody>
            <LightboxFooter
                actions={[
                    {
                        type: 'button',
                        color: 'secondary',
                        label: 'Cancel',
                        func: onClose
                    }
                ]}
            />
        </>
    )
}
