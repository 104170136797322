/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { FieldLabelStyles as S } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TFieldLabel = React.FC<{
    embedded?: boolean
    required?: boolean
    field: {
        id?: string
        name: string
    }
    label?: string
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const FieldLabel: TFieldLabel = ({ embedded, required, field, label }) => (
    <>
        {!!label && !embedded && (
            <S.Label htmlFor={field.id || field.name}>
                {label}
                {required && <span>*</span>}
            </S.Label>
        )}
    </>
)
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
