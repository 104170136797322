/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { css } from 'styled-components'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Theme } from 'nxui/src'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
const FieldWrapper = css<{ embedded?: boolean }>`
    position: relative;
    display: block;
    margin-bottom: ${({ embedded }) => (embedded ? 0 : 8)}px;
`

const FieldLabel = css`
    display: block;
    margin-bottom: 4px;
    font-size: 1.2rem;
    color: ${({ theme }) => theme[`text--200`]};
    ${Theme.Functions.normalizeText()};
    ${Theme.Functions.setFont('Inter', 700)};

    > span {
        margin-left: 4px;
        color: ${({ theme }) => theme[`error--100`]};
    }
`

const FieldColumnError = css`
    border-color: ${({ theme }) => theme[`error--100`]};
`

const FieldColumn = css<{ error: boolean }>`
    position: relative;
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme[`border--100`]};
    ${Theme.Functions.animation(`border`)};
    ${({ error }) => error && FieldColumnError}

    &:focus-within {
        outline: none;
        border-color: ${({ theme }) => theme[`border--200`]};
    }
`

const Field = css`
    position: relative;
    cursor: auto;
    display: inline-flex;
    flex: 1 1 0%;
    min-width: 0px;
    width: 100%;
    height: 38px;
    padding: 8px 12px;
    background-color: ${(props) => props.theme[`background--000`]};
    outline: 0;
    font-size: 1.2rem;
    color: ${(props) => props.theme[`text--200`]};
    border-radius: 5px;
    ${Theme.Functions.normalizeText()};
    ${Theme.Functions.setFont('Inter', 500)};

    &::placeholder {
        color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`text--100`], `0.35`)};
    }

    &:focus {
        outline: none;
        border-color: ${(props) => props.theme[`border--200`]};
    }

    &:disabled {
        color: ${(props) => props.theme[`text--100`]};
        background-color: ${(props) => props.theme[`background--200`]};
    }

    ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-md`])} {
        padding: 4px 8px;
    }
`

const IconBase = css`
    display: block;
    position: relative;
    width: 18px;
    height: 100%;

    ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-md`])} {
        width: 20px;
    }
`
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/

/***** EXPORTS *****/
export const FieldStyles = {
    Wrapper: FieldWrapper,
    Label: FieldLabel,
    Column: FieldColumn,
    Field,
    IconBase
}
