/* eslint-disable indent */
/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { getIn } from 'formik'
import { useId, useRef } from 'react'

/**********************************************************************************************************
 *   ASSET IMPORTS
 **********************************************************************************************************/
import { ExclamationCircleIcon } from '@heroicons/react/24/solid'

/**********************************************************************************************************
 *   RENDER INPUT FIELD
 **********************************************************************************************************/
import styled, { css } from 'styled-components'
import * as Theme from '../../theme/theme'

export const Style = {
    Label: css`
        display: block;
        margin-bottom: 4px;
        font-size: 1.2rem;
        color: ${(props) => props.theme[`text--200`]};
        ${Theme.Functions.normalizeText()};
        ${Theme.Functions.setFont('Inter', 700)};

        > span {
            margin-left: 4px;
            color: ${(props) => props.theme[`error--100`]};
        }
    `,
    Wrapper: css`
        position: relative;
        display: block;
        margin-bottom: 8px;

        ${(props) =>
            props.embedded &&
            css`
                margin-bottom: 0;
            `}
    `,
    Column: css`
        position: relative;
        display: inline-flex;
        flex-direction: row;
        width: 100%;
        border-radius: 6px;
        border: 1px solid ${(props) => props.theme[`border--100`]};
        ${Theme.Functions.animation(`border`)};

        ${(props) =>
            props.error &&
            css`
                border-color: ${(props) => props.theme[`error--100`]};
            `}

        &:focus-within {
            outline: none;
            border-color: ${(props) => props.theme[`border--200`]};
        }
    `,
    Field: css`
        position: relative;
        cursor: auto;
        display: inline-flex;
        flex: 1 1 0%;
        min-width: 0px;
        width: 100%;
        height: 38px;
        padding: 8px 12px;
        background-color: ${(props) => props.theme[`background--000`]};
        outline: 0;
        font-size: 1.2rem;
        color: ${(props) => props.theme[`text--200`]};
        border-radius: 6px;
        ${Theme.Functions.normalizeText()};
        ${Theme.Functions.setFont('Inter', 500)};

        &::placeholder {
            color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`text--100`], `0.35`)};
        }

        &:focus {
            outline: none;
            border-color: ${(props) => props.theme[`border--200`]};
        }

        &:disabled {
            color: ${(props) => props.theme[`text--100`]};
            background-color: ${(props) => props.theme[`background--200`]};
        }

        ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-md`])} {
            padding: 4px 8px;
        }
    `,
    Icon: {
        Base: css`
            display: block;
            position: relative;
            width: 18px;
            height: 100%;
            vertical-align: middle;

            ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-md`])} {
                width: 20px;
            }
        `
    },
    Toggle: {
        Label: css`
            position: relative;
            cursor: pointer;
            display: flex;
            padding: 15px 20px;
            border-radius: 8px;
            font-size: 1.2rem;
            color: ${(props) => props.theme[`text--200`]};
            ${Theme.Functions.normalizeText()};
            ${Theme.Functions.setFont('Inter', 500)};
            ${Theme.Functions.animation(`all`)};

            &.error {
                color: ${(props) => props.theme[`error--100`]};
            }

            &.disabled {
                cursor: default;
            }

            ${({ color, checked, disabled, loading }) => {
                if (disabled || loading) {
                    return css`
                        color: ${(props) => props.theme[`icon--100`]};
                        background-color: ${(props) => props.theme[`background--200`]};
                    `
                } else {
                    switch (color) {
                        case 'error':
                            if (checked) {
                                return css`
                                    background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`error--100`], `0.075`)};

                                    &:hover {
                                        background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`error--100`], `0.075`)};
                                    }

                                    &:focus {
                                        outline: none;
                                        background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`error--100`], `0.075`)};
                                    }
                                `
                            }

                            return css`
                                background-color: ${(props) => props.theme[`background--200`]};

                                &:hover {
                                    background-color: ${(props) => props.theme[`background--300`]};
                                }
                            `

                        default:
                        case 'primary':
                            if (checked) {
                                return css`
                                    background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`primary--100`], `0.075`)};

                                    &:hover {
                                        background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`primary--100`], `0.075`)};
                                    }

                                    &:focus {
                                        outline: none;
                                        background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`primary--100`], `0.075`)};
                                    }
                                `
                            }

                            return css`
                                background-color: ${(props) => props.theme[`background--200`]};

                                &:hover {
                                    background-color: ${(props) => props.theme[`background--300`]};
                                }
                            `
                    }
                }
            }}
        `
    }
}

export const Element = {
    Label: styled.label`
        ${Style.Label}
    `,
    Error: {
        Message: styled.p`
            display: inline-flex;
            min-height: 17px;
            height: auto;
            font-size: 1.2rem;
            color: ${(props) => props.theme[`error--100`]};
            ${Theme.Functions.normalizeText()};
            ${Theme.Functions.setFont('Inter', 600, 'italic')};
        `,
        Icon: styled(ExclamationCircleIcon)`
            ${Style.Icon.Base}
            color: ${(props) => props.theme[`error--100`]};
        `,
        Wrapper: styled.div`
            cursor: pointer;
            visibility: hidden;
            width: 0;
            opacity: 0; // @ts-ignore
            background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`error--100`], `0.1`)};
            padding: 0;
            ${Theme.Functions.animation(`all`)};

            &.visible {
                width: auto;
                padding: 0 6px;
                visibility: visible;
                opacity: 1;

                ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-md`])} {
                    padding: 0 10px;
                }
            }

            &:hover {
                > div,
                > svg {
                    visibility: visible;
                    opacity: 1;
                }
            }
        `,
        Tip: styled.div`
            visibility: hidden;
            opacity: 0;
            position: absolute;
            top: -32px;
            right: 0;
            width: fit-content;
            padding: 8px 16px;
            border-radius: 6px;
            border: 1px solid ${(props) => props.theme[`error--100`]};
            background-color: ${(props) => props.theme[`error--100`]};
            font-size: 1.2rem;
            color: ${(props) => props.theme[`white--100`]};
            ${Theme.Functions.setFont('Inter', 500)};
            ${Theme.Functions.animation(`all`)};

            ${(props) =>
                props.size &&
                css`
                    top: calc(-${props.size}px - 5px);
                `}
        `,
        Arrow: styled.div`
            position: absolute;
            bottom: -5px;
            right: 13px;
            width: 0;
            height: 0;
            border-width: 0 5px 5px 5px;
            border-color: transparent transparent ${(props) => props.theme[`error--100`]} transparent;
            border-style: solid;
            transform: rotate(180deg);
            visibility: hidden;
            opacity: 0;
            ${Theme.Functions.animation(`all`)};
        `
    }
}

/**********************************************************************************************************
 *   RENDER INPUT ELEMENTS
 **********************************************************************************************************/
export const RenderInputLabel = (embedded, required, label, field) => {
    return (
        label &&
        !embedded && (
            <Element.Label htmlFor={field.id || field.name}>
                {label}
                {required && <span>*</span>}
            </Element.Label>
        )
    )
}

/**********************************************************************************************************
 *   RENDER ERRORS
 **********************************************************************************************************/
export const RenderErrorMessages = (name, touched, errors, force) => {
    if (force)
        return (
            <Element.Error.Message id={`${name}-error`} className={'visible'}>
                {errors}
            </Element.Error.Message>
        )
    return (
        touched[name] &&
        errors[name] && (
            <Element.Error.Message id={`${name}-error`} className={touched[name] && errors[name] && 'visible'}>
                {errors[name]}
            </Element.Error.Message>
        )
    )
}

export const RenderErrorIcon = (name, touched, errors) => {
    const tooltipRef = useRef(null)
    const IconRender = <Element.Error.Icon id={`${name}-error-icon`} />
    const error = getIn(errors, name)
    const touch = getIn(touched, name)
    const TipRender = (
        <Element.Error.Tip id={useId()} ref={tooltipRef} size={tooltipRef?.current?.offsetHeight}>
            {error}
            <Element.Error.Arrow focus={touch} />
        </Element.Error.Tip>
    )

    return (
        <Element.Error.Wrapper id={useId()} className={touch && error && 'visible'} aria-hidden={touch && error ? 'true' : 'false'}>
            {TipRender}
            {IconRender}
        </Element.Error.Wrapper>
    )
}
