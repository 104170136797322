import { usePayInvoiceMutation } from 'api/billing'
import { useCheckoutCartMutation } from 'api/shop'
import { renderGenericPaymentMethodDescription } from 'components/invoice/invoice'
import { Payment } from 'components/invoice/invoice.styles'
import { useApplyAccountCreditToInvoice } from 'containers/billing/utils'
import { ShopRouteBuilder } from 'containers/shop/helpers/routeMatchers'
import { Field, Formik } from 'formik'
import { useLocalStorage } from 'helpers/hooks'
import { PaymentMethod } from 'models/billing'
import { Form as NXUIForm } from 'nxui/src'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function LinkTypeForm({ selectedPaymentMethod }: { selectedPaymentMethod?: PaymentMethod }) {
    const navigate = useNavigate()

    const { cart } = useAppSelector((state) => state.app)
    const { checkoutAccountCredit } = useAppSelector((state) => state.shop)
    const [checkoutCart, { isLoading: isCheckoutCartLoading }] = useCheckoutCartMutation({ fixedCacheKey: 'checkout-cart' })
    const [payInvoice, { isLoading: isPayInvoiceLoading }] = usePayInvoiceMutation({ fixedCacheKey: 'pay-checkout-invoice' })
    const { applyAccountCreditToInvoice } = useApplyAccountCreditToInvoice()
    const [, setRedirect] = useLocalStorage<{ isRedirect: boolean; invoice?: number; order?: number }>('redirect', {
        isRedirect: false,
        invoice: undefined,
        order: undefined
    })

    /*   RENDER COMPONENT
     *****************************************************/
    if (!cart || !selectedPaymentMethod) return <></>

    return (
        <Formik
            initialValues={{
                save_details: false
            }}
            onSubmit={async ({ save_details }) => {
                const order = await checkoutCart({
                    uuid: cart.uuid,
                    payment_method_id: selectedPaymentMethod.id
                }).unwrap()

                const { isInvoiceStillUnpaid, isSuccess } = await applyAccountCreditToInvoice(order.invoice.id, checkoutAccountCredit.amount)

                let link: string | undefined = undefined

                if (isSuccess && isInvoiceStillUnpaid) {
                    const invoice = await payInvoice({
                        id: String(order.invoice.id),
                        payment_method_id: selectedPaymentMethod.id,
                        payment_data: { save_details }
                    })
                        .unwrap()
                        .catch(() => {
                            /* Catch the error so the function can continue executing (this is required because of "unwrap"), the "order summary" page will then tell the user that payment failed */
                        })

                    link = invoice?.link
                }

                navigate(ShopRouteBuilder.orderId(order.id))
                if (link) {
                    setRedirect({ isRedirect: true, invoice: order.invoice.id, order: order.id })
                    window.location.href = link
                }
            }}
        >
            <Payment.Form id='linkTypeForm'>
                {renderGenericPaymentMethodDescription(selectedPaymentMethod?.name || '', selectedPaymentMethod?.instructions || '')}
                {selectedPaymentMethod.module_meta?.is_persistable ? (
                    <Payment.LinkType.SaveDetailsContainer>
                        <Field
                            name={'save_details'}
                            type={'checkbox'}
                            disabled={isCheckoutCartLoading || isPayInvoiceLoading}
                            description={'Save Details'}
                            component={NXUIForm.CheckboxField}
                        />
                    </Payment.LinkType.SaveDetailsContainer>
                ) : (
                    ''
                )}
            </Payment.Form>
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
