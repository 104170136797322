/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Outlet, useLocation } from 'react-router-dom'

/**********************************************************************************************************
 *   SHARED COMPONENTS
 **********************************************************************************************************/
import { Loader } from 'components/loader'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import { Auth } from 'containers/authentication/authentication.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function AuthenticationWizard() {
    const location = useLocation()
    const { appSession } = useAppSelector((state) => state.app)

    /*   RENDER COMPONENT
     *****************************************************/
    if (!appSession) {
        return (
            <Auth.Base when={location.pathname}>
                <Loader width={36} height={36} />
            </Auth.Base>
        )
    }

    return (
        <Auth.Base className={location.pathname === '/login/signup' ? 'signup' : ''}>
            <Auth.Fade when={location.pathname}>
                <Outlet />
            </Auth.Fade>
        </Auth.Base>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
