/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Form, Formik, FormikProps } from 'formik'
import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useCancelServiceMutation, useCheckCancelableServiceQuery } from 'api/service'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Cancel } from 'containers/services/services.styles'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { defaultCurrency, titleCase } from 'helpers/utils'
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
import { RefundMethod } from 'models/enums'
import { CancelService } from 'models/service'

interface Props {
    close: () => void
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function CancelFormComponent({ close }: Props) {
    const form = useRef<FormikProps<CancelService>>(null)
    const { id } = useParams()
    const serviceId = Number(id)
    const [cancelService, { isLoading, isSuccess, isError }] = useCancelServiceMutation({ fixedCacheKey: 'cancel-service' })
    const { data } = useCheckCancelableServiceQuery(serviceId)
    const {
        appSettings: {
            provider: { provider_name }
        }
    } = useAppSelector((state) => state.app)
    const reasons = [
        {
            label: 'No longer need this service',
            value: 'No longer need this service'
        },
        {
            label: `Transferring to another ${provider_name} service`,
            value: `Transferring to another ${provider_name} service`
        },
        {
            label: 'Unhappy with the performance of the service',
            value: 'Unhappy with the performance of the service'
        },
        {
            label: 'The quality of support provided was unsatisfactory',
            value: 'The quality of support provided was unsatisfactory'
        },
        {
            label: 'The price and inclusions are no longer competitive',
            value: 'The price and inclusions are no longer competitive'
        },
        {
            label: 'Other',
            value: 'Other'
        }
    ]
    const time = [
        {
            label: 'Cancel ASAP',
            value: 'immediate'
        },
        {
            label: 'End of billing cycle',
            value: 'end_of_billing_cycle'
        }
    ]

    useEffect(() => {
        if ((isSuccess || isError) && form.current?.dirty) {
            form.current.setSubmitting(false)
            form.current.resetForm()
        }

        if (isSuccess && form.current?.dirty) {
            close()
        }
    }, [form, isSuccess, isError])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Formik
            innerRef={form}
            initialValues={{
                reason: reasons[0].value,
                reason_comment: '',
                type: 'immediate',
                refund_method: 'none',
                confirm_delete: false,
                confirm_backup: false,
                account_name: '',
                branch_number: '',
                account_number: ''
            }}
            validationSchema={Yup.object({
                reason: Yup.string(),
                reason_comment: Yup.string(),
                type: Yup.string(),
                refund_method: Yup.string(),
                confirm_delete: Yup.bool(),
                confirm_backup: Yup.bool(),
                account_name: Yup.string().when('refund_method', {
                    is: 'manual',
                    then: (schema) => schema.required('Required')
                }),
                branch_number: Yup.number().when('refund_method', {
                    is: 'manual',
                    then: (schema) =>
                        schema
                            .typeError('Invalid branch number')
                            .required('Required')
                            .min(100_000, 'Invalid branch number')
                            .max(999_999, 'Invalid branch number')
                }),
                account_number: Yup.number().when('refund_method', {
                    is: 'manual',
                    then: (schema) => schema.typeError('Invalid account number').required('Required')
                })
            })}
            onSubmit={({ refund_method, account_name, branch_number, account_number, ...rest }) => {
                if (refund_method === RefundMethod.Manual) {
                    cancelService({ id: serviceId, refund_method, account_name, branch_number, account_number, ...rest })
                } else {
                    cancelService({ id: serviceId, refund_method, ...rest })
                }
            }}
        >
            {({ values }) => (
                <Form id={'cancelForm'}>
                    <Field
                        label={'Primary Reason for Cancelling Service'}
                        name={'reason'}
                        type={'select'}
                        disabled={isLoading}
                        list={reasons}
                        component={NXUIForm.SelectField}
                    />
                    <Field label={'Additional Comments'} name={'reason_comment'} component={NXUIForm.TextAreaField} />
                    <Field
                        label={'When would you like your service to be terminated?'}
                        name={'type'}
                        type={'select'}
                        disabled={isLoading}
                        list={time}
                        component={NXUIForm.SelectField}
                    />
                    {data?.refundable && (
                        <Field
                            label={'Refund Methods'}
                            name={'refund_method'}
                            type={'select'}
                            disabled={isLoading}
                            list={data.refund_methods.map((method: string) => {
                                return {
                                    label: titleCase(method.replace(/_/g, ' ')),
                                    value: method
                                }
                            })}
                            component={NXUIForm.SelectField}
                        />
                    )}
                    {values.refund_method === RefundMethod.Manual && (
                        <>
                            <Field label={'Account Name'} name={'account_name'} type={'text'} disabled={isLoading} component={NXUIForm.InputField} />
                            <Field
                                label={'Branch Number'}
                                name={'branch_number'}
                                type={'text'}
                                disabled={isLoading}
                                component={NXUIForm.InputField}
                            />
                            <Field
                                label={'Account Number'}
                                name={'account_number'}
                                type={'text'}
                                disabled={isLoading}
                                component={NXUIForm.InputField}
                            />
                        </>
                    )}
                    {data?.refundable && data?.refund_amount ? (
                        <Cancel.Refund>
                            <Cancel.Label>Refund Amount</Cancel.Label>
                            <Cancel.Value>{defaultCurrency(data?.refund_amount)}</Cancel.Value>
                        </Cancel.Refund>
                    ) : (
                        ''
                    )}
                    <Field
                        id={'confirm_delete'}
                        description={'I confirm all data stored as part of this service, including backups, will be deleted.'}
                        name={'confirm_delete'}
                        type={'checkbox'}
                        component={NXUIForm.CheckboxField}
                    />
                    <Field
                        id={'confirm_backup'}
                        description={'I confirm any data I require is backed up externally.'}
                        name={'confirm_backup'}
                        type={'checkbox'}
                        component={NXUIForm.CheckboxField}
                    />
                </Form>
            )}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { CancelFormComponent as CancelForm }
