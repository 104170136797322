/* eslint-disable indent */
/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { usePaymentInputs } from 'react-payment-inputs'
import images from 'react-payment-inputs/images'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { RenderErrorIcon, Style } from '../util'
import { Input } from './input'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled, { css } from 'styled-components'

const Payment = {
    Wrapper: styled.div`
        ${Style.Column}
        width: 100%;
    `,
    Field: styled.div`
        ${Style.Field}
        align-items: center;

        ${({ disabled }) => {
            if (disabled) {
                return css`
                    background-color: ${(props) => props.theme[`background--200`]};
                `
            }
        }}

        svg {
            width: 48px;
            margin-right: 8px;
        }

        input {
            width: 36px;
            margin-right: 8px;
            outline: none;

            &:disabled {
                background-color: ${(props) => props.theme[`background--200`]};
            }

            &:nth-child(3) {
                width: 50px;
            }

            &:first-of-type {
                width: 100%;
            }
        }
    `
}

/**********************************************************************************************************
 *   PAYMENT FIELD
 **********************************************************************************************************/
export const PaymentField = ({
    className,
    embedded,
    required,
    label,
    disabled,
    expiry = true,
    cvc = false,
    form: { touched, errors, isSubmitting }
}) => {
    /*   REF
     *****************************************************/
    const cardRef = useRef()
    const expiryRef = useRef()
    const cvcRef = useRef()

    /*   STATE
     *****************************************************/
    const [active, setActive] = useState(false)
    const [hasFocus, setFocus] = useState(false)

    const {
        getCardImageProps,
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps,

        wrapperProps
    } = usePaymentInputs()

    /*   EFFECT
     *****************************************************/
    useEffect(() => {
        if (
            (document.hasFocus() && cardRef?.current?.contains(document.activeElement)) ||
            expiryRef?.current?.contains(document.activeElement) ||
            cvcRef?.current?.contains(document.activeElement)
        ) {
            setFocus(true)
        }
    }, [])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Input.Wrapper className={className} embedded={embedded}>
            {label && !embedded && (
                <Input.Label>
                    {label}
                    {required && <span>*</span>}
                </Input.Label>
            )}
            <Payment.Wrapper focus={hasFocus} error={errors[active]}>
                <Payment.Field {...wrapperProps} disabled={disabled}>
                    <svg {...getCardImageProps({ images })} />
                    <Field name='cardNumber'>
                        {({ field }) => (
                            <input
                                ref={cardRef}
                                name={`cardNumber`}
                                {...getCardNumberProps({
                                    onChange: field.onChange
                                })}
                                onBlur={(e) => {
                                    field.onBlur && field.onBlur(e)
                                    setFocus(false)
                                }}
                                onFocus={(e) => {
                                    setActive('cardNumber')
                                    setFocus(true)
                                }}
                                disabled={isSubmitting || disabled}
                            />
                        )}
                    </Field>
                    {expiry && (
                        <Field name='expiryDate'>
                            {({ field }) => (
                                <input
                                    ref={expiryRef}
                                    name={`expiryDate`}
                                    {...getExpiryDateProps({
                                        onChange: field.onChange
                                    })}
                                    onBlur={(e) => {
                                        field.onBlur && field.onBlur(e)
                                        setFocus(false)
                                    }}
                                    onFocus={(e) => {
                                        setActive('expiryDate')
                                        setFocus(true)
                                    }}
                                    disabled={isSubmitting || disabled}
                                />
                            )}
                        </Field>
                    )}
                    {cvc && (
                        <Field name='cvc'>
                            {({ field }) => (
                                <input
                                    ref={cvcRef}
                                    name={`cvc`}
                                    {...getCVCProps({
                                        onChange: field.onChange
                                    })}
                                    onFocus={(e) => {
                                        setActive('cvc')
                                        setFocus(true)
                                    }}
                                    onBlur={(e) => {
                                        field.onBlur && field.onBlur(e)
                                        setFocus(false)
                                    }}
                                    disabled={isSubmitting || disabled}
                                />
                            )}
                        </Field>
                    )}
                </Payment.Field>
                {RenderErrorIcon(active, touched, errors)}
            </Payment.Wrapper>
        </Input.Wrapper>
    )
}
