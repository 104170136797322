import { IFieldWrapper } from 'containers/shop/components/form.d'
import { Theme } from 'nxui/src'
import { ReactElement } from 'react'
import styled, { css } from 'styled-components'

export const Field = {
    Wrapper: styled.div<IFieldWrapper>`
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        margin-bottom: 8px;

        ${(props) =>
            props.embedded &&
            css`
                margin-bottom: 0;
                flex-flow: row nowrap;
            `}

        /* 
         * breakpoints--md is invalid, fix this the next time this component is being worked on
         * @link {src/declarations/styled.d.ts}
         */
        ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints--md`])} {
            max-width: 250px;
        }

        /* 
         * breakpoints--md and breakpoints--lg is invalid, fix this the next time this component is being worked on
         * @link {src/declarations/styled.d.ts}
         */
        ${(props) => Theme.Functions.mediaBreakBetween(props.theme[`breakpoints--md`], props.theme[`breakpoints--lg`])} {
            max-width: 350px;
        }
    `
}

/**********************************************************************************************************
 *   RENDER ERRORS
 **********************************************************************************************************/
export const FieldWrapper = (props: IFieldWrapper): ReactElement => {
    // @ts-ignore
    return <Field.Wrapper {...props}>{props.children}</Field.Wrapper>
}
