/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { ResultDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions'

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { BaseQueryFn } from '@reduxjs/toolkit/query/react'
import { TTagTypes } from 'api/base'
import type { Links, MetaResponse } from 'models/app'
import type { Service } from 'models/service'
import { ServiceMetaPayload } from './types'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TServiceResponse = {
    data: Array<Service>
    links: Links
    meta: MetaResponse
}

type TProvidesTags = ResultDescription<TTagTypes, TServiceResponse, ServiceMetaPayload, unknown, NonNullable<unknown> | undefined>
type TQuery = (args: ServiceMetaPayload) => string
type TEndpointBuilder = EndpointBuilder<BaseQueryFn, TTagTypes, 'api'>

/**********************************************************************************************************
 *  HELPER FUNCTIONS
 **********************************************************************************************************/
const handleFilter = (filter_by?: Array<string>) => {
    if (!filter_by) return ''

    return (
        [...filter_by]
            //sort all values that include product- to front
            .sort((a, b) => {
                if (a.includes('product-') && !b.includes('product-')) return 1
                if (!a.includes('product-') && b.includes('product-')) return -1
                return 0
            })
            //combine each filter into a single query string
            .reduce((acc, filter) => {
                return acc + `&filter_by[]=${filter}`
            }, '')
    )
}

/**********************************************************************************************************
 *   QUERY FUNCTIONS
 **********************************************************************************************************/
const query: TQuery = ({ page = 1, filter_by, sort_by, search_by }) => {
    const filterBy = handleFilter(filter_by)
    const sortBy = sort_by ? `&sort_by=${sort_by}` : ''
    const searchBy = search_by ? `&search_by=${search_by.key},~,${search_by.value}` : ''

    return `client/services?page=${page}${sortBy}${filterBy}${searchBy}`
}

const providesTags: TProvidesTags = (result) => {
    return result
        ? [...result.data.map(({ id }) => ({ type: 'Services', id } as const)), { type: 'Services', id: 'LIST' }]
        : [{ type: 'Services', id: 'LIST' }]
}

/**********************************************************************************************************
 *   API ENDPOINT
 **********************************************************************************************************/
export const servicesEndpoint = (builder: TEndpointBuilder) =>
    builder.query<TServiceResponse, ServiceMetaPayload>({
        query,
        providesTags
    })
