import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { Anchor, Button, Theme } from 'nxui/src'
import styled, { css } from 'styled-components'

const createRecordStyles = () => {
    const Wrapper = styled.div`
        overflow-y: auto;
        max-height: 50vh;
        position: relative;
    `

    const Background = styled.div`
        background-color: white;
        padding: 24px;
    `

    const Outer = styled.div`
        border-radius: 16px;
        border: 1px solid ${({ theme }) => theme['border--000']};
        overflow: hidden;
        display: flex;
        flex-direction: column;
    `

    const Single = styled.div`
        display: grid;
        grid-template-columns: 1fr 4fr;
        column-gap: 20px;
        row-gap: 13px;
        padding: 25px;
        border-bottom: 1px solid ${({ theme }) => theme['border--000']};
    `

    const Label = styled.h3`
        font-weight: 500;
        color: ${({ theme }) => theme['text--100']};
        font-size: 14px;
    `

    const Content = styled.div`
        font-size: 14px;
        letter-spacing: -0.25px;
        font-family: Inter;
    `

    const ContentType = styled(Content)`
        border: 1px solid ${({ theme }) => theme['border--000']};
        width: fit-content;
        padding-inline: 6px;
        border-radius: 4px;
        text-transform: uppercase;
        text-align: center;
    `

    const Title = styled.h3<{ fixed: boolean }>`
        padding: 10px 20px;
        font-weight: 500;

        background-color: ${({ theme }) => theme['background--100']};
        color: ${({ theme }) => theme['text--100']};
        border: 1px solid ${({ theme }) => theme['border--000']};
        font-size: 12px;
        width: 100%;

        ${({ fixed }) => {
            if (!fixed) return

            return `
                position: sticky;
                top: 0;
                z-index: 1;
            `
        }}
    `

    const ContentMergeField = styled.span`
        padding: 2px 5px;
        border: 1px solid ${({ theme }) => theme['border--000']};
        border-radius: 5px;
    `

    return {
        Outer,
        Single,
        Label,
        Content,
        ContentType,
        Background,
        Title,
        Wrapper,
        ContentMergeField
    }
}

export const Preset = {
    Select: {
        Outer: styled.div`
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
        `,
        Item: styled.button`
            padding: 30px;
            border: 1px solid ${({ theme }) => theme['border--000']};
            border-radius: 10px;
            width: 100%;

            font-size: 14px;
            text-align: center;
            font-weight: 500;
            color: ${({ theme }) => theme['text--200']};

            ${Theme.Functions.animation('background-color')}

            &:hover {
                cursor: pointer;
                background-color: ${({ theme }) => theme['background--100']};
            }
        `
    },
    Form: {
        Notice: styled.p`
            padding: 20px;
            padding-right: 50px;
            background-color: #fff5cc;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;

            font-size: 12px;
            color: #735a1c;

            & > span {
                font-weight: bold;
            }
        `,
        InputFields: styled.div`
            padding: 20px;
            background-color: ${({ theme }) => theme['background--000']};
        `,
        Record: createRecordStyles()
    },
    Confirm: {
        Outer: styled.div`
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
        `,
        IconBackdrop: styled.div`
            background-color: #fff5cc;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        `,
        Icon: styled(InformationCircleIcon)`
            border-radius: 50%;
            width: 22px;
            height: 22px;
            color: #735a1c;
        `,
        Title: styled.h3`
            font-size: 16px;
            font-weight: 500;
            color: ${({ theme }) => theme['text--200']};
            text-align: center;
        `,
        Description: styled.p`
            font-size: 14px;
            text-align: center;
            color: ${({ theme }) => theme['text--200']};
            padding: 0 20px;
        `,
        Acknowledge: styled(Button).attrs({
            type: 'button',
            color: 'primary'
        })<{ loading: boolean }>`
            ${({ loading }) =>
                !loading
                    ? css`
                          padding-left: 30px;
                          padding-right: 30px;
                      `
                    : css`
                          padding-left: 25px;
                      `}
        `,
        Cancel: styled(Anchor).attrs({
            color: 'primary'
        })`
            margin: 25px;
            width: calc(100% - 50px);
            font-size: 14px;
            font-weight: 500;

            display: flex;
            justify-content: center;
        `
    }
}
