/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import React from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Fade } from '../../transition/transition'

/**********************************************************************************************************
 *   CUSTOM STYLES
 **********************************************************************************************************/
import styled from 'styled-components'
import * as Theme from '../../../theme/theme'

const Errors = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        padding: 20px;
        margin: 15px;
        border-radius: 16px;
        background-color: ${(props) => props.theme[`background--100`]};
    `,
    Error: styled.li`
        cursor: pointer;
        font-size: 1.4rem;
        color: ${(props) => props.theme[`error--100`]};
        ${Theme.Functions.setFont('Inter', 500)};
    `
}

/**********************************************************************************************************
 *   COMPONENT
 **********************************************************************************************************/
const ErrorComponent = ({ form }) => {
    return (
        <Fade when={Object.keys(form.errors)?.length > 0}>
            <Errors.Container>
                <ul>
                    {Object.keys(form.errors)?.map((error, index) => (
                        <Errors.Error key={index}>{form.errors[error]}</Errors.Error>
                    ))}
                </ul>
            </Errors.Container>
        </Fade>
    )
}

/**********************************************************************************************************
 *   EXPORTS
 **********************************************************************************************************/
export { ErrorComponent as Error }
