/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { ShoppingCartIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button } from '../button'

/**********************************************************************************************************
 *   STYLE
 **********************************************************************************************************/
const StyledEmptyStates = styled.div`
    text-align: center;

    > .container {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
        max-width: 768px;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px;

        > .title {
            color: ${(props) => props.theme['text--200']};
        }

        > .description {
            color: ${(props) => props.theme['text--100']};
        }

        > .icon {
            display: flex;
            justify-content: center;
            flex-shrink: 0;
            color: ${(props) => props.theme['icon--300']};
        }

        > .action {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 16px;

            > .icon {
                width: 22px;
                height: 22px;
            }
        }
    }
`

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Empty = ({
    className,
    icon = <InformationCircleIcon width={`48px`} />,
    title = 'title',
    description = 'description',
    action = {
        label: 'Button',
        color: 'primary',
        icon: <ShoppingCartIcon />,
        func: () => console.log('Click event')
    }
}) => {
    return (
        <StyledEmptyStates className={className ? ` ${className}` : ``}>
            <main className={'container'}>
                <div className={'icon'}>{icon}</div>
                <h3 className={'title'}>{title}</h3>
                <p className={'description'}>{description}</p>
                <div className={'action'}>
                    <Button type={action.type} color={action.color} onClick={action.func}>
                        <div className={'icon'}>{action.icon}</div>
                        {action.label}
                    </Button>
                </div>
            </main>
        </StyledEmptyStates>
    )
}

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
Empty.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.element,
    title: PropTypes.string,
    description: PropTypes.string,
    action: PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.element,
        type: PropTypes.oneOf(['button', 'submit', 'reset']),
        color: PropTypes.oneOf(['primary', 'secondary', 'error', 'flat']),
        func: PropTypes.func
    })
}
