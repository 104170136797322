/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Table } from 'nxui/src'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
import { Service } from 'models/service'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
// The "MinWidth" table cell styling is just so that the table headings for "DKIM Record" and "SPF Record" have enough width so that the text doesn't wrap on to 2 lines
export const ServerDataTableCell = {
    WordBroken: styled.div`
        word-break: break-all;
    `,
    MinWidth: styled.div`
        min-width: 130px;
    `
}

interface Props {
    server: Service['server']
}

export function DkimRecords({ server }: Props) {
    const { dkim } = server.additional_meta || {}

    const tableColumns = [
        {
            Header: 'DKIM Record',
            accessor: 'details'
        },
        {
            Header: '',
            accessor: 'value'
        },
        {
            Header: '',
            accessor: 'action',
            align: 'right'
        }
    ]

    const tableData = [
        {
            details: 'Hostname',
            value: dkim?.record_hostname || '',
            action: ''
        },
        {
            details: <ServerDataTableCell.MinWidth>Content (TXT)</ServerDataTableCell.MinWidth>,
            value: <ServerDataTableCell.WordBroken>{dkim?.record_value || ''}</ServerDataTableCell.WordBroken>,
            action: ''
        }
    ]

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Table
            render={{
                columns: () => tableColumns,
                data: () => tableData
            }}
        />
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
