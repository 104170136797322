/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import React from 'react'
import PropTypes from 'prop-types'

import { isValidArray } from '../../../helpers/functions'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import { Skeleton } from './skeleton.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SkeletonLoader = ({ sections = [] }) => {
    /*   FUNCTIONS
     *****************************************************/
    function renderSections() {
        return sections.map((i, index) => {
            switch (i) {
                case 'title':
                    return <Skeleton.Title key={`${i}-index`} />

                case 'description':
                    return <Skeleton.Description key={`${i}-index`} />

                case 'content':
                    return <Skeleton.Content key={`${i}-index`} />

                case 'footer':
                    return <Skeleton.Footer key={`${i}-index`} />

                default:
                    break
            }
        })
    }

    return (
        <Skeleton.Base role={'progressbar'}>
            <Skeleton.Gradient />
            {isValidArray(sections) && renderSections()}
        </Skeleton.Base>
    )
}

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
SkeletonLoader.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    sections: PropTypes.array
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { SkeletonLoader as Skeleton }
