/**********************************************************************************************************
 *   IMPORTS/MODULES
 **********************************************************************************************************/
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Dropdown } from './dropdown.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const DropdownComponent = ({ className, label, icon, options, forceOpen = false, ...props }) => {
    /*   REF
     *****************************************************/
    const selectorRef = useRef()
    const listRef = useRef()

    /*   STATE
     *****************************************************/
    const [selected, setSelected] = useState(options.find((option) => option?.active)?.key || '')
    const [isActive, setIsActive] = useState(false)

    /*   EFFECTS
     *****************************************************/
    useEffect(() => {
        const checkClickOutside = (e) => {
            if (
                !forceOpen &&
                isActive &&
                listRef.current &&
                !listRef.current.contains(e.target) &&
                selectorRef.current &&
                !selectorRef.current.contains(e.target)
            ) {
                setIsActive(false)
            }
        }

        if (!forceOpen) document.addEventListener('mousedown', checkClickOutside)

        return () => {
            if (!forceOpen) document.removeEventListener('mousedown', checkClickOutside)
        }
    }, [isActive])

    /*   FUNCTIONS
     *****************************************************/
    function handleOptionOnClick(key, onClick) {
        return () => {
            setSelected(key)
            setIsActive(false)
            onClick()
        }
    }

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Dropdown.Wrapper className={className}>
            {label && <Dropdown.Label>{label}</Dropdown.Label>}
            <Dropdown.Container active={isActive} {...props}>
                <Dropdown.Selector ref={selectorRef} onClick={() => setIsActive(!isActive)}>
                    {icon && <Dropdown.Icon>{icon}</Dropdown.Icon>}
                    <Dropdown.Title>{(options.find(({ key }) => selected === key) || options[0]).label}</Dropdown.Title>
                    <Dropdown.Chevron />
                </Dropdown.Selector>
            </Dropdown.Container>
            <Dropdown.List ref={listRef} active={isActive} height={listRef?.current?.scrollHeight}>
                {options.map(({ label, key, onClick }) => (
                    <Dropdown.Option key={key} onClick={handleOptionOnClick(key, onClick)} active={selected === key}>
                        {label}
                    </Dropdown.Option>
                ))}
            </Dropdown.List>
        </Dropdown.Wrapper>
    )
}

/**********************************************************************************************************
 *   PROPTYPES
 **********************************************************************************************************/
DropdownComponent.propTypes = {
    /** ClassName to give the component */
    className: PropTypes.string,

    /** Can display a label similar to input fields */
    label: PropTypes.node,

    /** Icon by name, props object, or pass an <Icon />. */
    icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

    /** Dropdown options */
    options: PropTypes.arrayOf(
        PropTypes.shape({
            // Unique identifier for the option
            key: PropTypes.string,
            // Node to display for that option label
            label: PropTypes.node,
            // Function to call when the option is clicked
            onClick: PropTypes.func
        })
    ),

    /** Force the dropdown to be open */
    forceOpen: PropTypes.bool
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { DropdownComponent as Dropdown }
