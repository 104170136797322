/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   COMPONENT IMPORTS
 **********************************************************************************************************/
import { Status } from 'nxui/src'

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
export const Container = styled.main`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function ErrorBoundary() {
    return (
        <Container>
            <Status status={500} back={() => (window.location.href = window.location.origin)} />
        </Container>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
