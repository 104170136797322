/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
import { WEIGHT } from 'helpers/constants'
import { Theme } from 'nxui/src'
import styled from 'styled-components'

export const Loader = {
    Container: styled.div`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `,
    Message: styled.p`
        ${Theme.Functions.setFont('Inter', WEIGHT.SEMI_BOLD)};
    `
}
