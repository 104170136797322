/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Form, Formik, FormikProps } from 'formik'
import { RefObject } from 'react'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Form as NXUIForm } from 'nxui/src'
/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { domainAPI, useUpdateEligibilityMutation } from 'api/domain'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { snakeCase } from 'helpers/utils'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
interface Props {
    form: RefObject<FormikProps<{ eligibility_information: Array<{ field_name: string; field_value: string }> }>>
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function UpdateEligibilityForm({ form }: Props) {
    const { id } = useParams()
    const domainId = Number(id)
    const { data } = domainAPI.endpoints.eligibilityDetails.useQueryState(domainId)
    const [updateEligibility] = useUpdateEligibilityMutation({ fixedCacheKey: 'update-eligibility' })

    function renderFields() {
        if (!data) {
            return null
        }

        return data.map(({ field_name, field_type, field_options }, index) => {
            if (field_type === 'dropdown') {
                return (
                    <Field
                        key={snakeCase(field_name)}
                        label={field_name}
                        name={`eligibility_information.${index}.field_value`}
                        type={'select'}
                        list={field_options.split(',').map((option) => {
                            return {
                                value: option,
                                label: option
                            }
                        })}
                        component={NXUIForm.SelectField}
                    />
                )
            }

            return (
                <Field
                    key={snakeCase(field_name)}
                    label={field_name}
                    name={`eligibility_information.${index}.field_value`}
                    component={NXUIForm.InputField}
                />
            )
        })
    }

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Formik
            innerRef={form}
            initialValues={{
                eligibility_information: data?.map(({ field_name, field_value, field_type, field_options }) => {
                    if (field_type === 'dropdown') {
                        const options = field_options.split(',')

                        return {
                            field_name,
                            field_value: options[0]
                        }
                    }

                    return {
                        field_name,
                        field_value
                    }
                }) ?? [{ field_name: '', field_value: '' }]
            }}
            validationSchema={Yup.object({
                eligibility_information: Yup.array().of(
                    Yup.object().shape({
                        field_value: Yup.string().required('Required')
                    })
                )
            })}
            onSubmit={({ eligibility_information }) => {
                updateEligibility({ id: domainId, eligibility_information })
            }}
        >
            {() => <Form id={'addEligibilityForm'}>{renderFields()}</Form>}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
