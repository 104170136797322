import { useLocalStorage } from 'helpers/hooks'

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * retrives the tld list from localStorage and ensures that it is the correct type
 */
export const useTLDList = () => {
    const [tldList] = useLocalStorage<unknown>('tld', [])

    if (!Array.isArray(tldList)) return null
    if (tldList.some((tld) => typeof tld !== 'string')) return null

    return tldList as Array<string>
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * renders the children if there are multiple tlds available
 */
export const RenderWhileMultipleTLDsAvailable = ({ children }: { children: React.ReactNode }) => {
    const tldList = useTLDList()

    return Number(tldList?.length) > 1 ? <>{children}</> : null
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
