import { Anchor } from 'nxui/src'
import styled from 'styled-components'

export const Style = {
    Anchor: styled(Anchor)<{ showDisabled: boolean; disabled: boolean }>`
        ${({ showDisabled }) =>
            showDisabled &&
            `
            pointer-events: none;
            opacity: 0.7;
            user-select: none
        `}

        ${({ disabled }) =>
            disabled &&
            `
            pointer-events: none;
            user-select: none;
        `}
    `
}
