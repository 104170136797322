/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useLocation, useNavigate } from 'react-router-dom'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import { TGenericUseLocation as GenericUseLocation, TGenericUseNavigate as GenericUseNavigate } from './types'

/***** ROUTES *****/
export type ParseableShopRoutes =
    | '/shop'
    | '/shop/purchase'
    | '/shop/checkout'
    | '/shop/order'
    | '/shop/landing'
    | '/shop/:groupId'
    | '/shop/:groupId/purchase'
    | '/shop/order/:orderId'
    | '/shop/:groupId/configure/domains/:id'
    | '/shop/:groupId/configure/services/:id'
    | '/shop/:groupId/configure/services/:id/addons/:addonId'

export type ShopRoutes =
    | '/shop'
    | '/shop/purchase'
    | '/shop/checkout'
    | '/shop/order'
    | '/shop/landing'
    | `/shop/order/${string}`
    | `/shop/${string}/purchase`
    | `/shop/${string}/configure/domains/${string}`
    | `/shop/${string}/configure/services/${string}`
    | `/shop/${string}/configure/services/${string}/addons/${string}`

// prettier-ignore
export type ShopRouteParams<TRoutes = ParseableShopRoutes> =
    TRoutes extends `${string}/:${infer Param}/${infer Rest}`
        ? Param | ShopRouteParams<Rest> :
    TRoutes extends `${string}/:${infer Param}`
        ? Param
    : never

// prettier-ignore
export type WebsiteRoutes = 
    | '/'
    | '/domain-pricing'
    | '/terms-of-service'
    | '/whois'
    | '/about-us'

export type AllRoutes = ShopRoutes | WebsiteRoutes

/***** SHOP TYPE DEFINITIONS *****/
type TShopState = {
    search?: string
}
export type UseShopNavigate = GenericUseNavigate<ShopRoutes, TShopState>
export type UseShopLocation = GenericUseLocation<ShopRoutes, TShopState>

/***** WEBSITE TYPE DEFINITIONS *****/
export type WebsiteState = unknown
type UseWebsiteNavigate = GenericUseNavigate<WebsiteRoutes, WebsiteState>
type UseWebsiteLocation = GenericUseLocation<WebsiteRoutes, WebsiteState>

/***** EXPORTS *****/
// @ts-ignore
export const useShopLocation: UseShopLocation = useLocation
export const useShopNavigate: UseShopNavigate = useNavigate

// @ts-ignore
export const useWebsiteLocation: UseWebsiteLocation = useLocation
export const useWebsiteNavigate: UseWebsiteNavigate = useNavigate
