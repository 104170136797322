/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import BillingCycleForm from 'containers/services/form/billingCycle.form'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
import { useChangeBillingCycleMutation } from 'api/service'
import { ServiceMetaPayload } from 'api/service/types'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { Styles as S } from './billingCycle.style'

interface Props {
    id: number
    status: boolean
    close: () => void
    meta: ServiceMetaPayload
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function BillingCycleLightbox({ id, status, close, meta = { page: '1', sort_by: null } }: Props) {
    const [, { isLoading }] = useChangeBillingCycleMutation({ fixedCacheKey: 'billing-cycle-change' })

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <S.Lightbox
            title={'Change Billing Cycle'}
            description={`You can change your billing cycle to make when you're invoiced more convenient.
                You will be invoiced on the new billing cycle at the end of your current prepaid period.`}
            content={<BillingCycleForm id={id} close={close} meta={meta} />}
            conditions={{ state: status, action: () => close() }}
            actions={[
                {
                    label: 'Back',
                    color: 'secondary',
                    type: 'button',
                    func: () => close()
                },
                {
                    label: 'Change',
                    color: 'primary',
                    type: 'submit',
                    loading: isLoading && 'Changing',
                    form: 'changeBillingCycleForm'
                }
            ]}
        />
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
