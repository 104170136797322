/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useField } from 'formik'
import React, { InputHTMLAttributes } from 'react'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBox } from 'components/checkbox'
import { FieldErrorMessage } from '../util/error/message'
import { FieldLabel } from '../util/label'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CheckboxFieldStyles as S } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TCheckboxFieldProps = React.FC<{
    name: string
    children?: React.ReactNode
    required?: boolean
    embedded?: boolean
    label?: string
    intrinsic?: InputHTMLAttributes<HTMLInputElement>
    boxPosition?: 'left' | 'right'
    disabled?: boolean
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const CheckboxField: TCheckboxFieldProps = ({ name, children, required, embedded, label, intrinsic, boxPosition, disabled }) => {
    /***** HOOKS *****/
    const [field] = useField(name)

    /***** RENDER HELPERS *****/
    const labelProps = { embedded, required, field: { id: intrinsic?.id, name }, label }

    /***** RENDER *****/
    return (
        <S.Wrapper>
            <FieldLabel {...labelProps} />
            <CheckBox disabled={disabled} boxPosition={boxPosition} checked={field.value} intrinsic={{ ...field, id: name, ...intrinsic }}>
                {children}
            </CheckBox>
            <FieldErrorMessage name={name} />
        </S.Wrapper>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
