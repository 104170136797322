/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useRef } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { RenderErrorIcon, RenderInputLabel } from '../util'
import { Input } from './input'

/**********************************************************************************************************
 *   ASSET IMPORTS
 **********************************************************************************************************/

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled from 'styled-components'
import * as Theme from '../../../theme/theme'

export const TextArea = {
    Field: styled.textarea`
        position: relative;
        cursor: auto;
        display: block;
        min-width: 0px;
        width: 100%;
        height: auto;
        padding: 8px 12px;
        background-color: ${(props) => props.theme[`background--000`]};
        outline: 0;
        font-size: 1.2rem;
        color: ${(props) => props.theme[`text--200`]};
        border-radius: 6px;
        white-space: pre-line !important;
        ${Theme.Functions.animation(`border`)};
        ${Theme.Functions.normalizeText()};
        ${Theme.Functions.setFont('Inter', 500)};

        &::placeholder {
            color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`text--100`], `0.35`)};
        }

        &:disabled {
            color: ${(props) => props.theme[`text--100`]};
            background-color: ${(props) => props.theme[`background--200`]};
        }
    `,
    Icon: styled.div`
        position: absolute;
        top: 4px;
        right: 4px;
        border-radius: 9999px;

        > div {
            border-radius: 9999px;
            padding: 0 !important;
        }
    `
}

/**********************************************************************************************************
 *   TEXTAREA FIELD
 **********************************************************************************************************/
export const TextAreaField = ({
    className,
    embedded,
    required,
    field,
    label,
    disabled,
    rows = 5,
    cols = 25,
    form: { touched, errors, isSubmitting }
}) => {
    const inputRef = useRef()

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Input.Wrapper className={className} embedded={embedded}>
            {RenderInputLabel(embedded, required, label, field)}
            <Input.Column focus={inputRef?.current === document.activeElement} error={touched[field.name] && errors[field.name]}>
                <TextArea.Field ref={inputRef} rows={rows} cols={cols} disabled={isSubmitting || disabled} {...field} />
                <TextArea.Icon>{RenderErrorIcon(field.name, touched, errors)}</TextArea.Icon>
            </Input.Column>
        </Input.Wrapper>
    )
}
