/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoot } from 'react-dom/client'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'

/**********************************************************************************************************
 *   BASE STYLES
 **********************************************************************************************************/
import 'react-phone-input-2/lib/high-res.css'

/**********************************************************************************************************
 *   APP IMPORT
 **********************************************************************************************************/
import { App } from 'App/app'
import { store } from 'store/store'
import reportWebVitals from './reportWebVitals'

if (process.env.REACT_APP_MOCK_APP === 'enable') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { worker } = require('mocks/server/dev-server')
    worker.start()

    if (window.Cypress) {
        window.appReady = true
    }
}

const rootElement = document.getElementById('app-root') as HTMLElement
const root = createRoot(rootElement)

root.render(
    <Provider store={store}>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_SITE_KEY as string}>
            <HelmetProvider>
                <App />
            </HelmetProvider>
        </GoogleReCaptchaProvider>
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
