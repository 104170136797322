/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ContactUsLightbox } from '../contactUsLightbox'
import { ContactDetail } from './detail'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/utilities/Flex'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Theme } from 'nxui/src'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { WebsiteStyles } from 'containers/website/style'

/**********************************************************************************************************
 *   STYLES START
 **********************************************************************************************************/
export const FooterStyles = {
    Banner: styled.footer`
        ${WebsiteStyles.Helpers.Banner}
        ${WebsiteStyles.Helpers.InlinePadding};

        padding-bottom: 0;
        background-color: ${({ theme }) => theme['black--100']};

        /* Inheritable */
        color: ${({ theme }) => theme['white--100']};

        svg {
            ${WebsiteStyles.Helpers.AlternativeAnchorColor}
        }

        a {
            color: white;
            text-decoration: underline;
        }
    `,

    ContactUsLightbox: styled(ContactUsLightbox)`
        margin-bottom: 72px;

        ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-sm'])} {
            margin-bottom: 40px;
        }
    `,

    CopyrightParagraph: styled.p`
        ${WebsiteStyles.Helpers.AlternativeAnchorColor}
    `,

    Lower: styled(Flex).attrs({ align: 'center', justify: 'center' })`
        height: 60px;

        ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-sm'])} {
            margin-bottom: -30px;
        }
    `,

    ContactGrid: styled.div`
        display: grid;
        justify-content: center;
        gap: 20px;
        padding-bottom: 50px;
        grid-template-columns: repeat(auto-fill, 250px);

        ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-sm'])} {
            grid-template-columns: repeat(auto-fill, 80%);
        }
    `,

    ContactDetailIcon: styled(ContactDetail.Icon)`
        align-self: flex-start;
    `,

    AddressText: styled.span`
        font-size: 14px;
        font-weight: 400;
    `,

    Separator: styled.div`
        width: 100vw;
        border-bottom: 1px solid ${({ theme }) => theme['border--100']};
        margin-left: calc(-1 * ${WebsiteStyles.Consts.inlinePadding});
    `
}
/**********************************************************************************************************
 *  STYLES END
 **********************************************************************************************************/
