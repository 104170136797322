/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useFormik } from 'formik'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useTLDList } from 'containers/shop/domain/register/helper'
import Yup from 'helpers/yup'
import { useShopNavigate } from 'router/helpers'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { WebsiteHomeStyles as S } from 'containers/website/home/style'
import { regex } from 'helpers/utils'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type THeroBannerSearchForm = React.FC<{
    children: React.ReactNode
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Form that is used in the HeroBannerSearch component internally, when no custom form provider is provided
 */
export const StandardSearchForm: THeroBannerSearchForm = ({ children }) => {
    /***** HOOKS *****/
    const navigate = useShopNavigate()
    const tlds = useTLDList()

    /***** FORM *****/
    const form = useFormik({
        initialValues: {
            search: ''
        },
        validationSchema: Yup.object().shape({
            search: Yup.string()
                .required('You must enter a domain name to search for.')
                .test('accepted-format', 'Please enter a valid domain name', (value) => {
                    if (!tlds) return true
                    if (!value.includes('.')) {
                        return regex.domain.test(value)
                    }

                    // check that it is not a subdomain
                    let withTLDRemoved = value
                    tlds?.forEach((tld) => {
                        withTLDRemoved = withTLDRemoved.replace(new RegExp(tld + '$'), '')
                    })

                    if (withTLDRemoved.includes('.')) {
                        return false
                    }

                    // check that it ends with an available TLD
                    for (const tld of tlds) {
                        if (value?.endsWith(tld)) {
                            return regex.domainWithExtension.test(value)
                        }
                    }

                    return false
                })
        }),
        validateOnChange: false,
        validateOnBlur: false,
        async onSubmit({ search }) {
            await new Promise((resolve) => setTimeout(resolve, 300))

            if (!tlds) return
            if (!search.includes('.')) {
                const [firstTLD] = tlds
                return navigate('/shop/purchase', {
                    state: {
                        search: `${search}${firstTLD}`
                    }
                })
            }

            navigate('/shop/purchase', {
                state: {
                    search
                }
            })
        }
    })

    /***** RENDER *****/
    return (
        <S.SearchFormikProvider value={form}>
            <form onSubmit={form.handleSubmit}>{children}</form>
        </S.SearchFormikProvider>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
