/**********************************************************************************************************
 *   API
 **********************************************************************************************************/
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { baseApi } from 'api/base'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
import { Country, SecurityQuestion, State } from 'models/account'
import { Settings, SignUpFields, SupportRequestPayload } from 'models/app'

/**********************************************************************************************************
 *   APP API
 **********************************************************************************************************/
export const appAPI = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getToken: builder.query<null, void>({
            query: () => 'client/csrf-token'
        }),
        initialiseApplication: builder.query<{ settings: Settings }, void>({
            async queryFn(_arg, _queryApi, _extraOptions, baseQuery) {
                await baseQuery('client/csrf-token')

                const fetchSettings = async () => {
                    return baseQuery('client/settings') as QueryReturnValue<
                        { data: Settings; meta: FetchBaseQueryMeta },
                        FetchBaseQueryError,
                        FetchBaseQueryMeta
                    >
                }

                const existingSettings = localStorage.getItem('mca_theme')
                const isEmptyLocalStorage = Object.keys(JSON.parse(existingSettings ?? '{}')).length === 0
                const settings = existingSettings && !isEmptyLocalStorage ? JSON.parse(existingSettings) : await fetchSettings()

                if (!existingSettings || isEmptyLocalStorage) localStorage.setItem('mca_theme', JSON.stringify(settings.data))
                if (settings.error) return { error: settings.error }

                return {
                    data: {
                        settings: existingSettings && !isEmptyLocalStorage ? settings.data : settings.data.data
                    }
                }
            },
            providesTags: ['InitialiseApplication']
        }),
        register: builder.mutation<void, SignUpFields>({
            query: (credentials) => ({
                url: 'client/register',
                method: 'POST',
                body: credentials
            })
        }),
        securityQuestions: builder.query<Array<SecurityQuestion>, void>({
            query: () => 'client/questions',
            transformResponse: (baseQueryReturnValue: { data: Array<SecurityQuestion> }) => baseQueryReturnValue.data
        }),
        countries: builder.query<Array<Country>, { type: 'all' | 'restricted' }>({
            query: (params) => ({
                url: 'client/countries',
                params
            }),
            transformResponse: (baseQueryReturnValue: { data: Array<Country> }) => baseQueryReturnValue.data
        }),
        countryStates: builder.query<Array<State>, string>({
            query: (code) => `client/countries/${code}/states`,
            transformResponse: (baseQueryReturnValue: { data: Array<State> }) => baseQueryReturnValue.data
        }),
        SupportRequest: builder.mutation<void, SupportRequestPayload>({
            query: ({ token, ...rest }) => ({
                url: 'client/support',
                method: 'POST',
                body: {
                    'g-recaptcha-response': token,
                    ...rest
                }
            })
        })
    })
})

export const {
    useGetTokenQuery,
    useInitialiseApplicationQuery,
    useRegisterMutation,
    useSecurityQuestionsQuery,
    useCountriesQuery,
    useCountryStatesQuery
} = appAPI
