/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import useResizeObserver from '@react-hook/resize-observer'
import React, { useRef, useState } from 'react'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { InnerDomainForwardingForm } from '../addDomainForwarder'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { forwardingStyles as S } from '../_forwarding.styles'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TDomainForwarderEditingRow = React.FC<object>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DomainForwarderEditingRow: TDomainForwarderEditingRow = () => {
    /***** STATE *****/
    const [height, setHeight] = useState<number | undefined>(172)
    const formRef = useRef<HTMLFormElement>(null)

    /***** HOOKS *****/
    useResizeObserver(formRef, ({ borderBoxSize }) => setHeight(borderBoxSize[0].blockSize))

    /***** RENDER *****/
    return (
        <S.Domain.EditingRowWrapper height={height}>
            <S.Domain.FreedomRow>
                <InnerDomainForwardingForm innerRef={formRef} />
            </S.Domain.FreedomRow>
        </S.Domain.EditingRowWrapper>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
