import { DNSRecord, type RecordType } from 'models/domain'
import { DNSRecordType } from 'models/enums'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
type filterFieldsProps = {
    rows: Partial<Record<keyof DNSRecord & 'actions', DNSRecord[keyof DNSRecord] | null>>
    record: DNSRecord
}

/**********************************************************************************************************
 *   HELPER FUNCTIONS
 **********************************************************************************************************/

/**
 * Replaces the provided domain name with the @ symbol
 *
 * @param record The record to replace the domain name with the @ symbol
 * @param domain The domain name to replace with the @ symbol
 */
export const replaceDomainWithAtSymbol = (record: DNSRecord, domain?: string) => {
    if (!domain) return record

    const regex = new RegExp(`^${domain}$`)
    if (regex.test(record.hostname)) {
        return {
            ...record,
            hostname: record.hostname.replace(domain, '@')
        }
    }

    return record
}

/**
 * Replaces any instance where the record ends in the provided domain name with nothing
 */
export const removeTrailingDomainFromRecord = (record: DNSRecord, domain?: string) => {
    if (!domain) return record

    const regex = new RegExp(`\\.${domain}$`)
    if (regex.test(record.hostname)) {
        return {
            ...record,
            hostname: record.hostname.replace(regex, '')
        }
    }

    return record
}

/**
 * Reappends the provided domain name to the end of the record
 */
export const reappendDomainToRecord = (record: DNSRecord, domain?: string) => {
    if (!domain) return record
    if ([domain, '@'].includes(record.hostname)) return record

    return {
        ...record,
        hostname: `${record.hostname}.${domain}`
    }
}

/**
 * Replaces the @ symbol with the provided domain name, this only applies if the entire hostname is the @ symbol.
 *
 * @param record The record to replace the @ symbol with the domain name
 * @param domain The domain name to replace the @ symbol with
 */
export const replaceAtSymbolWithDomain = (record: DNSRecord, domain?: string) => {
    if (!domain) return record

    const regex = /^@$/
    if (regex.test(record.hostname)) {
        return {
            ...record,
            hostname: record.hostname.replace('@', domain)
        }
    }

    return record
}

/**
 * Removes trailing dots from a string value.
 *
 * @param record The string to remove the trailing dot from.
 */
export const removeFloatingDotFromString = (record: string) => {
    if (!record) return record

    const trailing = /\.$/
    const preceding = /^\./
    return record.replace(trailing, '').replace(preceding, '')
}

export const removeFloatingWhitespaceFromString = (record: string) => {
    const preceding = /^\s+/
    const trailing = /\s+$/

    return record.replace(preceding, '').replace(trailing, '')
}

/**
 * Removes trailing dots from all string values in a DNSRecord object.
 * There shouldn't ever be a trailing dot on a DNSRecord object but this is a safety check in case one is added since there is no
 * sanitization on the backend for this.
 *
 * @param record The DNSRecord object to remove trailing dots from.
 */
export const removeFloatingDots = (record: DNSRecord) => {
    const trailing = /\.$/
    const preceding = /^\./

    const keys = Object.keys(record) as Array<keyof DNSRecord>

    return keys.reduce((acc, key) => {
        const value = record[key]
        if (typeof value === 'string') {
            return {
                ...acc,
                [key]: value.replace(trailing, '').replace(preceding, '')
            }
        }

        return {
            ...acc,
            [key]: record[key]
        }
    }, {} as DNSRecord)
}

/**
 * Checks if the record has a priority field
 *
 * @param record The record to check if it has a priority field
 * @returns True if the record has a priority field, false otherwise
 */
export const hasPriorityField = (record: DNSRecord) => {
    const filterRecords = [DNSRecordType.MX, DNSRecordType.SRV] as RecordType[]

    return filterRecords.includes(record.type)
}

/**
 * Filters out fields that are not needed for certain record types such as the priority field which is only available on MX and SRV records.
 * Since this value is still required if the column exists, it replaces the value with an empty fragment.
 *
 * @param row The row to filter out fields from.
 * @param record The raw record to filter out fields from.
 */
export const filterFields = (row: filterFieldsProps['rows'], record: filterFieldsProps['record']) => {
    /*** Priority ***/
    if (!hasPriorityField(record)) return { ...row, priority: <></> }

    return row
}
