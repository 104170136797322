/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit'
import { toast } from 'react-hot-toast'

/**********************************************************************************************************
 *   MIDDLEWARE
 **********************************************************************************************************/
export const alert: Middleware = () => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        const message = action.payload.data?.messages?.[0] || action.payload.data?.[0] || Object.values(action.payload)?.[1] || undefined

        if (message) {
            toast.custom(message, {
                duration: 8000
            })
        }
    }

    return next(action)
}
