/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { DateTime } from 'luxon'
import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   STATE IMPORTS
 **********************************************************************************************************/
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useGetSecurityQuery } from 'api/account'
import { useAcceptInvitationQuery, useRegisterNewUserMutation } from 'api/authentication'

/**********************************************************************************************************
 *   FORM IMPORTS
 **********************************************************************************************************/
import AuthSignUpForm from 'containers/authentication/forms/signup'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { AuthRegisterSuccess } from './auth.signup.success'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Anchor } from 'components/buttons'

/**********************************************************************************************************
*   CONSTS
**********************************************************************************************************/
import { SignUp } from 'containers/authentication/authentication.styles'
import { websiteRoutes } from 'router/consts'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function AuthSignUp() {
    /***** HOOKS *****/
    const navigate = useNavigate()
    const { token } = useParams()
    const gordon = useLocation().pathname?.includes('gordon')
    const {
        appSettings: {
            provider: { application_name, provider_name, business_number, url, logo_url },
            section: { website }
        }
    } = useAppSelector((state) => state.app)

    /***** QUERIES *****/
    useGetSecurityQuery()
    const { isError, isSuccess } = useAcceptInvitationQuery(token)
    const [, { isSuccess: isRegisterNewUserSuccess }] = useRegisterNewUserMutation({
        fixedCacheKey: 'register-new-user'
    })

    /***** EFFECTS *****/
    useEffect(() => {
        if (isError) {
            navigate('/')
        }
    }, [isError])

    /***** RENDER *****/
    if (isRegisterNewUserSuccess) return <AuthRegisterSuccess />

    if (isSuccess) {
        return (
            <SignUp.Registration.Base>
                <SignUp.Registration.Company>
                    <SignUp.Logo>
                        <img width={'300'} src={logo_url} alt={application_name} />
                    </SignUp.Logo>
                    <SignUp.Title>{application_name}</SignUp.Title>
                </SignUp.Registration.Company>
                <SignUp.Registration.Title>{gordon ? `Welcome. Welcome, to City 17` : `Welcome to ${provider_name}`}</SignUp.Registration.Title>
                <SignUp.Registration.Description>
                    {gordon
                        ? `
                         You have chosen, or been chosen, to relocate to one of our finest remaining urban centers.
                         I thought so much of City 17, that I elected to establish my administration, here, in the citadel, so thoughtfully provided by our benefactors.
                         I am proud to call City 17 my home.
                     `
                        : `Before you get started, we need to grab some details from you.`}
                </SignUp.Registration.Description>
                <SignUp.Registration.Form>
                    <AuthSignUpForm />
                </SignUp.Registration.Form>
                <SignUp.Footer>
                    <span>
                        &copy; {DateTime.local().year} - {provider_name} - ABN {business_number}
                    </span>
                    {website ? (
                        <Anchor.Link to={websiteRoutes.ROOT}>Website</Anchor.Link>
                    ) : (
                        <SignUp.Anchor href={url} target={'_blank'}>
                            Website
                        </SignUp.Anchor>
                    )}
                    <SignUp.Anchor href={url} target={'_blank'}>
                        Website
                    </SignUp.Anchor>
                </SignUp.Footer>
            </SignUp.Registration.Base>
        )
    }

    return null
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
