/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { useCancelServiceMutation } from 'api/service'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import CancelForm from 'containers/services/form/cancel.form'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { Styles as S } from './cancel.style'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
interface Props {
    status: boolean
    close: () => void
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function CancelLightbox({ status, close }: Props) {
    /***** QUERIES *****/
    const [, { isLoading }] = useCancelServiceMutation({ fixedCacheKey: 'cancel-service' })

    /***** RENDER *****/
    return (
        <S.Lightbox
            title={'Cancel Service'}
            description={`Once you cancel this service, all files, emails, databases and configurations will be deleted.
                To keep your data, make a full backup before cancelling your service.`}
            content={<CancelForm close={close} />}
            conditions={{ state: status, action: () => close() }}
            actions={[
                {
                    label: 'Back',
                    color: 'secondary',
                    type: 'button',
                    func: () => close()
                },
                {
                    label: 'Cancel Service',
                    color: 'error',
                    type: 'submit',
                    loading: isLoading && 'Cancelling',
                    form: 'cancelForm'
                }
            ]}
        />
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
