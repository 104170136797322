import { domainAPI } from 'api/domain'
import { Flex } from 'components/utilities/Flex'
import { useFormikContext } from 'formik'
import { Button, Lightbox } from 'nxui/src'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { forwardingStyles as S } from '../_forwarding.styles'

type TEmailForwarderActionRow = React.FC<{
    onSave: () => void
    onCancel: () => void
    onDelete: () => void
    recordId: number
}>

export const EmailForwarderActionRow: TEmailForwarderActionRow = ({ onSave, onCancel, recordId, onDelete }) => {
    /***** HOOKS *****/
    const { id } = useParams()
    const { isSubmitting } = useFormikContext()

    /***** STATE *****/
    const [isDeleting, setIsDeleting] = useState(false)

    /***** QUERIES *****/
    const [deleteEmailRedirect, { isLoading }] = domainAPI.endpoints.deleteEmailRedirect.useMutation({ fixedCacheKey: 'delete-email-redirect' })
    const [, { isLoading: isCreatingEmailRedirect }] = domainAPI.endpoints.createEmailRedirect.useMutation({ fixedCacheKey: 'create-email-redirect' })
    const { isFetching: isFetchingEmailRedirects } = domainAPI.endpoints.emailRedirects.useQueryState(Number(id))

    const isPerformingAction = isSubmitting || isLoading || isCreatingEmailRedirect || isFetchingEmailRedirects

    /***** RENDER *****/
    return (
        <>
            <S.ActionRow fullWidth fullHeight align='center' justify='space-between'>
                <Button disabled={isPerformingAction} type='button' color='error' onClick={() => setIsDeleting(true)}>
                    Delete
                </Button>
                <Flex gap={10}>
                    <Button disabled={isPerformingAction} color='secondary' onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button disabled={isPerformingAction} color='primary' onClick={onSave}>
                        Save
                    </Button>
                </Flex>
            </S.ActionRow>
            <Lightbox
                title='Current email forwarder will be deleted'
                type='confirm'
                icon='warning'
                description={
                    <S.ConfirmDelete>
                        <span>You are about to delete the following email forwarder, note that this action is irreversible.</span>
                        <S.DeleteLightboxActions>
                            <Button type='button' color='secondary' onClick={() => setIsDeleting(false)}>
                                No, keep my current email forwarder
                            </Button>
                            <Button
                                type='submit'
                                color='error'
                                disabled={isLoading}
                                loading={isLoading && 'Deleting Forwarder'}
                                onClick={async () => {
                                    await deleteEmailRedirect({ domainId: Number(id), recordId }).then(() => setIsDeleting(false))
                                    onDelete()
                                }}
                            >
                                Delete
                            </Button>
                        </S.DeleteLightboxActions>
                    </S.ConfirmDelete>
                }
                conditions={{
                    state: isDeleting && !isFetchingEmailRedirects,
                    action: () => setIsDeleting(false)
                }}
            />
        </>
    )
}
