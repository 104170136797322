/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/utilities/Flex'
import { Theme } from 'nxui/src'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
export const LightboxFooterStyles = {
    FlexWrapper: styled(Flex)`
        padding: 12px 16px;
        display: flex;
        flex-direction: column-reverse;
        background-color: ${({ theme }) => theme['background--100']};

        ${({ theme }) => Theme.Functions.mediaBreakUp(theme['breakpoints-sm'])} {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding-left: 16px;
            padding-right: 16px;
        }
    `
}
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/
