/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import {
    ClockIcon,
    ExclamationTriangleIcon,
    IdentificationIcon,
    PlusCircleIcon,
    RectangleStackIcon,
    ShoppingCartIcon
} from '@heroicons/react/24/solid'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import Contacts from 'containers/domains/contacts'
import Dns from 'containers/domains/dns'
import Information from 'containers/domains/information'
import RenewDomainLightbox from 'containers/domains/lightbox/renewDomain.lightbox'
import UpdateEligibilityLightbox from 'containers/domains/lightbox/updateEligibility.lightbox'
import UpdateEPPCodeLightbox from 'containers/domains/lightbox/updateEPPCode.lightbox'
import Overview from 'containers/domains/overview'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Anchor, Button, Empty, Loader, Status, Table, Tabs } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import {
    domainAPI,
    useDomainRenewalQuery,
    useEligibilityDetailsQuery,
    useEppCodeQuery,
    usePrefetch,
    useRenewDomainMutation,
    useUpdateEligibilityMutation
} from 'api/domain'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setAppActiveInvoice } from 'store/slices/appSlice'
import { setSupportOpen } from 'store/slices/supportSlice'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { scrollIntoViewIfNeeded } from 'helpers/utils'
import { useAppDispatch, useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Domain } from 'containers/domains/domains.styles'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
import { MetaPayload } from 'models/app'
import { Domain as DomainModel } from 'models/domain'

interface Props {
    meta?: MetaPayload
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function DomainComponent({ meta = { page: '1' } }: Props) {
    const dispatch = useAppDispatch()
    const {
        appSettings: { section: settingsSections }
    } = useAppSelector((state) => state.app)
    const domainContainerRef = useRef<null | HTMLElement>(null)
    const location = useLocation()
    const navigate = useNavigate()
    const { id } = useParams()
    const domainId = Number(id)
    const {
        domain,
        domains,
        isDomainActive,
        isDomainPending,
        isDomainExpired,
        isFetching: isDomainListFetching,
        isSuccess
    } = domainAPI.endpoints.domainList.useQueryState(meta, {
        selectFromResult: ({ data, ...rest }) => ({
            ...rest,
            domain: data?.data.find((domainItem) => domainItem.id === domainId),
            domains: data?.data ?? ([] as Array<DomainModel>),
            isDomainActive: data?.data.find((domainItem) => domainItem.id === domainId)?.status === 'active',
            isDomainPending: data?.data.find((domainItem) => domainItem.id === domainId)?.status === 'pending',
            isDomainExpired: data?.data.find((domainItem) => domainItem.id === domainId)?.status === 'expired'
        })
    })
    const { isFetching: isEPPCodeFetching, currentData: eppCode } = useEppCodeQuery(domainId, {
        skip: !domainId || !isDomainActive
    })
    const { currentData: eligibility, isFetching: isEligibilityDetailsFetching } = useEligibilityDetailsQuery(domainId, {
        skip: !domainId || (!isDomainActive && !isDomainPending)
    })
    const { isFetching: isDomainRenewalFetching, currentData: domainRenewal } = useDomainRenewalQuery(domainId, {
        skip: !domainId || (!isDomainActive && !isDomainExpired)
    })
    const [, { isSuccess: isRenewDomainSuccess, reset: resetRenewDomain }] = useRenewDomainMutation({ fixedCacheKey: 'renew-domain' })
    const [, { isLoading: isUpdateEligibilityLoading }] = useUpdateEligibilityMutation({ fixedCacheKey: 'update-eligibility' })
    const prefetchDomainContact = usePrefetch('domainContacts')
    const prefetchDnsRecord = usePrefetch('dnsRecords')
    const [openEligibilityLightbox, setOpenEligibilityLightbox] = useState(false)
    const [openUpdateEPPCodeLightbox, setOpenUpdateEPPCodeLightbox] = useState(false)
    const [openRenewDomainLightbox, setOpenRenewDomainLightbox] = useState(false)

    const domainTabs = useMemo(() => {
        return [
            {
                icon: PlusCircleIcon,
                label: 'General',
                render: <Information meta={meta} setOpenRenewDomainLightbox={setOpenRenewDomainLightbox} />,
                location: () => navigate(`/domains/${id}/general${location.search}`, { replace: true })
            },
            {
                icon: IdentificationIcon,
                label: 'Contacts',
                render: <Contacts meta={meta} />,
                location: () => navigate(`/domains/${id}/contacts${location.search}`, { replace: true })
            },
            {
                icon: RectangleStackIcon,
                label: 'DNS & Nameservers',
                render: <Dns meta={meta} />,
                location: () => navigate(`/domains/${id}/dns${location.search}`, { replace: true })
            }
        ]
    }, [domain, location])

    const filteredDomainTabsBasedOnSettings = domainTabs.filter(({ label }) => {
        if (label === 'General' && !settingsSections.domain.general) return false
        if (label === 'Contacts' && !settingsSections.domain.contacts) return false
        if (label === 'DNS & Nameservers' && !settingsSections.domain.dns) return false
        return true
    })

    function setInitialTab() {
        switch (location.pathname) {
            case `/domains/${id}/contacts`:
                return settingsSections.domain.contacts ? 'Contacts' : ''
            case `/domains/${id}/dns`:
                return settingsSections.domain.dns ? 'DNS & Nameservers' : ''
            case `/domains/${id}/general`:
                return settingsSections.domain.general ? 'General' : ''
            default:
                return ''
        }
    }

    function renderDomain() {
        if (isDomainListFetching || isEPPCodeFetching || isEligibilityDetailsFetching || isDomainRenewalFetching || isUpdateEligibilityLoading) {
            return (
                <AnimatePresence>
                    <motion.div
                        initial={{ opacity: 0, x: -15 }}
                        animate={{
                            opacity: 1,
                            x: 0,
                            transition: {
                                duration: 0.5,
                                ease: [0.83, 0, 0.17, 1]
                            }
                        }}
                        exit={{ opacity: 0, x: 15 }}
                    >
                        <Loader.Skeleton sections={['title', 'description', 'content', 'footer']} />
                    </motion.div>
                </AnimatePresence>
            )
        }

        if (isSuccess && domains.length === 0) {
            return (
                <Domain.Empty>
                    <Empty
                        icon={<InformationCircleIcon width={'48px'} />}
                        title={'No domains'}
                        description={'Get started by adding domain to shopping cart.'}
                        action={{
                            label: 'Register New Domain',
                            color: 'primary',
                            icon: <ShoppingCartIcon />,
                            func: () => navigate('/shop')
                        }}
                    />
                </Domain.Empty>
            )
        }

        return (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                {settingsSections.domain.admin ? <Overview meta={meta} /> : ''}
                {domain && domain.status === 'active' && filteredDomainTabsBasedOnSettings.length > 0 && (
                    <Domain.Tabs>
                        <Tabs.Prefab initial={setInitialTab()} tabs={filteredDomainTabsBasedOnSettings} />
                    </Domain.Tabs>
                )}
                {domain?.status === 'pending' && domain?.meta.domain_status === 'pending_registration_details' && (
                    <Domain.Card>
                        <Domain.Notification>A valid eligibility requirements must be provided to activate this domain name.</Domain.Notification>
                        <Domain.Content>
                            <h2>Eligibility Requirements</h2>
                            <p>
                                The provided domain registration eligibility information is invalid. Please update this information in order to
                                continue with the registration of this domain name.
                            </p>
                        </Domain.Content>
                        <Table
                            conditions={{
                                header: false,
                                search: false,
                                pagination: false,
                                sort: false
                            }}
                            render={{
                                columns: () => [
                                    {
                                        Header: 'Eligibility Information',
                                        accessor: 'information'
                                    },
                                    {
                                        accessor: 'value'
                                    },
                                    {
                                        Header: '',
                                        accessor: 'action',
                                        align: 'right'
                                    }
                                ],
                                data: () =>
                                    eligibility?.map(({ field_name, field_value }, index) => {
                                        if (eligibility.length - 1 === index) {
                                            return {
                                                information: field_name,
                                                value: field_value,
                                                action: (
                                                    <Anchor color={'primary'} onClick={() => setOpenEligibilityLightbox(true)}>
                                                        Update
                                                    </Anchor>
                                                )
                                            }
                                        }

                                        return {
                                            information: field_name,
                                            value: field_value
                                        }
                                    }) ?? []
                            }}
                        />
                    </Domain.Card>
                )}
                {domain?.status === 'pending' && domain?.meta.domain_status === 'pending_epp_code' && (
                    <Domain.Card>
                        <Domain.Notification>Please provide the valid domain password to complete the domain transfer.</Domain.Notification>
                        <Domain.Content>
                            <h2>EPP Code</h2>
                            <p>The supplied domain transfer password is incorrect. Please re-enter this transfer password.</p>
                        </Domain.Content>
                        <Table
                            conditions={{
                                header: false,
                                search: false,
                                pagination: false,
                                sort: false
                            }}
                            render={{
                                columns: () => [
                                    {
                                        Header: 'EPP Code',
                                        accessor: 'information'
                                    },
                                    {
                                        Header: 'Value',
                                        accessor: 'value'
                                    },
                                    {
                                        Header: '',
                                        accessor: 'action',
                                        align: 'right'
                                    }
                                ],
                                data: () => [
                                    {
                                        information: 'EPP Code',
                                        value: eppCode ?? '',
                                        action: (
                                            <Anchor color={'primary'} onClick={() => setOpenUpdateEPPCodeLightbox(true)}>
                                                Re-enter Password
                                            </Anchor>
                                        )
                                    }
                                ]
                            }}
                        />
                    </Domain.Card>
                )}
                {domain?.status === 'pending' && !['pending_epp_code', 'pending_registration_details'].includes(domain.meta.domain_status) && (
                    <Domain.Pending
                        footer={
                            <Domain.Footer>
                                <Anchor color='primary' onClick={() => dispatch(setSupportOpen(true))}>
                                    Contact Support
                                </Anchor>
                            </Domain.Footer>
                        }
                    >
                        <main>
                            <Domain.Icon status={domain.status}>
                                <ClockIcon width='28px' aria-hidden='true' />
                            </Domain.Icon>
                            <h2>Domain Pending</h2>
                            <p>Your domain is currently pending processing. If this doesn't seem correct, please contact support.</p>
                        </main>
                    </Domain.Pending>
                )}
                {domain?.status === 'expired' && !domainRenewal?.invoice && (
                    <Domain.Pending>
                        <main>
                            <Domain.Icon status={domain.status}>
                                <ExclamationTriangleIcon width='28px' aria-hidden='true' />
                            </Domain.Icon>
                            <h2>Domain Expired</h2>
                            <p>This domain is currently expired, please renew to reactivate the domain.</p>
                            <Button type='button' color='primary' onClick={() => setOpenRenewDomainLightbox(true)}>
                                Renew Domain
                            </Button>
                        </main>
                    </Domain.Pending>
                )}
                {domain?.status === 'expired' && domainRenewal?.invoice && (
                    <Domain.Card>
                        <Domain.Notification>You have an outstanding invoice, make a payment now to renew this domain.</Domain.Notification>
                        <Table
                            conditions={{
                                header: true,
                                search: false,
                                pagination: false,
                                sort: false
                            }}
                            render={{
                                columns: () => [
                                    {
                                        Header: 'General Information',
                                        accessor: 'information'
                                    },
                                    {
                                        Header: '',
                                        accessor: 'value'
                                    }
                                ],
                                data: () => [
                                    {
                                        information: 'Renew Domain Name',
                                        value:
                                            domainRenewal && domainRenewal?.errors.length >= 1 ? (
                                                <Domain.Renewal>
                                                    {domainRenewal.errors[0]}{' '}
                                                    <Anchor
                                                        color={'primary'}
                                                        onClick={() =>
                                                            dispatch(setAppActiveInvoice({ invoice: domainRenewal.invoice, status: true }))
                                                        }
                                                    >
                                                        {domainRenewal?.invoice?.id ? `#${domainRenewal?.invoice?.id}` : null}
                                                    </Anchor>
                                                </Domain.Renewal>
                                            ) : null
                                    }
                                ]
                            }}
                        />
                    </Domain.Card>
                )}
            </motion.div>
        )
    }

    useEffect(() => {
        if (id) scrollIntoViewIfNeeded(domainContainerRef)
    }, [id])

    useEffect(() => {
        if (isRenewDomainSuccess) {
            close()

            return () => {
                resetRenewDomain()
            }
        }
    }, [isRenewDomainSuccess])

    useEffect(() => {
        if (domainId) {
            prefetchDomainContact(domainId)
            prefetchDnsRecord(domainId)
        }
    }, [domainId])

    /*   RENDER COMPONENT
     *****************************************************/
    if (!id) return null

    if (!domain && isSuccess) {
        return (
            <Domain.Empty>
                <Status status={404} back={() => navigate('/domains/?page=1')} />
            </Domain.Empty>
        )
    }

    return (
        <Domain.Base ref={domainContainerRef}>
            <AnimatePresence>{renderDomain()}</AnimatePresence>
            <UpdateEligibilityLightbox status={openEligibilityLightbox} close={() => setOpenEligibilityLightbox(false)} />
            <UpdateEPPCodeLightbox id={domainId} status={openUpdateEPPCodeLightbox} close={() => setOpenUpdateEPPCodeLightbox(false)} />
            <RenewDomainLightbox id={domainId} status={openRenewDomainLightbox} close={() => setOpenRenewDomainLightbox(false)} />
        </Domain.Base>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { DomainComponent as Domain }
