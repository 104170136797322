/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createPortal } from 'react-dom'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { usePortal } from '../../../helpers/hooks/usePortal'
import { CartHeader } from './cart.header'
import { CartSummary } from './cart.summary'
import { CartFooter } from './cart.footer'
import { CartList } from './cart.list'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import { Cart } from './cart.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const CartDock = (props) => {
    const target = usePortal('cartDock')

    /*   RENDER
     ****************************************************/
    return createPortal(
        <Cart.Wrapper className={props.className} open={props.open}>
            <Cart.Background onClick={props.close} open={props.open} />
            <Cart.Base>
                <CartHeader close={props.close} />
                <CartList products={props.products} />
                <CartSummary products={props.products} />
                <CartFooter close={props.close} checkout={props.checkout} products={props.products} />
            </Cart.Base>
        </Cart.Wrapper>,
        target
    )
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
CartDock.defaultProps = {
    className: undefined,
    open: false
}
