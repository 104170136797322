/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useEffect, useState } from 'react'

/**********************************************************************************************************
 *   SHARED COMPONENTS
 **********************************************************************************************************/
import { Loader } from 'components/loader'
import { LFB } from 'nxui/src'

/**********************************************************************************************************
 *   HOOKS
 **********************************************************************************************************/
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { Timer } from 'helpers/utils'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useGetAuthorisedAccountsQuery, useLogoutMutation, useSelectAccountMutation } from 'api/authentication'

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
import { Auth, SelectAccount } from 'containers/authentication/authentication.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function AuthAccountForm() {
    const [selectedUser, setSelectedUser] = useState<number | null>(null)
    const {
        appAuthentication: { authorisedAccounts }
    } = useAppSelector((state) => state.app)
    const { isLoading: accountsListLoading, isError: accountsListError } = useGetAuthorisedAccountsQuery(undefined, {
        skip: !!authorisedAccounts
    })
    const [selectAccount, { isLoading: isSelectUserLoading }] = useSelectAccountMutation()
    const [logout] = useLogoutMutation()

    useEffect(() => {
        if (accountsListError) {
            Timer(() => logout(), 4000)
        }
    }, [accountsListError])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Auth.Wrapper>
            <Auth.Title>Select an Account</Auth.Title>
            <Auth.Description>Select one of the following user accounts to continue.</Auth.Description>
            <Auth.Break />
            <Auth.Row>
                <SelectAccount.Container>
                    {accountsListLoading ? (
                        <Loader message={'Fetching accounts list...'} width={38} height={38} />
                    ) : accountsListError ? (
                        <Loader message={'Fail to fetch account list. Logging out...'} width={38} height={38} />
                    ) : (
                        authorisedAccounts?.map(({ role: { name }, account: { id, account_holder, company_name } }) => (
                            <LFB
                                key={id}
                                loading={isSelectUserLoading && selectedUser === id}
                                disabled={isSelectUserLoading && selectedUser !== id}
                                label={account_holder}
                                description={company_name ? `${name} - ${company_name}` : name}
                                onClick={() => {
                                    setSelectedUser(id)
                                    selectAccount(id)
                                }}
                            />
                        ))
                    )}
                </SelectAccount.Container>
            </Auth.Row>
            <Auth.Break />
            <Auth.Border />
            <Auth.Footer>
                <Auth.Back color={'select'} onClick={() => logout()}>
                    Back to Login
                </Auth.Back>
            </Auth.Footer>
        </Auth.Wrapper>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
