/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect'

/**********************************************************************************************************
 *   HOOK
 **********************************************************************************************************/
const events = ['mousedown', 'touchstart']
const currentDocument = typeof document !== 'undefined' ? document : undefined

export function useOnClickOutside(ref, handler, { document = currentDocument } = {}) {
    if (typeof document === 'undefined') {
        return
    }

    useIsomorphicLayoutEffect(() => {
        if (!handler) {
            return
        }

        const listener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return
            }

            handler(event)
        }

        events.forEach((event) => {
            document.addEventListener(event, listener)
        })

        return () => {
            events.forEach((event) => {
                document.removeEventListener(event, listener)
            })
        }
    }, [handler])
}
