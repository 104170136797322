import { useDefaultProductGroup } from 'containers/shop/hooks/useDefaultProductGroup'
import { ParamEncoder } from 'helpers/functions/paramEncoder'
import { useAppParams } from 'helpers/hooks/useAppParams'
import { useNavigate } from 'react-router-dom'
import { type ParseableShopRoutes, type ShopRouteParams } from 'router/helpers'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
// prettier-ignore
type TGroupIdRoute<TRoutes = ParseableShopRoutes> = 
    TRoutes extends `/shop/:groupId`
        ? TRoutes : 
    TRoutes extends `/shop/:groupId/${string}`
        ? TRoutes : 
    never

// prettier-ignore
type Params<TRoute extends TGroupIdRoute> = { groupId?: string } & Omit<
    Record<ShopRouteParams<TRoute>, string | number>,
    'groupId'
>

type GroupNavigate = <TRoute extends TGroupIdRoute>(route: TRoute, params?: Params<TRoute>) => void
type Reducer = (acc: string, entry: [string, string | undefined]) => string

/**
 * Allows navigation to a shop group sub-route where the groupId can optionally be provided. If it is not provided
 * then a default group will be used based on the shops configuration.
 */
export const useShopGroupIdNavigate = (): GroupNavigate => {
    /***** HOOKS *****/
    const navigate = useNavigate()
    const { groupId } = useAppParams()
    const { defaultProductGroup } = useDefaultProductGroup()

    /***** HOOK RESULTS *****/
    return (route, params) => {
        const derivedParams = { groupId, ...params }
        const reducer: Reducer = (acc, [paramName, paramValue]) => {
            if (paramValue) {
                acc = acc.replace(`:${paramName}`, paramValue)
            }
            return acc
        }

        const builtRoute = Object.entries(derivedParams).reduce<string>(reducer, route)

        // prettier-ignore
        // If we provided a groupId or we are already on a route that has a groupId, use that, otherwise use the default group
        return !derivedParams.groupId 
            ? navigate(builtRoute.replace(':groupId', ParamEncoder.encode(defaultProductGroup.name))) 
            : navigate(builtRoute)
    }
}
