/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useState } from 'react'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { WebsiteHero } from 'containers/website/components/Hero'
import { WebsiteDomainFeatures } from 'containers/website/components/features'
import { Footer } from 'containers/website/components/footer'
import { WebsiteDomainPricingTable } from './table'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { WebsitePricingStyling as S } from './styles'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type FixedTabs = React.FC<{
    tabs: Array<{
        label: string
        render: React.ReactNode
    }>
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const FixedTabs: FixedTabs = ({ tabs }) => {
    /***** STATE *****/
    const [selectedIndex, setSelectedIndex] = useState(0)

    /***** RENDER HELPERS *****/
    const mappedTabs = tabs.map((tab, index) => ({
        ...tab,
        location: () => setSelectedIndex(index)
    }))

    /***** RENDER *****/
    return <S.Tabs selected={selectedIndex} tabs={mappedTabs} />
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const WebsiteDomainPricing = () => {
    /***** RENDER *****/
    return (
        <>
            <WebsiteHero large title='Domain Name Registration'>
                <S.WebsiteHero.Search />
                <S.Card>
                    <FixedTabs
                        tabs={[
                            {
                                label: 'New Registration',
                                render: <WebsiteDomainPricingTable filter='register' />
                            },
                            { label: 'Renewals', render: <WebsiteDomainPricingTable filter='renew' /> },
                            { label: 'Transfers', render: <WebsiteDomainPricingTable filter='transfer' /> }
                        ]}
                    />
                </S.Card>
            </WebsiteHero>
            <WebsiteDomainFeatures />
            <Footer />
        </>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
