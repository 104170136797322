/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Theme } from 'nxui/src'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
export const LightboxBodyStyle = {
    Wrapper: styled.div`
        display: inline-block;
        width: 100%;
        padding: 20px 16px 16px;
        max-height: 40vh;
        text-align: left;
        vertical-align: middle;
        overflow: auto;
        background-color: ${({ theme }) => theme['background--000']};

        ${Theme.Functions.animation('all')};
        ${({ theme }) => Theme.Functions.mediaBreakUp(theme['breakpoints-sm'])} {
            max-height: 50vh;
            padding: 24px;
        }
    `,

    /**
     * Internal Wrapper to ensure that content cannot horizontally overflow
     */
    InnerWrapper: styled.div`
        overflow: hidden;
        max-width: 100%;
    `
}
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/
