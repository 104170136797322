/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'
import { CombinedTButtonProps, TAnchorButtonProps, TLinkButtonProps, TOnClickButtonProps, TSubmitButtonProps, _Button } from '../base'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Theme } from 'nxui/src'
import { ButtonStyles } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
// For the button variation (non-anchor) allow size property
type TButtonProps = CombinedTButtonProps & { size?: 'small' | 'medium' | 'large' }

// Helper to easily define a button
type Button<T extends { type: string }> = React.FC<Omit<T, 'type'> & { size?: 'small' | 'medium' | 'large' }>

// Define the button variants (without type as the component defines this)
type StandardButton = Button<TOnClickButtonProps>
type SubmitButton = Button<TSubmitButtonProps>
type AnchorButton = Button<TAnchorButtonProps>
type LinkButton = Button<TLinkButtonProps>

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
const StyledButton = styled(_Button)<TButtonProps>`
    display: inline-flex;
    align-items: center;
    width: fit-content;
    white-space: nowrap;
    border-radius: 8px;
    background-size: cover;
    font-size: 1.4rem;
    text-decoration: none;
    letter-spacing: -0.25px;
    line-height: 1;

    ${Theme.Functions.setFont('Inter', 500)};
    ${Theme.Functions.animation('all')};
    ${({ fullwidth }) => fullwidth && 'width: 100%;'}

    .icon {
        width: 16px;
        height: 16px;
        margin: 0 6px 0 -4px;
        ${Theme.Functions.animation('all')};
    }

    ${ButtonStyles.Color}
    ${ButtonStyles.Size}
`

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _StandardButton: StandardButton = (props) => {
    return (
        <StyledButton type='onclick' {...(props as TButtonProps)}>
            {props.children}
        </StyledButton>
    )
}

export const _SubmitButton: SubmitButton = (props) => {
    return (
        <StyledButton type='submit' {...(props as TButtonProps)}>
            {props.children}
        </StyledButton>
    )
}

export const _AnchorButton: AnchorButton = (props) => {
    return (
        <StyledButton type='anchor' {...(props as TButtonProps)}>
            {props.children}
        </StyledButton>
    )
}

export const _LinkButton: LinkButton = (props) => {
    return (
        <StyledButton type='link' {...(props as TButtonProps)}>
            {props.children}
        </StyledButton>
    )
}
