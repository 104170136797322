/* eslint-disable indent */
/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import PropTypes from 'prop-types'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled, { css } from 'styled-components'
import * as Theme from '../../theme/theme'

const Select = {
    Row: styled.div`
        position: relative;
        display: inline-flex;
        flex-flow: row wrap;
        gap: 10px;
    `,
    Option: styled.button`
        display: flex;
        width: fit-content;
        padding: 8px 15px;
        line-height: 1.5;
        border-radius: 24px;
        ${Theme.Functions.normalizeText()};
        ${Theme.Functions.caption()};
        ${Theme.Functions.animation(`all`)};

        ${(props) =>
            props.variant === 'solid' &&
            css`
                color: ${(props) => props.theme[`text--100`]};
                background-color: ${(props) => props.theme[`background--200`]};

                &:hover {
                    color: ${(props) => props.theme[`info--100`]};
                }
            `}

        ${(props) =>
            props.variant === 'outline' &&
            css`
                color: ${(props) => props.theme[`text--100`]};
                border: 1px solid ${(props) => props.theme[`border--100`]};

                &:hover {
                    color: ${(props) => props.theme[`info--100`]};
                    border: 1px solid ${(props) => props.theme[`info--100`]};
                }
            `}

        span:last-of-type {
            white-space: nowrap;
        }

        svg {
            margin: 0 8px 0 -4px;
            width: 16px;
            height: 16px;
        }

        div {
            display: inline-flex;
            flex-flow: row nowrap;
            align-items: center;
            margin: 0 auto;
        }

        ${(props) =>
            props.active &&
            props.variant === 'solid' &&
            css`
                color: ${(props) => props.theme[`white--100`]};
                background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`info--100`], 1)};

                &:hover {
                    color: ${(props) => props.theme[`white--100`]};
                }
            `}

        ${(props) =>
            props.active &&
            props.variant === 'outline' &&
            css`
                border: 1px solid ${(props) => Theme.Functions.hexToRGBA(props.theme[`info--100`], 1)};
                color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`info--100`], 1)};
            `}
    `
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const MultiSelect = ({ className, variant = 'solid', options }) => {
    return (
        <Select.Row className={className}>
            {options.map(({ label, onClick, type, active, ...rest }, index) => {
                return (
                    <Select.Option key={index} type={type} onClick={onClick} active={active} variant={variant} {...rest}>
                        {label}
                    </Select.Option>
                )
            })}
        </Select.Row>
    )
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
MultiSelect.prototype = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(['solid', 'outline']),
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.shape({
                type: PropTypes.oneOf[('button', 'submit', 'reset')],
                className: PropTypes.string,
                label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
                onClick: PropTypes.func.isRequired,
                active: PropTypes.bool
            })
        ])
    )
}
