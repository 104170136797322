/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { toast } from 'react-hot-toast'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { Anchor, Badge } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { serviceAPI } from 'api/service'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Overview } from 'containers/services/services.styles'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import globalCookies from 'helpers/cookies'
import { getServiceStatusColour, parseISOToLocalString, parseSQL, titleCase } from 'helpers/utils'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
import { ServiceMetaPayload } from 'api/service/types'
import { ServiceStatus } from 'models/enums'
import { useAppSelector } from 'store/hooks'

interface Props {
    meta: ServiceMetaPayload
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function OverviewComponent({ meta = { page: '1', sort_by: null } }: Props) {
    const { id } = useParams()
    const serviceId = Number(id)
    const { service } = serviceAPI.endpoints.services.useQueryState(meta, {
        selectFromResult: ({ data }) => ({
            service: data?.data.find((serviceItem) => serviceItem.id === serviceId)
        })
    })
    const { data: moduleMeta } = serviceAPI.endpoints.moduleMeta.useQueryState(serviceId)
    const loginURL = `${process.env.REACT_APP_API}client/services/${serviceId}/login`
    const token = globalCookies.get('XSRF-TOKEN')
    const isServiceLoginEnabled = useAppSelector((state) => state.app.appSettings.section.service.login)

    async function fetchURLAndOpenLink() {
        const response = await fetch(loginURL, { method: 'GET', headers: { 'X-XSRF-TOKEN': token }, credentials: 'include' })
        const { data, messages } = await response.json()

        if (data.length <= 1 && messages.length >= 1) {
            toast.custom(messages[0], {
                duration: 8000
            })
        } else {
            const { url } = data
            window.open(url)
        }
    }

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Overview.Base
            header={{
                element: (
                    <Overview.Header>
                        <Overview.Service>
                            <Overview.Name>{service?.name}</Overview.Name>
                            <Overview.Product>{service?.product.name}</Overview.Product>
                        </Overview.Service>
                        {service ? <Badge color={getServiceStatusColour(service?.status)}>{service?.status.toUpperCase()}</Badge> : null}
                    </Overview.Header>
                )
            }}
            footer={
                service?.status === ServiceStatus.Active && service.product.module_id && isServiceLoginEnabled ? (
                    <Anchor color={'primary'} onClick={fetchURLAndOpenLink}>
                        {moduleMeta?.login_label}
                    </Anchor>
                ) : null
            }
        >
            <Overview.Info>
                <Overview.Column>
                    <Overview.Row>
                        <span>{`Primary ${titleCase(service?.product.name_type || 'name')}`}</span>
                        <span>{service?.name}</span>
                    </Overview.Row>
                </Overview.Column>
                <Overview.Column>
                    <Overview.Row>
                        <span>Plan</span>
                        <span>{service?.product.name}</span>
                    </Overview.Row>
                </Overview.Column>
                <Overview.Column>
                    <Overview.Row>
                        <span>Activated At</span>
                        <span>{service?.created_at ? parseISOToLocalString(service.created_at) : null}</span>
                    </Overview.Row>
                </Overview.Column>
                <Overview.Column>
                    <Overview.Row>
                        <span>Next Due Date</span>
                        <span>{service?.next_due_date ? parseSQL(service?.next_due_date).setLocale('en-AU').toLocaleString() : null}</span>
                    </Overview.Row>
                </Overview.Column>
                <Overview.Column>
                    <Overview.Row>
                        <span>Billing Cycle</span>
                        <span>{service?.billing_cycle?.name}</span>
                    </Overview.Row>
                </Overview.Column>
            </Overview.Info>
        </Overview.Base>
    )
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { OverviewComponent as Overview }
