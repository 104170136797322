/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Formik } from 'formik'
import * as Yup from 'yup'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { usePayInvoiceMutation } from 'api/billing'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { useApplyAccountCreditToInvoice } from 'containers/billing/utils'
import { useLocalStorage } from 'helpers/hooks'
import { useAppSelector } from 'store/hooks'
import { renderGenericPaymentMethodDescription } from './invoice'

/**********************************************************************************************************
 *   STYLE
 **********************************************************************************************************/
import { Payment } from 'components/invoice/invoice.styles'

/**********************************************************************************************************
 *   INTERFACE & TYPES
 **********************************************************************************************************/
import { PaymentMethod } from 'models/billing'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
interface LinkTypeFormProps {
    accountCreditToApply: number
    selectedPaymentMethod?: PaymentMethod
    setTemplate: (template: 'invoice' | 'success' | 'redirect') => void
}

export default function LinkTypeForm({ accountCreditToApply, selectedPaymentMethod, setTemplate }: LinkTypeFormProps) {
    const { appActiveInvoice } = useAppSelector((state) => state.app)
    const [payInvoice] = usePayInvoiceMutation({ fixedCacheKey: 'pay-invoice' })
    const { applyAccountCreditToInvoice } = useApplyAccountCreditToInvoice()
    const [, setRedirect] = useLocalStorage<{ isRedirect: boolean; invoice?: number; order?: number }>('redirect', {
        isRedirect: false,
        invoice: undefined,
        order: undefined
    })

    return (
        <Formik
            initialValues={{
                save_details: false
            }}
            validationSchema={Yup.object({
                save_details: Yup.boolean()
            })}
            onSubmit={async ({ save_details }) => {
                setTemplate('redirect')
                if (!appActiveInvoice?.invoice?.id) return

                const { isInvoiceStillUnpaid, isSuccess } = await applyAccountCreditToInvoice(appActiveInvoice.invoice.id, accountCreditToApply)

                let link: string | undefined = undefined

                if (isSuccess && isInvoiceStillUnpaid) {
                    const invoice = await payInvoice({
                        id: String(appActiveInvoice?.invoice?.id),
                        payment_method_id: Number(selectedPaymentMethod?.id),
                        payment_data: { save_details }
                    })
                        .unwrap()
                        .catch(() => {
                            setTemplate('invoice')
                        })

                    link = invoice?.link
                }

                if (link) {
                    setRedirect({ isRedirect: true, invoice: appActiveInvoice?.invoice?.id, order: undefined })
                    window.location.href = link
                }
            }}
        >
            {({ isSubmitting }) => (
                <Payment.Form id='linkTypeForm'>
                    {renderGenericPaymentMethodDescription(selectedPaymentMethod?.name || '', selectedPaymentMethod?.instructions || '')}
                    {selectedPaymentMethod?.module_meta?.is_persistable ? (
                        <Payment.LinkType.SaveDetailsContainer>
                            <Field
                                name={'save_details'}
                                type={'checkbox'}
                                disabled={isSubmitting}
                                description={'Save Details'}
                                component={NXUIForm.CheckboxField}
                            />
                        </Payment.LinkType.SaveDetailsContainer>
                    ) : (
                        ''
                    )}
                </Payment.Form>
            )}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
