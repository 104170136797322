/* eslint-disable indent */
/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { RenderErrorMessages, Style } from '../util'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled, { css } from 'styled-components'
import * as Theme from '../../../theme/theme'

export const Checkbox = {
    Base: styled.div`
        ${Style.Wrapper};
    `,
    Column: styled.div`
        ${Style.Column};
        border: none;
        flex-direction: column;
    `,
    Title: styled.label`
        display: flex;
        margin-bottom: 4px;
        font-size: 1.2rem;
        color: ${(props) => props.theme[`text--200`]};
        ${Theme.Functions.normalizeText()};
        ${Theme.Functions.setFont('Inter', 700)};
    `,
    Label: styled.label`
        ${Style.Toggle.Label};
        width: 100%;
        font-size: 1.2rem;
        ${Theme.Functions.setFont('Inter', 500)};
    `,
    Text: styled.div`
        width: 100%;
        word-break: break-word;
        user-select: none;
    `,
    Input: styled.input`
        position: relative;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        background-origin: border-box;
        user-select: none;
        flex-shrink: 0;
        appearance: none;
        height: 16px;
        width: 16px;
        padding: 0;
        margin-left: 20px;
        text-align: start;
        overflow: hidden;
        border-radius: 4px;
        ${Theme.Functions.animation(`all`)};

        &:focus {
            outline: none;
        }

        ${({ disabled, loading }) => {
            if (disabled || loading) {
                return css`
                    cursor: default;
                `
            }
        }}

        ${({ color }) => {
            switch (color) {
                case 'error':
                    return css`
                        border: 1px solid ${(props) => props.theme[`border--100`]};
                        background-color: ${(props) => props.theme[`white--100`]};

                        &:hover {
                            background-color: ${(props) => props.theme[`background--200`]};
                            background-image: url(https://cdn.nexigen.digital/nexigen/nxui/icons/solid/check.svg);
                            background-repeat: no-repeat;
                            background-size: 85% 85%;
                            background-position: center;
                        }

                        &:checked {
                            border-color: ${(props) => props.theme[`error--100`]};
                            background-color: ${(props) => props.theme[`error--100`]};
                            background-image: url(https://cdn.nexigen.digital/nexigen/nxui/icons/solid/check.svg);
                            background-repeat: no-repeat;
                            background-size: 85% 85%;
                            background-position: center;
                        }

                        &:checked:hover {
                            border-color: ${(props) => props.theme[`error--100`]};
                            background-color: ${(props) => props.theme[`error--100`]};
                        }

                        &:checked:focus {
                            outline: none;
                            border-color: ${(props) => props.theme[`error--100`]};
                            background-color: ${(props) => props.theme[`error--100`]};
                        }
                    `

                case 'primary':
                default:
                    return css`
                        border: 1px solid ${(props) => props.theme[`border--100`]};
                        background-color: ${(props) => props.theme[`white--100`]};

                        &:hover {
                            background-color: ${(props) => props.theme[`background--200`]};
                            background-image: url(https://cdn.nexigen.digital/nexigen/nxui/icons/solid/check.svg);
                            background-repeat: no-repeat;
                            background-size: 85% 85%;
                            background-position: center;
                        }

                        &:checked {
                            border-color: ${(props) => props.theme[`primary--100`]};
                            background-color: ${(props) => props.theme[`primary--100`]};
                            background-image: url(https://cdn.nexigen.digital/nexigen/nxui/icons/solid/check.svg);
                            background-repeat: no-repeat;
                            background-size: 85% 85%;
                            background-position: center;
                        }

                        &:checked:hover {
                            border-color: ${(props) => props.theme[`primary--100`]};
                            background-color: ${(props) => props.theme[`primary--100`]};
                        }

                        &:checked:focus {
                            outline: none;
                            border-color: ${(props) => props.theme[`primary--100`]};
                            background-color: ${(props) => props.theme[`primary--100`]};
                        }
                    `
            }
        }}
    `
}

/**********************************************************************************************************
 *   CHECKBOX FIELD
 **********************************************************************************************************/
export const CheckboxField = ({ field, label, description, className, color, disabled, form: { touched, errors, isSubmitting }, ...props }) => {
    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Checkbox.Base>
            <Checkbox.Column>
                {label && <Checkbox.Title>{label}</Checkbox.Title>}
                <Checkbox.Label disabled={disabled} color={color} checked={field.checked} className={isSubmitting || disabled ? 'disabled' : ''}>
                    <Checkbox.Text>{description}</Checkbox.Text>
                    <Checkbox.Input
                        {...field}
                        disabled={isSubmitting || disabled}
                        id={props.id}
                        color={color}
                        type='checkbox'
                        className={isSubmitting || disabled ? 'disabled' : ''}
                    />
                </Checkbox.Label>
                {RenderErrorMessages(field.name, touched, errors)}
            </Checkbox.Column>
        </Checkbox.Base>
    )
}
