/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled, { css } from 'styled-components'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { WebsiteHero } from '../components/Hero'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Card, Tabs, Theme } from 'nxui/src'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
export const WebsitePricingStyling = {
    WebsiteHero: {
        Search: styled(WebsiteHero.Search)`
            max-width: 700px;
            margin-inline: auto;
            margin-bottom: 40px;
        `
    },

    Card: styled(Card)`
        max-width: 1000px;
        margin-inline: auto;
        overflow: hidden;

        ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-md'])} {
            border-radius: 6px;
        }
    `,

    Tabs: styled(Tabs.Prefab)<{ selected: number }>`
        width: 100%;

        nav {
            padding: 20px;
            padding-bottom: 0;
        }

        tr > td:first-child {
            color: ${({ theme }) => theme['text--300']};
        }

        ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-md'])} {
            th,
            td {
                text-align: center;
                padding-inline: 5px;
            }

            th > span {
                justify-content: center;
                & > span {
                    width: 0;
                }
            }
        }

        ${({ selected, theme }) => css`
            ul > li {
                background-color: ${theme['white--100']};
                color: ${theme['text--300']};

                &:hover {
                    background-color: ${theme['background--100']};
                    color: ${theme['text--300']};
                }
            }

            ul > li:nth-child(${selected + 1}) {
                background-color: ${theme['primary--100']};
                color: ${theme['white--100']};
            }
        `}
    `
}
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/
