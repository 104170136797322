/**********************************************************************************************************
 *   API
 **********************************************************************************************************/
import { baseApi } from 'api/base'

/**********************************************************************************************************
 *   HELPER
 **********************************************************************************************************/
import { Links, MetaPayload, MetaResponse } from 'models/app'
import {
    AddPaymentMethodPayload,
    CreditTransaction,
    GeneratePaymentMethodTokenMutationArg,
    Invoice,
    PayInvoice,
    PaymentMethod,
    PaymentMethodData,
    PaymentMethodWithFormName,
    Transaction
} from 'models/billing'

/**********************************************************************************************************
 *   BILLING API
 **********************************************************************************************************/
export const billingAPI = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        paymentMethods: builder.query<Array<PaymentMethodWithFormName>, void>({
            query: () => 'client/ordering/payment-methods',
            transformResponse: (baseQueryReturnValue: { data: Array<PaymentMethod> }) => {
                // Add a "form name" to each payment method, so that we know which type of form to use for the payment
                function getPaymentMethodFormName(method: PaymentMethod) {
                    if (method.module_meta?.capture_type === 'link') return 'linkTypeForm'
                    if (!method.module_id) return 'eftForm'
                    if (method.module_meta?.capture_type === 'direct' && method.module_meta.payment_data_required) return 'creditCardForm'
                    if (method.module_meta?.capture_type === 'token') return 'tokenTypeForm'

                    //fallback to EFT form
                    return 'eftForm'
                }

                const data: PaymentMethodWithFormName[] = baseQueryReturnValue.data.map((paymentMethod) => ({
                    ...paymentMethod,
                    formName: getPaymentMethodFormName(paymentMethod)
                }))

                return data
            },
            providesTags: ['Payment-Methods']
        }),
        accountPaymentMethods: builder.query<Array<PaymentMethodData>, void>({
            query: () => 'client/billing/payment-method-data',
            transformResponse: (response: { data: Array<PaymentMethodData> }) => response.data,
            providesTags: ['Payment-Methods-Data']
        }),
        accountPaymentMethod: builder.query<PaymentMethodData, number>({
            query: (id) => `client/billing/payment-method-data/${id}`,
            transformResponse: (response: { data: PaymentMethodData }) => response.data
        }),
        savePaymentMethod: builder.mutation<void, AddPaymentMethodPayload>({
            query: (paymentMethod) => ({
                url: 'client/billing/payment-method-data',
                method: 'POST',
                body: paymentMethod
            }),
            invalidatesTags: ['Payment-Methods-Data']
        }),
        removePaymentMethod: builder.mutation<void, number>({
            query: (paymentMethod) => ({
                url: `client/billing/payment-method-data/${paymentMethod}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Payment-Methods-Data']
        }),
        setDefaultPaymentMethod: builder.mutation<void, number>({
            query: (paymentMethod) => ({
                url: `client/billing/payment-method-data/${paymentMethod}`,
                method: 'PUT'
            }),
            async onQueryStarted(data, { dispatch, queryFulfilled }) {
                const paymentMethodResult = dispatch(
                    billingAPI.util.updateQueryData('accountPaymentMethods', undefined, (draft) => {
                        draft.forEach((paymentMethod) => {
                            paymentMethod.is_default = paymentMethod.id === data
                        })
                    })
                )
                try {
                    await queryFulfilled
                } catch (error) {
                    paymentMethodResult.undo()
                }
            }
        }),
        generatePaymentMethodToken: builder.mutation<{ data: { render: string } }, GeneratePaymentMethodTokenMutationArg>({
            query: ({ paymentMethodId, payload }) => ({
                url: `client/billing/payment-methods/${paymentMethodId}/generate`,
                method: 'POST',
                body: payload
            })
        }),
        accountCredit: builder.query<{ account_credit: string }, void>({
            query: () => 'client/billing/credit',
            transformResponse: (response: { data: { account_credit: string } }) => response.data,
            providesTags: ['Account-Credit']
        }),
        applyAccountCredit: builder.mutation<Invoice, { id: number; account_credit: number }>({
            query: ({ id, ...rest }) => ({
                url: `client/billing/invoices/${id}/apply-credit`,
                method: 'POST',
                body: rest
            }),
            transformResponse: (response: { data: Invoice }) => response.data,
            invalidatesTags: ['Account-Credit']
        }),
        invoices: builder.query<{ data: Array<Invoice>; links: Links; meta: MetaResponse }, MetaPayload>({
            query: ({ page = '1', filter_by, sort_by = '-id', search_by }) => {
                const sortBy = sort_by ? `&sort_by=${sort_by}` : ''
                const filterBy = filter_by ? `&filter_by=${filter_by.key},${filter_by.values.join()}` : ''
                const searchBy = search_by ? `&search_by=${search_by.key},~,${search_by.value}` : ''

                return `client/billing/invoices?page=${page}${sortBy}${filterBy}${searchBy}`
            },
            providesTags: ['Invoices']
        }),
        invoice: builder.query<Invoice, number>({
            query: (invoice) => `client/billing/invoices/${invoice}`,
            transformResponse: (response: { data: Invoice }) => response.data,
            providesTags: (result, error, arg) => [{ type: 'Invoice', id: arg }]
        }),
        payInvoice: builder.mutation<void | { link: string }, PayInvoice & { id: string }>({
            query: ({ id, ...rest }) => ({
                url: `client/billing/invoices/${id}/pay`,
                method: 'POST',
                body: rest
            }),
            transformResponse: (response: { data: { link: string } }) => response.data,
            invalidatesTags: (result, error, arg) => ['Invoices', { type: 'Invoice', id: arg.id }, 'Services', 'Service', 'Payment-Methods-Data']
        }),
        sendInvoiceEmail: builder.mutation<void, number>({
            query: (id) => ({
                url: `client/billing/invoices/${id}/send`,
                method: 'POST'
            })
        }),
        transactions: builder.query<{ data: Array<Transaction>; links: Links; meta: MetaResponse }, MetaPayload>({
            query: ({ page = 1, filter_by, sort_by = '-transaction_date', search_by }) => {
                const sortBy = sort_by ? `&sort_by=${sort_by}` : ''
                const filterBy = filter_by ? `&filter_by=${filter_by.key},${filter_by.values}` : ''
                const searchBy = search_by ? `&search_by=${search_by.key},~,${search_by.value}` : ''

                return `client/billing/transactions?page=${page}${sortBy}${filterBy}${searchBy}`
            }
        }),
        creditTransactions: builder.query<{ data: Array<CreditTransaction>; links: Links; meta: MetaResponse }, MetaPayload>({
            query: ({ page = 1, filter_by, sort_by = '-created_at', search_by }) => {
                const sortBy = sort_by ? `&sort_by=${sort_by}` : ''
                const filterBy = filter_by ? `&filter_by=${filter_by.key},${filter_by.values}` : ''
                const searchBy = search_by ? `&search_by=${search_by.key},~,${search_by.value}` : ''

                return `client/billing/credit-transactions?page=${page}${sortBy}${filterBy}${searchBy}`
            }
        })
    })
})

export const {
    usePrefetch,
    useAccountPaymentMethodsQuery,
    useAccountPaymentMethodQuery,
    useSavePaymentMethodMutation,
    useRemovePaymentMethodMutation,
    useSetDefaultPaymentMethodMutation,
    useAccountCreditQuery,
    useInvoicesQuery,
    useInvoiceQuery,
    usePayInvoiceMutation,
    useSendInvoiceEmailMutation,
    useTransactionsQuery,
    useCreditTransactionsQuery,
    useApplyAccountCreditMutation,
    usePaymentMethodsQuery,
    useGeneratePaymentMethodTokenMutation
} = billingAPI
