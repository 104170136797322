/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Form } from 'formik'
import { Link } from 'react-router-dom'
import styled, { ThemeProps, css } from 'styled-components'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Box, Bubble, Button, Card, Combobox, Theme } from 'nxui/src'

/**********************************************************************************************************
 *   INTERFACES / ENUMS
 **********************************************************************************************************/
import { ServiceStatus, Weight } from 'models/enums'

interface StatusProps {
    status: `${ServiceStatus}`
}

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
export const Domains = {
    Base: styled.section`
        width: 100%;

        article {
            width: 100%;
            margin-bottom: 0;
        }

        td {
            padding: 0;
        }
    `,
    Active: styled(Bubble)`
        margin-bottom: 1rem;
    `,
    Links: styled.div`
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            flex-direction: column;
            gap: 10px;
        }

        > button {
            width: 47%;
            justify-content: center;
            ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
                width: 100%;
                margin: 0;
            }
        }
    `,
    Item: styled(Link)`
        display: flex;
        flex-direction: column;
        gap: 9px;

        &.active {
            background-color: #eef2ff;
            color: #373179;
        }

        &.last {
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
        }

        > li {
            display: grid;
            gap: 9px;
            padding: 18px;

            &:hover {
                cursor: pointer;
            }
        }
    `,
    Name: styled.span`
        word-break: break-word;
    `
}

export const Domain = {
    Base: styled.section`
        height: 100%;
        width: 100%;

        div[role='progressbar'] {
            ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-xl'])} {
                margin-top: 1rem;
            }
        }
    `,
    Empty: styled(Box)`
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-xl'])} {
            margin-top: 1rem;
        }

        .info {
            padding: 0 !important;
        }
    `,
    Tabs: styled(Card)`
        section {
            margin-bottom: 0;
        }

        nav[aria-label='tabs'],
        div[aria-label='select'] {
            margin: 20px;
        }
    `,
    Pending: styled(Card)`
        main {
            width: 100%;
            padding: 15px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 5px;
        }

        p {
            max-width: 340px;
            text-align: center;
            color: ${(props) => props.theme['text--100']};
            ${Theme.Functions.setFont('Inter', Weight.Medium)};
        }
    `,
    Footer: styled.div`
        display: flex;
        justify-content: center;
        background-color: ${(props) => props.theme['background--100']};
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
        padding: 15px;
    `,
    Icon: styled.div<StatusProps>`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 60px;
        width: 60px;
        margin: 0 auto;
        border-radius: 9999px;
        color: ${(props) => (props.status === 'expired' ? props.theme['error--100'] : props.theme['primary--100'])};
        background-color: ${(props) => (props.status === 'expired' ? 'rgba(255, 66, 66, 0.1)' : 'rgba(79, 70, 229, 0.1)')};
        ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
            height: 60px;
            width: 60px;
            margin: 0 auto 16px;
        }
    `,
    Content: styled.main`
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 5px;

        p {
            color: ${(props) => props.theme['text--100']};
            ${Theme.Functions.setFont('Inter', Weight.Medium)};
        }
    `,
    Notification: styled.div`
        display: flex;
        padding: 12px 20px;
        width: 100%;
        background-color: rgba(255, 184, 18, 0.1);
        color: ${(props) => props.theme['warning--200']};
        font-size: 1.4rem;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    `,
    Card: styled(Card)`
        tr {
            border: none;
            border-top: 1px solid ${(props) => props.theme['border--100']};

            &:first-of-type {
                border-top: 1px solid ${(props) => props.theme['border--100']};
            }
        }
    `,
    Renewal: styled.div`
        display: flex;
        align-items: center;
        gap: 7px;
        font-size: 1.4rem;

        > button {
            font-size: 1.4rem;
        }
    `
}

export const Nameservers = {
    Add: styled(Form)`
        border-top: 1px solid ${(props) => props.theme['gray--100']};
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;

        section {
            border-radius: 0;
            border-bottom: 0;
            box-shadow: none;
            margin-bottom: 10px;
        }

        tr {
            border: none;
        }

        td {
            padding: 5px 20px;
            height: unset;
        }

        tr:last-child {
            border: none;

            td {
                > div {
                    margin-bottom: 0;
                }
            }
        }

        td:last-child {
            display: table-cell;

            > div {
                margin-bottom: 0;
            }
        }
    `,
    Label: styled.label`
        display: block;
        margin-left: 20px;
        margin-top: 10px;
    `,
    AddMore: styled.div`
        display: flex;
        padding: 10px 20px;
        justify-content: flex-end;
        gap: 10px;
        border-top: 1px solid ${(props) => props.theme['gray--100']};

        ${({ initial }: { initial?: boolean }) =>
            initial &&
            css`
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                background-color: #f9fafb;
                justify-content: space-between;
            `}
    `,
    View: styled(Card)`
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        td {
            padding: 0;
        }
    `,
    Actions: styled.div`
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 20px;
        border-top: 1px solid ${(props) => props.theme['gray--100']};

        & > div {
            display: flex;
            gap: 6px;
            justify-content: flex-end;
        }
    `,
    EditAction: styled.div`
        button > span {
            display: flex;
            align-items: center;
            flex-direction: row;
            gap: 5px;

            svg {
                width: 12px;
                height: 12px;
            }
        }
    `,
    Row: styled.div`
        display: flex;
        justify-content: space-between;
        padding: 0 20px;

        & button {
            padding: 0 5px;

            &:focus {
                border-radius: 5px;
                outline: 1px solid ${(props) => props.theme['primary--100']};
            }
        }
    `,
    Update: styled.div`
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 17px 0 0;
    `,
    Editing: styled.form`
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        & > span {
            margin-bottom: 5px;
        }

        & > * {
            padding-right: 20px;
            padding-left: 20px;
        }
    `
}

export const DNSMode = {
    Wrapper: styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        margin: 0 20px;
        flex-wrap: wrap;
    `,
    Base: styled(Form)`
        flex-grow: 1;

        > div {
            max-width: 400px;
            margin-bottom: 20px;
        }
    `,
    Confirmation: styled.span`
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        span {
            text-align: center;
            color: ${(props) => props.theme['text--100']};
            font-weight: ${Weight.Medium};
        }
    `,
    Actions: styled.span`
        display: flex;
        gap: 6px;
    `
}

export const Records = {
    Add: styled(Form)`
        border-top: 1px solid ${(props) => props.theme['gray--100']};
        padding-top: 22px;

        tr {
            border: none;

            ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
                display: flex;
                flex-direction: column;

                > td {
                    height: 100%;
                }
            }
        }

        td:first-child {
            min-width: 120px;
        }

        input::-webkit-calendar-picker-indicator {
            display: none !important;
        }
    `,
    Confirm: styled.div`
        display: flex;
        justify-content: flex-end;
        padding: 10px;
        margin: 10px;
        gap: 10px;
        border-top: 1px solid #e0e0e0;
    `,
    Result: styled.p`
        padding: 0 20px 22px 20px;
        color: ${(props) => props.theme['text--100']};
        ${Theme.Functions.setFont('Inter', 500)};
    `,
    Value: styled.span`
        color: ${(props) => props.theme['black--100']};
    `,
    Small: styled.div`
        width: 80px;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            min-width: 275px;
        }
    `,
    Edit: styled(Form)`
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            thead {
                display: none;
            }

            tbody {
                padding: 15px 0;
                display: flex;
                flex-direction: column;

                td:first-child {
                    width: 100%;
                }

                tr {
                    padding: 0 20px;
                    border: none;
                    margin-bottom: 15px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    display: flex;
                    flex-direction: column;

                    td {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        padding: 12px 15px;
                        width: 100%;

                        &:nth-child(even) {
                            border-radius: 12px;
                            background-color: ${(props) => props.theme['background--100']};
                        }

                        &:empty {
                            display: none;
                        }
                    }
                }
            }
        }
        article {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        td:first-child {
            width: 15px;
            white-space: nowrap;
        }
    `,
    View: styled(Card)<{ editIndex: number | null; isSRV: boolean; editIndexes: number[] }>`
        border-radius: 0;
        overflow: auto;

        td {
            vertical-align: top;
        }

        table {
            color: ${(props) => props.theme['black--100']};

            tr {
                position: relative;
            }

            ${({
                editIndexes,
                srvIndexes,
                theme
            }: {
                editIndexes: number[]
                srvIndexes: number[]
                theme: ThemeProps<Record<string, unknown>>['theme']
            }) => {
                if (editIndexes.length === 0) return ''

                return editIndexes.map((editIndex, _, array) => {
                    const trPerEditIndex = 3 // 1 for the edit row, 1 for the action row.

                    const indexesLowerThanCurrent = [...array]
                        .sort((a, b) => a - b)
                        .filter((_editIndex: number) => _editIndex < editIndex)
                        .reduce((acc) => acc + trPerEditIndex, editIndex)

                    if (srvIndexes.includes(editIndex)) {
                        return css`
                            tr:nth-of-type(${indexesLowerThanCurrent + 1}) {
                                border-bottom: 1px solid transparent;
                            }

                            tr:nth-of-type(${indexesLowerThanCurrent + 3}) {
                                border-top: 1px solid transparent;
                                height: 190px;
                            }

                            ${Theme.Functions.mediaBreakDown(theme['breakpoints-sm'])} {
                                // View Row
                                tr:nth-of-type(${indexesLowerThanCurrent + 1}) {
                                    border-bottom: 1px solid transparent;
                                }

                                // Fields
                                tr:nth-of-type(${indexesLowerThanCurrent + 3}) {
                                    height: 700px;
                                }
                            }}
                        `
                    }

                    return css`
                        tr:nth-of-type(${indexesLowerThanCurrent + 1}) {
                            border-bottom: 1px solid transparent;
                        }

                        tr: nth-of-type(${indexesLowerThanCurrent + 3}) {
                            border-top: 1px solid transparent;
                        }
                    `
                })
            }}

            ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
                thead {
                    display: none;
                }

                button {
                    float: right;
                }

                tbody {
                    padding: 15px 0;
                    display: flex;
                    flex-direction: column;

                    tr {
                        display: flex;
                        flex-direction: column;
                        padding: 0 20px;
                        border: none;
                        margin-bottom: 15px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        td {
                            display: flex;
                            height: 100%;
                            padding: 12px 15px;

                            &:nth-child(even) {
                                border-radius: 12px;
                                background-color: ${(props) => props.theme['background--100']};
                            }
                        }
                    }
                }
            }
        }
    `,
    Record: styled.div<{ mode: 'view' | 'edit'; hasBadge?: boolean }>`
        min-height: 55px;
        display: flex;
        align-items: center;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            > div:nth-child(2) {
                min-width: ${(props) => (props.hasBadge ? 'auto' : '275px')};
                text-align: ${(props) => (props.mode === 'view' ? 'right' : 'left')};
            }
        }
    `,
    Hidden: styled.div`
        display: none;
        color: ${(props) => props.theme['text--100']};

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            display: block;
        }
    `,
    EditRecord: styled.div`
        button > span {
            display: flex;
            align-items: center;
            flex-direction: row;
            gap: 5px;

            svg {
                width: 12px;
                height: 12px;
            }
        }
    `,
    EditField: styled.div`
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-bottom: 20px;

        & > div > * {
            margin-top: 0;
            margin-bottom: 0;
        }
    `,
    Combobox: styled(Combobox)`
        width: 100px;
    `,
    ActionRow: styled.div`
        position: absolute;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        gap: 10px;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            border-bottom: 1px solid ${(props) => props.theme['gray--100']};
            height: 70px;
            padding: 0 35px;
        }
    `,
    RightButtons: styled.div`
        display: flex;
        gap: 10px;
        justify-content: flex-end;
    `,
    DeleteButton: styled(Button)`
        span {
            color: white;
        }
    `,
    SRVRow: styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 90px 90px;
        gap: 10px;

        position: absolute;
        left: 0;
        right: 0;
        height: 180px;
        width: 100%;

        padding: 0 20px;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(7, 90px);
            height: 700px;
            padding: 0 20px;

            > div {
                padding: 5px 15px 0 15px;
                border-radius: 12px;
            }

            > div:nth-child(even) {
                background-color: ${(props) => props.theme['background--100']};
            }
        }
    `,
    PreviewRow: styled.div`
        position: absolute;
        left: 0;
        min-height: 30px;
        width: 100%;
        padding-top: 10px;
        text-wrap: wrap;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            margin-top: 20px;
            margin-left: 15px;
        }
    `,
    SubmittingButton: styled(Button).attrs({
        type: 'button',
        color: 'secondary',
        loading: true
    })`
        padding: 0 12px;
        svg {
            margin-left: 0.75rem;
        }
    `,
    TableActions: styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;

        padding: 15px 20px;

        background-color: ${({ theme }) => theme['background--100']};
        border-radius: 15px;
    `
}

export const Contact = {
    Base: styled(Card)`
        width: 100%;
        margin-bottom: 0;
    `,
    Row: styled.div`
        display: flex;
        justify-content: space-between;
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            flex-direction: column;
        }

        > div {
            width: 220px;
            ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
                width: 100%;
            }
        }
    `,
    Value: styled.article`
        > p {
            font-weight: ${Weight.Medium};
            color: ${(props) => props.theme['black--100']};
        }
    `
}

export const Dns = {
    Base: styled.div`
        width: 100%;

        article {
            margin-bottom: 0;
        }

        > div[role='progressbar'] {
            margin-bottom: 0;
        }
    `,
    Parked: styled.span`
        display: flex;
        padding: 12px 20px;
        background-color: ${(props) => props.theme['background--100']};
        ${Theme.Functions.setFont('Inter', Weight.Medium)};
        color: ${(props) => props.theme['text--100']};
        font-size: 1.4rem;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
    `
}

export const Information = {
    Base: styled.div`
        section:first-child {
            border-radius: 0;
        }

        section:last-child {
            border-radius: 16px;
        }

        thead {
            th {
                display: flex;
            }
        }

        tr {
            display: flex;

            td {
                width: 100%;
                display: flex;
                align-items: center;
            }
        }

        &:last-child {
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
        }
    `,
    EPPCode: styled.div`
        display: flex;
        align-items: center;
        gap: 8px;

        button {
            > svg {
                opacity: 0.8;
                width: 24px;
                height: 24px;
                transition: opacity 0.8s ease-out;

                &:hover {
                    opacity: 1;
                }
            }
        }
    `,
    Renewal: styled.div`
        display: flex;
        align-items: center;
        gap: 7px;
        font-size: 1.4rem;

        > button {
            font-size: 1.4rem;
        }
    `
}

export const Overview = {
    Base: styled(Box)`
        margin-bottom: 16px;
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-xl'])} {
            margin-top: 16px;
        }

        header {
            width: 100%;

            > div {
                width: 100%;
            }
        }

        footer {
            padding: 12px 24px 12px 0;
            display: flex;
            justify-content: flex-end;
        }
    `,
    Header: styled.div`
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            flex-direction: column;
            align-items: flex-start;
            gap: 7px;
        }
    `,
    Service: styled.div`
        display: flex;
    `,
    Name: styled.span`
        font-size: 1.8rem;
        word-break: break-word;
        ${Theme.Functions.setFont('Inter', Weight.Medium)}
    `,
    Info: styled.div`
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid ${(props) => props.theme['gray--100']};
        padding: 25px;
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            flex-direction: column;
            padding: 0;
        }
    `,
    Column: styled.div`
        width: 25%;
        display: flex;
        flex-direction: row;
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-xl'])} {
            width: 100%;
        }
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            flex-direction: column;
        }

        &:not(:last-child):after {
            content: '';
            border: 1px solid rgba(209, 213, 219, 0.3);
            align-self: stretch;

            ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
                align-items: center;
            }
        }
    `,
    Row: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.4rem;
        text-align: center;
        flex: 1;
        gap: 10px;
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            padding: 20px 0 20px 25px;
            align-items: flex-start;
        }

        > span {
            &:first-child {
                color: ${(props) => props.theme['text--100']};
                ${Theme.Functions.setFont('Inter', Weight.Medium)};
            }

            &:last-child {
                word-break: break-all;
                ${Theme.Functions.setFont('Inter', Weight.Medium)};
            }
        }
    `
}
