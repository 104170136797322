import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { IFieldInput } from 'containers/shop/components/form.d'
import { AnimatePresence, motion } from 'framer-motion'
import { Weight } from 'models/enums'
import { Theme, Tooltip } from 'nxui/src'
import { forwardRef, ReactElement } from 'react'
import styled, { css } from 'styled-components'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
export const Field = {
    Input: styled.input<IFieldInput>`
        ${Theme.Functions.reset()};
        position: relative;
        cursor: auto;
        display: flex;
        height: 28px;
        width: calc(100% - 16px);
        padding: 4px 8px;
        outline: 0;
        background-color: ${(props) => props.theme[`background--000`]};
        font-size: 1.2rem;
        color: ${(props) => props.theme[`text--200`]};
        border-radius: 8px;
        ${Theme.Functions.normalizeText()};
        ${Theme.Functions.animation('all', 200)};
        ${Theme.Functions.setFont(`'Inter', sans-serif`, Weight.SemiBold)};

        &::placeholder {
            color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`text--100`], `0.35`)};
        }

        /* &:focus {
            outline: none;
            border-color: ${(props) => props.theme[`border--200`]};

            ${(props) =>
            props.prepend &&
            css`
                border-color: ${props.theme[`border--100`]};
            `}
        } */

        &:disabled {
            color: ${(props) => props.theme[`text--100`]};
            background-color: ${(props) => props.theme[`background--200`]};
            border-color: transparent;
        }

        &::-webkit-calendar-picker-indicator {
            display: none !important;
        }

        /* 
         * breakpoints--md is invalid, fix this the next time this component is being worked on
         * @link {src/declarations/styled.d.ts}
         */
        ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints--md`])} {
            padding: 4px 8px;
        }

        ${(props) =>
            props.error &&
            css`
                border-color: ${props.theme[`error--100`]};
            `}

        ${(props) =>
            props.disabled &&
            css`
                color: ${(props) => props.theme[`text--100`]};
                background-color: ${(props) => props.theme[`background--200`]};
                border-color: transparent;
            `}

        ${(props) =>
            props.prepend &&
            css`
                border-radius: 0 8px 8px 0;
                border-left: 0;
            `}

        ${(props) =>
            props.append &&
            css`
                border-radius: 8px 0 0 8px;
                border-right: 0;
            `}
    `,
    Wrapper: styled.div`
        display: inline-flex;
        flex-flow: row nowrap;
        border: 1px solid ${(props) => props.theme[`border--100`]};
        border-radius: 8px;

        &:focus-within {
            outline: none;
            border-radius: 8px;
            border: 1px solid ${(props) => props.theme[`border--200`]};
        }

        /* &:focus {
            outline: none;
            border-color: ${(props) => props.theme[`border--200`]};
        } */
    `,
    Prepend: styled.div<{ error?: boolean }>`
        display: inline-flex;
        align-items: center;
        padding: 0 10px;
        font-size: 1.2rem;
        border-radius: 8px 0 0 8px;
        /* border: 1px solid ${(props) => props.theme[`border--100`]}; */
        border-right: 0;
        background-color: ${(props) => props.theme[`background--200`]};
        color: ${(props) => props.theme[`text--100`]};
        ${Theme.Functions.setFont(`'Inter', sans-serif`, Weight.SemiBold)};
        ${(props) =>
            props.error &&
            css`
                border-color: ${props.theme[`error--100`]};
            `}
    `,
    Append: styled.div<{ error?: boolean }>`
        display: inline-flex;
        align-items: center;
        padding: 0 10px;
        font-size: 1.2rem;
        border-radius: 0 8px 8px 0;
        border: 1px solid ${(props) => props.theme[`border--100`]};
        border-left: 0;
        background-color: ${(props) => props.theme[`background--200`]};
        color: ${(props) => props.theme[`text--100`]};
        ${Theme.Functions.setFont(`'Inter', sans-serif`, Weight.SemiBold)};

        ${(props) =>
            props.error &&
            css`
                border-color: ${props.theme[`error--100`]};
            `}
    `,
    Error: {
        Base: styled(motion.div)`
            height: 100%;
            display: inline-flex;
            align-items: center;
            padding: 0 10px;
            font-size: 1.2rem;
            border-radius: 0 8px 8px 0;
            border-left: 0;
            background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`error--100`], `0.1`)};
            color: ${(props) => props.theme[`text--100`]};
            ${Theme.Functions.setFont(`'Inter', sans-serif`, Weight.SemiBold)};
        `,
        Icon: styled(ExclamationCircleIcon)`
            position: relative;
            width: 18px;
            height: 100%;
            vertical-align: middle;
            color: ${(props) => props.theme[`error--100`]};

            ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-md`])} {
                width: 20px;
            }
        `
    }
}

/**********************************************************************************************************
 *   INPUT FIELD
 **********************************************************************************************************/
export const FieldInput = forwardRef<HTMLInputElement, IFieldInput>((props, ref): ReactElement => {
    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Field.Wrapper>
            {props.prepend && <Field.Prepend>{props.prepend}</Field.Prepend>}
            <Field.Input {...props} ref={ref} />
            {props.error && props.touched && (
                <Tooltip as={'div'} content={props.message}>
                    <AnimatePresence>
                        <Field.Error.Base
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ opacity: { duration: 0.2 } }}
                            exit={{ opacity: 0 }}
                        >
                            <Field.Error.Icon />
                        </Field.Error.Base>
                    </AnimatePresence>
                </Tooltip>
            )}
            {props.append && !props.error && <Field.Append>{props.append}</Field.Append>}
        </Field.Wrapper>
    )
})
