/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/utilities/Flex'
import { CompetitiveBubble } from './bubble'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CompetitivePricesStyles as S } from './style'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _CompetitivePrices = () => {
    /***** HOOKS *****/
    const { website } = useAppSelector((state) => state.app.appSettings)

    /***** RENDER HELPERS *****/
    const filteredTLDs = website?.tld_list.split(',').filter(Boolean)

    /***** RENDER *****/
    if (!filteredTLDs?.length) {
        return null
    }

    return (
        <S.Banner>
            <S.Heading>Most competitive prices guaranteed</S.Heading>
            <Flex justify='center' gap={20} wrap='wrap'>
                {filteredTLDs.map((tld) => (
                    <CompetitiveBubble key={tld} tld={tld} />
                ))}
            </Flex>
        </S.Banner>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/***** EXPORTS *****/
export const CompetitivePrices = Object.assign(_CompetitivePrices, {
    Styles: S
})
