/* eslint-disable indent */
/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Style } from '../form/util'

/**********************************************************************************************************
 *   ASSET IMPORTS
 **********************************************************************************************************/
import { ChevronDownIcon } from '@heroicons/react/24/solid'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled, { css } from 'styled-components'
import * as Theme from '../../theme/theme'

export const Dropdown = {
    Wrapper: styled.div`
        ${Style.Wrapper}
        user-select: none;
    `,
    Label: styled.label`
        ${Style.Label}
    `,
    Selector: styled.div`
        ${Style.Field}
        cursor: pointer;
    `,
    Container: styled.div`
        ${Style.Column}
    `,
    Icon: styled.div`
        display: flex;
        align-items: center;
        margin-right: 10px;
        color: ${(props) => props.theme[`icon--100`]};
        ${Theme.Functions.animation(`color`)};

        svg {
            width: 14px;
        }
    `,
    Title: styled.span`
        display: inline-flex;
        align-items: center;
        width: 100%;
        font-size: 1.2rem;
        ${Theme.Functions.normalizeText()};
        ${Theme.Functions.setFont('Inter', 500)};
    `,
    Chevron: styled(ChevronDownIcon)`
        display: flex;
        align-items: center;
        width: 20px;
        margin-left: 8px;
        color: ${(props) => props.theme[`icon--100`]};
        ${Theme.Functions.animation(`color`)};
    `,
    List: styled.ul`
        z-index: 9999;
        position: absolute;
        width: 100%;
        visibility: hidden;
        max-height: 0;
        margin-top: 6px;
        padding: 5px;
        border: 1px solid ${(props) => props.theme[`transparent`]};
        border-radius: 8px;
        background-color: ${(props) => props.theme[`background--000`]};
        overflow: hidden;
        transition: max-height 250ms 0s, border-color 0s 200ms, visibility 0s 200ms;
        box-shadow: 0px 8px 12px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--100`], 0.04)};

        ${({ active, height }) => {
            if (active) {
                return css`
                    visibility: visible;
                    max-height: ${height < 256 ? height : 254 + 2}px;
                    border-color: ${(props) => props.theme[`border--100`]};
                    transition: max-height 250ms 0s, border-color 0s 0ms;
                    overflow: ${height < 256 ? 'hidden' : 'auto'};
                `
            }
        }}
    `,
    Option: styled.li`
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 36px;
        padding: 0px 12px;
        border-radius: 8px;
        background-size: cover;
        font-size: 1.2rem;
        color: ${(props) => props.theme[`text--300`]};
        ${Theme.Functions.setFont('Inter', 500)};
        ${Theme.Functions.normalizeText()};
        ${Theme.Functions.animation(`color`)};

        &:hover {
            color: ${(props) => props.theme[`primary--100`]};
        }

        ${({ active }) => {
            if (active) {
                return css`
                    color: ${(props) => props.theme[`white--100`]};
                    background-color: ${(props) => props.theme[`primary--100`]};

                    svg {
                        color: ${(props) => props.theme[`white--100`]};
                    }

                    &:hover {
                        color: ${(props) => props.theme[`white--100`]};
                    }
                `
            }
        }}
    `
}
