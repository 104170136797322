/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled, { css } from 'styled-components'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Anchor, Table, Theme } from 'nxui/src'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import { InputField } from 'components/form/input'
import { Flex } from 'components/utilities/Flex'
import { Weight } from 'models/enums'

const generateBaseStyles = () => {
    return {
        Table: styled(Table)<{ editing: boolean }>`
            border-bottom: 1px solid ${({ theme }) => theme['border--100']};

            tr {
                position: relative;
                border-inline: 1px solid transparent;

                td:first-child {
                    width: 110px;
                }

                td:last-child {
                    width: 200px;
                    ${({ editing }) => {
                        if (editing) {
                            return css`
                                width: 55px;
                            `
                        }
                    }}
                }
            }
        `,

        Anchor: styled(Anchor)`
            display: flex;
            text-align: end;
            justify-content: flex-end;

            svg {
                margin-left: 5px;
                width: 12px;
                height: 12px;
            }
        `,

        Form: styled.form`
            margin-bottom: -22px;
        `,

        InputField: styled(InputField)`
            ${InputField.Styles.Base} {
                padding-right: 5px;
            }
        `,

        PermanentPlaceholder: styled(InputField.PermanentPlaceholder)`
            padding-top: 11px;
            ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-xl'])} {
                padding-top: 10px;
            }
        `,

        FillInputField: styled(InputField)`
            width: 50%;
            margin-bottom: 0;
        `,

        CheckboxFieldOuter: styled.div`
            width: 200px;
            height: 40px;
            margin-top: 23px;

            label {
                height: 40px;
                padding-block: 12px;
            }
        `,

        InvisibleSubmitButton: styled.button.attrs({ type: 'submit' })`
            visibility: hidden;
        `,

        DeleteLightboxActions: styled.span`
            display: flex;
            gap: 6px;
        `,
        ConfirmDelete: styled.span`
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            gap: 20px;

            span {
                text-align: center;
                color: ${(props) => props.theme['text--100']};
                font-weight: ${Weight.Medium};
            }
        `,

        AddForwarderForm: styled.form`
            padding: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 10px;

            ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-sm'])} {
                flex-direction: column;
            }
        `,

        FetchingMessage: styled.span`
            white-space: nowrap;
        `,

        ActionRow: styled(Flex)`
            padding-inline: 20px;
        `,

        Boldened: styled.span`
            color: ${({ theme }) => theme['text--300']};
        `,

        Preview: styled.div<{ outsideTable: boolean }>`
            color: ${({ theme }) => theme['text--100']};
            font-size: 14px;
            padding-inline: 20px;
            height: 100%;
            display: flex;
            align-items: center;
            font-weight: 500;

            ${({ outsideTable }) => outsideTable && 'padding-top: 20px'}
        `
    }
}

/**********************************************************************************************************
 *   STYLES START
 **********************************************************************************************************/
export const forwardingStyles = {
    ...generateBaseStyles(),

    Domain: {
        CheckboxFieldWrapper: styled.div<{ hasLabel?: boolean }>`
            margin-left: ${({ hasLabel }) => (hasLabel ? 0 : 5)}px;
            margin-top: ${({ hasLabel }) => (hasLabel ? 5 : 0)}px;

            ${({ hasLabel }) =>
                css`
                    > div > div > label:nth-child(${hasLabel ? 1 : 2}) {
                        padding-block: 12px;
                    }
                `}

            > div > div {
                display: flex;
                align-items: flex-start;
            }
        `,

        /**
         * Provides a table tightly coupled to the domain Forwarder.
         * This removes the row borders on the row that is being edited
         */
        Table: styled(generateBaseStyles().Table)<{ editingIndex: number; editingRowHeight: number }>`
            border: 1px solid transparent;

            tr td {
                overflow-wrap: anywhere;
            }

            tr td:last-child {
                overflow-wrap: unset;
            }

            ${({ editingIndex, editingRowHeight }) => {
                if (editingIndex !== -1) {
                    return css`
                        tr: nth-of-type(${editingIndex + 2}), tr:nth-of-type(${editingIndex + 1}) {
                            border-bottom: 1px solid transparent;
                        }

                        ${
                            editingRowHeight
                                ? `tr:nth-of-type(${editingIndex + 2}) {
                                height: ${editingRowHeight}px;
                            }`
                                : ''
                        };
                    `
                }
            }}
        `,

        FreedomRow: styled.div`
            position: absolute;
            inset: 0;
            height: fit-content;
            height: 100%;

            ${InputField.Styles.PermanentPlaceholder} {
                padding-top: 11px;
            }
        `,

        AddForm: {
            Wrapper: styled.div`
                border-bottom: 1px solid ${({ theme }) => theme['border--100']};
            `,

            ActionWrapper: styled.div`
                display: flex;
                justify-content: flex-end;
                gap: 10px;
                padding: 16px;
                border-top: 1px solid ${({ theme }) => theme['border--100']};
            `
        },

        Inner: {
            SourceFieldWrapper: styled.div<{ isMobile?: boolean }>`
                position: relative;
                height: 38px;
                min-width: ${({ isMobile }) => (isMobile ? 100 : 250)}px;
            `,
            Divider: styled.div`
                margin-top: 30px;
            `
        },

        EditingRowWrapper: styled.div<{ height?: number }>`
            height: ${({ height }) => height}px;
        `
    },

    Email: {
        FormWrapper: styled.div`
            border-bottom: 1px solid ${({ theme }) => theme['border--100']};
        `,

        SourceWrapper: styled.div`
            position: relative;
            width: 50%;
        `,

        ActionWrapper: styled.div`
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            padding: 16px;
            border-top: 1px solid ${({ theme }) => theme['border--100']};
        `,

        ForwardingSource: styled.div`
            position: relative;
        `,

        CheckboxWrapper: styled.div`
            display: flex;
            height: 100%;
            align-items: flex-end;

            label {
                height: 40px;
                padding-block: 12px;
                margin-top: 22px;
                min-width: max-content;
            }

            ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-sm'])} {
                width: 100%;

                > div {
                    width: 100%;
                }

                label {
                    margin-top: 0;
                }
            }
        `,

        InputField: styled(InputField)`
            width: 100%;
            flex-grow: 1;
        `
    }
}
