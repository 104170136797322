/**
 * List of pathnames for routes relating to the whitelabel website. These routes indicate to the GuestRoot and UserRoot components
 * that the sidebar should not be displayed.
 */
export const websiteRoutes = {
    ROOT: '/',
    DOMAIN_PRICING: '/domain-pricing',
    TERMS_OF_SERVICE: '/terms-of-service',
    WHOIS: '/whois',
    ABOUT_US: '/about-us'
} as const
