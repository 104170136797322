/* eslint-disable indent */
/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled, { css } from 'styled-components'
import * as Theme from '../../theme/theme'

export const Shadow = css`
    position: absolute;
    z-index: 100;
`

export const Scroll = {
    Content: styled.div`
        position: relative;
    `,
    Base: styled.div`
        overflow: auto;
        ${Theme.Functions.hideScrollbar()};
        ${Theme.Functions.animation('box-shadow', 600)};

        ${(props) => {
            if (props.direction === 'horizontal') {
                return css`
                    box-shadow: inset 20px 0px 10px -10px ${Theme.Functions.hexToRGBA(props.theme['background--400'], props.positions.scrollLeft)},
                        inset -20px 0px 10px -10px ${Theme.Functions.hexToRGBA(props.theme['background--400'], props.positions.scrollRight)};
                `
            } else {
                return css`
                    box-shadow: inset 0px 20px 10px -10px ${Theme.Functions.hexToRGBA(props.theme['background--400'], props.positions.scrollTop)},
                        inset 0px -20px 10px -10px ${Theme.Functions.hexToRGBA(props.theme['background--400'], props.positions.scrollBottom)};
                `
            }
        }}
    `
}
