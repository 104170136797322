/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { DkimRecords } from 'containers/services/server/dkimRecords'
import { MxRecords } from 'containers/services/server/mxRecords'
import { Ports } from 'containers/services/server/ports'
import { ServerDetails } from 'containers/services/server/serverDetails'
import { SpfRecords } from 'containers/services/server/spfRecords'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Card } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { serviceAPI } from 'api/service'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
import { ServiceMetaPayload } from 'api/service/types'
import { Service } from 'models/service'

interface Props {
    meta: ServiceMetaPayload
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function Server({ meta = { page: '1', sort_by: null } }: Props) {
    const { id } = useParams()
    const serviceId = Number(id)

    const { service } = serviceAPI.endpoints.services.useQueryState(meta, {
        selectFromResult: ({ data, ...rest }) => ({
            ...rest,
            service: data?.data.find((serviceItem) => serviceItem.id === serviceId) as Service
        })
    })

    /*   RENDER COMPONENT
     *****************************************************/
    if (!service) {
        return null
    }

    const { server, schema } = service

    return (
        <Card>
            <ServerDetails
                username={(service.product.service_type === 'email_hosting' ? service.name : schema.username) || ''}
                server={server}
                schema={schema}
                product={service.product}
            />
            {service.product.service_type === 'email_hosting' ? (
                <>
                    <Ports server={server} />
                    <MxRecords server={server} />
                    <SpfRecords server={server} />
                    <DkimRecords server={server} />
                </>
            ) : (
                ''
            )}
        </Card>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
