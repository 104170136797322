/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { createSlice } from '@reduxjs/toolkit'

/**********************************************************************************************************
 *    SLICE
 **********************************************************************************************************/
export const alertSlice = createSlice({
    name: 'alerts',
    initialState: {
        appAlert: {}
    },
    reducers: {
        pushNewAlert: (state, { payload }) => {
            state.appAlert = payload
        }
    }
})

export const { pushNewAlert } = alertSlice.actions
export default alertSlice.reducer
