/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _CheckBox } from './base'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { S } from './style'
import { _CheckBoxPill } from './variants/_Pill'

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export const CheckBox = Object.assign(_CheckBox, {
    /**
     * Exports of internal styling components.
     */
    Style: S,
    /**
     * Stylistic variation of the CheckBox component with a pill shape.
     */
    Pill: _CheckBoxPill
})
