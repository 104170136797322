/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import RenewForm from 'containers/services/form/renew.form'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Lightbox } from 'nxui/src'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
interface Props {
    id: number
    status: boolean
    close: () => void
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function RenewLightbox({ id, status, close }: Props) {
    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Lightbox
            title={'Renew Service'}
            description={'Keep a good thing going by renewing your service today.'}
            content={<RenewForm id={id} close={close} />}
            conditions={{ state: status, action: () => close() }}
            actions={[
                {
                    label: 'Back',
                    color: 'secondary',
                    type: 'button',
                    func: () => close()
                },
                {
                    label: 'Renew',
                    color: 'primary',
                    type: 'submit',
                    form: 'renewForm'
                }
            ]}
        />
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
