/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Formik } from 'formik'
import Yup from 'helpers/yup'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { EmailForwarderPreviewRow } from './emailForwarder/previewRow'

/**********************************************************************************************************
 *   STORE IMPORT
 **********************************************************************************************************/
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { toggleAddingEmailRedirect } from 'store/slices/domainSlice'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { regex } from 'helpers/utils'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Button, Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { forwardingStyles as S } from './_forwarding.styles'

/**********************************************************************************************************
 *   FORM
 **********************************************************************************************************/
import { InputField } from 'components/form/input'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import { domainAPI } from 'api/domain'

/***** STATIC *****/
export const validationSchema = Yup.object().shape({
    catch_all: Yup.boolean(),
    source: Yup.string()
        .required('Required')
        .test('valid string', 'Do not include an @ symbol', (value) => !value?.includes('@'))
        .when('catch_all', {
            is: true,
            then: () => Yup.string().optional()
        }),
    destination: Yup.string()
        .required('Required')
        .test('valid email', 'Invalid email', (value) => regex.email.test(value))
})

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const AddEmailForwarderForm = () => {
    /***** HOOKS *****/
    const { id } = useParams()
    const addingEmailRedirect = useAppSelector(({ domain }) => domain.addingEmailRedirect)
    const dispatch = useAppDispatch()

    /***** QUERIES *****/
    const { data: domainData } = domainAPI.endpoints.domain.useQueryState(Number(id))
    const [, { isLoading: isUpdatingEmailRedirect }] = domainAPI.endpoints.updateEmailRedirect.useMutation({ fixedCacheKey: 'update-email-redirect' })
    const [createEmailRedirect, { isLoading: isCreatingEmailRedirect }] = domainAPI.endpoints.createEmailRedirect.useMutation({
        fixedCacheKey: 'create-email-redirect'
    })

    /***** RENDER *****/
    if (!addingEmailRedirect) {
        return null
    }

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{ source: '', destination: '', catch_all: false }}
            onSubmit={async ({ source, ...values }) => {
                await createEmailRedirect({ ...values, source: `${source}@${domainData?.domain}`, domainId: Number(id) })
                dispatch(toggleAddingEmailRedirect())
            }}
        >
            {({ handleSubmit, values }) => (
                <S.Email.FormWrapper>
                    <EmailForwarderPreviewRow outsideTable />
                    <S.AddForwarderForm onSubmit={handleSubmit}>
                        <S.Email.CheckboxWrapper>
                            <Field description='Catch All' type='checkbox' name='catch_all' component={NXUIForm.CheckboxField} />
                        </S.Email.CheckboxWrapper>

                        <S.Email.InputField
                            name='source'
                            permanentPlaceholder={<InputField.PermanentPlaceholder>@{domainData?.domain}</InputField.PermanentPlaceholder>}
                            disabled={values.catch_all}
                            label='Address'
                        />

                        <S.Email.InputField name='destination' label='Forwarding To' placeholder='custom@domain.com' />
                        <S.InvisibleSubmitButton />
                    </S.AddForwarderForm>
                    <S.Email.ActionWrapper>
                        <Button
                            disabled={isCreatingEmailRedirect || isUpdatingEmailRedirect}
                            color={'secondary'}
                            onClick={() => dispatch(toggleAddingEmailRedirect())}
                        >
                            Cancel
                        </Button>
                        <Button disabled={isCreatingEmailRedirect || isUpdatingEmailRedirect} type='button' color='primary' onClick={handleSubmit}>
                            Add
                        </Button>
                    </S.Email.ActionWrapper>
                </S.Email.FormWrapper>
            )}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
