/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Field, FormikProvider, useFormik } from 'formik'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckboxField } from 'components/form/checkbox'
import { InputField } from 'components/form/input'
import { Lightbox } from 'components/lightbox'
import { Flex } from 'components/utilities/Flex'
import { Padding } from 'components/utilities/Padding'
import { Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useToggle } from 'helpers/hooks/useToggle'
import Yup from 'helpers/yup'

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { regex } from 'helpers/utils'
import { supportAPI } from '../../../../api/support/support'
import { Button } from '../../../../components/buttons'
import { ContactUsLightboxStyles as S } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TContactUsLightbox = React.FC<{
    className?: string
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ContactUsLightbox: TContactUsLightbox = ({ className }) => {
    /***** STATE *****/
    const [isOpen, toggleIsOpen] = useToggle(false)

    /***** QUERIES *****/
    const [createContactRequest] = supportAPI.endpoints.contactUs.useMutation()

    /***** HOOKS *****/
    const { executeRecaptcha } = useGoogleReCaptcha()

    /***** FORM *****/
    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            type: 'tech',
            name: '',
            email: '',
            subject: '',
            body: '',
            attachments: [],
            tos: false,
            collect: false
        },
        validationSchema: Yup.object().shape({
            type: Yup.string().required('Please select an enquiry type.'),
            subject: Yup.string().required('Please enter a subject.'),
            body: Yup.string().required('Please enter a message.'),
            tos: Yup.boolean().isTrue('Please acknowledge the terms of service.'),
            collect: Yup.boolean().isTrue('Please acknowledge that your IP address and email are being logged.'),
            email: Yup.string().matches(regex.email, 'Please enter a valid email address.').required('Please enter an email address.'),
            name: Yup.string().required('Please enter your name.')
        }),
        onSubmit: async ({ type, attachments, body, subject, name, email }, { resetForm }) => {
            await createContactRequest({
                name,
                subject,
                body,
                attention: type === 'tech' ? 'Technical Support' : 'Accounts & Billing',
                return_email: email,
                'attachments[]': attachments,
                'g-recaptcha-response': (await executeRecaptcha?.()) ?? ''
            })
            toggleIsOpen()
            resetForm()
        }
    })

    /***** FUNCTIONS *****/
    const handleToggle = () => {
        toggleIsOpen()
        form.resetForm()
    }

    /***** RENDER *****/
    return (
        <div className={className}>
            <S.ContactUs align='center' justify='space-between' gap={30}>
                <Flex direction='column'>
                    <S.Heading>
                        Still have questions? <S.NoWrapSpan>Contact us</S.NoWrapSpan>
                    </S.Heading>
                    <S.Body>We are more than happy to help!</S.Body>
                </Flex>
                <Flex>
                    <Button color='primary' onClick={toggleIsOpen}>
                        Contact Us
                    </Button>
                </Flex>
            </S.ContactUs>

            <S.Lightbox isOpen={isOpen} onClose={handleToggle}>
                <FormikProvider value={form}>
                    <form onSubmit={form.handleSubmit}>
                        <Lightbox.Header title='Leave us a message'>
                            Let us know how we can help you. We will get back to you as soon as possible.
                        </Lightbox.Header>
                        <Lightbox.Body>
                            <Field
                                component={NXUIForm.SelectField}
                                name='type'
                                label='Enquiry Type'
                                list={[
                                    { value: 'tech', label: 'Technical Support' },
                                    { value: 'accounts', label: 'Accounts & Billing' }
                                ]}
                            />
                            <InputField name='name' label='Name' required />
                            <InputField name='email' label='Email Address' required />
                            <InputField name='subject' label='Subject' required />
                            <Field component={NXUIForm.TextAreaField} name='body' label='Message' required />
                            <Field component={NXUIForm.UploadField} name='attachments' label='Attachments' />

                            <Padding.Margin bottom={8} />

                            <CheckboxField boxPosition='left' name='collect' required>
                                I acknowledge that my IP address, email address are being logged for monitoring my support conversation.
                            </CheckboxField>

                            <CheckboxField boxPosition='left' name='tos' required>
                                I agree to the Terms of Service and the Privacy Policy.
                            </CheckboxField>
                        </Lightbox.Body>
                        <Lightbox.Footer>
                            <Button.Submit color='primary' loading={form.isSubmitting && 'Submitting'}>
                                Submit
                            </Button.Submit>
                        </Lightbox.Footer>
                    </form>
                </FormikProvider>
            </S.Lightbox>
        </div>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
