/**
 * @fileoverview helper hook for getting the shop routes for the application. This handles both authenticated and
 * unauthenticated routes.
 */

import ConfigureAddons from 'containers/shop/addons/addons.configure'
import ConfigureDomains from 'containers/shop/domain/configure/domain.configure'
import { ShopGroupIdComponent } from 'containers/shop/groupId'
import Order from 'containers/shop/order'
import ConfigureServices from 'containers/shop/services/configure'
import ShopCheckout from 'containers/shop/shop.checkout'
import UserLanding from 'containers/shop/user/user.landing'
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { GroupNavigate } from 'router/hooks/useShopRoutes/shop.$groupId'
import { ShopElements } from 'router/hooks/useShopRoutes/shop.$groupId.purchase'

const Shop = lazy(() => import('containers/shop/shop'))

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type AuthenticationLevel = 'user' | 'guest'
type RouteObject = { path: string; element: JSX.Element }
type UseShopRoutes = (authentication: AuthenticationLevel) => Array<{ index?: boolean; element: JSX.Element }>
type WhenAuthentication = (expectedAuthentication: AuthenticationLevel, routeObject: RouteObject, fallback?: RouteObject) => Array<RouteObject>

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * hook for getting the shop routes for the application. The authentication level can be passed to indicate which routes
 * should be returned (authenticated or unauthenticated routes).
 */
export const useShopRoutes: UseShopRoutes = (authentication) => {
    /***** FUNCTIONS *****/
    // prettier-ignore
    const whenAuthentication: WhenAuthentication = (expectedAuthentication, routeObject, fallback) => {
        return authentication === expectedAuthentication 
            ? [routeObject] 
            : [fallback].filter((v) => !!v)
    }

    /***** HOOK RESULTS *****/
    return [
        { path: 'shop', element: <GroupNavigate /> },
        { path: 'shop/purchase', element: <GroupNavigate /> },
        {
            path: 'shop',
            element: <Shop />,
            children: [
                ...whenAuthentication(
                    'guest',
                    { path: 'landing', element: <UserLanding /> },
                    { path: 'landing', element: <Navigate to='/shop' replace /> }
                ),
                { path: 'checkout', element: <ShopCheckout /> },
                { path: ':groupId', element: <GroupNavigate /> },
                { path: 'order', element: <Navigate to='/shop/purchase' replace /> },
                { path: 'order/:orderId', element: <Order /> },
                {
                    path: ':groupId',
                    element: <ShopGroupIdComponent />,
                    children: [
                        { index: true, path: 'purchase', element: <ShopElements /> },
                        { path: 'configure/domains/:id', element: <ConfigureDomains /> },
                        {
                            path: 'configure/services/:id',
                            element: <ConfigureServices />,
                            children: [{ path: 'addons/:addonId', element: <ConfigureAddons /> }]
                        }
                    ]
                }
            ]
        }
    ]
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
