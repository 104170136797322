/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { FormikProps } from 'formik'
import { useEffect, useRef } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Anchor, Lightbox } from 'nxui/src'

/**********************************************************************************************************
 *   COMPONENT IMPORTS
 **********************************************************************************************************/
import UpdateEligibilityForm from 'containers/domains/forms/updateEligibility.form'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useUpdateEligibilityMutation } from 'api/domain'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
interface Props {
    status: boolean
    close: () => void
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function UpdateEligibilityLightbox({ status, close }: Props) {
    const formRef = useRef<FormikProps<{ eligibility_information: Array<{ field_name: string; field_value: string }> }>>(null)
    const [, { isLoading, isSuccess, isError }] = useUpdateEligibilityMutation({ fixedCacheKey: 'update-eligibility' })

    useEffect(() => {
        if (isSuccess) {
            close()
            formRef.current?.resetForm()
            formRef.current?.setSubmitting(false)
        }

        if (isError) {
            formRef.current?.resetForm()
            formRef.current?.setSubmitting(false)
        }
    }, [formRef, isSuccess, isError])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Lightbox
            title={'Eligibility Requirements'}
            description={
                <>
                    Certain domain names require proof of eligibility in order to be registered.{' '}
                    <Anchor
                        color='primary'
                        target='_blank'
                        href='https://ventraip.com.au/faq/article/eligibility-criteria-for-registering-au-domain-names/'
                    >
                        Learn more about eligibility
                    </Anchor>
                </>
            }
            content={<UpdateEligibilityForm form={formRef} />}
            conditions={{ state: status, action: () => close() }}
            actions={[
                {
                    label: 'Back',
                    color: 'secondary',
                    type: 'button',
                    func: () => close()
                },
                {
                    label: 'Save',
                    color: 'primary',
                    type: 'submit',
                    disabled: isLoading,
                    loading: isLoading && 'Saving',
                    form: 'addEligibilityForm'
                }
            ]}
        />
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
