/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import React, { useId } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as Theme from '../../theme/theme'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Button } from '../button'
import { Loader } from '../loader'

/**********************************************************************************************************
 *   STYLE
 **********************************************************************************************************/
const Box = {
    Wrapper: styled.div`
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 16px;
        min-width: 280px;
        height: min-content;
        ${Theme.Functions.setFont('Inter', 400)};
        background-color: ${(props) => props.theme[`background--000`]};
        border-radius: 16px;
        box-shadow: 0 1px 3px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--100`], `0.1`)},
            0 1px 2px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--100`], `0.06`)};
    `,
    Footer: styled.footer`
        width: 100%;
    `,
    Action: styled.footer`
        position: relative;
        display: inline-flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        padding: 25px;
        height: 100%;
        width: 100%;
        background-color: ${(props) => props.theme[`background--100`]};
        border-radius: 0 0 16px 16px;
    `,
    Row: styled.div`
        position: relative;
        display: inline-flex;
        flex-direction: row;
    `,
    Loader: styled(Loader.Basic)`
        margin: 16px auto 48px;
    `
}

const Card = {
    Row: styled.div`
        display: inline-flex;
        flex-wrap: wrap;
        width: 100%;
        min-width: 280px;

        &:last-of-type {
            border-radius: 0 0 16px 16px;
            overflow: hidden;
        }
    `,
    Column: {
        Base: styled.div`
            display: inline-flex;
            flex: 1 1 0;
            background-color: ${(props) => props.theme[`background--000`]};
            min-width: 280px;
            padding: 18px 24px;
            box-shadow: 1px 0 0 0 ${(props) => props.theme[`gray--100`]}, 0 1px 0 0 ${(props) => props.theme[`gray--100`]},
                1px 1px 0 0 ${(props) => props.theme[`gray--100`]}, 1px 0 0 0 ${(props) => props.theme[`gray--100`]} inset,
                0 1px 0 0 ${(props) => props.theme[`gray--100`]} inset;

            &:first-of-type {
                box-shadow: 1px 0 0 0 ${(props) => props.theme[`gray--100`]}, 0 1px 0 0 ${(props) => props.theme[`gray--100`]},
                    1px 1px 0 0 ${(props) => props.theme[`gray--100`]}, 0 0 0 0 ${(props) => props.theme[`gray--100`]} inset,
                    0 1px 0 0 ${(props) => props.theme[`gray--100`]} inset;
            }

            ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-sm`])} {
                padding: 10px 15px;
                flex-direction: column;
            }
        `,
        Render: styled.div`
            display: inline-flex;
            align-items: center;
            width: 100%;
            color: ${(props) => props.theme[`text--300`]};
            font-size: 1.4rem;
            letter-spacing: -0.25px;
            ${Theme.Functions.setFont('Inter', 500)};
        `,
        Title: styled.div`
            display: inline-flex;
            align-items: center;
            height: 100%;
            min-width: 130px;
            margin-right: 10px;
            color: ${(props) => props.theme[`text--100`]};
            font-size: 1.4rem;
            letter-spacing: -0.25px;
            ${Theme.Functions.setFont('Inter', 600)};

            ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-sm`])} {
                margin-right: 15px;
                min-width: 100%;
            }
        `
    }
}

const Header = {
    Wrapper: styled.header`
        position: relative;
        display: inline-flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        padding: 25px;
    `,
    Title: styled.h2`
        position: relative;
        display: inline-flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
    `,
    Description: styled.p`
        position: relative;
        display: inline-flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        color: ${(props) => props.theme[`text--100`]};
    `,
    Attention: styled.div`
        display: flex;
        align-items: center;
        width: 100%;
        height: 38px;
        padding: 0 25px;
        font-size: 1.2rem;
        border-radius: 16px 16px 0 0;
        ${Theme.Functions.setFont('Inter', 500)};

        &.primary {
            color: ${(props) => props.theme[`primary--100`]};
            background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`primary--100`], `0.1`)};
        }
    `
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const BoxComponent = ({ children, loading, className, header, footer, grid, desc, action }) => {
    const validateGrid = () => grid?.length > 0
    const validateLoading = () => !!loading

    function renderGrid() {
        return grid.map((row) => {
            return (
                <Card.Row key={useId()}>
                    {row.map((column) => {
                        return (
                            <Card.Column.Base key={useId()}>
                                <Card.Column.Title>{column.title}</Card.Column.Title>
                                <Card.Column.Render>{column.render}</Card.Column.Render>
                            </Card.Column.Base>
                        )
                    })}
                </Card.Row>
            )
        })
    }

    function renderChildren() {
        switch (true) {
            case validateLoading():
                return <Box.Loader message={loading?.length > 0 ? loading : 'Loading...'} />
            case validateGrid():
                return renderGrid()

            default:
                return children
        }
    }

    return (
        <Box.Wrapper className={`${className ? ` ${className}` : ``}`}>
            {header && (
                <>
                    {header.attn ? <Header.Attention className={header.attn.color}>{header.attn.message}</Header.Attention> : ``}
                    <Header.Wrapper>
                        <Box.Row>
                            {header.title ? <Header.Title>{header.title}</Header.Title> : ``}
                            {header.element ? header.element : ``}
                        </Box.Row>
                        {desc && <Header.Description>{desc}</Header.Description>}
                    </Header.Wrapper>
                </>
            )}
            {renderChildren()}
            {footer ? <Box.Footer>{footer}</Box.Footer> : ''}
            {action && (
                <Box.Action>
                    {action ? (
                        <Button color={action.color ? action.color : 'flat'} onClick={action.function}>
                            {action.label}
                        </Button>
                    ) : (
                        ``
                    )}
                </Box.Action>
            )}
        </Box.Wrapper>
    )
}

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
BoxComponent.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    header: PropTypes.shape({
        title: PropTypes.string,
        element: PropTypes.element
    }),
    desc: PropTypes.string,
    footer: PropTypes.element,
    action: PropTypes.shape({
        label: PropTypes.string,
        color: PropTypes.string,
        func: PropTypes.func
    })
}

export { BoxComponent as Box }
