/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as Theme from '../../theme/theme'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import {
    ArrowRightIcon,
    ArrowRightOnRectangleIcon,
    DocumentMagnifyingGlassIcon,
    LockClosedIcon,
    NoSymbolIcon,
    ServerIcon
} from '@heroicons/react/24/outline'
import { Anchor } from '../anchor'

/**********************************************************************************************************
 *   STYLE
 **********************************************************************************************************/
const StyledStatusPage = styled.div`
    display: flex;
    flex-direction: column;

    > .container {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
        max-width: 1280px;
        width: 100%;
        margin: 0 auto;
        padding: 0 16px;

        > .icon {
            display: flex;
            justify-content: center;
            flex-shrink: 0;
            color: ${(props) => props.theme[`primary--100`]};

            &.custom {
                color: ${(props) => props.theme[`icon--300`]};
            }
        }

        > .info {
            padding-top: 8px;
            padding-bottom: 64px;
            text-align: center;

            > .link {
                display: flex;
                justify-content: center;
                margin-top: 16px;
            }

            > .code {
                text-transform: uppercase;
                letter-spacing: 0.025;
                color: ${(props) => props.theme[`primary--100`]};
                ${Theme.Functions.setFont('Inter', 600)};

                &.custom {
                    text-transform: uppercase;
                    letter-spacing: 0.025;
                    font-size: 1.2rem;
                    color: ${(props) => props.theme[`text--200`]};
                    ${Theme.Functions.setFont('Inter', 600)};
                }
            }

            > .title {
                margin-top: 8px;
                font-size: 38px;
                letter-spacing: -0.025;
                color: ${(props) => props.theme[`text--300`]};
                text-shadow: 1.7px 1.7px 1.4px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--100`], `0.03`)},
                    4px 4px 3.3px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--100`], `0.022`)},
                    7.5px 7.5px 6.3px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--100`], `0.018`)},
                    13.4px 13.4px 11.2px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--100`], `0.015`)},
                    25.1px 25.1px 20.9px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--100`], `0.012`)},
                    60px 60px 50px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--100`], `0.008`)};
                ${Theme.Functions.setFont('Inter', 800)};
            }

            > .desc {
                margin-top: 8px;
                color: ${(props) => props.theme[`text--100`]};
                ${Theme.Functions.setFont('Inter', 400)};

                &.custom {
                    margin-top: 0;
                }
            }
        }
    }
`

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Status = ({ status, back, custom }) => {
    const [content, setContent] = useState({
        status: undefined,
        title: undefined,
        desc: undefined
    })

    useEffect(() => {
        if (!content.status) {
            switch (status) {
                case 301:
                    setContent({
                        icon: <ArrowRightOnRectangleIcon width={`48px`} />,
                        status: `301 Moved`,
                        title: `Moved Permanently.`,
                        desc: `The resource you have requested has been permanently moved.`
                    })
                    break

                case 401:
                    setContent({
                        icon: <LockClosedIcon width={`48px`} />,
                        status: `401 Error`,
                        title: `Unauthorized.`,
                        desc: `Sorry, you do not have valid authorisation for the target resource.`
                    })
                    break

                case 403:
                    setContent({
                        icon: <NoSymbolIcon width={`48px`} />,
                        status: `403 Error`,
                        title: `Forbidden.`,
                        desc: `Sorry, you do not have permission to access this resource.`
                    })
                    break

                case 500:
                    setContent({
                        icon: <ServerIcon width={`48px`} />,
                        status: `500 Server Error`,
                        title: `Internal Server Error.`,
                        desc: `It looks like something went wrong and we've run into an unexpected error. Please try again later or contact support.`
                    })
                    break

                case 'custom':
                    setContent({
                        icon: custom.icon,
                        status: custom.status,
                        title: custom.title,
                        desc: custom.desc
                    })
                    break

                case 404:
                default:
                    setContent({
                        icon: <DocumentMagnifyingGlassIcon width={`48px`} />,
                        status: `404 Error`,
                        title: `Page not found.`,
                        desc: <>Sorry, we couldn&apos;t find the page you&apos;re looking for.</>
                    })
                    break
            }
        }
    }, [content])

    function renderCTA() {
        if (custom?.cta) {
            return (
                <Anchor color={`select`} icon={<ArrowRightIcon />} type={`onClick`} onClick={custom.cta.onClick}>
                    {custom.cta.title}
                </Anchor>
            )
        }

        return (
            <Anchor color={`primary`} type={`onClick`} onClick={back}>
                Go Back <span aria-hidden='true'> &rarr;</span>
            </Anchor>
        )
    }

    return (
        <StyledStatusPage>
            <main className={`container`}>
                {content.icon && <div className={`icon ${status === 'custom' ? 'custom' : ''}`}>{content.icon}</div>}
                <div className={`info`}>
                    {content.status && <p className={`code ${status === 'custom' ? 'custom' : ''}`}>{content.status}</p>}
                    {content.title && <h1 className={`title`}>{content.title}</h1>}
                    {content.desc && <p className={`desc ${status === 'custom' ? 'custom' : ''}`}>{content.desc}</p>}
                    <div className={`link`}>{renderCTA()}</div>
                </div>
            </main>
        </StyledStatusPage>
    )
}

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
Status.propTypes = {
    status: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.element]),
    back: PropTypes.func
}
