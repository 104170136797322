/* eslint-disable indent */
/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useFormikContext } from 'formik'
import { useState } from 'react'

/**********************************************************************************************************
 *   SHARED COMPONENTS
 **********************************************************************************************************/
import { Button } from '../../button'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { RenderInputLabel } from '../util'
import { Input } from './input'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled, { css } from 'styled-components'

const Toggle = {
    Base: styled.div`
        display: inline-flex;
        flex-flow: row wrap;
        gap: 10px;
    `,
    Button: styled(Button)`
        flex: 0 1 0;
        width: fit-content;
        margin: 0;

        ${(props) =>
            props.color === 'primary' &&
            css`
                border: 1px solid transparent;
            `}
    `
}

/**********************************************************************************************************
 *   RENDER Toggle FIELD
 **********************************************************************************************************/
const ToggleComponent = (props) => {
    const { disabled, field, label, required, embedded } = props
    const [active, setActive] = useState(field.value || false)
    const { isSubmitting, setFieldValue } = useFormikContext()

    return (
        <Input.Wrapper className={props.className} embedded={embedded}>
            {RenderInputLabel(embedded, required, label, field)}
            <Toggle.Base>
                <input type={'hidden'} {...field} value={field.value} disabled={isSubmitting || disabled} />
                <Toggle.Button
                    type={'button'}
                    color={active ? 'primary' : 'secondary'}
                    onClick={() => {
                        if (active) return
                        setFieldValue(field.name, !active)
                        setActive(!active)
                    }}
                    disabled={isSubmitting || disabled}
                >
                    {props.options[0]}
                </Toggle.Button>
                <Toggle.Button
                    type={'button'}
                    color={!active ? 'primary' : 'secondary'}
                    onClick={() => {
                        if (!active) return
                        setFieldValue(field.name, !active)
                        setActive(!active)
                    }}
                    disabled={isSubmitting || disabled}
                >
                    {props.options[1]}
                </Toggle.Button>
            </Toggle.Base>
        </Input.Wrapper>
    )
}

ToggleComponent.defaultProps = {
    options: ['Enabled', 'Disabled']
}

export { ToggleComponent as ToggleField }
