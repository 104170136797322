/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import type { TypedAddListener, TypedStartListening } from '@reduxjs/toolkit'
import { addListener, createListenerMiddleware } from '@reduxjs/toolkit'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/

/**********************************************************************************************************
 *   HELPER IMPORTS
 **********************************************************************************************************/
import { AppDispatch, RootState } from 'store/store'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE EXPORTS
 **********************************************************************************************************/
export const shopListenerMiddleware = createListenerMiddleware()

export type AppStartListening = TypedStartListening<RootState, AppDispatch>

export const startAppListening = shopListenerMiddleware.startListening as AppStartListening

export const addAppListener = addListener as TypedAddListener<RootState, AppDispatch>
