import { Flex } from 'components/utilities/Flex'
import { Theme } from 'nxui/src'
import styled, { css } from 'styled-components'

const ToggleBox = styled.div`
    position: relative;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    user-select: none;
    flex-shrink: 0;
    appearance: none;
    height: 16px;
    width: 16px;
    padding: 0px;
    text-align: start;
    overflow: hidden;
    border-radius: 4px;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    border: 1px solid rgb(209, 213, 219);
    background-color: ${(props) => props.theme[`background--100`]};
`

const InternalFlex = styled(Flex)<{ checked?: boolean; focused?: boolean }>`
    position: relative;
    cursor: pointer;
    padding: 15px 20px;
    border-radius: 8px;
    color: rgb(55, 65, 81);
    text-decoration: none;
    line-height: 1.5;
    white-space: normal;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    background-color: ${({ theme }) => theme[`background--100`]};
    width: 100%;
    border: 1px solid ${({ theme }) => theme[`background--100`]};

    /** Font */
    font-size: 1.2rem;
    font-style: normal;
    ${Theme.Functions.setFont('Inter', 500)};

    &:hover {
        background-color: ${(props) => props.theme[`background--200`]};
        ${ToggleBox} {
            background-color: ${(props) => props.theme[`background--200`]};
            background-image: url(https://cdn.nexigen.digital/nexigen/nxui/icons/solid/check.svg);
            background-repeat: no-repeat;
            background-size: 85% 85%;
            background-position: center;
        }
    }

    ${({ focused }) =>
        focused &&
        css`
            background-color: ${({ theme }) => theme['background--200']};
        `}

    ${({ checked }) =>
        checked &&
        css`
            ${ToggleBox} {
                border-color: ${(props) => props.theme[`primary--100`]};
                background-color: ${(props) => props.theme[`primary--100`]};
                background-image: url(https://cdn.nexigen.digital/nexigen/nxui/icons/solid/check.svg);
                background-repeat: no-repeat;
                background-size: 85% 85%;
                background-position: center;
            }

            &:hover ${ToggleBox} {
                border-color: ${(props) => props.theme[`primary--100`]};
                background-color: ${(props) => props.theme[`primary--100`]};
            }

            &:focus ${ToggleBox} {
                outline: none;
                border-color: ${(props) => props.theme[`primary--100`]};
                background-color: ${(props) => props.theme[`primary--100`]};
            }
        `}
`

const ChildrenWrapper = styled.span`
    width: 100%;
    word-break: break-word;
    user-select: none;
    text-align: left;
`

const Label = styled.label``

const Input = styled.input`
    appearance: none;
    position: absolute;
`

export const S = {
    ChildrenWrapper,
    ToggleBox,
    InternalFlex,
    Label,
    Input
}
