/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Theme } from 'nxui/src'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Bubble } from 'components/bubble'
import { Loader } from 'components/NXLoader'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { WebsiteStyles } from 'containers/website/style'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
export const CompetitivePricesStyles = {
    Banner: styled.div`
        ${WebsiteStyles.Helpers.Banner}
    `,

    Heading: styled.h2`
        ${WebsiteStyles.Helpers.Fonts.h2}
        text-align: center;
        margin-bottom: 40px;
    `,

    CompoundBubble: styled(Bubble.Compound)`
        width: 260px;
        min-width: 260px;

        ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-sm'])} {
            width: 100%;

            ${Bubble.Description} {
                font-size: 26px;
            }
        }
    `,

    Price: styled.p`
        font-size: 18px;
        display: flex;
        align-items: center;

        color: ${({ theme }) => theme['white--100']};

        ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-sm'])} {
            font-size: 15px;
        }

        ${Loader.Style.Base} {
            width: 30px;
            margin-top: 2px;
        }

        ${Loader.Style.SVG} {
            height: 16px;
            width: 16px;
        }
    `
}
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/
