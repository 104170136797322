/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Theme } from 'nxui/src'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Anchor } from 'components/buttons'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
export const FooterDetailStyles = {
    Anchor: styled(Anchor).attrs({ color: 'alternate' })`
        color: white;
        text-decoration: underline;
    `,
    Text: styled.span`
        font-size: 14px;
        font-weight: 400;
    `,
    Heading: styled.h3`
        margin-bottom: 10px;
        font-size: 20px;
    `,
    IconWrapper: styled.div`
        color: ${({ theme }) => Theme.Functions.hexToRGBA(theme['white--100'], 0.6)};
    `
}
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/
