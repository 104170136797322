/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import Yup from 'helpers/yup'
import { DateTime } from 'luxon'
import { useLocation } from 'react-router-dom'

/**********************************************************************************************************
 *   COMPONENT IMPORTS
 **********************************************************************************************************/
import { Anchor } from 'components/buttons'
import AuthenticationWizard from 'containers/authentication/authentication'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { websiteRoutes } from 'router/consts'
import { GuestRoute } from 'router/guestRouter.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function AuthLogin() {
    /***** HOOKS *****/
    const location = useLocation()
    const { provider_name, application_name, logo_url, website_url, url } = useAppSelector((state) => state.app.appSettings.provider)
    const { website } = useAppSelector((state) => state.app.appSettings.section)

    /***** RENDER HELPERS *****/
    const getWebsiteUrl = () => {
        try {
            return Yup.string().url().validateSync(website_url)
        } catch (e) {
            return url
        }
    }

    /***** RENDER *****/
    return (
        <GuestRoute.Content>
            <GuestRoute.Company>
                <GuestRoute.Logo>
                    <img width={'300'} src={logo_url} alt={application_name} />
                </GuestRoute.Logo>
                <GuestRoute.Title>{application_name}</GuestRoute.Title>
            </GuestRoute.Company>
            <AuthenticationWizard />
            <GuestRoute.Footer>
                {location.pathname === '/login' && (
                    <div>
                        <GuestRoute.Account>Don't have an account?</GuestRoute.Account>{' '}
                        <GuestRoute.Link to={'/login/signup'}>Sign up</GuestRoute.Link>
                    </div>
                )}
                <GuestRoute.Info>
                    <span>
                        &copy; {DateTime.local().year} - {provider_name}
                    </span>
                    <GuestRoute.SiteMap>
                        {website ? (
                            <Anchor.Link color='primary' to={websiteRoutes.ROOT}>
                                Website
                            </Anchor.Link>
                        ) : (
                            <GuestRoute.Anchor href={getWebsiteUrl()} target={'_blank'}>
                                Website
                            </GuestRoute.Anchor>
                        )}
                        &#183;
                        <GuestRoute.Link to={'/shop/purchase'}>Shop</GuestRoute.Link>
                    </GuestRoute.SiteMap>
                </GuestRoute.Info>
            </GuestRoute.Footer>
        </GuestRoute.Content>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
