/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import React, { useState, useEffect, useRef } from 'react'
import CurrencyInput, { formatValue } from 'react-currency-input-field'
import { useField } from 'formik'
import PropTypes from 'prop-types'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { RenderErrorIcon, Style, Element } from '../util'
import { Input } from './input'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled from 'styled-components'

const Currency = {
    Field: styled(CurrencyInput)`
        ${Style.Field}
    `
}

/**********************************************************************************************************
 *   INPUT FIELD
 **********************************************************************************************************/
export const CurrencyField = (props) => {
    const { className, label, name, prefix, allowNegativeValue, disabled } = props

    const [field, meta, helpers] = useField(props)

    /*   REF
     *****************************************************/
    const inputRef = useRef()

    /*   STATE
     *****************************************************/
    const [hasFocus, setFocus] = useState(false)
    const [values, setValues] = useState({
        float: Number(meta.initialValue || '0.00'),
        formatted: formatValue({
            value: meta.initialValue || '0.00',
            prefix: prefix
        }),
        value: meta.initialValue || '0.00'
    })

    /*   EFFECT
     *****************************************************/
    useEffect(() => {
        if (document.hasFocus() && inputRef.current?.contains(document.activeElement)) {
            setFocus(true)
        }
    }, [])

    // This handles when the value gets changed programmatically from within the application formik form, for example via "setFieldValue" helper
    useEffect(() => {
        setValues({
            float: Number(meta.value),
            formatted: formatValue({
                value: meta.value,
                prefix
            }),
            value: meta.value
        })
    }, [meta.value])

    function handleValueOnChange(value, _, values) {
        setValues(values)
        helpers.setValue(values.value || '')
    }

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Input.Wrapper className={className}>
            {label ? <Element.Label>{label}</Element.Label> : ''}
            <Input.Column focus={hasFocus} error={meta.touched && meta.error}>
                <Currency.Field
                    ref={inputRef}
                    id={name}
                    name={name}
                    disabled={disabled}
                    prefix={prefix}
                    allowNegativeValue={allowNegativeValue}
                    decimalScale={2}
                    onValueChange={handleValueOnChange}
                    value={values.value}
                    onFocus={() => setFocus(true)}
                    onBlur={(e) => {
                        field.onBlur && field.onBlur(e)
                        setFocus(false)
                        helpers.setTouched(true)
                    }}
                />
                {RenderErrorIcon(name, { [name]: meta.touched }, { [name]: meta.error })}
            </Input.Column>
        </Input.Wrapper>
    )
}

/**********************************************************************************************************
 *   PROPTYPES
 **********************************************************************************************************/
CurrencyField.propTypes = {
    /** ClassName to give the component */
    className: PropTypes.string,

    /** Can display a label similar to input fields */
    label: PropTypes.node,

    /** The name of the input field. This is essential so that "useField" can add the key value pair for "name" into the form values */
    name: PropTypes.string.isRequired,

    /** The currency prefix, eg. "$" */
    prefix: PropTypes.string.isRequired,

    /** Allow negative value */
    allowNegativeValue: PropTypes.bool,

    /** Whether or not the field should be disabled */
    disabled: PropTypes.bool
}
