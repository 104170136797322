/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Anchor, Badge, Card } from 'nxui/src'
import { Loader } from 'components/NXLoader'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import CancelLightbox from 'containers/services/lightbox/cancel/cancel.lightbox'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { serviceAPI, useDeleteCancelRequestMutation } from 'api/service'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function Cancel() {
    const { id } = useParams()
    const serviceId = Number(id)
    const { isLoading, isError, data } = serviceAPI.endpoints.pendingServiceCancellation.useQueryState(serviceId)
    const [deleteCancelRequest, { isLoading: isDeleteCancelRequestLoading }] = useDeleteCancelRequestMutation()
    const [lightboxStatus, setLightboxStatus] = useState(false)

    const columns = useMemo(() => {
        if (Array.isArray(data) && data.length === 0) {
            return [
                {
                    Header: 'Cancel',
                    accessor: 'cancel'
                },
                {
                    Header: '',
                    accessor: 'action',
                    align: 'right'
                }
            ]
        }

        return [
            {
                Header: 'Cancel',
                accessor: 'cancel'
            },
            {
                Header: '',
                accessor: 'status'
            },
            {
                Header: '',
                accessor: 'action',
                align: 'right'
            }
        ]
    }, [data])

    const security = useMemo(() => {
        if (isLoading || isError) return []

        if (Array.isArray(data) && data.length === 0) {
            return [
                {
                    cancel: 'Cancel Service',
                    action: (
                        <Anchor color={'primary'} onClick={() => setLightboxStatus(true)}>
                            Cancel
                        </Anchor>
                    )
                }
            ]
        }

        if (typeof data === 'object' && data !== null && !Array.isArray(data) && data?.completed) {
            return [
                {
                    cancel: 'Cancel Service',
                    status: <Badge color={'confirm'}>COMPLETED</Badge>
                }
            ]
        }

        return [
            {
                cancel: 'Cancel Service',
                status: <Badge color={'primary'}>IN PROGRESS</Badge>,
                action: isDeleteCancelRequestLoading ? (
                    <Loader />
                ) : (
                    <Anchor color={'primary'} onClick={() => deleteCancelRequest(serviceId)}>
                        Stop Cancellation
                    </Anchor>
                )
            }
        ]
    }, [data, isLoading, isError, isDeleteCancelRequestLoading])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <>
            <Card
                table={{
                    conditions: {
                        loading: isLoading && 'Checking existing cancellation request...',
                        sort: false
                    },
                    render: {
                        columns: () => columns,
                        data: () => security
                    }
                }}
            />
            <CancelLightbox status={lightboxStatus} close={() => setLightboxStatus(false)} />
        </>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
