/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Loader } from 'nxui/src'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useLightboxContext } from '../utilities'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { LightboxBodyStyle as S } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TLightboxBody = React.FC<{
    children: React.ReactNode
    loadingMessage?: React.ReactNode
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const LightboxBody: TLightboxBody = ({ children, loadingMessage }) => {
    /***** HOOKS *****/
    const { isLoading } = useLightboxContext()

    /***** RENDER *****/
    return (
        <S.Wrapper>
            <S.InnerWrapper>
                {isLoading && <Loader.Basic message={loadingMessage ?? 'Loading...'} />}
                {!isLoading && children}
            </S.InnerWrapper>
        </S.Wrapper>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
