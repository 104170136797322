/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Form, Formik } from 'formik'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { serviceAPI, useRenewServiceMutation } from 'api/service'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { defaultCurrency } from 'helpers/utils'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
interface Props {
    id: number
    close: () => void
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function RenewForm({ id, close }: Props) {
    const { data } = serviceAPI.endpoints.renewPrice.useQueryState(id)
    const [renewService] = useRenewServiceMutation({ fixedCacheKey: 'renew-service' })
    const renewList = data?.map(({ price, billing_cycle }) => ({
        value: billing_cycle.id,
        label: `${billing_cycle.name} - ${defaultCurrency(price)}`
    }))

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Formik
            initialValues={{
                billing_cycle_id: data ? data[0].billing_cycle.id : 0
            }}
            onSubmit={({ billing_cycle_id }) => {
                renewService({ id, billing_cycle_id })
                close()
            }}
        >
            {() => (
                <Form id={'renewForm'}>
                    <Field
                        label={'Renewal Billing Cycle'}
                        name={'billing_cycle_id'}
                        type={'select'}
                        list={renewList}
                        component={NXUIForm.SelectField}
                    />
                </Form>
            )}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
