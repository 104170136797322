/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import styled from 'styled-components'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import * as Theme from '../../theme/theme'
import { Button } from '../button'

/**********************************************************************************************************
 *   usePagination HOOK
 **********************************************************************************************************/
export const DOTS = '...'

function range(start, end) {
    const length = end - start + 1

    return Array.from({ length }, (_, index) => index + start)
}

export function usePagination({ totalCount, pageSize, siblingCount = 1, currentPage }) {
    return useMemo(() => {
        const totalPageCount = Math.ceil(totalCount / pageSize)
        const totalPageNumbers = siblingCount + 5

        /*
          Case 1:
          If the number of pages is less than the page numbers we want to show in our
          paginationComponent, we return the range [1..totalPageCount]
        */
        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount)
        }

        /*
            Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
        */
        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1)
        const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount)

        /*
          We do not show dots just when there is just one page number to be inserted between
          the extremes of sibling and the page limits i.e 1 and totalPageCount.
          Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
        */
        const shouldShowLeftDots = leftSiblingIndex > 2
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2

        const firstPageIndex = 1
        const lastPageIndex = totalPageCount

        /*
            Case 2: No left dots to show, but rights dots to be shown
        */
        if (!shouldShowLeftDots && shouldShowRightDots) {
            const leftItemCount = 3 + 2 * siblingCount
            const leftRange = range(1, leftItemCount)

            return [...leftRange, DOTS, totalPageCount]
        }

        /*
            Case 3: No right dots to show, but left dots to be shown
        */
        if (shouldShowLeftDots && !shouldShowRightDots) {
            const rightItemCount = 3 + 2 * siblingCount
            const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount)

            return [firstPageIndex, DOTS, ...rightRange]
        }

        /*
            Case 4: Both left and right dots to be shown
        */
        if (shouldShowLeftDots && shouldShowRightDots) {
            const middleRange = range(leftSiblingIndex, rightSiblingIndex)

            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
        }
    }, [totalCount, pageSize, siblingCount, currentPage])
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Pagination = ({ onPageChange, totalCount, siblingCount, currentPage, pageSize }) => {
    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    })

    if (currentPage === 0 || paginationRange.length < 2) {
        return null
    }

    const onNext = () => {
        onPageChange(currentPage + 1)
    }

    const onPrevious = () => {
        onPageChange(currentPage - 1)
    }

    const lastPage = paginationRange[paginationRange.length - 1]

    return (
        <Container>
            <MobilePagination>
                <Button type={'button'} disabled={currentPage === 1} onClick={onPrevious}>
                    Previous
                </Button>
                <Button type={'button'} disabled={currentPage === lastPage} onClick={onNext}>
                    Next
                </Button>
            </MobilePagination>
            <Result>
                <div>
                    <p>
                        Showing <span>{currentPage === 1 ? 1 : (currentPage - 1) * pageSize + 1}</span> to <span>{currentPage * pageSize}</span> of{' '}
                        <span>{totalCount}</span> results
                    </p>
                </div>
                <div>
                    <Navigation aria-label='Pagination'>
                        <Previous disabled={currentPage === 1} onClick={onPrevious}>
                            <ScreenReader>Previous</ScreenReader>
                            <ChevronLeftIcon className={'icon'} aria-hidden='true' />
                        </Previous>
                        {paginationRange.map((pageNumber, index) => {
                            if (pageNumber === DOTS) {
                                return <Dots key={index}>&#8230;</Dots>
                            }

                            if (pageNumber === currentPage) {
                                return (
                                    <Current key={index} onClick={() => onPageChange(pageNumber)}>
                                        {pageNumber}
                                    </Current>
                                )
                            }

                            return (
                                <Number key={index} onClick={() => onPageChange(pageNumber)}>
                                    {pageNumber}
                                </Number>
                            )
                        })}
                        <Next disabled={currentPage === lastPage} onClick={onNext}>
                            <ScreenReader>Next</ScreenReader>
                            <ChevronRightIcon className={'icon'} aria-hidden='true' />
                        </Next>
                    </Navigation>
                </div>
            </Result>
        </Container>
    )
}

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
const Container = styled.div`
    padding: 12px 16px;
    border-top-width: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${(props) => Theme.Functions.mediaBreakUp(props.theme['breakpoints-sm'])} {
        padding-left: 18px;
        padding-right: 18px;
    }
`

const MobilePagination = styled.div`
    display: flex;
    justify-content: space-between;
    flex: 1 1;

    ${(props) => Theme.Functions.mediaBreakUp(props.theme['breakpoints-sm'])} {
        display: none;
    }
`

const Result = styled.div`
    display: none;

    ${(props) => Theme.Functions.mediaBreakUp(props.theme['breakpoints-sm'])} {
        display: flex;
        flex: 1 1;
        justify-content: space-between;
        align-items: center;
    }

    > div {
        > p {
            font-size: 1.4rem;
            color: ${(props) => props.theme['text--200']};
            ${Theme.Functions.setFont('Inter', 400)};
        }
    }
`

const Navigation = styled.nav`
    --tw-shadow: 0 1px 2px 0 #0000000d;
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    border-radius: 6px;
    display: inline-flex;
    z-index: 0;
    position: relative;

    > button {
        font-size: 14px;
        line-height: 20px;
        display: inline-flex;
        align-items: center;
        position: relative;
        ${Theme.Functions.setFont('Inter', 500)};

        &:not([hidden]) ~ :not([hidden]) {
            --tw-space-x-reverse: 0;
            margin-left: calc(-1px * (1 - var(--tw-space-x-reverse)));
            margin-right: calc(-1px * var(--tw-space-x-reverse));
        }
    }
`

const Previous = styled.button`
    background-color: ${(props) => props.theme['white--100']};
    color: ${(props) => props.theme['text--300']};
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 8px;
    border-width: 1px;
    transition: background-color 0.5s ease-out;

    &:hover {
        background-color: ${(props) => props.theme['background--200']};
    }

    &:disabled {
        cursor: default;
        opacity: 0.5;
    }

    .icon {
        width: 20px;
        height: 20px;
    }
`

const Next = styled.button`
    background-color: ${(props) => props.theme['white--100']};
    color: ${(props) => props.theme['text--300']};
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 8px;
    border-width: 1px;
    transition: background-color 0.5s ease-out;

    &:hover {
        background-color: ${(props) => props.theme['background--200']};
    }

    &:disabled {
        cursor: default;
        opacity: 0.5;
    }

    .icon {
        width: 20px;
        height: 20px;
    }
`

const Dots = styled.span`
    color: ${(props) => props.theme['text--300']};
    border: 1px solid ${(props) => props.theme['gray--100']};
    padding: 8px 16px;
`

const Current = styled.button`
    color: ${(props) => props.theme['primary--100']};
    background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme['gradient-primary--200'], '0.1')};
    padding: 8px 16px;
    border-width: 1px;
    z-index: 10;
`

const Number = styled.button`
    color: ${(props) => props.theme['text--300']};
    background-color: ${(props) => props.theme['white--100']};
    padding: 8px 16px;
    border-width: 1px;
    z-index: 10;
    transition: background-color 0.5s ease-out;

    &:hover {
        background-color: ${(props) => props.theme['background--200']};
    }
`

const ScreenReader = styled.span`
    clip: rect(0, 0, 0, 0);
    border-width: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
Pagination.propTypes = {
    className: PropTypes.string,
    onPageChange: PropTypes.func,
    totalCount: PropTypes.number,
    siblingCount: PropTypes.number,
    currentPage: PropTypes.number,
    pageSize: PropTypes.number
}

Pagination.defaultProps = {
    className: '',
    siblingCount: 1
}
