export const WEIGHT = {
    LIGHT: 300,
    REGULAR: 400,
    MEDIUM: 500,
    SEMI_BOLD: 600,
    BOLD: 700,
    EXTRA_BOLD: 800
}

export const HTTP_STATUS_CODE = {
    OK: 200,
    CREATED: 201,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    CONFLICT: 409,
    INTERNAL_SERVER_ERROR: 500
}

export const INVOICE_STATUS = {
    PAID: 'paid',
    UNPAID: 'unpaid',
    CANCELED: 'cancelled',
    REFUNDED: 'refunded',
    DRAFT: 'draft',
    FRAUD: 'fraud',
    DISPUTED: 'disputed'
}

export const SERVICE_STATUS = {
    ACTIVE: 'active',
    SUSPENDED: 'suspended',
    TERMINATED: 'terminated',
    PENDING: 'pending',
    CANCELLED: 'cancelled',
    EXPIRED: 'expired',
    PENDING_TRANSFER: 'pending transfer',
    GRACE: 'grace',
    REDEMPTION: 'redemption',
    TRANSFERRED_AWAY: 'transferred away',
    FRAUD: 'fraud'
}

export const DNS_MODE_TYPE = {
    DNS_HOSTING: 'DNS Hosting',
    CUSTOM_NAMESERVERS: 'Custom Nameservers',
    PARKED: 'Parked',
    UNKNOWN: 'Unknown'
}
