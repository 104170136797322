/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useState } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Dropdown } from '../dropdown'

/**********************************************************************************************************
 *   STYLE
 **********************************************************************************************************/
import { Tabs } from './tabs.styles'

/**********************************************************************************************************
 *   HOOKS
 **********************************************************************************************************/
import { useWindowSize } from '../../helpers/hooks'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const TabOptions = (props) => {
    /*   HOOK
     *****************************************************/
    const { breakpoint } = useWindowSize()

    function getTabOptions() {
        if (breakpoint === 'sm' || breakpoint === 'md') {
            return props.tabs.map((tab) => {
                return {
                    label: tab.label,
                    active: props.active.label === tab.label,
                    onClick: () => {
                        tab.location && tab.location()
                        props.setActive({ ...tab, id: tab.label })
                    },
                    key: tab.key || tab.label
                }
            })
        }

        return props.tabs.map((tab) => (
            <Tabs.Option
                id={`tab-${tab.label}`}
                key={tab.label}
                type='button'
                active={props.active?.label === tab.label}
                aria-current={props.active?.label === tab.label ? 'page' : undefined}
                onClick={() => {
                    tab.location && tab.location()
                    props.setActive({ ...tab, id: tab.label })
                }}
            >
                <div>
                    {tab.icon && (
                        <span>
                            <tab.icon />
                        </span>
                    )}
                    <span>{tab.label}</span>
                </div>
            </Tabs.Option>
        ))
    }

    return (
        <>
            {breakpoint === 'sm' || breakpoint === 'md' ? (
                <Tabs.Dropdown {...props} aria-label='select'>
                    <Dropdown options={getTabOptions()} />
                </Tabs.Dropdown>
            ) : (
                <Tabs.Nav {...props} as={'nav'} aria-label={'tabs'} direction={'horizontal'}>
                    {getTabOptions()}
                </Tabs.Nav>
            )}
        </>
    )
}

export const TabContent = ({ when, onLoad, scroll, height }) => {
    return <>{scroll ? <Tabs.Scroll style={{ maxHeight: `${height}` }} data-scroll={'tab-content'}></Tabs.Scroll> : when.render}</>
}

export const TabWrapper = (props) => {
    return <Tabs.Content {...props}>{props.children}</Tabs.Content>
}

export const Prefab = (props) => {
    /*   STATE
     *****************************************************/
    const [activeTab, setActiveTab] = useState(
        props.initial ? props.tabs.find((tab) => tab.label === props.initial) : props.tabs?.length > 0 ? props.tabs[0] : undefined
    )

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <TabWrapper {...props}>
            <TabOptions {...props} active={activeTab} setActive={setActiveTab} />
            <TabContent when={activeTab} scroll={props.scroll} height={props.height} />
        </TabWrapper>
    )
}
