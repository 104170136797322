/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { AnimatePresence } from 'framer-motion'
import { Arrow, useHover, useLayer } from 'react-laag'
import { useTheme } from 'styled-components'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Tooltip } from './tooltip.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const TooltipComponent = (props) => {
    const theme = useTheme()
    const [isOver, hoverProps] = useHover({ delayEnter: 100, delayLeave: 300 })
    const { triggerProps, layerProps, renderLayer, arrowProps } = useLayer({
        isOpen: isOver,
        overflowContainer: true,
        auto: true,
        placement: props.placement,
        triggerOffset: 8 // small gap between wrapped content and the tooltip
    })

    return (
        <>
            <Tooltip.Wrapper as={props.as} {...triggerProps} {...hoverProps}>
                {props.children}
            </Tooltip.Wrapper>
            {renderLayer(
                <AnimatePresence>
                    {isOver && (
                        <Tooltip.Content
                            type={props.type}
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.9 }}
                            transition={{ duration: 0.1 }}
                            {...layerProps}
                        >
                            {props.content}
                            <Arrow
                                {...arrowProps}
                                backgroundColor={theme['white--100']}
                                borderColor={theme['border--100']}
                                borderWidth={1}
                                size={6}
                            />
                        </Tooltip.Content>
                    )}
                </AnimatePresence>
            )}
        </>
    )
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
TooltipComponent.defaultProps = {
    as: 'div',
    className: undefined,
    placement: 'top-center',
    content: '',
    children: undefined
}

export { TooltipComponent as Tooltip }
