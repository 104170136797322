import { shopAPI } from 'api/shop'
import { domainProductGroup } from 'containers/shop/hooks/useDefaultProductGroup'
import { ParamEncoder } from 'helpers/functions/paramEncoder'
import { useAppParams } from 'helpers/hooks/useAppParams'
import type { IProductGroup } from 'models/shop/product'
import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SetActiveProductGroup = (name: string | IProductGroup, fallback?: boolean) => void
type UseShopActiveGroup = () => [IProductGroup, SetActiveProductGroup]

/**
 * Simple abstraction for managing the active shop tab based on the :groupId param. By default, this will use the query param `product-group`
 * to find the corresponding product group. If domains are enabled, it will fall back to the domains tab or the first product
 * group if not.
 */
export const useShopActiveGroup: UseShopActiveGroup = () => {
    /***** HOOKS *****/
    const { domains, products } = useAppSelector((state) => state.app.appSettings.section.shop)
    const { groupId } = useAppParams()
    const { pathname } = useLocation()
    const navigate = useNavigate()

    /***** QUERIES *****/
    const { activeProductGroup } = shopAPI.endpoints.productGroups.useQuery(undefined, {
        selectFromResult: ({ data }) => {
            const hashFilteredData = data?.find(({ name }) => ParamEncoder.encode(name) === groupId)
            const firstData = data?.at(0) ?? domainProductGroup
            const activeProductGroup = hashFilteredData ?? (!domains && products && data?.length ? firstData : domainProductGroup)

            return { activeProductGroup }
        }
    })

    /***** FUNCTIONS *****/
    // prettier-ignore
    const setActiveProductGroup = useCallback<SetActiveProductGroup>((groupOrName, fallback) => {
        const name = typeof groupOrName === 'string' ? groupOrName : groupOrName.name
        const fallbackNavigate = (name: string) => navigate(`/shop/${ParamEncoder.encode(name)}/purchase`);

        return groupId && !fallback
            ? navigate(pathname.replace(groupId, ParamEncoder.encode(name)))
            : fallbackNavigate(name)
    }, [pathname, groupId])

    /***** HOOK RESULTS *****/
    return [activeProductGroup, setActiveProductGroup]
}
