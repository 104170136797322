import { useShopActiveGroup } from 'containers/shop/hooks/useShopActiveGroup'
import { ParamEncoder } from 'helpers/functions/paramEncoder'
import { Navigate } from 'react-router-dom'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function GroupNavigate() {
    /***** HOOKS *****/
    const [{ name }] = useShopActiveGroup()

    /***** RENDER *****/
    return <Navigate to={`/shop/${ParamEncoder.encode(name)}/purchase`} replace />
}
