/* eslint-disable indent */
/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Popover as PopoverUI, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { Fragment } from 'react'
import styled, { createGlobalStyle, css } from 'styled-components'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
const GlobalComboboxStyle = createGlobalStyle`
    .popover {
        &__transition {
            &--enter {
                transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
                transition-duration: 200ms;
                transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
            }
            &--enterFrom {
                --transform-translate-y: 4px;
                opacity: 0;
            }
            &--enterTo {
                --transform-translate-y: 0;
                opacity: 1;
            }
            &--leave {
                transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
                transition-duration: 150ms;
                transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
            }
            &--leaveFrom {
                --transform-translate-y: 0;
                opacity: 1;

            }
            &--leaveTo {
                --transform-translate-y: 4px;
                opacity: 0;
            }
        }
    }
`

const Popover = {
    Wrapper: styled.div`
        max-width: 384px;
    `,
    Base: styled(PopoverUI)`
        position: relative;
    `,
    Button: styled(PopoverUI.Button)`
        display: inline-flex;
        gap: 6px;
        padding: 11.5px;
        font-size: 1.4rem;
        line-height: 1.5rem;
        font-weight: 500;
        align-items: center;
        border-radius: 6px;
        color: ${(props) => props.theme['text--200']};
        background-color: ${(props) => props.theme['white--100']};
        border: 1px solid ${(props) => props.theme['border--100']};

        &:hover {
            background-color: ${(props) => props.theme['background--100']};
        }

        &.active {
            color: ${(props) => props.theme['white--100']};
            background-color: ${(props) => props.theme['primary--100']};
            border: none;

            &:hover {
                background-color: ${(props) => props.theme['primary--200']};
            }
        }
    `,
    ChevronDownIcon: styled(ChevronDownIcon)`
        margin-left: 8px;
        transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
        transition-duration: 150ms;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        width: 20px;
        height: 20px;
    `,
    Panel: styled(PopoverUI.Panel)`
        position: absolute;
        font-size: 1.4rem;
        z-index: 10;
        padding-left: 16px;
        padding-right: 16px;
        margin-top: 12px;
        width: 100vw;
        max-width: 384px;
        display: flex;
        justify-content: flex-start;

        ${({ placement }) => {
            if (placement === 'right') {
                return css`
                    right: 0;
                    justify-content: flex-end;
                `
            }
        }}

        @media (min-width: 640px) {
            padding-left: 0;
            padding-right: 0;
        }

        @media (min-width: 1024px) {
            max-width: 768px;
        }
    `
}

/**********************************************************************************************************
 *   POPOVER FIELD
 **********************************************************************************************************/
export default function PopoverComponent({ children, text, withChevron = true, active = false, placement = 'left' }) {
    if (!text) return null

    return (
        <>
            <GlobalComboboxStyle />
            <Popover.Wrapper>
                <Popover.Base>
                    {() => (
                        <>
                            <Popover.Button className={`${active ? 'active' : ''}`}>
                                {text}
                                {withChevron && <Popover.ChevronDownIcon aria-hidden='true' />}
                            </Popover.Button>
                            <Transition
                                as={Fragment}
                                enter='popover__transition--enter'
                                enterFrom='popover__transition--enterFrom'
                                enterTo='popover__transition--enterTo'
                                leave='popover__transition--leave'
                                leaveFrom='popover__transition--leaveFrom'
                                leaveTo='popover__transition--leaveTo'
                            >
                                <Popover.Panel placement={placement}>{children}</Popover.Panel>
                            </Transition>
                        </>
                    )}
                </Popover.Base>
            </Popover.Wrapper>
        </>
    )
}

export { PopoverComponent as Popover }
