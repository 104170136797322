/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useFormik } from 'formik'
import htmr from 'htmr'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { WebsiteHero } from 'containers/website/components/Hero'
import { Footer } from 'containers/website/components/footer'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { regex } from 'helpers/utils'
import Yup from 'helpers/yup'

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { shopAPI } from 'api/shop'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { WebsiteDomainFeatures } from '../components/features'
import { WebsiteWhoisStyles as S } from './style'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Whois = () => {
    /***** QUERIES *****/
    const [mutate, { data }] = shopAPI.endpoints.whois.useMutation()

    /***** HOOKS *****/
    const { executeRecaptcha } = useGoogleReCaptcha()

    /***** FORM *****/
    const form = useFormik({
        initialValues: {
            domain: ''
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: Yup.object().shape({
            domain: Yup.string()
                .required('You must enter a domain name to search for.')
                .test('is-valid-domain', 'Please enter a valid domain name', (value) => {
                    return regex.domainWithExtension.test(value)
                })
        }),
        onSubmit: async ({ domain }) => {
            const token = (await executeRecaptcha?.()) ?? ''
            await mutate({ domain, token }).unwrap()
        }
    })

    /***** RENDER *****/
    return (
        <S.OuterFlex direction='column' growChild={[1, 1]} justify='space-between'>
            <div>
                <S.WebsiteHero
                    grow={!data}
                    title='WHOIS Domain Name Lookup'
                    subtitle='Search the Whois domain database and find out who owns a website.'
                >
                    <S.FormikProvider value={form}>
                        <form onSubmit={form.handleSubmit}>
                            <WebsiteHero.Search custom name='domain' placeholder='Search the WHOIS domain database' />
                        </form>
                    </S.FormikProvider>
                </S.WebsiteHero>
                <S.ResultWrapper>
                    <S.BannerInner $center>{!!data && <S.ResultInner>{htmr(data.replaceAll('\n', '<br />'))}</S.ResultInner>}</S.BannerInner>
                </S.ResultWrapper>
            </div>
            <div>
                <WebsiteDomainFeatures />
                <Footer />
            </div>
        </S.OuterFlex>
    )
}
