/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { MagnifyingGlassIcon, XCircleIcon } from '@heroicons/react/24/solid'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import styled from 'styled-components'
import * as Theme from '../../../theme/theme'
import { Input } from '../../form/elements/input'

/**********************************************************************************************************
 *   CUSTOM STYLES
 **********************************************************************************************************/
const SearchFilter = {
    Base: styled.div`
        position: relative;
        display: flex;
        border-radius: 6px;
        margin: 24px;
        width: 250px;
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-xl'])} {
            margin: 20px 0 20px 20px;
        }

        > div {
            min-width: 250px;
            max-width: 600px;
        }

        input {
            position: initial;
        }

        &:focus-within,
        &:hover {
            label {
                color: ${(props) => props.theme[`primary--100`]};
            }
        }
    `,
    SearchIcon: styled.label`
        display: flex;
        padding: 8px 12px 8px 12px;
        color: ${(props) => props.theme[`icon--100`]};
        transition: color 300ms ease-out;
    `,
    ClearIcon: styled.button`
        display: flex;
        padding: 8px 12px;
        color: ${(props) => props.theme[`icon--100`]};
    `
}

export const TableSearchFilter = ({ filter, setFilter }) => {
    function keyDownHandler(e) {
        if (e.code === 'Escape') {
            setFilter('')
        }
    }

    return (
        <SearchFilter.Base role={'search'}>
            <Input.Column>
                <SearchFilter.SearchIcon htmlFor={'search'}>
                    <MagnifyingGlassIcon width={'20px'} />
                </SearchFilter.SearchIcon>
                <Input.Base
                    id={'search'}
                    name={'search'}
                    typ={'search'}
                    placeholder={'Search...'}
                    value={filter || ''}
                    aria-autocomplete={'both'}
                    autoComplete={'off'}
                    autoCorrect={'off'}
                    autoCapitalize={'off'}
                    spellCheck={'false'}
                    enterKeyHint={'go'}
                    onKeyDown={keyDownHandler}
                    onChange={(e) => setFilter(e.target.value)}
                />
                {filter && (
                    <SearchFilter.ClearIcon type={'button'} onClick={() => setFilter('')}>
                        <XCircleIcon width={'20px'} />
                    </SearchFilter.ClearIcon>
                )}
            </Input.Column>
        </SearchFilter.Base>
    )
}
