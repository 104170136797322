/* eslint-disable indent */
/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { motion } from 'framer-motion'

/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import styled, { css } from 'styled-components'
import * as Theme from '../../theme/theme'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
export const Tooltip = {
    Wrapper: styled.div`
        cursor: default;
        width: fit-content;
    `,
    Content: styled(motion.div)`
        cursor: default;
        width: fit-content;
        max-width: 260px;
        padding: 10px;
        font-size: 1.2rem;
        color: ${(props) => props.theme[`text--300`]};
        border-radius: 8px;
        border: 1px solid ${(props) => props.theme[`border--100`]};
        ${(props) =>
            props.type &&
            css`
                border-left: 4px solid ${props.theme[`${props.type}--100`]};
            `}
        background-color: ${(props) => props.theme[`white--100`]};
        ${Theme.Functions.setFont('Inter', 700)};
    `
}
