/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { shopAPI } from '../../../api/shop'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Table } from 'nxui/src'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import { defaultCurrency } from 'helpers/utils'
import { IDomainPricing } from '../../../models/shop/shop'

type TPricingTable = React.FC<{
    filter: 'register' | 'transfer' | 'renew'
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const WebsiteDomainPricingTable: TPricingTable = ({ filter }) => {
    /***** QUERIES *****/
    const { data, isLoading } = shopAPI.endpoints.domainProducts.useQuery()

    /***** RENDER HELPERS *****/
    const getPrice = (pricing: Array<IDomainPricing>, interval: string) => {
        const rawPrice = pricing.find(({ type, billing_cycle }) => type === filter && billing_cycle.interval === interval)?.price
        return defaultCurrency(rawPrice ?? '0.00')
    }

    /***** RENDER *****/
    return (
        <Table
            conditions={{
                loading: isLoading
            }}
            render={{
                columns: () => [
                    {
                        Header: 'DOMAINS',
                        accessor: 'name'
                    },
                    {
                        Header: '1 YEAR',
                        accessor: 'P1Y'
                    },
                    {
                        Header: '2 YEARS',
                        accessor: 'P2Y'
                    },
                    {
                        Header: '3 YEARS',
                        accessor: 'P3Y'
                    },
                    {
                        Header: '4 YEARS',
                        accessor: 'P4Y'
                    }
                ],
                data: () =>
                    data?.map(({ name, pricing }) => ({
                        name,
                        P1Y: getPrice(pricing, 'P1Y'),
                        P2Y: getPrice(pricing, 'P2Y'),
                        P3Y: getPrice(pricing, 'P3Y'),
                        P4Y: getPrice(pricing, 'P4Y')
                    })) ?? []
            }}
        />
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
