/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'

import styled, { createGlobalStyle } from 'styled-components'
import * as Theme from '../../../theme/theme'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Button, ButtonGroup } from '../../button'
import { Input } from '../../form/elements/input'

/**********************************************************************************************************
 *   STYLE
 **********************************************************************************************************/
const GlobalPaginationStyle = createGlobalStyle`
     .nxPagination {
         &__previous {
             padding: 0 8px;
         };

         &__number {
             padding: 0 8px;
         };

         &__next {
             padding: 0 8px;
         };

         &__icon {
             height: 20px;
             width: 20px;
         }
     }
 `

const Pagination = {
    Base: styled.div`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 65px;
        padding: 12px 16px;
        border-top: 1px solid ${(props) => props.theme[`border--000`]};
    `,
    Stats: styled.div`
        width: 100%;

        > p {
            font-size: 1.4rem;
            color: ${(props) => props.theme[`text--100`]};
            ${Theme.Functions.setFont('Inter', 500)};
        }

        > p > span {
            color: ${(props) => props.theme[`text--200`]};
            ${Theme.Functions.setFont('Inter', 600)};
        }

        ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-sm`])} {
            > p {
                font-size: 1.4rem;
                color: ${(props) => props.theme[`text--200`]};
            }
        }
    `,
    Mobile: styled.div`
        display: none;
        flex: 1 1 0%;
        justify-content: flex-end;

        > button:last-of-type {
            margin-left: 6px;
        }

        ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-sm`])} {
            display: flex;
        }
    `,
    Nav: {
        Base: styled.nav`
            position: relative;
            display: flex;
            align-items: center;

            ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-sm`])} {
                display: none;
                flex: 1 1 0%;
            }
        `,
        Label: styled.span`
            > span {
                ${Theme.Functions.srOnly()};
            }
        `,
        Direct: styled(Input.Base)`
            width: 112px;
            margin-right: 8px;
        `
    }
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const PaginationComponent = ({ nextPage, canNextPage, previousPage, canPreviousPage, gotoPage, pageIndex, totalCount }) => {
    return (
        <Pagination.Base>
            <GlobalPaginationStyle />
            <Pagination.Stats>
                {pageIndex > 0 && (
                    <p>
                        Page <span>{pageIndex}</span> of <span>{totalCount}</span>{' '}
                    </p>
                )}
            </Pagination.Stats>

            <Pagination.Mobile>
                <Button type={'button'} color={`secondary`} disabled={!canPreviousPage} onClick={() => previousPage()}>
                    Previous
                </Button>

                <Button type={'button'} color={`secondary`} disabled={!canNextPage} onClick={() => nextPage()}>
                    Next
                </Button>
            </Pagination.Mobile>

            <Pagination.Nav.Base aria-label='Pagination'>
                <Pagination.Nav.Direct
                    name={`goTo`}
                    placeholder={`Go to page...`}
                    onChange={(e) => {
                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                        gotoPage(page)
                    }}
                />
                <ButtonGroup
                    color={`secondary`}
                    list={[
                        {
                            type: 'button',
                            className: 'nxPagination__previous',
                            disabled: !canPreviousPage,
                            label: (
                                <Pagination.Nav.Label>
                                    <span>Previous Page</span>
                                    <ChevronLeftIcon className={'nxPagination__icon'} aria-hidden='true' />
                                </Pagination.Nav.Label>
                            ),
                            onClick: () => previousPage()
                        },
                        {
                            type: 'button',
                            className: 'nxPagination__next',
                            disabled: !canNextPage,
                            label: (
                                <Pagination.Nav.Label>
                                    <span>Next Page</span>
                                    <ChevronRightIcon className={'nxPagination__icon'} aria-hidden='true' />
                                </Pagination.Nav.Label>
                            ),
                            onClick: () => nextPage()
                        }
                    ]}
                />
            </Pagination.Nav.Base>
        </Pagination.Base>
    )
}

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
PaginationComponent.propTypes = {
    className: PropTypes.string,
    onPageChange: PropTypes.func,
    totalCount: PropTypes.number,
    siblingCount: PropTypes.number,
    currentPage: PropTypes.number,
    pageSize: PropTypes.number
}

PaginationComponent.defaultProps = {
    className: '',
    siblingCount: 1
}

export { PaginationComponent as Pagination }
