/* eslint-disable no-prototype-builtins */
/* eslint-disable indent */
/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { ArrowDownIcon, ArrowUpIcon, DocumentMagnifyingGlassIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import styled, { createGlobalStyle } from 'styled-components'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import * as Theme from '../../theme/theme'
import { Loader } from '../loader'
import { Popover } from '../popover'

/**********************************************************************************************************
 *   FILTER IMPORTS
 **********************************************************************************************************/
import { Pagination } from './filters/pagination'
import { TableSearchFilter } from './filters/search'

/**********************************************************************************************************
 *   STYLE
 **********************************************************************************************************/
const TableGlobalStyle = createGlobalStyle`
     .nxTable {
         &__wrapper {
             display: flex;
             flex-direction: column;
             width: 100%;
             height: fit-content;

             ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
                 overflow: auto;
             }
         }

         &__actionCell {
             display: flex;
             justify-content: flex-end;
             align-items: center;
         };

         &__noHeader {
             :first-of-type {
                 border-top: 1px solid ${(props) => props.theme['border--100']};
             }
         };

         &__noEntries {
             > td > div {
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 width: 100%;
                 margin: 24px auto;

                 > svg {
                     margin-right: 4px;
                 }
             }
         }
     };
 `

const Table = {
    Base: styled.table`
        width: 100%;
        color: ${(props) => props.theme['text--100']};
    `,
    Head: styled.thead`
        width: 100%;

        tr {
            background-color: ${(props) => props.theme['background--100']};
            border-bottom: 1px solid ${(props) => props.theme['border--000']};
        }

        th {
            padding: 0 20px;
            height: 40px;
            text-align: left;
            font-size: 1.2rem;
            color: ${(props) => props.theme['text--100']};
            text-transform: uppercase;
            user-select: none;
            ${Theme.Functions.setFont('Inter', 600)};

            > span {
                display: flex;
                flex-direction: row;
                align-items: center;

                > span {
                    width: 14px;
                    margin-left: 5px;
                }
            }
        }
    `,
    Body: styled.tbody`
        tr {
            border: 1px solid ${(props) => props.theme['border--000']};
            border-bottom: none;
            background-color: ${(props) => props.theme['transparent']};

            :first-of-type {
                border-top: none;
            }

            &.nxTable__embedded {
                border-left: none;
                border-right: none;

                :last-of-type {
                    border-bottom: none;
                }
            }
        }

        td {
            padding: 0 20px;
            height: 55px;
            font-size: 1.4rem;
            ${Theme.Functions.setFont('Inter', 500)};
        }
    `,
    Container: styled.div`
        display: flex;
        flex-direction: column;
        width: 100%;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            overflow: auto;
        }
    `,
    Option: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,
    Filter: styled.div`
        margin: 24px 24px 24px 0;
        ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-xl`])} {
            margin: 20px 20px 20px 0;
        }
    `
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const TableComponent = ({ className, render, conditions, onSort, onSearch, onFilter, footer, extendedOptions }) => {
    conditions = {
        length: 30,
        loader: 'basic',
        loading: false,
        header: true,
        embedded: false,
        search: false,
        sort: true,
        pagination: false,
        filter: {
            show: false,
            hasFilter: false,
            counter: 0,
            children: null,
            text: <span>Filter</span>,
            withChevron: true,
            placement: 'left',
            active: false
        },
        ...conditions
    }
    const tableData = () => {
        if (!render.data) {
            return React.useMemo(() => [], [render.data, conditions.loading, conditions.loader])
        }

        if (conditions.loading && conditions.loader === 'skeleton') {
            return React.useMemo(() => Array(conditions.length).fill({}), [render.data, conditions.loading, conditions.loader])
        }

        return React.useMemo(render.data, [render.data, conditions.loading, conditions.loader])
    }
    const tableColumns = () => {
        if (conditions.loading && conditions.loader === 'skeleton') {
            return React.useMemo(() => {
                return render.columns().map((column) => ({
                    ...column,
                    Cell: <Loader.Table />
                }))
            }, [render.columns, conditions.loading, conditions.loader])
        }

        return React.useMemo(() => render.columns(), [render.columns, conditions.loading, conditions.loader])
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        canNextPage,
        previousPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
        state,
        setGlobalFilter
    } = useTable(
        {
            columns: tableColumns(),
            data: tableData(),
            manualPagination: !conditions.pagination,
            manualSortBy: Boolean(onSort),
            manualGlobalFilter: Boolean(onSearch),
            autoResetPage: !conditions.sort,
            autoResetSortBy: !conditions.sort,
            ...extendedOptions
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const { globalFilter, pageIndex, pageSize, sortBy } = state

    function renderEmptyState() {
        return (
            <tr
                className={`
                 nxTable__noEntries
                 ${conditions.embedded && 'nxTable__embedded'}
             `}
            >
                <td colSpan={headerGroups.length >= 1 ? headerGroups[0]?.headers.length + 1 : 1}>
                    <div>
                        <DocumentMagnifyingGlassIcon width={`42px`} />
                        <div>
                            <h3>{render.empty?.title ? render.empty.title : 'No Entries'}</h3>
                            <p>{render.empty?.description ? render.empty.description : 'There are no entries to display.'}</p>
                        </div>
                    </div>
                </td>
            </tr>
        )
    }

    function renderLoadingState() {
        return (
            <tr
                className={`
                 nxTable__noEntries
                 ${conditions.embedded && 'nxTable__embedded'}
             `}
            >
                <td colSpan={headerGroups[0]?.headers.length + 1}>
                    <div>
                        <Loader.Basic message={conditions.loading.length > 0 ? conditions.loading : 'Loading...'} />
                    </div>
                </td>
            </tr>
        )
    }

    useEffect(() => {
        if (onSort) {
            onSort(sortBy)
        }

        if (onSearch) {
            onSearch(globalFilter)
        }
    }, [onSort, sortBy, onSearch, globalFilter])

    return (
        <Table.Container>
            <Table.Option>
                {conditions?.search && <TableSearchFilter filter={globalFilter} setFilter={setGlobalFilter} />}
                {conditions?.filter.show && (
                    <Table.Filter>
                        <Popover
                            text={conditions?.filter.text}
                            withChevron={false}
                            active={conditions?.filter.active}
                            placement={conditions?.filter.placement ?? 'left'}
                        >
                            {conditions?.filter.children}
                        </Popover>
                    </Table.Filter>
                )}
            </Table.Option>
            <div className={`nxTable__wrapper`}>
                <Table.Base {...getTableProps()} className={`${className ? ` ${className}` : ``}`}>
                    {conditions?.header && (
                        <Table.Head>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps(conditions.sort && column.getSortByToggleProps())}>
                                            <span>
                                                {column.render('Header')}
                                                {conditions.sort && (
                                                    <span>
                                                        {column.isSorted ? (
                                                            column.isSortedDesc ? (
                                                                <ArrowDownIcon width={`14px`} />
                                                            ) : (
                                                                <ArrowUpIcon width={`14px`} />
                                                            )
                                                        ) : (
                                                            ''
                                                        )}
                                                    </span>
                                                )}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </Table.Head>
                    )}
                    <Table.Body {...getTableBodyProps()}>
                        {conditions.loading && conditions.loader === 'basic'
                            ? renderLoadingState()
                            : !page || page.length <= 0
                            ? renderEmptyState()
                            : page.map((row) => {
                                  prepareRow(row)
                                  return (
                                      <tr
                                          {...row.getRowProps()}
                                          className={`
                                                 ${!conditions.header && !conditions.embedded ? 'nxTable__noHeader' : ''}
                                                 ${conditions?.embedded ? 'nxTable__embedded' : ''}
                                                 ${row?.original?.hasOwnProperty(`class`) ? ` ${row.original.class}` : ''}
                                             `}
                                      >
                                          {row.cells.map((cell) => {
                                              return (
                                                  <td
                                                      {...cell.getCellProps()}
                                                      className={`${cell.column.id === 'action' ? 'nxTable__actionCell' : ''}`}
                                                  >
                                                      {cell.render('Cell')}
                                                  </td>
                                              )
                                          })}
                                      </tr>
                                  )
                              })}
                    </Table.Body>
                    {footer && footer}
                </Table.Base>
                {conditions.pagination && (
                    <Pagination
                        nextPage={nextPage}
                        canNextPage={canNextPage}
                        previousPage={previousPage}
                        canPreviousPage={canPreviousPage}
                        gotoPage={gotoPage}
                        pageIndex={pageIndex + 1}
                        totalCount={pageCount}
                    />
                )}
            </div>
            <TableGlobalStyle />
        </Table.Container>
    )
}

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
Table.propTypes = {
    className: PropTypes.string,
    conditions: PropTypes.shape({
        header: PropTypes.bool,
        embedded: PropTypes.bool,
        search: PropTypes.bool
    }),
    render: PropTypes.shape({
        columns: PropTypes.any,
        data: PropTypes.any
    }),
    footer: PropTypes.array
}

export { TableComponent as Table }
