/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useRef } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { RenderErrorIcon, RenderInputLabel, Style } from '../util'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled from 'styled-components'

export const Input = {
    Wrapper: styled.div`
        ${Style.Wrapper}
    `,
    Column: styled.div`
        ${Style.Column}
    `,
    Base: styled.input`
        ${Style.Field}

        ::-webkit-calendar-picker-indicator {
            display: none;
        }
    `,
    Label: styled.label`
        ${Style.Label}
    `
}

/**********************************************************************************************************
 *   INPUT FIELD
 **********************************************************************************************************/
export const InputField = ({ embedded, required, label, field, disabled, form: { touched, errors, isSubmitting }, ...props }) => {
    /*   REF
     *****************************************************/
    const inputRef = useRef()

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Input.Wrapper {...props} embedded={embedded}>
            {RenderInputLabel(embedded, required, label, field)}
            <Input.Column error={touched[field.name] && errors[field.name]}>
                <Input.Base
                    {...field}
                    {...props}
                    onBlur={(e) => {
                        field.onBlur && field.onBlur(e)
                    }}
                    ref={inputRef}
                    disabled={isSubmitting || disabled}
                />
                {RenderErrorIcon(field.name, touched, errors)}
            </Input.Column>
        </Input.Wrapper>
    )
}
