/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createContext, useContext } from 'react'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TLightboxContext = {
    isLoading?: boolean
    onClose: () => void
    uninitialized?: boolean
}

export const LightboxContext = createContext<TLightboxContext>({
    isLoading: undefined,
    onClose: () => void 0,
    uninitialized: true
})

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useLightboxContext = () => {
    /***** HOOKS *****/
    const context = useContext(LightboxContext)

    if (context.uninitialized) {
        throw new Error('useLightboxContext must be used within a LightboxProvider')
    }

    /***** RETURN *****/
    return context
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
