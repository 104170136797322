/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Dialog, Transition } from '@headlessui/react'
import styled, { createGlobalStyle } from 'styled-components'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Theme } from 'nxui/src'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
/**
 * Set of lightbox styles that should be applied globally so they can be applied to
 */
const GlobalLightboxStyle = createGlobalStyle`
    .nxLightbox {
        &__transition {
            &--enter {
                transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
                transition-duration: 300ms;
            }
            &--enterFrom {
                opacity: 0;
            }
            &--enterTo {
                opacity: 1;
            }
            &--leave {
                transition-timing-function: cubic-bezier(0, 0, 1, 1);
                transition-duration: 200ms;
            }
            &--leaveFrom {
                opacity: 1;
            }
            &--leaveTo {
                opacity: 0;
            }
        }
        
        &Content__transition {
            &--enter {
                transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
                transition-duration: 300ms;
            }
            &--enterFrom {
                opacity: 0;
                transform: translateY(16px) scale(0.95);
                ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
                    transform: translateY(0px);
                    transform: scale(0.95);
                }
            }
            &--enterTo {
                opacity: 1;
                transform: translateY(0px);
                ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
                    transform: scale(1);
                }
            }
            &--leave {
                transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
                transition-duration: 200ms;
            }
            &--leaveFrom {
                opacity: 1;
                transform: translateY(0px);
                ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
                    transform: scale(1);
                }
            }
            &--leaveTo {
                opacity: 0;
                transform: translateY(16px) scale(0.95);
                ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
                    transform: translateY(0px);
                    transform: scale(0.95);
                }
            }
        }
    }
`

const BaseStyles = {
    /**
     * Transition Root element from @HeadlessUI , this wraps the lightbox
     */
    TransitionRoot: styled(Transition.Root)`
        position: absolute;
        top: 0;
        width: 100vw;
        height: 100vh;
        place-content: center;
        z-index: 20;
        overflow: auto;
    `,

    /**
     * Dialogue element from @HeadlessUI , this is the actual lightbox
     */
    Dialogue: styled(Dialog)`
        position: fixed;
        z-index: 20;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow-y: auto;
    `,

    InternalWrapper: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        padding: 8px 8px;
        text-align: center;

        ${({ theme }) => Theme.Functions.mediaBreakUp(theme[`breakpoints-sm`])} {
            display: block;
            padding: 0;
        }
    `,

    TransitionChildInternal: styled.div`
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`background--200`], `0.9`)};
    `,

    /**
     * Hidden span element to trick the browser into centering the modal contents
     */
    HiddenSpan: styled.span.attrs({ ariaHidden: true })`
        visibility: hidden;

        ${({ theme }) => Theme.Functions.mediaBreakUp(theme[`breakpoints-sm`])} {
            display: inline-block;
            vertical-align: middle;
            height: 100vh;
        }
    `,

    /**
     *  Last Wrapper before children are rendered
     */
    ChildrenWrapper: styled(Dialog.Panel)`
        position: relative;
        display: inline-block;
        min-width: 320px;
        vertical-align: bottom;
        background-color: ${(props) => props.theme['background--100']};
        border-radius: 8px;
        text-align: left;
        overflow: hidden;
        box-shadow: 1.7px 1.7px 2.2px ${(props) => Theme.Functions.hexToRGBA(props.theme['black--200'], '0.07')},
            4px 4px 5.3px ${(props) => Theme.Functions.hexToRGBA(props.theme['black--200'], '0.05')},
            7.5px 7.5px 10px ${(props) => Theme.Functions.hexToRGBA(props.theme['black--200'], '0.042')},
            13.4px 13.4px 17.9px ${(props) => Theme.Functions.hexToRGBA(props.theme['black--200'], '0.035')},
            25.1px 25.1px 33.4px ${(props) => Theme.Functions.hexToRGBA(props.theme['black--200'], '0.028')},
            60px 60px 80px ${(props) => Theme.Functions.hexToRGBA(props.theme['black--200'], '0.02')};

        ${Theme.Functions.animation('all')};
        ${({ theme }) => Theme.Functions.mediaBreakUp(theme['breakpoints-sm'])} {
            max-width: 50vh;
            min-width: 500px;
            margin-top: 32px;
            margin-bottom: 32px;
            vertical-align: middle;
        }
    `
}
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/

/***** EXPORTS *****/
export const LightboxStyles = {
    GlobalLightboxStyle,
    ...BaseStyles
}
