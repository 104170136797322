/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { FieldStyles as S } from '../shared.style'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
const Wrapper = styled.div`
    ${S.Wrapper}
`
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/

/***** EXPORTS *****/
export const CheckboxFieldStyles = {
    Wrapper
}
