import { useAccountPaymentMethodsQuery, usePaymentMethodsQuery } from 'api/billing'
import { useCheckoutCartMutation, useDeleteCartMutation, useRemoveCartItemMutation, useUpdateCartItemsMutation } from 'api/shop'
import { Loader } from 'components/loader'
import CartItem from 'containers/shop/shop.items'
import { Summary } from 'containers/shop/shop.styles'
import { AnimatePresence } from 'framer-motion'
import { componentTransitionVariants, getCartTotals } from 'helpers/utils'
import { ICartSummary } from 'models/shop/shop.summary.d'
import { Anchor, Button, Tooltip } from 'nxui/src'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ShopSummary = (props: ICartSummary) => {
    /***** HOOKS *****/
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { cart, appSession } = useAppSelector((state) => state.app)

    /***** QUERIES *****/
    const [deleteCart, { isLoading: isDeleteCartLoading }] = useDeleteCartMutation()
    const [, { isLoading: isRemoveCartItemLoading }] = useRemoveCartItemMutation()
    const [, { isLoading: isCheckoutCartLoading }] = useCheckoutCartMutation({ fixedCacheKey: 'checkout-cart' })
    const [, { isLoading: isUpdateCartItemsLoading }] = useUpdateCartItemsMutation({ fixedCacheKey: 'update-cart-items' })

    useAccountPaymentMethodsQuery(undefined, { skip: !appSession.authenticated })
    usePaymentMethodsQuery(undefined, { skip: !appSession.authenticated })

    /***** RENDER HELPERS *****/
    function renderCheckoutButton() {
        if (cart?.items?.some((item) => item.pending) || isUpdateCartItemsLoading) {
            return (
                <Tooltip
                    as='span'
                    content='You have unconfigured items in the cart. Please configure all pending items before proceeding to checkout'
                >
                    <Summary.Cart.Disable type='button' color='flat'>
                        Checkout
                    </Summary.Cart.Disable>
                </Tooltip>
            )
        }

        return (
            <Button
                type={'button'}
                color={'primary'}
                loading={isCheckoutCartLoading && 'Checking out'}
                disabled={
                    !cart ||
                    !cart?.items ||
                    isCheckoutCartLoading ||
                    isRemoveCartItemLoading ||
                    (!appSession.authenticated && pathname === '/shop/landing')
                }
                onClick={async () => {
                    if (appSession.authenticated) {
                        navigate('/shop/checkout')
                    } else {
                        navigate('/shop/landing')
                    }
                }}
            >
                Checkout
            </Button>
        )
    }

    function renderCartItems() {
        if (!cart) return null

        if (isDeleteCartLoading) {
            return (
                <Summary.Cart.Empty.Base>
                    <Loader height={48} width={48} message={'Removing Cart Items...'} />
                </Summary.Cart.Empty.Base>
            )
        }

        return (
            <Summary.Cart.Base>
                <Summary.Cart.Title>Your Cart</Summary.Cart.Title>
                <ul>
                    <AnimatePresence initial={false} mode={'sync'}>
                        {cart.items?.map((item) => (
                            <CartItem key={item.item_uuid} item={item} />
                        ))}
                    </AnimatePresence>
                </ul>
            </Summary.Cart.Base>
        )
    }

    /***** RENDER *****/
    if (!cart || !Array.isArray(cart.items)) {
        return (
            <Summary.Container variants={componentTransitionVariants} initial={'initial'} animate={'animate'} exit={'exit'}>
                <Summary.Base>
                    <Summary.Cart.Empty.Base>
                        <Summary.Cart.Empty.Icon />
                        Your cart is empty.
                    </Summary.Cart.Empty.Base>
                </Summary.Base>
            </Summary.Container>
        )
    }

    return (
        <AnimatePresence mode={'wait'}>
            <Summary.Container variants={componentTransitionVariants} initial={'initial'} animate={'animate'} exit={'exit'}>
                <Summary.Base className={props.className}>
                    {renderCartItems()}
                    <Summary.Actions.Base>
                        <Summary.Subtotal>
                            <h2>Subtotal</h2>
                            <p>{getCartTotals('subtotal', cart)}</p>
                        </Summary.Subtotal>
                        <Summary.Discount>
                            <h2>Discount</h2>
                            <p>{getCartTotals('discount', cart)}</p>
                        </Summary.Discount>
                        <Summary.Total>
                            <h2>Total</h2>
                            <p>{getCartTotals('total', cart)}</p>
                        </Summary.Total>
                        <Summary.Description>Continue to checkout to use account credit or add any promotional discount codes.</Summary.Description>
                        <Summary.Actions.Submit>
                            <Anchor
                                color={'error'}
                                disabled={isUpdateCartItemsLoading || isDeleteCartLoading}
                                onClick={async () => {
                                    await deleteCart(cart.uuid)
                                    navigate('/shop/purchase')
                                }}
                            >
                                Clear Cart
                            </Anchor>
                            {renderCheckoutButton()}
                        </Summary.Actions.Submit>
                    </Summary.Actions.Base>
                </Summary.Base>
            </Summary.Container>
        </AnimatePresence>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
