/***** COMPOUND IMPORTS *****/
import { _LinkAnchor, _OnClickAnchor, _StandardAnchor, _SubmitAnchor } from './anchor'
import { _AnchorButton, _LinkButton, _StandardButton, _SubmitButton } from './button'

/***** COMPOUND COMPONENT *****/
export const Button = Object.assign(_StandardButton, {
    Link: _LinkButton,
    Anchor: _AnchorButton,
    Submit: _SubmitButton
})

/***** COMPOUND COMPONENT *****/
export const Anchor = Object.assign(_StandardAnchor, {
    Link: _LinkAnchor,
    Button: _OnClickAnchor,
    Submit: _SubmitAnchor
})
