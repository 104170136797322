/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Formik, FormikProps, useFormikContext } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { Button, Lightbox, Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { DNSMode } from 'containers/domains/domains.styles'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { domainAPI, useUpdateNameserversMutation } from 'api/domain'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setCustomNameservers, setDNSMode } from 'store/slices/domainSlice'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { snakeCase } from 'helpers/utils'
import { useAppDispatch, useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
import { DNSModeType } from 'models/enums'

interface DNSModeFormValues {
    dns_config: `${DNSModeType}`
    nameservers: Array<string>
}

type DNSModeFormProps = {
    actions?: Array<React.ReactElement>
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function DnsModeForm({ actions }: DNSModeFormProps) {
    const { id } = useParams()
    const domainId = Number(id)
    const dispatch = useAppDispatch()
    const { customNameservers } = useAppSelector((state) => state.domain)
    const dnsModeFormRef = useRef<FormikProps<DNSModeFormValues>>(null)
    const [updateNameservers] = useUpdateNameserversMutation({ fixedCacheKey: 'update-nameservers' })
    const { dnsMode } = useAppSelector((state) => state.domain)
    const { dns_mode } = domainAPI.endpoints.dnsRecords.useQueryState(domainId, {
        selectFromResult: ({ data }) => {
            const [, value] = data ? Object.entries(data?.dns_mode).flat() : ['', { features: [], label: '', value: 0 }]

            return {
                dns_mode: typeof value === 'object' ? (value.label as `${DNSModeType}`) : DNSModeType.DNSHosting
            }
        }
    })

    const renderAction = () => {
        // don't render button if in the process of switching to custom nameservers
        if (dns_mode !== DNSModeType.CustomNameservers && dnsMode === DNSModeType.CustomNameservers) return null

        if (actions?.length && actions.length > 0) return actions

        return null
    }

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Formik
            innerRef={dnsModeFormRef}
            enableReinitialize={true}
            initialValues={{
                dns_config: dns_mode,
                nameservers: customNameservers
            }}
            onSubmit={({ dns_config, nameservers }, { setSubmitting, resetForm }) => {
                if (dns_config === DNSModeType.CustomNameservers) {
                    updateNameservers({ id: domainId, dns_config: snakeCase(dns_config), nameservers })
                    dispatch(setCustomNameservers([]))
                } else {
                    const dnsConfig = dns_config === 'URL & Email Forwarding' ? 'Forwarding' : dns_config
                    updateNameservers({ id: domainId, dns_config: snakeCase(dnsConfig) })
                }

                setSubmitting(false)
                resetForm()
            }}
        >
            {() => (
                <DNSMode.Wrapper>
                    <DNSMode.Base id='dnsModeForm'>
                        <Field
                            label={'Mode'}
                            name={'dns_config'}
                            type={'select'}
                            list={Object.values(DNSModeType)
                                .filter((value) => value !== 'Unknown')
                                .map((value: string) => ({
                                    label: value,
                                    value
                                }))}
                            component={NXUIForm.SelectField}
                        />
                        <AutoSubmitDNSMode />
                    </DNSMode.Base>
                    {renderAction()}
                </DNSMode.Wrapper>
            )}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const AutoSubmitDNSMode = () => {
    const dispatch = useAppDispatch()
    const { dnsMode } = useAppSelector((state) => state.domain)
    const [lightboxStatus, setLightboxStatus] = useState(false)
    const { initialValues, values, setFieldValue } = useFormikContext<DNSModeFormValues>()
    const [targetedMode, setTargetedMode] = useState(dnsMode)

    function close() {
        dispatch(setDNSMode(initialValues.dns_config))
        setFieldValue('dns_config', initialValues.dns_config)
        setFieldValue('nameservers', [])
        setLightboxStatus(false)
    }

    useEffect(() => {
        const { dns_config } = values
        const { CustomNameservers } = DNSModeType

        if (dns_config !== initialValues.dns_config && dns_config !== CustomNameservers) {
            setLightboxStatus(true)
        }

        if (dns_config !== CustomNameservers && dnsMode === CustomNameservers && values.nameservers.length >= 2) {
            setLightboxStatus(true)
            setFieldValue('dns_config', dnsMode)
        }

        if (values.nameservers.length !== 2) {
            dispatch(setDNSMode(dns_config))
        }

        if (lightboxStatus) {
            setTargetedMode(dnsMode)
        }
    }, [values, lightboxStatus])

    return (
        <Lightbox
            title={'Current DNS settings will be overwritten'}
            className={'lightbox'}
            type={'confirm'}
            icon={'warning'}
            description={
                <DNSMode.Confirmation>
                    <span>
                        You are about to change DNS configuration from {initialValues.dns_config} to {targetedMode}, this will override any
                        preconfigured records you have set.
                    </span>
                    <DNSMode.Actions>
                        <Button type='button' onClick={close}>
                            No, Keep my current DNS settings
                        </Button>
                        <Button type='submit' form='dnsModeForm' color='primary'>
                            Set DNS
                        </Button>
                    </DNSMode.Actions>
                </DNSMode.Confirmation>
            }
            conditions={{
                state: lightboxStatus,
                action: close
            }}
        />
    )
}
