/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Form, Formik, FormikProps } from 'formik'
import { RefObject, useEffect } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useUpdateEPPCodeMutation } from 'api/domain'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
interface Props {
    id: number
    form: RefObject<FormikProps<{ epp: string }>>
    close: () => void
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function UpdateEPPCodeForm({ id, form, close }: Props) {
    const [updateEPPCode, { isLoading, isSuccess, isError }] = useUpdateEPPCodeMutation()

    useEffect(() => {
        if ((isSuccess || isError) && form.current) {
            form.current.setSubmitting(false)
            form.current.resetForm()
            close()
        }
    }, [form, isSuccess, isError])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Formik
            innerRef={form}
            initialValues={{
                epp: ''
            }}
            onSubmit={({ epp }) => {
                updateEPPCode({ id, epp })
            }}
        >
            {() => (
                <Form id={'updateEPPCodeForm'}>
                    <Field label={'EPP Code'} name={'epp'} disabled={isLoading} component={NXUIForm.InputField} />
                </Form>
            )}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
