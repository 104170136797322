/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useEffect, useRef, useState } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { RenderErrorIcon, RenderInputLabel, Style } from '../util'
import { Input } from './input'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled from 'styled-components'

export const Select = {
    Field: styled.select`
        ${Style.Field}
        appearance: none;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
        background-position: right 8px center;
        background-repeat: no-repeat;
        background-size: 24px 24px;
    `
}

/**********************************************************************************************************
 *   RENDER SELECT FIELD
 **********************************************************************************************************/
export const SelectField = ({
    className,
    embedded,
    required,
    id,
    name,
    label,
    field,
    disabled,
    list,
    defaultValue,
    form: { getFieldProps, touched, errors, isSubmitting },
    ...rest
}) => {
    /*   REF
     *****************************************************/
    const selectRef = useRef()

    /*   STATE
     *****************************************************/
    const [hasFocus, setFocus] = useState(false)

    /*   EFFECT
     *****************************************************/
    useEffect(() => {
        if (document.hasFocus() && selectRef.current.contains(document.activeElement)) {
            setFocus(true)
        }
    }, [])

    return (
        <Input.Wrapper className={className} embedded={embedded}>
            {RenderInputLabel(embedded, required, label, field)}
            <Input.Column focus={hasFocus} error={touched[field.name] && errors[field.name]}>
                <Select.Field
                    {...rest}
                    ref={selectRef}
                    id={id}
                    name={name}
                    defaultValue={defaultValue}
                    error={touched[field.name] && errors[field.name]}
                    disabled={isSubmitting || disabled}
                    {...getFieldProps(field.name)}
                    onFocus={() => setFocus(true)}
                    onBlur={(e) => {
                        field.onBlur && field.onBlur(e)
                        setFocus(false)
                    }}
                >
                    {list.map(({ label, value }, index) => (
                        <option key={`${field.name}-${index}`} value={value}>
                            {label}
                        </option>
                    ))}
                </Select.Field>
                {RenderErrorIcon(field.name, touched, errors)}
            </Input.Column>
        </Input.Wrapper>
    )
}
