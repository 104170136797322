import styled from 'styled-components'

export const _Style = {
    nameFields: {
        /** Styled Wrapper around the email field */
        emailFieldWrapper: styled.div`
            margin: 20px;
        `
    }
}
