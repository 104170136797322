/**********************************************************************************************************
 *   API
 **********************************************************************************************************/
import { baseApi } from 'api/base'
import { TContactUsRequest } from './types'

/**********************************************************************************************************
 *   ACCOUNT API
 **********************************************************************************************************/
export const supportAPI = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        supportRequest: builder.mutation<[], { from: string; attention: string; subject: string; body: string; attachments?: Array<string | Blob> }>({
            query: (body) => ({
                url: 'client/support',
                method: 'POST',
                body
            })
        }),

        contactUs: builder.mutation<[], TContactUsRequest>({
            query: (body) => ({
                url: 'client/contact-us',
                method: 'POST',
                body
            })
        })
    })
})

export const { useSupportRequestMutation } = supportAPI
