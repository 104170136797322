/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Table } from 'nxui/src'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
import { Service } from 'models/service'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
interface Props {
    server: Service['server']
}

export function MxRecords({ server }: Props) {
    const { mx_records } = server.additional_meta || {}

    const tableColumns = [
        {
            Header: 'MX Records',
            accessor: 'details'
        },
        {
            Header: '',
            accessor: 'value'
        },
        {
            Header: '',
            accessor: 'action',
            align: 'right'
        }
    ]

    function getTableData() {
        if (!mx_records) {
            return []
        }

        return Object.entries(mx_records).map(([key, value]) => {
            return {
                details: key,
                value,
                action: ''
            }
        })
    }

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Table
            render={{
                columns: () => tableColumns,
                data: () => getTableData()
            }}
        />
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
