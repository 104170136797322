/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useState } from 'react'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Anchor, Tooltip } from 'nxui/src'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import DomainContactLightbox from 'containers/domains/lightbox/domainContact.lightbox'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { domainAPI, useDomainContactsQuery } from 'api/domain'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Contact } from 'containers/domains/domains.styles'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { DomainContact, titleCase } from 'helpers/utils'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
import { Contact as ContactType } from 'models/account'
import { MetaPayload } from 'models/app'
import { DomainContactType } from 'models/domain'

interface Props {
    meta?: MetaPayload
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function Contacts({ meta = { page: '1' } }: Props) {
    const { id } = useParams()
    const domainId = Number(id)
    const [type, setType] = useState<DomainContactType>('registrant')
    const [currentContact, setCurrentContact] = useState(DomainContact)
    const [openLightbox, setOpenLightbox] = useState(false)
    const { domain } = domainAPI.endpoints.domainList.useQueryState(meta, {
        selectFromResult: ({ data, ...rest }) => ({
            ...rest,
            domain: data?.data.find((domainItem) => domainItem.id === domainId)
        })
    })
    const { contacts, isFetching } = useDomainContactsQuery(domainId, {
        selectFromResult: ({ data, ...rest }) => ({
            ...rest,
            contacts: !data
                ? []
                : Object.entries(data).flatMap((contact) => {
                      const [domainContactType, contactFields] = contact as [DomainContactType, ContactType]
                      return !contactFields
                          ? []
                          : {
                                contacts: `${titleCase(domainContactType)} Contact`,
                                name: (
                                    <Contact.Value>
                                        <p>{`${contactFields.firstname} ${contactFields.lastname}`}</p>
                                    </Contact.Value>
                                ),
                                email: (
                                    <Contact.Value>
                                        <p>{contactFields.email}</p>
                                    </Contact.Value>
                                ),
                                organisation: (
                                    <Contact.Value>
                                        <p>{contactFields.organisation}</p>
                                    </Contact.Value>
                                ),
                                action: domain?.meta.id_protection ? (
                                    <Tooltip content={`Please disable ID Protection to update your domain's contacts`} placement='left-center'>
                                        <Anchor color={'secondary'} disabled={true}>
                                            Edit
                                        </Anchor>
                                    </Tooltip>
                                ) : (
                                    <Anchor
                                        color={'primary'}
                                        onClick={() => {
                                            setType(domainContactType)
                                            setCurrentContact(contactFields)
                                            setOpenLightbox(true)
                                        }}
                                    >
                                        Edit
                                    </Anchor>
                                )
                            }
                  })
        })
    })

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <>
            <Contact.Base
                table={{
                    render: {
                        columns: () => [
                            {
                                Header: 'Contacts',
                                accessor: 'contacts'
                            },
                            {
                                accessor: 'name'
                            },
                            {
                                accessor: 'email'
                            },
                            {
                                accessor: 'company'
                            },
                            {
                                accessor: 'action',
                                align: 'right'
                            }
                        ],
                        data: () => contacts
                    },
                    conditions: {
                        loading: isFetching && 'Loading Domain Contacts...',
                        search: false,
                        pagination: false
                    }
                }}
            />
            <DomainContactLightbox type={type} contact={currentContact} status={openLightbox} close={() => setOpenLightbox(false)} />
        </>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
