/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { UserIcon } from '@heroicons/react/20/solid'
import { css } from 'styled-components'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { HeaderActionStyles as S } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SVGPropsExcludingRef = Omit<React.SVGProps<SVGSVGElement>, 'ref'>
type THeaderAction = React.FC<{
    icon?: React.ForwardRefExoticComponent<
        SVGPropsExcludingRef & {
            title?: string | undefined
            titleId?: string | undefined
        } & React.RefAttributes<SVGSVGElement>
    >
    intrinsic?: SVGPropsExcludingRef
    onClick?: () => void
    className?: string
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _HeaderAction: THeaderAction = ({ icon: Icon = UserIcon, intrinsic, className }) => {
    return (
        <S.Outer>
            <Icon {...intrinsic} className={className} />
        </S.Outer>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export const HeaderAction = Object.assign(_HeaderAction, {
    Raw: {
        GridPlacement: css`
            grid-column: 3;
            justify-self: flex-end;
        `
    }
})
