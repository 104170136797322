/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useEffect, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Lightbox } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { domainAPI } from 'api/domain'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { getRecordsWithPresetApplied, isConflictingRecord, sanitizeFields, sanitizeRecords } from './_addPreset.helpers'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
import type { TAction, TProps, TReducerState } from './types'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { DNSRecord } from 'models/domain'
import { ConfirmPreset } from './_confirmPreset'
import { PresetForm } from './_presetForm'
import { SelectPreset } from './_selectPreset'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const states = {
    CLOSED: 'closed',
    PRESETS: 'presets',
    PRESET_FORM: 'presetForm',
    CONFIRM_PRESET: 'confirmPreset'
} as const

export const actions = {
    CLOSE: 'close',
    OPEN: 'open',
    SELECT_PRESET: 'selectPreset',
    CONFIRM_PRESET: 'applyPreset',
    ACKNOWLEDGE: 'acknowledge'
} as const

const reducer = (state: TReducerState, action: TAction) => {
    switch (action.type) {
        case actions.OPEN:
            return { state: states.PRESETS }
        case actions.SELECT_PRESET: {
            return {
                ...state,
                state: states.PRESET_FORM,
                selectedPresetId: action.payload.presetId
            }
        }
        case actions.CONFIRM_PRESET: {
            if (state.state !== states.PRESET_FORM) return state

            return {
                ...state,
                state: states.CONFIRM_PRESET,
                input_fields: action.payload.fields
            }
        }
        case actions.CLOSE:
            return { state: states.CLOSED }
        default:
            return state
    }
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function AddPresetLightbox({ isOpen, onClose }: TProps) {
    /***** HOOKS *****/
    const { id } = useParams()
    const domainId = Number(id)
    const dispatch = useDispatch()

    /***** Queries *****/
    const { presets } = domainAPI.endpoints.dnsPresets.useQuery(domainId, {
        selectFromResult: ({ data, ...rest }) => ({
            ...rest,
            presets: data?.filter(({ is_active }) => is_active)
        })
    })

    const { data: domainData } = domainAPI.endpoints.dnsRecords.useQueryState(domainId)
    const { data: domain } = domainAPI.endpoints.domain.useQuery(domainId)
    const [addDNSRecords, { isLoading: isAddingDNS, isSuccess: dnsSuccess, isError: dnsError }] = domainAPI.endpoints.addDNSRecords.useMutation()
    const domainName = domain?.domain

    /***** State *****/
    const [state, dispatchState] = useReducer<React.Reducer<TReducerState, TAction>>(reducer, {
        state: isOpen ? states.PRESETS : states.CLOSED
    })

    /***** Functions *****/
    const getPresetByID = (id?: number) => presets?.find((preset) => preset.id === id)

    const closeLightbox = () => {
        onClose()
        dispatchState({ type: actions.CLOSE })
    }

    const acknowledgePreset = () => {
        if (state.state !== states.CONFIRM_PRESET) return

        const { input_fields, selectedPresetId } = state

        if (state.state !== states.CONFIRM_PRESET) return
        if (!input_fields || !domainData?.records || !selectedPresetId) return
        if (!domainName) return

        const preset = getPresetByID(selectedPresetId)

        /**
         * transform that removes conflicting records from the applied preset.
         *
         * This is run after applying the preset to ensure that any records added as part of the preset that would cause conflicts after merge fields have been applied are removed.
         */
        const removeRemainingConflictingRecords = (records: DNSRecord[]) => {
            //replace merge fields with values
            const recordsWithMergeFields = records.map((record) => {
                const hostname = Object.entries(input_fields).reduce((acc, [field, value]) => {
                    const regex = new RegExp(`{{${field}}}`, 'g')
                    return acc.replace(regex, value)
                }, record.hostname)

                const content = Object.entries(input_fields).reduce((acc, [field, value]) => {
                    const regex = new RegExp(`{{${field}}}`, 'g')
                    return acc.replace(regex, value)
                }, record.content)

                return {
                    ...record,
                    hostname,
                    content
                }
            })

            const filtered = recordsWithMergeFields.filter((record) => !isConflictingRecord(recordsWithMergeFields, record))

            //return values and remove isPreset from the getRecordsWithPresetApplied
            return filtered.map((record) => ({
                ...record,
                isPreset: undefined
            }))
        }

        const recordsWithPresetApplied = getRecordsWithPresetApplied({
            preset,
            records: domainData.records,
            transforms: [sanitizeRecords, removeRemainingConflictingRecords],
            domain: domainName
        })

        //invalidate cache to get back the merge fields (as this is not something we can determine on the frontend)
        addDNSRecords({
            id: domainId,
            records: recordsWithPresetApplied,
            replacements: input_fields
        })
            .then(() => dispatch(domainAPI.util.invalidateTags(['Records'])))
            .catch(() => void null)
    }

    /***** Effects *****/
    useEffect(() => {
        if (!isOpen) return closeLightbox()

        dispatchState({ type: actions.OPEN })
    }, [isOpen])

    useEffect(() => {
        if (dnsSuccess || dnsError) {
            closeLightbox()
        }
    }, [dnsSuccess, dnsError])

    /***** RENDER *****/
    return (
        <Lightbox
            type='custom'
            conditions={{ action: closeLightbox, state: isOpen }}
            content={
                <>
                    {/* Available Presets */}
                    <SelectPreset
                        isOpen={state.state === states.PRESETS}
                        onSelect={(id) => {
                            dispatchState({ type: actions.SELECT_PRESET, payload: { presetId: id } })
                        }}
                        onClose={closeLightbox}
                    />

                    {/* Preset Form */}
                    <PresetForm
                        isOpen={state.state === states.PRESET_FORM}
                        presetId={(() => {
                            if (state.state !== states.PRESET_FORM) return
                            return state.selectedPresetId
                        })()}
                        onConfirm={(fields) => {
                            dispatchState({
                                type: actions.CONFIRM_PRESET,
                                payload: { fields: sanitizeFields(fields) }
                            })
                        }}
                        onClose={closeLightbox}
                    />

                    {/* Confirm/Acknowledge Preset */}
                    <ConfirmPreset
                        isOpen={state.state === states.CONFIRM_PRESET}
                        loading={isAddingDNS}
                        onConfirm={acknowledgePreset}
                        onClose={closeLightbox}
                    />
                </>
            }
        />
    )
}
