/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useHeaderContext } from 'components/header/context'
import { useAppViewport } from 'helpers/hooks/useAppViewport'
import { Theme } from 'nxui/src'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/utilities/Flex'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export type THeaderNavItem = React.FC<{
    /**
     * The title of the Nav Item
     */
    children: React.ReactNode

    /**
     * The path that the Nav Item should link to (An when it should be active). This expects a string
     * that will be used as a regex to match the current path
     */
    path: string

    /**
     * A custom matcher to determine if the Nav Item should be active. This can be a string which will test
     * string.includes on the pathname, or a regex which will test regex.test on the pathname
     */
    matcher?: string | RegExp

    /**
     * Whether to use an exact string match or not
     */
    exact?: boolean

    /**
     * Whether the Nav Item should be permanently displayed or not
     */
    permanent?: boolean

    /**
     * Optional icon to be passed and rendered when present in the sidebar
     */
    mobileIcon?: React.FC<React.RefAttributes<SVGSVGElement> & React.HTMLProps<SVGSVGElement>>
}>

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
const StyledLink = styled(Link)<{
    $permanent?: boolean
    $active: boolean
    $sidebar?: boolean
}>`
    font-size: 18px;
    font-weight: 500;
    transition: all 0.2s ease-out;
    white-space: nowrap;
    gap: 10px;

    color: ${({ theme }) => theme['text--200']};
    display: ${({ $permanent }) => ($permanent ? 'flex' : 'none')};

    &:hover {
        color: ${({ theme }) => theme['primary--100']};
    }

    ${({ $active }) => {
        if ($active) {
            return css`
                display: flex;
                color: ${({ theme }) => theme['primary--100']};
            `
        }
    }}

    ${({ $sidebar }) => {
        if ($sidebar) {
            return css`
                font-size: 15px;
                border-radius: 8px;
                transition-property: all;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-duration: 150ms;
                cursor: pointer;
                width: 100%;
                padding: 10px;

                background-color: ${({ theme }) => theme['background--200']};
                color: ${({ theme }) => theme['text--200']};

                &:hover {
                    background-color: ${({ theme }) => theme['background--300']};
                }
            `
        }
    }}

    ${({ $sidebar, theme, $active }) => {
        if ($sidebar && $active) {
            return css`
                ${Theme.Functions.mediaBreakDown(theme['breakpoints-md'])} {
                    background-color: ${theme['background--300']};
                }
            `
        }
    }}

    svg {
        height: 20px;
        width: 20px;
    }
`

const StyledContainer = styled(Flex)<{ hovered: boolean; active: boolean; permanent?: boolean }>`
    transition: border 0.2s ease-out;
    border-top: 2px solid transparent;

    display: ${({ permanent }) => (permanent ? 'flex' : 'none')};

    ${({ active }) =>
        active &&
        css`
            display: flex;
        `}

    ${({ hovered, active }) =>
        (hovered || active) &&
        css`
            border-top: 2px solid ${({ theme }) => theme['primary--100']};
        `}
`
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _HeaderNavItem: THeaderNavItem = (props) => {
    const { children, path, permanent, matcher, exact, mobileIcon: Icon } = props

    /***** STATE *****/
    const [hovered, setHovered] = useState(false)

    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm', 'md'])
    const { pathname } = useLocation()
    const { useActiveTitleEffect, closeSidebar, inSidebar } = useHeaderContext()

    /***** RENDER HELPERS *****/
    const getActive = () => {
        if (typeof matcher === 'string') {
            if (!exact) {
                return pathname.includes(matcher)
            }
            return pathname === matcher
        }

        if (matcher instanceof RegExp) {
            return matcher.test(pathname)
        }

        if (exact) {
            return pathname === path
        }

        return pathname.includes(path)
    }

    /***** EFFECTS *****/
    useActiveTitleEffect(getActive(), props, [])

    /***** RENDER *****/
    return (
        <StyledContainer align='center' fullHeight hovered={hovered} permanent={permanent} active={getActive()}>
            <StyledLink
                $sidebar={inSidebar}
                $permanent={permanent}
                $active={getActive()}
                onClick={closeSidebar}
                to={path}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                {isMobile && inSidebar && Icon && <Icon />}
                {children}
            </StyledLink>
        </StyledContainer>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/***** EXPORTS *****/
export const HeaderNavItem = Object.assign(_HeaderNavItem, {
    Style: {
        Container: StyledContainer,
        Link: StyledLink
    }
})
