/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Lightbox } from 'nxui/src'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import DomainContactForm from 'containers/domains/forms/domainContact.form'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useUpdateDomainContactsMutation } from 'api/domain'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { titleCase } from 'helpers/utils'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
import { Contact } from 'models/account'
import { DomainContactType } from 'models/domain'

interface Props {
    type: DomainContactType
    contact: Contact
    status: boolean
    close: () => void
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function DomainContactLightbox({ type, contact, status, close }: Props) {
    const [, { isLoading }] = useUpdateDomainContactsMutation({ fixedCacheKey: 'update-domain-contact' })

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <Lightbox
            title={`Edit ${titleCase(type)} Contact`}
            content={<DomainContactForm type={type} contact={contact} close={close} />}
            conditions={{ state: status, action: () => close() }}
            actions={[
                {
                    label: 'Back',
                    color: 'secondary',
                    type: 'button',
                    func: () => close()
                },
                {
                    label: 'Update',
                    color: 'primary',
                    type: 'submit',
                    disabled: isLoading,
                    loading: isLoading ? 'Updating Contact...' : false,
                    form: 'updateDomainContactForm'
                }
            ]}
        />
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
