/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useCallback, useRef } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect'

/**********************************************************************************************************
 *   HOOK
 **********************************************************************************************************/
export function useIsMounted() {
    const isMounted = useRef(true)

    useIsomorphicLayoutEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    const mounted = useCallback(() => {
        return isMounted.current
    }, [])

    return mounted()
}
