import { FieldInputProps, FormikErrors, FormikTouched } from 'formik'
import type { TChildren } from 'models/generic.d'
import { ChangeEvent, KeyboardEvent } from 'react'
import { Option } from './datalist.component'

/**********************************************************************************************************
 *   INTERFACE/TYPES
 *********************************************************************************************************/
export interface IFieldColumn {
    // The className attribute is used to set or return the value of an element's class attribute. Using this property, the user can change the class of an element to the desired class.
    className?: string
    focus?: boolean
    error?: boolean
    // A Boolean attribute which, if present, indicates that the user should not be able to interact with the input.
    disabled?: boolean
    // The children prop is a special prop, automatically passed to every component, that can be used to render the content included between the opening and closing tags when invoking a component.
    children?: TChildren
}

export interface IFieldDescription {
    // The className attribute is used to set or return the value of an element's class attribute. Using this property, the user can change the class of an element to the desired class.
    className?: string
    // The children prop is a special prop, automatically passed to every component, that can be used to render the content included between the opening and closing tags when invoking a component.
    children?: TChildren
}

export interface IFieldErrors {
    // The className attribute is used to set or return the value of an element's class attribute. Using this property, the user can change the class of an element to the desired class.
    className?: string
    name: string
    errors: FormikErrors<{
        [field: string]: unknown
    }>
    touched: FormikTouched<{
        [field: string]: unknown
    }>
}

export interface IFieldTip {
    // The className attribute is used to set or return the value of an element's class attribute. Using this property, the user can change the class of an element to the desired class.
    className?: string
    // A Number attribute that provides the offsetHeight of the input field its associated to.
    size?: number
}

export interface IFieldInput {
    // The className attribute is used to set or return the value of an element's class attribute. Using this property, the user can change the class of an element to the desired class.
    className?: string
    //The autocomplete attribute takes as its value a space-separated string that describes what, if any, type of autocomplete functionality the input should provide.
    autoComplete?: string
    // A Boolean attribute which, if present, indicates that the input should automatically have focus when the page has finished loading
    autoFocus?: boolean
    // Valid for both radio and checkbox types, checked is a Boolean attribute.
    checked?: boolean
    // A Boolean attribute which, if present, indicates that the input field has a validation error present.
    error?: boolean
    // A Boolean attribute which, if present, indicates that the user should not be able to interact with the input.
    disabled?: boolean
    //The description attribute allows an input field to display useful text that may be related to the input field itself
    description?: string
    // ** REQUIRED ** Form attribute that contains Field Props provided by Formik.
    field?: FieldInputProps<{
        [field: string]: unknown
    }>
    // A string specifying the <form> element with which the input is associated (that is, its form owner).
    form?: string
    // Global attribute valid for all elements, including all the input types, it defines a unique identifier (ID) which must be unique in the whole document.
    id?: string
    // The label is given to the <label> of the input field
    label?: string
    // The value given to the list attribute should be the id of a <datalist> element located in the same document.
    options?: Option[]
    // Valid for text, search, url, tel, email, and password, it defines the maximum number of characters (as UTF-16 code units) the user can enter into the field.
    maxLength?: number
    // Valid for date, month, week, time, datetime-local, number, and range, it defines the greatest value in the range of permitted values.
    max?: number
    // Valid for text, search, url, tel, email, and password, it defines the minimum number of characters (as UTF-16 code units) the user can enter into the entry field.
    minLength?: number
    // Valid for date, month, week, time, datetime-local, number, and range, it defines the most negative value in the range of permitted values.
    min?: number
    // The Boolean multiple attribute, if set, means the user can enter comma separated email addresses in the email widget or can choose more than one file with the file input.
    multiple?: boolean
    // A string specifying a name for the input control.
    name: string
    // The pattern attribute, when specified, is a regular expression that the input's value must match in order for the value to pass constraint validation.
    pattern?: string
    // The placeholder attribute is a string that provides a brief hint to the user as to what kind of information is expected in the field.
    placeholder?: string
    // The append attribute is a string that displays a right aligned text and can be either added to the value or simply descriptive.
    append?: string
    // The prepend attribute is a string that displays a left aligned text and can be either added to the value or simply descriptive.
    prepend?: string
    // A Boolean attribute which, if present, indicates that the user should not be able to edit the value of the input.
    readOnly?: boolean
    // required is a Boolean attribute which, if present, indicates that the user must specify a value for the input before the owning form can be submitted.
    required?: boolean
    // Valid for the numeric input types, including number, date/time input types, and range, the step attribute is a number that specifies the granularity that the value must adhere to.
    step?: number
    // Global attribute valid for all elements, including all the input types, an integer attribute indicating if the element can take input focus (is focusable), if it should participate to sequential keyboard navigation.
    tabIndex?: number
    // Global attribute valid for all elements, including all input types, containing a text representing advisory information related to the element it belongs to.
    title?: string
    // A string specifying the type of control to render. For example, to create a checkbox, a value of checkbox is used.
    type?: string
    // The input control's value.
    value?: string | number | ReadonlyArray<string> | undefined
    // value of the id attribute of the <datalist> of autocomplete options
    list?: string
    // The message attribute is a string that displays a error text.
    message?: string
    touched?: boolean
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void
    onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
}

export interface IInputWrapper {
    // A Boolean attribute which, if present, indicates that the inputs label and margins are removed. This is ideal for placing inputs inside of tables.
    embedded?: boolean
}

export interface IFieldLabel {
    // The className attribute is used to set or return the value of an element's class attribute. Using this property, the user can change the class of an element to the desired class.
    className?: string
    // The value of the for attribute must be a single id for a labelable form-related element in the same document as the <label> element.
    htmlFor?: string
    // A boolean attribute which removes any margins, as well as any <label> tags from a component.
    embedded?: boolean
    // required is a Boolean attribute which, if present, indicates that the user must specify a value for the input before the owning form can be submitted.
    required?: boolean
    // A string specifying a label for the input control.
    label?: string
    // A string specifying a name for the input control.
    name?: string
}

export interface IFieldWrapper {
    // The className attribute is used to set or return the value of an element's class attribute. Using this property, the user can change the class of an element to the desired class.
    className?: string
    // A boolean attribute which removes any margins, as well as any <label> tags from a component.
    embedded?: boolean
    // The children prop is a special prop, automatically passed to every component, that can be used to render the content included between the opening and closing tags when invoking a component.
    children?: TChildren
    // A Boolean attribute which, if present, indicates that the input field has a validation error present.
    error?: boolean
}

export const fieldDefaultProps = {
    className: undefined,
    autoComplete: undefined,
    autoFocus: undefined,
    checked: undefined,
    error: undefined,
    disabled: undefined,
    id: '',
    label: '',
    list: undefined,
    maxLength: undefined,
    max: undefined,
    minLength: undefined,
    min: undefined,
    multiple: undefined,
    name: undefined,
    pattern: undefined,
    placeholder: undefined,
    readOnly: undefined,
    required: undefined,
    step: undefined,
    tabIndex: 0,
    title: undefined,
    value: undefined
}

export const labelDefaultProps = {
    className: undefined,
    htmlFor: undefined,
    embedded: false,
    required: false,
    label: undefined,
    field: undefined
}

export const wrapperDefaultProps = {
    className: undefined,
    embedded: false,
    error: undefined
}
