/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { FormikContextType, FormikProvider } from 'formik'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TStyleableFormikProvider = React.FC<{
    className?: string

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: FormikContextType<any>

    children: React.ReactNode
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const StyleableFormikProvider: TStyleableFormikProvider = ({ className, ...props }) => {
    /***** RENDER *****/
    return (
        <div className={className}>
            <FormikProvider {...props} />
        </div>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
