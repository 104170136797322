/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Theme } from 'nxui/src'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Lightbox } from 'components/lightbox'
import { Flex } from 'components/utilities/Flex'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { WebsiteStyles } from '../../style'

/**********************************************************************************************************
 *   STYLES START
 **********************************************************************************************************/
export const ContactUsLightboxStyles = {
    Lightbox: styled(Lightbox)`
        ${Lightbox.Styles.ChildrenWrapper} {
            width: 500px;
        }
    `,

    Heading: styled.h2`
        ${WebsiteStyles.Helpers.Fonts.h2}

        margin-bottom: 10px;
        word-wrap: break-word;

        color: ${({ theme }) => theme['white--100']};

        ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-md'])} {
            font-size: 26px;
        }
    `,

    Body: styled.p`
        font-size: 18px;
        font-weight: 500;
        word-wrap: break-word;

        color: ${({ theme }) => theme['white--100']};

        ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-md'])} {
            font-size: 18px;
        }
    `,

    ContactUs: styled(Flex)`
        border-radius: 16px;
        height: 160px;
        padding: 50px;

        background: ${({ theme }) => Theme.Functions.hexToRGBA(theme['white--100'], 0.1)};

        ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-md'])} {
            padding: 30px;
        }

        ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-sm'])} {
            height: auto;
            text-align: center;
            gap: 20px;
            padding: 20px;
            flex-direction: column;
        }
    `,

    NoWrapSpan: styled.span`
        white-space: nowrap;
    `
}
/**********************************************************************************************************
 *   STYLES END
 **********************************************************************************************************/
