/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { Formik, FormikProps } from 'formik'
import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { domainAPI } from 'api/domain'

/**********************************************************************************************************
 *   STORE IMPORT
 **********************************************************************************************************/
import { useAppDispatch, useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { APITypeMap, APItoRecordTypeMap, TInitialValues, recordTypeAPIMap, sanitizeDomain, validationSchema } from '../addDomainForwarder'
import { DomainForwarderActionRow } from './actionRow'
import { DomainForwarderEditingRow } from './editRowForm'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/utilities/Flex'
import { Badge, Loader } from 'nxui/src'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { forwardingStyles as S } from '../_forwarding.styles'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import { regex } from 'helpers/utils'
import { DomainRedirect } from 'models/domain'
import { DNSModeType } from 'models/enums'
import { toggleAddingDomainRedirect } from 'store/slices/domainSlice'
import { DomainForwarderPreviewRow } from './previewRow'
type TTableRowObject = {
    [key in ReturnType<typeof columns>[number]['accessor']]: React.ReactNode
}
type TCreateRowProps = DomainRedirect.TRecord & {
    editingIndex: number
    index: number
    setEditing: React.Dispatch<React.SetStateAction<number>>
}
type TCreateRowReturnType =
    | TTableRowObject
    | [originalRow: TTableRowObject, previewFields: TTableRowObject, editingFields: TTableRowObject, actions: TTableRowObject]
type TCreateRow = (props: TCreateRowProps) => TCreateRowReturnType

/***** STATIC *****/
const columns = () => {
    return [
        {
            Header: 'TYPE',
            accessor: 'type'
        },
        {
            Header: 'SOURCE',
            accessor: 'source'
        },
        {
            Header: 'DESTINATION',
            accessor: 'destination'
        },
        {
            Header: '',
            accessor: 'actions'
        }
    ] as const
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DomainForwarderTable = () => {
    /***** HOOKS *****/
    const { id } = useParams()
    const { dnsMode } = useAppSelector((state) => state.domain)
    const formRef = useRef<FormikProps<TInitialValues>>(null)
    const dispatch = useAppDispatch()

    /***** STATE *****/
    const [editingIndex, setEditing] = useState<number>(-1)

    /***** QUERIES *****/
    const {
        data: redirectData,
        isFetching: isFetchingRedirectData,
        isLoading: isLoadingRedirectData
    } = domainAPI.endpoints.domainRedirects.useQuery(Number(id))
    const { data: domainData } = domainAPI.endpoints.domain.useQueryState(Number(id))
    const [updateDomainRedirect] = domainAPI.endpoints.updateDomainRedirect.useMutation({
        fixedCacheKey: 'update-domain-redirect'
    })

    /***** RENDER HELPERS *****/
    const createFreedomRow = (children: React.ReactNode, wrap = true): TTableRowObject => ({
        type: wrap ? <S.Domain.FreedomRow>{children}</S.Domain.FreedomRow> : children,
        destination: null,
        source: null,
        actions: null
    })

    const getInitialValues = () => {
        if (editingIndex === -1) {
            return {} as TInitialValues
        }

        if (!redirectData) {
            return {} as TInitialValues
        }

        const record = redirectData.records[editingIndex]

        const currentWebSource = record.hostname?.split('/')[0]?.split(`.${domainData?.domain ?? ''}`)[0]

        return {
            ...record,
            web_source: currentWebSource === domainData?.domain ? '' : currentWebSource,
            web_path: record.hostname.split('/').slice(1).join('/'),
            type: APItoRecordTypeMap[record.redirectType],
            wildcard_source: record.wildcardSource,
            retain_path: record.retainPath
        }
    }

    const renderLoader = () => [
        createFreedomRow(
            <Flex fullHeight justify='center' align='center'>
                <Flex align='center' justify='center'>
                    <Loader.Basic /> <S.FetchingMessage>Fetching Domain Redirects</S.FetchingMessage>
                </Flex>
            </Flex>
        )
    ]

    const createDomainForwarderRow: TCreateRow = ({ editingIndex, index, setEditing, ...record }) => {
        const displayRow: TCreateRowReturnType = {
            destination: <div>{record.destination}</div>,
            source: <div>{record.hostname}</div>,
            type: <Badge color='primary'>{APITypeMap[record.redirectType ?? 'P']}</Badge>,
            actions: (
                <Flex justify='flex-end'>
                    <S.Anchor color='primary' onClick={() => setEditing((editing) => (editing === index ? -1 : index))}>
                        Edit {editingIndex === index ? <ChevronDownIcon /> : <ChevronRightIcon />}
                    </S.Anchor>
                </Flex>
            )
        }

        if (editingIndex === index) {
            return [
                displayRow,
                createFreedomRow(<DomainForwarderPreviewRow />),
                createFreedomRow(<DomainForwarderEditingRow />, false),
                createFreedomRow(
                    <DomainForwarderActionRow
                        onSave={() => formRef.current?.handleSubmit()}
                        onCancel={() => setEditing(-1)}
                        onDelete={() => setEditing(-1)}
                        recordId={record.recordId}
                    />
                )
            ]
        }

        return displayRow
    }

    /***** RENDER *****/
    if (dnsMode !== DNSModeType.Forwarding) return null

    return (
        <Formik<TInitialValues>
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={getInitialValues()}
            innerRef={formRef}
            onSubmit={async ({ type, ...values }, { setSubmitting, setFieldError }) => {
                if (!domainData) {
                    throw new Error('Something went wrong, please try again')
                }

                const { domain } = domainData
                const { wildcard_source, web_path, web_source } = values

                const santized = sanitizeDomain(web_source, domain)

                if (!!santized && !regex.domainWithExtension.test(`${santized}.domain.com`)) {
                    return setFieldError('web_source', 'Invalid domain name')
                }

                await updateDomainRedirect({
                    ...values,
                    web_source: santized,
                    web_path: wildcard_source ? '' : web_path,
                    type: recordTypeAPIMap[type],
                    domainId: Number(id),
                    recordId: redirectData?.records[editingIndex].recordId ?? -1
                })
                    .unwrap()
                    .then(() => {
                        dispatch(toggleAddingDomainRedirect(false))
                        return setEditing(-1)
                    })
                    .catch(() => setSubmitting(false))
            }}
        >
            <S.Domain.Table
                editing={editingIndex !== -1}
                editingIndex={editingIndex}
                conditions={{ sort: false, loading: isLoadingRedirectData && 'Loading Domain Redirects' }}
                render={{
                    columns,
                    data() {
                        return [
                            ...(redirectData?.records.flatMap((record, index) => {
                                return createDomainForwarderRow({ ...record, index, editingIndex, setEditing })
                            }) ?? []), // render columns
                            ...(isFetchingRedirectData ? renderLoader() : []) // optionally show fetching row
                        ]
                    }
                }}
            />
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
