/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import { ICartItem } from 'models/shop/cart'

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useDomainInformationFromCart = () => {
    /***** STATE *****/
    const [isUsingDomainContactFields, setIsUsingDomainContactFields] = useState(false)

    /***** HOOKS *****/
    const [searchParams] = useSearchParams()
    const { cart } = useAppSelector((state) => state.app)

    /***** FUNCTIONS *****/
    const getDomainContactFields = () => {
        const domainContactFields = cart?.items?.find((item: ICartItem) => item.product.service_type === 'domain')?.domain_contact_fields

        if (!domainContactFields) return null

        return {
            ...domainContactFields,
            city: domainContactFields?.suburb
        }
    }

    /***** RESULTS *****/
    return {
        isComingFromCheckout: searchParams.get('ref') === 'checkout',
        isUsingDomainContactFields,
        setIsUsingDomainContactFields,
        domainContactFields: getDomainContactFields()
    } as const
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
