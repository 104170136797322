/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { addListener, createListenerMiddleware, TypedAddListener, TypedStartListening } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { authAPI } from 'api/authentication'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import globalCookies from 'helpers/cookies'
import { AppDispatch, RootState } from 'store/store'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setAppActiveRouter, setAppSession, setCart, setRedirect, setRequestedMethod, setSwitchAccountOpen } from 'store/slices/appSlice'

/**********************************************************************************************************
 *   INTERFACES
 **********************************************************************************************************/
import { Message, Settings } from 'models/app'
import { HTTPStatusCode } from 'models/enums'
import { ICart } from 'models/shop/cart'

/**********************************************************************************************************
 *   BASE EXPORTS
 **********************************************************************************************************/
export const authenticationListenerMiddleware = createListenerMiddleware()

export type AppStartListening = TypedStartListening<RootState, AppDispatch>

export const startAppListening = authenticationListenerMiddleware.startListening as AppStartListening

const token = globalCookies.get('XSRF-TOKEN')

/**********************************************************************************************************
 *   FETCH CART IN BACKGROUND & SET CART LOCAL STORAGE
 **********************************************************************************************************/
startAppListening({
    matcher: authAPI.endpoints.getSession.matchFulfilled,
    effect: async ({ payload }, listenerApi) => {
        /**********************************************************************************************************
         *   HANDLE AUTHENTICATION ROUTING
         **********************************************************************************************************/
        const { authenticated, two_factor_required, current_account } = payload
        const { app } = listenerApi.getState()
        const params = new URLSearchParams(window.location.search)
        const ref = params.get('ref')
        const appendParam = ref && ref === 'checkout' ? '?ref=checkout' : ''

        listenerApi.dispatch(setAppSession(payload))

        if (two_factor_required || !current_account) {
            if (authenticated && !current_account) {
                await listenerApi.dispatch(authAPI.endpoints.getAuthorisedAccounts.initiate(undefined, { forceRefetch: false }))
            }

            listenerApi.dispatch(
                setAppActiveRouter({
                    template: 'guest',
                    meta: {
                        loading: false,
                        message: '',
                        redirect: two_factor_required
                            ? `/login/two-factor${appendParam}`
                            : authenticated && !current_account
                            ? `/login/accounts${appendParam}`
                            : app.appActiveRouter.meta.redirect
                    }
                })
            )
        } else {
            if (app.appSwitchAccount) {
                listenerApi.dispatch(setSwitchAccountOpen(false))
                listenerApi.dispatch(
                    setAppActiveRouter({
                        template: 'user',
                        meta: {
                            loading: false,
                            message: '',
                            redirect: [
                                '/dashboard',
                                '/domains',
                                '/services',
                                '/billing/invoices',
                                '/billing/transactions',
                                '/billing/credit-transactions'
                            ].includes(window.location.pathname)
                                ? `${window.location.pathname}?page=1&sort_by=-id`
                                : window.location.pathname
                        }
                    })
                )
            } else {
                listenerApi.dispatch(
                    setAppActiveRouter({
                        template: 'user',
                        meta: {
                            loading: false,
                            message: '',
                            redirect: ref === 'checkout' ? 'shop/checkout' : false
                        }
                    })
                )
            }
        }

        const cartUuid = localStorage.getItem('cart_uuid')
        const response = await fetch(
            cartUuid ? `${process.env.REACT_APP_API}client/ordering/cart/${cartUuid}` : `${process.env.REACT_APP_API}client/ordering/cart`,
            { method: cartUuid ? 'GET' : 'POST', headers: { 'X-XSRF-TOKEN': token }, credentials: 'include' }
        )

        const cart: {
            data?: ICart
            messages: Message
        } = await response.json()

        if (response.status === HTTPStatusCode.NotFound || response.status === HTTPStatusCode.Unauthorized) {
            localStorage.removeItem('cart_uuid')
        }

        if (cart.data) {
            listenerApi.dispatch(setCart(cart.data))
        }

        if (!cartUuid && cart && cart.data) {
            localStorage.setItem('cart_uuid', cart.data.uuid)
        }

        const settingsResponse = await fetch(`${process.env.REACT_APP_API}client/settings`, {
            method: 'GET',
            headers: { 'X-XSRF-TOKEN': token },
            credentials: 'include'
        })

        const settings: {
            data?: Settings
            message: Array<Message>
        } = await settingsResponse.json()

        localStorage.setItem('mca_theme', JSON.stringify(settings))

        listenerApi.cancelActiveListeners()
    }
})

/**********************************************************************************************************
 *   REDIRECT ON ALTERNATIVE TWO FACTOR METHOD
 **********************************************************************************************************/
startAppListening({
    actionCreator: setRequestedMethod,
    effect: async (action, listenerApi) => {
        await listenerApi.dispatch(authAPI.endpoints.selectTwoFactorMethod.initiate(action.payload.type, { forceRefetch: true }))
        listenerApi.dispatch(setRedirect('/login/two-factor'))
        listenerApi.cancelActiveListeners()
    }
})

/**********************************************************************************************************
 *   LOGOUT AND REDIRECT TO LOGIN
 **********************************************************************************************************/
startAppListening({
    matcher: authAPI.endpoints.logout.matchFulfilled,
    effect: async (_, listenerApi) => {
        listenerApi.dispatch(authAPI.endpoints.getSession.initiate(undefined, { forceRefetch: true }))
        listenerApi.dispatch(setRedirect('/login'))
        listenerApi.cancelActiveListeners()
    }
})

/**********************************************************************************************************
 *   RESET API STATE WHEN SELECT A DIFFERENT ACCOUNT
 **********************************************************************************************************/
startAppListening({
    matcher: authAPI.endpoints.selectAccount.matchPending,
    effect: async (_, listenerApi) => {
        const { app } = listenerApi.getState()
        if (app.appSwitchAccount) {
            listenerApi.dispatch(
                setAppActiveRouter({
                    template: 'loading',
                    meta: {
                        loading: false,
                        message: 'Switching account...'
                    }
                })
            )
        } else {
            listenerApi.dispatch(
                setAppActiveRouter({
                    ...app.appActiveRouter,
                    meta: {
                        loading: true,
                        message: 'Assigning Account & Finalising Login...'
                    }
                })
            )
        }
        listenerApi.cancelActiveListeners()
    }
})

startAppListening({
    matcher: authAPI.endpoints.selectAccount.matchFulfilled,
    effect: async (_, listenerApi) => {
        listenerApi.dispatch(authAPI.endpoints.getSession.initiate(undefined, { forceRefetch: true }))
        listenerApi.cancelActiveListeners()
    }
})

/**********************************************************************************************************
 *   ACCEPT INVITATION
 **********************************************************************************************************/
startAppListening({
    matcher: authAPI.endpoints.acceptInvitation.matchPending,
    effect: async (_, listenerApi) => {
        listenerApi.dispatch(
            setAppActiveRouter({
                template: 'loading',
                meta: {
                    loading: false,
                    message: 'Accepting Invitation...'
                }
            })
        )
    }
})

startAppListening({
    matcher: authAPI.endpoints.acceptInvitation.matchFulfilled,
    effect: async ({ payload }, listenerApi) => {
        const {
            app: {
                appSession: { authenticated }
            }
        } = listenerApi.getState()
        const { detail, type, code } = payload.messages[0]

        if (authenticated) {
            const success = {
                detail,
                type
            }

            await listenerApi.dispatch(authAPI.endpoints.getSession.initiate(undefined, { forceRefetch: true }))

            listenerApi.dispatch(
                setAppActiveRouter({
                    template: 'user',
                    meta: {
                        loading: false,
                        message: '',
                        redirect: '/'
                    }
                })
            )

            setTimeout(() => {
                // @ts-ignore
                toast.custom(success, { duration: 10_000 })
            }, 500)
        } else if (code === 11) {
            const info = {
                detail,
                type
            }
            // @ts-ignore
            toast.custom(info, { duration: 10_000 })

            listenerApi.dispatch(
                setAppActiveRouter({
                    template: 'guest',
                    meta: {
                        loading: false,
                        message: '',
                        redirect: '/login'
                    }
                })
            )
        } else {
            listenerApi.dispatch(
                setAppActiveRouter({
                    template: 'guest',
                    meta: {
                        loading: false,
                        message: '',
                        redirect: false
                    }
                })
            )
        }

        listenerApi.cancelActiveListeners()
    }
})

startAppListening({
    matcher: authAPI.endpoints.acceptInvitation.matchRejected,
    effect: async (_, listenerApi) => {
        listenerApi.dispatch(
            setAppActiveRouter({
                template: 'guest',
                meta: {
                    loading: false,
                    message: '',
                    redirect: false
                }
            })
        )
    }
})

export const addAuthenticationListener = addListener as TypedAddListener<RootState, AppDispatch>
