import { billingAPI, usePayInvoiceMutation } from 'api/billing'
import { useCheckoutCartMutation } from 'api/shop'
import { useApplyAccountCreditToInvoice } from 'containers/billing/utils'
import { ShopRouteBuilder } from 'containers/shop/helpers/routeMatchers'
import { Form, Formik } from 'formik'
import { PaymentMethodData } from 'models/billing'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
interface DefaultPaymentMethodFormProps {
    children: ReactNode
    selectedSavedPaymentMethod: PaymentMethodData
}

export function DefaultPaymentMethodForm({ children, selectedSavedPaymentMethod }: DefaultPaymentMethodFormProps) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { cart } = useAppSelector((state) => state.app)
    const { checkoutAccountCredit } = useAppSelector((state) => state.shop)
    const [checkoutCart] = useCheckoutCartMutation({ fixedCacheKey: 'checkout-cart' })
    const [payInvoice] = usePayInvoiceMutation({ fixedCacheKey: 'pay-checkout-invoice' })
    const { applyAccountCreditToInvoice } = useApplyAccountCreditToInvoice()

    /*   RENDER COMPONENT
     *****************************************************/
    if (!selectedSavedPaymentMethod || !cart) return null

    return (
        <Formik
            initialValues={{}}
            onSubmit={async () => {
                const order = await checkoutCart({
                    uuid: cart.uuid,
                    payment_method_id: selectedSavedPaymentMethod.payment_method.id,
                    payment_method_data_id: selectedSavedPaymentMethod.id
                }).unwrap()

                const { isInvoiceStillUnpaid, isSuccess } = await applyAccountCreditToInvoice(order.invoice.id, checkoutAccountCredit.amount)

                if (isSuccess && isInvoiceStillUnpaid)
                    await payInvoice({
                        id: String(order.invoice.id),
                        payment_method_id: selectedSavedPaymentMethod.payment_method.id,
                        payment_method_data_id: selectedSavedPaymentMethod.id
                    })

                dispatch(billingAPI.endpoints.invoice.initiate(order.invoice.id, { forceRefetch: true })).unwrap()

                navigate(ShopRouteBuilder.orderId(order.id))
            }}
        >
            {() => {
                return <Form id={'savedPaymentMethodForm'}>{children}</Form>
            }}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
