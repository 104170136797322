/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/utilities/Flex'
import { Button, Lightbox } from 'nxui/src'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { forwardingStyles as S } from '../_forwarding.styles'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import { domainAPI } from 'api/domain'
import { useFormikContext } from 'formik'
type TDomainForwarderActionRow = React.FC<{
    onSave: () => void
    onCancel: () => void
    onDelete: () => void
    recordId: number
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DomainForwarderActionRow: TDomainForwarderActionRow = ({ onSave, onCancel, recordId, onDelete }) => {
    /***** HOOKS *****/
    const { id } = useParams()
    const { isSubmitting } = useFormikContext()

    /***** QUERIES *****/
    const [deleteDomainRedirect, { isLoading: isDeletingDomainRedirect }] = domainAPI.endpoints.deleteDomainRedirect.useMutation()
    const [, { isLoading: isCreatingDomainRedirect }] = domainAPI.endpoints.createDomainRedirect.useMutation({
        fixedCacheKey: 'create-domain-redirect'
    })
    const { isFetching: isFetchingDomainRedirects } = domainAPI.endpoints.domainRedirects.useQueryState(Number(id))

    /***** STATE *****/
    const [isDeleting, setIsDeleting] = useState(false)

    /***** RENDER *****/
    return (
        <>
            <S.ActionRow fullWidth fullHeight align='center' justify='space-between'>
                <Button
                    disabled={isDeletingDomainRedirect || isCreatingDomainRedirect || isSubmitting}
                    type='button'
                    color='error'
                    onClick={() => setIsDeleting(true)}
                >
                    Delete
                </Button>
                <Flex gap={10}>
                    <Button disabled={isDeletingDomainRedirect || isCreatingDomainRedirect || isSubmitting} color='secondary' onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button disabled={isDeletingDomainRedirect || isCreatingDomainRedirect || isSubmitting} color='primary' onClick={onSave}>
                        Save
                    </Button>
                </Flex>
            </S.ActionRow>
            <Lightbox
                title='Current domain forwarder will be deleted'
                type='confirm'
                icon='warning'
                description={
                    <S.ConfirmDelete>
                        <span>You are about to delete the following domain forwarder, note that this action is irreversible.</span>
                        <S.DeleteLightboxActions>
                            <Button type='button' color='secondary' onClick={() => setIsDeleting(false)}>
                                No, keep my current domain forwarder
                            </Button>
                            <Button
                                type='submit'
                                color='error'
                                loading={isDeletingDomainRedirect && 'Deleting Forwarder'}
                                disabled={isDeletingDomainRedirect || isFetchingDomainRedirects}
                                onClick={async () => {
                                    await deleteDomainRedirect({ domainId: Number(id), recordId }).then(() => setIsDeleting(false))
                                    onDelete()
                                }}
                            >
                                Delete
                            </Button>
                        </S.DeleteLightboxActions>
                    </S.ConfirmDelete>
                }
                conditions={{
                    state: isDeleting,
                    action: () => setIsDeleting(false)
                }}
            />
        </>
    )
}
