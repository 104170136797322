/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { HeaderNavItem } from './item'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/utilities/Flex'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _HeaderNavContainer = styled(Flex).attrs({ justify: 'center', gap: 20, fullHeight: true })``

/***** EXPORTS *****/
export const HeaderNavContainer = Object.assign(_HeaderNavContainer, {
    Item: HeaderNavItem
})
