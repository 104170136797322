import { Registration } from 'containers/shop/domain/domain.styles'
import { Field, useFormikContext } from 'formik'
import { defaultCurrency } from 'helpers/utils'
import type { ICartItem } from 'models/shop/cart'
import type { IDomainAvailability } from 'models/shop/domain'
import type { IProduct } from 'models/shop/product'
import type { IPricing } from 'models/shop/shop'
import { Form as NXUIForm } from 'nxui/src'
import { ChangeEvent } from 'react'
import { useAppDispatch } from 'store/hooks'
import { updateProductPendingSelectedPricing } from 'store/slices/shopSlice'
import type { TDomainConfigurationFormValues } from '../domain.configure'

type TToggleFields = React.FC<{
    domain: Readonly<
        Omit<IDomainAvailability, 'product'> & {
            product: IProduct
        }
    >
    cartItem: Readonly<ICartItem>
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Renders Basic Toggle Fields for the Domain Configuration Form. This includes
 *
 * - Pricing
 * - Auto Renew
 * - ID Protection
 */
export const ToggleFields: TToggleFields = ({ domain, cartItem }) => {
    /***** HOOKS *****/
    const { isSubmitting } = useFormikContext<TDomainConfigurationFormValues>()
    const dispatch = useAppDispatch()

    /***** RENDER HELPERS *****/
    const handleChange = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
        const selectedPricing = domain.pricing?.find((pricing) => pricing.billing_cycle_id === Number(value))
        if (!selectedPricing) return

        dispatch(updateProductPendingSelectedPricing({ type: 'domain', ...domain, selectedPricing }))
    }

    const PricingFieldLabel = 'Registration Period'
    const list = domain.pricing
        .filter((price) => price.type === cartItem.order_type)
        .map((price: IPricing) => ({
            label: `${price.billing_cycle.name} - ${defaultCurrency(price.price ? price.price : price.cost)} ${price.currency}`,
            value: price.billing_cycle.id
        }))

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Registration.Section>
            {domain.product?.pricing && (
                <Registration.Row>
                    <Field
                        id={'billing_cycle_id'}
                        name={'billing_cycle_id'}
                        label={PricingFieldLabel}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        list={list}
                        component={NXUIForm.SelectField}
                    />
                </Registration.Row>
            )}
            <Registration.Row>
                <Field
                    type='checkbox'
                    id='auto_renew'
                    name='auto_renew'
                    description='Auto Renew'
                    disabled={isSubmitting}
                    component={NXUIForm.CheckboxField}
                />
                {domain.meta.allow_id_protection && (
                    <Field
                        type='checkbox'
                        id='id_protection'
                        name='id_protection'
                        description='ID Protection'
                        disabled={isSubmitting}
                        component={NXUIForm.CheckboxField}
                    />
                )}
            </Registration.Row>
        </Registration.Section>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
