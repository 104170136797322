import { Lightbox } from 'nxui/src'
import styled from 'styled-components'

export const Styles = {
    Lightbox: styled(Lightbox)`
        & footer svg {
            margin-right: 0;
            margin-left: 0;
        }
    `
}
