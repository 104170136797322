/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { CartProduct } from './cart.product'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import { Cart } from './cart.styles'

const listVariants = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.1,
            delayChildren: 0.3
        }
    }
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const CartList = (props) => {
    function renderProductsList() {
        if (props.products && props.products.length > 0) {
            return props.products.map((product) => {
                return <CartProduct key={product.id} {...product} />
            })
        }

        return (
            <Cart.Empty.Base>
                <Cart.Empty.Icon />
                Your cart is empty.
            </Cart.Empty.Base>
        )
    }

    /*   RENDER
     ****************************************************/
    return (
        <Cart.List.Base className={props.className} variants={listVariants} animate={'show'}>
            {renderProductsList()}
        </Cart.List.Base>
    )
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
CartList.defaultProps = {
    className: undefined
}
