/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Anchor } from 'components/buttons'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { WebsiteStyles } from 'containers/website/style'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
export const WebsiteEndpointRendererStyles = {
    Htmr: {
        Anchor: styled(Anchor)`
            display: inline;
        `,
        UnorderedList: styled.ul`
            padding-left: 17px;
            margin-block: 10px;
            color: ${({ theme }) => theme['text--100']};

            li {
                list-style: disc;
            }
        `,
        OrderedListItem: styled.ol`
            padding-left: 17px;
            margin-block: 10px;
            color: ${({ theme }) => theme['text--100']};

            li {
                list-style: decimal;
                padding-left: 0;
            }
        `,
        BlockQuote: styled.blockquote`
            border-left: 5px solid #ccc;
            padding: 0.5em 10px;
        `,
        paragraph: styled.p`
            color: ${({ theme }) => theme['text--100']};
            margin-block: 10px;
        `,
        Heading1: styled.h1`
            ${WebsiteStyles.Helpers.Fonts.h1}
            margin-bottom: 10px;
        `,
        Heading2: styled.h2`
            ${WebsiteStyles.Helpers.Fonts.h2}
            margin-bottom: 15px;
        `,
        Heading3: styled.h3`
            ${WebsiteStyles.Helpers.Fonts.h3}
            margin-bottom: 15px;
        `,
        Heading4: styled.h4`
            ${WebsiteStyles.Helpers.Fonts.h4}
            text-transform: unset;
            margin-bottom: 15px;
        `,
        Heading5: styled.h5`
            ${WebsiteStyles.Helpers.Fonts.h5}
            margin-bottom: 15px;
        `,
        CascadingStyles: styled.div`
            max-width: 700px;

            p > code {
                padding: 0.2rem 0.5rem;
                margin: 0 0.2rem;
                font-size: 90%;
                white-space: nowrap;
                background: #f1f1f1;
                border: 1px solid #e1e1e1;
                border-radius: 4px;
            }

            pre > code {
                display: block;
                padding: 9.5px;
                margin: 0 0 10px;
                font-size: 13px;
                line-height: 1.42857143;
                color: #333;
                word-break: break-all;
                word-wrap: break-word;
                background-color: #f5f5f5;
                border: 1px solid #ccc;
                border-radius: 4px;
            }
        `
    },

    LegalContainer: styled.div`
        width: 100%;
        background-color: ${({ theme }) => theme['background--100']};
    `,

    Inner: styled.div`
        ${WebsiteStyles.Helpers.InlinePadding}
        padding-block: ${WebsiteStyles.Consts.BlockPadding};
    `,

    DocumentContainer: styled.div`
        width: clamp(0, ${WebsiteStyles.Consts.Width}, 100%);
    `,

    Heading: styled.h1`
        ${WebsiteStyles.Helpers.Fonts.h1}
        margin-bottom: 50px;
    `
}
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/
