import { shopAPI } from 'api/shop'
import { useShopActiveGroup } from 'containers/shop/hooks/useShopActiveGroup'
import { ShopSummary } from 'containers/shop/shop.summary'
import type { IProductGroup } from 'models/shop/product'
import { Loader } from 'nxui/src'
import type React from 'react'
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import { Shop as S } from '../shop.styles'

const DomainContent: IProductGroup = {
    id: 1,
    type: 'domains',
    name: 'Domain Names',
    description: null
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ShopGroupIdComponent = () => {
    /***** HOOKS *****/
    const location = useLocation()
    const navigate = useNavigate()
    const { browserTitle } = useOutletContext<{ browserTitle: React.ReactNode }>()
    const { domains, products } = useAppSelector((state) => state.app.appSettings.section.shop)
    const [{ name }, setActiveProductGroup] = useShopActiveGroup()

    /***** QUERIES *****/
    const { data: productGroupData, isLoading: isProductGroupDataLoading } = shopAPI.useProductGroupsQuery()
    const prefetchGroupList = shopAPI.usePrefetch('productGroupList', { ifOlderThan: 60 * 5 })

    /***** FUNCTIONS *****/
    const handleTabClick = (group: IProductGroup) => () => {
        if (location.pathname.includes('/configure')) {
            navigate('/shop/purchase')
        }

        setActiveProductGroup(group, true)
    }

    /***** RENDER *****/
    return (
        <>
            <S.Tabs>
                {!!domains && (
                    <S.Tab active={name === 'Domain Names'} onClick={handleTabClick(DomainContent)}>
                        Domain Names
                    </S.Tab>
                )}
                {!!products &&
                    productGroupData?.map((group: IProductGroup, index) => (
                        <S.Tab
                            key={index}
                            active={name === group.name}
                            onMouseOver={() => prefetchGroupList(group.id)}
                            onClick={handleTabClick(group)}
                        >
                            {group.name}
                        </S.Tab>
                    ))}
                {isProductGroupDataLoading && (
                    <S.TabLoader>
                        <Loader.Basic />
                    </S.TabLoader>
                )}
            </S.Tabs>
            <S.Content>
                <div>
                    {browserTitle}
                    <S.Browse.Base>
                        <S.Browse.Content>
                            <Outlet />
                        </S.Browse.Content>
                    </S.Browse.Base>
                </div>
                <ShopSummary />
            </S.Content>
        </>
    )
}
