/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import styled from 'styled-components'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Theme } from 'nxui/src'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Button } from 'components/buttons'
import { InputField } from 'components/form/input'
import { StyleableFormikProvider } from 'components/form/provider'
import { FieldErrorIcon } from 'components/form/util/error/icon'
import { Flex } from 'components/utilities/Flex'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { WebsiteStyles } from '../style'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
export const WebsiteHomeStyles = {
    BannerInner: WebsiteStyles.Components.BannerInner,

    HeroBanner: styled(Flex).attrs({ align: 'center', justify: 'center' })`
        ${WebsiteStyles.Helpers.Banner}
        background-color: ${({ theme }) => theme['primary--100']};
    `,

    HeroHeading: styled.h1`
        ${WebsiteStyles.Helpers.Fonts.h1}

        text-align: center;
        color: ${({ theme }) => theme['white--100']};
    `,

    Icons: {
        MagnifyingGlass: styled(MagnifyingGlassIcon)`
            stroke-width: 2;
        `
    },

    Container: styled.div`
        width: 100%;
        background: ${({ theme }) => theme['background--000']};
    `,

    SubHeading: styled.p`
        margin-top: 20px;
        font-size: 18px;
        min-height: 22px;
        text-align: center;
        font-weight: 500;

        color: ${({ theme }) => theme['white--100']};
    `,

    SearchFormikProvider: styled(StyleableFormikProvider)`
        margin-top: 35px;

        color: ${({ theme }) => theme['white--100']};

        ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-sm'])} {
            margin-top: 60px;
        }
    `,

    InputField: styled(InputField)`
        flex-grow: 1;

        ${FieldErrorIcon.Styles.Icon.Wrapper} {
            padding: 0 15px;

            svg {
                width: 25px;
            }
        }

        ${InputField.Styles.Base} {
            padding-left: 20px;
            height: 52px;
            font-size: 18px;
            font-weight: 500;

            &::placeholder {
                font-size: 18px;
                font-weight: 500;
                letter-spacing: -0.5px;

                color: ${({ theme }) => theme['text--100']};
            }
        }

        ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-sm'])} {
            ${InputField.Styles.Base} {
                height: 42px;
            }
        }
    `,

    Button: styled(Button.Submit)`
        background: ${({ theme }) => theme['black--100']};
        border: 1px solid ${({ theme }) => theme['black--100']};
        color: ${({ theme }) => theme['white--100']};
        height: auto;
        width: 140px;
        font-size: 18px;

        &:hover {
            background: ${({ theme }) => theme['black--200']};
        }

        span {
            color: ${({ theme }) => theme['white--100']};
        }
    `
}
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/
