/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export class ShopRouteBuilder {
    public static checkout() {
        return `/shop/checkout`
    }

    public static landing() {
        return `/shop/landing`
    }

    public static order() {
        return `/shop/order`
    }

    public static configure(groupId?: string) {
        return `/shop/${groupId}/configure`
    }

    public static purchase(groupId?: string) {
        return `/shop/${groupId}/purchase`
    }

    public static orderId(orderId?: string | number) {
        return `/shop/order/${orderId}`
    }

    public static configureDomains(groupId?: string, id?: string | number) {
        return `/shop/${groupId}/configure/domains/${id}`
    }

    public static configureServices(groupId?: string, id?: string | number) {
        return `/shop/${groupId}/configure/services/${id}`
    }

    public static configureServicesAddons(groupId?: string, id?: string | number, addonId?: string | number) {
        return `/shop/${groupId}/configure/services/${id}/addons/${addonId}`
    }
}
