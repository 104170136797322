/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *    HIGHT LEVEL ROUTES
 **********************************************************************************************************/

import AuthSignUp from 'containers/authentication/components/auth.signup.component'
import Auth2FAForm from 'containers/authentication/forms/auth.2fa.form'
import AuthAccountForm from 'containers/authentication/forms/auth.account.form'
import AuthLoginForm from 'containers/authentication/forms/auth.auth.form'
import AuthForgotForm from 'containers/authentication/forms/auth.forgot.form'
import AuthRecoveryForm from 'containers/authentication/forms/auth.recovery.form'
import AuthResetForm from 'containers/authentication/forms/auth.reset.form'
import AuthSignUpForm from 'containers/authentication/forms/signup'

import GuestRoot from 'router/guestRoot'

import { Container } from 'components/error/errorBoundary'
import { Status } from 'nxui/src'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import AuthLogin from 'containers/authentication/components/auth.login'
import { useShopRoutes } from 'router/hooks/useShopRoutes'
import { useWebsiteRoutes } from './hooks/useWebsiteRoutes'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function GuestRouter() {
    /***** HOOKS *****/
    const { settingsSections } = useAppSelector((state) => state.app.appSettings.section)

    /***** ROUTES *****/
    const routerWebsiteRoutes = useWebsiteRoutes()
    const shopRoutes = useShopRoutes('guest')

    /***** RENDER *****/
    if (typeof settingsSections === 'object' && settingsSections && !('login' in settingsSections)) {
        const router = createBrowserRouter([
            {
                path: '*',
                element: (
                    <Container>
                        <Status status={404} />
                    </Container>
                )
            }
        ])

        return <RouterProvider router={router} />
    }

    const router = createBrowserRouter([
        { path: '*', element: <Navigate to='/login' replace /> },
        {
            path: '/',
            element: <GuestRoot />,
            children: [
                {
                    path: 'login',
                    element: <AuthLogin />,
                    children: [
                        { index: true, element: <AuthLoginForm /> },
                        { path: 'reset-password/:token', element: <AuthResetForm /> },
                        { path: 'signup', element: <AuthSignUpForm /> },
                        { path: 'forgot', element: <AuthForgotForm /> },
                        {
                            path: 'two-factor',
                            element: <Auth2FAForm />
                        },
                        {
                            path: 'recovery',
                            element: <AuthRecoveryForm />
                        },
                        {
                            path: 'accounts',
                            element: <AuthAccountForm />
                        }
                    ]
                },
                { path: 'register/:token', element: <AuthSignUp /> },
                { path: 'register/:token/*', element: <AuthSignUp /> },
                { path: 'tos/*', element: <Navigate to='/login' replace /> },
                ...shopRoutes,
                ...routerWebsiteRoutes()
            ]
        }
    ])

    return <RouterProvider router={router} />
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
