/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { WebsiteFeatureStyles as S } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TWebsiteFeature = React.FC<{
    icon: React.FC<React.RefAttributes<SVGSVGElement>>
    title: string
    children: React.ReactNode
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const WebsiteFeature: TWebsiteFeature = ({ icon: Icon, title, children }) => {
    /***** RENDER *****/
    return (
        <S.FlexContainer align='center' direction='column'>
            <Icon />
            <S.Title>{title}</S.Title>
            <S.Description>{children}</S.Description>
        </S.FlexContainer>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
