/* eslint-disable indent */
/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled, { css } from 'styled-components'
import * as Theme from '../../theme/theme'

export const Frame = {
    Base: styled.div`
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        background-color: ${(props) => props.theme[`background--200`]};

        ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-lg`])} {
            flex-direction: column;
            ${({ guest }) => {
                return (
                    !guest &&
                    css`
                        margin-top: 65px;
                    `
                )
            }}
        }
    `,
    Simple: styled.div`
        position: relative;
    `
}

export const Flex = {
    Full: styled.div`
        display: flex;
        flex-direction: row;
        padding: 0 16px;
        width: 100%;
        height: 100%;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-xl`])} {
            flex-direction: column;
        }
    `,
    Split: styled.div`
        display: flex;
        flex-direction: row;
        flex: 1 1 0%;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-xl`])} {
            flex-direction: column;
        }
    `,
    Wrapper: styled.div`
        display: block;
        padding: 0 16px 16px 16px;
        width: 100%;
        overflow: auto;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-xl`])} {
            padding-bottom: 64px;
        }
    `
}

export const Inset = {
    Full: styled.section`
        position: relative;
    `,
    Left: styled.aside`
        display: flex;
        flex: 0 2 0%;
        min-width: 450px;
        margin-right: 16px;
        ${Theme.Functions.spaceY(`16px`)};

        ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-xl`])} {
            position: relative;
            margin-right: 0;
            min-width: unset;
        }
    `,
    Right: styled.section`
        width: 100%;
        display: flex;
        flex-direction: column;
    `
}
