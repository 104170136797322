/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import { useFormikContext } from 'formik'
import { useEffect, useRef, useState } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { RenderInputLabel, Style } from '../util'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled from 'styled-components'

export const Input = {
    Wrapper: styled.div`
        margin-bottom: 8px;
    `,
    Base: styled.div`
        width: 14rem;
        height: 38px;
        position: relative;
        flex-direction: row;
        display: flex;
        border-radius: 0.5rem;
        border: 1px solid ${(props) => props.theme[`border--100`]};
        overflow: hidden;
    `,
    Label: styled.label`
        ${Style.Label}
    `,
    Button: styled.button`
        width: 7rem;
        outline: 0;
        height: 100%;
        background-color: ${(props) => props.theme[`background--100`]};
        display: flex;
        align-items: center;
        justify-content: center;
        &:disabled {
            > span {
                color: ${(props) => props.theme[`text--100`]};
            }
        }
    `,
    Icon: styled.span`
        width: var(--size);
        height: var(--size);
        color: ${(props) => props.theme[`text--200`]};
    `,
    Number: styled.input`
        width: 100%;
        outline: 0;
        font-size: 1.2rem;
        font-weight: 600;
        text-align: center;
        align-items: center;
        display: flex;
        color: ${(props) => props.theme[`text--200`]};

        &[type='number'] {
            -moz-appearance: textfield;
        }

        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    `
}

/**********************************************************************************************************
 *   INPUT FIELD
 **********************************************************************************************************/
export const NumberField = ({ embedded, required, label, field, disabled, form: { touched, errors, isSubmitting }, ...props }) => {
    const { setFieldValue } = useFormikContext()
    /*   REF
     *****************************************************/
    const inputRef = useRef()

    /*   STATE
     *****************************************************/
    const [hasFocus, setFocus] = useState(false)

    /*   EFFECT
     *****************************************************/
    useEffect(() => {
        if (document.hasFocus() && inputRef.current.contains(document.activeElement)) {
            setFocus(true)
        }

        if (!field.value) {
            setFieldValue(field.name, 0)
        }
    }, [])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Input.Wrapper>
            {RenderInputLabel(embedded, required, label, field)}
            <Input.Base focus={hasFocus}>
                <Input.Button
                    type='button'
                    data-action='decrement'
                    disabled={disabled || (props?.min ? Number(props.min) === field.value : false)}
                    onClick={() => {
                        if (props?.step) {
                            setFieldValue(field.name, field.value - Number(props.step))
                        } else {
                            setFieldValue(field.name, field.value - 1)
                        }
                    }}
                >
                    <Input.Icon style={{ '--size': 18 + 'px' }}>
                        <ChevronDownIcon id='chevron-down' />
                    </Input.Icon>
                </Input.Button>
                <Input.Number
                    {...field}
                    {...props}
                    ref={inputRef}
                    onFocus={() => setFocus(true)}
                    onBlur={(e) => {
                        field.onBlur?.(e)
                        setFocus(false)
                    }}
                    disabled={isSubmitting || disabled}
                />
                <Input.Button
                    type='button'
                    data-action='increment'
                    disabled={disabled || (props?.max ? Number(props.max) === field.value : false)}
                    onClick={() => {
                        if (props?.step) {
                            setFieldValue(field.name, field.value + Number(props.step))
                        } else {
                            setFieldValue(field.name, field.value + 1)
                        }
                    }}
                >
                    <Input.Icon style={{ '--size': 18 + 'px' }}>
                        <ChevronUpIcon id='chevron-up' />
                    </Input.Icon>
                </Input.Button>
            </Input.Base>
        </Input.Wrapper>
    )
}
