/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Preset } from './addPreset.styles'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { LightboxBody } from 'nxui/src'

const { Confirm } = Preset

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ConfirmPreset = ({
    isOpen,
    loading,
    onConfirm,
    onClose
}: {
    isOpen: boolean
    loading: boolean
    onConfirm: () => void
    onClose: () => void
}) => {
    if (!isOpen) return null

    return (
        <>
            <LightboxBody>
                <Confirm.Outer>
                    <Confirm.IconBackdrop>
                        <Confirm.Icon />
                    </Confirm.IconBackdrop>
                    <Confirm.Title>Confirm DNS Change</Confirm.Title>
                    <Confirm.Description>
                        Please Note! Please be aware that the preset records you are about to add may conflict with some of your existing records.
                    </Confirm.Description>
                    <Confirm.Acknowledge loading={loading} onClick={onConfirm}>
                        I understand, apply DNS Preset
                    </Confirm.Acknowledge>
                </Confirm.Outer>
            </LightboxBody>
            <Confirm.Cancel onClick={onClose}>Cancel</Confirm.Cancel>
        </>
    )
}
