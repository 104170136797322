/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import React from 'react'

/**********************************************************************************************************
 *   STYLE
 **********************************************************************************************************/
import { Basic } from './basic.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const BasicLoaderComponent = (props) => {
    return (
        <Basic.Base id={'loader'}>
            <Basic.SVG xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
                <Basic.Circle cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></Basic.Circle>
                <Basic.Path
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                ></Basic.Path>
            </Basic.SVG>
            {props.message && <Basic.Message>{props.message}</Basic.Message>}
        </Basic.Base>
    )
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { BasicLoaderComponent as Basic }
