import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useInvoiceQuery, usePayInvoiceMutation } from 'api/billing'
import { useCheckoutCartMutation } from 'api/shop'
import { paymentProcessingMessage } from 'App/linkPaymentReturnLightbox'
import { Breakdown, Invoice, Line, Summary } from 'components/invoice/invoice.styles'
import { CheckoutOrder } from 'containers/shop/shop.styles'
import { defaultCurrency, getInvoiceStatusColour, parseDateToReadable, uid } from 'helpers/utils'
import { Badge, Card, Loader } from 'nxui/src'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setAppActiveInvoice, setCart } from 'store/slices/appSlice'
import { OrderCompleteTokenTypePayment } from './checkout/tokenTypeForm/OrderCompleteTokenTypePayment'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function Order() {
    /***** HOOKS *****/
    const dispatch = useAppDispatch()
    const { orderId } = useParams()
    const navigate = useNavigate()

    /***** SELECTORS *****/
    const { current_account } = useAppSelector((state) => state.app.appSession)
    const { invoiceId, processingStatus, paymentStatus } = useAppSelector((state) => state.app.appLinkTypePaymentState)
    const appTokenTypeOrderPaymentState = useAppSelector((state) => state.app.appTokenTypeOrderPaymentState)

    /***** QUERIES *****/
    const [, { data: order, reset: resetCheckoutCart }] = useCheckoutCartMutation({ fixedCacheKey: 'checkout-cart' })
    const [, { isError: isPayInvoiceError }] = usePayInvoiceMutation({ fixedCacheKey: 'pay-checkout-invoice' })
    const { data: invoice, isLoading: isInvoiceLoading } = useInvoiceQuery(order?.invoice.id || Number(invoiceId || 0) || skipToken)

    /***** DERIVES *****/
    const isLinkTypePaymentStillProcessing = processingStatus === 'in-progress'

    /***** EFFECTS *****/
    // If user hasn't come straight from checkout, or from successful link type payment, redirect them back to the shop
    useEffect(() => {
        if (!order && !invoiceId) {
            navigate('/shop/purchase')
        }

        return () => {
            resetCheckoutCart()
            dispatch(setCart(undefined))
        }
    }, [invoiceId])

    function renderOrderSummary() {
        if (isInvoiceLoading) {
            return <Loader.Skeleton sections={['title', 'content', 'footer']} />
        }

        if (!invoice) return ''

        // If the order is successful but there is an error with the payment
        if (isPayInvoiceError || appTokenTypeOrderPaymentState.status === 'error') {
            return (
                <Card
                    footer={
                        <CheckoutOrder.Footer>
                            <CheckoutOrder.AnchorButton
                                onClick={() => {
                                    navigate('/billing/invoices?page=1&sort_by=-id')
                                    dispatch(setAppActiveInvoice({ invoice: invoice || order?.invoice, status: true }))
                                }}
                            >
                                Re-attempt Payment
                            </CheckoutOrder.AnchorButton>
                        </CheckoutOrder.Footer>
                    }
                >
                    <CheckoutOrder.Content>
                        <CheckoutOrder.IconWarning>
                            <ExclamationCircleIcon />
                        </CheckoutOrder.IconWarning>
                        <CheckoutOrder.Header>
                            <h1>Order Complete But Payment Failed</h1>
                            <h2>
                                <span>Order Number</span>
                                <Badge color={'primary'}>{orderId}</Badge>
                            </h2>
                        </CheckoutOrder.Header>
                        <CheckoutOrder.Message>
                            Your order was completed but payment of the invoice failed. Please re-attemp payment of the invoice so that the order can
                            be fulfilled.
                        </CheckoutOrder.Message>
                    </CheckoutOrder.Content>
                </Card>
            )
        }

        // When the user goes back from stripe or paypal checkout via the browser back button, or via the stripe back button
        if (['canceled', 'used-browser-back'].includes(paymentStatus || '')) {
            return (
                <Card
                    footer={
                        <CheckoutOrder.Footer>
                            <CheckoutOrder.AnchorButton
                                onClick={() => {
                                    navigate('/billing/invoices?page=1&sort_by=-id')
                                    dispatch(setAppActiveInvoice({ invoice: invoice || order?.invoice, status: true }))
                                }}
                            >
                                Pay Invoice
                            </CheckoutOrder.AnchorButton>
                        </CheckoutOrder.Footer>
                    }
                >
                    <CheckoutOrder.Content>
                        <CheckoutOrder.IconWarning>
                            <ExclamationCircleIcon />
                        </CheckoutOrder.IconWarning>
                        <CheckoutOrder.Header>
                            <h1>Payment Not Completed</h1>
                            <h2>
                                <span>Order Number</span>
                                <Badge color={'primary'}>{orderId}</Badge>
                            </h2>
                        </CheckoutOrder.Header>
                        <CheckoutOrder.Message>
                            Your order was successful but it looks like payment of the invoice was not completed. Please visit the billing page to
                            procceed with payment so that the order can be fulfilled.
                        </CheckoutOrder.Message>
                    </CheckoutOrder.Content>
                </Card>
            )
        }

        // When the user is redirected back from a successful stripe or paypal payment
        if (isLinkTypePaymentStillProcessing) {
            return (
                <Card
                    footer={
                        <CheckoutOrder.Footer>
                            <CheckoutOrder.Anchor to={'/shop/purchase'}>Back to Shop</CheckoutOrder.Anchor>
                        </CheckoutOrder.Footer>
                    }
                >
                    <CheckoutOrder.Content>
                        <CheckoutOrder.IconSuccess>
                            <CheckIcon />
                        </CheckoutOrder.IconSuccess>
                        <CheckoutOrder.Header>
                            <h1>Order Complete</h1>
                            <h2>
                                <span>Order Number</span>
                                <Badge color={'primary'}>{orderId}</Badge>
                            </h2>
                        </CheckoutOrder.Header>
                        <CheckoutOrder.Message>
                            {paymentProcessingMessage}
                            <br />
                            <br />
                            You will receive a confirmation email with you order details shortly. Your newly purchased domains and services can be
                            managed in the Domains and Services pages respectively.
                        </CheckoutOrder.Message>
                    </CheckoutOrder.Content>
                </Card>
            )
        }

        // If the order is successful and the selected payment method was something async like EFT or BPAY, therefore the invoice is still unpaid
        if (invoice.status === 'unpaid') {
            return (
                <Card
                    footer={
                        <CheckoutOrder.Footer>
                            <CheckoutOrder.AnchorButton
                                onClick={() => {
                                    navigate('/billing/invoices?page=1&sort_by=-id')
                                    dispatch(setAppActiveInvoice({ invoice: invoice || order?.invoice, status: true }))
                                }}
                            >
                                Pay Invoice
                            </CheckoutOrder.AnchorButton>
                        </CheckoutOrder.Footer>
                    }
                >
                    <CheckoutOrder.Content>
                        <CheckoutOrder.IconSuccess>
                            <CheckIcon />
                        </CheckoutOrder.IconSuccess>
                        <CheckoutOrder.Header>
                            <h1>Order Complete</h1>
                            <h2>
                                <span>Order Number</span>
                                <Badge color={'primary'}>{orderId}</Badge>
                            </h2>
                        </CheckoutOrder.Header>
                        <CheckoutOrder.Message>
                            Your order was completed successfully. Please pay the invoice via your chosen payment method so that the order can be
                            fulfilled.
                        </CheckoutOrder.Message>
                    </CheckoutOrder.Content>
                </Card>
            )
        }

        // If the order is successful and fully paid for with no issues
        return (
            <Card
                footer={
                    <CheckoutOrder.Footer>
                        <CheckoutOrder.Anchor to={'/shop/purchase'}>Back to Shop</CheckoutOrder.Anchor>
                    </CheckoutOrder.Footer>
                }
            >
                <CheckoutOrder.Content>
                    <CheckoutOrder.IconSuccess>
                        <CheckIcon />
                    </CheckoutOrder.IconSuccess>
                    <CheckoutOrder.Header>
                        <h1>Order Complete</h1>
                        <h2>
                            <span>Order Number</span>
                            <Badge color={'primary'}>{orderId}</Badge>
                        </h2>
                    </CheckoutOrder.Header>
                    <CheckoutOrder.Message>
                        You will receive a confirmation email with you order details shortly. Your newly purchased domains and services can be managed
                        in the Domains and Services pages respectively.
                    </CheckoutOrder.Message>
                </CheckoutOrder.Content>
            </Card>
        )
    }

    function renderInvoice() {
        if (isInvoiceLoading) {
            return <Loader.Skeleton sections={['title', 'content', 'footer']} />
        }

        if (!invoice) return ''

        const { status, subtotal, total_discount, total_paid, credit_used, tax, total, amount_due } = invoice

        return (
            <div style={{ flex: 1 }}>
                <Card>
                    <CheckoutOrder.InvoiceHeader>
                        <h2>
                            Invoice <strong>{invoice.id}</strong>
                        </h2>
                        <Invoice.Status color={getInvoiceStatusColour(invoice.status)}>{invoice.status}</Invoice.Status>
                    </CheckoutOrder.InvoiceHeader>
                    <Invoice.Details>
                        <Invoice.Row key={uid()}>
                            <Invoice.Column.Base key={uid()}>
                                <Invoice.Column.Title>Account #</Invoice.Column.Title>
                                <Invoice.Column.Render>
                                    {current_account?.account && <Badge>{current_account.account.account_number}</Badge>}
                                </Invoice.Column.Render>
                            </Invoice.Column.Base>
                        </Invoice.Row>
                        <Invoice.Row key={uid()}>
                            <Invoice.Column.Base key={uid()}>
                                <Invoice.Column.Title>Due Date</Invoice.Column.Title>
                                <Invoice.Column.Render>{invoice ? parseDateToReadable(invoice.due_date) : ''}</Invoice.Column.Render>
                            </Invoice.Column.Base>
                            <Invoice.Column.Base key={uid()}>
                                <Invoice.Column.Title>Date Issued</Invoice.Column.Title>
                                <Invoice.Column.Render>{invoice ? parseDateToReadable(invoice?.created_at) : ''}</Invoice.Column.Render>
                            </Invoice.Column.Base>
                        </Invoice.Row>
                        {invoice?.status === 'paid' ? (
                            <Invoice.Row key={uid()}>
                                <Invoice.Column.Base key={uid()}>
                                    <Invoice.Column.Title>Paid Date</Invoice.Column.Title>
                                    <Invoice.Column.Render>{invoice ? parseDateToReadable(invoice.date_paid) : ''}</Invoice.Column.Render>
                                </Invoice.Column.Base>
                            </Invoice.Row>
                        ) : (
                            <Invoice.Row key={uid()}>
                                <Invoice.Column.Base key={uid()}>
                                    <Invoice.Column.Title>Amount Paid</Invoice.Column.Title>
                                    <Invoice.Column.Render>{defaultCurrency(invoice?.total_paid)}</Invoice.Column.Render>
                                </Invoice.Column.Base>
                            </Invoice.Row>
                        )}
                    </Invoice.Details>
                    <Invoice.Breakdown>
                        <Breakdown.Title>Invoice Breakdown</Breakdown.Title>
                        <Breakdown.Content>
                            {invoice?.invoice_items?.map(({ id, description, total }) => (
                                <Line.Row key={id}>
                                    <Line.Title>{description}</Line.Title>
                                    <Line.Price>{defaultCurrency(total)}</Line.Price>
                                </Line.Row>
                            ))}
                        </Breakdown.Content>
                    </Invoice.Breakdown>
                    <CheckoutOrder.InvoiceFooter>
                        <Summary.Base>
                            <Summary.Details>
                                {subtotal && (
                                    <Line.Row>
                                        <Line.Title>Subtotal</Line.Title>
                                        <Line.Price>{defaultCurrency(subtotal)}</Line.Price>
                                    </Line.Row>
                                )}
                                {total_discount && total_discount !== '0.00' && (
                                    <Line.Row>
                                        <Line.Title>Discount</Line.Title>
                                        <Line.Price>{defaultCurrency(total_discount)}</Line.Price>
                                    </Line.Row>
                                )}
                                {credit_used && credit_used !== '0.00' && (
                                    <Line.Row>
                                        <Line.Title>Credit Applied</Line.Title>
                                        <Line.Price>{defaultCurrency(credit_used)}</Line.Price>
                                    </Line.Row>
                                )}
                                {tax && tax !== '0.00' && (
                                    <Line.Row>
                                        <Line.Title>GST</Line.Title>
                                        <Line.Price>{defaultCurrency(tax)}</Line.Price>
                                    </Line.Row>
                                )}
                                {total && (
                                    <Line.Row>
                                        <Line.Title>Total</Line.Title>
                                        <Line.Price>{defaultCurrency(total)}</Line.Price>
                                    </Line.Row>
                                )}
                                <Summary.Total.Base>
                                    <Summary.Total.Title>
                                        {status === 'paid' && total_paid && total_paid !== '0.00' ? 'Total Amount Paid' : 'Total Amount Due'}
                                    </Summary.Total.Title>
                                    <Summary.Total.Price>{defaultCurrency(status === 'paid' ? total_paid : amount_due)}</Summary.Total.Price>
                                </Summary.Total.Base>
                            </Summary.Details>
                        </Summary.Base>
                    </CheckoutOrder.InvoiceFooter>
                </Card>
            </div>
        )
    }

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <>
            <CheckoutOrder.Base>
                <CheckoutOrder.Container>{renderOrderSummary()}</CheckoutOrder.Container>
                <CheckoutOrder.Container>{renderInvoice()}</CheckoutOrder.Container>
            </CheckoutOrder.Base>
            <OrderCompleteTokenTypePayment />
        </>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
