/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled, { keyframes } from 'styled-components'
import * as Theme from '../../../theme/theme'

import { Basic } from '../basic'

const ALoading = keyframes`
     to {
         background-position: -150% 0px;
     }
 `

export const Skeleton = {
    Base: styled.div`
        cursor: progress;
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 16px;
        min-width: 280px;
        width: 100%;
        height: 100%;
        padding: 20px;
        border-radius: 16px;
        background-color: ${(props) => props.theme[`background--000`]};
        overflow: hidden;
        box-shadow: 0 1px 3px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--100`], `0.1`)},
            0 1px 2px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--100`], `0.06`)};
    `,
    Title: styled.span`
        height: 44px;
        min-width: 130px;
        border-radius: 8px;
        background-color: ${(props) => props.theme[`background--200`]};
    `,
    Description: styled.span`
        height: 44px;
        min-width: 600px;
        margin-top: 16px;
        border-radius: 8px;
        background-color: ${(props) => props.theme[`background--200`]};

        ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-md`])} {
            min-width: 100%;
        }
    `,
    Content: styled.div`
        height: 128px;
        width: 100%;
        margin-top: 16px;
        border-radius: 8px;
        background-color: ${(props) => props.theme[`background--200`]};
    `,
    Footer: styled.div`
        height: 44px;
        width: 100%;
        margin-top: 16px;
        border-radius: 8px;
        background-color: ${(props) => props.theme[`background--200`]};
    `,
    Gradient: styled.div`
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: linear-gradient(-45deg, transparent, ${(props) => props.theme[`background--000`]}, transparent);
        background-position: 250% 0px;
        background-size: 250% 250%;
        background-repeat: no-repeat;
        animation: ${ALoading} 2s ease-in-out infinite;
    `,
    Corner: styled(Basic)`
        position: absolute;
        top: 15px;
        right: 5px;
        height: 30px;
        width: 30px;
    `,
    Message: styled.span`
        color: ${(props) => props.theme[`text--100`]};
        font-size: 1.4rem;
        letter-spacing: -0.25px;
        ${Theme.Functions.setFont('Inter', 500)};
    `
}
