/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as Theme from '../../theme/theme'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { ArrowRightCircleIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import { Anchor } from '../anchor'

/**********************************************************************************************************
 *   STYLE
 **********************************************************************************************************/
const StyledBubble = styled.div`
    position: relative;
    width: 100%;
    min-width: 300px;
    height: fit-content;
    padding: 20px 25px;
    border-radius: 16px;
    background-color: ${(props) => props.theme[`primary--100`]};
    overflow: hidden;
    box-shadow: 0 1px 3px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--100`], `0.1`)},
        0 1px 2px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--100`], `0.06`)};
    > h3 {
        padding: 0;
        color: ${(props) => props.theme[`white--100`]};
        font-size: 1.4rem;
    }

    > h1 {
        padding: 0;
        color: ${(props) => props.theme[`white--100`]};
        font-size: 4rem;
    }
`

const StyledButtonIcon = styled.span`
    position: absolute;
    top: 0px;
    right: 0;
    width: 180px;
    opacity: 0.1;
    color: ${(props) => props.theme[`white--100`]};
`

const StyledButtonIconPlaceholder = styled(ArrowRightCircleIcon)`
    position: absolute;
    top: 0px;
    right: 0;
    width: 180px;
    opacity: 0.1;
    color: ${(props) => props.theme[`white--100`]};
`

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Bubble = ({ className, title, desc, icon, action }) => {
    return (
        <StyledBubble className={`${className ? ` ${className}` : ''}`}>
            <h3>{title}</h3>
            <h1>{desc}</h1>
            {action && (
                <Anchor color={`alternate`} onClick={action.onClick} icon={<ArrowRightIcon />}>
                    {action.label}
                </Anchor>
            )}
            {icon ? <StyledButtonIcon>{icon}</StyledButtonIcon> : <StyledButtonIconPlaceholder />}
        </StyledBubble>
    )
}

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
Bubble.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    details: PropTypes.string
}
