/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useState } from 'react'

/**********************************************************************************************************
 *   SHARED COMPONENTS
 **********************************************************************************************************/
import { Loader } from 'components/loader'
import { Button, LFB, Lightbox } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useGetAuthorisedAccountsQuery, useSelectAccountMutation } from 'api/authentication'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setSwitchAccountOpen } from 'store/slices/appSlice'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { useAppDispatch, useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Auth, SelectAccount } from 'containers/authentication/authentication.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function SwitchAccountComponent() {
    const dispatch = useAppDispatch()
    const [selectedUser, setSelectedUser] = useState<number | null>(null)
    const { appSwitchAccount, appAuthentication, appSession } = useAppSelector((state) => state.app)
    const { authorisedAccounts } = appAuthentication
    const { current_account } = appSession
    const { isLoading: accountsListLoading, isError: accountsListError } = useGetAuthorisedAccountsQuery(undefined, {
        skip: !!authorisedAccounts
    })
    const [selectAccount, { isLoading: isSelectUserLoading }] = useSelectAccountMutation()

    function renderAccounts() {
        if (!authorisedAccounts) return null

        if (accountsListLoading) return <Loader message={'Fetching accounts list...'} width={38} height={38} />

        if (accountsListError) return <Loader message={'Fail to fetch account list. Logging out...'} width={38} height={38} />

        return authorisedAccounts.map(({ role, account }) => (
            <LFB
                key={account.id}
                loading={isSelectUserLoading && selectedUser === account.id}
                disabled={(isSelectUserLoading && selectedUser !== account.id) || current_account?.account.id === account.id}
                label={account.account_holder}
                description={account.company_name ? `${role.name} - ${account.company_name}` : role.name}
                onClick={() => {
                    setSelectedUser(account.id)
                    selectAccount(account.id)
                }}
            />
        ))
    }

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Lightbox
            type={'custom'}
            content={
                <>
                    <Auth.Lightbox.Base>
                        <Auth.Title>Select an Account</Auth.Title>
                        <Auth.Description>Select one of the following user accounts to continue.</Auth.Description>
                        <Auth.Break />
                        <Auth.Row>
                            <SelectAccount.Container>{renderAccounts()}</SelectAccount.Container>
                        </Auth.Row>
                    </Auth.Lightbox.Base>
                    <Auth.Lightbox.Footer>
                        <Button type={'button'} color={'secondary'} onClick={() => dispatch(setSwitchAccountOpen(false))}>
                            Back
                        </Button>
                    </Auth.Lightbox.Footer>
                </>
            }
            conditions={{ state: appSwitchAccount, action: () => dispatch(setSwitchAccountOpen(false)) }}
            actions={[
                {
                    label: 'Back',
                    disabled: false,
                    type: 'button',
                    func: () => dispatch(setSwitchAccountOpen(false))
                }
            ]}
            close={() => dispatch(setSwitchAccountOpen(false))}
        />
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { SwitchAccountComponent as SwitchAccount }
