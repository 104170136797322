/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { FieldStyles as S } from 'components/form/shared.style'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
const Label = styled.label`
    ${S.Label}
`
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/

/***** EXPORTS *****/
export const FieldLabelStyles = {
    Label
}
