import { billingAPI, usePayInvoiceMutation } from 'api/billing'
import { useCheckoutCartMutation } from 'api/shop'
import { Payment } from 'components/invoice/invoice.styles'
import { useApplyAccountCreditToInvoice } from 'containers/billing/utils'
import { ShopRouteBuilder } from 'containers/shop/helpers/routeMatchers'
import { Field, Formik } from 'formik'
import { PaymentMethod } from 'models/billing'
import { Form as NXUIForm } from 'nxui/src'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import * as Yup from 'yup'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function CreditCardForm({ selectedPaymentMethod }: { selectedPaymentMethod?: PaymentMethod }) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { cart } = useAppSelector((state) => state.app)
    const { checkoutAccountCredit } = useAppSelector((state) => state.shop)
    const [checkoutCart, { isLoading: isCheckoutCartLoading }] = useCheckoutCartMutation({ fixedCacheKey: 'checkout-cart' })
    const [payInvoice, { isLoading: isPayInvoiceLoading }] = usePayInvoiceMutation({ fixedCacheKey: 'pay-checkout-invoice' })
    const { applyAccountCreditToInvoice } = useApplyAccountCreditToInvoice()

    /*   RENDER COMPONENT
     *****************************************************/
    if (!selectedPaymentMethod || !cart) return null

    return (
        <Formik
            initialValues={{
                card_holder: '',
                cardNumber: '',
                cvc: '',
                expiryDate: '',
                save_details: false
            }}
            validationSchema={Yup.object({
                card_holder: Yup.string().required('Required'),
                cardNumber: Yup.string().required('Card Number is required'),
                cvc: Yup.string().required('Required'),
                expiryDate: Yup.string().required('Required'),
                save_details: Yup.boolean()
            })}
            onSubmit={async ({ card_holder, cardNumber, cvc, expiryDate, save_details }) => {
                const payment_data = {
                    card_holder,
                    card_number: cardNumber.replace(/\s+/g, ''),
                    expiry: expiryDate.replace(/\s+/g, ''),
                    cvv: cvc
                }

                const order = await checkoutCart({
                    payment_method_id: selectedPaymentMethod.id,
                    uuid: cart.uuid,
                    payment_data
                }).unwrap()

                const { isInvoiceStillUnpaid, isSuccess } = await applyAccountCreditToInvoice(order.invoice.id, checkoutAccountCredit.amount)

                if (isSuccess && isInvoiceStillUnpaid) {
                    await payInvoice({
                        id: String(order.invoice.id),
                        payment_data: { ...payment_data, save_details, payment_method_id: selectedPaymentMethod.id },
                        payment_method_id: selectedPaymentMethod.id
                    })
                }

                dispatch(billingAPI.endpoints.invoice.initiate(order.invoice.id, { forceRefetch: true })).unwrap()
                navigate(ShopRouteBuilder.orderId(order.id))
            }}
        >
            {() => {
                return (
                    <Payment.Form id='creditCardForm'>
                        <Field
                            id={'card_holder'}
                            label={'Cardholder Name'}
                            name={'card_holder'}
                            type={'text'}
                            disabled={isCheckoutCartLoading || isPayInvoiceLoading}
                            autoComplete={'name'}
                            component={NXUIForm.InputField}
                        />
                        <Field
                            id={'card_details'}
                            label={'Card Details'}
                            expiry
                            cvc
                            disabled={isCheckoutCartLoading || isPayInvoiceLoading}
                            component={NXUIForm.PaymentField}
                        />
                        <Field
                            name={'save_details'}
                            type={'checkbox'}
                            disabled={isCheckoutCartLoading || isPayInvoiceLoading}
                            description={'Save Details'}
                            component={NXUIForm.CheckboxField}
                        />
                    </Payment.Form>
                )
            }}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
