/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Styles } from './defaultNameservers.styles'
import { Dns } from 'containers/domains/domains.styles'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useDnsRecordsQuery } from 'api/domain'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const DefaultNameserversForm = () => {
    const { id } = useParams()
    const { data } = useDnsRecordsQuery(Number(id))

    return (
        <>
            {(data?.nameservers.length ?? 0) > 0 && <Styles.Title>Default Nameservers</Styles.Title>}
            {data?.nameservers?.map((nameserver: string) => (
                <Styles.Nameserver key={nameserver}>{nameserver}</Styles.Nameserver>
            ))}
            <Dns.Parked>Domain DNS is set to be Default Nameservers.</Dns.Parked>
        </>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default DefaultNameserversForm
