import { AdjustmentsVerticalIcon, PlusCircleIcon } from '@heroicons/react/20/solid'
import { shopAPI, useRemoveCartItemMutation, useRemovePromoCodeFromCartMutation, useUpdateCartItemsMutation } from 'api/shop'
import { CartItem, Checkout } from 'containers/shop/shop.styles'
import { useAppParams } from 'helpers/hooks/useAppParams'
import { defaultCurrency, getBillingCycle } from 'helpers/utils'
import { ICartAddon, ICartItem } from 'models/shop/cart.d'
import { Anchor, Badge, Form as NXUIForm } from 'nxui/src'
import { Dispatch, MutableRefObject, SetStateAction, useRef, useState } from 'react'
import { useShopNavigate } from 'router/helpers'
import { useAppDispatch } from 'store/hooks'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function CartItemComponent({ cartItem }: { cartItem: ICartItem }) {
    /***** STATE *****/
    const [showConfiguration, setShowConfiguration] = useState<{ toggle: boolean; item_uuid: string | undefined }>({
        toggle: false,
        item_uuid: undefined
    })
    const [showAddons, setShowAddons] = useState<{ toggle: boolean; item_uuid: string | undefined }>({
        toggle: false,
        item_uuid: undefined
    })

    /***** HOOKS *****/
    const configureRef = useRef<HTMLDivElement | null>(null)
    const addonsRef = useRef<HTMLDivElement | null>(null)
    const dispatch = useAppDispatch()
    const navigate = useShopNavigate()
    const { groupId } = useAppParams('/shop/:groupId')

    /***** QUERIES *****/
    const [updateCartItems] = useUpdateCartItemsMutation()
    const [removeCartItem] = useRemoveCartItemMutation()
    const [removePromoCodeFromCart] = useRemovePromoCodeFromCartMutation()

    const cartId = localStorage.getItem('cart_uuid')

    /*   FUNCTIONS
     *****************************************************/
    function getOrderSubtitle(cartItem: ICartItem | ICartAddon) {
        if ('order_type' in cartItem) {
            switch (cartItem.order_type) {
                case 'register':
                    return 'Domain Registration'

                case 'transfer':
                    return 'Domain Transfer'

                default:
                    return cartItem.product.name.toLowerCase()
            }
        }

        return cartItem.product.name.toLowerCase()
    }

    function getProductIcon(cartItem: ICartItem) {
        switch (cartItem.order_type) {
            case 'register':
                return <CartItem.Icon.Register />
            case 'transfer':
                return <CartItem.Icon.Transfer />
            default:
                return <CartItem.Icon.Product />
        }
    }

    function renderProductConfiguration(
        cartItem: ICartItem | ICartAddon,
        ref: MutableRefObject<HTMLDivElement | null>,
        [showConfiguration, setShowConfiguration]: [
            { toggle: boolean; item_uuid: string | undefined },
            Dispatch<SetStateAction<{ toggle: boolean; item_uuid: string | undefined }>>
        ]
    ) {
        switch (cartItem.product.service_type) {
            case 'domain':
                return (
                    <CartItem.Configure.Base>
                        <Checkout.Title
                            open={showConfiguration.toggle && showConfiguration.item_uuid === cartItem.item_uuid}
                            onClick={() =>
                                setShowConfiguration((prevShowConfiguration) => ({
                                    toggle: !prevShowConfiguration.toggle,
                                    item_uuid: cartItem.item_uuid
                                }))
                            }
                        >
                            <span>Configuration</span>
                            <CartItem.Configure.Icon />
                        </Checkout.Title>
                        <CartItem.Configure.Dropdown
                            ref={ref}
                            open={showConfiguration.toggle && showConfiguration.item_uuid === cartItem.item_uuid}
                            height={ref?.current?.scrollHeight}
                        >
                            <CartItem.Configure.Settings>
                                {'auto_renew' in cartItem && (
                                    <CartItem.Configure.Setting>
                                        <AdjustmentsVerticalIcon />
                                        <CartItem.Configure.Label>Auto Renew</CartItem.Configure.Label>
                                        <NXUIForm.Switch
                                            title={'Auto Renew Switch'}
                                            checked={cartItem.auto_renew}
                                            onClick={() => {
                                                updateCartItems({
                                                    uuid: localStorage.getItem('cart_uuid'),
                                                    items: [
                                                        {
                                                            ...cartItem,
                                                            product_id: cartItem.product.id,
                                                            billing_cycle_id: cartItem.billing_cycle.id,
                                                            auto_renew: !cartItem.auto_renew
                                                        }
                                                    ]
                                                })
                                            }}
                                        />
                                    </CartItem.Configure.Setting>
                                )}
                                {'id_protection' in cartItem && cartItem.id_protection !== undefined && (
                                    <CartItem.Configure.Setting>
                                        <AdjustmentsVerticalIcon />
                                        <CartItem.Configure.Label>ID Protection</CartItem.Configure.Label>
                                        <NXUIForm.Switch
                                            title={'ID Protection Switch'}
                                            checked={cartItem.id_protection}
                                            onClick={() => {
                                                updateCartItems({
                                                    uuid: localStorage.getItem('cart_uuid'),
                                                    items: [
                                                        {
                                                            ...cartItem,
                                                            product_id: cartItem.product.id,
                                                            billing_cycle_id: cartItem.billing_cycle.id,
                                                            id_protection: !cartItem.id_protection
                                                        }
                                                    ]
                                                })
                                            }}
                                        />
                                    </CartItem.Configure.Setting>
                                )}
                            </CartItem.Configure.Settings>
                        </CartItem.Configure.Dropdown>
                    </CartItem.Configure.Base>
                )
            case 'shared_hosting':
            case 'addon':
                return (
                    <CartItem.Configure.Base>
                        <Checkout.Title
                            open={showConfiguration.toggle && showConfiguration.item_uuid === cartItem.item_uuid}
                            onClick={() =>
                                setShowConfiguration((prevShowConfiguration) => ({
                                    toggle: !prevShowConfiguration.toggle,
                                    item_uuid: cartItem.item_uuid
                                }))
                            }
                        >
                            <span>Configuration</span>
                            <CartItem.Configure.Icon />
                        </Checkout.Title>
                        <CartItem.Configure.Dropdown
                            ref={ref}
                            open={showConfiguration.toggle && showConfiguration.item_uuid === cartItem.item_uuid}
                            height={ref?.current?.scrollHeight}
                        >
                            <CartItem.Configure.Settings>
                                {cartItem.config_options?.map(({ config, option, quantity }) => {
                                    if (config.input_type === 'checkbox') {
                                        return (
                                            <CartItem.Configure.Setting key={config.id}>
                                                <AdjustmentsVerticalIcon />
                                                <CartItem.Configure.Config>{config.name}</CartItem.Configure.Config>
                                                {/* If its a checkbox config option, show yes or no based on if the quantity is 1 or 0 */}
                                                <CartItem.Configure.Option>{quantity ? 'Yes' : 'No'}</CartItem.Configure.Option>
                                            </CartItem.Configure.Setting>
                                        )
                                    }

                                    return (
                                        <CartItem.Configure.Setting key={config.id}>
                                            <AdjustmentsVerticalIcon />
                                            <CartItem.Configure.Config>{config.name}</CartItem.Configure.Config>
                                            <CartItem.Configure.Option>{option.name}</CartItem.Configure.Option>
                                        </CartItem.Configure.Setting>
                                    )
                                })}
                            </CartItem.Configure.Settings>
                        </CartItem.Configure.Dropdown>
                    </CartItem.Configure.Base>
                )

            default:
                return <></>
        }
    }

    function renderProductAddon(
        cartItem: ICartItem,
        ref: MutableRefObject<HTMLDivElement | null>,
        [showAddons, setShowAddons]: [
            { toggle: boolean; item_uuid: string | undefined },
            Dispatch<SetStateAction<{ toggle: boolean; item_uuid: string | undefined }>>
        ]
    ) {
        if (!Array.isArray(cartItem?.addons)) return null

        return (
            <CartItem.Configure.Base>
                <Checkout.Title
                    open={showAddons.toggle && showAddons.item_uuid === cartItem.item_uuid}
                    onClick={() =>
                        setShowAddons((prevShowAddons) => ({
                            toggle: !prevShowAddons.toggle,
                            item_uuid: cartItem.item_uuid
                        }))
                    }
                >
                    <span>Addons</span>
                    <CartItem.Configure.Icon />
                </Checkout.Title>
                <CartItem.Configure.Dropdown
                    ref={ref}
                    open={showAddons.toggle && showAddons.item_uuid === cartItem.item_uuid}
                    height={ref?.current?.scrollHeight}
                >
                    <CartItem.Configure.Settings>
                        {cartItem.addons.map((addon) => {
                            return (
                                <CartItem.Configure.Setting key={addon.item_uuid}>
                                    <CartItem.Configure.Link
                                        to={`/shop/${groupId}/configure/services/${cartItem.item_uuid}/addons/${addon.item_uuid}`}
                                    >
                                        <PlusCircleIcon width={'14px'} />
                                        <CartItem.Configure.Config>{addon.name}</CartItem.Configure.Config>
                                    </CartItem.Configure.Link>
                                </CartItem.Configure.Setting>
                            )
                        })}
                    </CartItem.Configure.Settings>
                </CartItem.Configure.Dropdown>
            </CartItem.Configure.Base>
        )
    }

    /*   RENDER COMPONENT
     *****************************************************/
    if (!cartItem || !cartItem?.price || !cartId) return null

    return (
        <CartItem.Base>
            <Checkout.Column className={'cartItem__icon'}>
                <Checkout.Icon>{getProductIcon(cartItem)}</Checkout.Icon>
            </Checkout.Column>
            <Checkout.Column>
                <Checkout.Row>
                    <Checkout.Column className={'cartItem__product'}>
                        <CartItem.Product>
                            <CartItem.Title>{cartItem.name}</CartItem.Title>
                            <CartItem.Subtitle>
                                {getOrderSubtitle(cartItem)}
                                <Anchor
                                    color={'primary'}
                                    onClick={() =>
                                        navigate(
                                            `/shop/${groupId}/configure/${cartItem.order_type !== 'all' ? 'domains' : 'services'}/${
                                                cartItem.item_uuid
                                            }`
                                        )
                                    }
                                >
                                    <CartItem.Configure.Edit />
                                </Anchor>
                            </CartItem.Subtitle>
                        </CartItem.Product>
                    </Checkout.Column>
                    <Checkout.Column>
                        <CartItem.Total>
                            {cartItem?.price?.discount !== '0.00' && (
                                <CartItem.ItemPrice.Discount>{defaultCurrency(cartItem?.price?.subtotal)}</CartItem.ItemPrice.Discount>
                            )}
                            <CartItem.ItemPrice.Total className={`${cartItem?.price?.discount !== '0.00' ? 'highlight' : ''}`}>
                                {defaultCurrency(cartItem?.price.total)}
                                <CartItem.ItemPrice.BillingCycle>{getBillingCycle(cartItem.billing_cycle.name)}</CartItem.ItemPrice.BillingCycle>
                            </CartItem.ItemPrice.Total>
                            {cartItem?.price?.discount !== '0.00' && (
                                <Badge
                                    color={'alternative'}
                                    onClick={async () => {
                                        if (cartItem.promo_code) {
                                            await removePromoCodeFromCart({
                                                uuid: cartId,
                                                code: cartItem.promo_code
                                            })
                                            dispatch(shopAPI.endpoints.viewCart.initiate(cartId, { forceRefetch: true }))
                                        }
                                    }}
                                >
                                    {cartItem.promo_code}
                                </Badge>
                            )}
                        </CartItem.Total>
                    </Checkout.Column>
                </Checkout.Row>
                <Checkout.Column className={'cartItem__configurationRow'}>
                    {cartItem?.addons && renderProductAddon(cartItem, addonsRef, [showAddons, setShowAddons])}
                    {(cartItem?.config_options || cartItem.product.service_type === 'domain') &&
                        renderProductConfiguration(cartItem, configureRef, [showConfiguration, setShowConfiguration])}
                </Checkout.Column>
            </Checkout.Column>
            <Checkout.Column className={'cartItem__action'}>
                <CartItem.Remove.Base>
                    <button type={'button'} onClick={() => removeCartItem({ uuid: cartId, remove_items: [cartItem.item_uuid] })}>
                        <CartItem.Remove.TrashIcon width={'18px'} />
                    </button>
                </CartItem.Remove.Base>
            </Checkout.Column>
        </CartItem.Base>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
