/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { Footer } from 'containers/website/components/footer'
import { WebsiteEndpointRenderer } from 'containers/website/components/markdown'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { WebsiteTermsOfServiceStyles as S } from './style'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Component rendered at /terms-of-service
 */
export const TermsOfService = () => {
    /***** HOOKS *****/
    const { terms_and_conditions } = useAppSelector((state) => state.app.appSettings.provider)

    /***** RENDER *****/
    return (
        <S.LegalFlexContainer direction='column' fullWidth fullHeight growChild={[1, 1]} justify='space-between'>
            <WebsiteEndpointRenderer title='Terms of Service' html={terms_and_conditions} />
            <Footer />
        </S.LegalFlexContainer>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
