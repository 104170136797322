/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr'
import React from 'react'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { WebsiteEndpointRendererStyles as S } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TWebsiteEndpointRenderer = React.FC<{
    title: string
    html?: string
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @description - This component will render a section title and any html provided in the props.
 * Internally this uses the HTMR library and a number of specific style translations to ensure that the
 * default MCA styles and theme are not overriding the style and that the relevant components are
 * used to render the html.
 */
export const WebsiteEndpointRenderer: TWebsiteEndpointRenderer = ({ title, html }) => {
    /***** RENDER HELPERS *****/
    type TAnchorProps = Omit<React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, 'ref'>
    type TOrderedListItemProps = Omit<React.DetailedHTMLProps<React.OlHTMLAttributes<HTMLOListElement>, HTMLOListElement>, 'ref'>
    type TBlockQuoteProps = Omit<React.DetailedHTMLProps<React.BlockquoteHTMLAttributes<HTMLElement>, HTMLElement>, 'ref'>
    type TListItemProps = Omit<React.DetailedHTMLProps<React.OlHTMLAttributes<HTMLOListElement>, HTMLOListElement>, 'ref'>
    type TParagraphProps = Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>, 'ref'>
    type THeading1Props = Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>, 'ref'>

    const htmrOptions = {
        transform: {
            ul: ({ children, ...props }: TListItemProps) => (
                <S.Htmr.UnorderedList {...props}>
                    <p>{children}</p>
                </S.Htmr.UnorderedList>
            ),
            ol: ({ children, ...props }: TOrderedListItemProps) => (
                <S.Htmr.OrderedListItem {...props}>
                    <p>{children}</p>
                </S.Htmr.OrderedListItem>
            ),
            //@ts-ignore
            a: (props: TAnchorProps) => <S.Htmr.Anchor {...props} target='_blank' color='primary' />,
            h1: (props: THeading1Props) => <S.Htmr.Heading1 {...props} />,
            h2: (props: THeading1Props) => <S.Htmr.Heading2 {...props} />,
            h3: (props: THeading1Props) => <S.Htmr.Heading3 {...props} />,
            h4: (props: THeading1Props) => <S.Htmr.Heading4 {...props} />,
            h5: (props: THeading1Props) => <S.Htmr.Heading5 {...props} />,
            p: (props: TParagraphProps) => <S.Htmr.paragraph {...props} />,
            blockquote: (props: TBlockQuoteProps) => <S.Htmr.BlockQuote {...props} />
        }
    }

    /***** RENDER *****/
    return (
        <S.Inner>
            <S.DocumentContainer>
                <S.Heading>{title}</S.Heading>
                <S.Htmr.CascadingStyles>{htmr(html ?? '', htmrOptions)}</S.Htmr.CascadingStyles>
            </S.DocumentContainer>
        </S.Inner>
    )
}
