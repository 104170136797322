/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useFormikContext } from 'formik'
import { useEffect } from 'react'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
import { ChangePlanForm, Service } from 'models/service'
import { IProduct } from 'models/shop/product'

/**********************************************************************************************************
 *   METHODS
 **********************************************************************************************************/
export function getInitialValuesConfig(serviceData: Service, totalPlansData: IProduct[], selectedProductId?: string) {
    // If the selected product is the same as the current service product, or if its the first time render (no selectedProductId yet)
    if (!selectedProductId || serviceData.product.id === Number(selectedProductId)) {
        const configsToReturn: {
            config_id: number
            option_id: string | number
            quantity?: Quantity | undefined
        }[] = []
        const serviceConfigs = serviceData.config || []

        serviceConfigs.forEach(({ config_id, option_id, quantity }) => {
            const productConfigOptionGroups = totalPlansData.find(({ id }) => id === serviceData.product.id)?.config_option_groups
            const matchingGroup = productConfigOptionGroups?.find(({ config_options }) => config_options.find(({ id }) => id === config_id))
            const matchingOption = matchingGroup?.config_options.find(({ id }) => id === config_id)

            if (matchingOption) {
                configsToReturn.push({
                    config_id,
                    option_id: matchingOption?.input_type === 'radio' ? String(option_id) : option_id,
                    ...(matchingOption?.input_type === 'checkbox' && { quantity: quantity ? ['1'] : [] }),
                    ...(matchingOption?.input_type === 'number' && { quantity: quantity })
                })
            }
        })

        return configsToReturn
    }

    // If a different product is selected
    const matchingPlan = totalPlansData.find(({ id }) => id.toString() === selectedProductId)
    const configOptionGroups = matchingPlan?.config_option_groups

    type Quantity = string[] | number

    const config: {
        config_id: number
        option_id: string | number
        quantity?: Quantity
    }[] = []

    configOptionGroups?.forEach((configOptionGroup) => {
        configOptionGroup.config_options.forEach((configOption) => {
            const configValue = configOption.values.find((value) => value)

            if (!configValue) return

            function getQuantityObj(): { quantity?: Quantity } {
                if (configOption?.input_type === 'checkbox') return { quantity: [] }
                if (configOption?.input_type === 'number') return { quantity: 0 }
                return {}
            }

            const item = {
                config_id: configOption.id,
                option_id: configOption?.input_type === 'radio' ? String(configValue.id) : configValue.id,
                ...getQuantityObj()
            }

            config.push(item)
        })
    })

    return config
}

/**********************************************************************************************************
 *   COMPONENTS
 **********************************************************************************************************/
export const ResetFormOnProductChange = ({ serviceData, totalPlansData }: { serviceData: Service; totalPlansData: IProduct[] }) => {
    const { values, resetForm } = useFormikContext<ChangePlanForm>()

    useEffect(() => {
        resetForm({
            values: {
                ...values,
                config: getInitialValuesConfig(serviceData, totalPlansData, values.product_id)
            }
        })
    }, [values.product_id])

    return null
}
