/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Theme } from 'nxui/src'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { StyleableFormikProvider } from 'components/form/provider'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { Flex } from '../../../components/utilities/Flex'
import { WebsiteHero } from '../components/Hero'
import { WebsiteStyles } from '../style'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
export const WebsiteWhoisStyles = {
    WebsiteHero: styled(WebsiteHero)<{ grow?: boolean }>`
        ${({ grow }) => grow && 'height: 100%;'}
    `,
    BannerInner: WebsiteStyles.Components.BannerInner,
    FormikProvider: styled(StyleableFormikProvider)`
        margin-top: 35px;

        ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-sm'])} {
            margin-top: 60px;
        }
    `,
    ResultWrapper: styled.div`
        background: white;
        width: 100vw;
        padding-inline: 20px;
    `,
    ResultInner: styled.div`
        padding-block: 72px;
        font-size: 14px;
    `,
    UpperWrapper: styled.div`
        flex-grow: 1;
    `,
    OuterFlex: styled(Flex)`
        // 90px for header element
        min-height: calc(100% - 90px);
    `
}
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/
