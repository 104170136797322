/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { createSlice } from '@reduxjs/toolkit'

/**********************************************************************************************************
 *   INTERFACES
 **********************************************************************************************************/
import { IProductGroup } from 'models/shop/product'
import { IDomainConfiguration, IProductsPendingConfiguration, IServiceConfiguration } from 'models/shop/shop.configure'

/**********************************************************************************************************
 *    SLICE
 **********************************************************************************************************/
export interface AccountCreditState {
    amount: number
    isValid: boolean
}

interface ShopState {
    searchTerm: undefined
    // checkoutPaymentFormName: string | null
    checkoutAccountCredit: AccountCreditState
    domain: null | string
    activeProductGroup: IProductGroup
    checkoutPaymentFormData: {
        name: string
        hasCapture: boolean
    } | null
    productsPendingConfiguration:
        | IProductsPendingConfiguration
        | {
              domains: []
              services: []
          }
    searchParamPromotion: string | null
}

const initialState: ShopState = {
    searchTerm: undefined,
    checkoutPaymentFormData: null,
    checkoutAccountCredit: {
        amount: 0,
        isValid: true
    },
    domain: '',
    activeProductGroup: {
        id: 0,
        type: 'domains',
        name: 'Domain Names',
        description: null
    },
    productsPendingConfiguration: {
        domains: [],
        services: []
    },
    searchParamPromotion: null
}

export const shopSlice = createSlice({
    name: 'shop',
    initialState,
    reducers: {
        setSearchTerm: (state, { payload }) => {
            state.searchTerm = payload
        },
        setActiveProductGroup: (state, { payload }) => {
            state.activeProductGroup = payload
        },
        setProductsPendingConfiguration: (state, { payload }) => {
            if (payload.type === 'domains') {
                state.productsPendingConfiguration.domains = [...state.productsPendingConfiguration.domains, ...payload.data]
            } else if (payload.type === 'services') {
                state.productsPendingConfiguration.services = [...state.productsPendingConfiguration.services, ...payload.data]
            }
        },
        resetProductsPendingConfiguration: (state) => {
            state.productsPendingConfiguration = {
                domains: [],
                services: []
            }
        },
        updateProductPendingSelectedPricing: (state, { payload }) => {
            if (payload.type === 'domains') {
                state.productsPendingConfiguration.domains = state.productsPendingConfiguration.domains.map((domain: IDomainConfiguration) =>
                    domain.name === payload.name ? payload : domain
                )
            } else if (payload.type === 'services') {
                state.productsPendingConfiguration.services = state.productsPendingConfiguration.services.map((service: IServiceConfiguration) =>
                    service.name === service.name ? payload : service
                )
            }
        },
        setCheckoutPaymentFormData: (state, { payload }) => {
            state.checkoutPaymentFormData = payload
        },
        setCheckoutAccountCredit: (state, { payload }: { payload: ShopState['checkoutAccountCredit'] }) => {
            state.checkoutAccountCredit = payload
        },
        setPromotionFromSearchParams: (state, { payload }) => {
            state.searchParamPromotion = payload
        }
    }
})

export const {
    setSearchTerm,
    setActiveProductGroup,
    setProductsPendingConfiguration,
    resetProductsPendingConfiguration,
    updateProductPendingSelectedPricing,
    setCheckoutPaymentFormData,
    setCheckoutAccountCredit,
    setPromotionFromSearchParams
} = shopSlice.actions

export default shopSlice.reducer
