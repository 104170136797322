/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import styled, { css } from 'styled-components'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Theme } from 'nxui/src'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { FieldStyles } from 'components/form/shared.style'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
const Message = styled.p`
    display: inline-flex;
    min-height: 17px;
    height: auto;
    font-size: 1.2rem;
    color: ${(props) => props.theme[`error--100`]};
    ${Theme.Functions.normalizeText()};
    ${Theme.Functions.setFont('Inter', 600, 'italic')};
`

const Icon = styled(ExclamationCircleIcon)`
    ${FieldStyles.IconBase}
    color: ${(props) => props.theme[`error--100`]};
`

const IconWrapper = styled.div<{ visible: boolean }>`
    cursor: pointer;
    visibility: hidden;
    width: 0;
    opacity: 0;
    background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`error--100`], `0.1`)};
    padding: 0;
    ${Theme.Functions.animation(`all`)};

    ${({ visible }) =>
        visible &&
        css`
            width: auto;
            padding: 0 6px;
            visibility: visible;
            opacity: 1;

            ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-md`])} {
                padding: 0 10px;
            }
        `}

    &:hover {
        > div,
        > svg {
            visibility: visible;
            opacity: 1;
        }
    }
`

const IconTip = styled.div<{ size: number }>`
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: -32px;
    right: 0;
    width: fit-content;
    padding: 8px 16px;
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme[`error--100`]};
    background-color: ${(props) => props.theme[`error--100`]};
    font-size: 1.2rem;
    color: ${(props) => props.theme[`white--100`]};
    ${Theme.Functions.setFont('Inter', 500)};
    ${Theme.Functions.animation(`all`)};

    ${({ size }) =>
        size &&
        css`
            top: calc(-${size}px - 5px);
        `}
`

const IconArrow = styled.div`
    position: absolute;
    bottom: -5px;
    right: 13px;
    width: 0;
    height: 0;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent ${(props) => props.theme[`error--100`]} transparent;
    border-style: solid;
    transform: rotate(180deg);
    visibility: hidden;
    opacity: 0;
    ${Theme.Functions.animation(`all`)};
`
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/

/***** EXPORTS *****/
export const FieldErrorStyles = {
    Message,
    Icon: Object.assign(Icon, {
        Wrapper: IconWrapper,
        Tip: IconTip,
        Arrow: IconArrow
    })
}
