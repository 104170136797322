/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useCallback, useRef, useState } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { mapValueToRange } from '../functions'
import { useIsMounted } from './useIsMounted'
import { useEventListener } from './useEventListener'
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect'

/**********************************************************************************************************
 *   HOOK
 **********************************************************************************************************/
export function useScrollPosition() {
    // Mutable values like 'ref.current' aren't valid dependencies
    // because mutating them doesn't re-render the component.
    // Instead, we use a state as a ref to be reactive.
    const isMounted = useIsMounted()
    const ref = useRef(null)
    const [init, setInit] = useState(false)
    const [size, setSize] = useState({
        scrollLeft: 0,
        scrollTop: 0,
        scrollRight: 0,
        scrollBottom: 0
    })

    const setInitialSize = useCallback(() => {
        const { scrollHeight, scrollTop, scrollLeft, scrollWidth, offsetWidth, offsetHeight } = ref.current
        const initHeight = scrollHeight - scrollTop
        const initWidth = scrollWidth - scrollLeft
        const widthScrollable = scrollWidth - offsetWidth > 0
        const heightScrollable = scrollHeight - offsetHeight > 0

        setSize({
            scrollLeft: widthScrollable ? mapValueToRange(scrollLeft, 0, initWidth, 0, 0.15) : 0,
            scrollTop: heightScrollable ? mapValueToRange(scrollTop, 0, initHeight, 0, 0.15) : 0,
            scrollRight: widthScrollable ? mapValueToRange(initWidth - scrollLeft, 0, initWidth, 0, 0.15) : 0,
            scrollBottom: heightScrollable ? mapValueToRange(initHeight - scrollTop, 0, initHeight, 0, 0.15) : 0
        })
        setInit(true)
    }, [])

    const onScroll = (event) => {
        const target = event.target
        const scrollHeight = target.scrollHeight - target.clientHeight
        const scrollWidth = target.scrollWidth - target.clientWidth

        setSize({
            scrollLeft: mapValueToRange(target.scrollLeft, 0, scrollWidth, 0, 0.15),
            scrollTop: mapValueToRange(target.scrollTop, 0, scrollHeight, 0, 0.15),
            scrollRight: mapValueToRange(scrollWidth - target.scrollLeft, 0, scrollWidth, 0, 0.15),
            scrollBottom: mapValueToRange(scrollHeight - target.scrollTop, 0, scrollHeight, 0, 0.15)
        })
    }

    useEventListener('resize', setInitialSize)

    useIsomorphicLayoutEffect(() => {
        if (isMounted && ref.current && !init) {
            setInitialSize()
        }
    }, [ref])

    return [ref, onScroll, size]
}
