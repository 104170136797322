/**********************************************************************************************************
 *   STYLE
 **********************************************************************************************************/
import { CheckIcon } from '@heroicons/react/24/solid'
import { Form } from 'formik'
import { Badge, Button, Fade, Tabs, Theme } from 'nxui/src'
import styled from 'styled-components'

export const Invoice = {
    Header: styled.header`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        color: ${(props) => props.theme['text--100']};
        background-color: ${(props) => props.theme['background--000']};
        ${Theme.Functions.setFont('Inter', 400)};

        ${(props) => Theme.Functions.mediaBreakUp(props.theme['breakpoints-sm'])} {
            padding: 1.5rem;
        }
        strong {
            color: ${(props) => props.theme['text--300']};
            ${Theme.Functions.setFont('Inter', 500)};
        }
    `,
    Status: styled(Badge)``,
    Details: styled.div`
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    `,
    Row: styled.div`
        display: inline-flex;
        flex-wrap: wrap;
        width: 100%;
        min-width: 280px;

        &:last-of-type {
            border-radius: 0 0 16px 16px;
        }
    `,
    Column: {
        Base: styled.div`
            display: inline-flex;
            flex: 1 1 0;
            background-color: ${(props) => props.theme['background--000']};
            min-width: 280px;
            padding: 18px 24px;
            box-shadow: 1px 0 0 0 ${(props) => props.theme['gray--100']}, 0 1px 0 0 ${(props) => props.theme['gray--100']},
                1px 1px 0 0 ${(props) => props.theme['gray--100']}, 0 0 0 0 ${(props) => props.theme['gray--100']} inset,
                0 1px 0 0 ${(props) => props.theme['gray--100']} inset;

            ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
                height: 55px;
                padding: 10px 15px;
                flex-direction: row;
            }
        `,
        Render: styled.div`
            display: inline-flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            text-align: right;
            color: ${(props) => props.theme['text--300']};
            font-size: 1.4rem;
            letter-spacing: -0.25px;
            ${Theme.Functions.setFont('Inter', 500)};
        `,
        Title: styled.div`
            display: inline-flex;
            align-items: center;
            height: 100%;
            min-width: 100px;
            margin-right: 10px;
            color: ${(props) => props.theme['text--100']};
            font-size: 1.4rem;
            letter-spacing: -0.25px;
            ${Theme.Functions.setFont('Inter', 400)};

            ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
                margin-right: 15px;
            }
        `
    },
    Breakdown: styled.div`
        width: 100%;
        border-bottom: 1px solid ${(props) => props.theme['border--100']};
    `,
    Actions: styled.div`
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            flex-direction: column-reverse;
            gap: 10px;
        }
    `,
    Action: styled(Button)`
        display: flex;
        gap: 5px;
    `,
    Left: styled.div`
        width: 100%;
        display: flex;
        gap: 10px;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            > button {
                width: 100%;
                margin: 0;
                justify-content: center;
            }
        }
    `,
    Right: styled.div`
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            flex-direction: column-reverse;

            > button {
                width: 100%;
                margin: 0;
                justify-content: center;
            }
        }
    `,
    Footer: styled.footer`
        width: 100%;
        display: flex;
        flex-direction: row;
        background-color: ${(props) => props.theme['background--000']};

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            flex-direction: column;
        }
    `
}

export const Payment = {
    Base: styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 20px;
        background-color: ${(props) => props.theme['background--000']};
        height: 600px;
        overflow-y: auto;

        ::-webkit-scrollbar {
            width: 8px;
        }

        ::-webkit-scrollbar-track {
            background: ${(props) => props.theme['background--100']};
        }

        ::-webkit-scrollbar-thumb {
            background: ${(props) => props.theme['background--300']};
            border-radius: 10px;
        }

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            padding: 20px 20px 0;
        }
    `,
    Header: styled.div`
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
    `,
    Content: styled.div``,
    Title: styled.div`
        font-size: 1.4rem;
        color: ${(props) => props.theme['text--300']};
        ${Theme.Functions.setFont('Inter', 700)};
    `,
    Description: styled.div`
        font-size: 1.2rem;
        color: ${(props) => props.theme['text--100']};
        ${Theme.Functions.setFont('Inter', 500)};
    `,
    Body: styled.div`
        width: 100%;
    `,
    Tabs: styled(Tabs.Prefab)`
        border: none;
        box-shadow: none;
    `,
    Form: styled(Form)`
        width: 100%;
    `,
    Fade: styled(Fade)<{ hidden: boolean }>`
        align-items: flex-start;
        ${(props) => (props.hidden ? 'display: none;' : '')}
    `,
    Divider: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 20px 0;
        width: 100%;
    `,
    Line: styled.hr`
        background-color: hsla(0, 0%, 10%, 0.1);
        border: none;
        height: 1px;
        width: 100%;
    `,
    Text: styled.p`
        color: ${(props) => props.theme['text--100']};
        background-color: ${(props) => props.theme['background--000']};
        ${Theme.Functions.setFont('Inter', 500)};
        left: 50%;
        padding: 0 8px;
        position: absolute;
        transform: translateX(-50%);
        white-space: nowrap;
    `,
    LinkType: {
        SaveDetailsContainer: styled.div`
            margin-top: 10px;
        `
    }
}

export const Breakdown = {
    Title: styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: ${(props) => props.theme['background--100']};
        border-bottom: 1px solid ${(props) => props.theme['border--100']};
        padding: 0 20px;
        height: 40px;
        text-align: left;
        font-size: 1.2rem;
        color: ${(props) => props.theme['text--100']};
        text-transform: uppercase;
        ${Theme.Functions.setFont('Inter', 600)};
    `,
    Content: styled.div`
        background-color: ${(props) => props.theme['background--000']};
        padding: 20px;
    `,
    Row: styled.div``
}

export const Line = {
    Row: styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 15px;

        &:last-of-type {
            padding-bottom: 0px;
        }
    `,
    Title: styled.div`
        text-align: left;
        font-size: 1.4rem;
        color: ${(props) => props.theme['text--100']};
        ${Theme.Functions.setFont('Inter', 400)};
    `,
    Price: styled.div`
        font-size: 1.4rem;
        ${Theme.Functions.setFont('Inter', 500)};
    `
}

export const Summary = {
    Base: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: fit-content;
    `,
    Total: {
        Base: styled.div`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 10px;
            padding-top: 10px;
            border-top: 1px solid ${(props) => props.theme['border--100']};
        `,
        Title: styled.div`
            text-align: left;
            font-size: 1.4rem;
            color: ${(props) => props.theme['text--100']};
            ${Theme.Functions.setFont('Inter', 400)};
        `,
        Price: styled.div`
            font-size: 2rem;
            color: ${(props) => props.theme['text--300']};
            ${Theme.Functions.setFont('Inter', 700)};
        `
    },
    Actions: styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px 15px;
        background-color: ${(props) => props.theme['background--000']};

        button {
            width: 50%;
        }
    `,
    Details: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        margin: 20px;
        padding: 15px;
        border-radius: 8px;
        border: 1px solid ${(props) => props.theme['border--100']};
    `
}

export const Description = {
    Base: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.4rem;
        color: ${(props) => props.theme['text--100']};
    `,
    Instructions: styled.div`
        ul {
            list-style-type: disc;
            padding-left: 20px;
        }
    `
}

export const Method = {
    NestedContent: styled.div`
        padding-left: 20px;
    `,
    NestedForm: styled.div`
        padding-top: 10px;
    `,
    Credit: styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    `,
    Saved: {
        BadgeBackground: styled.div`
            background-color: ${(props) => props.theme['background--000']};
            position: absolute;
            top: 0px;
            left: -20px;
            transform: translateY(-50%) scale(0.8);
        `,
        Badge: styled(Badge)``,
        Card: styled.span`
            width: 100%;
            text-align: left;
            font-size: 1.2rem;
            color: ${(props) => props.theme['text--300']};
            ${Theme.Functions.setFont('Inter', 700)};
        `,
        Expiry: styled.span`
            flex: 1;
            text-align: right;
            font-size: 1.2rem;
            color: ${(props) => props.theme['text--300']};
            ${Theme.Functions.setFont('Inter', 700)};
        `,
        Type: styled.span`
            svg {
                height: 16px;
                width: 3rem;
                margin-right: 0.5rem;
            }
        `
    },
    Wrapper: styled.nav`
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        gap: 10px;

        ${(props) => Theme.Functions.mediaBreakUp(props.theme['breakpoints-sm'])} {
            margin-bottom: 0;
        }
    `,
    Option: {
        Wrapper: styled.button`
            position: relative;
            cursor: pointer;
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 44px;
            padding: 10px 15px;
            border-radius: 8px;
            border: 1px solid ${(props) => props.theme['border--100']};
            ${Theme.Functions.animation('transition-all')};

            &.active:not(.disabled) {
                border-color: ${(props) => props.theme['confirm--100']};
                background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme['confirm--100'], '0.075')};

                label {
                    color: ${(props) => props.theme['confirm--100']};
                }

                &:hover {
                    border-color: ${(props) => props.theme['confirm--100']};

                    label {
                        color: ${(props) => props.theme['confirm--100']};
                    }
                }
            }

            &:hover:not(.disabled) {
                border-color: ${(props) => props.theme['border--200']};

                label {
                    color: ${(props) => props.theme['text--300']};
                }
            }

            &.disabled {
                background-color: ${(props) => props.theme['background--100']};
                cursor: auto;

                label {
                    cursor: auto;
                }
            }

            &:first-of-type {
                margin-right: 10px;
            }
        `,
        Label: styled.label`
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 5px;
            width: 100%;
            cursor: pointer;
            text-align: left;
            font-size: 1.2rem;
            color: ${(props) => props.theme['text--100']};
            ${Theme.Functions.setFont('Inter', 700)};
            ${Theme.Functions.animation('transition-all')};

            > span {
                display: flex;
                flex-direction: column;
            }
        `,
        Icon: {
            Wrapper: styled.div`
                display: inline-flex;
                align-items: center;
                justify-content: center;
                height: 16px;
                width: 16px;
                border-radius: 9999px;
                background-color: ${(props) => props.theme['confirm--100']};
            `,
            Fade: styled(Fade)`
                position: absolute;
                top: -8px;
                right: -8px;
                width: 18px;
            `,
            Check: styled(CheckIcon)`
                width: 12px;
                color: ${(props) => props.theme['white--100']};
            `
        }
    }
}
