/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
/*eslint import/namespace: [2, { allowComputed: true }]*/
import * as HeroIcons from '@heroicons/react/24/solid'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { WebsiteFeature } from 'containers/website/components/features/feature'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppSelector } from 'store/hooks'
import { snakeToPascalCase } from '../../../../helpers/utils'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { FeatureStyles as S } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type WebsiteDomainFeatures = React.FC<{
    className?: string
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const WebsiteDomainFeatures: WebsiteDomainFeatures = ({ className }) => {
    /***** HOOKS *****/
    const { website } = useAppSelector((state) => state.app.appSettings)

    /***** RENDER HELPERS *****/
    const getIcon = (icon: string) => {
        type HeroKey = keyof typeof HeroIcons

        const iconName = snakeToPascalCase(icon) as HeroKey
        return HeroIcons[iconName] ?? HeroIcons[`${iconName}Icon` as HeroKey] ?? HeroIcons.EyeSlashIcon
    }

    /***** RENDER *****/
    return (
        <S.Banner className={className}>
            <S.BannerInner $large>
                <S.Heading>{website?.information_title}</S.Heading>
                <S.FlexContainer align='flex-start' justify='space-evenly' gap={50} wrap='wrap'>
                    {website?.information
                        .filter(([, title]) => Boolean(title))
                        .map(([icon, title, description], index) => (
                            <WebsiteFeature icon={getIcon(icon)} title={title} key={index}>
                                {description}
                            </WebsiteFeature>
                        ))}
                </S.FlexContainer>
            </S.BannerInner>
        </S.Banner>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
