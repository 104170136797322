/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { AdjustmentsVerticalIcon, BackspaceIcon, PlusCircleIcon, ServerIcon, ShoppingCartIcon } from '@heroicons/react/24/solid'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useMemo, useRef } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import Addons from 'containers/services/addons'
import Cancel from 'containers/services/cancel'
import Information from 'containers/services/information'
import Overview from 'containers/services/overview'
import Server from 'containers/services/server'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { Empty, Loader, Tabs } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import {
    serviceAPI,
    useAvailablePlansQuery,
    useBillingCycleQuery,
    useChangePlanStatusQuery,
    useCheckCancelableServiceQuery,
    useModuleMetaQuery,
    usePendingServiceCancellationQuery,
    useRenewPriceQuery
} from 'api/service'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Service } from 'containers/services/services.styles'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { scrollIntoViewIfNeeded } from 'helpers/utils'
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
import { ServiceMetaPayload } from 'api/service/types'
import { ServiceStatus } from 'models/enums'
import toast from 'react-hot-toast'

interface Props {
    meta?: ServiceMetaPayload
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function ServiceComponent({ meta = { page: '1' } }: Props) {
    /***** HOOKS *****/
    const {
        appSettings: { section: settingsSections }
    } = useAppSelector((state) => state.app)
    const serviceContainerRef = useRef<null | HTMLElement>(null)
    const location = useLocation()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { id } = useParams()
    const serviceId = Number(id)

    /***** QUERIES *****/
    const {
        services,
        service,
        isFetching: isServicesFetching,
        isSuccess
    } = serviceAPI.endpoints.services.useQueryState(meta, {
        selectFromResult: ({ data, ...rest }) => ({
            ...rest,
            services: data?.data ?? [],
            service: data?.data.find((serviceItem) => serviceItem.id === serviceId)
        })
    })
    const { isFetching: isModuleMetaFetching } = useModuleMetaQuery(serviceId, {
        skip: !serviceId || !service?.product.module_id || !service
    })
    const { isFetching: isAvailablePlansFetching } = useAvailablePlansQuery(serviceId, {
        skip: !serviceId || !service
    })
    const { isFetching: isRenewPriceFetching } = useRenewPriceQuery(serviceId, { skip: !serviceId || !service })
    const { isFetching: isBillingCycleFetching } = useBillingCycleQuery(serviceId, { skip: !serviceId || !service })
    const { isFetching: isCheckCancelableServiceFetching } = useCheckCancelableServiceQuery(serviceId, { skip: !serviceId || !service })
    const { isFetching: isPendingServiceCancellationFetching } = usePendingServiceCancellationQuery(serviceId, { skip: !serviceId || !service })
    const { isLoading: isChangePlanStatusLoading } = useChangePlanStatusQuery(serviceId, { skip: !serviceId || !service })

    /***** RENDER HELPERS *****/
    const serviceTabs = useMemo(() => {
        const tabs = [
            {
                icon: PlusCircleIcon,
                label: 'General',
                render: <Information meta={meta} />,
                location: () => navigate(`/services/${serviceId}/general${location.search}`, { replace: true })
            },
            {
                icon: AdjustmentsVerticalIcon,
                label: 'Addons',
                render: <Addons />,
                location: () => navigate(`/services/${serviceId}/addons${location.search}`, { replace: true })
            }
        ]

        if (service?.status === ServiceStatus.Active) {
            return [
                ...tabs,
                {
                    icon: ServerIcon,
                    label: 'Server',
                    render: <Server meta={meta} />,
                    location: () => navigate(`/services/${serviceId}/server${location.search}`, { replace: true })
                },
                {
                    icon: BackspaceIcon,
                    label: 'Cancel',
                    render: <Cancel />,
                    location: () => navigate(`/services/${serviceId}/cancel${location.search}`, { replace: true })
                }
            ]
        }

        return [
            ...tabs,
            {
                icon: BackspaceIcon,
                label: 'Cancel',
                render: <Cancel />,
                location: () => navigate(`/services/${serviceId}/cancel${location.search}`, { replace: true })
            }
        ]
    }, [service?.status, serviceId, location])

    const filteredServiceTabsBasedOnSettings = serviceTabs.filter(({ label }) => {
        if (label === 'General' && !settingsSections.service.general) return false
        if (label === 'Addons' && !settingsSections.service.addons) return false
        if (label === 'Server' && !settingsSections.service.server) return false
        if (label === 'Cancel' && !settingsSections.service.cancel) return false
        return true
    })

    function setInitialTab() {
        switch (location.pathname) {
            case `/services/${serviceId}/addons`:
                return settingsSections.service.addons ? 'Addons' : ''
            case `/services/${serviceId}/server`:
                return settingsSections.service.server ? 'Server' : ''
            case `/services/${serviceId}/cancel`:
                return settingsSections.service.cancel ? 'Cancel' : ''
            case `/service/${serviceId}/general`:
                return settingsSections.service.general ? 'General' : ''
            default:
                return ''
        }
    }

    function renderService() {
        if (
            isServicesFetching ||
            isModuleMetaFetching ||
            isAvailablePlansFetching ||
            isRenewPriceFetching ||
            isBillingCycleFetching ||
            isPendingServiceCancellationFetching ||
            isCheckCancelableServiceFetching ||
            isChangePlanStatusLoading
        ) {
            return (
                <AnimatePresence>
                    <motion.div
                        initial={{ opacity: 0, x: -15 }}
                        animate={{
                            opacity: 1,
                            x: 0,
                            transition: {
                                duration: 0.5,
                                ease: [0.83, 0, 0.17, 1]
                            }
                        }}
                        exit={{ opacity: 0, x: 15 }}
                    >
                        <Loader.Skeleton sections={['title', 'description', 'content', 'footer']} />
                    </motion.div>
                </AnimatePresence>
            )
        }

        if (isSuccess && services.length === 0) {
            return (
                <Service.Empty>
                    <Empty
                        icon={<InformationCircleIcon width={'48px'} />}
                        title={'No services'}
                        description={'Get started by adding service to shopping cart.'}
                        action={{
                            label: 'Purchase New Service',
                            color: 'primary',
                            icon: <ShoppingCartIcon />,
                            func: () => navigate('/shop')
                        }}
                    />
                </Service.Empty>
            )
        }

        return (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                {settingsSections.service.overview ? <Overview meta={meta} /> : ''}
                {service?.status && (
                    <Service.Card>
                        <Tabs.Prefab initial={setInitialTab()} tabs={filteredServiceTabsBasedOnSettings} />
                    </Service.Card>
                )}
            </motion.div>
        )
    }

    /***** EFFECTS *****/
    useEffect(() => {
        if (id) scrollIntoViewIfNeeded(serviceContainerRef)
    }, [id])

    /***** RENDER *****/
    if (!id) return null

    if (isSuccess && !service) {
        navigate(`/services/?${searchParams.toString()}`)
        toast.error(
            {
                type: 'ERROR',
                // @ts-ignore
                detail: 'Service could not be found with the current filters'
            },
            { duration: 5000 }
        )
        return null
    }

    return <Service.Base ref={serviceContainerRef}>{renderService()}</Service.Base>
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { ServiceComponent as Service }
