/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { DateTime } from 'luxon'
import styled from 'styled-components'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { domainAPI } from 'api/domain'
import { daysRemainsUntilExpired, getServiceStatusColour, parseISO } from 'helpers/utils'
import { Domain } from 'models/domain'
import { Badge } from 'nxui/src'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
interface Props {
    domain?: Domain
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function Badges({ domain }: Props) {
    if (!domain) {
        return null
    }

    const {
        status,
        meta: { domain_status, expiry }
    } = domain
    const now = DateTime.now()
    const expiryDate = parseISO(expiry)
    const expiryDiff = expiryDate.diff(now, 'days').toObject().days ?? 0
    const { data: domainRenewal } = domainAPI.endpoints.domainRenewal.useQueryState(domain.id, { skip: !domain.id })

    return (
        <Wrapper>
            <Badge color={getServiceStatusColour(status)}>{status.toUpperCase()}</Badge>
            {domain_status === 'pending_epp_code' && <Badge color={'alternative'}>EPP CODE REQUIRED</Badge>}
            {domain_status === 'pending_registration_details' && <Badge color={'alternative'}>ELIGIBILITY REQUIRED</Badge>}
            {status === 'active' && expiryDiff > -1 && (
                <Badge color={'alternative'}>
                    EXPIRING {daysRemainsUntilExpired(expiry) === 'Today' ? daysRemainsUntilExpired(expiry) : `IN ${daysRemainsUntilExpired(expiry)}`}{' '}
                </Badge>
            )}
            {domainRenewal?.invoice && <Badge color={'alternative'}>AWAITING PAYMENT</Badge>}
        </Wrapper>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
const Wrapper = styled.div`
    display: flex;
    gap: 5px;
`
