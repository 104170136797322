/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { Field, Formik, FormikProps, useFormikContext } from 'formik'
import htmr from 'htmr'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { Loader } from 'components/loader'
import { Anchor, Lightbox, LightboxBody, LightboxHeader, Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useGetSecurityQuery } from 'api/account'
import { useCountriesQuery, useCountryStatesQuery } from 'api/app'
import { authAPI, useRegisterNewUserMutation } from 'api/authentication'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { useDomainInformationFromCart } from 'containers/authentication/forms/signup/hooks'
import { regex } from 'helpers/utils'
import { useAppDispatch, useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
import { Auth, SignUp } from 'containers/authentication/authentication.styles'
import { S } from './style'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
import type { SecurityQuestion } from 'models/account'
import type { IUserSignUp } from 'models/authentication'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function AuthSignUpForm() {
    /***** HOOKS *****/
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { token } = useParams()
    const { domainContactFields, setIsUsingDomainContactFields, isComingFromCheckout, isUsingDomainContactFields } = useDomainInformationFromCart()
    const { default_country } = useAppSelector((state) => state.app.appSettings.provider)

    /***** STATE *****/
    const [selectedCountry, setSelectedCountry] = useState(default_country || undefined)
    const signUpFormRef = useRef<FormikProps<IUserSignUp>>(null)

    /***** QUERIES *****/
    const { data: contriesData, isSuccess: isCountrySuccess } = useCountriesQuery({ type: 'restricted' })
    const { isSuccess: acceptInvitationSuccess, data: acceptInvitationData } = authAPI.endpoints.acceptInvitation.useQueryState(token)
    const { data: securityQuestions, isLoading: isSecurityLoading } = useGetSecurityQuery()
    const [register, { isLoading: isRegisterNewUserLoading }] = useRegisterNewUserMutation({
        fixedCacheKey: 'register-new-user'
    })
    const { states } = useCountryStatesQuery(selectedCountry || skipToken, {
        selectFromResult: ({ data, ...rest }) => ({
            ...rest,
            states:
                data?.map(({ name, iso_code }) => ({
                    label: name,
                    value: iso_code
                })) ?? []
        })
    })

    /***** FUNCTIONS *****/
    function UpdateState() {
        const { values } = useFormikContext<{ country: string; state: string }>()

        useEffect(() => {
            if (values.country !== selectedCountry && default_country !== '') {
                setSelectedCountry(values.country)
            }
        }, [values.country, default_country, isCountrySuccess])

        return null
    }

    const initialValues = useMemo<IUserSignUp>(() => {
        const defaultValues = {
            firstname: '',
            lastname: '',
            phone: '',
            dateDay: '',
            dateMonth: '',
            dateYear: '',
            dob: '',
            email: acceptInvitationData?.data?.email ?? '',
            account_type: 'personal',
            business_number: '',
            company_name: '',
            address1: '',
            address2: '',
            city: '',
            postcode: '',
            state: states[0]?.value ?? '',
            country: selectedCountry ?? 'AU',
            password: '',
            password_confirmation: '',
            security_question_id: securityQuestions?.[0]?.id ?? '',
            security_answer: '',
            terms_and_conditions: false
        } as const

        if (isUsingDomainContactFields) {
            return {
                ...defaultValues,
                ...domainContactFields
            }
        }

        return defaultValues
    }, [acceptInvitationData, selectedCountry, securityQuestions, states, isUsingDomainContactFields, domainContactFields])

    /***** RENDER HELPERS *****/
    const now = DateTime.now()

    /***** RENDER *****/
    return (
        <Formik
            innerRef={signUpFormRef}
            isInitialValid={false}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={Yup.object({
                account_type: Yup.mixed().oneOf(['personal', 'business']).required('Please select an account type'),
                firstname: Yup.string().required('Required'),
                lastname: Yup.string().required('Required'),
                phone: Yup.string().required('Required').matches(regex.internationalPhone, 'Invalid phone number'),
                dateDay: Yup.string().required('Day is Required'),
                dateMonth: Yup.string().required('Month is Required'),
                dateYear: Yup.number()
                    .required('Year is Required')
                    .min(now.year - 125, 'Date of Birth cannot be set way back to the past')
                    .test('expiry', 'Date of Birth must be set to the past', (dateYear) => {
                        if (signUpFormRef.current && dateYear) {
                            const {
                                values: { dateDay, dateMonth }
                            } = signUpFormRef.current

                            if (dateDay && dateDay.length >= 1 && dateMonth && dateMonth.length >= 1 && String(dateYear).length >= 1) {
                                const expiry = DateTime.fromObject({
                                    year: Number(dateYear),
                                    month: Number(dateMonth),
                                    day: Number(dateDay)
                                })

                                return expiry < now
                            }
                        }

                        return true
                    }),
                email: Yup.string().required('Required').email('Invalid Email address'),
                business_number: Yup.string().when('account_type', {
                    is: 'business',
                    then: (schema) => schema.max(255, 'Business Number must not exceed 255 characters').required('Required'),
                    otherwise: (schema) => schema.notRequired()
                }),
                company_name: Yup.string().when('account_type', {
                    is: 'business',
                    then: (schema) => schema.required('Required'),
                    otherwise: (schema) => schema.notRequired()
                }),
                address1: Yup.string().required('Required'),
                city: Yup.string().required('Required'),
                postcode: Yup.string().matches(regex.specialCharacters, 'Postcode cannot have special characters').required('Required'),
                state: Yup.string().required('Required'),
                password: Yup.string()
                    .required('Required')
                    .matches(regex.password, 'Password must contain at least 8 characters, one uppercase, one number and one special case character'),
                password_confirmation: Yup.string()
                    .oneOf([Yup.ref('password')], 'Password must match')
                    .required('Required'),
                security_question_id: Yup.number().required('Required'),
                security_answer: Yup.string().required('Required'),
                terms_and_conditions: Yup.boolean().oneOf([true], 'You must accept the terms and conditions')
            })}
            onSubmit={async ({ account_type, company_name, business_number, dateDay, dateMonth, dateYear, postcode, ...values }) => {
                await register({
                    ...values,
                    ...(account_type === 'business' && { company_name, business_number }),
                    postcode: postcode.length >= 1 ? postcode : 'N/A',
                    account_type,
                    dob:
                        DateTime.fromObject({
                            day: Number(dateDay),
                            month: Number(dateMonth),
                            year: Number(dateYear)
                        }).toISO() ?? ''
                }).unwrap()
                await dispatch(authAPI.endpoints.getSession.initiate(undefined, { forceRefetch: true }))
            }}
        >
            {({ isSubmitting, isValid, values }) => {
                const countries =
                    contriesData?.map(({ name, iso_code }) => ({
                        label: name,
                        value: iso_code
                    })) ?? []

                return (
                    <Auth.Wrapper>
                        <Auth.Title>New User Registration</Auth.Title>

                        {/* Render checkbox to use information from first domain in cart */}
                        {isComingFromCheckout && !!domainContactFields && (
                            <S.DomainContactContainer>
                                <span>Account Contact Details</span>
                                <S.CheckBox checked={isUsingDomainContactFields} onClick={() => setIsUsingDomainContactFields((prev) => !prev)}>
                                    Use My Domain Contact Details
                                </S.CheckBox>
                            </S.DomainContactContainer>
                        )}
                        <Auth.Break />
                        <SignUp.Form>
                            <SignUp.Body>
                                {renderPersonalFields(isSubmitting, values.account_type, acceptInvitationSuccess)}
                                {renderResidentialFields(isSubmitting, countries, states)}
                                {renderSecurityFields(isSubmitting, isSecurityLoading, securityQuestions)}
                                <RenderTermsAndConditions />
                            </SignUp.Body>
                            <UpdateState />
                            <Auth.Submit
                                color='primary'
                                type='submit'
                                disabled={isRegisterNewUserLoading || isSubmitting || !isValid}
                                loading={isRegisterNewUserLoading || isSubmitting ? 'Submitting...' : false}
                            >
                                Create Account
                            </Auth.Submit>
                        </SignUp.Form>
                        <Auth.Break />
                        <Auth.Border />
                        <Auth.Footer>
                            <Anchor color={'select'} onClick={() => navigate('/login')}>
                                Back to Login
                            </Anchor>
                        </Auth.Footer>
                    </Auth.Wrapper>
                )
            }}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   FORM FIELDS
 **********************************************************************************************************/
function renderPersonalFields(isSubmitting: boolean, account: string, isInvitation: boolean) {
    const { default_country } = useAppSelector((state) => state.app.appSettings.provider)

    return (
        <>
            <SignUp.Title top={true}>Personal Details</SignUp.Title>
            <SignUp.Section>
                <SignUp.Row>
                    <Field
                        required
                        id={'firstname'}
                        label={'First Name'}
                        name={'firstname'}
                        type={'text'}
                        autoComplete={'given-name'}
                        disabled={isSubmitting}
                        component={NXUIForm.InputField}
                    />
                    <Field
                        required
                        id={'lastname'}
                        label={'Last Name'}
                        name={'lastname'}
                        type={'text'}
                        autoComplete={'family-name'}
                        disabled={isSubmitting}
                        component={NXUIForm.InputField}
                    />
                </SignUp.Row>
                <SignUp.Row>
                    <Field
                        required
                        id={'phone'}
                        label={'Phone Number'}
                        name={'phone'}
                        type={'text'}
                        country={typeof default_country === 'string' ? default_country.toLowerCase() : 'au'}
                        autoFormat={false}
                        disabled={isSubmitting}
                        component={NXUIForm.PhoneField}
                    />
                    <Field
                        required
                        label={'Date of Birth'}
                        placeholder={'DD/MM/YYYY'}
                        name={'dob'}
                        type={'text'}
                        disabled={isSubmitting}
                        component={NXUIForm.DateInputField}
                    />
                </SignUp.Row>
                <Field
                    required
                    id={'email'}
                    label={'Email Address'}
                    name={'email'}
                    type={'text'}
                    autoComplete={'email'}
                    disabled={isInvitation}
                    component={NXUIForm.InputField}
                />
                <Field
                    required
                    id={'account_type'}
                    label={'Account Type'}
                    name={'account_type'}
                    type={'select'}
                    disabled={isSubmitting}
                    list={[
                        { value: 'personal', label: 'Personal' },
                        { value: 'business', label: 'Business' }
                    ]}
                    component={NXUIForm.SelectField}
                />
            </SignUp.Section>
            {account === 'business' && (
                <>
                    <SignUp.Title top={true}>Business Details</SignUp.Title>
                    <SignUp.Section>
                        <SignUp.Row>
                            <Field
                                required
                                id={'business_number'}
                                label={'Business Number'}
                                name={'business_number'}
                                type={'text'}
                                disabled={isSubmitting}
                                component={NXUIForm.InputField}
                            />
                            <Field
                                required
                                id={'company_name'}
                                label={'Company'}
                                name={'company_name'}
                                type={'text'}
                                autoComplete={'organization-title'}
                                disabled={isSubmitting}
                                component={NXUIForm.InputField}
                            />
                        </SignUp.Row>
                    </SignUp.Section>
                </>
            )}
        </>
    )
}

function renderResidentialFields(
    isSubmitting: boolean,
    countries: Array<{ label: string; value: string }>,
    states: Array<{ label: string; value: string }>
) {
    return (
        <>
            <SignUp.Title>Residential Details</SignUp.Title>
            <SignUp.Section>
                <Field
                    required
                    id={'address1'}
                    label={'Address Line 1'}
                    name={'address1'}
                    type={'text'}
                    disabled={isSubmitting}
                    component={NXUIForm.InputField}
                />
                <Field
                    id={'address2'}
                    label={'Address Line 2'}
                    name={'address2'}
                    type={'text'}
                    autoComplete={'address-line2'}
                    placeholder={'Apartment, suite, unit number, etc.'}
                    disabled={isSubmitting}
                    component={NXUIForm.InputField}
                />
                <SignUp.Row>
                    <Field
                        required
                        id={'city'}
                        label={'City'}
                        name={'city'}
                        type={'text'}
                        autoComplete={'city'}
                        disabled={isSubmitting}
                        component={NXUIForm.InputField}
                    />
                    <Field
                        required
                        id={'postcode'}
                        label={'Postcode'}
                        name={'postcode'}
                        type={'text'}
                        autoComplete={'postal-code'}
                        disabled={isSubmitting}
                        component={NXUIForm.InputField}
                    />
                </SignUp.Row>
                <SignUp.Row>
                    <Field
                        required
                        label={'Country/Region'}
                        name={'country'}
                        type={'select'}
                        disabled={isSubmitting}
                        list={countries}
                        component={NXUIForm.SelectField}
                    />
                    <Field
                        required
                        label={'State/Province'}
                        name={'state'}
                        type={'select'}
                        disabled={isSubmitting}
                        list={states}
                        component={NXUIForm.SelectField}
                    />
                </SignUp.Row>
            </SignUp.Section>
        </>
    )
}

function renderSecurityFields(isSubmitting: boolean, isSecurityLoading: boolean, securityQuestions?: Array<SecurityQuestion>) {
    if (isSecurityLoading) {
        return (
            <>
                <SignUp.Title>Security Information</SignUp.Title>
                <Auth.Break />
                <SignUp.Section>
                    <Loader message={'Loading Security Information...'} width={38} height={38} />
                </SignUp.Section>
                <Auth.Break />
            </>
        )
    }

    if (!securityQuestions) return null

    return (
        <>
            <SignUp.Title>Security Information</SignUp.Title>
            <SignUp.Section>
                <SignUp.Row>
                    <Field
                        required
                        id={'password'}
                        label={'Password'}
                        name={'password'}
                        type={'password'}
                        autoComplete={'new-password'}
                        disabled={isSubmitting}
                        component={NXUIForm.SensitiveField}
                    />
                    <Field
                        required
                        id={'password_confirmation'}
                        label={'Confirm Password'}
                        name={'password_confirmation'}
                        type={'password'}
                        autoComplete={'new-password'}
                        disabled={isSubmitting}
                        component={NXUIForm.SensitiveField}
                    />
                </SignUp.Row>
                <Field
                    id={'question'}
                    label={'Security Question'}
                    name={'security_question_id'}
                    type={'select'}
                    component={NXUIForm.SelectField}
                    list={securityQuestions?.map(({ id, question }) => ({
                        label: question,
                        value: id
                    }))}
                />
                <Field
                    required
                    id={'answer'}
                    label={'Answer'}
                    name={'security_answer'}
                    type={'text'}
                    disabled={isSubmitting}
                    component={NXUIForm.InputField}
                />
            </SignUp.Section>
        </>
    )
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function RenderTermsAndConditions() {
    /***** HOOKS *****/
    const { isSubmitting } = useFormikContext()
    const { terms_and_conditions } = useAppSelector((state) => state.app.appSettings.provider)

    /***** STATE *****/
    const [isModalOpen, setIsModalOpen] = useState(false)

    /***** RENDER HELPERS *****/
    type TAnchorProps = Omit<React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, 'ref'>
    type TOrderedListItemProps = Omit<React.DetailedHTMLProps<React.OlHTMLAttributes<HTMLOListElement>, HTMLOListElement>, 'ref'>
    type TBlockQuoteProps = Omit<React.DetailedHTMLProps<React.BlockquoteHTMLAttributes<HTMLElement>, HTMLElement>, 'ref'>
    type TListItemProps = Omit<React.DetailedHTMLProps<React.OlHTMLAttributes<HTMLOListElement>, HTMLOListElement>, 'ref'>

    const htmrOptions = {
        transform: {
            a: (props: TAnchorProps) => <S.Anchor {...props} target='_blank' color='primary' />,
            ul: ({ children, ...props }: TListItemProps) => (
                <S.UnorderedList {...props}>
                    <p>{children}</p>
                </S.UnorderedList>
            ),
            ol: ({ children, ...props }: TOrderedListItemProps) => (
                <S.OrderedListItem {...props}>
                    <p>{children}</p>
                </S.OrderedListItem>
            ),
            blockquote: (props: TBlockQuoteProps) => <S.BlockQuote {...props} />
        }
    }

    /***** RENDER *****/
    return (
        <>
            <SignUp.Title>Terms and Conditions</SignUp.Title>
            <S.TOS>
                Before continuing, you must agree to our Terms and Conditions. You can read the{' '}
                <S.Anchor color='primary' onClick={() => setIsModalOpen(true)}>
                    Terms and Conditions here
                </S.Anchor>
            </S.TOS>
            <S.CheckboxFieldWrapper>
                <Field
                    required
                    id='tos'
                    name='terms_and_conditions'
                    disabled={isSubmitting}
                    component={NXUIForm.CheckboxField}
                    description='I agree to the Terms and Conditions'
                />
            </S.CheckboxFieldWrapper>

            <Lightbox
                type='custom'
                conditions={{ action: () => setIsModalOpen(false), state: isModalOpen }}
                content={
                    <>
                        <LightboxHeader title='Terms and Conditions' conditions={{ action: () => setIsModalOpen(false), state: isModalOpen }} />
                        <S.LightboxBodyWrapper>
                            <LightboxBody>{htmr(terms_and_conditions ?? '', htmrOptions)}</LightboxBody>
                        </S.LightboxBodyWrapper>
                    </>
                }
            />
        </>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
