/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Transition as TransitionGroup } from 'react-transition-group'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Timer } from '../../helpers/functions'

/**********************************************************************************************************
 *   STYLE
 **********************************************************************************************************/
import styled from 'styled-components'

const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 }
}

const Transition = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
`

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Fade = ({ className, when, onLoad, children }) => {
    const [onLoadCalled, setOnLoadCalled] = useState(false)
    const [previousWhen, setPreviousWhen] = useState(false)
    const [render, updateRender] = useState(false)
    const [inState, updateInState] = useState(false)

    const queueRenderTimer = () =>
        Timer(() => {
            updateRender(children)
        }, 200)

    const queueStateTimer = () =>
        Timer(() => {
            updateInState(true)
        }, 200)

    const callOnLoad = () => {
        if (onLoad && !onLoadCalled) {
            onLoad()
            setOnLoadCalled(true)
        }
    }

    useEffect(() => {
        if (previousWhen && when !== previousWhen) {
            setPreviousWhen(when)
            updateInState(false)
            queueRenderTimer()
            queueStateTimer()
        }

        if (!previousWhen) {
            setPreviousWhen(when)
            updateRender(children)
            updateInState(true)
        }

        if (updateInState && !onLoadCalled) {
            callOnLoad()
        }
    }, [when, render, previousWhen, updateRender, updateInState, setPreviousWhen])

    return (
        <TransitionGroup in={inState} timeout={200}>
            {(state) => (
                <Transition
                    className={className}
                    style={{
                        ...transitionStyles[state]
                    }}
                >
                    {render}
                </Transition>
            )}
        </TransitionGroup>
    )
}

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
Fade.propTypes = {
    when: PropTypes.any,
    onLoad: PropTypes.func
}
