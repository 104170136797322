/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { forwardRef, useMemo, useState } from 'react'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'helpers/hooks/useAppViewport'
import { useClickAway } from 'helpers/hooks/useClickaway'
import { useToggle } from 'helpers/hooks/useToggle'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { HeaderAction } from './action'
import { HeaderNavContainer } from './nav'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { HeaderNavItem, THeaderNavItem } from './nav/item'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { HeaderContext } from './context'
import { HeaderStyles as S } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type THeaderProps = {
    /**
     * The children of the Header. This is intended to be a Header.Nav element and will be rendered in the center
     * of the nav on desktop and mobile.
     *
     * Note: Depending on the implementation, this may not fit on mobile so it is intended that the Header.Nav and Header.Nav.Item
     * compound components are used as these will automatically behave as expected
     */
    children: React.ReactNode

    /**
     * Element intended to be rendered as the logo. Spacing is provided for this element on the left hand
     * side of the nav on desktop and is placed in the sidebar on mobile.
     */
    logo: React.ReactNode

    /**
     * Element intended to be rendered as the Login button. Spacing is provided for this element on the right hand
     * side of the nav on desktop and mobile.
     */
    login: React.ReactNode

    /**
     * Dictates how the header should render. If the header is set to original, then it will not render children and instead will not display
     * navigation options. If sidebar is set, then children will be rendered and sidebar will be visible on mobile.
     */
    type: 'original' | 'sidebar'
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _Header = forwardRef<HTMLElement, THeaderProps>(({ children, logo, login, type = 'sidebar' }, ref) => {
    /***** STATE *****/
    const [activeProps, setActiveTitle] = useState<PropsFrom<THeaderNavItem>>({} as PropsFrom<THeaderNavItem>)
    const [isSidenavOpen, toggleSidenavOpen, setSidenavOpen] = useToggle(false)

    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm', 'md'])
    const sidenavRef = useClickAway<HTMLDivElement>(() => setSidenavOpen(false))

    /***** RENDER HELPERS *****/
    const contextValue = useMemo(() => ({ setActiveTitle, closeSidebar: () => setSidenavOpen(false) }), [])
    const sidebarContextValue = useMemo(() => ({ setActiveTitle, closeSidebar: () => setSidenavOpen(false), inSidebar: true }), [])

    /***** RENDER *****/
    switch (type) {
        case 'original':
            return (
                <S.Header type='original' ref={ref}>
                    <HeaderContext.Provider value={contextValue}>
                        {logo}
                        {login}
                    </HeaderContext.Provider>
                </S.Header>
            )
        case 'sidebar':
        default:
            if (isMobile) {
                return (
                    <S.Header ref={ref}>
                        <S.Burger height={24} onClick={toggleSidenavOpen} />

                        {/* Renders a copy of the active nav item using the registered props from the context */}
                        <HeaderNavContainer>
                            <HeaderNavItem {...activeProps} matcher={/.*/} />
                        </HeaderNavContainer>

                        {login}

                        {/* Sidebar - not visible unless opened */}
                        <HeaderContext.Provider value={sidebarContextValue}>
                            <S.SideNav ref={sidenavRef} open={isSidenavOpen}>
                                <S.SideNavInner>
                                    {logo}
                                    {children}
                                </S.SideNavInner>
                            </S.SideNav>
                        </HeaderContext.Provider>
                    </S.Header>
                )
            }

            return (
                <S.Header ref={ref}>
                    <HeaderContext.Provider value={contextValue}>
                        {logo}
                        {children}
                        {login}
                    </HeaderContext.Provider>
                </S.Header>
            )
    }
})
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export const Header = Object.assign(_Header, {
    Logo: S.Logo,
    Action: HeaderAction,
    Nav: HeaderNavContainer
})
