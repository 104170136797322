import { AdjustmentsVerticalIcon, PlusCircleIcon } from '@heroicons/react/20/solid'
import { useDomainProductListQuery } from 'api/shop'
import Transfer from 'containers/shop/domain/domain.transfer'
import Register from 'containers/shop/domain/register'
import { Shop } from 'containers/shop/shop.styles'
import { motion } from 'framer-motion'
import { useLocalStorage } from 'helpers/hooks'
import { componentTransitionVariants } from 'helpers/utils'
import { useEffect, useState } from 'react'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function DomainsShopComponent() {
    const tabs = [
        {
            label: 'Register',
            icon: PlusCircleIcon,
            render: <Register embedded={false} />
        },
        {
            label: 'Transfer',
            icon: AdjustmentsVerticalIcon,
            render: <Transfer embedded={false} />
        }
    ]
    const [active, setActive] = useState(tabs.find((tab) => tab.label === 'Register'))
    const [, setTLDList] = useLocalStorage<Array<string>>('tld', [])
    const { isSuccess, data } = useDomainProductListQuery()

    useEffect(() => {
        if (!localStorage.getItem('tld') && isSuccess && data) {
            const tld = data.map(({ name }: { name: string }) => name)
            setTLDList(tld)
        }
    }, [isSuccess])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Shop.Browse.Base>
            <motion.div style={{ width: '100%' }} variants={componentTransitionVariants} initial={'initial'} animate={'animate'} exit={'exit'}>
                <Shop.Browse.Tabs.Wrapper>
                    <Shop.Browse.Tabs.Options setActive={setActive} active={active} tabs={tabs} />
                    <Shop.Browse.Tabs.Content when={active} />
                </Shop.Browse.Tabs.Wrapper>
            </motion.div>
        </Shop.Browse.Base>
    )
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { DomainsShopComponent as PurchaseDomains }
