/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import PropTypes from 'prop-types'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Breadcrumbs } from '../breadcrumbs'
import { Sidenav } from '../sidenav'
import { Fade } from '../transition/transition'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import { Flex, Frame, Inset } from './frame.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const FrameInset = ({ className, inset, icon, location, content, left, right }) => {
    return (
        <>
            {inset === 'none' && content.render}
            {inset === 'full' && (
                <Flex.Full className={className}>
                    {location && <Breadcrumbs icon={icon} location={location} />}
                    {content.when ? <Fade when={content.when}>{content.render}</Fade> : content.render}
                </Flex.Full>
            )}
            {inset === 'split' && (
                <Flex.Wrapper className={className}>
                    {location && <Breadcrumbs icon={icon} location={location} />}
                    <Flex.Split>
                        <Inset.Left as={`aside`}>{left.when ? <Fade when={left.when}>{left.render}</Fade> : left.render}</Inset.Left>

                        <Inset.Right as={`section`}>{right.when ? <Fade when={right.when}>{right.render}</Fade> : right.render}</Inset.Right>
                    </Flex.Split>
                </Flex.Wrapper>
            )}
        </>
    )
}

export const FrameComponent = ({ className, content, guest, company, nav, profile, support, shop, switchAcc, logout }) => {
    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Frame.Base className={className && className} guest={guest}>
            {!guest && <Sidenav shop={shop} support={support} company={company} nav={nav} profile={profile} switchAcc={switchAcc} logout={logout} />}
            {content.render}
        </Frame.Base>
    )
}

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
FrameInset.propTypes = {
    inset: PropTypes.oneOf(['full', 'split', 'none']),
    icon: PropTypes.element,
    content: PropTypes.oneOfType([
        PropTypes.shape({
            when: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
            render: PropTypes.oneOfType([PropTypes.node, PropTypes.element])
        }),
        PropTypes.bool
    ]),
    left: PropTypes.oneOfType([
        PropTypes.shape({
            when: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
            render: PropTypes.oneOfType([PropTypes.node, PropTypes.element])
        }),
        PropTypes.bool
    ]),
    right: PropTypes.oneOfType([
        PropTypes.shape({
            when: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
            render: PropTypes.oneOfType([PropTypes.node, PropTypes.element])
        }),
        PropTypes.bool
    ])
}

FrameComponent.propTypes = {
    className: PropTypes.string,
    guest: PropTypes.bool,
    company: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        application: PropTypes.string
    }),
    nav: PropTypes.arrayOf(
        PropTypes.arrayOf(
            // Array of "SidenavItem" components
            PropTypes.element
        )
    ),
    profile: PropTypes.shape({
        account: PropTypes.shape({
            name: PropTypes.string,
            company: PropTypes.string,
            account_number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            role: PropTypes.shape({
                name: PropTypes.string
            })
        }),
        redirect: PropTypes.func
    }),
    logout: PropTypes.func
}

FrameInset.defaultProps = {
    inset: 'full',
    content: {
        when: true,
        render: <></>
    },
    left: false,
    right: false
}

FrameComponent.defaultProps = {
    guest: false,
    inset: 'full',
    content: {
        when: true,
        render: <></>
    },
    left: false,
    right: false,
    company: {
        src: '',
        alt: '',
        application: ''
    },
    nav: [],
    profile: {
        account: {
            name: '',
            company: '',
            account_number: '',
            role: {
                name: ''
            }
        },
        redirect: () => void 0
    },
    logout: () => void 0
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { FrameComponent as Frame }

