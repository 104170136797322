import { Lightbox } from 'nxui/src'
import styled from 'styled-components'

export const Styles = {
    Lightbox: styled(Lightbox)`
        span {
            color: white;
        }
    `
}
