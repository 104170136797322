/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import PropTypes from 'prop-types'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Sidenav } from '../sidenav.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const SidenavItem = ({ children, active, color }) => {
    return (
        <Sidenav.NavItem.Wrapper active={active} color={color}>
            {children}
        </Sidenav.NavItem.Wrapper>
    )
}

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
SidenavItem.propTypes = {
    // Can be any react element. In most cases "children" should be a react router "Link" component.
    // To use an icon with the SidenavItem, nest the svg icon within the "link" that you pass as children.
    children: PropTypes.element,

    // If its the currently active link.
    active: PropTypes.bool,

    // color of the link
    color: PropTypes.string
}

export default SidenavItem
