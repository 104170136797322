/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { TableTitleStyles as S } from './tableTitle.styles'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TTableTitle = React.FC<{ title: string; children: React.ReactNode; isActive?: boolean }>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const TableTitle: TTableTitle = ({ title, children, isActive = true }) => {
    return (
        <>
            {isActive && (
                <S.Outer>
                    <S.Title>{title}</S.Title>
                    <S.Children>{children}</S.Children>
                </S.Outer>
            )}
        </>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
