/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Anchor, Card } from 'nxui/src'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import BillingCycleLightbox from 'containers/services/lightbox/billingCycle/billingCycle.lightbox'
import ChangeServicePlanLightbox from 'containers/services/lightbox/changePlan/changeServicePlan.lightbox'
import RenewLightbox from 'containers/services/lightbox/renew.lightbox'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { serviceAPI, useCancelChangePlanRequestMutation } from 'api/service'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setAppActiveInvoice } from 'store/slices/appSlice'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { parseSQL, titleCase } from 'helpers/utils'
import { useAppDispatch } from 'store/hooks'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Services } from 'containers/services/services.styles'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
import { ServiceMetaPayload } from 'api/service/types'
import { ChangePlanStatus } from 'models/enums'

interface Lightbox {
    status: boolean
    template: 'none' | 'renew' | 'billing-cycle' | 'change-plan'
}

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
interface Props {
    meta?: ServiceMetaPayload
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function Information({ meta = { page: '1' } }: Props) {
    const dispatch = useAppDispatch()
    const { id } = useParams()
    const serviceId = Number(id)
    const { isLoading, isError, service } = serviceAPI.endpoints.services.useQueryState(meta, {
        selectFromResult: ({ data, ...rest }) => ({
            ...rest,
            service: data?.data.find((serviceItem) => serviceItem.id === serviceId)
        })
    })
    const { data: changePlanStatusData, isFetching: isChangePlanStatusFetching } = serviceAPI.endpoints.changePlanStatus.useQueryState(serviceId)
    const { data: availablePlans } = serviceAPI.endpoints.availablePlans.useQueryState(serviceId)
    const { data: billingCycles } = serviceAPI.endpoints.billingCycle.useQueryState(serviceId)
    const [cancelChangePlanRequest, { isLoading: isCancelChangeLoading }] = useCancelChangePlanRequestMutation()
    const [lightbox, setLightbox] = useState<Lightbox>({ status: false, template: 'none' })
    const columns = [
        {
            Header: 'Information',
            accessor: 'information'
        },
        {
            Header: 'name',
            accessor: 'name'
        },
        {
            Header: '',
            accessor: 'action',
            align: 'right'
        }
    ]

    const serviceData = useMemo(() => {
        if (isLoading || isError || !service) {
            return []
        }

        const changePlanAction = () =>
            changePlanStatusData?.status === ChangePlanStatus.Pending ? (
                <Anchor
                    color={'primary'}
                    disabled={isCancelChangeLoading || isChangePlanStatusFetching}
                    onClick={() => cancelChangePlanRequest(serviceId)}
                >
                    {isCancelChangeLoading || isChangePlanStatusFetching ? '...' : 'Cancel Plan Change'}
                </Anchor>
            ) : (
                <Anchor color={'primary'} onClick={() => setLightbox({ status: true, template: 'change-plan' })}>
                    Change
                </Anchor>
            )

        return [
            {
                information: 'Plan',
                name: service.action_invoices?.change_plan ? (
                    <Services.Invoice
                        color={'primary'}
                        onClick={() => {
                            if (!service.action_invoices.change_plan) return
                            dispatch(setAppActiveInvoice({ invoice: service.action_invoices.change_plan, status: true }))
                        }}
                    >{`#${service.action_invoices?.change_plan.id}`}</Services.Invoice>
                ) : (
                    service?.product.name
                ),
                action:
                    availablePlans &&
                    (availablePlans.plan_paths.length !== 0 ||
                        availablePlans.current_product.config_option_groups?.some((group) => group.config_options.length > 0))
                        ? changePlanAction()
                        : null
            },
            {
                information: `Primary ${titleCase(service.product.name_type || 'name')}`,
                name: service?.name
            },
            {
                information: 'Next Due Date',
                name: service.action_invoices?.renewal ? (
                    <Services.Invoice
                        color={'primary'}
                        onClick={() => {
                            if (!service.action_invoices.renewal) return
                            dispatch(setAppActiveInvoice({ invoice: service.action_invoices.renewal, status: true }))
                        }}
                    >{`#${service.action_invoices?.renewal.id}`}</Services.Invoice>
                ) : (
                    parseSQL(service.next_due_date).setLocale('en-AU').toLocaleString()
                ),
                action: service?.action_invoices?.renewal ? null : (
                    <Anchor color={'primary'} onClick={() => setLightbox({ status: true, template: 'renew' })}>
                        Renew
                    </Anchor>
                )
            },
            {
                information: 'Billing Cycle',
                name: service?.billing_cycle?.name,
                action:
                    billingCycles && billingCycles.length >= 2 ? (
                        <Anchor
                            color={'primary'}
                            onClick={() =>
                                setLightbox({
                                    status: true,
                                    template: 'billing-cycle'
                                })
                            }
                        >
                            Change
                        </Anchor>
                    ) : null
            }
        ]
    }, [
        service?.name,
        service?.product?.name,
        service?.billing_cycle?.name,
        service?.next_due_date,
        isLoading,
        isError,
        changePlanStatusData?.status,
        availablePlans,
        billingCycles,
        isCancelChangeLoading,
        service?.action_invoices?.change_plan,
        service?.action_invoices?.renewal
    ])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <>
            <Card
                table={{
                    conditions: {
                        loading: isLoading && 'Loading information...',
                        sort: false
                    },
                    render: {
                        columns: () => columns,
                        data: () => serviceData
                    }
                }}
            />
            <BillingCycleLightbox
                id={serviceId}
                status={lightbox.status && lightbox.template === 'billing-cycle'}
                close={() => setLightbox({ status: false, template: 'none' })}
                meta={meta}
            />
            <RenewLightbox
                id={serviceId}
                status={lightbox.status && lightbox.template === 'renew'}
                close={() => setLightbox({ status: false, template: 'none' })}
            />
            <ChangeServicePlanLightbox
                status={lightbox.status && lightbox.template === 'change-plan'}
                close={() => setLightbox({ status: false, template: 'change-plan' })}
                meta={meta}
            />
        </>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
