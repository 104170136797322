/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Formik } from 'formik'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { billingAPI } from 'api/billing'
import { useCheckoutCartMutation } from 'api/shop'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Payment } from 'components/invoice/invoice.styles'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { renderGenericPaymentMethodDescription } from 'components/invoice/invoice'
import { useApplyAccountCreditToInvoice } from 'containers/billing/utils'
import { useAppDispatch, useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   INTERFACE & TYPES
 **********************************************************************************************************/
import { PaymentMethod } from 'models/billing'
import { useNavigate } from 'react-router-dom'
import { ShopRouteBuilder } from 'containers/shop/helpers/routeMatchers'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Used for any asyncronous payment methods, such as EFT and BPAY
 */
export function EftForm({ selectedPaymentMethod }: { selectedPaymentMethod?: PaymentMethod }) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { cart } = useAppSelector((state) => state.app)
    const { checkoutAccountCredit } = useAppSelector((state) => state.shop)
    const [checkoutCart] = useCheckoutCartMutation({ fixedCacheKey: 'checkout-cart' })
    const { applyAccountCreditToInvoice } = useApplyAccountCreditToInvoice()

    /*   RENDER COMPONENT
     *****************************************************/
    if (!cart || !selectedPaymentMethod) return null

    return (
        <Formik
            initialValues={{}}
            onSubmit={async () => {
                const order = await checkoutCart({
                    payment_method_id: selectedPaymentMethod.id,
                    uuid: cart.uuid
                }).unwrap()

                await applyAccountCreditToInvoice(order.invoice.id, checkoutAccountCredit.amount)

                dispatch(billingAPI.endpoints.invoice.initiate(order.invoice.id, { forceRefetch: true })).unwrap()
                navigate(ShopRouteBuilder.orderId(order.id))
            }}
        >
            {() => (
                <Payment.Form id='eftForm'>
                    {renderGenericPaymentMethodDescription(selectedPaymentMethod?.name || '', selectedPaymentMethod?.instructions || '')}
                </Payment.Form>
            )}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
