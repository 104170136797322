/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Form, Formik, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Contact } from 'containers/domains/domains.styles'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useCountriesQuery, useCountryStatesQuery } from 'api/app'
import { domainAPI, useUpdateDomainContactsMutation } from 'api/domain'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { regex } from 'helpers/utils'
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
import { Contact as ContactType } from 'models/account'
import { ContactsResponse, DomainContactType } from 'models/domain'

interface Props {
    type: DomainContactType
    contact: ContactType
    close: () => void
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function DomainContactForm({ type, contact, close }: Props) {
    const { id } = useParams()
    const domainId = Number(id)
    const {
        appSettings: {
            provider: { default_country }
        }
    } = useAppSelector((state) => state.app)
    const { data: domainContacts } = domainAPI.endpoints.domainContacts.useQueryState(domainId)
    const [updateDomainContacts] = useUpdateDomainContactsMutation({ fixedCacheKey: 'update-domain-contact' })
    const [selectedCountry, setSelectedCountry] = useState(default_country)
    const { data: contriesData } = useCountriesQuery({ type: 'all' })
    const { states } = useCountryStatesQuery(selectedCountry, {
        selectFromResult: (data, ...rest) => ({
            ...rest,
            states:
                data.data?.map(({ name, iso_code }) => ({
                    label: name,
                    value: iso_code
                })) ?? []
        })
    })

    const countries =
        contriesData?.map(({ name, iso_code }) => ({
            label: name,
            value: iso_code
        })) ?? []

    function UpdateState() {
        const { values } = useFormikContext<{ country: string; state: string }>()

        useEffect(() => {
            if (values.country !== selectedCountry) {
                setSelectedCountry(values.country)
            }
        }, [values.country])

        return null
    }

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Formik
            initialValues={contact}
            validationSchema={Yup.object({
                firstname: Yup.string().required('Required'),
                lastname: Yup.string().required('Required'),
                phone: Yup.string()
                    .required('Required')
                    .matches(/^\+?[1-9]*\.?\d{1,14}$/, 'Invalid phone number'),
                email: Yup.string().required('Required').email('Invalid Email address'),
                address1: Yup.string().required('Required'),
                suburb: Yup.string().required('Required'),
                postcode: Yup.string().matches(regex.specialCharacters, 'Postcode cannot has special characters')
            })}
            onSubmit={async (values, { resetForm }) => {
                const updatedContact = { [type]: values }

                if (domainContacts) {
                    const existingContacts = Object.fromEntries(
                        Object.entries(domainContacts).filter(([, contactFields]) => Boolean(contactFields))
                    ) as ContactsResponse

                    try {
                        await updateDomainContacts({ id: domainId, contacts: { ...existingContacts, ...updatedContact } }).unwrap()
                        close()
                    } catch (error) {
                        resetForm()
                    }
                }
            }}
        >
            {({ isSubmitting }) => (
                <Form id={'updateDomainContactForm'}>
                    <Contact.Row>
                        <Field
                            required
                            label={'First Name'}
                            name={'firstname'}
                            type={'text'}
                            disabled={isSubmitting}
                            component={NXUIForm.InputField}
                        />
                        <Field required label={'Last Name'} name={'lastname'} type={'text'} disabled={isSubmitting} component={NXUIForm.InputField} />
                    </Contact.Row>
                    <Field
                        required
                        label={'Phone Number'}
                        name={'phone'}
                        type={'tel'}
                        autoFormat={false}
                        disabled={isSubmitting}
                        component={NXUIForm.PhoneField}
                    />
                    <Field required label={'Email Address'} name={'email'} type={'text'} component={NXUIForm.InputField} />
                    <Field label={'Organisation'} name={'organisation'} type={'text'} disabled={isSubmitting} component={NXUIForm.InputField} />
                    <Field required label={'Address 1'} name={'address1'} type={'text'} disabled={isSubmitting} component={NXUIForm.InputField} />
                    <Field
                        label={'Address 2'}
                        name={'address2'}
                        type={'text'}
                        autoComplete={'address-line2'}
                        placeholder={'Apartment, suite, unit number, etc.'}
                        disabled={isSubmitting}
                        component={NXUIForm.InputField}
                    />
                    <Contact.Row>
                        <Field label={'Suburb'} name={'suburb'} type={'text'} disabled={isSubmitting} component={NXUIForm.InputField} />
                        <Field label={'Postcode'} name={'postcode'} type={'text'} disabled={isSubmitting} component={NXUIForm.InputField} />
                    </Contact.Row>
                    <Contact.Row>
                        <Field
                            required
                            label={'Country/Region'}
                            name={'country'}
                            type={'select'}
                            disabled={isSubmitting}
                            list={countries}
                            component={NXUIForm.SelectField}
                        />
                        <Field
                            label={'State/Province'}
                            name={'state'}
                            type={'select'}
                            disabled={isSubmitting}
                            list={states}
                            component={NXUIForm.SelectField}
                        />
                    </Contact.Row>
                    <UpdateState />
                </Form>
            )}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
