/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useEffect, useRef, useState } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { RenderErrorIcon, RenderInputLabel, Style } from '../util'
import { Input } from './input'

/**********************************************************************************************************
 *   ASSET IMPORTS
 **********************************************************************************************************/
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled from 'styled-components'
import * as Theme from '../../../theme/theme'

const Sensitive = {
    Field: styled.input`
        ${Style.Field}
    `,
    Wrapper: styled.div`
        position: relative;
        display: inline-flex;
        flex-direction: row;
        width: 100%;
    `,
    Icon: {
        Base: styled.div`
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
            padding: 0 12px;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            background-color: ${(props) => props.theme[`background--100`]};
            ${Theme.Functions.animation(`all`)};

            &:hover {
                background-color: ${(props) => props.theme[`background--200`]};
            }
        `,
        On: styled(EyeIcon)`
            width: 20px;
            height: 20px;
            color: ${(props) => props.theme[`icon--100`]};

            &.error {
                right: 36px;
            }
        `,
        Off: styled(EyeSlashIcon)`
            width: 20px;
            height: 20px;
            color: ${(props) => props.theme[`icon--100`]};

            &.error {
                right: 36px;
            }
        `
    }
}

/**********************************************************************************************************
 *   PASSWORD FIELD
 **********************************************************************************************************/
export const SensitiveField = ({ embedded, required, label, field, toggle, disabled, form: { touched, errors, isSubmitting }, ...props }) => {
    /*   REF
     *****************************************************/
    const inputRef = useRef()

    /*   STATE
     *****************************************************/
    const [hasFocus, setFocus] = useState(false)
    const [sensitiveView, setSensitiveView] = useState(false)

    /*   EFFECT
     *****************************************************/
    useEffect(() => {
        if (document.hasFocus() && inputRef.current.contains(document.activeElement)) {
            setFocus(true)
        }
    }, [])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Input.Wrapper className={props.className} embedded={embedded}>
            {RenderInputLabel(embedded, required, label, field)}
            <Input.Column focus={hasFocus} error={touched[field.name] && errors[field.name]}>
                <Sensitive.Wrapper>
                    <Sensitive.Icon.Base
                        error={touched[field.name] && errors[field.name]}
                        onClick={() => setSensitiveView((currentSensitiveView) => !currentSensitiveView)}
                    >
                        {sensitiveView ? <Sensitive.Icon.On /> : <Sensitive.Icon.Off />}
                    </Sensitive.Icon.Base>
                    <Sensitive.Field
                        {...field}
                        {...props}
                        type={sensitiveView ? 'text' : 'password'}
                        ref={inputRef}
                        disabled={isSubmitting || disabled}
                        onFocus={() => setFocus(true)}
                        onBlur={(e) => {
                            field.onBlur && field.onBlur(e)
                            setFocus(false)
                        }}
                    />
                </Sensitive.Wrapper>
                {RenderErrorIcon(field.name, touched, errors)}
            </Input.Column>
        </Input.Wrapper>
    )
}
