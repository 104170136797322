/* eslint-disable indent */
/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Scroll } from '../scroll'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled, { css } from 'styled-components'
import * as Theme from '../../theme/theme'

export const Tabs = {
    Content: styled.div`
        width: 100%;
    `,
    Nav: styled(Scroll)`
        display: flex;
        flex-flow: row nowrap;
        gap: 10px;
        width: fit-content;
        padding: 3px;
        margin-bottom: 20px;
        border-radius: 32px;
    `,
    Dropdown: styled.div`
        margin-bottom: 20px;
    `,
    Scroll: styled(Scroll)``,
    Option: styled.button`
        display: flex;
        width: fit-content;
        font-size: 1.2rem;
        padding: 10px 15px;
        line-height: 1.6rem;
        border-radius: 24px;
        color: ${(props) => props.theme[`text--100`]};
        background-color: ${(props) => props.theme[`background--200`]};
        ${Theme.Functions.normalizeText()};
        ${Theme.Functions.setFont('Inter', 500)};
        ${Theme.Functions.animation(`all`)};

        span:last-of-type {
            white-space: nowrap;
        }

        svg {
            margin: 0 8px 0 -4px;
            width: 16px;
            height: 16px;
        }

        div {
            display: inline-flex;
            flex-flow: row nowrap;
            align-items: center;
            margin: 0 auto;
        }

        &:hover {
            color: ${(props) => props.theme[`info--100`]};
        }

        ${(props) =>
            props.active &&
            css`
                color: ${(props) => props.theme[`white--100`]};
                background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`info--100`], 1)};

                &:hover {
                    color: ${(props) => props.theme[`white--100`]};
                }
            `}
    `
}
