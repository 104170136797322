/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { Field, Formik, FormikProps } from 'formik'
import { useMemo, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { Anchor, Button, Lightbox, Form as NXUIForm, Tooltip } from 'nxui/src'
// import { Anchor, Button, Lightbox, Form as NXUIForm, Tooltip } from 'nxui/src'
import { WrapWithComponent } from '../../../components/wrapWithComponent'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { domainAPI, useUpdateNameserversMutation } from 'api/domain'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { DNSMode, Nameservers } from 'containers/domains/domains.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function EditNameserversForm() {
    /***** STATE *****/
    const [editingNameserver, setEditingNameserver] = useState<number | null>(null)
    const [isDeleteLightboxOpen, setIsDeleteLightboxOpen] = useState(false)

    /***** HOOKS *****/
    const { id } = useParams()
    const domainId = Number(id)
    const editNameserversFormRef = useRef<FormikProps<{ nameserver: string }>>(null)

    /***** QUERIES *****/
    const [updateNameservers, { isLoading }] = useUpdateNameserversMutation()
    const { nameservers, isError, isFetching, isInitialSetup } = domainAPI.endpoints.dnsRecords.useQueryState(domainId, {
        selectFromResult: ({ data, ...rest }) => ({
            ...rest,
            isInitialSetup: !Object.keys(data?.dns_mode ?? {}).includes('custom_nameservers'),
            nameservers: data?.nameservers ?? []
        })
    })

    /***** RENDER HELPERS *****/
    const data = useMemo(() => {
        if (isError) return []

        return nameservers.map((nameserver, index) => {
            const onDelete = () => {
                if (nameservers.length <= 2) {
                    const error = {
                        detail: 'You tried to delete the remaining two nameservers. If this is intentional set it to DNS Hosting instead.',
                        type: 'ERROR'
                    }
                    // @ts-ignore
                    toast.custom(error, {
                        duration: 5000
                    })
                } else {
                    setIsDeleteLightboxOpen(true)
                }
            }

            const onCancel = () => setEditingNameserver(null)

            if (editingNameserver === index) {
                return {
                    nameservers: (
                        <Nameservers.Update>
                            <Nameservers.Row>
                                <span>{nameserver}</span>
                                <Nameservers.EditAction>
                                    <Anchor onClick={onCancel} color='primary'>
                                        <span>
                                            Edit
                                            <ChevronDownIcon />
                                        </span>
                                    </Anchor>
                                </Nameservers.EditAction>
                            </Nameservers.Row>
                            <Formik
                                enableReinitialize
                                initialValues={{ nameserver }}
                                innerRef={editNameserversFormRef}
                                onSubmit={async ({ nameserver }, { setSubmitting, resetForm }) => {
                                    await updateNameservers({
                                        id: domainId,
                                        nameservers: nameservers.map((ns, i) => (i === index ? nameserver : ns)),
                                        dns_config: 'custom_nameservers'
                                    }).unwrap()

                                    setEditingNameserver(null)
                                    setSubmitting(false)
                                    resetForm()
                                }}
                            >
                                {({ handleSubmit }) => (
                                    <Nameservers.Editing onSubmit={handleSubmit}>
                                        <span>Nameserver</span>
                                        <Field embedded name='nameserver' type='text' component={NXUIForm.InputField} className={'field'} />
                                        <Nameservers.Actions>
                                            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                            <WrapWithComponent<any, React.FC<{ content: string }>>
                                                component={Tooltip}
                                                wrap={nameservers.length <= 2}
                                                content='You must have at least two nameservers'
                                            >
                                                <Button
                                                    type='button'
                                                    color='error'
                                                    disabled={nameservers.length <= 2 || isLoading}
                                                    onClick={onDelete}
                                                >
                                                    Delete Nameserver
                                                </Button>
                                            </WrapWithComponent>
                                            <div>
                                                <Button type='button' color='secondary' onClick={onCancel} disabled={isLoading}>
                                                    Cancel
                                                </Button>
                                                <Button type='submit' color='primary' disabled={isLoading}>
                                                    Save
                                                </Button>
                                            </div>
                                        </Nameservers.Actions>
                                    </Nameservers.Editing>
                                )}
                            </Formik>
                        </Nameservers.Update>
                    )
                }
            }

            return {
                nameservers: (
                    <Nameservers.Row>
                        <span>{nameserver}</span>
                        <Nameservers.EditAction>
                            <Anchor onClick={() => setEditingNameserver(index)} color='primary'>
                                <span>
                                    Edit
                                    <ChevronRightIcon />
                                </span>
                            </Anchor>
                        </Nameservers.EditAction>
                    </Nameservers.Row>
                )
            }
        })
    }, [nameservers, isLoading, editingNameserver])

    /***** RENDER *****/
    return (
        <>
            <Nameservers.View
                table={{
                    conditions: {
                        loading: isLoading || isFetching
                    },
                    render: {
                        columns: () => [
                            {
                                Header: isInitialSetup ? 'Existing Nameservers' : 'Custom Nameservers',
                                accessor: 'nameservers'
                            }
                        ],
                        data: () => data
                    }
                }}
            />
            <Lightbox
                title='Current Nameserver will be deleted'
                type='confirm'
                icon='warning'
                description={
                    <DNSMode.Confirmation>
                        <span>You are about to delete the following Nameserver, note that this action is irreversible.</span>
                        <DNSMode.Actions>
                            <Button type='button' color='secondary' onClick={() => setIsDeleteLightboxOpen(false)}>
                                No, keep this Nameserver
                            </Button>
                            <Button
                                type='button'
                                color='error'
                                onClick={() => {
                                    setIsDeleteLightboxOpen(false)
                                    setEditingNameserver(null)

                                    updateNameservers({
                                        id: domainId,
                                        nameservers: nameservers.filter((_, i) => i !== editingNameserver),
                                        dns_config: 'custom_nameservers'
                                    })
                                }}
                            >
                                Delete
                            </Button>
                        </DNSMode.Actions>
                    </DNSMode.Confirmation>
                }
                conditions={{
                    state: isDeleteLightboxOpen,
                    action: () => setIsDeleteLightboxOpen(false)
                }}
            />
        </>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
