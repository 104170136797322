/* eslint-disable indent */
/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useRef } from 'react'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled, { css } from 'styled-components'
import * as Theme from '../../../theme/theme'

const Dropdown = {
    List: styled.div`
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        padding: 5px;
        border: 1px solid ${(props) => props.theme[`border--100`]};
        background-color: ${(props) => props.theme[`background--000`]};
        border-radius: 6px;
        box-shadow: 0px 0.7px 1.3px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--200`], 0.028)},
            0px 2.2px 4.5px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--200`], 0.042)},
            0px 10px 20px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--200`], 0.07)};
    `,
    Option: styled.button`
        ${Theme.Functions.reset()};
        cursor: pointer;
        height: 40px;
        padding: 0px 15px;
        margin: 4px 0;
        color: ${(props) => props.theme[`text--200`]};
        border-radius: 6px;
        background-color: ${(props) => props.theme[`--dropdown-option__background`]};
        ${Theme.Functions.body()};
        ${Theme.Functions.animation('all', 200)};

        &:first-of-type,
        &:last-of-type {
            margin: 0;
        }

        &:hover,
        &:focus {
            color: ${(props) => props.theme[`primary--100`]};
            background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`primary--100`], 0.1)};
        }

        ${(props) =>
            props.active &&
            css`
                color: ${(props) => props.theme[`white--100`]};
                background-color: ${(props) => props.theme[`primary--100`]};

                &:hover {
                    color: ${(props) => props.theme[`white--100`]};
                    background-color: ${(props) => props.theme[`primary--100`]};
                }
            `}
    `
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DropdownList = (props) => {
    const listRef = useRef(null)

    function renderDropdownList() {
        return props.options.map((option, index) => {
            return (
                <Dropdown.Option
                    tabIndex={0}
                    active={option.active}
                    key={index}
                    onClick={(e) => {
                        if (option.onClick) option.onClick(e, option.value)
                        if (props.close) props.close()
                    }}
                >
                    {option.label}
                </Dropdown.Option>
            )
        })
    }

    /*   RENDER
     ****************************************************/
    return (
        <Dropdown.List ref={listRef} {...props}>
            {renderDropdownList()}
        </Dropdown.List>
    )
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
DropdownList.defaultProps = {
    className: undefined,
    options: [],
    close: undefined,
    tabIndex: 0
}
