/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useEffect, useState } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Lightbox } from 'nxui/src'

/**********************************************************************************************************
 *   COMPONENT IMPORTS
 **********************************************************************************************************/
import RenewDomainForm from 'containers/domains/forms/renewDomain.form'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { domainAPI, useRenewDomainMutation } from 'api/domain'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
interface Props {
    id: number
    status: boolean
    close: () => void
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function RenewDomainLightbox({ id, status, close }: Props) {
    const { billingCycleId, isSuccess } = domainAPI.endpoints.domainRenewal.useQueryState(id, {
        selectFromResult: ({ data, ...rest }) => ({
            ...rest,
            billingCycleId: data && data.pricing.length >= 1 ? data.pricing[0].billing_cycle.id : ''
        })
    })
    const [renewDomain, { isLoading }] = useRenewDomainMutation({ fixedCacheKey: 'renew-domain' })
    const [template, setTemplate] = useState<'renew' | 'confirm'>('renew')
    const [payload, setPayload] = useState<{ promo_code?: string; billing_cycle_id?: string }>({
        promo_code: ''
    })

    useEffect(() => {
        if (!status) {
            setPayload((previousPayload) => ({ ...previousPayload, promo_code: '' }))
            setTimeout(() => {
                setTemplate('renew')
            }, 500)
        }
    }, [status])

    useEffect(() => {
        if (isSuccess) {
            setPayload({
                promo_code: '',
                billing_cycle_id: String(billingCycleId)
            })
        }
    }, [isSuccess])

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    if (template === 'confirm') {
        return (
            <Lightbox
                title='Confirm Renew Domain'
                type='confirm'
                icon='info'
                description='When you confirm this submission, an invoice for your domain will be generated. This invoice must be paid in full in order for the domain renewal to take affect.'
                conditions={{
                    state: status,
                    action: () => close()
                }}
                actions={[
                    {
                        label: 'Back',
                        color: 'secondary',
                        type: 'button',
                        func: () => setTemplate('renew')
                    },
                    {
                        label: 'Generate Renewal Invoice',
                        color: 'primary',
                        disabled: isLoading,
                        loading: isLoading && 'Generating invoice',
                        type: 'button',
                        func: () => {
                            if (payload.promo_code !== '') {
                                renewDomain({
                                    id,
                                    billing_cycle_id: Number(payload.billing_cycle_id),
                                    promo_code: payload.promo_code
                                })
                            } else {
                                renewDomain({ id, billing_cycle_id: Number(payload.billing_cycle_id) })
                            }
                        }
                    }
                ]}
            />
        )
    }

    return (
        <Lightbox
            title='Renew Domain Name'
            description='Ensure your domain name remains registered by renewing it before it expires.'
            content={<RenewDomainForm id={id} payload={payload} setPayload={setPayload} setTemplate={setTemplate} />}
            conditions={{ state: status, action: () => close() }}
            actions={[
                {
                    label: 'Back',
                    color: 'secondary',
                    type: 'button',
                    func: () => close()
                },
                {
                    label: 'Renew',
                    color: 'primary',
                    type: 'submit',
                    form: 'renewDomainForm'
                }
            ]}
        />
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
