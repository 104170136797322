/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { useRef, useState } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Anchor } from '../../anchor'
import { Button } from '../../button'
import { Tooltip } from '../../tooltip'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import { Product, Services } from './product.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ServiceProduct = (props) => {
    const [dockOpen, setDockOpen] = useState(false)

    /*   REF
     *****************************************************/
    const dockRef = useRef()

    function renderAction() {
        if (!props?.availability) {
            return (
                <Button type={'button'} size={'large'} disabled={true}>
                    Product not available
                </Button>
            )
        }

        if (props?.dock) {
            return (
                <Button
                    type='button'
                    size={'large'}
                    color={dockOpen ? 'secondary' : 'primary'}
                    disabled={props.disabled}
                    onClick={() => setDockOpen(!dockOpen)}
                >
                    Get Started
                </Button>
            )
        }

        if (props?.isAdded) {
            return (
                <Button type={'button'} size={'large'} color={'error'} disabled={props.disabled} onClick={props.select}>
                    Remove
                </Button>
            )
        }

        return (
            <Button type='button' size={'large'} color={'primary'} disabled={props.disabled} onClick={props.select}>
                Get Started
            </Button>
        )
    }

    /*   RENDER
     ****************************************************/
    return (
        <Services.Product className={props.className} important={props.important}>
            <Product.Base>
                <Product.Summary>
                    <Product.Title>
                        {props.title} {props.important && <Product.Badge color={'primary'}>Most Popular</Product.Badge>}
                    </Product.Title>
                    {props.price && <Product.Price>{props.price}</Product.Price>}
                    {props?.description && <Product.Description>{props.description}</Product.Description>}
                    <Product.Action>{renderAction()}</Product.Action>
                </Product.Summary>
                {props.features && (
                    <Product.Features.List>
                        {props.features.map((feature, index) => {
                            return (
                                <Product.Features.Item key={index}>
                                    <Product.Features.Icon />
                                    <Product.Features.Description>
                                        <span>{feature.value}</span> {feature.description}
                                    </Product.Features.Description>
                                </Product.Features.Item>
                            )
                        })}
                    </Product.Features.List>
                )}
            </Product.Base>
            {props.dock && (
                <Product.Dock.Base ref={dockRef} open={dockOpen} height={dockRef?.current?.scrollHeight}>
                    <Product.Dock.Wrapper>
                        {props.dock.title && <Product.Dock.Title>{props.dock.title}</Product.Dock.Title>}
                        <Product.Dock.Close onClick={() => setDockOpen(false)}>
                            <Tooltip content={'Close'}>
                                <XMarkIcon />
                            </Tooltip>
                        </Product.Dock.Close>
                    </Product.Dock.Wrapper>
                    {props.dock.render}
                    {props.dock.actions && (
                        <Product.Dock.Actions>
                            <Anchor color={'error'} onClick={() => setDockOpen(false)}>
                                Close
                            </Anchor>
                            {props.dock.actions.map((action) => (
                                <Button {...action}>{action.label}</Button>
                            ))}
                        </Product.Dock.Actions>
                    )}
                </Product.Dock.Base>
            )}
        </Services.Product>
    )
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
ServiceProduct.defaultProps = {
    className: undefined,
    id: undefined,
    title: undefined,
    description: undefined,
    price: undefined,
    important: false,
    features: undefined,
    availability: true
}
