/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useState } from 'react'
import { useLayer } from 'react-laag'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { DropdownContent } from './components/content.component'
import { DropdownList } from './components/list.component'
import { DropdownTrigger } from './components/trigger.component'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const DropdownPrefab = (props) => {
    /*   STATE
     ****************************************************/
    const [isOpen, setOpen] = useState(false)

    /*   FUNCTIONS
     ****************************************************/
    function triggerSetOpen(e) {
        setOpen(e)
    }

    function close() {
        setOpen(false)
    }

    /*   CUSTOM
     ****************************************************/
    const { triggerProps, layerProps, renderLayer } = useLayer({
        isOpen: isOpen,
        onOutsideClick: close,
        onDisappear: close,
        overflowContainer: false,
        auto: true,
        placement: props.placement,
        triggerOffset: 5
    })

    /*   RENDER
     ****************************************************/
    return (
        <>
            <DropdownTrigger className={props.className} trigger={triggerSetOpen} triggerProps={triggerProps} open={isOpen}>
                {props.children}
            </DropdownTrigger>
            {renderLayer(
                <DropdownContent open={isOpen} layerProps={layerProps}>
                    {props.options ? <DropdownList options={props.options} close={close} /> : props.content}
                </DropdownContent>
            )}
        </>
    )
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
DropdownPrefab.defaultProps = {
    className: undefined,
    placement: 'bottom-start',
    content: undefined,
    options: undefined,
    tabIndex: 0
}

export { DropdownPrefab as Dropdown }
