import { useParams } from 'react-router-dom'
import type { ParseableShopRoutes, ShopRouteParams, WebsiteRoutes } from 'router/helpers'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Routes = ParseableShopRoutes | WebsiteRoutes

type UseAppParamsResult<TRoute extends Routes | undefined = undefined> = 
    TRoute extends undefined 
        ? Partial<Record<ShopRouteParams, string>> 
        : Record<ShopRouteParams<TRoute>, string>

type UseAppParams = <TRoute extends Routes | undefined = undefined> (from?: TRoute) => UseAppParamsResult<TRoute>

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Typed abstraction of useParams. Does not currently have all route types registered, but can be extended as needed/used.
 * 
 * @param from - optional path that you are calling the hook from, this will be used to infer what params are definitely
 * available to you based on the path you are calling the hook from.
*/
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useAppParams: UseAppParams = (_) => {
    return useParams() as ExpectedAny;
}
