import { XMarkIcon } from '@heroicons/react/24/solid'
import { appAPI } from 'api/app'
import { useGeneratePaymentMethodTokenMutation } from 'api/billing'
import { Invoice } from 'models/billing'
import { Theme } from 'nxui/src'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import toast from 'react-hot-toast'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateAppTokenTypeOrderPaymentStatus } from 'store/slices/appSlice'
import styled from 'styled-components'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const IframeContainer = {
    Page: styled.div`
        position: absolute;
        inset: 0;
        z-index: 100000;
    `,
    Background: styled.div`
        background-color: black;
        opacity: 0.6;
        position: absolute;
        inset: 0;
    `,
    Modal: styled.div`
        border-radius: 10px;
        width: 750px;
        height: 720px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            width: 340px;
            height: 500px;
        }

        iframe {
            position: relative;
            z-index: 1;
        }
    `,
    ModalCloseButton: styled.button`
        color: black;
        position: absolute;
        top: 20px;
        right: 20px;
    `
}

interface IframeProps {
    srcDoc: string
    onClose: () => void
    invoiceId: Invoice['id'] | null
}

function Iframe({ srcDoc, onClose, invoiceId }: IframeProps) {
    const dispatch = useAppDispatch()

    useEffect(() => {
        function listenForPaymentComplete(event: MessageEvent<ExpectedAny>) {
            if (!event.isTrusted || event.data?.type !== 'payment') {
                return
            }

            const { message, status } = event.data?.data || {}
            const isSuccess = status === 'payment_success'

            toast.custom(
                {
                    // @ts-ignore
                    detail: message,
                    type: isSuccess ? 'SUCCESS' : 'ERROR'
                },
                {
                    duration: 5000
                }
            )

            if (isSuccess) {
                dispatch(
                    appAPI.util.invalidateTags([
                        'Invoices',
                        { type: 'Invoice', id: invoiceId || undefined },
                        'Services',
                        'Service',
                        'Payment-Methods-Data'
                    ])
                )
            }

            dispatch(updateAppTokenTypeOrderPaymentStatus(isSuccess ? 'success' : 'error'))
            onClose()
        }

        window.addEventListener('message', listenForPaymentComplete)

        return () => {
            window.removeEventListener('message', listenForPaymentComplete)
        }
    }, [])

    const windowWidth = window.innerWidth

    return (
        <IframeContainer.Page>
            <IframeContainer.Background />
            <IframeContainer.Modal>
                <IframeContainer.ModalCloseButton onClick={onClose}>
                    <XMarkIcon height={20} width={20} />
                </IframeContainer.ModalCloseButton>
                <iframe
                    srcDoc={srcDoc}
                    id='tokenFormIframe'
                    title='Payment Form'
                    width={windowWidth > 500 ? '650' : '300'}
                    height={windowWidth > 500 ? '620' : '400'}
                />
            </IframeContainer.Modal>
        </IframeContainer.Page>
    )
}

export function OrderCompleteTokenTypePayment() {
    const [isIframeShowing, setIsIframeShowing] = useState(false)

    const { cart, appTokenTypeOrderPaymentState } = useAppSelector((state) => state.app)

    const [generatePaymentMethodToken, { data: generatePaymentMethodTokenData }] = useGeneratePaymentMethodTokenMutation()

    function iframeOnClose() {
        setIsIframeShowing(false)
    }

    useEffect(() => {
        if (appTokenTypeOrderPaymentState.status !== 'ready') {
            return
        }

        generatePaymentMethodToken({
            paymentMethodId: appTokenTypeOrderPaymentState.paymentMethodId ?? -1,
            payload: { type: 'checkout', invoice_id: appTokenTypeOrderPaymentState.invoiceId ?? -1 }
        })
            .unwrap()
            .then(() => {
                setIsIframeShowing(true)
                return
            })
            .catch(() => {
                // Catch
            })
    }, [appTokenTypeOrderPaymentState.status])

    if (!cart || appTokenTypeOrderPaymentState.paymentMethodId === null) return <></>

    return isIframeShowing
        ? createPortal(
              <Iframe
                  onClose={iframeOnClose}
                  srcDoc={generatePaymentMethodTokenData?.data.render || ''}
                  invoiceId={appTokenTypeOrderPaymentState.invoiceId}
              />,
              document.body
          )
        : null
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
