/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Formik, FormikProps } from 'formik'
import { useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { Button, Card, Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { domainAPI, useUpdateNameserversMutation } from 'api/domain'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setCustomNameservers, setDNSMode } from 'store/slices/domainSlice'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Nameservers } from 'containers/domains/domains.styles'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { snakeCase } from 'helpers/utils'
import { DNSModeType } from 'models/enums'
import { useAppDispatch } from 'store/hooks'

/**********************************************************************************************************
 * TYPES
 **********************************************************************************************************/
type AddNameserverFormProps = {
    showAddNameserver: boolean
    onClose: () => void
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function AddNameserverForm({ showAddNameserver, onClose }: AddNameserverFormProps) {
    const [updateNameservers] = useUpdateNameserversMutation({ fixedCacheKey: 'update-nameservers' })
    const [numberNameserverFields, setNumberNameserverFields] = useState(2)
    const { id } = useParams()
    const dispatch = useAppDispatch()
    const addNameserverFormRef = useRef<FormikProps<{ custom_nameservers: Array<{ nameserver: string }> }>>(null)
    const domainId = Number(id)
    const { initialValues, isInitialSetup, nameservers, isError } = domainAPI.endpoints.dnsRecords.useQueryState(domainId, {
        selectFromResult: ({ data, ...rest }) => ({
            ...rest,
            isInitialSetup: !Object.keys(data?.dns_mode ?? {}).includes('custom_nameservers'),
            dns_mode: data?.dns_mode,
            nameservers: data?.nameservers ?? [],
            initialValues:
                data && data.nameservers.length >= 1
                    ? { custom_nameservers: [{ nameserver: '' }] }
                    : { custom_nameservers: [{ nameserver: '' }, { nameserver: '' }] }
        })
    })

    const fields = useMemo(() => {
        if (isError) return []

        // The domain has not yet been changed to custom nameservers
        if (isInitialSetup) {
            return [
                { nameservers: <Field label='Nameservers' name='custom_nameservers.0.nameserver' type='text' component={NXUIForm.InputField} /> },
                ...Array(numberNameserverFields - 1)
                    .fill({})
                    .map((_, index) => ({
                        nameservers: <Field name={`custom_nameservers.${index + 1}.nameserver`} type='text' component={NXUIForm.InputField} />
                    }))
            ]
        }

        // The domain is already set to custom nameservers - Show a single new nameserver to add
        setNumberNameserverFields(nameservers.length)
        return [
            {
                nameservers: <Field name='custom_nameservers.0.nameserver' type='text' component={NXUIForm.InputField} />
            }
        ]
    }, [nameservers, numberNameserverFields])

    if (!showAddNameserver && !isInitialSetup) return null

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={
                !isInitialSetup
                    ? Yup.object().shape({
                          custom_nameservers: Yup.array().of(
                              Yup.object().shape({
                                  nameserver: Yup.string().required('Required')
                              })
                          )
                      })
                    : Yup.object().shape({
                          custom_nameservers: Yup.array()
                              .of(
                                  Yup.object().shape({
                                      nameserver: Yup.string().required('Required')
                                  })
                              )
                              .min(2, 'At least two nameservers are required')
                      })
            }
            innerRef={addNameserverFormRef}
            enableReinitialize={true}
            onSubmit={({ custom_nameservers }, { setSubmitting }) => {
                const allNameservers = [...nameservers, ...custom_nameservers.map(({ nameserver }) => nameserver)]

                dispatch(setDNSMode(DNSModeType.CustomNameservers))
                dispatch(setCustomNameservers(allNameservers))

                if (!isInitialSetup) {
                    updateNameservers({
                        id: domainId,
                        dns_config: snakeCase(DNSModeType.CustomNameservers),
                        nameservers: allNameservers
                    })
                    dispatch(setCustomNameservers([]))
                }

                setSubmitting(false)
            }}
        >
            {({ handleSubmit }) => (
                <Nameservers.Add onSubmit={handleSubmit}>
                    <Nameservers.Label>{!isInitialSetup && 'Nameserver'}</Nameservers.Label>
                    <Card
                        table={{
                            render: {
                                columns: () => [
                                    {
                                        Header: DNSModeType.CustomNameservers,
                                        accessor: 'nameservers'
                                    }
                                ],
                                data: () => fields
                            },
                            conditions: {
                                header: false,
                                search: false,
                                pagination: false
                            }
                        }}
                    />
                    <Nameservers.AddMore initial={isInitialSetup}>
                        <Button
                            type='button'
                            color='secondary'
                            onClick={isInitialSetup ? () => setNumberNameserverFields((num) => num + 1) : onClose}
                        >
                            {isInitialSetup ? 'Add More' : 'Cancel'}
                        </Button>
                        <Button color='primary' type='submit'>
                            {isInitialSetup ? 'Save' : 'Add'}
                        </Button>
                    </Nameservers.AddMore>
                </Nameservers.Add>
            )}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
