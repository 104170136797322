/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SVGProps } from 'react'
import { BasicLoaderStyles as S } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TBasicLoaderProps = React.FC<{
    message?: React.ReactNode
    position?: 'left' | 'right'
    className?: string
    intrinsic?: Omit<SVGProps<SVGSVGElement>, 'ref'>
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Basic Loader Component
 *
 * The Basic Loader component provides a simple loading animation with text , originally implemented from the
 * UIKit.
 */
const _BasicLoader: TBasicLoaderProps = ({ message, position = 'left', className, ...rest }) => {
    /***** RENDER *****/
    return (
        <S.Base className={className}>
            {position === 'left' && !!message && <S.Message>{message}</S.Message>}
            <S.SVG hasMessage={!!message} position={position} {...rest} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
                <S.Circle cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></S.Circle>
                <S.Path
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                ></S.Path>
            </S.SVG>
            {position === 'right' && !!message && <S.Message>{message}</S.Message>}
        </S.Base>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/***** EXPORTS *****/
export const Loader = Object.assign(_BasicLoader, {
    Style: S
})
