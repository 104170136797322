import { useState } from 'react'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'

/**********************************************************************************************************
 *   SHARED COMPONENTS
 **********************************************************************************************************/
import { toast } from 'react-hot-toast'
import { Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   HOOKS
 **********************************************************************************************************/
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useRecoverPasswordMutation } from 'api/authentication'

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
import { Auth } from 'containers/authentication/authentication.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export default function AuthForgotForm() {
    const [recipientEmail, setRecipientEmail] = useState<string>('text@example.com')
    const [forgotTemplate, setForgotTemplate] = useState<'form' | 'success'>('form')
    const { email_support } = useAppSelector((state) => state.app.appSettings.provider)
    const [recoverPassword, { isLoading }] = useRecoverPasswordMutation()

    const resendPasswordResetEmail = async () => {
        try {
            await recoverPassword({
                email: recipientEmail
            }).unwrap()
            toast.success({
                type: 'SUCCESS',
                //@ts-ignore
                detail: `Thank you! If there's an account associated with ${recipientEmail} please check your inbox.`
            })
            setForgotTemplate('success')
        } catch (error) {
            return null
        }
    }

    const PasswordRecoverForm = () => (
        <Formik
            initialValues={{ email: '' }}
            validationSchema={Yup.object({
                email: Yup.string().email('Invalid email address').required('Required')
            })}
            onSubmit={async ({ email }, { setSubmitting }) => {
                try {
                    setRecipientEmail(email)
                    await recoverPassword({ email }).unwrap()
                    setForgotTemplate('success')
                } catch (error) {
                    setSubmitting(false)
                }
            }}
        >
            {({ isSubmitting }) => (
                <Auth.Wrapper>
                    <Auth.Title>Reset Account Password</Auth.Title>
                    <Auth.Description>Fill out the form below to complete your password reset request.</Auth.Description>
                    <Auth.Break />
                    <Auth.Form>
                        <Field label='Email address' name='email' type='email' disabled={isSubmitting} component={NXUIForm.InputField} />
                        <Auth.Submit color='primary' type='submit' disabled={isSubmitting} loading={isSubmitting ? 'Submitting' : false}>
                            Request Password Reset
                        </Auth.Submit>
                    </Auth.Form>
                    <Auth.Break />
                    <Auth.Footer>
                        <Auth.Link to='/login'>Back to Login</Auth.Link>
                    </Auth.Footer>
                </Auth.Wrapper>
            )}
        </Formik>
    )

    const PasswordRecoverSuccess = () => (
        <Auth.Wrapper>
            <Auth.Title>Password Reset Link Sent</Auth.Title>
            <Auth.Description>
                If there's an account associated with <strong>{recipientEmail}</strong>, we've sent a password reset link to that email.
            </Auth.Description>
            <Auth.Description>
                If you didn't receive an email, check your spam folder or whitelist <strong>{email_support}</strong>.
            </Auth.Description>
            <Auth.Break />
            <Auth.Row>
                <Auth.Submit color='primary' disabled={isLoading} loading={isLoading} onClick={resendPasswordResetEmail}>
                    Resend Password Reset Email
                </Auth.Submit>
            </Auth.Row>
            <Auth.Break />
            <Auth.Footer>
                <Auth.Link to='/login'>Back to Login</Auth.Link>
                <Auth.Button onClick={() => setForgotTemplate('form')}>Re-enter email address</Auth.Button>
            </Auth.Footer>
        </Auth.Wrapper>
    )

    return forgotTemplate === 'success' ? PasswordRecoverSuccess() : PasswordRecoverForm()
}
