/***** BASE IMPORTS *****/
import { Store, useStore } from '@tanstack/react-store'

/***** TYPE DEFINITIONS *****/
type TViewport = keyof typeof mediaResolutions
type TUseAppViewport = <Match extends TViewport[] | undefined = undefined>(match?: Match) => Match extends undefined ? TViewport : boolean

/***** CONSTANTS & CONFIGURATION *****/
/**
 * Standardized viewport dimensions based off Theme in NXUI
 */
const mediaResolutions = {
    xs: '(max-width: 380px)',
    sm: '(min-width: 381px) and (max-width: 640px)',
    md: '(min-width: 641px) and (max-width: 768px)',
    lg: '(min-width: 769px) and (max-width: 1024px)',
    xl: '(min-width: 1025px) and (max-width: 1280px)',
    '2xl': '(min-width: 1281px) and (max-width: 1536px)',
    '3xl': '(min-width: 1537px) and (max-width: 2160px)',
    '4xl': '(min-width: 2161px)'
} as const

const mediaStore = new Store({
    viewport: 'xs' as TViewport
})

/***** LISTENERS & FUNCTIONALITY *****/
const handleMediaChange = (viewport: TViewport) => (e: MediaQueryListEvent) => {
    if (e.matches) {
        mediaStore.setState(() => ({ viewport }))
    }
}

// Set initial viewport
Object.entries(mediaResolutions).forEach(([viewport, resolution]) => {
    if (window.matchMedia(resolution).matches) {
        mediaStore.setState(() => ({ viewport: viewport as TViewport }))
    }
})

// Listen for media changes
Object.entries(mediaResolutions).forEach(([viewport, resolution]) => {
    window.matchMedia(resolution).addEventListener('change', handleMediaChange(viewport as TViewport))
})

/***** EXPORTS & HOOKS *****/
/**
 * Provides the current viewport of the application or matches any "match" prop passed
 * to the hook and returns a boolean
 */
export const useAppViewport: TUseAppViewport = (match?: Array<TViewport>) => {
    const { viewport } = useStore(mediaStore)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!match) return viewport as any

    return match.includes(viewport)
}
