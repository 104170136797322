import { useCheckoutCartMutation } from 'api/shop'
import { renderGenericPaymentMethodDescription } from 'components/invoice/invoice'
import { Payment } from 'components/invoice/invoice.styles'
import { useApplyAccountCreditToInvoice } from 'containers/billing/utils'
import { ShopRouteBuilder } from 'containers/shop/helpers/routeMatchers'
import { FormikProvider, useFormik } from 'formik'
import type { PaymentMethodWithFormName } from 'models/billing'
import type React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { readyAppTokenTypeOrderPayment, updateAppTokenTypeOrderPaymentStatus } from 'store/slices/appSlice'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TokenTypeForm = React.FC<{
    selectedPaymentMethod: PaymentMethodWithFormName
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const TokenTypeForm: TokenTypeForm = ({ selectedPaymentMethod }) => {
    /***** SELECTORS *****/
    const cart = useAppSelector((state) => state.app.cart)
    const checkoutAccountCredit = useAppSelector((state) => state.shop.checkoutAccountCredit)

    /***** HOOKS *****/
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    /***** QUERIES *****/
    const [checkoutCart] = useCheckoutCartMutation({ fixedCacheKey: 'checkout-cart' })
    const { applyAccountCreditToInvoice } = useApplyAccountCreditToInvoice()

    /***** FORM *****/
    const form = useFormik({
        initialValues: {},
        async onSubmit() {
            if (!cart || typeof selectedPaymentMethod?.id !== 'number') return

            const order = await checkoutCart({
                uuid: cart.uuid,
                payment_method_id: selectedPaymentMethod?.id
            }).unwrap()

            const { isInvoiceStillUnpaid, isSuccess } = await applyAccountCreditToInvoice(order.invoice.id, checkoutAccountCredit.amount)

            if (isSuccess) {
                // If the invoice is still unpaid, we will pop up the stipe modal as soon as the "order complete" page loads
                if (isInvoiceStillUnpaid) {
                    dispatch(readyAppTokenTypeOrderPayment({ invoiceId: order.invoice.id, paymentMethodId: selectedPaymentMethod?.id }))
                } else {
                    dispatch(updateAppTokenTypeOrderPaymentStatus('idle'))
                }
                navigate(ShopRouteBuilder.orderId(order.id))
            }
        }
    })

    /***** RENDER *****/
    return (
        <>
            {cart && typeof selectedPaymentMethod?.id === 'number' && (
                <FormikProvider value={form}>
                    <Payment.Form id='tokenTypeForm'>
                        {renderGenericPaymentMethodDescription(selectedPaymentMethod?.name || '', selectedPaymentMethod?.instructions || '')}
                    </Payment.Form>
                </FormikProvider>
            )}
        </>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
