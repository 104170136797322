/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import React from 'react'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import { Table } from './table.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const TableLoader = () => {
    return (
        <Table.Base role={'progressbar'}>
            <Table.Gradient />
            <Table.Block>
                <Table.Text />
            </Table.Block>
        </Table.Base>
    )
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { TableLoader as Table }
