/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Form, Formik } from 'formik'
import { Dispatch, SetStateAction, useMemo } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Form as NXUIForm } from 'nxui/src'
/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { domainAPI } from 'api/domain'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { defaultCurrency, getBillingCycle } from 'helpers/utils'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
interface Props {
    id: number
    payload: { promo_code?: string; billing_cycle_id?: string }
    setPayload: Dispatch<SetStateAction<{ promo_code?: string; billing_cycle_id?: string }>>
    setTemplate: Dispatch<SetStateAction<'renew' | 'confirm'>>
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function RenewDomainForm({ id, payload, setPayload, setTemplate }: Props) {
    const { data } = domainAPI.endpoints.domainRenewal.useQueryState(id)
    const pricing = useMemo(() => {
        if (!data) return []

        return data.pricing.map(({ price, billing_cycle: { id, name } }) => ({
            value: id,
            label: `${defaultCurrency(price)} ${getBillingCycle(name)}`
        }))
    }, [data])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Formik
            enableReinitialize={true}
            initialValues={payload}
            onSubmit={({ promo_code, billing_cycle_id }, { setSubmitting }) => {
                if (promo_code === '') {
                    setPayload({ billing_cycle_id })
                } else {
                    setPayload({ promo_code, billing_cycle_id })
                }
                setTemplate('confirm')
                setSubmitting(false)
            }}
        >
            {({ isSubmitting }) => (
                <Form id={'renewDomainForm'}>
                    <Field
                        label={'Renewal Period'}
                        name={'billing_cycle_id'}
                        type={'select'}
                        disabled={isSubmitting}
                        list={pricing}
                        component={NXUIForm.SelectField}
                    />
                    <Field label={'Promo Code'} name={'promo_code'} disabled={isSubmitting} component={NXUIForm.InputField} />
                </Form>
            )}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
