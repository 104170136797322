/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import type { TypedAddListener, TypedStartListening } from '@reduxjs/toolkit'
import { addListener, createListenerMiddleware } from '@reduxjs/toolkit'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { domainAPI } from 'api/domain'

/**********************************************************************************************************
 *   HELPER IMPORTS
 **********************************************************************************************************/
import { AppDispatch, RootState } from 'store/store'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setAppActiveInvoice } from 'store/slices/appSlice'

/**********************************************************************************************************
 *   BASE EXPORTS
 **********************************************************************************************************/
export const domainListenerMiddleware = createListenerMiddleware()

export type AppStartListening = TypedStartListening<RootState, AppDispatch>

export const startAppListening = domainListenerMiddleware.startListening as AppStartListening

/**********************************************************************************************************
 *   RENEW DOMAIN
 **********************************************************************************************************/
startAppListening({
    matcher: domainAPI.endpoints.renewDomain.matchFulfilled,
    effect: async ({ payload: invoice }, listenerApi) => {
        listenerApi.dispatch(setAppActiveInvoice({ invoice, status: true }))
        listenerApi.cancelActiveListeners()
    }
})

export const addAppListener = addListener as TypedAddListener<RootState, AppDispatch>
