/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'
import { WebsiteDomainFeatures } from '../components/features'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
export const AboutUsStyles = {
    Container: styled.div`
        width: 100%;
        height: 100%;
        background-color: ${({ theme }) => theme['background--100']};
    `,
    WebsiteDomainFeatures: styled(WebsiteDomainFeatures)<{ marginTop?: number }>`
        margin-top: ${({ marginTop }) => marginTop}px;
    `
}
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/
