/* eslint-disable @typescript-eslint/no-explicit-any */

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { WrapWithComponent } from 'components/wrapWithComponent'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TRenderPropOrInvokeChildren = <T extends Record<string, any>, P extends keyof UnionToIntersection<T>>(props: {
    props: T
    prop: P
    children: (props: Exclude<T, Record<P, any>>) => React.ReactElement
    className?: string
}) => React.ReactElement

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The RenderPropOrInvokeChildren component is a utility component that allows you to accept a "props" object
 * which may be a union of possible props, or simply have optional props that "can" be rendered and provides
 * the ability to either render that prop if it exists, or invoke the children function with the props that
 * are narrowed, excluding the requested prop from the props object.
 *
 * This is particularly useful for cases where you have a component with props like
 * { children: React.ReactNode } | { someOtherProp: React.ReactNode } and you would like the children
 * to render only if it exists. In this case, the component could be called like so:
 * ```tsx
 * // props = { children: React.ReactNode } | { something: React.ReactNode }
 * <RenderPropOrInvokeChildren props={props} prop='children'>
 *   ({ something }) => <div>{something}</div>
 * </RenderPropOrInvokeChildren>
 * ```
 *
 * This will render the children prop if it exists, or invoke the children function with the narrowed props (which is just { something: React.ReactNode })
 */
export const RenderPropOrInvokeChildren: TRenderPropOrInvokeChildren = ({ props, prop, children, className }) => {
    /***** RENDER *****/
    return (
        <WrapWithComponent component={Styleable} wrap={!!className} className={className}>
            {prop in props ? (props as any)[prop] : children(props as any)}
        </WrapWithComponent>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Simple helper component that can be used to wrap something in styling.
 */
const Styleable = (props: { children: React.ReactNode; className?: string }) => <div className={props.className}>{props.children}</div>
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
