/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Anchor, Badge, Card } from 'nxui/src'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import AddonPurchaseLightbox from 'containers/services/lightbox/addonPurchase.lightbox'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useServiceQuery } from 'api/service'
import { useProductQuery } from 'api/shop'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setAppActiveInvoice } from 'store/slices/appSlice'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { getServiceStatusColour } from 'helpers/utils'
import { useAppDispatch } from 'store/hooks'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
import { IAddon, IProduct } from 'models/shop/product.d'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function Addons() {
    const dispatch = useAppDispatch()
    const { id } = useParams()
    const serviceId = Number(id)
    const [template, setTemplate] = useState<'configuration' | 'confirmation' | 'product'>('product')
    const [productAddon, setProductAddon] = useState<IAddon | undefined>(undefined)
    const { isFetching, isError, data } = useServiceQuery(serviceId)
    const { data: product, isFetching: isProductFetching } = useProductQuery(Number(data?.product.id), { skip: !data?.product.id })
    const [lightboxStatus, setLightboxStatus] = useState(false)
    const addonsData = useMemo(() => {
        if (isFetching || isError || !data || !data.addons || !product) {
            return []
        }

        const renderAction = (addon: IProduct) => {
            const pendingAddon = data.pending_addons.find((item) => item.product.id === addon.id)

            if (pendingAddon) {
                return (
                    <Anchor
                        color={'primary'}
                        onClick={() => {
                            dispatch(setAppActiveInvoice({ invoice: pendingAddon.order.invoice, status: true }))
                        }}
                    >
                        Pay
                    </Anchor>
                )
            }

            if (
                Array.isArray(data.addons) &&
                data.addons?.length >= 1 &&
                data.addons.some((serviceAddon) => serviceAddon.status === 'active' && serviceAddon.product.id === addon.id)
            ) {
                return <Badge color={getServiceStatusColour(addon.status)}>{addon.status.toUpperCase()}</Badge>
            }

            if (addon.config_option_groups && addon.config_option_groups.length >= 1) {
                return (
                    <Anchor
                        color={'primary'}
                        onClick={() => {
                            setProductAddon(addon)
                            setTemplate('configuration')
                            setLightboxStatus(true)
                        }}
                    >
                        Config
                    </Anchor>
                )
            }

            return (
                <Anchor
                    color={'primary'}
                    onClick={() => {
                        setProductAddon(addon)
                        setTemplate('configuration')
                        setLightboxStatus(true)
                    }}
                >
                    Add
                </Anchor>
            )
        }

        return product?.addons?.map((addon: IProduct) => {
            return {
                addons: addon.name,
                description: addon.description,
                action: renderAction(addon)
            }
        })
    }, [product?.addons, isFetching, isError])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <>
            <Card
                table={{
                    conditions: {
                        loading: (isFetching || isProductFetching) && 'Loading Addons...',
                        sort: false
                    },
                    render: {
                        columns: () => [
                            {
                                Header: 'Addons',
                                accessor: 'addons'
                            },
                            {
                                Header: 'Description',
                                accessor: 'description'
                            },
                            {
                                Header: '',
                                accessor: 'action',
                                align: 'right'
                            }
                        ],
                        data: () => addonsData
                    }
                }}
            />
            <AddonPurchaseLightbox
                template={template}
                setTemplate={setTemplate}
                addonParent={data}
                productAddon={productAddon}
                status={lightboxStatus}
                close={() => setLightboxStatus(false)}
            />
        </>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
