/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { XMarkIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { isFunction } from '../../helpers/functions'
import * as Theme from '../../theme/theme'

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
const BadgeWrapper = styled.div.attrs((props) => ({
    color: props.color || 'secondary'
}))`
    display: inline-flex;
    align-items: center;
    height: 20px;
    width: fit-content;
    border-radius: 4px;
    white-space: nowrap;

    ${({ color }) => {
        switch (color) {
            case 'primary':
                return css`
                    color: ${(props) => props.theme[`primary--100`]};
                    background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`primary--100`], `0.2`)};

                    > button {
                        color: ${(props) => props.theme[`primary--100`]};

                        &:hover {
                            background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`primary--100`], `0.25`)};
                        }
                    }
                `

            case 'attention':
                return css`
                    color: ${(props) => props.theme[`error--100`]};
                    background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`error--100`], `0.2`)};

                    > button {
                        color: ${(props) => props.theme[`error--200`]};

                        &:hover {
                            background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`error--100`], `0.25`)};
                        }
                    }
                `

            case 'alternative':
                return css`
                    color: ${(props) => props.theme[`warning--200`]};
                    background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`warning--100`], `0.2`)};

                    > button {
                        color: ${(props) => props.theme[`warning--200`]};

                        &:hover {
                            background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`warning--100`], `0.25`)};
                        }
                    }
                `

            case 'confirm':
                return css`
                    color: ${(props) => props.theme[`confirm--100`]};
                    background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`confirm--100`], `0.2`)};

                    > button {
                        color: ${(props) => props.theme[`confirm--200`]};

                        &:hover {
                            background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`confirm--100`], `0.25`)};
                        }
                    }
                `

            case 'info':
                return css`
                    color: ${(props) => props.theme[`info--100`]};
                    background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`info--100`], `0.2`)};

                    > button {
                        color: ${(props) => props.theme[`info--200`]};

                        &:hover {
                            background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`info--100`], `0.225`)};
                        }
                    }
                `

            case 'secondary':
            default:
                return css`
                    color: ${(props) => props.theme[`text--100`]};
                    background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`gray--200`], `0.5`)};

                    > button {
                        color: ${(props) => props.theme[`text--100`]};

                        &:hover {
                            background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`gray--200`], `0.55`)};
                        }
                    }
                `
        }
    }}
`

const BadgeTitle = styled.span`
    padding: 0 6px;
    font-size: 1rem;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    ${Theme.Functions.setFont('Inter', 700)};
`

const BadgeButton = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 100%;
    width: fit-content;
    padding: 0 6px;
    border-radius: 0 4px 4px 0;
    border-left: 1px solid ${(props) => props.theme[`white--100`]};
    ${Theme.Functions.animation(`all`)};
`

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Badge = ({ className, color, onClick, children }) => {
    return (
        <BadgeWrapper color={color} className={`${className ? ` ${className}` : ''}`}>
            <BadgeTitle>{children}</BadgeTitle>
            {isFunction(onClick) && (
                <BadgeButton onClick={onClick}>
                    <XMarkIcon width={`14px`} />
                </BadgeButton>
            )}
        </BadgeWrapper>
    )
}

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
Badge.propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary', 'alternative', 'attention', 'confirm', 'info']),
    onClick: PropTypes.func
}
