/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, ExclamationTriangleIcon, InformationCircleIcon, XCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import PropTypes from 'prop-types'
import { Fragment, useEffect, useRef, useState } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Anchor } from '../anchor'
import { Button } from '../button'
import { Loader } from '../loader'
import LightboxBody from './compoundComponents/lightboxBody'
import LightboxFooter from './compoundComponents/lightboxFooter'
import LightboxHeader from './compoundComponents/lightboxHeader'
import { GlobalLightboxStyle, Lightbox } from './lightbox.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function handleActionsRender(actions, loading) {
    return actions?.map((action, index) => {
        return (
            <Button
                key={index}
                type={action.type}
                form={action.form}
                disabled={loading || action.disabled}
                loading={action.loading}
                color={action.color}
                onClick={action.func}
            >
                {action.label}
            </Button>
        )
    })
}

export function handleLinksRender(links) {
    return links?.map((link) => {
        return (
            <Anchor key={link.label} {...link}>
                {link.label}
            </Anchor>
        )
    })
}

export function handleCloseRender(conditions) {
    return (
        <Lightbox.Close>
            <button type='button' onClick={conditions.action}>
                <XMarkIcon width={`20px`} />
            </button>
        </Lightbox.Close>
    )
}

export const LightboxComponent = ({
    id,
    className = '',
    conditions,
    icon,
    title = '',
    description = '',
    content,
    loading,
    links,
    actions,
    close,
    type = `default`
}) => {
    const [template, setTemplate] = useState('default')
    const [activeKey, setActiveKey] = useState(undefined)
    const contentRef = useRef()
    const lightboxHeaderRef = useRef()
    const lightboxFooterRef = useRef()

    function handleIconRender() {
        switch (icon) {
            case 'warning':
                return (
                    <Lightbox.Icon className={`warning`}>
                        <ExclamationTriangleIcon width={`42px`} aria-hidden='true' />
                    </Lightbox.Icon>
                )

            case 'error':
                return (
                    <Lightbox.Icon className={`red`}>
                        <XCircleIcon width={`42px`} aria-hidden='true' />
                    </Lightbox.Icon>
                )

            case 'info':
                return (
                    <Lightbox.Icon className={`info`}>
                        <InformationCircleIcon width={`42px`} aria-hidden='true' />
                    </Lightbox.Icon>
                )

            case 'success':
            default:
                return (
                    <Lightbox.Icon className={`confirm`}>
                        <CheckIcon width={`42px`} aria-hidden='true' />
                    </Lightbox.Icon>
                )
        }
    }

    function handleTemplateRender() {
        switch (template) {
            case 'confirm':
            case 'success':
                return (
                    <>
                        <Lightbox.CTA.Wrapper>
                            <Lightbox.CTA.Title>
                                {handleIconRender()}
                                {handleCloseRender(conditions)}
                                <Dialog.Title as='h3'>{title}</Dialog.Title>
                                <Lightbox.CTA.Description>
                                    {loading ? (
                                        <Loader.Basic className={`loader`} message={loading?.length > 0 ? loading : 'Loading...'} />
                                    ) : (
                                        <>{description}</>
                                    )}
                                </Lightbox.CTA.Description>
                            </Lightbox.CTA.Title>
                        </Lightbox.CTA.Wrapper>
                        <Lightbox.CTA.Footer>{handleActionsRender(actions)}</Lightbox.CTA.Footer>
                    </>
                )

            case 'custom':
                return content

            default:
                return <LightboxBody loading={loading}>{content}</LightboxBody>
        }
    }

    function handleHeaderRender() {
        switch (template) {
            case 'confirm':
            case 'success':
            case 'custom':
                return ''

            default:
                return <LightboxHeader lightboxFooterRef={lightboxFooterRef} title={title} description={description} conditions={conditions} />
        }
    }

    function handleFooterRender() {
        switch (template) {
            case 'confirm':
            case 'success':
            case 'custom':
                return ''

            default:
                return <LightboxFooter lightboxFooterRef={lightboxFooterRef} actions={actions} links={links} loading={loading} />
        }
    }

    useEffect(() => {
        if (type) setTemplate(type)
        if (id !== activeKey) setActiveKey(id)
    }, [type, id, activeKey, setActiveKey, setTemplate])

    return (
        <>
            <GlobalLightboxStyle />
            <Transition.Root className={`nxLightbox__root`} show={conditions.state} as={Fragment}>
                <Dialog className={`nxLightbox__dialog`} initialFocus={contentRef} onClose={conditions.action}>
                    <Lightbox.Wrapper>
                        <Transition.Child
                            as={Fragment}
                            enter='nxLightbox__transition--enter'
                            enterFrom='nxLightbox__transition--enterFrom'
                            enterTo='nxLightbox__transition--enterTo'
                            leave='nxLightbox__transition--leave'
                            leaveFrom='nxLightbox__transition--leaveFrom'
                            leaveTo='nxLightbox__transition--leaveTo'
                        >
                            <div className={`overlay`} />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className={`spanHidden`} aria-hidden='true'>
                            &#8203;
                        </span>

                        <Transition.Child
                            as={Fragment}
                            enter='nxLightboxContent__transition--enter'
                            enterFrom='nxLightboxContent__transition--enterFrom'
                            enterTo='nxLightboxContent__transition--enterTo'
                            leave='nxLightboxContent__transition--leave'
                            leaveFrom='nxLightboxContent__transition--leaveFrom'
                            leaveTo='nxLightboxContent__transition--leaveTo'
                        >
                            <Lightbox.Inner className={className} ref={contentRef}>
                                <Lightbox.Ref />
                                {handleHeaderRender()}
                                {handleTemplateRender()}
                                {handleFooterRender()}
                            </Lightbox.Inner>
                        </Transition.Child>
                    </Lightbox.Wrapper>
                </Dialog>
            </Transition.Root>
        </>
    )
}

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
LightboxComponent.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    conditions: PropTypes.shape({
        state: PropTypes.bool.isRequired,
        action: PropTypes.func.isRequired
    }),
    type: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    content: PropTypes.element,
    icon: PropTypes.string,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            href: PropTypes.string,
            target: PropTypes.string,
            func: PropTypes.func
        }).isRequired
    ),
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            color: PropTypes.string,
            func: PropTypes.func
        }).isRequired
    )
}

export { LightboxComponent as Lightbox, LightboxHeader, LightboxBody, LightboxFooter }
