/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { ArrowRightCircleIcon } from '@heroicons/react/24/outline'
import styled, { css } from 'styled-components'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Theme } from 'nxui/src'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
const BaseStyling = {
    Icon: css`
        position: absolute;
        top: 0;
        right: 0;
        width: 180px;
        opacity: 0.1;

        color: ${({ theme }) => theme['white--100']};
    `
}

const _BubbleStyles = {
    Bubble: styled.div`
        position: relative;
        width: 100%;
        min-width: 300px;
        height: fit-content;
        padding: 20px 25px;
        border-radius: 16px;
        overflow: hidden;

        background-color: ${({ theme }) => theme['primary--100']};
        box-shadow: 0 1px 3px ${({ theme }) => Theme.Functions.hexToRGBA(theme['black--100'], '0.1')},
            0 1px 2px ${({ theme }) => Theme.Functions.hexToRGBA(theme['black--100'], '0.06')};
    `,

    Title: styled.h3`
        padding: 0;
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;

        color: ${({ theme }) => theme['white--100']};
    `,

    Description: styled.p`
        padding: 0;
        font-size: 40px;
        font-weight: 800;

        color: ${(props) => props.theme[`white--100`]};
    `,

    IconWrapper: styled.span`
        ${BaseStyling.Icon}
    `,

    DefaultIcon: styled(ArrowRightCircleIcon)`
        ${BaseStyling.Icon}
    `
}
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/

/***** EXPORTS *****/
export const BubbleStyles = Object.assign(_BubbleStyles, {
    Raw: BaseStyling
})
