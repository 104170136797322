/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Theme } from 'nxui/src'

/**********************************************************************************************************
 * TYPES
 **********************************************************************************************************/
import { Weight } from 'models/enums'

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
export const Styles = {
    Title: styled.div`
        color: ${(props) => props.theme['text--100']};
        ${Theme.Functions.setFont('Inter', Weight.Medium)};
        padding: 10px 20px;
        background: #f5f5f5;
    `,
    Nameserver: styled.div`
        padding: 15px 20px;
        ${Theme.Functions.setFont('Inter', Weight.Medium)};

        &:not(:last-child) {
            border-bottom: 1px solid ${(props) => props.theme['gray--100']};
        }
    `
}
