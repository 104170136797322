/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import styled, { keyframes } from 'styled-components'
import * as Theme from '../../../theme/theme'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
export const ASpin = keyframes`
     from {
         transform: rotate(0deg);
     }
     to {
         transform: rotate(360deg);
     }
 `

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
export const Basic = {
    SVG: styled.svg`
        height: 1.25rem;
        width: 1.25rem;
        margin-left: -0.25rem;
        margin-right: 0.75rem;
        animation: ${ASpin} 1s linear infinite;
    `,
    Circle: styled.circle`
        opacity: 0.1;
    `,
    Path: styled.path`
        opacity: 0.4;
    `,
    Base: styled.div`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    `,
    Message: styled.span`
        color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`text--300`], 0.75)};
        font-size: 1.4rem;
    `
}
