/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Formik } from 'formik'
import * as Yup from 'yup'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { Loader } from 'components/loader'
import { Anchor, Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { authAPI, useGetTwoFactorQuery, useLogoutMutation, useVerifyTwoFactorCodeMutation } from 'api/authentication'

/**********************************************************************************************************
 *   HOOKS
 **********************************************************************************************************/
import { useAppDispatch, useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
import { Auth } from 'containers/authentication/authentication.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function Auth2FAForm() {
    const dispatch = useAppDispatch()
    const {
        appAuthentication: {
            twoFactor: { primary: twoFactorPrimary, backup, requested }
        }
    } = useAppSelector((state) => state.app)
    const hasBackup = Array.isArray(backup) ? backup?.filter(({ is_primary }) => !is_primary).length >= 1 : false
    const { refetch, isLoading: twoFactorLoading } = useGetTwoFactorQuery()
    const [verifyTwoFactor] = useVerifyTwoFactorCodeMutation()
    const [logout] = useLogoutMutation()

    function renderTwoFactorDescription() {
        switch (requested ? requested.type : twoFactorPrimary?.type) {
            case 'authenticator':
                return 'Enter the two-factor authentication code which is available via your authenticator app.'
            case 'sms':
                return `Enter the two-factor authentication code which was sent via SMS to ${
                    requested ? requested.identifier : twoFactorPrimary?.identifier ?? ''
                }.`
            case 'email':
                return `Enter the two-factor authentication code which was sent to ${
                    requested ? requested.identifier : twoFactorPrimary?.identifier ?? ''
                }.`
            default:
                return ''
        }
    }

    if (twoFactorLoading) {
        return (
            <>
                <Auth.Title>Two Factor Authentication</Auth.Title>
                <Auth.Break />
                <Auth.Loading>
                    <Loader message='Requesting Two Factor Code...' width={38} height={38} />
                </Auth.Loading>
                <Auth.Break />
                <Auth.Border />
                <Auth.Footer>
                    <Auth.Back color={'select'} onClick={() => logout()}>
                        Back to Login
                    </Auth.Back>
                </Auth.Footer>
            </>
        )
    }

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Formik
            initialValues={{
                code: ''
            }}
            validationSchema={Yup.object({
                code: Yup.string().required('Required')
            })}
            onSubmit={({ code }, { setSubmitting, resetForm }) => {
                verifyTwoFactor({ code, type: requested ? requested.type : twoFactorPrimary?.type ?? '' })
                    .unwrap()
                    .catch(() => {
                        setSubmitting(false)
                        resetForm()
                    })
            }}
        >
            {({ isSubmitting, isValid }) => {
                return (
                    <>
                        <Auth.Title>Two Factor Authentication</Auth.Title>
                        <Auth.Description>{renderTwoFactorDescription()}</Auth.Description>
                        <Auth.Form>
                            <Field
                                label='Authentication Code'
                                name='code'
                                type='text'
                                autoComplete='off'
                                component={NXUIForm.InputField}
                                disabled={isSubmitting}
                            />
                            <Auth.Submit
                                color='primary'
                                type='submit'
                                disabled={isSubmitting || !isValid}
                                loading={isSubmitting ? 'Verifying...' : false}
                            >
                                Verify Code
                            </Auth.Submit>
                        </Auth.Form>
                        <Auth.Row>
                            {requested?.type !== 'authenticator' && (
                                <Auth.Resent>
                                    <span>Didn't receive a code? </span>{' '}
                                    <Anchor
                                        color='primary'
                                        onClick={() => {
                                            if (requested) {
                                                dispatch(authAPI.endpoints.selectTwoFactorMethod.initiate(requested.type, { forceRefetch: true }))
                                            } else {
                                                refetch()
                                            }
                                        }}
                                    >
                                        Resend code
                                    </Anchor>
                                </Auth.Resent>
                            )}
                        </Auth.Row>
                        <Auth.Row>{hasBackup && <Auth.Link to='/login/recovery'>Use an alternative method</Auth.Link>}</Auth.Row>
                        <Auth.Break />
                        <Auth.Border />
                        <Auth.Footer>
                            <Auth.Back color={'select'} onClick={() => logout()}>
                                Back to Login
                            </Auth.Back>
                        </Auth.Footer>
                    </>
                )
            }}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
