/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Button, Loader } from 'nxui/src'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { TableTitle } from 'containers/domains/components/tableTitle/tableTitle'
import AddNameserverForm from 'containers/domains/forms/addNameserver.form'
import AddRecordForm from 'containers/domains/forms/addRecord/addRecord.form'
import DefaultNameserversForm from 'containers/domains/forms/defaultNameservers/defaultNameservers.form'
import DnsModeForm from 'containers/domains/forms/dnsMode.form'
import EditNameserversForm from 'containers/domains/forms/editNameservers.form'
import EditRecordForm from 'containers/domains/forms/editRecord/editRecord.form'
import { AddDomainForwarderForm } from 'containers/domains/forms/forwarding/addDomainForwarder'
import { AddEmailForwarderForm } from 'containers/domains/forms/forwarding/addEmailForwarder'
import { DomainForwarderTable } from 'containers/domains/forms/forwarding/domainForwarder'
import { EmailForwardingTable } from 'containers/domains/forms/forwarding/emailForwarder'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Dns } from 'containers/domains/domains.styles'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { domainAPI, useDnsRecordsQuery, useUpdateNameserversMutation } from 'api/domain'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { snakeCase } from 'helpers/utils'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { toggleAddingDomainRedirect, toggleAddingEmailRedirect, toggleAddingRecord } from 'store/slices/domainSlice'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
import { MetaPayload } from 'models/app'
import { DNSModeType } from 'models/enums'

interface Props {
    meta?: MetaPayload
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function DnsComponent({ meta = { page: '1' } }: Props) {
    /***** HOOKS *****/
    const { id } = useParams()
    const dispatch = useAppDispatch()
    const domainId = Number(id)
    const { dnsMode } = useAppSelector((state) => state.domain)

    /***** STATE *****/
    const [showAdd, setShowAdd] = useState(false)

    /***** QUERIES *****/
    const { isFetching, isError, data } = useDnsRecordsQuery(domainId)
    const { isFetching: isFetchingPresets } = domainAPI.endpoints.dnsPresets.useQuery(domainId)
    const [, { isLoading }] = useUpdateNameserversMutation({ fixedCacheKey: 'update-nameservers' })

    /***** FUNCTIONS *****/
    const handleToggleDomainRedirect = () => dispatch(toggleAddingDomainRedirect())
    const handleToggleEmailRedirect = () => dispatch(toggleAddingEmailRedirect())
    const handleToggleRecord = () => dispatch(toggleAddingRecord())

    /***** RENDER HELPERS *****/
    function renderDNSRecordForm() {
        switch (dnsMode) {
            case DNSModeType.Forwarding:
            case DNSModeType.DNSHosting:
                return (
                    <>
                        <TableTitle title='URL Redirect' isActive={dnsMode === DNSModeType.Forwarding}>
                            <Button color='primary' onClick={handleToggleDomainRedirect}>
                                Add Domain Redirect
                            </Button>
                        </TableTitle>
                        <AddDomainForwarderForm />
                        <DomainForwarderTable />

                        <TableTitle title='Email Forwarders' isActive={dnsMode === DNSModeType.Forwarding}>
                            <Button color='primary' onClick={handleToggleEmailRedirect}>
                                Add Email Forwarder
                            </Button>
                        </TableTitle>
                        <AddEmailForwarderForm />
                        <EmailForwardingTable />

                        <TableTitle title='DNS Records'>
                            <Button color='primary' onClick={handleToggleRecord}>
                                Add Record
                            </Button>
                        </TableTitle>
                        <AddRecordForm meta={meta} />
                        <EditRecordForm />
                    </>
                )
            case DNSModeType.CustomNameservers:
                return (
                    <>
                        <AddNameserverForm showAddNameserver={showAdd} onClose={() => setShowAdd(!showAdd)} />
                        <EditNameserversForm />
                    </>
                )
            case DNSModeType.DefaultNameservers:
                return <DefaultNameserversForm />
            case 'Parked':
                return <Dns.Parked>Domain is currently parked.</Dns.Parked>
            default:
                return null
        }
    }

    const actions = useMemo(() => {
        switch (dnsMode) {
            case DNSModeType.CustomNameservers:
                return [
                    <Button key={`${DNSModeType.CustomNameservers}.Add`} color='primary' onClick={() => setShowAdd(!showAdd)}>
                        Add Nameserver
                    </Button>
                ]
            default:
                return []
        }
    }, [dnsMode, showAdd])

    useEffect(() => {
        setShowAdd(false)
    }, [isLoading])

    /***** RENDER *****/
    if (isError) return null

    if (isLoading || isFetchingPresets || (isFetching && data?.dns_mode && Object.keys(data.dns_mode)[0] !== snakeCase(dnsMode))) {
        return (
            <Dns.Base>
                <Loader.Skeleton sections={['title', 'description', 'content', 'footer']} />
            </Dns.Base>
        )
    }

    return (
        <Dns.Base>
            <DnsModeForm actions={actions} />
            {renderDNSRecordForm()}
        </Dns.Base>
    )
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { DnsComponent as Dns }
