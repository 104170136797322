/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { Field, Formik, FormikProps } from 'formik'
import { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   STORE IMPORTS
 **********************************************************************************************************/
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/utilities/Flex'
import { Badge, Loader, Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { domainAPI } from 'api/domain'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { EmailForwarderActionRow } from './emailForwardingActions'
import { EmailForwardingSource } from './emailForwardingSource'
import { EmailForwarderPreviewRow } from './previewRow'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { TEmailRedirect } from 'models/domain'
import { DNSModeType } from 'models/enums'
import { forwardingStyles as S } from '../_forwarding.styles'
import { validationSchema } from '../addEmailForwarder'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export type TEmailForwardingFormValues = {
    catch_all: boolean
    source: string
    destination: string
}
type TTableRowObject = {
    [key in ReturnType<typeof columns>[number]['accessor']]: React.ReactNode
}

const columns = () =>
    [
        {
            Header: 'CATCH ALL',
            accessor: 'catch_all'
        },
        {
            Header: 'SOURCE ADDRESS',
            accessor: 'source'
        },
        {
            Header: 'DESTINATION ADDRESS',
            accessor: 'destination'
        },
        {
            Header: '',
            accessor: 'actions'
        }
    ] as const

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const EmailForwardingTable = () => {
    /***** HOOKS *****/
    const { id } = useParams()
    const { dnsMode } = useAppSelector((state) => state.domain)

    /***** STATE *****/
    const [editingIndex, setEditing] = useState<number>(-1)
    const innerRef = useRef<FormikProps<TEmailForwardingFormValues>>(null)

    /***** QUERIES *****/
    const { data, isFetching: isFetchingRedirectData, isLoading: isLoadingRedirectData } = domainAPI.endpoints.emailRedirects.useQuery(Number(id))
    const [updateEmailRedirect] = domainAPI.endpoints.updateEmailRedirect.useMutation({ fixedCacheKey: 'update-email-redirect' })

    /***** FUNCTIONS *****/
    const getInitialValues = () => {
        if (editingIndex === -1) {
            return {} as TEmailForwardingFormValues
        }

        if (!data) {
            return {} as TEmailForwardingFormValues
        }

        const { destination, source } = data.records[editingIndex]
        return {
            catch_all: source.split('@')[0] === '',
            source: source.split('@')[0],
            destination
        }
    }

    /***** RENDER HELPERS *****/
    const renderLoader = () => [
        createFreedomRow(
            <Flex fullHeight justify='center' align='center'>
                <Flex align='center' justify='center'>
                    <Loader.Basic /> <S.FetchingMessage>Fetching Email Redirects</S.FetchingMessage>
                </Flex>
            </Flex>
        )
    ]

    const createFreedomRow = (children: React.ReactNode, wrap = true): TTableRowObject => ({
        catch_all: wrap ? <S.Domain.FreedomRow>{children}</S.Domain.FreedomRow> : children,
        source: null,
        destination: null,
        actions: null
    })

    const createRow = ({ index, ...options }: TEmailRedirect & { index: number; catch_all: boolean }) => {
        const baseRow = {
            catch_all: <Badge color={options.catch_all ? 'primary' : 'secondary'}>{options.catch_all ? 'Enabled' : 'Disabled'}</Badge>,
            source: <div>{options.source}</div>,
            destination: <div>{options.destination}</div>,
            actions: (
                <Flex justify='flex-end'>
                    <S.Anchor type='button' color='primary' onClick={() => setEditing((editing) => (editing === index ? -1 : index))}>
                        Edit {editingIndex === index ? <ChevronDownIcon /> : <ChevronRightIcon />}
                    </S.Anchor>
                </Flex>
            )
        }

        const editingRow = {
            catch_all: (
                <S.Email.CheckboxWrapper>
                    <Field name='catch_all' description='Catch All' type='checkbox' component={NXUIForm.CheckboxField} />
                </S.Email.CheckboxWrapper>
            ),
            source: <EmailForwardingSource />,
            destination: <Field name='destination' type='text' label='Destination' component={S.InputField} />,
            actions: null
        }

        const actionRow = createFreedomRow(
            <EmailForwarderActionRow
                onSave={() => innerRef.current?.handleSubmit()}
                onCancel={() => setEditing(-1)}
                onDelete={() => setEditing(-1)}
                recordId={options.id}
            />
        )

        if (editingIndex === index) {
            return [baseRow, createFreedomRow(<EmailForwarderPreviewRow />), editingRow, actionRow]
        }

        return baseRow
    }

    /***** RENDER *****/
    if (dnsMode !== DNSModeType.Forwarding) return null

    return (
        <Formik
            enableReinitialize
            initialValues={getInitialValues()}
            onSubmit={async ({ destination, source, catch_all }) => {
                if (!data?.records[editingIndex]) {
                    return
                }

                await updateEmailRedirect({
                    source: `${source}@${data?.domain}`,
                    destination,
                    domainId: Number(id),
                    recordId: data?.records[editingIndex].id,
                    catch_all
                })
                    .unwrap()
                    .then(() => setEditing(-1))
                    .catch(() => setEditing(-1))
            }}
            innerRef={innerRef}
            validationSchema={validationSchema}
        >
            {({ handleSubmit }) => (
                <S.Form onSubmit={handleSubmit}>
                    <S.Domain.Table
                        editing={editingIndex !== -1}
                        editingIndex={editingIndex}
                        conditions={{
                            sort: false,
                            loading: isLoadingRedirectData && 'Loading Email Redirects'
                        }}
                        render={{
                            columns: columns,
                            data: () => [
                                ...(data?.records.flatMap((record, index) =>
                                    createRow({ ...record, index, catch_all: record.source.split('@')[0] === '' })
                                ) ?? []),
                                ...(isFetchingRedirectData ? renderLoader() : []) // optionally show fetching row
                            ]
                        }}
                    />
                    <S.InvisibleSubmitButton />
                </S.Form>
            )}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
