/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Form, Formik, FormikProps } from 'formik'
import { RefObject } from 'react'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { MultiSelect, Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { serviceAPI } from 'api/service'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Change } from 'containers/services/services.styles'
import { Services } from 'containers/shop/shop.styles'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { defaultCurrency, getBillingCycle, handleCheckboxQuantity, removeSkuFromConfigOptionName } from 'helpers/utils'
import { ResetFormOnProductChange, getInitialValuesConfig } from './helpers'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
import { ServiceMetaPayload } from 'api/service/types'
import { ChangePlanData } from 'containers/services/lightbox/changePlan/changeServicePlan.lightbox'
import { ChangePlanForm } from 'models/service'

interface ChangeServicePlanFormComponentProps {
    form: RefObject<FormikProps<ChangePlanForm>>
    setChangePlanData: React.Dispatch<React.SetStateAction<ChangePlanData>>
    meta: ServiceMetaPayload
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function ChangeServicePlanFormComponent({
    form,
    setChangePlanData,
    meta = { page: '1', sort_by: null }
}: ChangeServicePlanFormComponentProps) {
    const { id } = useParams()
    const serviceId = Number(id)

    const { data: changePlanPathsData } = serviceAPI.endpoints.availablePlans.useQueryState(serviceId)
    const { serviceData } = serviceAPI.endpoints.services.useQueryState(meta, {
        selectFromResult: ({ data }) => ({
            serviceData: data?.data.find((serviceItem) => serviceItem.id === serviceId)
        })
    })

    if (!serviceData || !changePlanPathsData) return <p>Something went wrong! Please contact support or try again later.</p>

    const totalPlansData = [changePlanPathsData.current_product, ...changePlanPathsData.plan_paths]

    const renderPlanChange = (values: ChangePlanForm) => {
        if (!totalPlansData || totalPlansData.length < 1) return ''

        return (
            <Change.Paths>
                <Field
                    name='product_id'
                    label='Available Products'
                    type='select'
                    list={totalPlansData.map(({ id, name, description, pricing }) => {
                        const pricingObject = pricing.find(({ billing_cycle }) => billing_cycle.id === values.billing_cycle_id)
                        const price = `${defaultCurrency(pricingObject?.price)}${getBillingCycle(pricingObject?.billing_cycle.name)}`

                        return {
                            label: `${name} ${description ? `- ${description}` : ''} - ${price}${
                                id === serviceData.product.id ? ' (current plan)' : ''
                            }`,
                            value: id
                        }
                    })}
                    component={NXUIForm.SelectField}
                />
            </Change.Paths>
        )
    }

    const renderBillingCycle = (
        values: ChangePlanForm,
        setFieldValue: (field: string, value: boolean | string | number, shouldValidate?: boolean | undefined) => void
    ) => {
        const matchingPlan = totalPlansData.find(({ id }) => id.toString() === values.product_id)

        if (!matchingPlan) return ''

        return (
            <Change.BillingCycleField>
                <Change.Label>Billing Cycle</Change.Label>
                <MultiSelect
                    options={matchingPlan.pricing.map((billingCycleOption) => {
                        const { name, id } = billingCycleOption.billing_cycle

                        return {
                            type: 'button',
                            label: name,
                            active: values.billing_cycle_id === id,
                            onClick: () => {
                                setFieldValue('billing_cycle_id', id)
                            }
                        }
                    })}
                />
            </Change.BillingCycleField>
        )
    }

    const renderConfigOptions = (values: ChangePlanForm) => {
        const matchingPlan = totalPlansData.find(({ id }) => id.toString() === values.product_id)
        const configOptionGroups = matchingPlan?.config_option_groups

        if (!configOptionGroups) return ''

        return configOptionGroups.map((productConfigOptionGroup, index) => {
            return (
                <Services.ConfigOptionGroup key={`${productConfigOptionGroup.id}${index}`}>
                    <Services.GroupHeading>{productConfigOptionGroup.name}</Services.GroupHeading>

                    {productConfigOptionGroup.config_options.map((productConfigOption, index) => {
                        // The values of the product config option being rendered, sorted by display order
                        const sortedConfigValues = [...productConfigOption.values].sort((a, b) => a.display_order - b.display_order)

                        // The value from the sorted values, found by matching the value id to the option id of a config option in the current form
                        const optionValue = sortedConfigValues.find(({ id }) => {
                            const foundOption = values.config[index]
                            if (!foundOption) return false
                            return id === Number(foundOption.option_id)
                        })

                        if (!optionValue) return ''

                        const billingCycle = optionValue?.pricing.find(({ billing_cycle: { id } }) => id === values.billing_cycle_id)

                        function getPrice() {
                            const costOfOneAs2dpString = billingCycle?.price
                            const quantity = values.config[index].quantity

                            if (productConfigOption.input_type !== 'number' || quantity === undefined || typeof quantity !== 'number')
                                return costOfOneAs2dpString

                            const totalCost = Number(costOfOneAs2dpString || 0) * quantity
                            return totalCost.toFixed(2)
                        }

                        const readablePrice = `${defaultCurrency(getPrice())}${getBillingCycle(billingCycle?.billing_cycle.name)}`

                        if (productConfigOption.input_type === 'checkbox') {
                            return (
                                <div key={`${productConfigOption.id}-${index}`}>
                                    <Services.ConfigOption.Label>
                                        {removeSkuFromConfigOptionName(productConfigOption.name)}
                                        <span>{readablePrice}</span>
                                    </Services.ConfigOption.Label>
                                    <div>
                                        {productConfigOption.values.map((value) => {
                                            return (
                                                <Field
                                                    key={String(value.id)}
                                                    name={`config.${index}.quantity`}
                                                    value={String(1)}
                                                    type='checkbox'
                                                    description={value.name}
                                                    component={NXUIForm.CheckboxField}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        }

                        if (productConfigOption.input_type === 'radio') {
                            return (
                                <div key={`${productConfigOption.id}-${index}`} role={'group'} aria-labelledby={productConfigOption.name}>
                                    <Services.ConfigOption.Label>
                                        {removeSkuFromConfigOptionName(productConfigOption.name)}
                                        <span>{readablePrice}</span>
                                    </Services.ConfigOption.Label>
                                    <div>
                                        {productConfigOption.values.map((value) => {
                                            return (
                                                <Field
                                                    key={String(value.id)}
                                                    label={value.name}
                                                    name={`config.${index}.option_id`}
                                                    value={String(value.id)}
                                                    type='radio'
                                                    description={value.name}
                                                    component={NXUIForm.RadioField}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        }

                        if (productConfigOption.input_type === 'number') {
                            return (
                                <div key={`${productConfigOption.id}-${index}`} role={'group'} aria-labelledby={productConfigOption.name}>
                                    <Services.ConfigOption.Label>
                                        {removeSkuFromConfigOptionName(productConfigOption.name)}
                                        <span>{readablePrice}</span>
                                    </Services.ConfigOption.Label>
                                    {productConfigOption.values.map((value) => {
                                        return (
                                            <Field
                                                key={String(value.id)}
                                                label={value.name}
                                                name={`config.${index}.quantity`}
                                                type={'number'}
                                                min={productConfigOption.min_quantity.toString()}
                                                max={productConfigOption.max_quantity.toString()}
                                                component={NXUIForm.NumberField}
                                            />
                                        )
                                    })}
                                </div>
                            )
                        }

                        if (productConfigOption.input_type === 'dropdown') {
                            const values = productConfigOption.values.map(({ id, name }) => {
                                return {
                                    label: name,
                                    value: id
                                }
                            })

                            return (
                                <div key={`${productConfigOption.id}-${index}`}>
                                    <Field
                                        name={`config.${index}.option_id`}
                                        label={
                                            <Services.ConfigOption.Label>
                                                {removeSkuFromConfigOptionName(productConfigOption.name)}
                                                <span>{readablePrice}</span>
                                            </Services.ConfigOption.Label>
                                        }
                                        type='select'
                                        list={values}
                                        component={NXUIForm.SelectField}
                                    />
                                </div>
                            )
                        }
                    })}
                </Services.ConfigOptionGroup>
            )
        })
    }

    return (
        <Formik
            innerRef={form}
            initialValues={{
                billing_cycle_id: serviceData.billing_cycle.id,
                product_id: serviceData.product.id.toString(),
                config: getInitialValuesConfig(serviceData, totalPlansData)
            }}
            onSubmit={(values) => {
                setChangePlanData({
                    template: 'confirm',
                    value: {
                        ...values,
                        product_id: Number(values.product_id),
                        config: values.config.map(({ config_id, option_id, quantity }) => ({
                            config_id,
                            option_id: Number(option_id),
                            ...(handleCheckboxQuantity(quantity || 0) && {
                                quantity: handleCheckboxQuantity(quantity || 0)
                            })
                        }))
                    }
                })
            }}
        >
            {({ values, setFieldValue }) => {
                return (
                    <Form id={'changePlanForm'}>
                        {renderPlanChange(values)}
                        <Change.ConfigDescription>
                            {Number(values.product_id) === serviceData.product.id
                                ? 'Update the configuration of your current plan.'
                                : 'Configure your new plan.'}
                        </Change.ConfigDescription>
                        {renderBillingCycle(values, setFieldValue)}
                        {renderConfigOptions(values)}
                        <ResetFormOnProductChange serviceData={serviceData} totalPlansData={totalPlansData} />
                    </Form>
                )
            }}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { ChangeServicePlanFormComponent as ChangeServicePlanForm }
