/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { css } from 'styled-components'
import { TButtonBaseProps } from '../base'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
export const ButtonStyles = {
    Size: css<{ size?: 'small' | 'medium' | 'large' }>`
        ${({ size }) => {
            switch (size) {
                case 'small':
                    return css`
                        height: 32px;
                        padding: 0px 10px;
                    `

                case 'large':
                    return css`
                        height: 44px;
                        padding: 0px 24px;
                    `

                default:
                    return css`
                        height: 38px;
                        padding: 0px 16px;
                    `
            }
        }}
    `,

    Color: css<{ loading?: TButtonBaseProps['loading']; disabled?: boolean; color?: TButtonBaseProps['color'] }>`
        ${({ color, disabled, loading }) => {
            switch (true) {
                case disabled || !!loading: {
                    return css`
                        cursor: default;
                        color: ${({ theme }) => theme['text--100']};
                        background-color: ${({ theme }) => theme['gray--100']};

                        .icon {
                            color: ${({ theme }) => theme['icon--300']};
                        }
                    `
                }
                case color === 'primary': {
                    return css`
                        color: ${({ theme }) => theme['white--100']};
                        background-color: ${({ theme }) => theme['primary--100']};

                        &:hover {
                            background-color: ${({ theme }) => theme['primary--200']};
                        }

                        .icon {
                            color: ${({ theme }) => theme['white--100']};
                        }
                    `
                }
                case color === 'error':
                    return css`
                        color: ${({ theme }) => theme['white--100']};
                        background-color: ${({ theme }) => theme['error--100']};

                        &:hover {
                            background-color: ${({ theme }) => theme['error--200']};
                        }

                        .icon {
                            color: ${({ theme }) => theme['white--100']};
                        }
                    `

                case color === 'flat':
                    return css`
                        color: ${({ theme }) => theme['text--100']};
                        background-color: ${({ theme }) => theme['secondary--100']};

                        &:hover {
                            color: ${({ theme }) => theme['text--300']};
                            background-color: ${({ theme }) => theme['secondary--200']};

                            .icon {
                                color: ${({ theme }) => theme['text--300']};
                            }
                        }

                        .icon {
                            color: ${({ theme }) => theme['icon--300']};
                        }
                    `

                case color === 'secondary':
                default: {
                    return css`
                        color: ${({ theme }) => theme['text--200']};
                        background-color: ${({ theme }) => theme['secondary--100']};
                        border: 1px solid ${({ theme }) => theme['border--100']};

                        &:hover {
                            background-color: ${({ theme }) => theme['secondary--200']};
                        }

                        .icon {
                            color: ${({ theme }) => theme['icon--300']};
                        }
                    `
                }
            }
        }}
    `
}
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/
