/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { getInitials } from '../../helpers/functions'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Anchor } from '../anchor'
import SidenavItem from './compoundComponents/sidenavItem'
import { Account, Mobile, Profile, Sidenav } from './sidenav.styles'

import { Bars3Icon } from '@heroicons/react/24/solid'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SidenavComponent = ({ company, nav, shop, support, profile, logout, switchAcc }) => {
    /*   REF
     *****************************************************/
    const backgroundRef = useRef()

    /*   STATE
     *****************************************************/
    const [isActive, setIsActive] = useState(false)

    /*   EFFECTS
     *****************************************************/
    useEffect(() => {
        const checkClickOutside = (e) => {
            if (isActive && backgroundRef.current && backgroundRef.current.contains(e.target)) {
                setIsActive(false)
            }
        }
        document.addEventListener('mousedown', checkClickOutside)

        return () => {
            document.removeEventListener('mousedown', checkClickOutside)
        }
    }, [isActive])

    /*   FUNCTIONS
     *****************************************************/
    function renderNavOptions() {
        return nav.map((navGroup, navGroupIndex) => {
            return navGroup.map((item, i) => {
                return (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-to-interactive-role
                    <li onClick={() => closeMenuOnAction()} role='button' key={`${i}-nav`}>
                        {item}
                        {i === navGroup.length - 1 && navGroupIndex !== nav.length - 1 && <Sidenav.Nav.Break />}
                    </li>
                )
            })
        })
    }

    function closeMenuOnAction(cb) {
        if (cb) cb()
        setIsActive(false)
    }

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <>
            <Sidenav.Mobile>
                <Mobile.Nav.List>
                    <Mobile.Nav.Option onClick={() => setIsActive(true)}>
                        <Bars3Icon width={'28px'} />
                    </Mobile.Nav.Option>
                    <Mobile.Nav.Logo>
                        <Sidenav.Logo src={company.src} alt={company.alt} onClick={() => closeMenuOnAction(company.home)} />
                    </Mobile.Nav.Logo>
                </Mobile.Nav.List>
            </Sidenav.Mobile>

            <Sidenav.Background active={isActive} ref={backgroundRef} />

            <Sidenav.Container active={isActive}>
                <Sidenav.Header>
                    <Anchor onClick={company.navigate}>
                        <Sidenav.Logo src={company.src} alt={company.alt} />
                    </Anchor>
                </Sidenav.Header>
                <Sidenav.Nav.List>{renderNavOptions()}</Sidenav.Nav.List>
                <Sidenav.Profile onClick={profile.redirect}>
                    <Profile.Avatar>{getInitials(profile.account.name)}</Profile.Avatar>
                    <div>
                        <Profile.Title>{profile.account.name}</Profile.Title>
                        <Account.Role>{profile.account.role.name}</Account.Role>
                    </div>
                </Sidenav.Profile>
                <Sidenav.Nav.Break />
                <Sidenav.Actions>
                    {switchAcc && (
                        <Anchor color={`select`} onClick={() => closeMenuOnAction(switchAcc)}>
                            Switch Account
                        </Anchor>
                    )}
                    {logout && (
                        <Sidenav.Logout color={`select`} onClick={() => closeMenuOnAction(logout)}>
                            Logout
                        </Sidenav.Logout>
                    )}
                </Sidenav.Actions>
            </Sidenav.Container>
        </>
    )
}

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
SidenavComponent.propTypes = {
    company: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        application: PropTypes.string
    }),
    nav: PropTypes.arrayOf(
        PropTypes.arrayOf(
            // Array of "SidenavItem" components
            PropTypes.element
        )
    ),
    profile: PropTypes.shape({
        account: PropTypes.shape({
            name: PropTypes.string,
            company: PropTypes.string,
            account_number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            role: PropTypes.shape({
                name: PropTypes.string
            })
        }),
        redirect: PropTypes.func
    }),
    shop: PropTypes.func,
    support: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    switchAcc: PropTypes.func,
    logout: PropTypes.func
}

SidenavComponent.defaultProps = {
    company: {
        src: '',
        alt: '',
        application: ''
    },
    nav: [],
    profile: {
        account: {
            name: '',
            company: '',
            account_number: '',
            role: {
                name: ''
            }
        },
        redirect: () => void 0
    },
    shop: null,
    support: null,
    switchAcc: null,
    logout: () => void 0
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { SidenavComponent as Sidenav, SidenavItem }

