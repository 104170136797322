/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Field, FieldProps } from 'formik'
import React, { InputHTMLAttributes } from 'react'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { FieldLabel } from 'components/form/util/label'
import { FieldErrorIcon } from '../util/error/icon'

/**********************************************************************************************************
*   CONSTS
**********************************************************************************************************/
import { InputFieldStyles as S } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TInputFieldProps = {
    embedded?: boolean
    required?: boolean
    label?: string
    disabled?: boolean
    permanentPlaceholder?: React.ReactNode
    placeholder?: string
    innerRef?: React.Ref<HTMLInputElement>
    intrinsic?: InputHTMLAttributes<HTMLInputElement>
    className?: string
}

type TInputField = React.FC<{ name: string } & TInputFieldProps>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const InternalComponent = ({
    embedded,
    required,
    label,
    disabled,
    placeholder,
    permanentPlaceholder,
    innerRef,
    intrinsic,
    className,
    ...fieldProps
}: FieldProps & TInputFieldProps) => {
    /***** RENDER HELPERS *****/
    const {
        field,
        form: { touched, errors, isSubmitting }
    } = fieldProps
    const labelProps = { embedded, required, field: { id: intrinsic?.id, name: field.name }, label }
    const errorProps = { name: field.name, touched, errors }

    /***** RENDER *****/
    return (
        <S.Wrapper className={className} key={label} embedded={embedded}>
            <FieldLabel {...labelProps} />
            <S.Column error={!!(touched[field.name] && errors[field.name])} disabled={!!(isSubmitting || disabled)}>
                <S.Base
                    type='text'
                    placeholder={placeholder}
                    {...field}
                    {...intrinsic}
                    onBlur={field?.onBlur}
                    ref={innerRef}
                    disabled={isSubmitting || disabled}
                />
                {permanentPlaceholder}
                <FieldErrorIcon {...errorProps} />
            </S.Column>
        </S.Wrapper>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const _InputField: TInputField = (props) => <Field component={InternalComponent} {...props} />

/***** EXPORTS *****/
export const InputField = Object.assign(_InputField, {
    Styles: S,
    PermanentPlaceholder: S.PermanentPlaceholder
})
