/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { GlobeAltIcon } from '@heroicons/react/20/solid'

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useDomainFeaturedProductsQuery } from 'api/shop'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Loader } from 'components/NXLoader'
import { Bubble } from 'components/bubble'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getDefaultCurrencySymbol } from 'helpers/utils'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CompetitivePricesStyles as S } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import { IDomainProducts } from 'models/shop/shop'

type TCompetitiveBubble = React.FC<{
    tld?: string
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const CompetitiveBubble: TCompetitiveBubble = ({ tld }) => {
    /***** QUERIES *****/
    // Access the featured products query via shopAPI.endpoints
    const { data: featuredProducts, isLoading: isDomainFeaturedProductsLoading } = useDomainFeaturedProductsQuery()

    // Find the specific TLD from the featured products
    const result = featuredProducts?.find((tldProduct: IDomainProducts) => tldProduct.name === tld)

    // Check if pricing is available, and specifically get the registration price
    const pricing = result?.pricing?.find((priceItem) => priceItem.type === 'register')
    const price = pricing ? `${getDefaultCurrencySymbol()}${pricing.price}` : 'N/A'

    /***** RENDER *****/
    return (
        <S.CompoundBubble icon={GlobeAltIcon}>
            <Bubble.Description>{tld}</Bubble.Description>

            {/* Conditionally render price or loader */}
            {isDomainFeaturedProductsLoading ? (
                <S.Price>
                    <Loader />
                </S.Price>
            ) : (
                result && <S.Price>{price} /yr</S.Price>
            )}
        </S.CompoundBubble>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
