/* eslint-disable */
/**********************************************************************************************************
 *   ACCOUNT
 **********************************************************************************************************/
export enum AccountStatus {
    Active = 'active',
    Disabled = 'disabled',
    Closed = 'closed'
}

export enum TwoFactorType {
    AuthenticatorApp = 'authenticator',
    Email = 'email',
    SMS = 'sms'
}

/**********************************************************************************************************
 *   APPLICATION
 **********************************************************************************************************/
export enum StatusCodes {
    OK = 200,
    Created = 201,
    NoContent = 204,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    MethodNotAllow = 405,
    Conflict = 409,
    InternalServerError = 500
}

export enum HTTPVerbs {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATch = 'PATCH',
    DELETE = 'DELETE'
}

export enum HTTPStatusCode {
    Ok = 200,
    Created = 201,
    NoContent = 204,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    MethodNotAllowed = 405,
    Conflict = 409,
    InternalServerError = 500
}

/**********************************************************************************************************
 *   PRODUCT
 **********************************************************************************************************/
export enum ProductStatus {
    Active = 'active',
    Disabled = 'disabled'
}

export enum ProductServiceType {
    SharedHosting = 'shared_hosting',
    ResellerHosting = 'reseller_hosting',
    VPS = 'vps',
    Domain = 'domain',
    EmailHosting = 'email_hosting',
    GSuite = 'gsuite',
    SSL = 'ssl',
    Other = 'other',
    Addon = 'addon'
}

export enum BillingCycleName {
    OneTime = 'One Time',
    Monthly = 'Monthly',
    Quarterly = 'Quarterly',
    SemiAnnually = 'Semi-Annually',
    Annually = 'Annually',
    Biennially = 'Biennially',
    Triennially = 'Triennially',
    OneYear = '1 Year',
    TwoYears = '2 Years',
    ThreeYears = '3 Years',
    FourYears = '4 Years',
    FiveYears = '5 Years',
    SixYears = '6 Years',
    SevenYears = '7 Years',
    EightYears = '8 Years',
    NineYears = '9 Years',
    TenYears = '10 Years'
}

export enum BillingCycleInterval {
    P1M = '/ Monthly',
    P3M = '/ Quarterly',
    P6M = '/ Semi-Annually',
    P1Y = '/ Annually',
    P2Y = '/ Biennially',
    P3Y = '/ Triennially',
    P4Y = '/ 4 Years',
    P5Y = '/ 5 Years',
    P6Y = '/ 6 Years',
    P7Y = '/ 7 Years',
    P8Y = '/ 8 Years',
    P9Y = '/ 9 Years',
    P10Y = '/ 10 Years'
}

/**********************************************************************************************************
 *   BILLING
 **********************************************************************************************************/
export enum InvoiceStatus {
    Paid = 'paid',
    Unpaid = 'unpaid',
    Cancelled = 'cancelled',
    Refunded = 'refunded',
    Draft = 'draft',
    Fraud = 'fraud',
    Disputed = 'disputed'
}

export enum PaymentMethod {
    Bpay = 'BPAY',
    Paypal = 'PayPal',
    EFT = 'EFT',
    CreditCard = 'Credit Card',
    AccountCredit = 'Credit'
}

export enum RefundMethod {
    Gateway = 'gateway',
    Manual = 'manual',
    AccountCredit = 'account_credit',
    Donation = 'donation',
    None = 'none'
}

/**********************************************************************************************************
 *   SHOPPING CART
 **********************************************************************************************************/
export enum OrderStatus {
    Active = 'active',
    Pending = 'pending',
    Cancelled = 'cancelled',
    Refunded = 'refunded',
    PendingReview = 'pending review',
    Fraud = 'fraud'
}

export enum OrderType {
    All = 'all',
    Register = 'register',
    Transfer = 'transfer',
    Renew = 'renew',
    Existing = 'existing'
}

/**********************************************************************************************************
 *   SERVICE
 **********************************************************************************************************/
export enum ManageableStatus {
    Active = 'active',
    Suspended = 'suspended',
    Pending = 'pending',
    PendingTransfer = 'pending transfer'
}

export enum ServiceStatus {
    Active = 'active',
    Suspended = 'suspended',
    Terminated = 'terminated',
    Pending = 'pending',
    Cancelled = 'cancelled',
    Expired = 'expired',
    PendingTransfer = 'pending transfer',
    Grace = 'grace',
    Redemption = 'redemption',
    TransferredAway = 'transferred away',
    Fraud = 'fraud',
}

export enum ChangePlanStatus {
    Pending = 'pending',
    Completed = 'completed',
    Cancelled = 'cancelled'
}

/**********************************************************************************************************
 *   DOMAIN
 **********************************************************************************************************/
export enum AvailabilityResultStatus {
    Available = 'available',
    Registered = 'registered',
    Reserved = 'reserved',
    Unknown = 'unknown',
    NotSupported = 'not supported'
}

export enum DNSModeType {
    DNSHosting = 'DNS Hosting',
    CustomNameservers = 'Custom Nameservers',
    DefaultNameservers = 'Default Nameservers',
    Parked = 'Parked',
    Forwarding = 'URL & Email Forwarding',
    Unknown = 'Unknown'
}

export enum DNSRecordType {
    A = 'A',
    AAAA = 'AAAA',
    CNAME = 'CNAME',
    MX = 'MX',
    NS = 'NS',
    SOA = 'SOA',
    SRV = 'SRV',
    TXT = 'TXT'
}

/**********************************************************************************************************
 *   STYLING
 **********************************************************************************************************/
export enum Weight {
    Light = 300,
    Regular = 400,
    Medium = 500,
    SemiBold = 600,
    Bold = 700,
    ExtraBold = 800
}
