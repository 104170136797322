/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import {
    removeFloatingDotFromString,
    removeFloatingDots,
    removeFloatingWhitespaceFromString
} from 'containers/domains/forms/editRecord/_editRecord.helpers'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
import { DNSRecord, PresetResponse } from 'models/domain'

type TPresetRecord = DNSRecord & { isPreset?: boolean }

type TInjectRecordTransform = (props: {
    domain: Readonly<string>
    record: Readonly<TPresetRecord> | undefined
    records: Readonly<Array<TPresetRecord>>
}) => DNSRecord | undefined

type TInsertRecord = (props: {
    domain: string
    record: DNSRecord
    records: Array<DNSRecord>
    transforms?: Array<TInjectRecordTransform>
    meta?: {
        isPreset?: boolean
    }
}) => void
/**
 * Ejects a domain from the records array and returns it if it existed.
 */
export const ejectRecord = (record: DNSRecord, records: Array<DNSRecord>) => {
    const { id } = record

    if (id) {
        const index = records.findIndex((record) => record.id === id)

        if (index === -1) return

        return records.splice(index, 1)[0]
    }

    // if no id, we'll have to compare the fields
    const { hostname, type, content } = record

    const index = records.findIndex((record) => {
        return record.hostname === hostname && record.type === type && record.content === content
    })

    if (index === -1) return

    return records.splice(index, 1)[0]
}

// same as TInjectRecordTransform but the records prop is optional
type TAppendMissingDomains = <T extends DNSRecord | undefined>(props: {
    domain: Readonly<string>
    record: T
    records?: Readonly<Array<DNSRecord>>
}) => T extends undefined ? undefined : DNSRecord

export const appendMissingDomains: TAppendMissingDomains = ({ domain, record }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!record) return record as any

    const sanitizedRecord: DNSRecord = { ...record }

    if (sanitizedRecord.hostname === '@') {
        sanitizedRecord.hostname = domain
        return sanitizedRecord
    }

    // check if it ends in the current users domain
    if (!sanitizedRecord.hostname?.endsWith(domain)) {
        //if it ends in a dot, strip the dot
        if (sanitizedRecord.hostname?.endsWith('.')) {
            sanitizedRecord.hostname = record?.hostname.slice(0, -1)
        }

        //append domain
        sanitizedRecord.hostname = `${sanitizedRecord.hostname}.${domain}`

        //if it now starts with a dot, remove the dot from the start
        if (sanitizedRecord.hostname?.startsWith('.')) {
            sanitizedRecord.hostname = sanitizedRecord.hostname.slice(1)
        }
    }

    return sanitizedRecord
}

const defaultTTL = 14400
const injectMissingTTL: TInjectRecordTransform = ({ record }) => {
    if (!record) return record

    const { ttl } = record

    if (!ttl || String(ttl) === '') {
        return {
            ...record,
            ttl: defaultTTL
        }
    }

    return record
}

/**
 * Injects formValues into their corresponding merge fields in the records based on the merge field syntax {{key}}.
 */
export const injectMergeFields = (records: Readonly<Array<DNSRecord> | undefined>, formValues: Readonly<Record<string, string>>) => {
    return (records?.map((record) => {
        const entries = Object.entries(record)

        // if a key of formValues is included in key OR value, replace the corresponding one with the value from formValues
        return Object.fromEntries(
            entries.map(([key, value]) => {
                Object.keys(formValues).forEach((formKey) => {
                    if (key === 'isPreset') return

                    const formValue = formValues[formKey] === '' ? `{{${formKey}}}` : formValues[formKey]

                    if (key.includes(`{{${formKey}}}`)) {
                        key = key.replaceAll(`{{${formKey}}}`, formValue)
                    }

                    if (value.includes(`{{${formKey}}}`)) {
                        value = value.replaceAll(`{{${formKey}}}`, formValue)
                    }
                })

                return [key, value]
            })
        )
    }) ?? []) as Array<DNSRecord>
}

/**
 * Backend validation should be performed in most cases for this, but general frontend validation
 * here is present so that we can optimistically update the UI to best represent what will come back from
 * the API
 */
export const isConflictingRecord = (
    existingRecords: Readonly<Array<TPresetRecord>>,
    record: Readonly<TPresetRecord>,
    options?: { ignoreIsPreset?: boolean }
) => {
    const doesNotMatchId = (existingRecord: TPresetRecord) => existingRecord.id !== record.id || !record.id

    if (!options?.ignoreIsPreset && record.isPreset) return false

    if (record.type !== 'TXT') {
        return existingRecords.some((existingRecord) => {
            if (!existingRecord.isPreset && !record.isPreset) return false

            if (existingRecord.type === 'CNAME' || record.type === 'CNAME') {
                return existingRecord.hostname === record.hostname && doesNotMatchId(existingRecord)
            }

            if (['CNAME', 'A', 'AAAA'].includes(existingRecord.type) && ['CNAME', 'A', 'AAAA'].includes(record.type)) {
                return existingRecord.hostname === record.hostname && doesNotMatchId(existingRecord)
            }

            return existingRecord.hostname === record.hostname && existingRecord.type === record.type && doesNotMatchId(existingRecord)
        })
    }

    // For TXT Records, it's only conflicting if it's the same hostname and content (ie v=spf1 or dkim)
    return existingRecords.some((existingRecord) => {
        if (!existingRecord.isPreset && !record.isPreset) return false

        return (
            existingRecord.hostname === record.hostname &&
            (existingRecord.content === record.content || (existingRecord.content.startsWith('v=spf1') && record.content.startsWith('v=spf1'))) &&
            doesNotMatchId(existingRecord)
        )
    })
}

/**
 * Adds an "isConflicting" property to each record to indicate if the record is conflicting with existing DNS Records
 */
export const markConflictingRecords = (
    existingRecords: Readonly<Array<DNSRecord>>,
    records: Readonly<Array<DNSRecord>>
): Array<DNSRecord & { isConflicting: boolean }> => {
    const sanitizedRecords = sanitizeRecords(records)
    return sanitizedRecords.map((newRecord) => {
        const isConflicting = isConflictingRecord(existingRecords, newRecord, { ignoreIsPreset: true })

        return {
            ...newRecord,
            isConflicting
        }
    })
}

type TGetRemovingRecords = (props: {
    formValues: Record<string, string>
    records: Array<DNSRecord> | undefined
    removingRecords?: Array<DNSRecord> | undefined
    existingRecords: Readonly<Array<DNSRecord>>
    domain: string
}) => Array<DNSRecord>

/**
 * Provides a list of records that the frontend will need to remove due to conflicts with existing records (that are only present as a result of input fields being filled in)
 */
export const getNewConflictingRecords: TGetRemovingRecords = ({ formValues, records, removingRecords = [], existingRecords, domain }) => {
    const recordsWithValuesInjected = sanitizeRecords(injectMergeFields(records, formValues))
    const recordsWithDomainAppended = recordsWithValuesInjected
        .map((record) => appendMissingDomains({ domain, record }))
        .map((record) => removeFloatingDots(record)) // remove floating dots
        .filter(Boolean) as Array<DNSRecord> // append domain name
    const conflictingRecords = markConflictingRecords(existingRecords, recordsWithDomainAppended)

    // get the conflictingRecords that are not already in removingRecords
    const newConflictingRecords = conflictingRecords
        .filter(({ isConflicting }) => isConflicting)
        .map((record) => markConflictingRecords(removingRecords, [record])[0]) //get records that conflict with removing records
        .filter(({ isConflicting }) => !isConflicting) //only keep records that are not already in the list of removing records

    return newConflictingRecords
}

/**
 * provides a list of records that will be removed based on the formValues, records, and existingRecords.
 */
export const getRemovingRecords: TGetRemovingRecords = ({ formValues, records, removingRecords = [], existingRecords, domain }) => {
    const newConflictingRecords = getNewConflictingRecords({ formValues, records, removingRecords, existingRecords, domain })

    return [...removingRecords, ...newConflictingRecords]
}

/**
 * Applies a transform that removes the record if it already exists in the records array
 */
const ignoreDuplicateRecords: TInjectRecordTransform = ({ record, records }) => {
    if (!record) return record
    if (isConflictingRecord(records, record, { ignoreIsPreset: false })) return undefined

    return record
}

const insertRecord: TInsertRecord = ({ domain, record, records, transforms, meta }) => {
    const { isPreset = false } = meta ?? {}

    let appliedTransforms = { ...record, isPreset } as TPresetRecord | undefined

    transforms?.forEach((transform) => {
        if (!appliedTransforms) return

        appliedTransforms = transform({ domain, record: appliedTransforms, records })
    })

    if (appliedTransforms) records.push(appliedTransforms)
}

/**
 * Returns a new array of records with the preset applied
 *
 * @param transforms - A list of functions that will be applied to the records after the preset has been applied
 */
export const getRecordsWithPresetApplied = ({
    preset,
    records,
    transforms = [],
    domain
}: {
    preset: PresetResponse | undefined
    records: Array<DNSRecord>
    transforms?: Array<(records: Array<DNSRecord>) => Array<DNSRecord>>
    domain?: string
}) => {
    if (!preset) return records
    if (!domain) return records

    let appliedPresets = [...records].map((record) => ({ ...record, isPreset: false })) as Array<TPresetRecord>

    preset?.records_to_overwrite?.forEach((record) => {
        ejectRecord(record, appliedPresets)
    })

    preset?.config?.forEach((record) => {
        insertRecord({
            domain,
            record,
            records: appliedPresets,
            meta: { isPreset: true },
            transforms: [appendMissingDomains, injectMissingTTL, ignoreDuplicateRecords]
        })
    })

    transforms?.forEach((transform) => {
        appliedPresets = transform(appliedPresets)
    })

    return appliedPresets
}

/** Removes floating white space from input fields */
export const sanitizeFields = (fields: Record<string, string>) => {
    return Object.fromEntries(
        Object.entries(fields).map(([key, value]) => {
            return [key, removeFloatingWhitespaceFromString(value)]
        })
    )
}

/**
 * Helper function to sanitize white space and dots
 */
export const sanitizeRecords = (records: Readonly<Array<DNSRecord>>) => {
    return records.map((record) => {
        const rest = {} as Record<string, string>
        if (record.content) {
            rest.content = removeFloatingWhitespaceFromString(removeFloatingDotFromString(record.content))
        }
        if (record.hostname) {
            rest.hostname = removeFloatingWhitespaceFromString(removeFloatingDotFromString(record.hostname))
        }

        return {
            ...record,
            ...rest
        } as DNSRecord
    })
}
