/* eslint-disable indent */
/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import styled, { css } from 'styled-components'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { RenderErrorMessages, Style } from '../util'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import * as Theme from '../../../theme/theme'

export const Radio = {
    Base: styled.div`
        display: flex;
        flex: 1 1 0%;
        align-items: center;
        margin-bottom: 15px;
    `,
    Label: styled.label`
        ${Style.Toggle.Label};
        font-size: 1.2rem;
        font-weight: 500;
        user-select: none;
    `,
    Extended: styled.div``,
    Text: styled.div`
        width: 100%;
        word-break: break-word;
    `,
    Input: styled.input`
        position: relative;
        top: 2px;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        background-origin: border-box;
        user-select: none;
        flex-shrink: 0;
        appearance: none;
        height: 16px;
        width: 16px;
        padding: 0;
        margin-left: 16px;
        text-align: start;
        overflow: hidden;
        border-radius: 9999px;
        border-width: 1px;
        ${Theme.Functions.animation(`all`)};

        &:focus {
            outline: none;
        }

        ${({ color, disabled, loading }) => {
            if (disabled || loading) {
                return css`
                    cursor: default;
                    border-color: ${(props) => props.theme[`gray--100`]};
                    background-color: ${(props) => props.theme[`background--100`]};
                `
            }

            switch (color) {
                case 'error':
                    return css`
                        border-color: ${(props) => props.theme[`border--100`]};
                        background-color: ${(props) => props.theme[`white--100`]};

                        &:hover {
                            border-width: 4px;
                            background-color: ${(props) => props.theme[`background--100`]};
                        }

                        &:checked {
                            border-width: 4px;
                            border-color: ${(props) => props.theme[`error--100`]};
                            background-color: ${(props) => props.theme[`white--100`]};
                        }

                        &:checked:hover {
                            border-width: 4px;
                            border-color: ${(props) => props.theme[`error--100`]};
                            background-color: ${(props) => props.theme[`white--100`]};
                        }

                        &:checked:focus {
                            outline: none;
                            border-color: ${(props) => props.theme[`error--100`]};
                            background-color: ${(props) => props.theme[`white--100`]};
                        }
                    `

                case 'primary':
                default:
                    return css`
                        border-color: ${(props) => props.theme[`border--100`]};
                        background-color: ${(props) => props.theme[`white--100`]};

                        &:hover {
                            border-width: 4px;
                            background-color: ${(props) => props.theme[`background--100`]};
                        }

                        &:checked {
                            border-width: 4px;
                            border-color: ${(props) => props.theme[`primary--100`]};
                            background-color: ${(props) => props.theme[`white--100`]};
                        }

                        &:checked:hover {
                            border-width: 4px;
                            border-color: ${(props) => props.theme[`primary--100`]};
                            background-color: ${(props) => props.theme[`white--100`]};
                        }

                        &:checked:focus {
                            outline: none;
                            border-color: ${(props) => props.theme[`primary--100`]};
                            background-color: ${(props) => props.theme[`white--100`]};
                        }
                    `
            }
        }}
    `
}

/**********************************************************************************************************
 *   RADIO FIELD
 **********************************************************************************************************/
export const RadioField = ({ field, label, description, color, disabled, form: { touched, errors, isSubmitting }, ...props }) => {
    return (
        <Radio.Base>
            <Radio.Label
                disabled={disabled}
                color={color}
                checked={field.checked}
                className={isSubmitting || disabled ? 'disabled' : ''}
                htmlFor={field.id}
            >
                <Radio.Text>{label}</Radio.Text>
                <Radio.Input {...field} id={props.id} value={field.value} checked={field.checked} color={color} disabled={disabled} type='radio' />
            </Radio.Label>
            {RenderErrorMessages(field.name, touched, errors)}
        </Radio.Base>
    )
}
