/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { createSlice } from '@reduxjs/toolkit'
import { accountAPI } from 'api/account'
import { TTwoFactorType } from 'models/authentication'

/**********************************************************************************************************
 *    SLICE
 **********************************************************************************************************/
type Status = 'idle' | 'loading' | 'success' | 'error'

interface AccountState {
    twoFactor: {
        status: Status
        identifier?: string
        type?: TTwoFactorType
        setup: {
            secret: string
        }
    }
    emailLog: {
        id: string
        subject: string
        parseDate: string
    }
}

const initialState: AccountState = {
    twoFactor: {
        status: 'idle',
        identifier: '',
        type: undefined,
        setup: {
            secret: ''
        }
    },
    emailLog: {
        id: '',
        subject: '',
        parseDate: ''
    }
}

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setEmailLog: (state, { payload }) => {
            state.emailLog = payload
        },
        setTwoFactor: (state, { payload }) => {
            state.twoFactor = payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(accountAPI.endpoints.createTwoFactor.matchPending, (state) => {
                state.twoFactor = { status: 'loading', identifier: '', type: undefined, setup: { secret: '' } }
            })
            .addMatcher(
                accountAPI.endpoints.createTwoFactor.matchFulfilled,
                (
                    state,
                    {
                        payload: setup,
                        meta: {
                            arg: {
                                originalArgs: { identifier, type }
                            }
                        }
                    }
                ) => {
                    state.twoFactor = { status: 'success', identifier, type, setup }
                }
            )
            .addMatcher(accountAPI.endpoints.createTwoFactor.matchRejected, (state) => {
                state.twoFactor = { status: 'error', identifier: '', type: undefined, setup: { secret: '' } }
            })
    }
})

export const { setEmailLog, setTwoFactor } = accountSlice.actions

export default accountSlice.reducer
