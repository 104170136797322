/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useField } from 'formik'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { FieldErrorMessageStyles as S } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TFieldErrorMessage = React.FC<{
    name: string
    id?: string
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const FieldErrorMessage: TFieldErrorMessage = ({ name, id = name }) => {
    /***** HOOKS *****/
    const [, { error, touched }] = useField(name)

    /***** RENDER *****/
    return (
        <S.Message id={`${id}-error`} visible={!!touched && !!error}>
            {error}
        </S.Message>
    )
}
