/* eslint-disable indent */
/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import * as Theme from '../../theme/theme'

/**********************************************************************************************************
 *   COMPONENT IMPORTS
 **********************************************************************************************************/
import { Loader } from '../loader'

/**********************************************************************************************************
 *   STYLE
 **********************************************************************************************************/
const StyledButton = styled.button`
    display: inline-flex;
    align-items: center;
    width: fit-content;
    white-space: nowrap;
    border-radius: 8px;
    background-size: cover;
    font-size: 1.4rem;
    text-decoration: none;
    letter-spacing: -0.25px;
    line-height: 1;
    ${Theme.Functions.setFont('Inter', 500)};
    ${Theme.Functions.animation(`all`)};

    .icon {
        width: 16px;
        height: 16px;
        margin: 0 6px 0 -4px;
        ${Theme.Functions.animation('all')};
    }

    ${({ size }) => {
        switch (size) {
            case 'small':
                return css`
                    height: 32px;
                    padding: 0px 10px;
                `

            case 'large':
                return css`
                    height: 44px;
                    padding: 0px 24px;
                `

            default:
                return css`
                    height: 38px;
                    padding: 0px 16px;
                `
        }
    }}

    ${({ color, disabled, loading }) => {
        if (disabled || loading) {
            return css`
                cursor: default;
                color: ${(props) => props.theme['text--100']};
                background-color: ${(props) => props.theme['gray--100']};

                .icon {
                    color: ${(props) => props.theme[`icon--300`]};
                }
            `
        } else {
            switch (color) {
                case 'primary':
                    return css`
                        color: ${(props) => props.theme['white--100']};
                        background-color: ${(props) => props.theme['primary--100']};

                        &:hover {
                            background-color: ${(props) => props.theme['primary--200']};
                        }

                        .icon {
                            color: ${(props) => props.theme['white--100']};
                        }
                    `

                case 'error':
                    return css`
                        color: ${(props) => props.theme['white--100']};
                        background-color: ${(props) => props.theme['error--100']};

                        &:hover {
                            background-color: ${(props) => props.theme['error--200']};
                        }

                        .icon {
                            color: ${(props) => props.theme['white--100']};
                        }
                    `

                case 'flat':
                    return css`
                        color: ${(props) => props.theme['text--100']};
                        background-color: ${(props) => props.theme['secondary--100']};

                        &:hover {
                            color: ${(props) => props.theme['text--300']};
                            background-color: ${(props) => props.theme['secondary--200']};

                            .icon {
                                color: ${(props) => props.theme['text--300']};
                            }
                        }

                        .icon {
                            color: ${(props) => props.theme['icon--300']};
                        }
                    `

                case 'secondary':
                default:
                    return css`
                        color: ${(props) => props.theme['text--200']};
                        background-color: ${(props) => props.theme['secondary--100']};
                        border: 1px solid ${(props) => props.theme['border--100']};

                        &:hover {
                            background-color: ${(props) => props.theme['secondary--200']};
                        }

                        .icon {
                            color: ${(props) => props.theme['icon--300']};
                        }
                    `
            }
        }
    }}
`

const LongFormButton = {
    Button: styled.button`
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        text-align: left;
        margin-bottom: 10px;
        text-decoration: none;
        letter-spacing: -0.25px;
        background-color: ${(props) => props.theme['white--100']};
        border: 1px solid ${(props) => props.theme['border--100']};
        border-radius: 8px;
        ${Theme.Functions.setFont('Inter', 500)};
        ${Theme.Functions.animation('all')};

        &:hover {
            background-color: ${(props) => props.theme['background--100']};
        }

        &.disabled {
            cursor: default;
            background-color: ${(props) => props.theme['background--200']};
        }
    `,
    Loading: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin: 0 6px 0 6px;
    `,
    Title: styled.div`
        padding: 15px 20px;
    `,
    Label: styled.h3`
        font-size: 1.4rem;
        color: ${(props) => props.theme['text--300']};
    `,
    Description: styled.p`
        font-size: 1.2rem;
        color: ${(props) => props.theme['text--100']};
        ${Theme.Functions.setFont('Inter', 500)};
    `
}

export const Button = ({ children, icon, onClick, className, loading, disabled, ...rest }) => {
    function renderButtonContent() {
        if (loading) return <Loader.Basic message={loading ? loading : children} />

        return (
            <>
                {icon && <div className={`icon`}>{icon}</div>}
                {children}
            </>
        )
    }

    return (
        <StyledButton
            {...rest}
            className={className ? ` ${className}` : ''}
            disabled={disabled}
            onClick={
                disabled || loading
                    ? (e) => {
                          e.preventDefault()
                      }
                    : onClick
            }
        >
            {renderButtonContent()}
        </StyledButton>
    )
}

export const LFB = ({ label, description, icon, onClick, loading, disabled }) => {
    //Long Form Button
    return (
        <LongFormButton.Button
            type={'button'}
            className={(disabled || loading) && 'disabled'}
            onClick={
                disabled || loading
                    ? (e) => {
                          e.preventDefault()
                      }
                    : onClick
            }
        >
            <LongFormButton.Title>
                <LongFormButton.Label>{label}</LongFormButton.Label>
                <LongFormButton.Description>{description}</LongFormButton.Description>
            </LongFormButton.Title>
            {loading && (
                <LongFormButton.Loading>
                    <Loader.Basic />
                </LongFormButton.Loading>
            )}
        </LongFormButton.Button>
    )
}

/*****************************************************
 *   BUTTON GROUPS                                    *
 *****************************************************/
const StyledButtonGroup = styled.span`
    position: relative;
    z-index: 0;
    display: inline-flex;
    border-radius: 6px;
`

const StyledButtonStart = styled(StyledButton)`
    margin-right: 0;
    margin-left: -1px;
    border-radius: 6px 0 0 6px;
`

const StyledButtonCenter = styled(StyledButton)`
    margin-right: 0;
    margin-left: -1px;
    border-radius: 0;
`

const StyledButtonEnd = styled(StyledButton)`
    margin-right: 0;
    margin-left: -1px;
    border-radius: 0 6px 6px 0;
`

export const ButtonGroup = ({ className, color, list }) => {
    function getButtonToRender(data, index) {
        switch (index) {
            case 0:
                return (
                    <StyledButtonStart as={Button} key={`button-${index}`} {...data} color={color ? color : data.color}>
                        {data.label}
                    </StyledButtonStart>
                )
            case list.length - 1:
                return (
                    <StyledButtonEnd as={Button} key={`button-${index}`} {...data} color={color ? color : data.color}>
                        {data.label}
                    </StyledButtonEnd>
                )
            default:
                return (
                    <StyledButtonCenter as={Button} key={`button-${index}`} {...data} color={color ? color : data.color}>
                        {data.label}
                    </StyledButtonCenter>
                )
        }
    }

    return (
        <StyledButtonGroup className={className ? ` ${className}` : ``}>
            {list.map((data, index) => {
                return getButtonToRender(data, index)
            })}
        </StyledButtonGroup>
    )
}

/**********************************************************************************************************
 *   PROP TYPES & DEFAULTS
 **********************************************************************************************************/
Button.propTypes = {
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    onClick: PropTypes.func,
    className: PropTypes.string,
    icon: PropTypes.element,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    color: PropTypes.oneOf(['primary', 'secondary', 'error', 'flat'])
}

ButtonGroup.propTypes = {
    className: PropTypes.string,
    list: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.shape({
                type: PropTypes.string,
                className: PropTypes.string,
                color: PropTypes.string,
                icon: PropTypes.element,
                label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
                onClick: PropTypes.func.isRequired
            })
        ])
    )
}
