/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Formik } from 'formik'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useResetPasswordMutation } from 'api/authentication'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { regex } from 'helpers/utils'

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
import { Auth } from 'containers/authentication/authentication.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function AuthResetForm() {
    const navigate = useNavigate()
    const { token } = useParams()
    const [resetPassword] = useResetPasswordMutation()

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Formik
            initialValues={{
                token,
                email: '',
                password: '',
                password_confirmation: ''
            }}
            validationSchema={Yup.object({
                email: Yup.string().email('Invalid email address').required('Required'),
                password: Yup.string()
                    .required('Required')
                    .matches(regex.password, 'Password must contain at least 8 characters, one uppercase, one number and one special case character'),
                password_confirmation: Yup.string()
                    .oneOf([Yup.ref('password')], 'Password must match')
                    .required('Required')
            })}
            onSubmit={async ({ token, email, password, password_confirmation }, { resetForm }) => {
                try {
                    const success = {
                        detail: `Your password has been successfully reset.`,
                        type: 'SUCCESS'
                    }

                    await resetPassword({ token, email, password, password_confirmation }).unwrap()
                    // @ts-ignore
                    toast.custom(success, {
                        duration: 5000
                    })
                    navigate('/login')
                } catch (error) {
                    resetForm()
                }
            }}
        >
            {({ isSubmitting, isValid }) => {
                return (
                    <Auth.Wrapper>
                        <Auth.Title>Password Reset</Auth.Title>
                        <Auth.Description>Enter your email address below to begin the password recovery process.</Auth.Description>
                        <Auth.Form>
                            <Field
                                id={'email'}
                                label={'Email address'}
                                name={'email'}
                                type={'text'}
                                autoComplete={'username'}
                                component={NXUIForm.InputField}
                            />
                            <Field
                                id={'password'}
                                label={'Password'}
                                name={'password'}
                                type={'password'}
                                autoComplete={'current-password'}
                                component={NXUIForm.InputField}
                            />
                            <Field
                                id={'confirmPassword'}
                                label={'Confirm Password'}
                                name={'password_confirmation'}
                                type={'password'}
                                autoComplete={'current-password'}
                                component={NXUIForm.InputField}
                            />
                            <Auth.Submit
                                color={'primary'}
                                type={'submit'}
                                disabled={!isValid || isSubmitting}
                                loading={isSubmitting ? 'Resetting...' : false}
                            >
                                Reset Password
                            </Auth.Submit>
                        </Auth.Form>
                        <Auth.Border />
                        <Auth.Footer>
                            <Auth.Link to={'/login'}>Back to Login</Auth.Link>
                        </Auth.Footer>
                    </Auth.Wrapper>
                )
            }}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
