import { Landing } from 'containers/shop/user/user.styles'
import { useNavigate } from 'react-router-dom'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function UserLandingComponent() {
    const navigate = useNavigate()

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Landing.Base>
            <Landing.Existing.Base>
                <Landing.Info.Base>
                    <Landing.Existing.Icon />
                    <Landing.Info.Title>Existing Users</Landing.Info.Title>
                    <Landing.Info.Description>If you already have an account, please login to continue.</Landing.Info.Description>
                </Landing.Info.Base>
                <Landing.Info.Action color={'primary'} onClick={() => navigate('/login?ref=checkout')}>
                    Login
                </Landing.Info.Action>
            </Landing.Existing.Base>
            <Landing.New.Base>
                <Landing.Info.Base>
                    <Landing.New.Icon />
                    <Landing.Info.Title>New Users</Landing.Info.Title>
                    <Landing.Info.Description>If you're a new user, please sign up to continue.</Landing.Info.Description>
                </Landing.Info.Base>
                <Landing.Info.Action onClick={() => navigate('/login/signup?ref=checkout')}>Sign Up</Landing.Info.Action>
            </Landing.New.Base>
        </Landing.Base>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { UserLandingComponent as UserLanding }

