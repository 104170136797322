/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useFormikContext } from 'formik'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { domainAPI } from 'api/domain'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { TEmailForwardingFormValues } from '.'
import { forwardingStyles as S } from '../_forwarding.styles'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TDomainForwarderPreviewRow = React.FC<{ outsideTable?: boolean }>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const EmailForwarderPreviewRow: TDomainForwarderPreviewRow = ({ outsideTable = false }) => {
    /***** HOOKS *****/
    const { id } = useParams()
    const { values } = useFormikContext<TEmailForwardingFormValues>()

    /***** QUERIES *****/
    const { data: domainData } = domainAPI.endpoints.domain.useQueryState(Number(id))

    /***** RENDER HELPERS *****/
    const destination = <S.Boldened>{values.destination}</S.Boldened>
    const name = (
        <span>
            {values.catch_all ? (
                <span>
                    <S.Boldened>any email</S.Boldened> on the domain{' '}
                </span>
            ) : (
                <S.Boldened>{values.source}</S.Boldened>
            )}
            <S.Boldened>@{domainData?.domain}</S.Boldened>
        </span>
    )

    /***** RENDER *****/
    return (
        <S.Preview outsideTable={outsideTable}>
            <span>
                Emails sent to {name} will be forwarded to {destination}
            </span>
        </S.Preview>
    )
}
