/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import { Cart } from './cart.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const CartSummary = (props) => {
    function getProductTotal() {
        let total = 0.0
        if (props.products) {
            props.products.forEach((product) => {
                total = total + product.price
            })
        }
        return total.toFixed(2)
    }

    /*   RENDER
     ****************************************************/
    return (
        <Cart.Summary.Base className={props.className}>
            <Cart.Summary.Total>
                <h2>Total</h2>
                <p>${getProductTotal()} AUD</p>
            </Cart.Summary.Total>
            <Cart.Summary.Description>
                This total is before any deductions or discounts. Continue to checkout to add any promotional discount codes, or use account credit.
            </Cart.Summary.Description>
        </Cart.Summary.Base>
    )
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
CartSummary.defaultProps = {
    className: undefined
}
