export const Theme = {
    'breakpoints-sm': 640,
    'breakpoints-md': 768,
    'breakpoints-lg': 1024,
    'breakpoints-xl': 1280,
    'breakpoints-2xl': 1536,
    'breakpoints-3xl': 2160,
    transparent: 'transparent',
    'primary--100': '#4f46e5',
    'primary--200': '#3739B8',
    'primary--300': '#0A1E42',
    'secondary--100': '#FFFFFF',
    'secondary--200': '#F9FAFB',
    'highlight--100': '#22D3EE',
    'confirm--100': '#4caf50',
    'confirm--200': '#418344',
    'warning--100': '#FFB812',
    'warning--200': '#C25D04',
    'error--100': '#FF4242',
    'error--200': '#e42a2a',
    'info--100': '#3381FF',
    'info--200': '#0F46BD',
    'background--000': '#FFFFFF',
    'background--100': '#F9FAFB',
    'background--200': '#F3F4F6',
    'background--300': '#E5E7EB',
    'background--400': '#374151',
    'border--000': '#E5E7EB',
    'border--100': '#D1D5DB',
    'border--200': '#111827',
    'icon--100': '#9CA3AF',
    'icon--200': '#A0A6B2',
    'icon--300': '#6B7280',
    'text--100': '#9CA3AF',
    'text--200': '#6B7280',
    'text--300': '#111827',
    'white--100': '#FFFFFF',
    'black--100': '#111827',
    'black--200': '#000000',
    'gray--100': '#E5E7EB',
    'gray--200': '#D1D5DB',
    'gradient-primary--100': '#4f46e5',
    'gradient-primary--200': '#6875f5'
}
