/**********************************************************************************************************
 *   UTILITIES
**********************************************************************************************************/
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { Footer } from 'containers/website/components/footer'
import { WebsiteEndpointRenderer } from '../components/markdown'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { Flex } from '../../../components/utilities/Flex'
import { AboutUsStyles as S } from './style'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const AboutUs = () => {
    /***** HOOKS *****/
    const { website } = useAppSelector((state) => state.app.appSettings)

    /***** RENDER HELPERS *****/
    const html = typeof website?.about_us === 'string' ? website?.about_us : ''

    /***** RENDER *****/
    return (
        <S.Container>
            <Flex direction='column' fullHeight growChild={[1, 1]} justify='space-between'>
                <WebsiteEndpointRenderer title='About Us' html={html} />
                <div>
                    <S.WebsiteDomainFeatures />
                    <Footer />
                </div>
            </Flex>
        </S.Container>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
