/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import Yup from 'helpers/yup'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   STORE IMPORTS
 **********************************************************************************************************/
import { Field, Formik, useFormikContext } from 'formik'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { toggleAddingDomainRedirect } from 'store/slices/domainSlice'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/utilities/Flex'
import { Button, Combobox, Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { DomainForwarderPreviewRow } from './domainForwarder/previewRow'

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { domainAPI } from 'api/domain'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { regex } from 'helpers/utils'
import { forwardingStyles as S } from './_forwarding.styles'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export type TInitialValues = {
    type: ValueOf<typeof recordTypes>
    web_source: string
    web_path: string
    destination: string
    wildcard_source: boolean
    retain_path: boolean
}

/***** STATIC *****/
export const recordTypes = {
    '301': '301 Redirect (Permanent)',
    '302': '302 Redirect (Temporary)'
} as const

export const APITypeMap = {
    P: 'Permanent',
    T: 'Temporary'
}

export const recordTypeAPIMap = {
    [recordTypes[301]]: 'P',
    [recordTypes[302]]: 'T'
} as const

export const APItoRecordTypeMap = {
    P: recordTypes[301],
    T: recordTypes[302]
} as const

export const validationSchema = Yup.object().shape({
    type: Yup.string().oneOf(Object.values(recordTypes)).required('Required'),
    web_source: Yup.string().matches(/^[a-zA-Z0-9-\.]+$/, 'Subdomain can only include letters, numbers, hyphens and dots'),
    wildcard: Yup.boolean(),
    destination: Yup.string()
        .url('Invalid URL, please ensure that the destination matches the format http(s)://example.domain.com/')
        .required('Required'),
    retain_path: Yup.boolean(),
    web_path: Yup.string()
        .matches(/^[a-zA-Z0-9!*'();:@&=+$,/?%#\[\]-_./]+$/, 'The path must only include valid URL characters')
        .matches(/^[^/]/, 'Path cannot start with a slash')
        .when('wildcard', {
            is: (value: unknown) => Boolean(value),
            then: (schema) => schema.notRequired()
        })
})

export const sanitizeDomain = (source: string, domain: string) => {
    if (source.endsWith(`.${domain}`)) {
        return source.replace(`.${domain}`, '')
    }

    if (source.endsWith(`.${domain}.`)) {
        return source.replace(`.${domain}.`, '')
    }

    if (source.endsWith(domain)) {
        return source.replace(domain, '')
    }

    if (source.endsWith('.')) {
        return source.replace(/\.$/, '')
    }

    return source
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const AddDomainForwarderForm = () => {
    /***** HOOKS *****/
    const { id } = useParams()
    const { addingDomainRedirect } = useAppSelector(({ domain }) => domain)
    const dispatch = useAppDispatch()

    /***** QUERIES *****/
    const { data: domainData } = domainAPI.endpoints.domain.useQueryState(Number(id))
    const [createDomainRedirect, { isLoading: isCreatingDomainRedirect }] = domainAPI.endpoints.createDomainRedirect.useMutation({
        fixedCacheKey: 'create-domain-redirect'
    })
    const [, { isLoading: isUpdatingDomainRedirect }] = domainAPI.endpoints.updateDomainRedirect.useMutation({
        fixedCacheKey: 'update-domain-redirect'
    })

    /***** RENDER *****/
    if (!addingDomainRedirect) {
        return null
    }

    return (
        <Formik<TInitialValues>
            validationSchema={validationSchema}
            initialValues={{
                type: recordTypes[301] as ValueOf<typeof recordTypes>,
                web_source: '',
                web_path: '',
                destination: '',
                wildcard_source: false, //wildcard path
                retain_path: false
            }}
            onSubmit={async ({ type, ...values }, { setSubmitting, setFieldError }) => {
                if (!domainData) {
                    throw new Error('Something went wrong, please try again')
                }

                const { domain } = domainData
                const { wildcard_source, web_path, web_source } = values
                const santized = sanitizeDomain(web_source, domain)

                if (!!santized && !regex.domainWithExtension.test(`${santized}.domain.com`)) {
                    return setFieldError('web_source', 'Invalid domain name')
                }

                await createDomainRedirect({
                    ...values,
                    web_source: santized,
                    web_path: wildcard_source ? '' : web_path,
                    type: recordTypeAPIMap[type],
                    domainId: Number(id)
                })
                    .unwrap()
                    .then(() => dispatch(toggleAddingDomainRedirect()))
                    .catch(() => setSubmitting(false))
            }}
        >
            {({ handleSubmit }) => (
                <S.Domain.AddForm.Wrapper>
                    <DomainForwarderPreviewRow outsideTable />
                    <InnerDomainForwardingForm />
                    <S.Domain.AddForm.ActionWrapper>
                        <Button
                            disabled={isUpdatingDomainRedirect || isCreatingDomainRedirect}
                            color='secondary'
                            onClick={() => dispatch(toggleAddingDomainRedirect())}
                        >
                            Cancel
                        </Button>
                        <Button disabled={isUpdatingDomainRedirect || isCreatingDomainRedirect} color='primary' onClick={handleSubmit}>
                            Add
                        </Button>
                    </S.Domain.AddForm.ActionWrapper>
                </S.Domain.AddForm.Wrapper>
            )}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const InnerDomainForwardingForm = ({ innerRef }: { innerRef?: React.RefObject<HTMLFormElement> }) => {
    /***** HOOKS *****/
    const { id } = useParams()
    const { handleSubmit, values, isSubmitting, setValues } = useFormikContext<TInitialValues>()

    /***** QUERIES *****/
    const { data: domainData } = domainAPI.endpoints.domain.useQueryState(Number(id))

    /***** EFFECTS *****/
    useEffect(() => {
        if (values.web_path === '*' && !values.wildcard_source) {
            setValues((values) => ({ ...values, web_path: '' }))
        }
    }, [values.wildcard_source])

    /***** RENDER *****/
    return (
        <S.AddForwarderForm ref={innerRef} onSubmit={handleSubmit}>
            <Flex direction='column' fullWidth growChild={[1, 1]}>
                <Flex gap={10} fullWidth wrap='wrap' growChild={[2, 1]}>
                    <Field
                        component={Combobox}
                        disabled={isSubmitting}
                        name='type'
                        id='type'
                        label='Record Type'
                        options={Object.values(recordTypes)}
                    />
                    <Flex wrap='nowrap' gap={5} growChild={[1, 1]}>
                        <S.InputField
                            label='Source'
                            name='web_source'
                            permanentPlaceholder={<S.PermanentPlaceholder>.{domainData?.domain}</S.PermanentPlaceholder>}
                        />

                        <S.Domain.Inner.Divider> / </S.Domain.Inner.Divider>
                        {!values.wildcard_source ? (
                            <Field
                                component={NXUIForm.InputField}
                                name='web_path'
                                placeholder={values.wildcard_source && '*'}
                                label='Path'
                                disabled={values.wildcard_source}
                            />
                        ) : (
                            <NXUIForm.InputField label='Path' field={{ onBlur: () => void 0 }} form={{ touched: {} }} disabled value='*' />
                        )}
                        <S.Domain.CheckboxFieldWrapper>
                            <Field component={NXUIForm.CheckboxField} type='checkbox' name='wildcard_source' label='Wildcard' />
                        </S.Domain.CheckboxFieldWrapper>
                    </Flex>
                </Flex>

                <Flex columnGap={5}>
                    <Flex fullWidth growChild={[1, 1]}>
                        <Field
                            component={NXUIForm.InputField}
                            type='url'
                            name='destination'
                            placeholder='https://destination.com'
                            label='Destination'
                        />
                    </Flex>

                    <S.Domain.CheckboxFieldWrapper>
                        <Field component={NXUIForm.CheckboxField} type='checkbox' name='retain_path' label='Retain Path?' />
                    </S.Domain.CheckboxFieldWrapper>
                </Flex>
            </Flex>
            <S.InvisibleSubmitButton />
        </S.AddForwarderForm>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
