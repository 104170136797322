/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { motion, AnimatePresence } from 'framer-motion'

/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
const Dropdown = {
    Content: styled(motion.div)`
        position: relative;
        display: block;
    `
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DropdownContent = (props) => {
    /*   RENDER
     ****************************************************/
    return (
        <AnimatePresence>
            {props.open && (
                <Dropdown.Content
                    tabIndex={0}
                    initial={{ opacity: 0, translateY: -5 }}
                    animate={{ opacity: 1, translateY: 0 }}
                    exit={{ opacity: 0, translateY: -5 }}
                    transition={{ duration: 0.1 }}
                    {...props.layerProps}
                >
                    {props.children}
                </Dropdown.Content>
            )}
        </AnimatePresence>
    )
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
DropdownContent.defaultProps = {
    className: undefined,
    open: false,
    layerProps: undefined,
    tabIndex: 0
}
