/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled, { css } from 'styled-components';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { FieldStyles as S } from '../shared.style';

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
const Wrapper = styled.div`
    ${S.Wrapper}
`

const Column = styled.div<{ disabled: boolean; error: boolean }>`
    ${S.Column}
    background: white;

    ${({ disabled }) => {
        return (
            disabled &&
            css`
                background-color: ${({ theme }) => theme[`background--200`]};
            `
        )
    }}
`

const Base = styled.input`
    ${S.Field}

    width: 40px; //ensures that the input field can shrink to 40px minimum

    ::-webkit-calendar-picker-indicator {
        display: none;
    }
`

const PermanentPlaceholder = styled.div`
    padding: 11px;
    font-size: 12px;
    padding-left: 0;
    font-weight: 500;
    padding-bottom: 9px;
    user-select: none;
    pointer-events: none;

    color: ${({ theme }) => theme['text--100']};
`
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/

/***** EXPORTS *****/
export const InputFieldStyles = {
    Wrapper,
    Column,
    Base,
    PermanentPlaceholder
}
