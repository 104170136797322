/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Theme } from 'nxui/src'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/utilities/Flex'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { WebsiteStyles } from 'containers/website/style'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
export const FeatureStyles = {
    Banner: styled(Flex).attrs({ align: 'center', justify: 'center' })`
        ${WebsiteStyles.Helpers.Banner}
        background-color: ${({ theme }) => Theme.Functions.hexToRGBA(theme['primary--100'], '0.1')};
    `,

    Heading: styled.h2`
        ${WebsiteStyles.Helpers.Fonts.h2}

        text-align: center;
        color: ${({ theme }) => theme['primary--100']};
    `,

    FlexContainer: styled(Flex)`
        margin-top: 36px;
    `,

    BannerInner: WebsiteStyles.Components.BannerInner
}
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/
