/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

/**********************************************************************************************************
 *   SHARED COMPONENTS
 **********************************************************************************************************/
import { LFB } from 'nxui/src'

/**********************************************************************************************************
 *   HOOKS
 **********************************************************************************************************/
import { useAppDispatch, useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { authAPI } from 'api/authentication'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setRequestedMethod } from 'store/slices/appSlice'

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
import { Auth, TwoFactor } from 'containers/authentication/authentication.styles'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
import { IUserTwoFactorMethod } from 'models/authentication'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function AuthRecoveryForm() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [methodList, setMethodList] = useState<Array<IUserTwoFactorMethod>>([])
    const {
        appAuthentication: {
            twoFactor: { requested, backup: twoFactorBackups }
        }
    } = useAppSelector((state) => state.app)
    const { isFetching } = authAPI.endpoints.selectTwoFactorMethod.useQueryState(requested?.type ?? '')

    function renderMethodLabel(type: string, identifier: string) {
        switch (type) {
            case 'sms':
                return {
                    title: 'Mobile Verification',
                    desc: `Receive an SMS with an authentication code to ${identifier}`
                }

            case 'email':
                return {
                    title: 'Email Verification',
                    desc: `Receive an email with an authentication code to ${identifier}`
                }

            case 'authenticator':
            default:
                return {
                    title: 'App Verification',
                    desc: 'Use your authentication app to receive a code'
                }
        }
    }

    useEffect(() => {
        if (twoFactorBackups && twoFactorBackups.length > 1) {
            if (requested) {
                setMethodList(twoFactorBackups.filter((method) => method.id !== requested.id))
            } else {
                setMethodList(twoFactorBackups.filter((method) => !method.is_primary))
            }
        }
    }, [])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <>
            <Auth.Wrapper>
                <Auth.Title>Two Factor Methods</Auth.Title>
                <Auth.Description>If you&apos;re having trouble with two-factor authentication, here are some alternatives.</Auth.Description>
            </Auth.Wrapper>
            <Auth.Break />
            <TwoFactor.Recovery>
                {methodList.map((method) => {
                    const details = renderMethodLabel(method.type, method.identifier)

                    return (
                        <LFB
                            key={method.type}
                            loading={isFetching}
                            label={details.title}
                            description={details.desc}
                            onClick={() => dispatch(setRequestedMethod(method))}
                        />
                    )
                })}
            </TwoFactor.Recovery>
            <Auth.Break />
            <Auth.Wrapper>
                <Auth.Border />
                <Auth.Footer>
                    <Auth.Back color={'select'} onClick={() => navigate('/login/two-factor')}>
                        Back to Code Verification
                    </Auth.Back>
                </Auth.Footer>
            </Auth.Wrapper>
        </>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
