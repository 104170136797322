/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Anchor } from 'nxui/src'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { defaultCurrency } from 'helpers/utils'

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useChangePlanPreviewMutation } from 'api/service'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Summary as S } from 'containers/shop/shop.styles'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ChangePlanPreview = React.FC<{
    onBack: () => void
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ChangePlanPreview: ChangePlanPreview = ({ onBack }) => {
    /***** QUERIES *****/
    const [, { data: planChangePreviewData }] = useChangePlanPreviewMutation({ fixedCacheKey: 'change-plan-lightbox' })

    /***** RENDER *****/
    if (!planChangePreviewData) return null

    const { amount_due, credit_amount, discount_amount, total_amount_due, overdue_amount } = planChangePreviewData

    return (
        <S.Actions.Base>
            <S.Subtotal>
                <h2>Subtotal</h2>
                <p>{defaultCurrency(amount_due)}</p>
            </S.Subtotal>
            {!!Number(credit_amount) && (
                <S.Subtotal>
                    <h2>Credit amount</h2>
                    <p>{defaultCurrency(credit_amount)}</p>
                </S.Subtotal>
            )}
            {!!Number(overdue_amount) && (
                <S.Subtotal>
                    <h2>Overdue amount</h2>
                    <p>{defaultCurrency(overdue_amount)}</p>
                </S.Subtotal>
            )}
            {!!Number(discount_amount) && (
                <S.Discount>
                    <h2>Discount</h2>
                    <p>{defaultCurrency(discount_amount)}</p>
                </S.Discount>
            )}
            <S.Total>
                <h2>Total Due Today</h2>
                <p>{defaultCurrency(Number(total_amount_due) > 0 ? total_amount_due : '0.00')}</p>
            </S.Total>
            <S.Description>
                <Anchor color='primary' onClick={onBack}>
                    Back to change plan configuration
                </Anchor>
            </S.Description>
        </S.Actions.Base>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
