/* eslint-disable indent */
/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Combobox as Autocomplete, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid'
import { Fragment, useEffect, useState } from 'react'
import styled, { createGlobalStyle, css } from 'styled-components'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import * as Theme from '../../theme/theme'
import { Element, RenderErrorIcon } from '../form/util'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
const GlobalComboboxStyle = createGlobalStyle`
    .combobox {
        &__transition {
            &--enter {
                transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
                transition-duration: 100ms;
                transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
            }
            &--enterFrom {
                --transform-scale-x: .95;
                --transform-scale-y: .95;
                opacity: 0;
            }
            &--enterTo {
                --transform-scale-x: 1;
                --transform-scale-y: 1;
                opacity: 1;
            }
            &--leave {
                transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
                transition-duration: 100ms;
                transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
            }
            &--leaveFrom {
                opacity: 1;
            }
            &--leaveTo {
                opacity: 0;
            }
        }
    }
`

const Combobox = {
    Wrapper: styled.div`
        position: relative;
        display: block;
        margin-bottom: 8px;

        ${(props) =>
            props.embedded &&
            css`
                margin-bottom: 0;
            `}
    `,
    Container: styled.div`
        position: relative;
        margin-top: 0.25rem;
    `,
    Action: styled.div`
        position: relative;
        display: inline-flex;
        flex-direction: row;
        width: 100%;
        border-radius: 8px;
        border: 1px solid ${(props) => props.theme['border--100']};
        ${Theme.Functions.animation('border')};

        &:focus-within {
            outline: none;
            border-color: ${(props) => props.theme['border--200']};
        }
    `,
    Input: styled(Autocomplete.Input)`
        position: relative;
        cursor: auto;
        display: inline-flex;
        flex: 1 1 0%;
        min-width: 0px;
        width: 100%;
        height: 38px;
        padding: 8px 12px;
        background-color: ${(props) => props.theme['background--000']};
        outline: 0;
        font-size: 1.2rem;
        color: ${(props) => props.theme['text--200']};
        border-radius: 8px;
        ${Theme.Functions.normalizeText()};
        ${Theme.Functions.setFont('Inter', 500)};

        &:disabled {
            color: ${(props) => props.theme['text--100']};
            background-color: ${(props) => props.theme['background--200']};
            border-color: transparent;
        }
    `,
    Button: styled(Autocomplete.Button)`
        display: flex;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        padding-right: 0.5rem;
        align-items: center;
    `,
    WrapperButton: styled(Autocomplete.Button)`
        width: 100%;
    `,
    SelectorIcon: styled(ChevronUpDownIcon)`
        color: ${(props) => props.theme['text--100']};
        width: 1.25rem;
        height: 1.25rem;
    `,
    Options: styled(Autocomplete.Options)`
        overflow: auto;
        position: absolute;
        padding: 1rem 0;
        margin-top: 0.25rem;
        background-color: ${(props) => props.theme['background--000']};
        font-size: 1.2rem;
        line-height: 1.5rem;
        width: 100%;
        max-height: 450px;
        z-index: 6;
        border-radius: 8px;
        box-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        --ring-color: #000000;
        --ring-opacity: 0.05;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);

        @media (min-width: 640px) {
            font-size: 1rem;
            line-height: 1.25rem;
        }

        .option {
            position: relative;
            padding: 1rem 1.5rem 1rem 3rem;
            cursor: default;
            user-select: none;
        }

        .active {
            color: ${(props) => props.theme['white--100']};
            background-color: ${(props) => props.theme['primary--100']};
        }

        .inactive {
            color: ${(props) => props.theme['text--200']};
        }
    `,
    Option: styled(Autocomplete.Option)`
        position: relative;
        padding: 1rem 1.5rem 1rem 3rem;
        cursor: default;
        user-select: none;
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: ${(props) => props.theme['text--200']};
    `,
    NotFound: styled.div`
        position: relative;
        padding: 0.5rem 1rem 0.5rem 1rem;
        color: ${(props) => props.theme['text--200']};
        ${Theme.Functions.setFont('Inter', 500)};
        cursor: default;
        user-select: none;
        font-size: 1.2rem;
    `,
    Text: styled.span`
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 1.2rem;
        font-weight: ${(props) => (props.selected ? 500 : 400)};
    `,
    Select: styled.span`
        display: flex;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        padding-left: 0.75rem;
        align-items: center;
        color: ${(props) => (props.active ? props.theme['white--100'] : props.theme['primary--100'])};
    `,
    CheckIcon: styled(CheckIcon)`
        width: 1.25rem;
        height: 1.25rem;
    `
}

const getFormValueFromField = (form, field) => {
    const { values } = form
    const { name } = field

    if (!values) return null

    if (typeof values !== 'object') return null

    //should not directly be an array - likely not a Formik Form
    if (Array.isArray(values)) return null

    //check if the field exists at the top level
    if (values[name]) return values[name]

    //nested object, the field should also be an object then
    const delimitedNames = name.split('.') //as string[]

    //should not be a nested object
    if (delimitedNames.length === 1) return null

    //get value from values
    let value = values

    for (let i = 0; i < delimitedNames.length; i++) {
        value = value?.[delimitedNames[i]]

        if (value === undefined) return null
    }

    return value
}

/**********************************************************************************************************
 *   COMBOBOX FIELD
 **********************************************************************************************************/
const ComboboxComponent = ({ id, label, name, options = [], disabled = false, customValue = false, nullable = false, field, form, ...props }) => {
    const [selected, setSelected] = useState(options[0])
    const [query, setQuery] = useState('')

    const getFilterOptions = () => {
        if (query === '') return options

        return options.filter((option) => {
            if (option?.name) {
                return option.name.toLowerCase().includes(query.toLowerCase())
            }

            return option.toLowerCase().includes(query.toLowerCase())
        })
    }

    const filteredOptions = getFilterOptions()

    useEffect(() => {
        if (form && field && form?.values[field.name] === form?.initialValues[field.name]) {
            setSelected(getFormValueFromField(form, field))
        }
    }, [form, field])

    return (
        <>
            <GlobalComboboxStyle />
            <Combobox.Wrapper>
                <Element.Label htmlFor={id}>{label}</Element.Label>
                <Autocomplete
                    value={selected}
                    onChange={(event) => {
                        if (form && field) {
                            form?.setFieldValue(field.name, event)
                        }

                        setSelected(event)
                    }}
                    disabled={disabled}
                    name={name}
                    nullable={nullable}
                >
                    {({ open }) => (
                        <Combobox.Container>
                            <Combobox.Action>
                                <Combobox.WrapperButton>
                                    <Combobox.Input
                                        {...field}
                                        displayValue={(option) => {
                                            if (option?.name) {
                                                return option.name
                                            }

                                            return option
                                        }}
                                        onChange={(event) => {
                                            if (form && field) form.setFieldValue(field.name, event.target.value)

                                            return setQuery(event.target.value)
                                        }}
                                        {...props}
                                    />
                                </Combobox.WrapperButton>
                                {(form === undefined || !form?.errors?.[field?.name]) && (
                                    <Combobox.Button>
                                        <Combobox.SelectorIcon aria-hidden='true' />
                                    </Combobox.Button>
                                )}
                                {field && form && RenderErrorIcon(field.name, form.touched, form.errors)}
                            </Combobox.Action>
                            <Transition
                                show={open}
                                as={Fragment}
                                enter='combobox__transition--enter'
                                enterFrom='combobox__transition--enterFrom'
                                enterTo='combobox__transition--enterTo'
                                leave='combobox__transition--leave'
                                leaveFrom='combobox__transition--leaveFrom'
                                leaveTo='combobox__transition--leaveTo'
                                afterLeave={() => setQuery('')}
                            >
                                <Combobox.Options static>
                                    {customValue && query.length > 0 && (
                                        <Combobox.Option value={selected?.name ? { id: null, name: query } : query}>Create "{query}"</Combobox.Option>
                                    )}
                                    {filteredOptions.length === 0 && query !== '' && !customValue ? (
                                        <Combobox.NotFound>Nothing found.</Combobox.NotFound>
                                    ) : (
                                        filteredOptions.map((option, index) => {
                                            if (typeof option === 'object') {
                                                return (
                                                    <Autocomplete.Option
                                                        className={({ active }) => `option ${active ? 'active' : 'inactive'}`}
                                                        key={option.id}
                                                        value={option}
                                                    >
                                                        {({ selected, active }) => (
                                                            <>
                                                                <Combobox.Text selected={selected}>{option.name}</Combobox.Text>
                                                                {selected ? (
                                                                    <Combobox.Select active={active}>
                                                                        <Combobox.CheckIcon aria-hidden='true' />
                                                                    </Combobox.Select>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </Autocomplete.Option>
                                                )
                                            }

                                            return (
                                                <Autocomplete.Option
                                                    className={({ active }) => `option ${active ? 'active' : 'inactive'}`}
                                                    key={`${option}-${index}`}
                                                    value={option}
                                                >
                                                    {({ selected, active }) => (
                                                        <>
                                                            <Combobox.Text selected={selected}>{option}</Combobox.Text>
                                                            {selected ? (
                                                                <Combobox.Select active={active}>
                                                                    <Combobox.CheckIcon aria-hidden='true' />
                                                                </Combobox.Select>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Autocomplete.Option>
                                            )
                                        })
                                    )}
                                </Combobox.Options>
                            </Transition>
                        </Combobox.Container>
                    )}
                </Autocomplete>
            </Combobox.Wrapper>
        </>
    )
}

export { ComboboxComponent as Combobox }
