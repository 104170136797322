import { billingAPI } from 'api/billing'
import { useCheckoutCartMutation } from 'api/shop'
import { useApplyAccountCreditToInvoice } from 'containers/billing/utils'
import { ShopRouteBuilder } from 'containers/shop/helpers/routeMatchers'
import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function AccountCreditForm() {
    /***** SELECTORS *****/
    const { checkoutAccountCredit } = useAppSelector((state) => state.shop)
    const { cart } = useAppSelector((state) => state.app)

    /***** QUERIES *****/
    const { data: paymentMethods } = billingAPI.endpoints.paymentMethods.useQueryState()
    const [checkoutCart] = useCheckoutCartMutation({ fixedCacheKey: 'checkout-cart' })
    const { applyAccountCreditToInvoice } = useApplyAccountCreditToInvoice()

    /***** HOOKS *****/
    const dispatch = useAppDispatch()
    const firstPaymentMethod = paymentMethods?.find(({ id }) => id)
    const navigate = useNavigate()

    /***** RENDER *****/
    if (!cart || !firstPaymentMethod) return null

    return (
        <Formik
            initialValues={{}}
            onSubmit={async () => {
                const order = await checkoutCart({
                    payment_method_id: firstPaymentMethod.id,
                    uuid: cart.uuid
                }).unwrap()

                await applyAccountCreditToInvoice(order.invoice.id, checkoutAccountCredit.amount)

                dispatch(billingAPI.endpoints.invoice.initiate(order.invoice.id, { forceRefetch: true })).unwrap()
                navigate(ShopRouteBuilder.orderId(order.id))
            }}
        >
            {() => <Form id='accountCreditForm' />}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
