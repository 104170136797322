/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Theme } from 'nxui/src'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
const StyledErrorMessage = styled.div<{ visible?: boolean }>`
    min-height: 17px;
    height: auto;
    font-size: 1.2rem;
    color: ${({ theme }) => theme['error--100']};

    ${Theme.Functions.normalizeText()};
    ${Theme.Functions.setFont('Inter', 600, 'italic')};

    display: ${({ visible }) => (visible ? 'inline-flex' : 'none')};
`
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/

/***** EXPORTS *****/
export const FieldErrorMessageStyles = {
    Message: StyledErrorMessage
}
