/* eslint-disable indent */
/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useState } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid'

/**********************************************************************************************************
 *   STYLE
 **********************************************************************************************************/
import styled, { css } from 'styled-components'
import * as Theme from '../../theme/theme'

const Accordion = {
    Container: styled.div`
        height: fit-content;
        border-radius: 16px;
        border: 1px solid ${(props) => props.theme[`border--100`]};
        overflow: hidden;
    `,
    Label: styled.h4`
        padding: 10px 15px;
        color: ${(props) => props.theme[`text--200`]};
        border-radius: 16px 16px 0 0;
        background-color: ${(props) => props.theme[`background--100`]};
        border-bottom: 1px solid ${(props) => props.theme[`border--100`]};
    `,
    Item: styled.div`
        border-bottom: 1px solid ${(props) => props.theme[`border--100`]};

        &:last-of-type {
            border-bottom: none;
        }
    `,
    Title: styled.div`
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 15px;
        font-size: 1.4rem;
        ${Theme.Functions.animation(`all`)};

        &:hover {
            background-color: ${(props) => props.theme[`background--100`]};
        }

        ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-sm`])} {
            padding: 10px;
        }

        ${({ open }) => {
            if (open) {
                return css`
                    color: ${(props) => props.theme[`white--100`]};
                    background-color: ${(props) => props.theme[`primary--100`]};

                    &:hover {
                        background-color: ${(props) => props.theme[`primary--100`]};
                    }
                `
            }
        }}
    `,
    Content: styled.div`
        height: 0px;
        visibility: hidden;
        border-top: 1px solid ${(props) => props.theme[`border--100`]};
        font-size: 1.4rem;

        ${({ open }) => {
            if (open) {
                return css`
                    height: fit-content;
                    visibility: visible;
                `
            }
        }}
    `,
    Icon: {
        Down: styled(ChevronDownIcon)`
            margin-right: 15px;
            width: 18px;
            color: ${(props) => props.theme[`text--100`]};

            ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-sm`])} {
                margin-right: 10px;
            }

            ${({ open }) => {
                if (open) {
                    return css`
                        color: ${(props) => props.theme[`white--100`]};
                    `
                }
            }}
        `,
        Right: styled(ChevronRightIcon)`
            margin-right: 15px;
            width: 18px;
            color: ${(props) => props.theme[`text--100`]};

            ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-sm`])} {
                margin-right: 10px;
            }

            ${({ open }) => {
                if (open) {
                    return css`
                        color: ${(props) => props.theme[`white--100`]};
                    `
                }
            }}
        `
    }
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const AccordionComponent = ({ label, list }) => {
    /*   STATE
     **********************************************************************************************************/
    const [openIndex, setOpenIndex] = useState(undefined)

    /*   JSX FUNCTIONS
     **********************************************************************************************************/
    function renderAccordionList(data) {
        return data.map((item, index) => {
            return (
                <Accordion.Item key={index}>
                    <Accordion.Title onClick={() => setOpenIndex(index)} open={openIndex === index}>
                        {openIndex === index ? (
                            <Accordion.Icon.Down open={openIndex === index} />
                        ) : (
                            <Accordion.Icon.Right open={openIndex === index} />
                        )}
                        {item.label}
                    </Accordion.Title>
                    <Accordion.Content open={openIndex === index}>{item.content}</Accordion.Content>
                </Accordion.Item>
            )
        })
    }

    /**********************************************************************************************************
     *   RENDER
     **********************************************************************************************************/
    return (
        <Accordion.Container>
            {label && <Accordion.Label>{label}</Accordion.Label>}
            {list && renderAccordionList(list)}
        </Accordion.Container>
    )
}

export { AccordionComponent as Accordion }
