/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typeOverview
 * T represents the component being passed to the component prop on the component. G represents anything
 * which adds support for compound components which match typically match the type
 * `React.ComponentType<{}> & { \* some object \*}`.
 */
type TWrapWithComponent = <G, T extends React.ComponentType<{ children: React.ReactNode }> & G>(
    props: PropsFrom<T> & {
        /**
         * React component to wrap the children with. This component must accept children as a prop as
         * it will be passed the children prop internally.
         */
        component: T

        /**
         * Where or not the component should wrap the children.
         */
        wrap: boolean

        /**
         * The children to be wrapped.
         */
        children: React.ReactNode
    }
) => React.JSX.Element

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Component that can optionally wrap the children in the component passed through the
 * `component` prop. If `wrap` is false, it will return the children as is.
 */
export const WrapWithComponent: TWrapWithComponent = ({ component: Component, wrap, children, ...props }) => {
    /***** RENDER *****/
    if (wrap) {
        // @ts-ignore
        return <Component {...props}>{children}</Component>
    }

    return <>{children}</>
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
