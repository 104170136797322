/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { domainAPI } from 'api/domain'
import { useFormikContext } from 'formik'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { forwardingStyles as S } from '../_forwarding.styles'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import { TInitialValues } from '../addDomainForwarder'
type TDomainForwarderPreviewRow = React.FC<{ className?: string; outsideTable?: boolean }>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DomainForwarderPreviewRow: TDomainForwarderPreviewRow = ({ className, outsideTable = false }) => {
    /***** HOOKS *****/
    const { id } = useParams()
    const form = useFormikContext<TInitialValues>()

    const { errors } = form
    const { web_source, web_path, wildcard_source, destination, retain_path, type } = form.values

    /***** QUERIES *****/
    const { data: domainData } = domainAPI.endpoints.domain.useQueryState(Number(id))

    /***** RENDER HELPERS *****/
    const [protocol, rest] = destination?.split('//') ?? [null, destination]
    const [_destinationDomain, ...path] = rest?.split('/') ?? [destination]
    const destinationPathValue = path?.join('/') ?? destination
    const websourceEndsWithDomain = web_source?.endsWith(domainData?.domain ?? '')

    /***** COMPONENTS *****/
    const page = <S.Boldened>{web_path === '*' || wildcard_source ? 'any page' : `/${web_path}`}</S.Boldened>
    const permanent = <S.Boldened>{type === '301 Redirect (Permanent)' ? 'permanently' : 'temporarily'}</S.Boldened>
    const source = (
        <S.Boldened>{websourceEndsWithDomain ? web_source : `${web_source}${web_source?.length > 0 ? '.' : ''}${domainData?.domain}`}</S.Boldened>
    )

    /***** RENDER HELPERS *****/
    const getDestinationPath = () => {
        if (errors.destination) {
            return <S.Boldened>/</S.Boldened>
        } else {
            return <S.Boldened>{retain_path ? 'the same page' : `/${destinationPathValue}`}</S.Boldened>
        }
    }

    const getDestinationDomain = () => {
        if (errors.destination) {
            return <S.Boldened>{destination}</S.Boldened>
        } else {
            return (
                <S.Boldened>
                    {rest ? `${protocol}//` : ''}
                    {['https:', 'http:'].includes(protocol) && !_destinationDomain && `${protocol}//`}
                    {_destinationDomain}
                </S.Boldened>
            )
        }
    }

    /***** RENDER *****/
    return (
        <S.Preview className={className} outsideTable={outsideTable}>
            <span>
                Visitors to {page} on {source} will be {permanent} redirected to {getDestinationPath()} on {getDestinationDomain()}
            </span>
        </S.Preview>
    )
}
