/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { MapPinIcon } from '@heroicons/react/24/solid'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ContactDetail } from './detail'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Anchor } from 'components/buttons'
import { Flex } from 'components/utilities/Flex'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { useAppViewport } from 'helpers/hooks/useAppViewport'
import { forwardRef } from 'react'
import { websiteRoutes } from 'router/consts'
import { FooterStyles as S } from './style'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Footer = forwardRef<HTMLDivElement>((_, ref) => {
    /***** HOOKS *****/
    const { provider_name, email_billing, email_info, email_support } = useAppSelector((store) => store.app.appSettings.provider)
    const { website } = useAppSelector((store) => store.app.appSettings)
    const isSmall = useAppViewport(['sm', 'xs'])

    /***** RENDER *****/
    return (
        <S.Banner ref={ref}>
            <S.ContactUsLightbox />

            <S.ContactGrid>
                <ContactDetail title='Sales' phone={website?.phone_info} email={email_info} />
                <ContactDetail title='Support' phone={website?.phone_support} email={email_support} />
                <ContactDetail title='Billing' phone={website?.phone_billing} email={email_billing} />

                {!!website?.address && (
                    <ContactDetail title='Our Address'>
                        <Flex gap={8} justify='flex-start' align='center'>
                            <S.ContactDetailIcon icon={MapPinIcon} height={30} />
                            <S.AddressText>{website?.address}</S.AddressText>
                        </Flex>
                    </ContactDetail>
                )}
            </S.ContactGrid>

            <S.Separator />

            <S.Lower>
                <Flex justify='space-between' fullWidth direction={isSmall && 'column'}>
                    <S.CopyrightParagraph>Copyright © {provider_name}. All rights reserved</S.CopyrightParagraph>
                    <Flex gap={20}>
                        <Anchor.Link color='alternate' to={websiteRoutes.WHOIS}>
                            Whois Lookup
                        </Anchor.Link>
                        <Anchor.Link color='alternate' to={websiteRoutes.TERMS_OF_SERVICE}>
                            Legal Document
                        </Anchor.Link>
                    </Flex>
                </Flex>
            </S.Lower>
        </S.Banner>
    )
})
