/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import React from 'react'
import { Switch as HeadlessSwitch } from '@headlessui/react'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled from 'styled-components'
import * as Theme from '../../../theme/theme'

const Switch = {
    Base: styled(HeadlessSwitch)`
        cursor: pointer;
        position: relative;
        display: inline-flex;
        flex-shrink: 0;
        height: 24px;
        width: 44px;
        background-color: transparent;
        border-width: 2px;
        border-color: transparent;
        border-radius: 9999px;
        ${Theme.Functions.animation(`background`)};
        transition-duration: 200ms;
        ${Theme.Functions.outline(`none`)};

        &.enabled {
            background-color: ${(props) => props.theme[`primary--100`]};
        }

        &.disabled {
            background-color: ${(props) => props.theme[`background--200`]};
        }
    `,
    Reader: styled.span`
        ${Theme.Functions.srOnly()};
    `,
    Wrapper: styled.span`
        pointer-events: none;
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        background-color: ${(props) => props.theme[`background--100`]};
        border-radius: 9999px;
        box-shadow: 0 1px 3px 0 ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--200`], `0.1`)},
            0 1px 2px 0 ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--200`], `0.06`)};
        transform: translate(0);
        transition-property: transform;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 200ms;

        &.enabled {
            transform: translate(20px);
        }

        &.disabled {
            transform: translate(0);
        }
    `,
    Puck: styled.span`
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        ${Theme.Functions.inset()};
        ${Theme.Functions.animation(`opacity`)};
        transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
        transition-duration: 200ms;

        &.enabled {
            opacity: 1;
        }

        &.disabled {
            opacity: 0;
        }
    `,
    Cross: styled.svg`
        height: 12px;
        width: 12px;
        color: ${(props) => props.theme[`icon--100`]};
    `,
    Tick: styled.svg`
        height: 12px;
        width: 12px;
        color: ${(props) => props.theme[`primary--100`]};
    `
}

/**********************************************************************************************************
 *   SWITCH FIELD
 **********************************************************************************************************/
export const SwitchComponent = ({ title = 'Use setting', disabled, checked, onClick }) => {
    return (
        <Switch.Base className={checked ? `enabled` : `disabled`} disabled={disabled} checked={checked} onChange={onClick}>
            <Switch.Reader>{title}</Switch.Reader>
            <Switch.Wrapper className={checked ? `enabled` : `disabled`}>
                <Switch.Puck className={!checked ? `enabled` : `disabled`} aria-hidden='true'>
                    <Switch.Cross fill='none' viewBox='0 0 12 12'>
                        <path
                            d='M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2'
                            stroke='currentColor'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                    </Switch.Cross>
                </Switch.Puck>
                <Switch.Puck className={checked ? `enabled` : `disabled`} aria-hidden='true'>
                    <Switch.Tick fill='currentColor' viewBox='0 0 12 12'>
                        <path d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z' />
                    </Switch.Tick>
                </Switch.Puck>
            </Switch.Wrapper>
        </Switch.Base>
    )
}

export { SwitchComponent as Switch }
